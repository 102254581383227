import * as types from '../actions/actionTypes'
import numberFormat from '../utils/numberFormat.js'

export default function (state = {}, action) {
  switch (action.type) {
    case types.WORKS_INIT:
      !!action.payload && localStorage.setItem("works", JSON.stringify(action.payload))
      return action.payload

    case types.WORK_DELETE: {
      let workId = action.payload
      let newWorkList = state && state.length > 0 ? [...state] : []
      newWorkList = newWorkList && newWorkList.length > 0 && newWorkList.filter(m => m.workId !== workId)
      return newWorkList
    }
    case types.WORK_CHAPTER_DUEDATE_UPDATE: {
      const { workId, chapterId, dueDate } = action.payload

      let newWork = state && state.length > 0 && state.filter(m => m.workId === workId)[0]
      let chapterOptions = newWork && Object.assign([], newWork.chapterOptions)
      chapterOptions = chapterOptions && chapterOptions.length > 0 && chapterOptions.map(m => {
        if (m.chapterId === chapterId) {
          m.dueDate = dueDate
        }
        return m
      })
      newWork.chapterOptions = chapterOptions
      newWork.chapterDueDate = dueDate
      let newState = state && state.length > 0 && state.filter(m => m.workId !== workId)
      return newState ? newState.concat(newWork) : [newWork]
    }

    default:
      return state
  }
}


export const selectWorkById = (state, workId) => state && state.length > 0 && state.filter(m => m.workId === workId)[0]

export const selectWorkSummary = (state, workId, workId_current, me_personId) => {
  const work = selectWorkById(state, workId)
  if (!work || !work.workId) return

  let hasChapter = work && work.chapterId_current && work.chapterOptions && work.chapterOptions.length > 0 && work.chapterOptions.filter(m => m.chapterId === work.chapterId_current)[0]
  let chapterName_current = !hasChapter
    ? work.chapterName_current
    : hasChapter.name

  let chapterNbr_current = !hasChapter
    ? work.chapterNbr_current
    : hasChapter.chapterNbr

  return {
    ...work,
    chapterName_current,
    chapterNbr_current,
    isCurrentWork: workId === workId_current,
    isUserOwner: me_personId === work.personId,
    sectionCount: work.chapterOptions && work.chapterOptions.length,
    dueDate: work.dueDate || work.chapterDueDate,
    activeDate: work.activeDate,
    wordCount: work.chapterWordCount,
    sentenceCount: work.chapterSentenceCount,
  }
}

export const selectWorks = (state) => state && state.length > 0 && state.map(({ workId }) => selectWorkSummary(state, workId))

export const selectChaptersArray = (state, workId) => {
  let work = selectWorks(state).filter(m => m.workId === workId)[0]
  return work ? work.chapterOptions : []
}

export const selectChapterSummary = (state, workId, workId_current, chapter, me_personId) => {
  const work = selectWorkById(state, workId)
  if (!work || !chapter) return

  return {
    isCurrentWork: workId === workId_current,
    isUserOwner: me_personId === work.personId,
    workId: work.workId,
    title: chapter.name,
    description: work.description,
    author: work.authorName,
    authorPersonId: work.personId,
    //entryDate: work.entryDate,  We don't have an entry date specifically for a chapter. But I think that we should
    dueDate: chapter.dueDate,
    lastUpdate: work.lastUpdate,
    wordCount: numberFormat(work.chapterOptions.filter(m => m.chapterId === chapter.chapterId)[0].wordCount),
    sentenceCount: chapter.sentenceCount,
    editsPending: chapter.editsPending,
    editsProcessed: chapter.editsProcessed,
    editorsCount: chapter.editorsCount,
    sectionCount: work.chapterOptions.length,
    chapterName_current: chapter && chapter.name,
    chapterNbr_current: chapter && chapter.chapterNbr,
    chapterId_current: work.chapterId_current,
    chapterId: chapter.chapterId,
    languageId_current: work.languageId_current,
    chapterNbr: chapter.chapterNbr,
    chapterOptions: work.chapterOptions, //I don't think this one is useful since it is all chapters and we are showing one chapter at a time, so that this is repeated throughout all chapters.
    sequenceOptions: work.chapterOptions.map(m => ({ label: m.chapterNbr, id: m.chapterNbr })),
    languagesCount: work.languagesCount,
    languageId: work.languageId_current || work.languageId,
    languageName_current: work.languageName_current,
    languageOptions: work.languageOptions,
    chapterName: chapter.name,
    workStatusId: chapter.workStatusId,
    hrefId: '',
    hrefSentence: '',
    editorAssign: chapter.editorAssign,
    lastVisitedHrefId: '',
    fileName: '',
    workStatusName: chapter && chapter.workStatusName,
    editSeverityName: chapter && chapter.editSeverityName,
    authorComment: chapter && chapter.authorComment,
    editorComments: chapter && chapter.editorComments,
  }
}


export const hasActiveDocument = (state, personId) => state && state.length > 0 &&
  selectWorks(state).map(m => m.editorAssign && m.editorAssign.length > 0 && m.editorAssign.editorPersonId === personId && m.isActive)

export const hasCompletedDocument = (state, personId) => state && state.length > 0 &&
  selectWorks(state).map(m => m.editorAssign && m.editorAssign.length > 0 && m.editorAssign.editorPersonId === personId && m.completed)

export const selectEditorAssignCountByPersonId = (state, personId) => (state && state.length > 0 &&
  selectWorks(state).filter(m => m.editorAssign && m.editorAssign.length > 0 && m.editorAssign.editorPersonId === personId).length) || 0

export const selectEditorSoonestDueDate = (state, personId) => {
  let soonestDueDate = ""
  state && state.length > 0 && selectWorks(state).forEach(m => {
    if ((!soonestDueDate && m.dueDate) || (m.dueDate < soonestDueDate)) {
      soonestDueDate = m.dueDate
    }
  })
  return soonestDueDate
}

export const isContactAnEditor = (state, personId) => state && state.length > 0 &&
  selectWorks(state).map(m => m.editorAssign && m.editorAssign.length > 0 && m.editorAssign.editorPersonId === personId)

export const selectEditorAssignByPersonAndWorkId = (state, workId, personId) => {
  let editorAssign = []
  let works = selectWorks(state)

  if (works && works.length > 0) {
    let work = works.filter(m => m.workId === workId)[0]
    editorAssign = work.editorAssign
    if (work && editorAssign) {
      editorAssign = editorAssign.filter(m => m.editorPersonId === personId)
    }
  }
  return editorAssign
}

export const selectEditorAssignLanguageList = (state, personId, workId) => state && state.length > 0 &&
  selectWorks(state).filter(m => m.workId === workId)
    .editorAssign.filter(m => m.editorPersonId === personId)
    .reduce((acc, assign) => acc = acc.includes(assign.languageId) ? acc : acc.concat(assign.languageId), [])
