import MoveTarget from '../assets/Inline Icons/move-target.svg'
import * as editorService from './editor-dom'

let globalAddTableSequence

export const insertAddTableTargets = (suffixId, setAddTableTarget, setOpenTableAddModal, setHasTableStructure) => {
  let assignNewImages = []
  const editorDiv = document.getElementById(`editorDiv${suffixId}`)
  const mainDiv = document.getElementById(`${suffixId}0001`)
  if (mainDiv && editorDiv) {
    for (let i = 0; i < mainDiv.children.length; i++) {
      const exists = editorDiv.querySelector(`img[id="${mainDiv.children[i].id}"][data-type="ADDTABLE"]`)
      if (!exists) {
        let img = document.createElement('img')
        img.id = mainDiv.children[i].id
        img.src = MoveTarget
        img.height = 14
        img.className = 'AddTableTarget'
        img.style.cursor = 'pointer'
        img.setAttribute('data-type', 'ADDTABLE')
        img.addEventListener("click", function () {
          removeAddTableTargetIcons(`editorDiv${suffixId}`)
          setAddTableTarget(mainDiv.children[i])
          setOpenTableAddModal(`editorDiv${suffixId}`)
          setHasTableStructure(true)
        })
        assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
          paragraph: mainDiv.children[i],
          image: img,
        })
      }
    }
    assignNewImages.forEach(m => {
      const paragraphParent = m.paragraph.parentElement
      paragraphParent.insertBefore(m.image, m.paragraph)
    })
  }
}

export const createNewTable = ({ 
  addOrUpdateEdit, 
  chapterId, 
  columns, 
  editorName, 
  getWorkEditReviewFilled,
  handleSetChosenSegment,
  isAuthor, 
  languageId, 
  paragraphElement, 
  personId, 
  rows, 
  suffixId, 
}) => {
  let sequence = 1
  let firstSpan
  let nextId = editorService.getNextId(null, chapterId)

  // Create the table element
  const table = document.createElement('table')
  table.style.borderCollapse = 'collapse'
  table.style.border = '1px solid black'
  table.id = nextId++
  table.setAttribute('data-type', 'ADDTABLE')

  let addListEntriesReceive = [{
    addListElementId: table.id,
    sequence: sequence++,
    workSegmentTypeName: 'TABLE',
    parentElementId: `${suffixId}0001`, //This is the firstElement main body tag element id
    text: '',
    comment: '',
    styleClass: table.style.classList,
    styleInline: table.style.cssText,
    workSegmentId: 0,
  }]

  // Generate rows and columns
  for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
    const row = document.createElement('tr')
    row.id = nextId++
    row.setAttribute('data-type', 'ADDTABLE')

    addListEntriesReceive.push({
      addListElementId: row.id,
      sequence: sequence++,
      workSegmentTypeName: 'TR',
      parentElementId: table.id,
      text: '',
      comment: '',
      styleClass: row.style.classList,
      styleInline: row.style.cssText,
      workSegmentId: 0,
    })

    for (let colIndex = 0; colIndex < columns; colIndex++) {
      const cell = document.createElement('td')
      cell.id = nextId++
      cell.style.border = '1px solid black'
      cell.setAttribute('data-type', 'ADDTABLE')

      addListEntriesReceive.push({
        addListElementId: cell.id,
        sequence: sequence++,
        workSegmentTypeName: 'TD',
        parentElementId: row.id,
        text: '',
        comment: '',
        styleClass: cell.style.classList,
        styleInline: cell.style.cssText,
        workSegmentId: 0,
      })

      // Create a span inside the cell
      const span = document.createElement('span')
      span.id = nextId++
      span.setAttribute('data-type', 'ADDTABLE')
      span.innerHTML = '&nbsp;' // Add a non-breaking space for clickable behavior
      span.contentEditable = 'true' // Make the span editable
      span.style.display = 'inline-block' // Optional: Ensures it behaves like text
      span.style.minWidth = '50px' // Optional: Set a minimum width for usability
      span.style.minHeight = '20px' // Optional: Set a minimum height for usability

      if (!firstSpan) firstSpan = span

      addListEntriesReceive.push({
        addListElementId: span.id,
        sequence: sequence++,
        workSegmentTypeName: 'TEXT',
        parentElementId: cell.id,
        text: span.innerHTML,
        comment: '',
        styleClass: span.style.classList,
        styleInline: span.style.cssText,
        workSegmentId: 0,
      })

      // Append the span to the cell
      cell.appendChild(span)
      row.appendChild(cell)
    }
    table.appendChild(row)
  }

  if (!isAuthor) {
    addOrUpdateEdit({
      elementId: Number(paragraphElement.id),
      editSegmentId: 0,
      editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
      firstName: editorName?.firstName,
      lastName: editorName?.lastName,
      personId,
      chapterId,
      languageId,
      text: '',
      type: 'ADDTABLE',
      addListEntriesReceive,
    }, () => {
      setTimeout(() => getWorkEditReviewFilled(), 500);
      setTimeout(() => {
        const editorDiv = document.getElementById(`editorDiv${suffixId}`)
        if (editorDiv) {
          let newSpan = document.querySelector(`span[id="${firstSpan.id}"]`)
          if (newSpan) {
            editorService.setCursorPosition(newSpan, newSpan, 0, 0)
            handleSetChosenSegment(newSpan)
          }
        }
      }, 2000)
    })
  }
  paragraphElement.parentElement.insertBefore(table, paragraphElement)

}

export const removeAddTableTargetIcons = (editorDivId) => {
  const editorDiv = document.getElementById(editorDivId)
  if (editorDiv) {
    let loop = 0
    while (!!editorDiv.getElementsByClassName('AddTableTarget') && loop < 5) {
      let images = editorDiv.getElementsByClassName('AddTableTarget')
      if (images && images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          images[i].remove()
        }
      }
      loop++
    }
  }
}

export const setAddTableAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId) => {
  //1. Put the edit icon in front of the paragraph elementId to which the addTABLE edit is attached.
  //2. If it is the editor-owner, then show the table
  let addTableEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'ADDTABLE')
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const paragraphElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const paragraph = document.querySelector(`[id="${paragraphElementId}"]`)
    if (paragraph && (paragraph.nodeName === 'P' || paragraph.nodeName === 'TABLE')) {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')

      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = paragraphElementId
        img.src = `/inline/table-add-${editorColor}.png`
        img.height = 12 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', edit.type)
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)
        paragraph.parentElement.insertBefore(img, paragraph)
      }

      let isFirstAppend = true
      let parent = paragraph.parentElement
      let insertBeforeElement = paragraph

      if (editPerson) {
        const ifTabView = divDisplayId.indexOf('tabView') > -1 ? `~tabView${editorService.getSuffixIdFromEditorDivId(divDisplayId)}` : ''
        edit.addListEntries?.length > 0 && edit.addListEntries.forEach(entry => {
          if (entry.type === 'TABLE' || entry.type === 'THEAD' || entry.type === 'TBODY' || entry.type === 'TR' || entry.type === 'TH' || entry.type === 'TD') {
            let tableElement = document.createElement(entry.type.toLowerCase())
            tableElement.id = entry.addListElementId + ifTabView
            tableElement.setAttribute('data-edit-segment-id', entry.editSegmentId)
            tableElement.setAttribute('data-sequence', entry.sequence)
            tableElement.setAttribute('data-type', 'ADDTABLE')
            if (entry.styleClass) tableElement.setAttribute('class', entry.styleClass)
            if (entry.styleInline) tableElement.setAttribute('style', entry.styleInline)
            //We need to get the base OL or UL assigned in front of the parargraph that this edit is pegged to. Then, everything else will be appended within the OL or UL list
            if (isFirstAppend) {
              parent.insertBefore(tableElement, insertBeforeElement)
            } else {
              parent = document.getElementById(entry.parentElementId + ifTabView) //This should work since there shouldn't be any edit icons with the same elementId as parentElementId except one in this freestyle list of elements for the editor.
              if (parent) parent.append(tableElement)
            }
            isFirstAppend = false
            parent = tableElement
          } else if (entry.type === 'TEXT') {
            //If there is a workSegmentId on this record, that means that the list was converted from the author's text.
            //However, that workSegmentid is not going to be valid because the workSegment records were rebuilt.
            //But that is okay. Just because we have a workSegmentId, we know that it is okay to pick up the editor's version of that record and move it in by it's Penspring id.
            let span
            if (entry.originalWorkSegmentId > 0) {
              span = document.querySelector(`span[id="${entry.addListElementId + ifTabView}"][data-type]`)
              if (span) {
                span.setAttribute('data-type', 'ADDTABLE')
                span.setAttribute('data-edit-segment-id', entry.editSegmentId)
                span.setAttribute('data-sequence', entry.sequence)
                if (entry.editInSeries) span.setAttribute('data-edit-in-series', entry.editInSeries)
                parent.append(span)
              }
            } else {
              span = document.createElement('span')
              span.id = entry.addListElementId + ifTabView
              if (!!entry.text) { //This could be a comment only if the text is blank.
                span.innerHTML = entry.text
                if (span.innerHTML !== '') span.innerHTML = " " + span.innerHTML //This needs a space to separate it from the next sentence.
              } else {
                span.innerHTML = '&nbsp;'
              }
              if (entry.styleClass) span.setAttribute('class', entry.styleClass)
              if (entry.styleInline) span.setAttribute('style', entry.styleInline)
              span.setAttribute('data-type', 'ADDTABLE')
              span.setAttribute('data-edit-segment-id', entry.editSegmentId)
              span.setAttribute('data-sequence', entry.sequence)
              if (entry.editInSeries) span.setAttribute('data-edit-in-series', entry.editInSeries)
              span.spellCheck = 'true'
              parent.append(span)
            }
          }
        })
      }
    }
  })
}

export const deleteAuthorTable = ({
  addOrUpdateEdit,
  chapterId,
  editorName,
  getWorkEditReviewFilled,
  isAuthor,
  languageId,
  personId,
  tableElement,
}) => {
  //1. Delete the tableElement.
  //2. For the editor, send the edit to the database.
  //3. Recall the workEditReview record.
  if (tableElement) {
    tableElement.remove()
    if (!isAuthor) {
      addOrUpdateEdit({
        elementId: Number(tableElement.id),
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        firstName: editorName?.firstName,
        lastName: editorName?.lastName,
        personId,
        chapterId,
        languageId,
        text: '',
        type: 'DELETETABLE',
      }, () => setTimeout(() => getWorkEditReviewFilled(), 500))
    }
  }
}

export const setDeleteTableAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId) => {
  //1. For the author, put the edit icon in front of the table to be deleted.
  //2. For the editor, 
  //3.    put the icon in front of the nextSibling.
  //4.    Delete the table
  let addTableEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'DELETETABLE')
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const tableElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const table = document.querySelector(`table[id="${tableElementId}"]`)
    if (table) {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')

      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = tableElementId
        img.src = `/inline/table-delete-${editorColor}.png`
        img.height = 12 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', edit.type)
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)
        if (editPerson) {
          if (table.nextSibling) {
            table.parentElement.insertBefore(img, table.nextSibling)
          } else {
            table.parentElement.append(img)
          }
          table.remove()
        } else {
          table.parentElement.insertBefore(img, table)
        }
      }
    }
  })
}

export const gatherRowAddTableSegmentsToSave = (editSegmentId, suffixId, editType) => {
  //We look for the TR record as the parent and then save recursively 
  let saveSegments = []
  globalAddTableSequence = 1
  const firstElementId = `${suffixId}0001`
  const mainDiv = document.getElementById(firstElementId)
  const elements = mainDiv.querySelectorAll(`tr[data-edit-segment-id="${editSegmentId}"][data-type="${editType}"]`)
  elements.length > 0 && elements.forEach(element => {
    saveAddTableElementChildren(element, saveSegments, editSegmentId, suffixId)
  })
  return saveSegments
}

export const gatherAddTableSegmentsToSave = (editSegmentId, suffixId, editType) => {
  //I believe that this function is for the editor only since the addTABLE is its own region of freestyle for the editor to go to the AddListEntry table
  //When we are gathering these elements for the ADDTABLE edit, we are going to reset their id to the nextd available id (personId 3 + getNextId)
  //  because new spans and things will either have a blank or zero element.id
  //Also, we are going to set the sequence, which is also important to be entered and accurate for the addListEntry records.
  //Oh ... and we are going to combine all of the text in a listItem into one span since hitting enter and other actions can create disparate spans on the same line
  //  which are then being split up (inaccurately) into their own sentences.
  let saveSegments = []
  globalAddTableSequence = 1
  const firstElementId = `${suffixId}0001`
  const mainDiv = document.getElementById(firstElementId)
  const elements = mainDiv.querySelectorAll(`table[data-edit-segment-id="${editSegmentId}"][data-type="${editType}"]`)
  elements.length > 0 && elements.forEach(element => {
    saveAddTableElementChildren(element, saveSegments, editSegmentId, suffixId)
  })
  return saveSegments
}

export const saveAddTableElementChildren = (child, saveSegments, editSegmentId, suffixId) => {
  let segmentType = '';

  if (child.nodeName === 'P') {
    segmentType = 'PARAGRAPH';
  } else if (child.nodeName === 'SPAN') {
    segmentType = 'TEXT';
  } else if (child.nodeName === 'TABLE') {
    segmentType = 'TABLE';
  } else if (child.nodeName === 'THEAD') {
    segmentType = 'THEAD';
  } else if (child.nodeName === 'TBODY') {
    segmentType = 'TBODY';
  } else if (child.nodeName === 'TR') {
    segmentType = 'TR';
  } else if (child.nodeName === 'TH') {
    segmentType = 'TH';
  } else if (child.nodeName === 'TD') {
    segmentType = 'TD';
  } else if (child.nodeName === 'IMG') {
    segmentType = 'IMAGE';
  }

  let newParentElementId = Number(child.parentElement.id) || `${suffixId}0001`; //1 is for the first element recorded here. This was updated in the saveAddtableElementWorkSegment record for the parent to be the up-to-date globalAddTableSequence

  if (segmentType !== 'IMAGE') {
    saveAddTableElementWorkSegment(child, newParentElementId, segmentType, saveSegments, editSegmentId)
  }

  if (segmentType !== 'TEXT' && segmentType !== 'IMAGE') {
    let children = child.children
    for (let i = 0; i < children.length; i++) {
      saveAddTableElementChildren(children[i], saveSegments, editSegmentId, suffixId)
    }
  }
}

export const saveAddTableElementWorkSegment = (element, parentElementId, segmentType, saveSegments, editSegmentId) => {
  let text = ''
  if (segmentType === 'TEXT') {
    text = element.innerHTML.replace('<s></s>', '').replace('<b></b>', '').replace('<i></i>', '').replace('<u></u>', '').replace('<span></span>', '')
    text = editorService.stripOutEditImages(text)
    if (text && text.length > 6 && text.indexOf('&nbsp;') === text.length - 6) text = text.substring(0, text.length - 6)
  }
  let styleClass = element.getAttribute('class')
  let styleInline = element.getAttribute('style')
  element.id = element.id
  element.setAttribute('data-edit-segment-id', editSegmentId)
  element.setAttribute('data-type', element.dataset.type)
  // let imageSource = element.getAttribute("src") //These four are image related attributes
  // let imageHeight = element.getAttribute("height")
  // let imageWidth = element.getAttribute("width")
  // let imageAlt = element.getAttribute("alt")

  //If the previous saveSegments[saveSegments.length-1] is a workSegmentTypeName === 'TEXT', then just append this text to the end of it
  if (segmentType === 'TEXT') {
    if (saveSegments[saveSegments.length - 1] && saveSegments[saveSegments.length - 1].workSegmentTypeName === 'TEXT') {
      saveSegments[saveSegments.length - 1].text += text
      return
    }
  }
  saveSegments.push({
    editSegmentId: Number(editSegmentId),
    addListElementId: element.id,
    sequence: globalAddTableSequence++,
    workSegmentTypeName: segmentType,
    parentElementId,
    text,
    styleClass: styleClass || '',
    styleInline: styleInline || '',
    originalWorkSegmentId: Number(element.dataset.originalWorkSegmentId) || 0,
  })
}

export const isInAddTableFirstLevel = (spanElement) => {
  const characterBeforeCaret = editorService.getCharacterBeforeCaret()
  const greatGrandParent = spanElement?.parentElement?.parentElement?.parentElement
  if (spanElement?.dataset?.type === 'ADDTABLE'
    && ((editorService.getCursorPosition(spanElement) === 1 && characterBeforeCaret === ' ') || editorService.getCursorPosition(spanElement) === 0)
    && greatGrandParent.id == 1) {

    return true
  }
}


export const addNewRowByCellClick = ({
  addOrUpdateEdit, 
  chapterId, 
  editorName, 
  editType, 
  getWorkEditReviewFilled, 
  handleSetChosenSegment,
  isAuthor, 
  languageId, 
  personId, 
  spanElement, 
  suffixId, 
}) => {

  let nextId = editorService.getNextId(null, chapterId)
  let firstSpan
  let sequence = 1
  let cell = spanElement
  let loop = 0
  let addListEntriesReceive = []

  while (cell && !(cell.nodeName === 'TD' && cell.id) && loop < 5) {
    cell = cell.parentElement
    loop++
  }
  if ((cell.nodeName === 'TD' && cell.id)) {
    const rowClicked = cell.parentElement 
    const rowClickedNextSibling = rowClicked.nextSibling
    let table = rowClicked.parentElement 
    if (table.nodeName === 'THEAD' || table.nodeName === 'TBODY') {
      table = table.parentElement
    }

    const row = document.createElement('tr')
    row.id = nextId++
    row.setAttribute('data-type', 'ADDTABLEROW')
    row.setAttribute('style', rowClicked.style.cssText)

    if (editType !== 'ADDTABLE') { //This isn't in an editor's freestyle ADDTABLE. This is a new ADDTABLEROW record in an author's existing table.
      addListEntriesReceive.push({
        addListElementId: Number(row.id),
        sequence: sequence++,
        workSegmentTypeName: 'TR',
        parentElementId: Number(table.id), //This is the firstElement main body tag element id
        text: '',
        comment: '',
        workSegmentId: 0,
      })
    }

    for (let colIndex = 0; colIndex < rowClicked.children.length; colIndex++) {
      const cell = document.createElement('td')
      cell.id = nextId++
      cell.style.border = '1px solid black'
      cell.setAttribute('data-type', 'ADDTABLEROW')

      if (editType !== 'ADDTABLE') { //This isn't in an editor's freestyle ADDTABLE. This is a new ADDTABLEROW record in an author's existing table.
        addListEntriesReceive.push({
          addListElementId: Number(cell.id),
          sequence: sequence++,
          workSegmentTypeName: 'TD',
          parentElementId: Number(row.id), //This is the firstElement main body tag element id
          text: '',
          comment: '',
          workSegmentId: 0,
        })
      }

      // Create a span inside the cell
      const span = document.createElement('span')
      span.id = nextId++
      span.setAttribute('data-type', 'ADDTABLEROW')
      span.innerHTML = '&nbsp;' // Add a non-breaking space for clickable behavior
      span.contentEditable = 'true' // Make the span editable
      span.style.display = 'inline-block' // Optional: Ensures it behaves like text
      span.style.minWidth = '50px' // Optional: Set a minimum width for usability
      span.style.minHeight = '20px' // Optional: Set a minimum height for usability

      if (editType !== 'ADDTABLE') { //This isn't in an editor's freestyle ADDTABLE. This is a new ADDTABLEROW record in an author's existing table.
        addListEntriesReceive.push({
          addListElementId: Number(span.id),
          sequence: sequence++,
          workSegmentTypeName: 'TEXT',
          parentElementId: Number(cell.id), //This is the firstElement main body tag element id
          text: span.innerHTML,
          comment: '',
          workSegmentId: 0,
        })
      }

      if (!firstSpan) firstSpan = span

      // Append the span to the cell
      cell.appendChild(span)
      row.appendChild(cell)
    }

    if (!isAuthor && editType !== 'ADDTABLE') { //This isn't in an editor's freestyle ADDTABLE. This is a new ADDTABLEROW record in an author's existing table.
      addOrUpdateEdit({
        elementId: Number(rowClicked.id),
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        firstName: editorName?.firstName,
        lastName: editorName?.lastName,
        personId,
        chapterId,
        languageId,
        text: '',
        type: 'ADDTABLEROW',
        addListEntriesReceive,
      }, () => {
        setTimeout(() => getWorkEditReviewFilled(), 500);
        setTimeout(() => {
          const editorDiv = document.getElementById(`editorDiv${suffixId}`)
          if (editorDiv) {
            let newSpan = document.querySelector(`span[id="${firstSpan.id}"]`)
            if (newSpan) {
              editorService.setCursorPosition(newSpan, newSpan, 0, 0)
              handleSetChosenSegment(newSpan)
            }
          }
        }, 2000)
      })
    }

    if (rowClickedNextSibling) {
      table.insertBefore(row, rowClickedNextSibling)
    } else {
      table.appendChild(row) 
    }
    return { firstSpan, editSegmentId: table.dataset.editSegmentId }
  }
}

export const addNewTableRow = ({
  addOrUpdateEdit, 
  addOrUpdateEditAddList, 
  chapterId, 
  editorName,
  getWorkEditReviewFilled,
  handleSetChosenSegment,
  isAuthor,
  languageId, 
  personId, 
  setCursorPosition, 
  spanElement, 
  suffixId, 
}) => {

  if (spanElement?.dataset.type === 'ADDTABLE') {
    const { firstSpan, editSegmentId } = addNewRowByCellClick({
      addOrUpdateEdit,
      chapterId,
      editorName,
      editType: 'ADDTABLE',
      getWorkEditReviewFilled,
      handleSetChosenSegment,
      isAuthor,
      languageId,
      personId,
      spanElement,
      suffixId})

    const saveSegments = gatherAddTableSegmentsToSave(editSegmentId, suffixId)
    addOrUpdateEditAddList(saveSegments)
    setTimeout(() => {
      let newSpan = document.querySelector(`span[id="${firstSpan.id}"]`)
      if (newSpan) {
        setCursorPosition(newSpan, newSpan, 0, 0)
        handleSetChosenSegment(newSpan)
      }
    }, 500)
  } else {
    let cell = spanElement
    let loop = 0
    while (cell && !(cell.nodeName === 'TD' && cell.id) && loop < 5) {
      cell = cell.parentElement
      loop++
    }
    if ((cell.nodeName === 'TD' && cell.id)) {
      addNewRowByCellClick({
        addOrUpdateEdit,
        chapterId,
        editorName,
        editType: 'ADDTABLEROW',
        getWorkEditReviewFilled,
        isAuthor,
        languageId,
        personId,
        spanElement: cell,
        suffixId
      })
    }
  }
}

//ADDTABLEROW
//This is for a table row, which can be for an author's table for an ADDTABLE type of a table that belongs to this editor.  Hmm. What if an editor wants to add a table row to another editor's row?  I think that they would have to accept the entire table and then make their own row to show the author.
export const setAddTableRowAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId, chapterId) => {
  //1. Put the edit icon in front of the first span of the first cell of the row above the row that the editor has marked as a new row.
  //2. If it is the editor-owner, then show the new row. But now we need to be able to do a similar addListEntry to be able to freely text. Oh, sheesh. May the force be with us.
  let addTableEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'ADDTABLEROW')
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const rowElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const row = document.querySelector(`[id="${rowElementId}"]`)
    let firstSpan
    if (row && row.nodeName === 'TR') {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')
      const ifTabView = divDisplayId.indexOf('tabView') > -1 ? `~tabView${editorService.getSuffixIdFromEditorDivId(divDisplayId)}` : ''
      let newRowElement
      if (editPerson) {
        let parent = row.parentElement
        let isFirstAppend = true
        let insertBeforeElement = row.nextElementSibling
        edit.addListEntries?.length > 0 && edit.addListEntries.forEach(entry => {
          //This should be starting with TR, not TABLE (like the ADDTABLE version, but this is ADDTABLEROW).
          if (entry.type === 'TABLE' || entry.type === 'THEAD' || entry.type === 'TBODY' || entry.type === 'TR' || entry.type === 'TH' || entry.type === 'TD') {
            let tableElement = document.createElement(entry.type.toLowerCase())
            if (!newRowElement) newRowElement = tableElement
            tableElement.id = entry.addListElementId + ifTabView
            tableElement.setAttribute('data-edit-segment-id', entry.editSegmentId)
            tableElement.setAttribute('data-sequence', entry.sequence)
            tableElement.setAttribute('data-type', 'ADDTABLEROW')
            if (entry.styleClass) tableElement.setAttribute('class', entry.styleClass)
            if (entry.styleInline) tableElement.setAttribute('style', entry.styleInline)
            //We need to get the base OL or UL assigned in front of the parargraph that this edit is pegged to. Then, everything else will be appended within the OL or UL list
            if (isFirstAppend) {
              parent.insertBefore(tableElement, insertBeforeElement)
            } else {
              parent = document.getElementById(entry.parentElementId + ifTabView) //This should work since there shouldn't be any edit icons with the same elementId as parentElementId except one in this freestyle list of elements for the editor.
              if (parent) parent.append(tableElement)
            }
            isFirstAppend = false
            parent = tableElement
          } else if (entry.type === 'TEXT') {
            //If there is a workSegmentId on this record, that means that the list was converted from the author's text.
            //However, that workSegmentid is not going to be valid because the workSegment records were rebuilt.
            //But that is okay. Just because we have a workSegmentId, we know that it is okay to pick up the editor's version of that record and move it in by it's Penspring id.
            let span
            if (entry.originalWorkSegmentId > 0) {
              span = document.querySelector(`span[id="${entry.addListElementId + ifTabView}"][data-type]`)
              if (span) {
                span.setAttribute('data-type', 'ADDTABLEROW')
                span.setAttribute('data-edit-segment-id', entry.editSegmentId)
                span.setAttribute('data-sequence', entry.sequence)
                if (entry.editInSeries) span.setAttribute('data-edit-in-series', entry.editInSeries)
                parent.append(span)
              }
            } else {
              span = document.createElement('span')
              span.id = entry.addListElementId + ifTabView
              if (!!entry.text) { //This could be a comment only if the text is blank.
                span.innerHTML = entry.text
                if (span.innerHTML !== '') span.innerHTML = " " + span.innerHTML //This needs a space to separate it from the next sentence.
              } else {
                span.innerHTML = '&nbsp;'
              }
              if (entry.styleClass) span.setAttribute('class', entry.styleClass)
              if (entry.styleInline) span.setAttribute('style', entry.styleInline)
              span.setAttribute('data-type', 'ADDTABLEROW')
              span.setAttribute('data-edit-segment-id', entry.editSegmentId)
              span.setAttribute('data-sequence', entry.sequence)
              if (entry.editInSeries) span.setAttribute('data-edit-in-series', entry.editInSeries)
              span.spellCheck = 'true'
              parent.append(span)
            }
            if (!firstSpan && span) firstSpan = span
          }
        })
      }
      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = rowElementId
        img.src = `/inline/row-add-${editorColor}.png`
        img.height = 12 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', 'ADDTABLEROW')
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)
        if (editPerson && newRowElement && newRowElement.firstChild) {
          newRowElement.firstChild.insertBefore(img, newRowElement.firstChild.firstChild) //This needs to go in front of the first span of the first cell.
        } else {
          row.firstChild.insertBefore(img, row.firstChild.firstChild) //This needs to go in front of the first span of the first cell.
        }
      }
    }
  })
}

export const deleteTableRowEdit = ({
  addOrUpdateEdit,
  addOrUpdateEditAddList,
  chapterId,
  getWorkEditReviewFilled,
  languageId,
  personId,
  spanElement,
  suffixId,
}) => {

  if (spanElement?.dataset.type === 'ADDTABLE') {
    let cell = spanElement
    let loop = 0
    while (cell && !(cell.nodeName === 'TD' && cell.id) && loop < 5) {
      cell = cell.parentElement
      loop++
    }
    if ((cell.nodeName === 'TD' && cell.id)) {
      const row = cell.parentElement
      let table = row.parentElement
      if (table.nodeName === 'THEAD' || table.nodeName === 'TBODY') {
        table = table.parentElement
      }
      row.remove()
      const saveSegments = gatherAddTableSegmentsToSave(cell.dataset.editSegmentId, suffixId)
      addOrUpdateEditAddList(saveSegments)
    }
  } else {
    let cell = spanElement
    let loop = 0
    while (cell && !(cell.nodeName === 'TD' && cell.id) && loop < 5) {
      cell = cell.parentElement
      loop++
    }
    if ((cell.nodeName === 'TD' && cell.id)) {
      const row = cell.parentElement
      row.remove()

      addOrUpdateEdit({
        chapterId,
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        elementId: Number(row.id),
        languageId,
        personId,
        text: '',
        type: 'DELETETABLEROW',
      }, () => setTimeout(() => getWorkEditReviewFilled(), 500))
    }
  }
}

//DELETETABLEROW
//This is for a table row, which can be for an author's table for an ADDTABLE type of a table that belongs to this editor.  Hmm. What if an editor wants to add a table row to another editor's row?  I think that they would have to accept the entire table and then make their own row to show the author.
export const setDeleteTableRowAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId, chapterId) => {
  //1. Fpr the author, put the edit icon in front of the first span of the first cell 
  //2. If it is the editor-owner, put the delete icon in front of the first span of the first cell of the row above the deleted row.
  let addTableEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'DELETETABLEROW')
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const rowElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const row = document.querySelector(`[id="${rowElementId}"]`)
    if (row && (row.nodeName === 'TR')) {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')
      const tableElement = row.parentElement
      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = rowElementId
        img.src = `/inline/row-delete-${editorColor}.png`
        img.height = 12 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', edit.type)
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)
        if (editPerson) {
          if (row.previousSibling) {
            row.previousSibling.firstChild.insertBefore(img, row.previousSibling.firstChild.firstChild) //This needs to go in front of the first span of the first cell.
          } else {
            tableElement.parentElement.insertBefore(img, tableElement)
          }
        } else {
          row.firstChild.insertBefore(img, row.firstChild.firstChild) //This needs to go in front of the first span of the first cell.
        }
      }
      if (editPerson) {
        row.remove()
      }
    }
  })
}

export const addTableColumnEdit = ({
  addOrUpdateEdit,
  addOrUpdateEditAddList,
  chapterId,
  columnIndex,
  editorName,
  getWorkEditReviewFilled,
  handleSetChosenSegment,
  isAuthor,
  languageId,
  tableElement,
  personId,
  suffixId,
}) => {
  //0. If this is the ADDTABLE that belongs to the editor
  //1.   Take the index of the column and loop through the rows of the table.
  //2.     Create the new TD and SPAN with ids
  //3.     If there is a current TD at the given index 
  //4.       Insert the new TD and SPAN after the current TD
  //5.     Else, 
  //6.       Append the new TD and SPAN at the end of the TR.
  //7.     End if
  //8.     If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
  //9.   End loop
  //10. Else - It is an editor's table
  //11. Take the index of the column and loop through the rows of the table.
  //12.   Create the new TD and SPAN with ids
  //13.   If there is a current TD at the given index 
  //14.     Insert the new TD and SPAN after the current TD
  //15.   Else, 
  //16.     Append the new TD and SPAN at the end of the TR.
  //17.   End if
  //18.   If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
  //19. end of loop
  //20.If this is the editor record the addOrUpdateEditor record with the addlistEntriesReceive records
  //      We will save the columnIndex in StartElementId

  let firstSpan
  let addListEntriesReceive = []
  let nextId = editorService.getNextId(null, chapterId)
  let sequence = 1
  
  if (tableElement?.dataset.type === 'ADDTABLE') { //This is the editor's table that she added. But we still want to add some columns for her.
    //1. Take the index of the column and loop through the rows of the table.
    for (let i = 0; i < tableElement.children.length; i++) {
      const row = tableElement.children[i]
      //2.   Create the new TD and SPAN with ids
      const { cell, span } = createTdSpanPairNew(row, firstSpan, nextId, sequence, addListEntriesReceive, 'ADDTABLECOLUMN')
      const newCell = cell
      if (!firstSpan) firstSpan = span //This is for setting the cursor in the first cell created for the user to continue to type.
      nextId += 2
      sequence += 2

      //3.   If there is a current TD at the given index (If there are td's that are missing, then add the needed TD/SPAN pairs until it reaches the index that we will fill in.)
      if (row.children.length - 1 > columnIndex) {
        //4.     Insert the new TD and SPAN after the current TD
        row.insertBefore(newCell, row.children[columnIndex].nextSibling)
        //5.   Else, 
      } else {
        //6.     Append the new TD and SPAN at the end of the TR.
        const missingCells = columnIndex - (row.children.length - 1)

        for (let count = 0; count < missingCells; count++) {
          const { cell } = createTdSpanPairNew(row, firstSpan, nextId, sequence, addListEntriesReceive, 'ADDTABLECOLUMN')
          const fillerCell = cell
          nextId += 2
          sequence += 2
          row.append(fillerCell)
        }
        row.append(newCell)
        //7.   End if
      }
      //8.   If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
    //9. end of loop
    }
    const saveSegments = gatherAddTableSegmentsToSave(tableElement.dataset.editSegmentId, suffixId, 'ADDTABLE')    
    addOrUpdateEditAddList(saveSegments)

  //10. Else - It is an editor's table
  } else {
    //11. Take the index of the column and loop through the rows of the table.
    for(let i = 0; i < tableElement.children.length; i++) {
      const row = tableElement.children[i]
      //12.   Create the new TD and SPAN with ids
      const { cell, span } = createTdSpanPairNew(row, firstSpan, nextId, sequence, addListEntriesReceive, 'ADDTABLECOLUMN')
      const newCell = cell
      if (!firstSpan) firstSpan = span //This is for setting the cursor in the first cell created for the user to continue to type.
      nextId += 2
      sequence += 2

      //13.   If there is a current TD at the given index (If there are td's that are missing, then add the needed TD/SPAN pairs until it reaches the index that we will fill in.)
      if (row.children.length-1 > columnIndex) {
        //14.     Insert the new TD and SPAN after the current TD
        row.insertBefore(newCell, row.children[columnIndex].nextSibling)
      //15.   Else, 
      } else {
        //16.     Append the new TD and SPAN at the end of the TR.
        const missingCells = columnIndex - (row.children.length - 1)

        for (let count = 0; count < missingCells; count++) {
          const { cell } = createTdSpanPairNew(row, firstSpan, nextId, sequence, addListEntriesReceive, 'ADDTABLECOLUMN')
          const fillerCell = cell
          nextId += 2
          sequence += 2
          row.append(fillerCell)
        }
        row.append(newCell)
      //17.   End if
      }
      //18.   If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
    //19. end of loop
    }
    //20.If this is the editor record the addOrUpdateEditor record with the addlistEntriesReceive records
    //      We will save the columnIndex in StartElementId
    if (!isAuthor) {
      addOrUpdateEdit({
        elementId: Number(tableElement.id),
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        firstName: editorName?.firstName,
        lastName: editorName?.lastName,
        personId,
        chapterId,
        languageId,
        startElementId: columnIndex,
        text: '',
        type: 'ADDTABLECOLUMN',
        addListEntriesReceive,
      }, () => {
        setTimeout(() => getWorkEditReviewFilled(), 500);
        setTimeout(() => {
          const editorDiv = document.getElementById(`editorDiv${suffixId}`)
          if (editorDiv) {
            let newSpan = document.querySelector(`span[id="${firstSpan.id}"]`)
            if (newSpan) {
              editorService.setCursorPosition(newSpan, newSpan, 0, 0)
              handleSetChosenSegment(newSpan)
            }
          }
        }, 2000)
      })
    }
  }
}

const createTdSpanPairNew = (row, firstSpan, nextId, sequence, addListEntriesReceive, editType) => {
  const cell = document.createElement('td')
  cell.id = nextId++
  cell.style.border = '1px solid black'
  cell.setAttribute('data-type', editType)

  addListEntriesReceive.push({
    addListElementId: cell.id,
    sequence: sequence++,
    workSegmentTypeName: 'TD',
    parentElementId: row.id,
    text: '',
    comment: '',
    styleClass: cell.style.classList,
    styleInline: cell.style.cssText,
    workSegmentId: 0,
  })

  // Create a span inside the cell
  const span = document.createElement('span')
  span.id = nextId++
  span.setAttribute('data-type', editType)
  span.innerHTML = '&nbsp;' // Add a non-breaking space for clickable behavior
  span.contentEditable = 'true' // Make the span editable
  span.style.display = 'inline-block' // Optional: Ensures it behaves like text
  span.style.minWidth = '50px' // Optional: Set a minimum width for usability
  span.style.minHeight = '20px' // Optional: Set a minimum height for usability

  if (!firstSpan) firstSpan = span

  addListEntriesReceive.push({
    addListElementId: span.id,
    sequence: sequence++,
    workSegmentTypeName: 'TEXT',
    parentElementId: cell.id,
    text: span.innerHTML,
    comment: '',
    styleClass: span.style.classList,
    styleInline: span.style.cssText,
    workSegmentId: 0,
  })
  cell.append(span)
  return {cell, span}
}

//ADDTABLECOLUMN
//This is for a table column, which can be for an author's table for an ADDTABLE type of a table that belongs to this editor.  Hmm. What if an editor wants to add a table row to another editor's row?  I think that they would have to accept the entire table and then make their own row to show the author.
export const setAddTableColumnAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId) => {
  //1. Loop through the ADDTABLECOLUMN edits
  //2.   If this is the editor
  //3.     Loop through the given table's rows
  //4.       If the row's children (TDs) goes past the columnIndex (StartElementId, in this case)
  //5.         insert the new column after the next TD of the columnIndex
  //6.       Else
  //7.         Insert the filler columns one after another until the final cell is inserted
  //8.       End if 
  //9.     End loop
  //10.  End if
  //11.  If this is the editor
  //12.    Put the icon at the top of the new column (to the right of the columnIndex - StartElementId)
  //13.  Else
  //14.    Put the icon at the top of the columnIndex
  //15.  End if
  //16.End loop

  let addTableEdits = edits?.length > 0 && edits.filter(e => e.type === 'ADDTABLECOLUMN')
  //1. Loop through the ADDTABLECOLUMN edits
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const tableElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const table = document.querySelector(`table[id="${tableElementId}"]`)
    const columnIndex = edit.startElementId
    if (table && table.nodeName === 'TABLE') {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')
      const ifTabView = divDisplayId.indexOf('tabView') > -1 ? `~tabView${editorService.getSuffixIdFromEditorDivId(divDisplayId)}` : ''
      //2.   If this is the editor
      if (editPerson) {
        //3.     Loop through the given table's rows
        for(let i = 0; i < table.children.length; i++) {
          let row = table.children[i]
          //4.       If the row's children (TDs) goes past the columnIndex (StartElementId, in this case)
          if (row.children.length > columnIndex) { //Remember that the length is not zero-based but the columnIndex is so that the columnIndex will be less by 1
            //5.         insert the new column aftere the next TD of the columnIndex
            const cells = edit.addListEntries.filter(m => Number(m.parentElementId) === Number(row.id))
            cells?.length > 0 && cells.forEach(cell => { //There really should only be one.
              const span = edit.addListEntries.filter(m => Number(m.parentElementId) === Number(cell.addListElementId))[0]
              const newCell = insertTdSpanPairFromEdit(edit, cell, span, ifTabView, 'ADDTABLECOLUMN')
              row.insertBefore(newCell, row.children[columnIndex + 1])
            })
          //6.       Else
          } else {
            //7.         Insert the filler columns one after another until the final cell is inserted
            const cells = edit.addListEntries.filter(m => Number(m.parentElementId) === Number(row.id))
            cells?.length > 0 && cells.forEach(cell => {
              const span = edit.addListEntries.filter(m => Number(m.parentElementId) === Number(cell.addListElementId))[0]
              const newCell = insertTdSpanPairFromEdit(edit, cell, span, ifTabView, 'ADDTABLECOLUMN')
              row.append(newCell)
            })
          //8.       End if 
          }
        //9.     End loop
        }
      //10.  End if
      }
      //11.  If this is the editor
      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = table.id
        img.src = `/inline/column-add-${editorColor}.png`
        img.height = 12 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', edit.type)
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)
        
        if (editPerson) {
          //12.    Put the icon at the top of the new column (to the right of the columnIndex - StartElementId)
          let target = table.children[0]
          const index = columnIndex + 1 * 1
          target = target.children[index]
          target.insertBefore(img, target.firstChild)
        //13.  Else
        } else {
          //14.    Put the icon at the top of the columnIndex (but just in case that column doesn't exist on the first one, we need to find the first column in the table that matches that column index)
          for (let i = 0; i < table.children.length; i++) {
            let row = table.children[i]
            if (row.children.length >= columnIndex+1) { //Remember that the length is not zero-based but the columnIndex is so that the columnIndex will be less by 1
              let target = row.children[columnIndex]
              target.insertBefore(img, target.firstChild)
              break
            }
          }
        //15.  End if
        }
      }
    //16.End loop
    }
  })
}

const insertTdSpanPairFromEdit = (entry, cell, span, ifTabView, editType) => {
  const newCell = document.createElement('td')
  newCell.id = cell.addListElementId + ifTabView
  newCell.style.border = '1px solid black'
  newCell.setAttribute('data-edit-segment-id', entry.editSegmentId)
  newCell.setAttribute('data-sequence', cell.sequence)
  newCell.setAttribute('data-type', editType)

  // Create a span inside the cell
  const newSpan = document.createElement('span')
  newSpan.id = span.addListElementId + ifTabView  //This is an addList record (span) not a DOM element so we refer to the 'addListElementId' field for the id.
  newSpan.setAttribute('data-edit-segment-id', entry.editSegmentId)
  newSpan.setAttribute('data-sequence', span.sequence)
  newSpan.setAttribute('data-type', editType)
  newSpan.innerHTML = span.text //This is an addList record (span) not a DOM element so we refer to the 'text' field.
  newSpan.contentEditable = 'true' // Make the span editable
  newSpan.style.display = 'inline-block' // Optional: Ensures it behaves like text
  newSpan.style.minWidth = '50px' // Optional: Set a minimum width for usability
  newSpan.style.minHeight = '20px' // Optional: Set a minimum height for usability

  newCell.append(newSpan)
  return newCell
}

export const gatherColumnAddTableSegmentsToSave = (editSegmentId, suffixId, editType) => {
  //We look for the TR record as the parent and then save recursively 
  let saveSegments = []
  globalAddTableSequence = 1
  const firstElementId = `${suffixId}0001`
  const mainDiv = document.getElementById(firstElementId)
  const elements = mainDiv.querySelectorAll(`td[data-edit-segment-id="${editSegmentId}"][data-type="${editType}"]`)
  elements.length > 0 && elements.forEach(element => {
    saveAddTableElementChildren(element, saveSegments, editSegmentId, suffixId)
  })
  return saveSegments
}

export const deleteTableColumnEdit = ({
  addOrUpdateEdit,
  addOrUpdateEditAddList,
  chapterId,
  columnIndex,
  editorName,
  getWorkEditReviewFilled,
  isAuthor,
  languageId,
  tableElement,
  personId,
  suffixId,
}) => {
  //1. If this is the ADDTABLE that belongs to the editor
  //2. Take the index of the column and loop through the rows of the table.
  //3.   If there is a current TD at the given index, remove it
  //4. Else - this is a table that belongs to the editor.
  //5. Take the index of the column and loop through the rows of the table.
  //6.   If there is a current TD at the given index, remove it
  //7. end of loop
  //8. If this is the editor record the addOrUpdateEditor record with the addlistEntriesReceive records
  //      We will save the columnIndex in StartElementId

  //1. If this is the ADDTABLE that belongs to the editor
  if (tableElement?.dataset.type === 'ADDTABLE') { //This is the editor's table that she added. But we still want to add some columns for her.
    //2. Take the index of the column and loop through the rows of the table.
    for (let i = 0; i < tableElement.children.length; i++) {
      const row = tableElement.children[i]
      //3.   If there is a current TD at the given index, remove it
      if (row.children.length - 1 > columnIndex) {
        row.children[columnIndex].remove()
      }
    }
    const saveSegments = gatherAddTableSegmentsToSave(tableElement.dataset.editSegmentId, suffixId, 'ADDTABLE')
    addOrUpdateEditAddList(saveSegments)

  //4. Else - this is a table that belongs to the editor.
  } else {
    //5. Take the index of the column and loop through the rows of the table.
    for (let i = 0; i < tableElement.children.length; i++) {
      const row = tableElement.children[i]
      //6.   If there is a current TD at the given index, remove it
      if (row.children.length - 1 > columnIndex) {
        row.children[columnIndex].remove()
      }
    //7. end of loop
    }
    //8. If this is the editor record the addOrUpdateEditor record with the addlistEntriesReceive records
    //      We will save the columnIndex in StartElementId
    if (!isAuthor) {
      addOrUpdateEdit({
        elementId: Number(tableElement.id),
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        firstName: editorName?.firstName,
        lastName: editorName?.lastName,
        personId,
        chapterId,
        languageId,
        startElementId: columnIndex,
        text: '',
        type: 'DELETETABLECOLUMN',
      }, () => setTimeout(() => getWorkEditReviewFilled(), 500))
    }
  }
}

//DELETETABLECOLUMN
export const setDeleteTableColumnAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId) => {
  //1. Loop through the DELETETABLECOLUMN edits
  //2.   If this is the editor
  //3.     Loop through the given table's rows
  //4.       If the row's children (TDs) has a TD in the columnIndex (StartElementId, in this case), remove it
  //5.     End loop
  //6.  End if
  //7.  If this is the editor
  //8.    Put the icon at the top of the new column to the left of the columnIndex - StartElementId
  //9.  Else
  //10.    Put the icon at the top of the columnIndex (but just in case that column doesn't exist on the first one, we need to find the first column in the table that matches that column index)
  //11.  End if
  //12.End loop

  let addTableEdits = edits?.length > 0 && edits.filter(e => e.type === 'DELETETABLECOLUMN')
  //1. Loop through the DELETETABLECOLUMN edits
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const tableElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const table = document.querySelector(`table[id="${tableElementId}"]`)
    const columnIndex = edit.startElementId
    if (table && table.nodeName === 'TABLE') {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')
      const ifTabView = divDisplayId.indexOf('tabView') > -1 ? `~tabView${editorService.getSuffixIdFromEditorDivId(divDisplayId)}` : ''
      //2.   If this is the editor
      if (editPerson) {
        //3.     Loop through the given table's rows
        for (let i = 0; i < table.children.length; i++) {
          let row = table.children[i]
          //4.       If the row's children (TDs) has a TD in the columnIndex (StartElementId, in this case), remove it
          if (row.children.length >= columnIndex + 1) { //Remember that the length is not zero-based but the columnIndex is so that the columnIndex will be less by 1
            row.children[columnIndex].remove()
          }
          //5.     End loop
        }
        //6.  End if
      }
      //7.  If this is the editor
      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = table.id
        img.src = `/inline/column-delete-${editorColor}.png`
        img.height = 12 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', edit.type)
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)

        if (editPerson) {
          //8.    Put the icon at the top of the new column to the left of the columnIndex - StartElementId
          let target = table.children[0]
          const index = columnIndex - 1
          target = target.children[index]
          target.insertBefore(img, target.firstChild)
        //9.  Else
        } else {
          //10.    Put the icon at the top of the columnIndex (but just in case that column doesn't exist on the first one, we need to find the first column in the table that matches that column index)
          for (let i = 0; i < table.children.length; i++) {
            let row = table.children[i]
            if (row.children.length >= columnIndex + 1) { //Remember that the length is not zero-based but the columnIndex is so that the columnIndex will be less by 1
              let target = row.children[columnIndex]
              target.insertBefore(img, target.firstChild)
              break
            }
          }
          //11.  End if
        }
      }
      //12.End loop
    }
  })
}

export const addTableCellEdit = ({
  addOrUpdateEdit,
  addOrUpdateEditAddList,
  chapterId,
  columnIndex,
  editorName,
  getWorkEditReviewFilled,
  handleSetChosenSegment,
  isAuthor,
  languageId,
  personId,
  rowIndex,
  suffixId,
  tableElement,
}) => {
  //0. If this is the ADDTABLE that belongs to the editor
  //1.   Take the index of the column and the index of the row and loop through the rows of the table.
  //2.     Create the new TD and SPAN with ids on the rowIndex
  //3.     If there is a current TD at the given index 
  //4.       Insert the new TD and SPAN after the current TD
  //5.     Else, 
  //6.       Append the new TD and SPAN at the end of the TR.
  //7.     End if
  //8.     If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
  //9.   End loop
  //10. Else - It is an editor's table
  //11. Take the index of the column and loop through the rows of the table.
  //12.   Create the new TD and SPAN with ids
  //13.   If there is a current TD at the given index 
  //14.     Insert the new TD and SPAN after the current TD
  //15.   Else, 
  //16.     Append the new TD and SPAN at the end of the TR.
  //17.   End if
  //18.   If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
  //19. end of loop
  //20.If this is the editor record the addOrUpdateEditor record with the addlistEntriesReceive records
  //      We will save the columnIndex in StartElementId

  let firstSpan
  let addListEntriesReceive = []
  let nextId = editorService.getNextId(null, chapterId)
  let sequence = 1

  //1. If this is the ADDTABLE that belongs to the editor
  if (tableElement?.dataset.type === 'ADDTABLE') { //This is the editor's table that she added. But we still want to add some columns for her.
    //2. Take the index of the column and loop through the rows of the table.
    for (let i = 0; i < tableElement.children.length; i++) {
      //3. When we find the rowIndex 
      if (i === rowIndex) {
        const row = tableElement.children[i]
        //4.   Create the new TD and SPAN with ids (but don't insert it yet until we know if we need to create fillerCells to get there\)
        const { cell, span } = createTdSpanPairNew(row, firstSpan, nextId, sequence, addListEntriesReceive, 'ADDTABLECELL')
        const newCell = cell
        if (!firstSpan) firstSpan = span //This is for setting the cursor in the first cell created for the user to continue to type.
        nextId += 2
        sequence += 2

        //5.   If there is a current TD at the given index (If there are td's that are missing, then add the needed TD/SPAN pairs until it reaches the index that we will fill in.)
        if (row.children.length - 1 > columnIndex) {
          //6.     Insert the new TD and SPAN after the current TD
          row.insertBefore(newCell, row.children[columnIndex].nextSibling)
          //7.   Else, 
        } else {
          //8.     Append the new TD and SPAN at the end of the TR.
          const missingCells = columnIndex - (row.children.length - 1)

          for (let count = 0; count < missingCells; count++) {
            const { cell } = createTdSpanPairNew(row, firstSpan, nextId, sequence, addListEntriesReceive, 'ADDTABLECELL')
            const fillerCell = cell
            nextId += 2
            sequence += 2
            row.append(fillerCell)
          }
          row.append(newCell)
          //9.   End if
        }
      }
      //10.   If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
      //11. end of loop
    }
    const saveSegments = gatherAddTableSegmentsToSave(tableElement.dataset.editSegmentId, suffixId, 'ADDTABLE')
    addOrUpdateEditAddList(saveSegments)

    //12. Else - It is an editor's table
  } else {
    //13. Take the index of the column and loop through the rows of the table.
    for (let i = 0; i < tableElement.children.length; i++) {
      //3. When we find the rowIndex 
      if (i === rowIndex) {
        const row = tableElement.children[i]
        //12.   Create the new TD and SPAN with ids
        const { cell, span } = createTdSpanPairNew(row, firstSpan, nextId, sequence, addListEntriesReceive, 'ADDTABLECELL')
        const newCell = cell
        if (!firstSpan) firstSpan = span //This is for setting the cursor in the first cell created for the user to continue to type.
        nextId += 2
        sequence += 2

        //13.   If there is a current TD at the given index (If there are td's that are missing, then add the needed TD/SPAN pairs until it reaches the index that we will fill in.)
        if (row.children.length - 1 > columnIndex) {
          //14.     Insert the new TD and SPAN after the current TD
          row.insertBefore(newCell, row.children[columnIndex].nextSibling)
          //15.   Else, 
        } else {
          //16.     Append the new TD and SPAN at the end of the TR.
          const missingCells = columnIndex - (row.children.length - 1)

          for (let count = 0; count < missingCells; count++) {
            const { cell } = createTdSpanPairNew(row, firstSpan, nextId, sequence, addListEntriesReceive, 'ADDTABLECELL')
            const fillerCell = cell
            nextId += 2
            sequence += 2
            row.append(fillerCell)
          }
          row.append(newCell)
          //17.   End if
        }
      }
      //18.   If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
      //19. end of loop
    }
    //20.If this is the editor record the addOrUpdateEditor record with the addlistEntriesReceive records
    //      We will save the columnIndex in StartElementId
    if (!isAuthor) {
      addOrUpdateEdit({
        elementId: Number(tableElement.id),
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        firstName: editorName?.firstName,
        lastName: editorName?.lastName,
        personId,
        chapterId,
        languageId,
        startElementId: columnIndex,
        precedingStartElementId: rowIndex,
        text: '',
        type: 'ADDTABLECELL',
        addListEntriesReceive,
      }, () => {
        setTimeout(() => getWorkEditReviewFilled(), 500);
        setTimeout(() => {
          const editorDiv = document.getElementById(`editorDiv${suffixId}`)
          if (editorDiv) {
            let newSpan = document.querySelector(`span[id="${firstSpan.id}"]`)
            if (newSpan) {
              editorService.setCursorPosition(newSpan, newSpan, 0, 0)
              handleSetChosenSegment(newSpan)
            }
          }
        }, 2000)
      })
    }
  }
}

//ADDTABLECELL
export const setAddTableCellAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId) => {
  //1. Loop through the ADDTABLECELL edits
  //2.   If this is the editor
  //3.     Loop through the given table's rows
  //4.       When we find the rowIndex 
  //5.       If the row's children (TDs) goes past the columnIndex (StartElementId, in this case)
  //6.         insert the new column after the next TD of the columnIndex
  //7.       Else
  //8.         Insert the filler columns one after another until the final cell is inserted
  //9.       End if 
  //10.     End loop
  //11.  End if
  //12.  If this is the editor
  //13.    Put the icon at the top of the new column (to the right of the columnIndex - StartElementId)
  //14.  Else
  //15.    Put the icon at the top of the columnIndex (but just in case that column doesn't exist on the first one, we need to find the first column in the table that matches that column index)
  //16.  End if
  //17. End loop

  let addTableEdits = edits?.length > 0 && edits.filter(e => e.type === 'ADDTABLECELL')
  //1. Loop through the ADDTABLECELL edits
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const tableElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const table = document.querySelector(`table[id="${tableElementId}"]`)
    const columnIndex = edit.startElementId
    const rowIndex = edit.precedingStartElementId
    if (table && table.nodeName === 'TABLE') {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')
      const ifTabView = divDisplayId.indexOf('tabView') > -1 ? `~tabView${editorService.getSuffixIdFromEditorDivId(divDisplayId)}` : ''
      //2.   If this is the editor
      if (editPerson) {
        //3.     Loop through the given table's rows
        for (let i = 0; i < table.children.length; i++) {
          //4. When we find the rowIndex 
          if (i === rowIndex) {
            let row = table.children[i]
            //5.       If the row's children (TDs) goes past the columnIndex (StartElementId, in this case)
            if (row.children.length > columnIndex + 1) { //Remember that the length is not zero-based but the columnIndex is so that the columnIndex will be less by 1
              //6.         insert the new column after the next TD of the columnIndex
              const cells = edit.addListEntries.filter(m => Number(m.parentElementId) === Number(row.id))
              cells?.length > 0 && cells.forEach(cell => { //There really should only be one.
                const span = edit.addListEntries.filter(m => Number(m.parentElementId) === Number(cell.addListElementId))[0]
                const newCell = insertTdSpanPairFromEdit(edit, cell, span, ifTabView, 'ADDTABLECELL')
                row.insertBefore(newCell, row.children[columnIndex + 1])
              })
              //7.       Else
            } else {
              //8.         Insert the filler columns one after another until the final cell is inserted
              const cells = edit.addListEntries.filter(m => Number(m.parentElementId) === Number(row.id))
              cells?.length > 0 && cells.forEach(cell => {
                const span = edit.addListEntries.filter(m => Number(m.parentElementId) === Number(cell.addListElementId))[0]
                const newCell = insertTdSpanPairFromEdit(edit, cell, span, ifTabView, 'ADDTABLECELL')
                row.append(newCell)
              })
              //9.       End if 
            }
          }
          //10.     End loop
        }
        //11.  End if
      }
      //12.  If this is the editor
      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = table.id
        img.src = `/inline/cell-add-${editorColor}.png`
        img.height = 12 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', edit.type)
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)

        if (editPerson) {
          //13.    Put the icon at the top of the new column (to the right of the columnIndex - StartElementId)
          const row = table.rows[rowIndex];
          const cell = row.children[columnIndex+1]
          cell.insertBefore(img, cell.firstChild);
          //14.  Else
        } else {
          //15.    Put the icon at the top of the columnIndex 
          const row = table.rows[rowIndex];
          const cell = row.children[columnIndex]
          cell.insertBefore(img, cell.firstChild);
        //16.  End if
        }
      }
      //17. End loop
    }
  })
}

export const gatherCellAddTableSegmentsToSave = (editSegmentId, suffixId) => {
  //We look for the TD record as the parent and then save recursively 
  let saveSegments = []
  globalAddTableSequence = 1
  const firstElementId = `${suffixId}0001`
  const mainDiv = document.getElementById(firstElementId)
  const elements = mainDiv.querySelectorAll(`td[data-edit-segment-id="${editSegmentId}"]`)
  elements.length > 0 && elements.forEach(element => {
    saveAddTableElementChildren(element, saveSegments, editSegmentId, suffixId)
  })
  return saveSegments
}

export const deleteTableCellEdit = ({
  addOrUpdateEdit,
  addOrUpdateEditAddList,
  chapterId,
  columnIndex,
  editorName,
  getWorkEditReviewFilled,
  isAuthor,
  languageId,
  personId,
  rowIndex,
  suffixId,
  tableElement,
}) => {
  //1. If this is the ADDTABLE that belongs to the editor
  //2. Take the index of the column and loop through the rows of the table.
  //3. When we find the rowIndex 
  //4.   If there is a current TD at the given index, remove it
  //5. end of loop
  //6. Else - It is an editor's table
  //7. Take the index of the column and loop through the rows of the table.
  //8. When we find the rowIndex 
  //9.   If there is a current TD at the given index, remove it
  //10.   If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
  //11. end of loop
  //12.If this is the editor record the addOrUpdateEditor record with the addlistEntriesReceive records
  //      We will save the columnIndex in StartElementId

  //1. If this is the ADDTABLE that belongs to the editor
  if (tableElement?.dataset.type === 'ADDTABLE') { //This is the editor's table that she added. But we still want to add some columns for her.
    //2. Take the index of the column and loop through the rows of the table.
    for (let i = 0; i < tableElement.children.length; i++) {
      //3. When we find the rowIndex 
      if (i === rowIndex) {
        const row = tableElement.children[i]
        //4.   If there is a current TD at the given index, remove it
        if (row.children.length - 1 > columnIndex) {
          row.children[columnIndex].remove()
        }
      }
      //5. end of loop
    }
    const saveSegments = gatherAddTableSegmentsToSave(tableElement.dataset.editSegmentId, suffixId, 'ADDTABLE')
    addOrUpdateEditAddList(saveSegments)

    //6. Else - It is an editor's table
  } else {
    //7. Take the index of the column and loop through the rows of the table.
    for (let i = 0; i < tableElement.children.length; i++) {
      //8. When we find the rowIndex 
      if (i === rowIndex) {
        const row = tableElement.children[i]
        //9.   If there is a current TD at the given index, remove it
        if (row.children.length - 1 > columnIndex) {
          row.children[columnIndex].remove()
        }
      }
      //10.   If this is the editor, record the TD and SPAN in the addlistEntriesReceive record in order to maintain changes as freestyle for the editor.
      //11. end of loop
    }
    //12.If this is the editor record the addOrUpdateEditor record with the addlistEntriesReceive records
    //      We will save the columnIndex in StartElementId
    if (!isAuthor) {
      addOrUpdateEdit({
        elementId: Number(tableElement.id),
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        firstName: editorName?.firstName,
        lastName: editorName?.lastName,
        personId,
        chapterId,
        languageId,
        startElementId: columnIndex,
        precedingStartElementId: rowIndex,
        text: '',
        type: 'DELETETABLECELL',
      }, () => setTimeout(() => getWorkEditReviewFilled(), 500))
    }
  }
}

//DELETETABLECELL
export const setDeleteTableCellAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId) => {
  //1. Loop through the DELETETABLECELL edits
  //2.   If this is the editor
  //3.     Loop through the given table's rows
  //4. When we find the rowIndex
  //5.       If the row's children (TDs) goes past the columnIndex (StartElementId, in this case)
  //6.     End loop
  //7.  End if
  //8.  If this is the editor
  //9.    If there is a cell to the left, put the icon there.
  //      else if there is a cell to the right, put the icon there.
  //      else if there is a row above with a cell, put the icon there.
  //      else put the icon at the top of the table.
  //10.  Else
  //11.    Put the icon at the top of the columnIndex
  //12.  End if
  //13. End loop

  let addTableEdits = edits?.length > 0 && edits.filter(e => e.type === 'DELETETABLECELL')
  //1. Loop through the DELETETABLECELL edits
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const tableElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const table = document.querySelector(`table[id="${tableElementId}"]`)
    const columnIndex = edit.startElementId
    const rowIndex = edit.precedingStartElementId
    if (table && table.nodeName === 'TABLE') {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')
      const ifTabView = divDisplayId.indexOf('tabView') > -1 ? `~tabView${editorService.getSuffixIdFromEditorDivId(divDisplayId)}` : ''
      //2.   If this is the editor
      if (editPerson) {
        //3.     Loop through the given table's rows
        for (let i = 0; i < table.children.length; i++) {
          //4. When we find the rowIndex 
          if (i === rowIndex) {
            let row = table.children[i]
            //5.       If the row's children (TDs) goes past the columnIndex (StartElementId, in this case)
            if (row.children.length > columnIndex + 1) { //Remember that the length is not zero-based but the columnIndex is so that the columnIndex will be less by 1
              row.children[columnIndex].remove()
            }
          }
          //6.     End loop
        }
        //7.  End if
      }
      //8.  If this is the editor
      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = table.id
        img.src = `/inline/cell-delete-${editorColor}.png`
        img.height = 12 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', edit.type)
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)

        if (editPerson) {
          //9.    If there is a cell to the left, put the icon there. 
          //      else if there is a cell to the right, put the icon there. 
          //      else if there is a row above with a cell, put the icon there.
          //      else put the icon at the top of the table.
          const row = table.rows[rowIndex]
          let targetCell
          if (columnIndex > 0) {
            targetCell = row.children[columnIndex - 1]
            targetCell.insertBefore(img, targetCell.firstChild);
          } else if (columnIndex === 0) {
            targetCell = row.children[columnIndex] //Because the cell is deleted so that the new one will be in its place with columnIndex 0.
            targetCell.insertBefore(img, targetCell.firstChild);
          } else if (rowIndex > 0) {
            targetCell = table.rows[rowIndex-1].children[0]
            targetCell.insertBefore(img, targetCell.firstChild);
          } else {
            table.parentElement.insertBefore(img, table);
          }

          //10.  Else
        } else {
          //11.    Put the icon at the top of the columnIndex
          const row = table.rows[rowIndex];
          const cell = row.children[columnIndex]
          cell.insertBefore(img, cell.firstChild);
          //12.  End if
        }
      }
      //13. End loop
    }
  })
}

