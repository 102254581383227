import React, { useState, useEffect } from 'react';
import styles from './AssessmentResponseTypeRequired.module.css';
import classes from 'classnames'

function AssessmentResponseTypeRequired(props) {
  const {
    question,
  } = props

  const [answerOptions, setAnswerOptions] = useState()

  useEffect(() => {
    let answerTypes = []
    // if (question.responseTypeRequired?.['text'] === 'yes') {
    //   answerTypes.push('Writing')
    // }
    if (question.responseTypeRequired?.['camera'] === 'yes') {
      answerTypes.push('Camera picture')
    }
    if (question.responseTypeRequired?.['upload'] === 'yes') {
      answerTypes.push('File upload')
    }
    if (question.responseTypeRequired?.['audio'] === 'yes') {
      answerTypes.push('Audio recording')
    }
    if (question.responseTypeRequired?.['video'] === 'yes') {
      answerTypes.push('Video recording')
    }
    setAnswerOptions(answerTypes)
  }, [question])

  return (
    <div className={classes(styles.row, styles.moreLeft)}>
      {answerOptions?.length > 0 && 
        <div className={styles.instructions}>
          To answer this question, additional response types are required:
          {answerOptions?.length > 0 && answerOptions.map((entry, index) => 
            <div className={styles.instructionsBold} key={index}>
              {entry}
            </div>
          )}
        </div>
      }
    </div>
  )
};

export default AssessmentResponseTypeRequired