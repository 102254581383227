import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import styles from './AssessmentCorrectSummaryView.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import EditTableStickyLeft from '../../../components/EditTableStickyLeft'
import TextDisplay from '../../../components/TextDisplay'
import Icon from '../../../components/Icon'
import OneFJefFooter from '../../../components/OneFJefFooter'
import classes from 'classnames'
import { createInfoToastAuto } from '../../../services/queryClient.js'
import { useMediaQuery } from "react-responsive"

function AssessmentCorrectSummaryView(props) {
  const {
    assignmentId,
    assessmentId,
    correctSummary,
    assessmentQuestions,
  } = props

  const navigate = useNavigate()
  const componentRef = useRef(null)
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [finalHeadings, setFinalHeadings] = useState([])
  const [finalData, setFinalData] = useState([])

  const sendToCorrectView = (studentPersonId) => {
    navigate(`/assessmentCorrect/${assignmentId}/${assessmentId}/${studentPersonId}`)
  }

  const handlePathLink = (path) => path && navigate(path)

  const setHeaderLabel = (question) => {
    return (
      <div className={styles.rowLink}>
        <div className={styles.notBold}>{`${question.sequence} - ${question.questionTypeName}`}</div>
        {question.questionTypeCode === 'ESSAY' &&
          <div className={styles.rowSpaceLeft}>
            <Icon pathName={'pencil0'} fillColor='white' />
            <div className={styles.editAllLink}>edit all</div>
          </div>
        }
      </div>
    )
  }

  const hasCompletedAssessment = (personId) => {
    const hasPersonCompleted = correctSummary?.studentsCompleted?.length > 0 && correctSummary?.studentsCompleted.filter(m => m.id === personId)[0]
    return hasPersonCompleted && hasPersonCompleted.id ? true : false
  }

  const displayStatusIconOrTotal = (memberId) => { 
    const isCompleted = correctSummary?.studentsCompleted?.length > 0 && correctSummary.studentsCompleted.filter(f => f.id === memberId)[0]
    if (isCompleted) {
      if (isCompleted.sum > 0) {
        return <div className={styles.link}>{isCompleted.sum}</div>
      } else {
        return <Icon pathName={'clipboard_check'} premium={true} fillColor={'lightgreen'} />
      }
    } else {
      return <Icon pathName={'clock2'} premium={true} fillColor={'#ff0000'} />
    }
  }

  useEffect(() => {
    let headings = []

    assessmentQuestions?.length > 0 && assessmentQuestions.forEach(m =>
      headings.push({
        label: setHeaderLabel(m),
        pathLink: m.questionTypeCode === 'ESSAY'
          ? `/editReview/${correctSummary.workId}/${correctSummary.chapterId}/${correctSummary.languageId}/${m.assessmentQuestionId}`
          : `/assessmentCorrectSameAll/${assignmentId}/${assessmentId}/${m.assessmentQuestionId}`,
        tightText: true
      })
    )

    let data = []

    correctSummary?.allGroupMembers?.length > 0 && correctSummary.allGroupMembers.forEach(member => {
      let row = [{
        cellColor: 'background',
        value: <div className={styles.rowSpace}>
          <div className={styles.row}>
            <div
              className={classes(styles.link, styles.row)}
              onClick={hasCompletedAssessment(member.id)
                ? () => sendToCorrectView(member.id)
                : () => createInfoToastAuto('<div>The chosen student does not have a completed assessment.</div>')
              }
            >
              {member.label}
            </div>
            <div className={styles.moreLeft}>
              {displayStatusIconOrTotal(member.id)}
            </div>
          </div>
        </div>
      }]
      const completed = correctSummary?.studentsCompleted?.length > 0 && correctSummary.studentsCompleted.filter(d => d.id === member.id)[0]
      if (completed && completed.corrected?.length > 0) {
        const corrected = [...completed.corrected].sort((a, b) => a.sequence - b.sequence)
        corrected.forEach(c => {
          let score = c.score
          row.push({
            value: score ? score == 0 ? '0' : score : c.pendingCorrection ? '--' : '0',
            clickFunction: hasCompletedAssessment(c.personId)
              ? () => navigate(`/assessmentCorrect/${assignmentId}/${assessmentId}/${c.personId}`)
              : () => createInfoToastAuto('<div>The chosen student does not have a completed assessment.</div>')
          })
        })
      }
      if (!completed?.corrected?.length) {
        assessmentQuestions?.length > 0 && assessmentQuestions.forEach(m => {
          row.push({ value: '--' })
        })
      }
      data.push(row)
    })

    setFinalHeadings(headings)
    setFinalData(data)
  }, [assessmentQuestions])

  const sendBackToSetup = () => {
    navigate(`/assessmentQuestions/${assessmentId}`)
  }

  return (
    <div className={styles.container}>
      <div className={classes(styles.mainDiv, styles.backgroundTopSpace)}>
        <div className={classes(globalStyles.pageTitle, styles.moreMarginBottom, styles.whiteText)}>
          Assessment Correction Summary
        </div>
        <TextDisplay 
          label='Assignment' 
          whiteText
          text={<div className={styles.rowLinkPlain} onClick={sendBackToSetup}>
                  {correctSummary.assignmentTitle}
                  <div className={styles.editLink}>edit</div>
                </div>} />
        <div className={styles.tableMargin} ref={componentRef}>
          <EditTableStickyLeft
            data={finalData}
            firstColumnClass={styles.firstColumnClass}
            headings={finalHeadings}
            isMobile={isMobile}
            labelClass={styles.tableLabelClass}
            sendToReport={handlePathLink} />
        </div>
      </div>
      <OneFJefFooter />
    </div>
  )
}

export default AssessmentCorrectSummaryView
