import React, {useEffect, useState} from 'react';
import styles from './HeadlessSelectSymbolInsert.module.css';
import HeadlessSelectList from '../HeadlessSelectList'
import InputText from '../InputText'
import Checkbox from '../Checkbox'
import Icon from '../Icon'
import HtmlCharacterEntity from "../../assets/Edit Icons/HtmlCharacterEntity.png";

const HeadlessSelectSymbolInsert = ({ 
	handleHtmlCharacterInsert,
	htmlCharacterOptions,
 }) => {

	const [htmlCharactersFiltered, setHtmlCharactersFiltered] = useState()
	const [searchHtmlCharacters, setSearchHtmlCharacters] = useState()
	const [charactersChosenFavorites, setCharactersChosenFavorites] = useState([])
	const [showCharacterFilters, setShowCharacterFilters] = useState(false)
	const [characterFilters, setCharacterFilters] = useState('')

	useEffect(() => {
		let charactersChosen = localStorage.getItem('htmlCharacterEntitiesChosen')
		if (charactersChosen) setCharactersChosenFavorites(JSON.parse(charactersChosen))
		let htmlCharacterFilters = localStorage.getItem('characterFilters')
		if (htmlCharacterFilters) {
			setCharacterFilters(htmlCharacterFilters)
		} else {
			setCharacterFilters('Bullet Regular ')
			localStorage.setItem('characterFilters', 'Bullet Regular ')
		}
	}, [])

	useEffect(() => {
		if (htmlCharacterOptions?.length > 0) {
			var newSearch = []
			if (characterFilters) {
				const filters = characterFilters.trim().split(/\s+/)
				htmlCharacterOptions.forEach(option => {
					let foundMatch = false
					filters.forEach(filter => {
						if (option.tag.indexOf(filter) > -1) {
							foundMatch = true
						}
					})
					const alreadyEntered = newSearch.filter(m => m.id === option.id)[0]
					if (foundMatch && !alreadyEntered) newSearch.push(option)
				})
			}
			if (searchHtmlCharacters) {
				newSearch = newSearch.filter(m => m.description.toLowerCase().indexOf(searchHtmlCharacters.toLowerCase()) > -1 || m.id.indexOf(searchHtmlCharacters.toLowerCase()) > -1)
			}
			setHtmlCharactersFiltered(newSearch)
		}
	}, [searchHtmlCharacters, htmlCharacterOptions, characterFilters])

	const chooseCharacter = (newCharacter) => {
    let newChosen = charactersChosenFavorites?.length > 0 ? [...charactersChosenFavorites] : []
    let existChosen = newChosen.filter(character => character === newCharacter)[0]
    if (!existChosen) {
      newChosen.push(newCharacter)
      setCharactersChosenFavorites(newChosen)
      localStorage.setItem('htmlCharacterEntitiesChosen', JSON.stringify(newChosen))
    }
    handleHtmlCharacterInsert(newCharacter)
	}

	const deleteCharacter = (newCharacter) => {
		let newChosen = charactersChosenFavorites?.length > 0 ? [...charactersChosenFavorites] : []
		newChosen = newChosen.filter(character => character !== newCharacter)
		setCharactersChosenFavorites(newChosen)
		handleHtmlCharacterInsert(newChosen)
		localStorage.setItem('htmlCharacterEntitiesChosen', JSON.stringify(newChosen))
	}

	const handleCharacterFilter = (filterOption) => {
		let newFilters = characterFilters
		if (newFilters.indexOf(filterOption) > -1) {
			newFilters = newFilters.replace(filterOption, '')
		} else {
			newFilters = newFilters + ' ' + filterOption
		}
		setCharacterFilters(newFilters)
		localStorage.setItem('characterFilters', newFilters)
	}

	return (
		<HeadlessSelectList key={'HtmlCharacters'}
			header={<div className={styles.listHeader}>Symbol insert</div>}
			iconAvatar={<img src={HtmlCharacterEntity} className={styles.logo} alt={'edit'} title={'Html characters'} />}
			onSelect={chooseCharacter}
			listWidth={'290px'}
			addClassName={styles.fixedWidthSelect}
			listOptions={htmlCharactersFiltered}>
			<div style={{ width: '250px', margin: '10px 5px 10px 5px' }}>
				<InputText
					size={'medium'}
					name={'searchHtmlCharacters'}
					label={'Search'}
					value={searchHtmlCharacters}
					onChange={(event) => setSearchHtmlCharacters(event.target.value)} />
				<div style={{ width: '240px' }}>
					{charactersChosenFavorites?.length > 0 && <div className={styles.labelSpace}>Favorites</div>}
					{charactersChosenFavorites?.length > 0 && charactersChosenFavorites.map((character, i) => {
						const option = htmlCharacterOptions.filter(m => m.id === character)[0]
						return (
							<div className={styles.rowSpace} key={i}>
								<div dangerouslySetInnerHTML={{ __html: character + " " + option?.description }}
									onClick={() => chooseCharacter(character)}
									style={{ cursor: 'pointer', fontSize: '11pt', marginLeft: '7px' }} />
								<div className={styles.deleteTrash} onClick={() => deleteCharacter(character)}>
									<Icon pathName={'trash2'} premium={true} fillColor={'red'} />
								</div>
							</div>
						)
					})}
					{charactersChosenFavorites?.length > 0 && <hr />}
				</div>
				<div style={{ width: '220px' }}>
					<div className={styles.row}>
						<div>Filters {characterFilters.trim().split(/\s+/).length || 0}/5</div>
						<div onClick={() => setShowCharacterFilters(!showCharacterFilters)} className={styles.showHideFilter}> {showCharacterFilters ? 'hide' : 'show'}</div>
					</div>
					{showCharacterFilters &&
						<div>
							<Checkbox
								label={`Regular`}
								checked={characterFilters.indexOf('Regular') > -1}
								onClick={() => handleCharacterFilter('Regular')} />
							<Checkbox
								label={`Bullets`}
								checked={characterFilters.indexOf('Bullet') > -1}
								onClick={() => handleCharacterFilter('Bullet')} />
							<Checkbox
								label={`Extended bullets`}
								checked={characterFilters.indexOf('ExtendedBull') > -1}
								onClick={() => handleCharacterFilter('ExtendedBull')} />
							<Checkbox
								label={`Latin`}
								checked={characterFilters.indexOf('Latin') > -1}
								onClick={() => handleCharacterFilter('Latin')} />
							<Checkbox
								label={`Cyrillic`}
								checked={characterFilters.indexOf('Cyrillic') > -1}
								onClick={() => handleCharacterFilter('Cyrillic')} />
						</div>
					}
					{charactersChosenFavorites?.length > 0 && <hr />}
				</div>
			</div>
		</HeadlessSelectList>
	)
};

export default HeadlessSelectSymbolInsert