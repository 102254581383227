import React, { useEffect } from 'react'
import AssessmentQuestionsView from '../views/Assessment/AssessmentQuestionsView'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionAssessment from '../actions/assessment.js'
import * as actionQuestionTypes from '../actions/question-types.js'
import * as actionWorks from '../actions/works.js'
import {
  selectMe,
  selectAssessmentQuestions,
  selectAssessment,
  selectListLevelGeneral,
  selectQuestionTypes,
  selectVoiceRecording,
} from '../store.js'

// takes values from the redux store and maps them to props
const mapStateToProps = (state) => {
  let me = selectMe(state)
  let assessment = selectAssessment(state)
  let assessmentQuestions = selectAssessmentQuestions(state)
  let sequenceCount = assessmentQuestions && assessmentQuestions.length
  let sequences = []
  for (let i = 1; i <= sequenceCount; i++) {
    let option = { id: String(i), value: String(i), label: String(i) }
    sequences = sequences ? sequences.concat(option) : [option]
  }
  let retakeOptions = []
  for (let i = 0; i <= 10; i++) {
    let option = { id: String(i), value: String(i), label: String(i) }
    retakeOptions = retakeOptions ? retakeOptions.concat(option) : [option]
  }

  return {
    assessment,
    assessmentQuestions,
    isTeacher: assessment.personId === me.personId,
    langCode: me.langCode,
    listLevelGeneral: selectListLevelGeneral(state),
    userPersonId: me.personId,
    questionTypes: selectQuestionTypes(state),
    retakeOptions,
    sequences,
    voiceRecording: selectVoiceRecording(state),
  }
}

const bindActionsToDispatch = dispatch => ({
  getAssessment: (personId, assessmentId) => dispatch(actionAssessment.getAssessment(personId, assessmentId)),
  getAssessmentQuestions: (studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentQuestions.getAssessmentQuestions(studentPersonId, assessmentId, assignmentId)),
  removeAssessmentQuestion: (personId, assessmentId, assessmentQuestionId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestion(personId, assessmentId, assessmentQuestionId)),
  reorderAssessmentQuestions: (personId, assessmentQuestionId, newSequence) => dispatch(actionAssessmentQuestions.reorderAssessmentQuestions(personId, assessmentQuestionId, newSequence)),
  togglePublishedAssessment: (personId, assessmentId) => dispatch(actionAssessment.togglePublishedAssessment(personId, assessmentId)),
  updateAssessmentTotalPoints: (personId, assessmentQuestionId, subTotalPoints) => dispatch(actionAssessment.updateAssessmentTotalPoints(personId, assessmentQuestionId, subTotalPoints)),
  getQuestionTypes: (personId) => dispatch(actionQuestionTypes.init(personId)),
  updateAssessmentSettings: (personId, assessmentId, field, value) => dispatch(actionAssessment.updateAssessmentSettings(personId, assessmentId, field, value)),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
  generateAiQuizAssessmentQuestions: (assessmentId, quizContent, runFunction) => dispatch(actionAssessmentQuestions.generateAiQuizAssessmentQuestions(assessmentId, quizContent, runFunction)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const {
    getAssessmentQuestions,
    assignmentId,
    getAssessment,
    getListLevelGeneral,
    getQuestionTypes,
    userPersonId,
  } = props

  const params = useParams()

  useEffect(() => {
    if (userPersonId, params.assessmentId) {
      getAssessmentQuestions(userPersonId, params.assessmentId, assignmentId)
      getAssessment(userPersonId, params.assessmentId)
      getQuestionTypes(userPersonId)
      getListLevelGeneral()
    }
  }, [userPersonId, params.assessmentId, assignmentId])

  return <AssessmentQuestionsView {...props} assessmentId={params.assessmentId} />
}

export default storeConnector(Container)
