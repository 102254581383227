import React from 'react';
import styles from './MarketingFrontPageSteps.module.css';
import classes from 'classnames';
import penspringSmall from "../../assets/logos/penspring_reg.png";

export default function MarketingFrontPageSteps(props) {
	const { isTablet } = props
	return (
		<div className={styles.container}>
      <div className={styles.centerAlignSubHeader}>
        Use it for free! <i>Go ahead!</i>
      </div>
			<div className={styles.row}>
        <div className={classes(styles.topAlign, isTablet ? styles.leftWidthMobile : styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>1</div>
					</div>
				</div>
				<div className={styles.rightPosition}>
          <div className={styles.segoeSmall}>You've got words.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; An important email</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; A document of any length</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; An extensive book with chapters</div>
				</div>
			</div>
			<div className={styles.row}>
				<div className={classes(styles.topAlign, isTablet ? styles.leftWidthMobile : styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>2</div>
					</div>
				</div>
				<div className={styles.rightPosition}>
          <div className={styles.segoeSmall}>An editor writes over the original text and their view changes accordingly.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Moved sentences</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Deleted sentences</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; A reordered list</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; A new paragraph break, etc.</div>
				</div>
			</div>
			<div className={styles.row}>
				<div className={classes(styles.topAlign, isTablet ? styles.leftWidthMobile : styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>3</div>
					</div>
				</div>
				<div className={styles.rightPosition}>
          <div className={styles.segoeSmall}>The author returns and still sees their original text but notices there are edit icons.</div>
				</div>
			</div>
			<div className={styles.row}>
				<div className={classes(styles.topAlign, isTablet ? styles.leftWidthMobile : styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>4</div>
					</div>
				</div>
				<div className={styles.rightPosition}>
          <div className={styles.segoeSmall}>Additional editors arrive to see the original text with the first edit icons.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Clicking on an edit icon shows the intended edit.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; The second editor can vote up or down the first editor's edit.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; The second editor can make additional edits of their own.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Other editor's entire version is available by clicking on that editor's tab at the top right.</div>
				</div>
			</div>
			<div className={styles.row}>
				<div className={classes(styles.topAlign, isTablet ? styles.leftWidthMobile : styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>5</div>
					</div>
				</div>
				<div className={styles.rightPosition}>
          <div className={styles.segoeSmall}>The author returns again to see her original text with refined edits found in the edit icons.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; The author may have more than one variation of a given sentence.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; The second editor can vote up or down the first editor's edit.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; The author accepts, adjusts, or rejects each edit type at her leisure.</div>
				</div>
			</div>
      <div className={styles.row}>
        <div className={classes(styles.topAlign, isTablet ? styles.leftWidthMobile : styles.leftWidth)}>
          <div className={classes(styles.rowRight, styles.rightAlignText)}>
            <div className={styles.stepText}>step</div>
            <div className={styles.stepNumber}>6</div>
          </div>
        </div>
        <div className={styles.rightPosition}>
          <div className={styles.segoeSmall}>An edit report shows the work stats of each editor.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Finished edits</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Pending edits</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Accepted</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Not accepted</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Votes up, down, and troll</div>
        </div>
      </div>
      <hr />
      <div className={styles.whoDoesThat}>Who Does That?!!</div>
      <div className={styles.whoDoesThat}><img className={styles.penspringLogoBiggest} src={penspringSmall} alt="penspring" />does. </div>
      <hr />
		</div>
	)
};
