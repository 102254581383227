import React from 'react';
import onefJef from '../../assets/logos/one-f Jef Logo.png';
import styles from './OneFJefFooter.module.css';
import { useMediaQuery } from 'react-responsive'

export default ({smallWidth, smallHeight, pushRight}) => {
	const isMobile = useMediaQuery({ query: '(max-width: 700px)' })

	return (
		<div className={pushRight ? styles.pushRight : smallHeight ? styles.sideNoteTight : styles.sideNote}>
			<div className={styles.row}>
				&copy; 2010 to 2024 
				<span style={{ fontFamily: 'Sylfaen', fontSize: '9px', marginTop: '0px', marginLeft: '6px', marginRight: '3px'}}>one-f</span>
				<span style={{ fontFamily: 'Sylfaen', fontSize: '12px', marginTop: '-2px', marginRight: '1px', }}>Jef</span>
				<span style={{ fontFamily: 'Sylfaen', fontSize: '12px', marginTop: '-3px', marginRight: '3px',  }}>&reg;</span>
				All rights reserved.
			</div>
			<div className={styles.reallySmall}>
				{(smallWidth || isMobile)
					? <div>one-f Jef is also known as Jef with one f.  Why?  Because how<br/>many f's does it take to make the f sound?</div>
					: `one-f Jef is also known as Jef with one f.  Why?  Because how many f's does it take to make the f sound?`
				}

			</div>
		</div>
	);
}
