import React, { useEffect, useState } from 'react'
import AssessmentCorrectSummaryView from '../views/Assessment/AssessmentCorrectSummaryView'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actionAssessmentCorrectSummary from '../actions/assessment-correct-summary.js'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
//import * as actionMyVisitedPages from '../actions/my-visited-pages.js'
import { 
  selectMe, 
  selectAssessmentCorrectSummary, 
  selectAssessmentQuestions, 
} from '../store.js'

const mapStateToProps = (state) => {
  let me = selectMe(state)
  let correctSummary = selectAssessmentCorrectSummary(state)

  return {
    personId: me.personId,
    correctSummary,
    correctDetails: correctSummary && correctSummary.details,
    assessmentQuestions: selectAssessmentQuestions(state),
  }
}

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
  getAssessmentCorrectSummary: (personId, assessmentId) => dispatch(actionAssessmentCorrectSummary.getAssessmentCorrectSummary(personId, assessmentId)),
  getAssessmentQuestions: (studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentQuestions.getAssessmentQuestions(studentPersonId, assessmentId, assignmentId)),
  //setMyVisitedPage: (personId, myVisitedPage) => dispatch(actionMyVisitedPages.setMyVisitedPage(personId, myVisitedPage)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const {
    getAssessmentQuestions,
    correctSummary,
    getAssessmentCorrectSummary,
    personId,
  } = props

  const params = useParams()
  const [isInit, setIsInit] = useState()

  useEffect(() => {
    if (!isInit && personId && params.assessmentId && params.assignmentId) {
      setIsInit(true)
      getAssessmentCorrectSummary(personId, params.assessmentId)
      getAssessmentQuestions(personId, params.assessmentId, params.assignmentId)
      //props.location && props.location.pathname && setMyVisitedPage(personId, { path: props.location.pathname, description: `Assessment Correction Summary` })
    }
  }, [personId, params.assessmentId, params.assignmentId])


  return !correctSummary || correctSummary?.length === 0 ? null : <AssessmentCorrectSummaryView {...props} assessmentId={params.assessmentId} assignmentId={params.assignmentId} />
}

export default storeConnector(Container)
