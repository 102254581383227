import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router'
//import ReactToPrint from "react-to-print"
import { createConfirmToastAuto, createInfoToastAuto } from '../../../services/queryClient.js'
import { guidEmpty } from '../../../utils/GuidValidate.js'
import AssessmentEssay from '../../../components/Assessment/AssessmentEssay'
import AssessmentFillBlank from '../../../components/Assessment/AssessmentFillBlank'
import AssessmentMatching from '../../../components/Assessment/AssessmentMatching'
import AssessmentMultipleAnswer from '../../../components/Assessment/AssessmentMultipleAnswer'
import AssessmentMultipleChoice from '../../../components/Assessment/AssessmentMultipleChoice'
import AssessmentPassage from '../../../components/Assessment/AssessmentPassage'
import AssessmentQuestionActionOptions from '../../../components/Assessment/AssessmentQuestionActionOptions'
import AssessmentTrueFalse from '../../../components/Assessment/AssessmentTrueFalse'
import ButtonWithIcon from '../../../components/ButtonWithIcon'
import CheckboxToggle from '../../../components/CheckboxToggle'
import classes from 'classnames'
import globalStyles from '../../../utils/globalStyles.module.css'
import Icon from '../../../components/Icon'
import MessageModal from '../../../components/MessageModal'
import OneFJefFooter from '../../../components/OneFJefFooter'
import SelectSingleDropDown from '../../../components/SelectSingleDropDown'
import styles from './AssessmentQuestionsView.module.css'
import TextDisplay from '../../../components/TextDisplay'

function AssessmentQuestionsView(props) {
  const {
    assessment = {},
    assessmentId,
    assessmentQuestions,
    benchmarkTestId,
    courseEntryName,
    isTeacher,
    userPersonId,
    removeAssessmentQuestion,
    reorderAssessmentQuestions,
    retakeOptions,
    removeAssessmentQuestionAnswerOption,
    sequences,
    togglePublishedAssessment,
    updateAssessmentSettings,
    updateAssessmentTotalPoints,
    listLevelGeneral,
  } = props

  const navigate = useNavigate()
  let componentRef = useRef(null)

  const [answerIndex, setAnswerIndex] = useState()
  const [assessmentQuestionId, setAssessmentQuestionId] = useState()
  const [isEditMode, setIsEditMode] = useState()
  const [isShowingModal_addOrUpdate, setIsShowingModal_addOrUpdate] = useState(false)
  const [isShowingModal_pointsError, setIsShowingModal_pointsError] = useState(false)
  const [isShowingModal_removeAnswerOption, setIsShowingModal_removeAnswerOption] = useState(false)
  const [replacedTotalPoints, setReplacedTotalPoints] = useState()
  const [subTotalPoints, setSubTotalPoints] = useState()

  const handleAddOrUpdateQuestionClose = () => {
    setIsShowingModal_addOrUpdate(false)
    setIsEditMode(false)
  }

  const handleRemoveQuestionOpen = (assessmentQuestionId) => {
    createConfirmToastAuto('<div>Are you sure you want to delete this assessment question?</div>', () => removeAssessmentQuestion(userPersonId, assessmentId, assessmentQuestionId))
  }

  const handleRemoveAnswerOptionClose = () => {
    setIsShowingModal_removeAnswerOption(false)
    setAssessmentQuestionId('')
    setAnswerIndex('')
  }

  const handleRemoveAnswerOption = () => {
    removeAssessmentQuestionAnswerOption(userPersonId, assessmentQuestionId, answerIndex) //, () => handleAddOrUpdateQuestionOpen(assessmentQuestionId, true)
    handleRemoveAnswerOptionClose()
    handleAddOrUpdateQuestionClose()
  }

  const handlePointsErrorClose = () => {
    setIsShowingModal_pointsError(false)
    togglePublishedAssessment(userPersonId, assessment.assessmentId)
  }

  const handlePointsErrorSave = () => {
    handlePointsErrorClose()
    updateAssessmentTotalPoints(userPersonId, assessmentId, subTotalPoints)
    togglePublishedAssessment(userPersonId, assessment.assessmentId)
    setReplacedTotalPoints(subTotalPoints)
  }

  const reorderSequence = (assessmentQuestionId, event) => {
    reorderAssessmentQuestions(userPersonId, assessmentQuestionId, event.target.value)
  }

  const handlePublish = () => {
    let pointsIntended = assessment && assessment.totalPoints
    let subTotalPoints = (assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.reduce((acc, m) => acc += m.pointsPossible, 0)) || 0
    if (pointsIntended !== subTotalPoints && (!assessment || !assessment.isPublished)) {
      let subTotalPoints = (assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.reduce((acc, m) => acc += m.pointsPossible, 0)) || 0
      setIsShowingModal_pointsError(true)
      setSubTotalPoints(subTotalPoints)
    }
    togglePublishedAssessment(userPersonId, assessment.assessmentId)
  }

  const showViewOnlyMessage = (assessmentQuestionId) => {
    assessment.isPublished
      ? createInfoToastAuto('<div>You are in the view-only page.<br/>This assessment has been published and cannot be changed.</div>')
      : createConfirmToastAuto('<div>You are in the view-only page.<br/>Do you want to change an assessment question or answer?</div>',
          () => navigate(`/assessmentItemSetup/${assessmentId}/${assessmentQuestionId}`))
  }

  const handleCannotChangeOpen = () => createInfoToastAuto(`<div>This quiz/test is published. Changes cannot be made.</div>`)

  const toggleCheckbox = (field, value) => {
    updateAssessmentSettings(userPersonId, assessmentId, field, value === 'yes')
  }

  const handleChange = (event) => {
    updateAssessmentSettings(userPersonId, assessmentId, event.target.name, event.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Assessment Questions
        </div>
        <div className={classes(styles.moreTop, styles.rowWrap)}>
          {courseEntryName &&
            <TextDisplay label={`Course`} text={courseEntryName} clickFunction={() => navigate(-1)} />}
          <TextDisplay label={benchmarkTestId ? 'Benchmark assessment' : 'Assessment'} text={assessment && assessment.name} />
          <TextDisplay label={'Points intended'} text={replacedTotalPoints || (assessment && assessment.totalPoints)} />
          <TextDisplay label={'Points total'} text={assessmentQuestions.reduce((acc, m) => acc += m.pointsPossible, 0)} />
          {/* <div className={styles.printPosition}>
            <ReactToPrint trigger={() => <a href="#"
              className={classes(styles.moveDownRight, styles.link, styles.row)}><Icon
                pathName={'printer'} premium={true} className={styles.icon} />Print</a>} content={() => componentRef} />
          </div> */}
        </div>
        <div className={styles.checkboxes}>
          <CheckboxToggle
            id={'bigTextDisplay'}
            label={'Big text display'}
            labelClass={styles.checkboxLabel}
            checked={assessment.bigTextDisplay ? 'yes' : 'no'}
            onClick={(checkboxValue) => toggleCheckbox('bigTextDisplay', checkboxValue)}
            personId={userPersonId} />
          <CheckboxToggle
            id={'oneAtAtimeView'}
            label={'One question at a time'}
            labelClass={styles.checkboxLabel}
            checked={assessment.oneAtAtimeView ? 'yes' : 'no'}
            onClick={(checkboxValue) => toggleCheckbox('oneAtAtimeView', checkboxValue)}
            personId={userPersonId} />
          <CheckboxToggle
            id={'showAnswersImmediately'}
            label={'Show answers to students (including immediately after students finish)'}
            labelClass={styles.checkboxLabel}
            checked={assessment.showAnswersImmediately ? 'yes' : 'no'}
            onClick={(checkboxValue) => toggleCheckbox('showAnswersImmediately', checkboxValue)}
            personId={userPersonId} />
          <CheckboxToggle
            id={'forceAllAnswers'}
            label={'Student must answer all questions'}
            labelClass={styles.checkboxLabel}
            checked={assessment.forceAllAnswers ? 'yes' : 'no'}
            onClick={(checkboxValue) => toggleCheckbox('forceAllAnswers', checkboxValue)}
            personId={userPersonId} />

          <div className={styles.littleLeft}>
            <SelectSingleDropDown
              id={`retakeCount`}
              name={`retakeCount`}
              label={'Re-take attempts'}
              value={assessment.retakeCount || ''}
              options={retakeOptions || []}
              className={styles.moreBottomMargin}
              noBlank={true}
              height={`super-small`}
              onChange={handleChange} />
          </div>
        </div>
        
        <div className={styles.rowMargin}>
          <div>Generate Quiz/Test with AI</div>
          <ButtonWithIcon icon={'earth'} label={'Next >'} onClick={() => navigate(`/assessmentAiGenerated/${assessmentId}`)} />
        </div>
        <div className={styles.rowMargin}>
          <ButtonWithIcon icon={'earth'} label={assessment.isPublished ?
            'Unpublish' : 'Publish'}
            changeRed={assessment.isPublished}
            onClick={handlePublish}
            disabled={!assessmentQuestions || !assessmentQuestions.length} />
        </div>
        <hr />
        <a onClick={assessment.isPublished ? showViewOnlyMessage : () => navigate(`/assessmentItemSetup/${assessmentId}/${guidEmpty}`)} className={classes(styles.row, styles.addNew)}>
          <Icon pathName={'plus_circle'} className={classes(styles.icon, (assessment.isPublished ? styles.lowOpacity : ''))} fillColor={'green'} premium={true}/>
          <span className={classes(styles.addAnother, (assessment.isPublished ? styles.lowOpacity : ''))}>
            {assessmentQuestions?.length > 0 ? 'Add another question' : 'Add the first question'}</span>
        </a>
        <div ref={componentRef} className={classes(styles.center, styles.componentPrint, styles.maxWidth)}>
          {assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.map((m, i) => {
            if (m.questionTypeCode === 'TRUEFALSE') {
              return (
                <div key={i}>
                  <AssessmentTrueFalse
                    assessmentQuestionId={m.assessmentQuestionId}
                    disabledMessage={() => showViewOnlyMessage(m.assessmentQuestionId)}
                    isTeacher={true}
                    mediaBinaries={m?.mediaBinaries}
                    nameKey={i}
                    userPersonId={userPersonId}
                    question={m} />
                  {isTeacher &&
                    <AssessmentQuestionActionOptions
                      assessmentQuestionId={m.assessmentQuestionId}
                      assessmentId={assessmentId}
                      handleCannotChangeOpen={handleCannotChangeOpen}
                      handleRemoveQuestionOpen={handleRemoveQuestionOpen}
                      isPublished={assessment.isPublished}
                      pointsPossible={m.pointsPossible}
                      reorderSequence={reorderSequence} 
                      sequence={m.sequence}
                      sequences={sequences} />
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'MULTIPLECHOICE') {
              return (
                <div key={i}>
                  <AssessmentMultipleChoice
                    assessmentQuestionId={m.assessmentQuestionId}
                    disabledMessage={() => showViewOnlyMessage(m.assessmentQuestionId)}
                    isTeacher={true}
                    mediaBinaries={m?.mediaBinaries}
                    nameKey={i}
                    question={m} 
                    userPersonId={userPersonId} />
                  {isTeacher &&
                    <AssessmentQuestionActionOptions
                      assessmentQuestionId={m.assessmentQuestionId}
                      assessmentId={assessmentId}
                      handleCannotChangeOpen={handleCannotChangeOpen}
                      handleRemoveQuestionOpen={handleRemoveQuestionOpen}
                      isPublished={assessment.isPublished}
                      pointsPossible={m.pointsPossible}
                      reorderSequence={reorderSequence}
                      sequence={m.sequence}
                      sequences={sequences} />
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'MULTIPLEANSWER') {
              return (
                <div key={i}>
                  <AssessmentMultipleAnswer
                    assessmentQuestionId={m.assessmentQuestionId}
                    disabledMessage={() => showViewOnlyMessage(m.assessmentQuestionId)}
                    isTeacher={true}
                    mediaBinaries={m?.mediaBinaries}
                    nameKey={i} 
                    question={m}
                    userPersonId={userPersonId} />
                  {isTeacher &&
                    <AssessmentQuestionActionOptions
                      assessmentQuestionId={m.assessmentQuestionId}
                      assessmentId={assessmentId}
                      handleCannotChangeOpen={handleCannotChangeOpen}
                      handleRemoveQuestionOpen={handleRemoveQuestionOpen}
                      isPublished={assessment.isPublished}
                      pointsPossible={m.pointsPossible}
                      reorderSequence={reorderSequence}
                      sequence={m.sequence}
                      sequences={sequences} />
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'ESSAY') {
              return (
                <div key={i}>
                  <AssessmentEssay
                    assessmentQuestionId={m.assessmentQuestionId}
                    isTeacher={true}
                    keywords={m.keywordPhrases}
                    listLevelGeneral={listLevelGeneral}
                    mediaBinaries={m?.mediaBinaries}
                    nameKey={i}
                    question={m}
                    userPersonId={userPersonId} />
                  <div className={styles.littleLeft}>
                    <div className={styles.instructions}>
                      {m.keywordPhrases && m.keywordPhrases.length > 0
                        ? m.keywordCountAccuracy
                          ? `These keywords and/or phrases are used for automated scoring.`
                          : `These keywords and/or phrases may be used for reference, but automated scoring will not occur without setting the keyword accuracy count.`
                        : `Automated scoring will not occur because keywords and/or phrases are not set.`
                      }
                    </div>
                    <div className={styles.rowWrap}>
                      {isTeacher &&
                        <TextDisplay
                          label={'Keywords or phrases'}
                          text={m.keywordPhrases && m.keywordPhrases.length > 0
                            ? m.keywordPhrases.map((keywordPhrase, i) =>
                              <div key={i} className={classes(styles.labelBold, styles.littleLeft)}>{keywordPhrase}</div>)
                            : <div key={i} className={classes(styles.labelItalicsGray, styles.littleLeft)}>none</div>
                          }
                        />
                      }

                      {isTeacher &&
                        <TextDisplay
                          label={'Keyword accuracy count'}
                          text={m.keywordCountAccuracy === 0 || !!m.keywordCountAccuracy
                            ? m.keywordCountAccuracy
                            : <div key={i}
                              className={classes(styles.labelItalicsGray, styles.littleLeft)}>{'n/a'}</div>
                          }
                        />
                      }
                    </div>
                  </div>
                  {isTeacher &&
                    <AssessmentQuestionActionOptions
                      assessmentQuestionId={m.assessmentQuestionId}
                      assessmentId={assessmentId}
                      handleCannotChangeOpen={handleCannotChangeOpen}
                      handleRemoveQuestionOpen={handleRemoveQuestionOpen}
                      isPublished={assessment.isPublished}
                      pointsPossible={m.pointsPossible}
                      reorderSequence={reorderSequence}
                      sequence={m.sequence}
                      sequences={sequences} />
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'FILLBLANK') {
              return (
                <div key={i}>
                  <AssessmentFillBlank
                    answerVariations={m.answerVariations}
                    isTeacher={true}
                    mediaBinaries={m?.mediaBinaries}
                    nameKey={i}
                    question={m} />
                  {isTeacher &&
                    <AssessmentQuestionActionOptions
                      assessmentQuestionId={m.assessmentQuestionId}
                      assessmentId={assessmentId}
                      handleCannotChangeOpen={handleCannotChangeOpen}
                      handleRemoveQuestionOpen={handleRemoveQuestionOpen}
                      isPublished={assessment.isPublished}
                      pointsPossible={m.pointsPossible}
                      reorderSequence={reorderSequence}
                      sequence={m.sequence}
                      sequences={sequences} />
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'MATCHING') {
              return (
                <div key={i}>
                  <AssessmentMatching
                    answers={m?.answers}
                    assessmentQuestionId={m.assessmentQuestionId}
                    isTeacher={true}
                    matches={m?.matches}
                    mediaBinaries={m?.mediaBinaries}
                    nameKey={i}
                    question={m}
                    userPersonId={userPersonId}
                    viewMode={'TeacherView'} />
                  {isTeacher &&
                    <AssessmentQuestionActionOptions
                      assessmentQuestionId={m.assessmentQuestionId}
                      assessmentId={assessmentId}
                      handleCannotChangeOpen={handleCannotChangeOpen}
                      handleRemoveQuestionOpen={handleRemoveQuestionOpen}
                      isPublished={assessment.isPublished}
                      pointsPossible={m.pointsPossible}
                      reorderSequence={reorderSequence}
                      sequence={m.sequence}
                      sequences={sequences} />
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'PASSAGE') {
              return (
                <div key={i}>
                  <AssessmentPassage
                    isTeacher={true} 
                    mediaBinaries={m?.mediaBinaries}
                    nameKey={i}
                    userPersonId={userPersonId}
                    question={m} />
                  {isTeacher &&
                    <AssessmentQuestionActionOptions
                      assessmentQuestionId={m.assessmentQuestionId}
                      assessmentId={assessmentId}
                      handleCannotChangeOpen={handleCannotChangeOpen}
                      handleRemoveQuestionOpen={handleRemoveQuestionOpen}
                      isPublished={assessment.isPublished}
                      pointsPossible={m.pointsPossible}
                      reorderSequence={reorderSequence}
                      sequence={m.sequence}
                      sequences={sequences} />
                  }
                </div>
              )
            }
            return null
          })}
        </div>
        <hr className={styles.hrHeight} />
        {assessmentQuestions?.length >= 1 &&
          <a onClick={assessment.isPublished ? showViewOnlyMessage : () => navigate(`/assessmentItemSetup/${assessmentId}/${guidEmpty}`)} className={classes(styles.row, styles.addNew)}>
            <Icon pathName={'plus_circle'} className={styles.icon} fillColor={'green'} premium={true}/>
            <span className={styles.addAnother}>Add Another Question</span>
          </a>
        }
      </div>
      <OneFJefFooter />
      {isShowingModal_removeAnswerOption &&
        <MessageModal handleClose={handleRemoveAnswerOptionClose}
          heading={'Remove this answer option?'}
          explainJSX={'Are you sure you want to delete this answer option?'}
          isConfirmType={true}
          onClick={handleRemoveAnswerOption} />
      }
      {isShowingModal_pointsError &&
        <MessageModal handleClose={handlePointsErrorClose}
          heading={'Points intended do not match!'}
          explainJSX={'The points intended for this quiz do not match the total points entered for the assessment questions. Do you want to reset the total points possible to ${subTotalPoints}?'}
          isConfirmType={true}
          onClick={handlePointsErrorSave} />
      }
    </div>
  )
}

export default AssessmentQuestionsView
