import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router'
//import ReactToPrint from "react-to-print"
import { createConfirmToastAuto, createInfoToastAuto } from '../../../services/queryClient.js'
import ButtonWithIcon from '../../../components/ButtonWithIcon'
import CheckboxToggle from '../../../components/CheckboxToggle'
import InputTextArea from '../../../components/InputTextArea'
import classes from 'classnames'
import globalStyles from '../../../utils/globalStyles.module.css'
import OneFJefFooter from '../../../components/OneFJefFooter'
import SelectSingleDropDown from '../../../components/SelectSingleDropDown'
import styles from './AssessmentAiGeneratedView.module.css'
import TextDisplay from '../../../components/TextDisplay'
import { useMediaQuery } from "react-responsive"

function AssessmentAiGeneratedView(props) {
  const {
    assessment,
    assessmentId,
    generateAiQuizAssessmentQuestions,
    userPersonId,
  } = props

  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [shortCutChoose, setShortCutChoose] = useState()
  const [setup, setSetup] = useState({ quizContent: '' })
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const toggleCheckbox = (field, value) => {
    setSetup(prevState => ({ ...prevState, [field]: value}))
  }

  const changeItem = ({target}) => {
    setSetup(prevState => ({ ...prevState, [target.name]: target.value }))
  }

  const changeQuestionCount = ({ target }, questionType) => {
    setSetup(prevState => ({
      ...prevState,
      [questionType]: {
        ...prevState[questionType] || {}, 
        [target.name]: parseInt(target.value, 10) 
      }
    }));
  }

  const processForm = (event) => {
    event.stopPropagation()
    event.preventDefault()
    let missingFields = ''

    if (!setup.quizContent) missingFields += "\nThe quiz content has not yet been entered."

    if (missingFields) {
      createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`)
    } else {
      setSubmitSuccess(true)
      let completeContent = ''
      generateAiQuizAssessmentQuestions(assessmentId, completeContent, setup)
    }
  }

  const handleSetShortCutChoose = ({target}) => {
    const chosenValue = target.value
    setShortCutChoose(chosenValue)
    setSetup(prevState => ({
      ...prevState,
      essay: {...prevState.essay, quantity: chosenValue },
      fillBlank: {...prevState.fillBlank, quantity: chosenValue, choices: chosenValue},
      matching: { ...prevState.matching, quantity: chosenValue, choices: chosenValue },
      multipleAnswer: { ...prevState.multipleAnswer, quantity: chosenValue, choices: chosenValue },
      multipleChoice: { ...prevState.multipleChoice, quantity: chosenValue, choices: chosenValue },
      passage: {...prevState.passage, quantity: chosenValue},
      trueFalse: {...prevState.trueFalse, quantity: chosenValue},
    }))
  }

  return (
    <div className={styles.container}>
      <div className={classes(styles.mainDiv, styles.background)}>
        <div className={globalStyles.pageTitle}>
          AI-Generated Assessment
        </div>
        <div className={styles.moreTop}>
          <TextDisplay label={'Assessment'}
            text={<div onClick={() => navigate(-1)} className={styles.rowLinkPlain}>
              {assessment?.name}
              <div className={styles.linkGoBack}>&lt; Go back</div>
            </div>} />
        </div>
        <InputTextArea
          label={'Copy-in or type your plain-text content'}
          columns={isMobile ? '50' : '100'}
          rows={20}
          name={'quizContent'}
          value={setup.quizContent || ''}
          autoComplete={'dontdoit'}
          onChange={changeItem}
          required={true}
          whenFilled={setup.quizContent}/>

        <div className={styles.header}>What kind and how many questions do you want?</div>
        <div className={styles.shortCut}>
          <div>ShortCut: Choose </div>
          <select
            value={shortCutChoose}
            onChange={(event) => handleSetShortCutChoose(event)}
            className={styles.selectList}>
              <option value='0'>--</option>
              {Array.from({ length: 10 }, (v, i) => <option value={i+1}>{i+1}</option>)}
          </select>
          <div>of each (then you can adjust them one-at-a-time, as needed)</div>
        </div>
        <div className={classes(styles.subHeader, styles.row)}>
          Essay
          <div className={styles.instructions}>You can return to the setup to provide acceptable phrases for automatic scoring</div>
        </div>
        <div className={styles.row}>
          <div>
            <SelectSingleDropDown
              id={'quantity'}
              name={`quantity`}
              label={'Quantity'}
              value={setup.essay?.quantity || ''}
              options={Array.from({length: 25 }, (v, i) => ({id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'essay')} />
          </div>
        </div>
        <hr/>

        <div className={classes(styles.subHeader, styles.row)}>
          Fill in the blank
          <div className={styles.instructions}>You can return to the setup to provide acceptable alternative words</div>
        </div>
        <div className={styles.row}>
          <div>
            <SelectSingleDropDown
              id={'quantity'}
              name={`quantity`}
              label={'Quantity'}
              value={setup.fillBlank?.quantity || ''}
              options={Array.from({ length: 25 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'fillBlank')} />
          </div>
          <div>
            <SelectSingleDropDown
              id={'choices'}
              name={`choices`}
              label={'How many choices?'}
              value={setup.fillBlank?.choices || ''}
              options={Array.from({ length: 10 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'fillBlank')} />
          </div>
        </div>
        <hr />
        <div className={styles.subHeader}>
          Matching
        </div>
        <div className={styles.row}>
          <div>
            <SelectSingleDropDown
              id={'quantity'}
              name={`quantity`}
              label={'Quantity'}
              value={setup.matching?.quantity || ''}
              options={Array.from({ length: 25 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'matching')} />
          </div>
          <div>
            <SelectSingleDropDown
              id={'choices'}
              name={`choices`}
              label={'How many choices?'}
              value={setup.matching?.choices || ''}
              options={Array.from({ length: 10 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'matching')} />
          </div>
        </div>
        <hr />
        <div className={styles.subHeader}>
          Multiple choice (more than one answer)
        </div>
        <div className={styles.row}>
          <div>
            <SelectSingleDropDown
              id={'quantity'}
              name={`quantity`}
              label={'Quantity'}
              value={setup.multipleAnswer?.quantity || ''}
              options={Array.from({ length: 25 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'multipleAnswer')} />
          </div>
          <div>
            <SelectSingleDropDown
              id={'choices'}
              name={`choices`}
              label={'How many choices?'}
              value={setup.multipleAnswer?.choices || ''}
              options={Array.from({ length: 10 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'multipleAnswer')} />
          </div>
        </div>
        <hr />
        <div className={styles.subHeader}>
          Multiple choice (one answer)
        </div>
        <div className={styles.row}>
          <div>
            <SelectSingleDropDown
              id={'quantity'}
              name={`quantity`}
              label={'Quantity'}
              value={setup.multipleChoice?.quantity || ''}
              options={Array.from({ length: 25 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'multipleChoice')} />
          </div>
          <div>
            <SelectSingleDropDown
              id={'choices'}
              name={`choices`}
              label={'How many choices?'}
              value={setup.multipleChoice?.choices || ''}
              options={Array.from({ length: 10 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'multipleChoice')} />
          </div>
        </div>
        <hr />
        <div className={classes(styles.subHeader, styles.row)}>
          Passage 
          <div className={styles.instructions}>Intended to be a reading followed by one or more questions</div>
        </div>
        <div className={styles.row}>
          <div>
            <SelectSingleDropDown
              id={'quantity'}
              name={`quantity`}
              label={'Quantity'}
              value={setup.passage?.quantity || ''}
              options={Array.from({ length: 25 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'passage')} />
          </div>
        </div>
        <hr />
        <div className={styles.subHeader}>
          True/False
        </div>
        <div className={styles.row}>
          <div>
            <SelectSingleDropDown
              id={'quantity'}
              name={`quantity`}
              label={'Quantity'}
              value={setup.trueFalse?.quantity || ''}
              options={Array.from({ length: 25 }, (v, i) => ({ id: `${i + 1}`, label: `${i + 1}` }))}
              height={`super-short`}
              whiteText
              onChange={(event) => changeQuestionCount(event, 'trueFalse')} />
          </div>
        </div>
        <hr />
        <div className={styles.buttonsCenter}>
          <div className={styles.buttonPosition}>
            <div className={styles.cancelLink} onClick={() => navigate(-1)}>
              Close
            </div>
            <div>
              <ButtonWithIcon label={'Submit'} icon={'checkmark_circle'} onClick={processForm} submitSuccess={submitSuccess} />
            </div>
          </div>
        </div>
      </div>
      <OneFJefFooter />
    </div>
  )
}

export default AssessmentAiGeneratedView
