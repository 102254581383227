import React, { useState, useEffect } from 'react'
import { createConfirmToastAuto, createInfoToastAuto } from '../../../services/queryClient.js'
import { guidEmpty } from '../../../utils/GuidValidate.js'
import { useMediaQuery } from "react-responsive"
import { useNavigate } from 'react-router-dom'
import * as mediaService from '../../../services/media-files.js'
import AssessmentAnswerVariation from '../../../components/Assessment/AssessmentAnswerVariation'
import AssessmentEssayKeyword from '../../../components/Assessment/AssessmentEssayKeyword'
import AssessmentMatching from '../../../components/Assessment/AssessmentMatching'
import AssessmentResponseTypeRequiredControls from '../../../components/Assessment/AssessmentResponseTypeRequiredControls'
import ButtonWithIcon from '../../../components/ButtonWithIcon'
import CheckboxToggle from '../../../components/CheckboxToggle'
import classes from 'classnames'
import Icon from '../../../components/Icon'
import InputDataList from '../../../components/InputDataList'
import InputText from '../../../components/InputText'
import InputTextArea from '../../../components/InputTextArea'
import MediaLinks from '../../../components/Media/MediaLinks'
import MediaListViewer from '../../../components/Media/MediaListViewer'
import MessageModal from '../../../components/MessageModal'
import QuizQuestionTypeScroller from '../../../components/QuizQuestionTypeScroller'
import ReactDOMServer from "react-dom/server"
import SelectSingleDropDown from '../../../components/SelectSingleDropDown'
import styles from './AssessmentItemSetupView.module.css'
import TextDisplay from '../../../components/TextDisplay'
import OneFJefFooter from '../../../components/OneFJefFooter'

const alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

/*
  In order to coordinate creating new AssessmentQuestion records, we use a autoPartialSave function to pick up the first AssessmentQuestionId.
  We use useEffect whenever the question changes. But this becomes a problem with the more complex Answers and Matches for the Matching as well as the
    MultipleChoice and MultipleAnswers question types. So we will create a separate state for answers and matches to be able to update locally. When the 
    user clicks on the submit button, then we will save these two separate objects to the backend.
*/

function AssessmentItemSetupView(props) {
  const {
    assessment,
    gradingType,
    isTeacher,
    userPersonId,
    questionTypes,
    standards = [],
  } = props

  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [assessmentQuestionId, setAssessmentQuestionId] = useState()
  const [errors, setErrors] = useState({
    questionTypeCode: '',
    pointsPossible: '',
    learnerOutcomeList: '',
    questionText: '',
    answers: '',
    correctAnswer: '',
    keywordPhrase: '',
    keywordCountAccuracy: '',
    totalPoints: '',
  })

  const [fillInTheBlankPhrases, setFillInTheBlankPhrases] = useState()
  const [isShowingModal_keywordCount, setIsShowingModal_keywordCount] = useState()
  const [multipleAnswers, setMultipleAnswers] = useState([])
  const [multipleToMatch, setMultipleToMatch] = useState([])
  const [multipleCount, setMultipleCount] = useState()
  const [question, setQuestion] = useState({
    assessmentQuestionId: '',
    questionTypeCode: '',
    pointsPossible: '',
    learnerOutcomeList: '',
    questionText: '',
    answers: [],
    correctAnswer: [],
    keywordPhrases: [],
    keywordCountAccuracy: 0,
    answerVariations: [],
    solutionText: '',
    responseTypeRequired: {}
  })
  const [standardIds, setStandardIds] = useState()
  const [mediaBinaries, setMediaBinaries] = useState()
  const [isInit, setIsInit] = useState()

  useEffect(() => {
    setAssessmentQuestionId(props.assessmentQuestionId)
  }, [props.assessmentQuestionId])

  useEffect(() => {
    return () => {
      setAssessmentQuestionId(null)
      setErrors({})
      setFillInTheBlankPhrases()
      setIsShowingModal_keywordCount()
      setMultipleAnswers([])
      setMultipleToMatch([])
      setMultipleCount()
      setQuestion({})
      setStandardIds()
      setMediaBinaries()
    }
  }, [])

  useEffect(() => {
    //We need to get the assessmentQuestionId for the autoPartialSave in progress so we can keep updating the record as it is built.
    if (props.assessmentQuestions?.length > 0) { //&& !!question?.questionTypeCode) { I think that this questionTypeCode is holding us back from initiating the page with a valid if not partial assessmentQuestion
      const updatedQuestion = props.assessmentQuestions.filter(m => m.assessmentQuestionId === props.assessmentQuestionId)[0]
      if (updatedQuestion) {
        setAssessmentQuestionId(updatedQuestion.assessmentQuestionId)
        setMediaBinaries(updatedQuestion.mediaBinaries)
      }
    }
  }, [props.assessmentQuestions, props.assessmentQuestionId])

  useEffect(() => {
    if (!isInit && question.assessmentQuestionId) {
      if ((!question?.answers || question?.answers?.length === 0) && multipleAnswers?.length === 0) {
        setMultipleAnswers([ 
            { assessmentQuestionId: question.assessmentQuestionId, answerText: '', sequence: 0 }, 
            { assessmentQuestionId: question.assessmentQuestionId, answerText: '', sequence: 1 }, 
            { assessmentQuestionId: question.assessmentQuestionId, answerText: '', sequence: 2 }
          ])
        setMultipleCount(3)
      } else {
        setMultipleAnswers(question.answers)
        setMultipleCount(question.answers.length)
      }
      setIsInit(true)
    }
  }, [question])

  useEffect(() => {
    if (props.assessmentQuestionId !== guidEmpty && props.assessmentQuestions?.length > 0) {
      let editQuestion = props.assessmentQuestions.filter(m => m.assessmentQuestionId === props.assessmentQuestionId)[0]
      if (editQuestion) {
        if (typeof editQuestion.responseTypeRequired !== 'object') {
          editQuestion.responseTypeRequired = JSON.parse(editQuestion.responseTypeRequired)
        }

        setQuestion(editQuestion)
        if (editQuestion.questionTypeCode === 'MULTIPLEANSWER' || editQuestion.questionTypeCode === 'MULTIPLECHOICE' || editQuestion.questionTypeCode === 'MATCHING') {
          setMultipleAnswers(editQuestion.answers)
          setMultipleToMatch(editQuestion.matches)
        }
      }
    }
  }, [props.assessmentId, props.assessmentQuestionId, props.assessmentQuestions])

  const changeItem = ({ target }) => {
    let newQuestion = { ...question }
    if (target.name === 'questionTypeCode') {
      newQuestion.questionText = ''
    }
    if (!newQuestion) {
      newQuestion = {
        assessmentQuestionId: '',
        questionTypeCode: '',
        pointsPossible: '',
        learnerOutcomeList: '',
        questionText: '',
        answers: [],
        correctAnswer: [],
        keywordPhrases: [],
        keywordCountAccuracy: 0,
        answerVariations: [],
        solutionText: '',
      }
    }
    newQuestion[target.name] = !isNaN(target.value) ? Number(target.value) : target.value
    if (target.name === 'questionText') newQuestion['fillBlankPhrase'] = target.value
    setQuestion(newQuestion)
    if (target.value === 'MULTIPLEANSWER' || target.value === 'MULTIPLECHOICE') {
      if (!multipleAnswers?.length === 0) {
        setMultipleAnswers(question?.answers?.length > 0 ? question.answers : [{ answerText: '' }, { answerText: '' }, { answerText: '' }])
      }
      if (!multipleCount) setMultipleCount(3)
    }
  }

  const autoPartialSave = () => {
    if (!question.questionTypeCode) {
      //No message. Just don't save if if the user started the item setup modal but didn't choose a question type because they want to close the modal.
    } else {
      if (question.correctAnswer && Array.isArray(question.correctAnswer))
        question.correctAnswer = Object.values(question.correctAnswer).join(',')  //This one is not ~^.  It is a comma delimited.
      if (question.responseTypeRequired?.length > 0 && typeof question.responseTypeRequired === 'object')
        question.responseTypeRequired = JSON.stringify(question.responseTypeRequired)  //This one is not ~^.  It is a JSON stringify

      question.assessmentQuestionId = question.assessmentQuestionId && question.assessmentQuestionId !== guidEmpty
        ? question.assessmentQuestionId
        : assessmentQuestionId || guidEmpty //This should be coming from the useEffect after the first save of the qustionTypeCode

      question.assessmentId = question.assessmentId && question.assessmentId !== guidEmpty
        ? question.assessmentId
        : props.assessmentId

      question.pointsPossible = isNaN(question.pointsPossible) ? 0 : Number(question.pointsPossible)
      question.solutionText = question.solutionText ? question.solutionText : ''
      question.answers = multipleAnswers
      question.matches = multipleToMatch

      question.entryDate = null

      const runFunction = (newAssessmentQuestionId) => handleSetAssessmentQuestionId(newAssessmentQuestionId) //navigate(`/assessmentItemSetup/${props.assessmentId}/${newAssessmentQuestionId}`)
      props.addOrUpdateAssessmentItem(question, assessmentQuestionId && assessmentQuestionId !== guidEmpty ? ()=>{} : runFunction)
    }
  }

  const handleSetAssessmentQuestionId = (newAssessmentQuestionId) => {
    setAssessmentQuestionId(newAssessmentQuestionId)
    setQuestion(prevQuestion => ({...prevQuestion, assessmentQuestionId: newAssessmentQuestionId}))
  }

  const processForm = () => {
    let missingInfoMessage = []

    if (!question.questionTypeCode) {
      setErrors({ ...errors, questionTypeCode: 'Question type is required' })
      missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>Question type</div>
    }
    if (!question.pointsPossible && question.questionTypeCode !== 'PASSAGE') {
      setErrors({ ...errors, pointsPossible: 'Points are required' })
      missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>Points</div>
    }
    if ((question.questionTypeCode === 'FILLBLANK' && !question.questionText) || (!question.questionText && !mediaService.hasMedia(question.mediaBinaries, 'question'))) {
      setErrors({ ...errors, questionText: 'Question text is required' })
      missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>Question text</div>
    }
    if (question.questionTypeCode === 'ESSAY' && question.keywordPhrases && question.keywordPhrases.length > 0 && !question.keywordCountAccuracy) {
      handleKeywordCountOpen()
    }
    if (question.questionTypeCode === 'TRUEFALSE') {
      if (question.correctAnswer === '' || question.correctAnswer === null || question.correctAnswer === undefined) {
        setErrors({ ...errors, hasCorrectAnswer: 'A True/False question must be set as true or false' })
        missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>Correct answer</div>
      }
    }
    if (question.questionTypeCode === 'FILLBLANK' && (!question.correctAnswer || question.correctAnswer.length === 0)) {
      setErrors({ ...errors, correctAnswer: 'Choose at least one blank' })
      missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>Choose at least one blank</div>
    }

    if (question.questionTypeCode === 'MATCHING' && validateMatching() !== '') {
      missingInfoMessage = validateMatching()
    }

    if (gradingType === 'STANDARDSRATING' && question.questionTypeCode !== 'PASSAGE' && (!question.standardIds || question.standardIds.length === 0)) {
      setErrors({...errors, totalPoints: "Choose at least one blank"})
      missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>At least one standard</div>
    }

    let hasCorrectAnswer = question.correctAnswer
    if (!hasCorrectAnswer) {
      if (question.questionTypeCode === 'MULTIPLECHOICE') {
        hasCorrectAnswer = multipleAnswers?.length > 0 && multipleAnswers.filter(m => m.isCorrect)[0]
      } else if (question.questionTypeCode === 'MULTIPLEANSWER') {
        hasCorrectAnswer = multipleAnswers?.length > 0 && multipleAnswers.filter(m => m.isCorrect)[0]
      }
    }
    if (!hasCorrectAnswer && question.questionTypeCode !== 'ESSAY' && question.questionTypeCode !== 'PASSAGE') {
      setErrors({ ...errors, totalPoints: "There must be at least one accurate answer." })
      missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>An accurate answer</div>
    }

    if (missingInfoMessage && missingInfoMessage.length > 0) {
      const htmlString = ReactDOMServer.renderToStaticMarkup(missingInfoMessage)
      createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${htmlString}</div></div>`)
    } else {
      //It is possible that the keywordCountAccuracy changed by force due to the change of the keywordPhrases.  It can even be blank.
      //So we are going to force the keywordcountAccuracy here if this is an Essay assessment question.
      //This will also help to make sure that the student has a chance at the question to be graded because if the count is higher than
      //	the actual number of keywordPhrases, they will never have a chance to get 100%.
      if (question.questionTypeCode === 'ESSAY') {
        let keywordPhraseCount = question.keywordPhrases && question.keywordPhrases.length ? question.keywordPhrases && question.keywordPhrases.length : 0
        if (Number(question.keywordCountAccuracy) > keywordPhraseCount) question.keywordCountAccuracy = keywordPhraseCount
      }

      if (question.correctAnswer && Array.isArray(question.correctAnswer))
        question.correctAnswer = Object.values(question.correctAnswer).join(',')  //This one is not ~^.  It is a comma delimited.
      // if (question.responseTypeRequired?.length > 0 && typeof question.responseTypeRequired === 'object')
      //   question.responseTypeRequired = JSON.stringify(question.responseTypeRequired)  //This one is not ~^.  It is a JSON stringify

      question.assessmentQuestionId = question.assessmentQuestionId
        ? question.assessmentQuestionId
        : props.assessmentQuestionId

      question.assessmentId = question.assessmentId
        ? question.assessmentId
        : props.assessmentId

      question.pointsPossible = isNaN(question.pointsPossible) ? 0 : Number(question.pointsPossible)
      question.solutionText = question.solutionText ? question.solutionText : ''
      question.answers = multipleAnswers
      question.matches = multipleToMatch

      question.entryDate = null
      question.isCreated = true

      props.addOrUpdateAssessmentItem(question, () => navigate(`/assessmentQuestions/${props.assessmentId}`))
    }
  }

  const validateMatching = () => {
    //1. See if there are any blanks from the highest index of the correctAnswers, answers and matches)
    //2. See if there are any duplicate answers and provide that message.
    //3. See if there are any answers that don't have a matches entry.
    let missingInfoMessage = ''
    let newQuestion = {...question}

    newQuestion.correctAnswer = newQuestion.correctAnswer && typeof newQuestion.correctAnswer === 'object'
      ? [...newQuestion.correctAnswer]
      : newQuestion.correctAnswer.length > 0
        ? newQuestion.correctAnswer.split(',')
        : []

    let maxIndex = newQuestion.correctAnswer.length - 1
    maxIndex = multipleAnswers.length - 1
    maxIndex = multipleToMatch.length - 1

    //1. See if there are any blanks from the highest index of the correctAnswers, questionText(s) and toMatchText(s)
    for (let i = 0; i < maxIndex; i++) {
      if (!newQuestion.correctAnswer)
        missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>An answer is missing</div>
      if (!newQuestion.answers)
        missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>A left entry is missing</div>
      if (!newQuestion.matches)
        missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>A right entry is missing</div>
    }

    //2. See if there are any duplicate answers and provide that message.
    let hasDuplicate = false
    for (let i = 0; i <= maxIndex; i++) {
      if (newQuestion?.correctAnswer && newQuestion.correctAnswer[i] && newQuestion.correctAnswer[i] !== '0') {
        let indexValue = newQuestion.correctAnswer[i]
        if (newQuestion.correctAnswer.indexOf(indexValue) > -1 && newQuestion.correctAnswer.indexOf(indexValue) !== i) hasDuplicate = true
      }
    }
    if (hasDuplicate) missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>Duplicate answer</div>

    //3. See if there are any answers that don't have a toMatchText entry.
    let hasNoAnswerEntry = false
    for (let i = 0; i <= maxIndex; i++) {
      if (newQuestion.correctAnswer && newQuestion.correctAnswer.length > 0 && newQuestion.correctAnswer[i] && newQuestion.correctAnswer[i] !== '0') {
        let chosenAnswerIndex = alpha.indexOf(newQuestion.correctAnswer[i])
        let indexValue = multipleToMatch[chosenAnswerIndex]
        if (!indexValue) hasNoAnswerEntry = true
      }
    }
    if (hasNoAnswerEntry) missingInfoMessage[missingInfoMessage.length] = <div className={styles.errorLine}>An answer chosen has blank text</div>

    return missingInfoMessage
  }

  const handleTrueFalse = (yesNo) => {
    let newQuestion = { ...question }
    newQuestion['correctAnswer'] = yesNo
    setQuestion(newQuestion)
  }

  const handleRemoveMultipleChoiceAnswer = (index) => {
    createConfirmToastAuto(`<div>Are you sure you want to delete this answer?</div>`, () => {
      let newMultipleAnswers = [...multipleAnswers]
      newMultipleAnswers.splice(index, 1)
      setQuestion({ ...question, answers: newMultipleAnswers })
      setMultipleAnswers(newMultipleAnswers)
      setMultipleCount(multipleCount - 1)
    })
  }

  const handleMultipleAnswerCorrectAnswer = (index, yesNo) => {
    const newMultipleAnswers = Array.isArray(multipleAnswers) ? [...multipleAnswers] : [];
    // while (newMultipleAnswers.length <= index) {
    //   newMultipleAnswers.push({ sequence: newMultipleAnswers.length });
    // }
    if (newMultipleAnswers[index]) {
      newMultipleAnswers[index] = { ...newMultipleAnswers[index], isCorrect: yesNo === 'yes' };
    } else {
      newMultipleAnswers[index] = { sequence: index, isCorrect: yesNo === 'yes' };
    }
    setMultipleAnswers(newMultipleAnswers);
  }

  const handleMultipleChoiceCorrectAnswer = (index, yesNo) => {
    //This function is different from handleMultipleAnswerCorrectAnswer above because the choice only has one answer that can be accurate.
    const newMultipleAnswers = Array.isArray(multipleAnswers) ? [...multipleAnswers] : [];
    //If the user chooses yesNo === 'yes' then we need to be sure to set any other choices to No. In fact, we might as well set them all of the others for the users to 'no'.
    if (yesNo === 'yes') {
      newMultipleAnswers.forEach(m => {
        m.isCorrect = false
      })
    }
    if (newMultipleAnswers[index]) {
      newMultipleAnswers[index] = { ...newMultipleAnswers[index], isCorrect: yesNo === 'yes' }
    } else {
      newMultipleAnswers[index] = { sequence: index, isCorrect: yesNo === 'yes' }
    }
    setMultipleAnswers(newMultipleAnswers);
  }

  const handleMatchingCorrectAnswers = (event, index = 0) => {
    const incomingAnswer = event.target.value
    setQuestion(prevQuestion => {
      let answers = Array.isArray(prevQuestion.correctAnswer)
        ? [...prevQuestion.correctAnswer] 
        : (typeof prevQuestion.correctAnswer === 'string' && prevQuestion.correctAnswer)
          ? prevQuestion.correctAnswer.split(',') 
          : []; 
      while (answers.length <= index) {
        answers.push('')
      }
      answers[index] = incomingAnswer
      return { ...prevQuestion, correctAnswer: answers }
    })  
  }

  const handleMultipleAnswers = (event, index) => {
    setMultipleAnswers(prevAnswers => {
      let answers = prevAnswers?.length > 0 ? [...prevAnswers] : []
      answers[index] = answers[index]
        ? { ...answers[index], answerText: event.target.value }
        : { assessmentQuestionAnswerOptionId: guidEmpty, answerText: event.target.value, sequence: index }
      return answers
    })
  }

  const handleMultipleToMatch = (event, index) => {
    setMultipleToMatch(prevMatches => {
      let matches = prevMatches?.length > 0 ? [...prevMatches] : []
      matches[index] = matches[index]
        ? { ...matches[index], toMatchText: event.target.value }
        : { assessmentQuestionToMatchOptionId: guidEmpty, toMatchText: event.target.value, sequence: index }
      return matches
    })
  }

  const removeMatchingRemoveLine = (removeIndex) => {
    let newQuestion = { ...question }
    if (newQuestion.correctAnswer && newQuestion.correctAnswer.length >= removeIndex + 1) newQuestion.correctAnswer.splice(removeIndex, 1)
    if (multipleAnswers && multipleAnswers.length >= removeIndex + 1) multipleAnswers.splice(removeIndex, 1)
    if (multipleToMatch.length >= removeIndex + 1) multipleToMatch.splice(removeIndex, 1)
    setQuestion(newQuestion)
  }

  const incrementMultipleCount = () => {
    let newCount = multipleCount >= 3 ? multipleCount : 3
    newCount++
    setMultipleCount(newCount)
  }

  const handleAddKeywordPhrase = (keywordPhrase) => {
    let keywordPhrases = question.keywordPhrases || []
    if (!keywordPhrases.includes(keywordPhrase)) {
      const updatedKeywordPhrases = [...keywordPhrases, keywordPhrase]
      const newQuestion = { ...question, keywordPhrases: updatedKeywordPhrases }
      setQuestion(newQuestion)
    }
  }

  const handleRemoveKeywordPhrase = (keywordIndex) => {
    let newQuestion = { ...question }
    newQuestion.keywordPhrases.splice(keywordIndex, 1)
    setQuestion(newQuestion)
  }

  const handleKeywordCountOpen = () => setIsShowingModal_keywordCount(true)
  const handleKeywordCountClose = () => setIsShowingModal_keywordCount(false)
  const handleKeywordCountSave = () => props.addOrUpdateAssessmentItem(question)
  
  const handleAddAnswerVariation = (answerVariation) => {
    let newQuestion = { ...question }
    if (!newQuestion.answerVariations || !newQuestion.answerVariations.length === 0 || newQuestion.answerVariations.indexOf(answerVariation) === -1) {
      let answerVariations = question && newQuestion.answerVariations ? newQuestion.answerVariations : []
      answerVariations = answerVariations ? answerVariations.concat(answerVariation) : [answerVariation]
      newQuestion.answerVariations = answerVariations
      setQuestion(newQuestion)
    }
  }

  const handleRemoveAnswerVariation = (variationIndex) => {
    let newQuestion = { ...question }
    newQuestion.answerVariations.splice(variationIndex, 1)
    setQuestion(newQuestion)
  }

  const fillInTheBlankDisplay = () => {
    let correctAnswers = typeof question.correctAnswer === 'string'
      ? !question.correctAnswer
        ? []
        : question.correctAnswer.split(',')
      : question.correctAnswer
        ? question.correctAnswer
        : []

    correctAnswers = correctAnswers && correctAnswers.length > 0 && correctAnswers.map(m => Number(m))

    let arrayWords = question.questionText && question.questionText.match(/\w+|[.!?]/g)
    let result = <div className={styles.rowTextBlanks}>
      {arrayWords && arrayWords.length > 0 && arrayWords.map((word, index) => {
        if (correctAnswers && correctAnswers.length > 0 && correctAnswers.indexOf(index) > -1) {
          return <input key={index} type={'text'} className={classes(styles.wordSpace, styles.shortTextInput)} onClick={() => blankOutWord(index)} />
        } else {
          return <div key={index} className={classes(styles.link, styles.wordSpace)} onClick={() => blankOutWord(index)}>
            {word}
          </div>
        }
      })}
    </div>

    return result
  }

  const blankOutWord = (index, assessIncoming) => {
    const newQuestion = assessIncoming ? assessIncoming : { ...question }
    let fillBlanksChosen = typeof newQuestion.correctAnswer === 'string'
      ? !newQuestion.correctAnswer
        ? []
        : newQuestion.correctAnswer.match(/\w+|[.!?]/g)
      : newQuestion.correctAnswer
    //Otherwise add it.
    if (fillBlanksChosen && fillBlanksChosen.length > 0 && fillBlanksChosen.indexOf(index) > -1) {
      fillBlanksChosen = fillBlanksChosen.filter(m => m !== index)
    } else {
      fillBlanksChosen = fillBlanksChosen && fillBlanksChosen.length > 0 ? fillBlanksChosen.concat(index) : [index]
    }
    fillBlanksChosen = fillBlanksChosen && fillBlanksChosen.length > 0 && fillBlanksChosen.map(m => !m && m !== 0 && m !== '0' ? null : Number(m))
    let fillInTheBlankPhrases = getFillInTheBlankPhrases(Number(fillBlanksChosen))
    setFillInTheBlankPhrases(fillInTheBlankPhrases)
    setQuestion({ ...question, correctAnswer: fillBlanksChosen })
  }

  const getFillInTheBlankPhrases = (fillBlanksChosen) => {
    let arrayWords = question.questionText && question.questionText.match(/\w+|[.!?]/g)
    let fillInTheBlankPhrases = []

    //Reset the consecutive phrases
    if (fillBlanksChosen && fillBlanksChosen.length > 0) {
      fillBlanksChosen = fillBlanksChosen.sort()
      let phraseCount = ''
      let prevIndex = ''
      let space = ''
      fillBlanksChosen.forEach(m => {
        if ((m || m === 0) && m !== null && m !== undefined) {
          if (((prevIndex || prevIndex === 0) && m === prevIndex + 1 * 1) || (!prevIndex && prevIndex !== 0)) {
            phraseCount = phraseCount || 0
            fillInTheBlankPhrases[phraseCount] = fillInTheBlankPhrases[phraseCount]
              ? fillInTheBlankPhrases[phraseCount] += space + arrayWords[m]
              : arrayWords[m]
            space = ' '
          } else {
            fillInTheBlankPhrases[++phraseCount] = arrayWords[m]
          }
        }
        prevIndex = m
      })
    }
    return fillInTheBlankPhrases
  }

  const chooseStandards = (standardIds) => {
    let newQuestion = { ...question }
    newQuestion['standardIds'] = standardIds && standardIds.length > 0 && standardIds.reduce((acc, m) => acc && acc.length ? acc.concat(m.id) : [m.id], [])
    setQuestion(newQuestion)
    setStandardIds()
  }

  return !question ? null : (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={styles.center}>
          <div className={styles.heading}>
            {question?.assessmentQuestionId && question.assessmentQuestionId !== guidEmpty
              ? 'Edit Question'
              : 'Add Question'
            }
          </div>
          <div className={styles.moreTop}>
            <TextDisplay label={'Assessment'}
              text={<div onClick={() => navigate(-1)} className={styles.rowLinkPlain}>
                {assessment?.name}
                <div className={styles.linkGoBack}>&lt; Go back</div>
              </div>} />
          </div>
        </div>
        <div>
          {question.assessmentQuestionId
            ? <TextDisplay 
                label={'Question Type'} 
                text={questionTypes && questionTypes.length > 0 && questionTypes.filter(m => m.id === question.questionTypeCode)[0]?.label} />
            : <div>
              <div>
                <SelectSingleDropDown
                  id={`questionTypeCode`}
                  name={`questionTypeCode`}
                  label={'Question Type'}
                  value={question.questionTypeCode || ''}
                  options={questionTypes}
                  height={`medium`}
                  onChange={changeItem}
                  onBlur={autoPartialSave}
                  disabled={question.assessmentQuestionId}
                  required={true}
                  whenFilled={question.questionTypeCode}
                  error={errors.questionTypeCode} />
              </div>
              {!question.questionTypeCode && 
                <div>
                  <QuizQuestionTypeScroller />
                </div>
              }
            </div>
          }
          {question.assessmentQuestionId &&
            <div className={classes(styles.instructions, styles.muchLeft)}>If you want to change the question type, delete and start again.</div>
          }
        </div>
        {question.questionTypeCode && question.questionTypeCode !== '0' && question.questionTypeCode !== 'PASSAGE' &&
          <div>
            <InputText
              id={`pointsPossible`}
              name={`pointsPossible`}
              size={'super-short'}
              label={'Points'}
              numberOnly={true}
              value={question.pointsPossible || ''}
              onChange={changeItem}
              required={true}
              whenFilled={question.pointsPossible}
              autoComplete={'dontdoit'}
              error={errors.pointsPossible} />
          </div>
        }
        {gradingType === 'STANDARDSRATING' && question.questionTypeCode !== 'PASSAGE' &&
          <div>
            <div className={styles.listPosition}>
              <InputDataList
                label={'Standards'}
                name={'standardIds'}
                options={standards || [{ id: '', value: '' }]}
                value={standardIds}
                multiple={true}
                height={`medium`}
                className={styles.moreTop}
                onChange={chooseStandards} />
            </div>
          </div>
        }
        {question?.questionTypeCode && question?.questionTypeCode !== 'PASSAGE' &&
          <AssessmentResponseTypeRequiredControls
            setQuestion={setQuestion}
            question={question}
            excludeWriting={question?.questionTypeCode === 'ESSAY'} />
        }
        {question.questionTypeCode && question.questionTypeCode !== '0' && 
          <div>
            <InputTextArea
              label={question.questionTypeCode === 'PASSAGE' ? 'Enter the passage' : 'Enter the question'}
              columns={isMobile ? '50' : '80'}
              name={'questionText'}
              value={question.questionText || ''}
              autoComplete={'dontdoit'}
              onChange={changeItem}
              required={true}
              whenFilled={(question.questionTypeCode === 'FILLBLANK' && question.questionText)
                || (question.questionTypeCode !== 'FILLBLANK' && (question.questionText || mediaService.hasMedia(question.mediaBinaries, 'question')))}
              error={errors.questionText} />

            <MediaLinks
              audioLabel={'File for'}
              audioTitle={'Assessment Question'}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBase={{
                recordType: 'AssessmentQuestion',
                recordId: question.assessmentQuestionId,
                section: 'question',
                indexNbr: 0,
              }}
              userPersonId={userPersonId}
              {...props} />

            <MediaListViewer
              recordType={'AssessmentQuestion'}
              recordId={assessmentQuestionId}
              section={'question'}
              index={0}
              userPersonId={userPersonId}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBinaries={mediaBinaries} />
          </div>
        }
        {question.questionTypeCode === 'TRUEFALSE' &&
          <div>
            <CheckboxToggle
              label={'Is this true?'}
              checked={question.correctAnswer === 'yes'
                ? 'yes'
                : question.correctAnswer === '' || question.correctAnswer === null || question.correctAnswer === undefined
                  ? 'PRERESPONSE'
                  : 'no'
              }
              onClick={(yesNo) => handleTrueFalse(yesNo)} />
          </div>
        }
        <hr/>
        {question.questionText && question.questionTypeCode === 'FILLBLANK' &&
          <div>
            <TextDisplay label={'Full phrase'} text={question.questionText} />
          </div>
        }
        {question.questionText && question.questionTypeCode === 'FILLBLANK' &&
          <div className={styles.widthLimit}>
            <TextDisplay
              label={`Blank-out phrase (Click on a word to blank it out)`}
              labelClassName={classes(styles.instructions, styles.moreLeft)}
              text={fillInTheBlankDisplay()} />
          </div>
        }
        {fillInTheBlankPhrases && fillInTheBlankPhrases.length > 0 && question.questionTypeCode === 'FILLBLANK' &&
          <div>
            {fillInTheBlankPhrases.map((m, i) => !m
              ? null
              : <TextDisplay label={fillInTheBlankPhrases.length === 1 ? 'Answer' : `Answer #${i + 1 * 1}`} text={m} key={i} />
            )}
            <div className={styles.positionVariations}>
              <AssessmentAnswerVariation
                addAnswerVariation={handleAddAnswerVariation}
                answerVariations={question.answerVariations}
                removeAnswerVariation={handleRemoveAnswerVariation} />
            </div>
          </div>
        }
        {question.questionTypeCode === 'MATCHING' &&
          <div>
            <AssessmentMatching
              answers={multipleAnswers}
              assessmentQuestionId={assessmentQuestionId}
              handleMatchingCorrectAnswers={handleMatchingCorrectAnswers}
              handleMultipleAnswers={handleMultipleAnswers}
              handleMultipleToMatch={handleMultipleToMatch}
              isTeacher={isTeacher}
              matches={multipleToMatch}
              mediaBinaries={question?.mediaBinaries}
              question={question}
              removeMatchingRemoveLine={removeMatchingRemoveLine}
              userPersonId={userPersonId}
              viewMode={'AddOrUpdate'}
              {...props} />
            <hr />
          </div>
        }
        {question.questionTypeCode === 'ESSAY' &&
          <div>
            <AssessmentEssayKeyword
              addKeywordPhrase={handleAddKeywordPhrase}
              keywordCountAccuracy={question.keywordCountAccuracy}
              keywordPhrases={question.keywordPhrases}
              removeKeywordPhrase={handleRemoveKeywordPhrase}
              updateKeywordCountAccuracy={changeItem} />
          </div>
        }
        {question.questionTypeCode === 'PASSAGE' &&
          <div>
            <span className={styles.labelNotice}>This is intended to be a reading or example of a problem which will be followed by one or more questions.</span><br />
          </div>
        }
        {question.questionTypeCode === 'MULTIPLECHOICE' &&
          <div>
            {alpha.map((alph, index) => { 
              if (multipleCount >= index + 1) {
                return <div key={index}>
                  <div className={styles.row}>
                    <CheckboxToggle
                      id={alpha}
                      label={''}
                      checked={multipleAnswers[index]?.isCorrect
                        ? 'yes'
                        : multipleAnswers[index] === null || multipleAnswers[index]?.isCorrect === null || multipleAnswers[index]?.isCorrect === undefined
                          ? 'PRERESPONSE'
                          : 'no'
                      }
                      onClick={(yesNo) => handleMultipleChoiceCorrectAnswer(index, yesNo)} />
                    <span className={styles.label}>{alph}</span>
                    <InputTextArea
                      label={''}
                      id={alpha[index]}
                      name={alpha[index]}
                      autoComplete={'dontdoit'}
                      value={(multipleAnswers?.length > 0 && multipleAnswers[alpha.indexOf(alph)] && multipleAnswers[alpha.indexOf(alph)].answerText) || ''}
                      onChange={(event) => handleMultipleAnswers(event, index)}
                      error={errors.correctAnswer} />
                    {multipleCount > 2 &&
                      <Icon pathName={`trash2`} premium={true} className={styles.iconTrash} onClick={() => handleRemoveMultipleChoiceAnswer(index)} smaller flilColor={'maroon'} />
                    }
                  </div>
                  <MediaLinks
                    audioLabel={'File for'}
                    audioTitle={'Assessment Question'}
                    runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
                    mediaBase={{
                      recordType: 'AssessmentQuestion',
                      recordId: question.assessmentQuestionId,
                      section: 'answer',
                      indexNbr: index,
                    }}
                    userPersonId={userPersonId}
                    {...props} />
                  <MediaListViewer
                    recordType={'AssessmentQuestion'}
                    recordId={assessmentQuestionId}
                    section={'answer'}
                    index={index}
                    userPersonId={userPersonId}
                    runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
                    mediaBinaries={mediaBinaries} />
                  <hr />
                </div>
              }
            })}
            <a onClick={incrementMultipleCount} className={styles.rowLink}>
              <div className={styles.plus}>+</div>
              Add another multiple choice answer
            </a>
          </div>
        }
        {question.questionTypeCode === 'MULTIPLEANSWER' &&
          <div>
            {alpha.map((alph, index) => {  //eslint-disable-line
              if (multipleCount >= index + 1) {
                return <div key={index}>
                  <div className={styles.row}>
                    <CheckboxToggle
                      id={alpha}
                      label={''}
                      checked={multipleAnswers[index]?.isCorrect
                        ? 'yes'
                        : multipleAnswers[index] === null || multipleAnswers[index]?.isCorrect === null || multipleAnswers[index]?.isCorrect === undefined
                          ? 'PRERESPONSE'
                          : 'no'
                      }
                      onClick={(yesNo) => handleMultipleAnswerCorrectAnswer(index, yesNo)} />
                    <span className={styles.label}>{alph}</span>
                    <InputTextArea
                      label={''}
                      id={alpha[index]}
                      name={alpha[index]}
                      autoComplete={'dontdoit'}
                      value={(multipleAnswers?.length > 0 && multipleAnswers[alpha.indexOf(alph)] && multipleAnswers[alpha.indexOf(alph)].answerText) || ''}
                      onChange={(event) => handleMultipleAnswers(event, index)}
                      error={errors.correctAnswer} />
                    {multipleCount > 2 &&
                      <Icon pathName={`trash2`} premium={true} className={styles.iconTrash} onClick={() => handleRemoveMultipleChoiceAnswer(index)} smaller flilColor={'maroon'} />
                    }
                  </div>
                  {/* <div className={classes(styles.row, styles.includePicture, styles.muchMoreLeft)} id={`answerFile${index}`}> */}
                    <MediaLinks
                      audioLabel={'File for'}
                      audioTitle={'Assessment Question'}
                      runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
                      mediaBase={{
                        recordType: 'AssessmentQuestion',
                        recordId: question.assessmentQuestionId,
                        section: 'answer',
                        indexNbr: index,
                      }}
                      userPersonId={userPersonId}
                      {...props} />
                  {/* </div> */}
                  <MediaListViewer
                    recordType={'AssessmentQuestion'}
                    recordId={assessmentQuestionId}
                    section={'answer'}
                    index={index}
                    userPersonId={userPersonId}
                    runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
                    mediaBinaries={mediaBinaries} />
                  <hr />
                </div>
              }
            })}
            <a onClick={incrementMultipleCount} className={styles.rowLink}>
              <div className={styles.plus}>+</div>
              Add another multiple choice answer
            </a>
          </div>
        }
        {question.questionTypeCode && question.questionTypeCode !== 'PASSAGE' &&
          <div>
            <div className={styles.headerLabel}>Solution (optional)</div>
            <div className={styles.moreTop}>
              <InputTextArea
                label={'After the assessment is corrected, this explanation or media will be displayed'}
                columns={isMobile ? '50' : '80'}
                id={'solutionText'}
                name={'solutionText'}
                value={question.solutionText || ''}
                onChange={changeItem}
                autoComplete={'dontdoit'} />
            </div>
            <MediaLinks
              audioLabel={'File for'}
              audioTitle={'Assessment Question'}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBase={{
                recordType: 'AssessmentQuestion',
                recordId: question.assessmentQuestionId,
                section: 'solution',
                indexNbr: 0,
              }}
              userPersonId={userPersonId}
              {...props} />
            <MediaListViewer
              recordType={'AssessmentQuestion'}
              recordId={assessmentQuestionId}
              section={'solution'}
              index={0}
              userPersonId={userPersonId}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBinaries={mediaBinaries} />
          </div>
        }
        <div>
          <span className={styles.error}>{errors.correctAnswer}</span>
        </div>
        {question?.questionTypeCode &&
          <div>
            <div className={classes(styles.rowRight)}>
              <div className={styles.cancelLink} onClick={() => navigate(-1)}>
                Close
              </div>
              <div>
                <ButtonWithIcon label={'Submit'} icon={'checkmark_circle'} onClick={processForm} />
              </div>
            </div>
          </div>
        }
        <OneFJefFooter />        
      </div>
      {isShowingModal_keywordCount &&
        <MessageModal handleClose={handleKeywordCountClose} heading={'Missing keyword count accuracy?'}
          explainJSX={"You have entered a keyword for this essay, but you didn't indicate how many keywords that need to be present in the essay to get full credit. Do you want to save this entry anyway?"} isConfirmType={true}
          onClick={handleKeywordCountSave} />
      }
    </div>
  )
}

export default AssessmentItemSetupView  