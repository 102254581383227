import * as types from './actionTypes';
import {apiHost} from '../api_host.js';
import { emptyGuid } from '../utils/GuidValidate.js'

export const getMyGroups = (personId) => {
    return dispatch => {
        let storage = localStorage.getItem("groupFileTreeExplorer");
        storage && dispatch && dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: JSON.parse(storage) });

        return fetch(`${apiHost}ebi/myGroupFileTreeExplorer/${personId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => {
            dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: response });
            localStorage.setItem("groupFileTreeExplorer", JSON.stringify(response));
        })
        //.catch(error => { console.l og('request failed', error); });
    }
}

export const getPeopleByGroupIds = (groupIds, personId) => {
    return dispatch => {
        let storage = localStorage.getItem("peopleByGroupIds");
        storage && dispatch && dispatch({ type: types.PEOPLE_BY_GROUP_INIT, payload: JSON.parse(storage) });

        return fetch(`${apiHost}ebi/myGroupFileTreeExplorer/peopleByGroup/${personId}`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
          body: JSON.stringify(groupIds),
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json();
              } else {
                  const error = new Error(response.statusText);
                  error.response = response;
                  throw error;
              }
          })
          .then(response => {
              dispatch({ type: types.PEOPLE_BY_GROUP_INIT, payload: response });
              localStorage.setItem("peopleByGroupIds", JSON.stringify(response));
          })
    }
}

export const getWorkByGroupIds = (groupIds, personId) => {
    return dispatch => {
        let storage = localStorage.getItem("workByGroupIds");
        storage && dispatch && dispatch({ type: types.WORK_BY_GROUP_INIT, payload: JSON.parse(storage) });

        return fetch(`${apiHost}ebi/myGroupFileTreeExplorer/workByGroup/${personId}`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
            body: JSON.stringify(groupIds),
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json();
              } else {
                  const error = new Error(response.statusText);
                  error.response = response;
                  throw error;
              }
          })
          .then(response => {
              dispatch({ type: types.WORK_BY_GROUP_INIT, payload: response });
              localStorage.setItem("workByGroupIds", JSON.stringify(response));
          })
    }
}

export const assignGroupToGroupFolder = (personId, groupId, groupFolderId) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/myGroupFileTreeExplorer/assignGroupToFolder/${personId}/${groupId}/${groupFolderId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json();
              } else {
                  const error = new Error(response.statusText);
                  error.response = response;
                  throw error;
              }
          })
          .then(response => {
              dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: response });
              localStorage.setItem("groupFileTreeExplorer", JSON.stringify(response));
          })
    }
}

export const addGroupFolder = (personId, parentWorkFolderId, folderTitle, mineOrOthers) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/myGroupFileTreeExplorer/addGroupFolder/${personId}/${parentWorkFolderId}/${encodeURIComponent(folderTitle)}/${mineOrOthers}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json();
              } else {
                  const error = new Error(response.statusText);
                  error.response = response;
                  throw error;
              }
          })
          .then(response => {
              dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: response });
              localStorage.setItem("groupFileTreeExplorer", JSON.stringify(response));
          })
    }
}

export const deleteGroupFolder = (personId, groupFolderId) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/myGroupFileTreeExplorer/deleteGroupFolder/${personId}/${groupFolderId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json();
              } else {
                  const error = new Error(response.statusText);
                  error.response = response;
                  throw error;
              }
          })
          .then(response => {
              dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: response });
              localStorage.setItem("groupFileTreeExplorer", JSON.stringify(response));
          })
    }
}

export const toggleExpanded = (personId, groupFolderId) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/myGroupFileTreeExplorer/toggleExpanded/${groupFolderId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          //.then(dispatch({ type: types.GROUP_FILE_TREE_TOGGLE_EXPANDED, payload: groupFolderId }))  //This is mutating state and I can't figure out why since I was doing a clean copy of state.
          .then(dispatch(getMyGroups(personId)))
    }
}

// export const toggleAllExpanded = (personId, expandAll) => {
// 		expandAll = !!expandAll ? expandAll : false;
//     return dispatch => {
//         return fetch(`${apiHost}ebi/myGroupFileTreeExplorer/toggleExpanded/all/${personId}/${expandAll}`, {
//             method: 'get',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Access-Control-Allow-Credentials' : 'true',
//                 "Access-Control-Allow-Origin": "*",
//                 "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
//                 "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
//                 "Authorization": "Bearer " + localStorage.getItem("authToken"),
//             },
//         })
//         .then(() => {
// 						dispatch(getMyGroups(personId));
// 				})
//     }
// }

export const deleteGroupFromFolder = (mineOrOthers, personId, levelType, id) => { //If levelType 'work' then id is workId; if levelType 'folder' then id is groupFolderId
    return dispatch => {
        fetch(`${apiHost}ebi/group/deleteFromFolder/${mineOrOthers}/${personId}/${levelType}/${id}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json()
              } else {
                  const error = new Error(response.statusText)
                  error.response = response
                  throw error
              }
          })
          .then(response => {
              dispatch(getMyGroups(personId))
          })
    }
}


export const getGroupMembers = (groupId, assignmentId, displayOnlyCompletedAssignment) => { 
  if (!groupId) groupId = emptyGuid
  if (!assignmentId) assignmentId = emptyGuid
  return dispatch => {
    fetch(`${apiHost}ebi/myGroupFileTreeExplorer/getMembers/${groupId}/${assignmentId}/${displayOnlyCompletedAssignment}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          const error = new Error(response.statusText)
          error.response = response
          throw error
        }
      })
      .then(response => {
        dispatch({ type: types.GROUP_MEMBERS_INIT, payload: response });
      })
  }
}
