import React from 'react';
import styles from './AssessmentStudentResponseSetting.module.css';

function AssessmentStudentResponseSetting(props) {
  const {
    question,
  } = props

  return (
    <div className={styles.container}>
      {question?.responseTypeRequired && Object.keys(question.responseTypeRequired).length > 0 && 
        <div className={styles.question}>
          The student should respond with
          {/* {question.responseTypeRequired?.['text'] === 'yes' &&
            <div className={styles.responseType}>Text input</div>
          } */}
          {question.responseTypeRequired?.['upload'] === 'yes' &&
            <div className={styles.responseType}>File upload</div>
          }
          {question.responseTypeRequired?.['sketch'] === 'yes' &&
            <div className={styles.responseType}>By hand</div>
          }
          {question.responseTypeRequired?.['camera'] === 'yes' &&
            <div className={styles.responseType}>Camera picture</div>
          }
          {question.responseTypeRequired?.['audio'] === 'yes' &&
            <div className={styles.responseType}>Audio recording</div>
          }
          {question.responseTypeRequired?.['video'] === 'yes' &&
            <div className={styles.responseType}>Video recording</div>
          }
        </div>
      }
    </div>
  )
};

export default AssessmentStudentResponseSetting