import React from 'react';
import styles from './AssessmentResponseTypeRequiredControls.module.css';
import classes from 'classnames'
import CheckboxToggle from '../../CheckboxToggle'

function AssessmentResponseTypeRequiredControls(props) {
  const {
    excludeWritten,
    setQuestion,
    question
  } = props

  const handleResponseTypeRequired = (type, yesNo) => {
    let newQuestion = { ...question }
    const responseTypeRequired = { ...newQuestion.responseTypeRequired, [type]: yesNo }
    newQuestion.responseTypeRequired = responseTypeRequired
    setQuestion(newQuestion)
  }

  return (
    <div>
      <div className={classes(styles.headerLabel, styles.moreBottom)}>
        {excludeWritten 
          ? <div>The student is required to answer in additional ways:</div>
          : <div>The student is required to answer in one or more ways:</div>
        }
      </div>
      {/* {(!excludeWritten && question.questionTypeCode !== 'ESSAY' && question.questionTypeCode !== 'FILLBLANK') &&
        <CheckboxToggle
          label={'A typed answer'}
          checked={question?.responseTypeRequired?.['text'] === 'yes'
            ? 'yes'
            : question?.responseTypeRequired?.['text'] === 'no' || question?.assessmentQuestionId
              ? 'no'
              : 'PRERESPONSE'
          }
          onClick={(yesNo) => handleResponseTypeRequired('text', yesNo)} />
      } */}
      <CheckboxToggle
        label={'A handwritten answer'}
        checked={question?.responseTypeRequired?.['sketch'] === 'yes'
          ? 'yes'
          : question?.responseTypeRequired?.['sketch'] === 'no' || question?.assessmentQuestionId
            ? 'no'
            : 'PRERESPONSE'
        }
        onClick={(yesNo) => handleResponseTypeRequired('sketch', yesNo)} />
      <CheckboxToggle
        label={'A file upload'}
        checked={question?.responseTypeRequired?.['upload'] === 'yes'
          ? 'yes'
          : question?.responseTypeRequired?.['upload'] === 'no' || question?.assessmentQuestionId
            ? 'no'
            : 'PRERESPONSE'
        }
        onClick={(yesNo) => handleResponseTypeRequired('upload', yesNo)} />
      <CheckboxToggle
        label={'An audio recording'}
        checked={question?.responseTypeRequired?.['audio'] === 'yes'
          ? 'yes'
          : question?.responseTypeRequired?.['audio'] === 'no' || question?.assessmentQuestionId
            ? 'no'
            : 'PRERESPONSE'
        }
        onClick={(yesNo) => handleResponseTypeRequired('audio', yesNo)} />
      <CheckboxToggle
        label={'A camera picture'}
        checked={question?.responseTypeRequired?.['camera'] === 'yes'
          ? 'yes'
          : question?.responseTypeRequired?.['camera'] === 'no' || question?.assessmentQuestionId
            ? 'no'
            : 'PRERESPONSE'
        }
        onClick={(yesNo) => handleResponseTypeRequired('camera', yesNo)} />
      <CheckboxToggle
        label={'A video recording'}
        checked={question?.responseTypeRequired?.['video'] === 'yes'
          ? 'yes'
          : question?.responseTypeRequired?.['video'] === 'no' || question?.assessmentQuestionId
            ? 'no'
            : 'PRERESPONSE'
        }
        onClick={(yesNo) => handleResponseTypeRequired('video', yesNo)} />
    </div>
  )
};

export default AssessmentResponseTypeRequiredControls