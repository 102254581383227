import React from 'react';
import styles from './OriginalTextAndComment.module.css';
import * as editorService from '../../services/editor-dom'
import { createConfirmToastAuto } from '../../services/queryClient.js'
import Icon from '../../components/Icon'

function OriginalTextAndComment(props) {
  const { 
    chapterId,
    deleteWorkSegmentComment, 
    originalSentenceText, 
    segment, 
    workSummary, 
  } = props

  const confirmDeleteEdit = () => {
    createConfirmToastAuto('<div>Are you sure you want to delete this comment?</div>', () => deleteWorkSegmentComment(chapterId, segment?.workSegmentId))
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.row}>
          {!editorService.isMobile() && originalSentenceText && <span className={styles.textSmall}>{`Original Text:`}</span>}
          <div className={styles.languageName}>{workSummary.languageName}</div>
        </div>
        <div className={styles.authorSentence}>
          <div dangerouslySetInnerHTML={{ __html: originalSentenceText }} />
          {segment?.comment && 
            <div className={styles.rowSpaceBetween}>
              <div className={styles.sentenceComment}><div className={styles.textSmall}>Author's comment:</div> {segment.comment}</div>
              <a onClick={confirmDeleteEdit} className={styles.trashCan} title={'Delete this comment'}>
                <Icon pathName={`undo2`} premium={true} smaller fillColor={'#EF5555FF'} className={styles.choiceIcons} />
              </a>
            </div>
          }
        </div>
      </div>
      <hr />
    </div>
  )
}

export default OriginalTextAndComment