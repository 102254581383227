import React from 'react'
import { useLocation } from "react-router-dom";
import styles from './AssessmentTrueFalse.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import CheckboxToggle from '../../CheckboxToggle'
import Icon from '../../Icon'
import MediaListViewer from '../../Media/MediaListViewer'
import AssessmentSolutionDisplay from '../../Assessment/AssessmentSolutionDisplay'
import AssessmentStudentResponseSetting from '../../Assessment/AssessmentStudentResponseSetting'
import QuestionLabel from '../../QuestionLabel'
import PointsDisplay from '../../PointsDisplay'
import classes from 'classnames'

function AssessmentTrueFalse(props) {
  const {
    assessmentCorrect,
    assessmentQuestionId,
    assignmentId,
    bigTextDisplay,
    className = "",
    disabledMessage,
    onBlurScore,
    onClick,
    isTeacher,
    mediaBinaries,
    userPersonId,
    question,
    score,
    setScore,
  } = props

  const location = useLocation();
  const isLearnerPage = location.pathname.indexOf('assessmentLearner') > -1
  const isCorrectPage = location.pathname.indexOf('assessmentCorrect') > -1
  const isItemSetupPage = location.pathname.indexOf('assessmentQuestion') > -1 || location.pathname.indexOf('assessmentItemSetup') > -1

  const sendAssessmentAnswer = (value) => {
    if (onClick) onClick(userPersonId, question.assessmentQuestionId, value, assignmentId) //We want the value to return 'yes' and 'no' rather than 'true' and 'false'.
  }

  let isAssessmentCorrected = assessmentCorrect?.assessmentId
  
  return (
    <div className={classes(className, styles.container)}>
      <QuestionLabel label={'True or False'} />
      <div className={classes(styles.row, styles.questionLine)}>
        {isAssessmentCorrected
          ? question?.learnerAnswer?.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
          <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question.sequence}.
        </div>
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{question.questionText}</div>
      </div>
      <MediaListViewer
        recordType={'AssessmentQuestion'}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      <AssessmentStudentResponseSetting question={question} />
      <PointsDisplay 
        assessmentCorrect={assessmentCorrect}
        assessmentQuestionId={assessmentQuestionId}
        className={styles.littleRight} 
        isTeacher={question.isOwner}
        onBlurScore={onBlurScore}
        pending={!question.learnerAnswer || question.learnerAnswer?.pendingCorrection}
        pointsPossible={question.pointsPossible} 
        score={score} 
        setScore={setScore} 
        studentPersonId={question?.learnerAnswer?.personId} />

      <div className={styles.radioLeft}>
        <CheckboxToggle
          label={'Is this true?'}
          checked={(isItemSetupPage && question.correctAnswer === 'yes') || question?.learnerAnswer?.learnerAnswer === 'yes' 
            ? 'yes' 
            : (isItemSetupPage && question.correctAnswer === 'no') || question?.learnerAnswer?.learnerAnswer === 'no'
              ? 'no' 
              : 'PRERESPONSE'}
          disabled={disabledMessage || question?.learnerAnswer?.isSubmitted}
          onClick={disabledMessage 
            ? () => disabledMessage()
            : (checkboxValue) => sendAssessmentAnswer(checkboxValue)
          }
          personId={userPersonId}
          labelClass={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.label)} />
      </div>
      <MediaListViewer
        label={`Student's response`}
        recordType={`AssessmentLearnerAnswer${question?.learnerAnswer?.personId}`}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />

      {(isTeacher || assessmentCorrect) &&
        <AssessmentSolutionDisplay question={question} userPersonId={userPersonId} className={styles.solutionDisplay} />
      }
    </div>
  )
}

export default AssessmentTrueFalse