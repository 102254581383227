import React, {useState, useEffect} from 'react';
import styles from './RadioGroupToggle.module.css';
import './RadioGroupToggle.css'
import classes from 'classnames';
import Required from '../Required';
import YesChosen from '../../assets/YesNoButtons/Yes-chosen.png'
import YesNotChosen from '../../assets/YesNoButtons/Yes-notChosen.png'
import NoChosen from '../../assets/YesNoButtons/No-chosen.png'
import NoNotChosen from '../../assets/YesNoButtons/No-notChosen.png'
import { guidEmpty } from '../../utils/GuidValidate'
import { useMediaQuery } from 'react-responsive'

//to do: Make the radio label a link to set the value of this radio group choice.
export default ({
  className = "",
  data = [],
  disabled,
  disabledMessage,
  horizontal = false,
  initialValue,
  label,
  labelClass = "",
  name,
  onClick,
  personId,
  position = "before",
  required = false,
  title,
  titleClass,
  whenFilled,
}) => {

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  if (!name && !data) {
    name = data.replace(/ /g, "");
  }

  const [chosenId, setChosenId] = useState()
  const [noIds, setNoIds] = useState() //We will proactively set a list of not chosen Ids so that there is some reponse recorded at least even if the user is eliminating the choices by choosing 'no' although the actual positive choice has not yet been made.

  useEffect(() => {
    if (!initialValue && initialValue === null || initialValue === undefined) {
      setChosenId('PRERESPONSE')
    } else {
      setChosenId(initialValue === 'PRERESPONSE' || initialValue === guidEmpty ? null : initialValue) //initialValue will most likely be filled in with an answer guid
    }
  }, [initialValue])

  const handleClick = (newChosen, noId) => { //We will proactively set a list of not chosen Ids so that there is some reponse recorded at least even if the user is eliminating the choices by choosing 'no' although the actual positive choice has not yet been made.
    if (disabled) {
      disabledMessage()
      return // Prevent changes if disabled
    }
    if (noId) {
      if (!(noIds?.length > 0 && noIds.indexOf(noId) > -1)) { //don't add the noId if it is already in the list.
        const newList = noIds?.length > 0 ? noIds.concat(noId) : [noId]
        setNoIds(newList)
      }
    } 
    if (newChosen) {
      setChosenId(newChosen === 'UNCHOOSE' ? null : newChosen) // Set to true or false when clicked
      onClick(newChosen, personId)
    }
  }

  const isInNoIds = (id) => {
    return noIds?.length > 0 && noIds.indexOf(id) > -1 ? true : false
  }

  return (
    // <div className={classes(styles.container, className)}>
    <div className="checkbox-wrapper-55">
      {(title || label) &&
        <div className={styles.row}>
          {(title || label) &&
            <span className={classes(styles.titleClass, titleClass, required ? styles.lower : '', disabled ? styles.lowOpacity : '')}>
              {title || label}
            </span>
          }
          <div className={styles.leftDown}>
            <Required setIf={required} setWhen={whenFilled}/>
          </div>
        </div>
      }
      <div className={(horizontal ? styles.horizontal : styles.radio)}>
        {data.map((d, index) => {
          return (
            <div key={index} className={classes(isMobile ? styles.rowMobile : styles.row, className, (horizontal ? styles.spaceBetween : styles.spaceBelow))}>
              {position === "before" ?
                <div className={styles.toggleContainer}>
                  <div onClick={() => handleClick(d.id)}>
                    <img src={(chosenId === null || chosenId == 'PRERESPONSE') && !isInNoIds(d.id) ? YesNotChosen : chosenId === d.id ? YesChosen : YesNotChosen} alt="Toggle Button" className={styles.toggleImage} />
                  </div>
                  <div onClick={() => handleClick(chosenId === d.id ? 'UNCHOOSE' : null, d.id)}>
                    <img src={(chosenId === null || chosenId == 'PRERESPONSE') && !isInNoIds(d.id) ? NoNotChosen : chosenId !== d.id ? NoChosen : NoNotChosen} alt="Toggle Button" className={styles.toggleImage} />
                  </div>
                </div>
                : ''}
              <a className={classes(styles.label, (labelClass ? labelClass : ''), (d.correction === 'wrong' ? styles.wrong : d.correction === 'correct' ? styles.correct : ''))}
                onClick={() => !disabled && handleClick(d.id)}>{d.label}</a>
              {position !== "before" ?
                <div className={styles.toggleContainer}>
                  <div onClick={() => handleClick(d.id)}>
                    <img src={(chosenId === null || chosenId == 'PRERESPONSE') && !isInNoIds(d.id) ? YesNotChosen : chosenId === d.id ? YesChosen : YesNotChosen} alt="Toggle Button" className={styles.toggleImage} />
                  </div>
                  <div onClick={() => handleClick(chosenId === d.id ? 'UNCHOOSE' : null, d.id)}>
                    <img src={(chosenId === null || chosenId == 'PRERESPONSE') && !isInNoIds(d.id) ? NoNotChosen : chosenId !== d.id ? NoChosen : NoNotChosen} alt="Toggle Button" className={styles.toggleImage} />
                  </div>
                </div>
                : ''}
            </div>
          )
        })}
      </div>
    </div>
  )
};
