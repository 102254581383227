import React from 'react';
import styles from './AudioDisplay.module.css';
import classes from 'classnames';

export default ({
  browserMessage, 
  className, 
  controls="controls", 
  deleteFunction, 
  isOwner, 
  isSmall ,
  preload="auto", 
  src, 
}) => {
    return (
        <div className={styles.container}>
						<audio src={src} preload={preload} controls={controls} className={classes((isSmall ? styles.small : ''), className)} >
							 {!browserMessage && 'This browser does not support this audio control'}
							 {browserMessage}
						</audio>
            {isOwner && deleteFunction &&
								<div onClick={deleteFunction} className={styles.remove}>
                    remove
                </div>
						}
        </div>
    )
}
