const globalAddMarginLeft = 36
const debugOutline = false

export const setListLevel = ({
  currentElement,
  elementListItem,
  direction,
  chapterId,
  edits,
  chapterListLevels,
  listLevelGeneral,
  addChapterListLevels,
  chosenSegment,
}) => {

  //Ensure that the currentElement is a span
  if (!(currentElement && currentElement.nodeName === 'SPAN')) {
    let lastChosen = chosenSegment && chosenSegment[chosenSegment - 1]
    if (lastChosen && lastChosen.id) currentElement = document.querySelector(`span[id="${lastChosen.id}"][data-type='TEXT']`)
  }
  const structure = getListStructure(currentElement, chapterListLevels)
  const elementSpanIndex = getStructureIndex(structure, currentElement)
  const isTargetFirstLevel = elementSpanIndex && structure[elementSpanIndex] && structure[elementSpanIndex].level === 1
  const hasMultipleTabIndents = getTabMarginCount(elementListItem)
  const hasChildren = getHasChildren(elementListItem)
  const isFirstChildOfList = getIsFirstChildOfList(currentElement)

  // if (chapterListLevels && chapterListLevels.length > 0) {
  // 	chapterListLevels = chapterListLevels.filter(m => m.topListElementId === Number(structure[0].element.id))
  // 	if (!(chapterListLevels && chapterListLevels.length > 0)) chapterListLevels = setChapterListLevels(chapterId, structure, addChapterListLevels)
  // } else {
  // 	chapterListLevels = setChapterListLevels(chapterId, structure, addChapterListLevels)
  // 	//setTimeout(() => getChapterListLevels('', chapterId), 500)
  // }
  // //Fill in the chapterListLevels with the other
  // if (!(chapterListLevels && chapterListLevels.length > 0)) {
  chapterListLevels = listLevelGeneral
  // } else {
  // 	let listType = chapterListLevels[0].listType
  // 	let maxLevel = chapterListLevels.reduce((acc, m) => acc < m.level ? m.level : acc, 0)
  // 	let addListLevels = listLevelGeneral && listLevelGeneral.length > 0 && listLevelGeneral.filter(m => m.listGroup === listType && m.level > maxLevel)
  // 	chapterListLevels = chapterListLevels.concat(addListLevels)
  // }
  //if (debugOutline) debugger

  if (direction === 'MINUS') {
    const hasAboveStructure = getAboveStructure(structure, elementSpanIndex)
    const hasSiblingsUpper = getSiblingsUpper(elementListItem) //the previousSibling listItem
    const hasSiblingsLower = getSiblingsLower(elementListItem) //true or false
    const hasUpperLeftElementOneIndent = getUpperLeftElementOneIndent(currentElement, elementListItem, chapterListLevels)  //returns listItem
    const hasUpperLeftElementTwoIndent = getUpperLeftElementTwoIndent(currentElement, elementListItem, chapterListLevels)  //returns listItem
    const hasUpperLeftElementMoreIndent = getUpperLeftElementMoreIndent(currentElement, elementListItem, chapterListLevels)  //returns ?? listItem?
    const hasLowerLeftElementOneIndent = getLowerLeftElementOneIndent(currentElement, elementListItem, chapterListLevels)  //returns listItem
    const hasGrandParent = getGrandParent(currentElement)

    // XXXX
    //             0000
    // XXXX
    if (hasMultipleTabIndents > 1 && isFirstChildOfList && !hasChildren && !hasSiblingsLower && !hasLowerLeftElementOneIndent) {
      leftHasMultipleTabIndentsIsFirstChNoChNoLwrSibNoLwrLeft({ elementListItem, chapterListLevels })

      // XXXX
      //             0000
      //                   |XXXX|
      //             XXXX
    } else if (hasMultipleTabIndents > 1 && isFirstChildOfList && hasSiblingsLower && !hasLowerLeftElementOneIndent && !hasUpperLeftElementOneIndent && hasUpperLeftElementTwoIndent) {
      leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeftHasUpLeftTwoIndent({
        elementListItem,
        chapterListLevels,
      })

      // XXXX
      //             0000
      //                   XXXX
      //             XXXX
      //       XXXX
      //} else if (hasMultipleTabIndents > 1 && isFirstChildOfList && hasSiblingsLower && hasLowerLeftElementOneIndent && !hasUpperLeftElementOneIndent && hasUpperLeftElementTwoIndent) {
      // } else if (hasMultipleTabIndents > 1 && isFirstChildOfList && hasSiblingsLower && hasLowerLeftElementOneIndent && hasUpperLeftElementTwoIndent) {
      //   leftHasMultipleTabIndentsIsFirstChHasLwrSibHasLwrLeftHasUpLeftTwoIndent({
      //     currentElement,
      //     elementListItem,
      //     chapterListLevels,
      //     hasUpperLeftElementTwoIndent,
      //     hasLowerLeftElementOneIndent
      //   })

      // XXXX
      //                    0000
      //                          |XXXX|
      //                    XXXX
    } else if (hasMultipleTabIndents > 1 && isFirstChildOfList && hasSiblingsLower && !hasLowerLeftElementOneIndent && hasUpperLeftElementTwoIndent) {
      leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeft({
        currentElement,
        elementListItem,
        chapterListLevels
      })

      // XXXX
      //             0000
      // XXXX or XXXX (siblings or children)
      // } else if (hasMultipleTabIndents > 1) {
      // 	leftHasMultipleTabIndents({currentElement, structure, elementListItem, elementSpanIndex, chapterListLevels})

      //<LI>
      //  <UL> (or <OL>
      //    <LI>
      //      elementSpan
      // } else if (hasDirectDoubleIndent) {
      // 	leftHasDirectDoubleIndent({currentElement, elementListItem})

      //0. Find that there is preceding structure and if this is a first level target moving to the left (and outside of the structure)
      // XXXX
      // 0000
      // XXXX or XXXX (siblings or children)
    } else if (hasAboveStructure && isTargetFirstLevel) {
      leftHasUpStrIsFirstLvl({ currentElement, structure, elementListItem, chapterListLevels })

      //0. Find that there has no preceding structure and if this is a first level target moving to the left (and outside of the structure)
      // 0000
      // XXXX or XXXX (siblings or children)
    } else if (!hasAboveStructure && isTargetFirstLevel) {
      leftNoUpStrIsFirstLvl({ currentElement, structure, elementListItem })

      // XXXX
      //      0000
      //            XXXX
      //      XXXX
    } else if (hasChildren && hasUpperLeftElementOneIndent && !hasLowerLeftElementOneIndent && !hasUpperLeftElementMoreIndent) {
      leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasNoLowerLeft({
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels
      })

      // XXXX
      //      0000
      //            XXXX
      //      XXXX
    } else if (hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasUpperLeftElementOneIndent && hasLowerLeftElementOneIndent) { // && !hasUpperLeftElementMoreIndent
      leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasLowerLeft({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })
      //0. Find that this target has no children but no siblings above or below, but has an upper left level that is one indent away (or more), but has one lower level that is one indent in.
      //This needs to be before the if condition: hasChildren && !hasSiblingsUpper && !hasSiblingsLower
      // XXXX
      //      XXXX
      //                  0000
      //            XXXX
    } else if (hasChildren && !hasSiblingsUpper && !hasSiblingsLower && hasLowerLeftElementOneIndent && hasUpperLeftElementTwoIndent) {
      leftHasChNoUpSibNoDnSibHasDnLftOneTabHasUpLftOneTab({
        elementListItem,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })

      //      XXXX
      //            XXXX
      //      0000
      // XXXX
    } else if (!hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasLowerLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSibHasLwrLftOneTab({ currentElement, elementListItem, chapterListLevels, hasLowerLeftElementOneIndent })

      //      XXXX
      //                  XXXX
      //            0000
      //      XXXX
    } else if (!hasChildren && !hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftNoChNoUpSibNoDnSibHasUpLftOneTab({ currentElement, elementListItem, hasUpperLeftElementOneIndent, chapterListLevels, hasLowerLeftElementOneIndent })

      //0. Find that this target has no children and no siblings above or below and not structure above
      //        0000
      //  XXXX
    } else if (!hasAboveStructure && !hasChildren && !hasSiblingsUpper && !hasSiblingsLower) {
      leftNoChNoUpSibNoDnSibNoUpperStr({ currentElement, elementListItem, hasLowerLeftElementOneIndent, chapterListLevels })

      // XXXX
      //       XXXX
      //                  0000
      //            XXXX
    } else if (!hasChildren && !hasSiblingsUpper && !hasSiblingsLower && hasLowerLeftElementOneIndent) {
      leftNoChNoUpSibNoDnSibHasLowerOneIndent({
        elementListItem,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has no children and no siblings above or below
      // XXXX
      //       XXXX
      //                  0000
      //            XXXX
    } else if (!hasChildren && !hasSiblingsUpper && !hasSiblingsLower) {
      leftNoChNoUpSibNoDnSib({ currentElement, elementListItem, chapterListLevels, chosenSegment })

      // XXXX
      //            0000
      //            XXXX
      //            XXXX
      // XXXX
    } else if (!isTargetFirstLevel && hasSiblingsLower && !hasChildren && !hasSiblingsUpper && !hasUpperLeftElementOneIndent && !hasLowerLeftElementOneIndent) {
      leftNoFirstLvlNoUpLftOneTabNoLwrLftOneTab({
        elementListItem,
        chapterListLevels,
      })


      //This needs to be above leftNoChNoUpSibHasDnSibHasLwrLftOneTab
      // XXXX
      //      0000
      //      XXXX
      // XXXX
    } else if (!hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasLowerLeftElementOneIndent && hasUpperLeftElementOneIndent) {
      leftNoChNoUpSibHasDnSibHasLwrLftOneTabHasUpLeftOneTab({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })


      //This needs to be above: !hasChildren && !hasSiblingsUpper && hasSiblingsLower
      // XXXX
      //      XXXX
      //                  0000
      //                  XXXX
      //           XXXX
    } else if (!hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasLowerLeftElementOneIndent) {
      leftNoChNoUpSibHasDnSibHasLwrLftOneTab({
        currentElement,
        elementListItem,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })

      // XXXX
      //      XXXX
      //                  0000
      //                        XXXX
      //                  XXXX
      //           XXXX
    } else if (hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasLowerLeftElementOneIndent) {
      leftHasChNoUpSibHasDnSibHasLwrLftOneTab({
        elementListItem,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })

      //0. Find that this target has no children but has siblings below but not above, but has an upper left level that is one indent away (or more).
      //This needs to be above: !hasChildren && !hasSiblingsUpper && hasSiblingsLower
      // XXXX
      //      XXXX
      //                  0000
      //                  XXXX
    } else if (!hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasUpperLeftElementMoreIndent) {
      leftNoChNoUpSibHasDnSibHasUpLftMoreTab({ elementListItem, hasUpperLeftElementMoreIndent, chapterListLevels })

      //0. Find that this target has no children and no siblings above but has siblings below
      // XXXX
      //      XXXX
      //            0000
      //            XXXX
    } else if (!hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftNoChNoUpSibHasDnSibHasUpLeftOneTab({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels
      })

      //0. Find that this target has one or more children but no siblings above or below, and that the upper left list is one more tab indent over (or more).
      //This needs to be above: hasChildren && !hasSiblingsUpper && !hasSiblingsLower
      // XXXX
      //      XXXX
      //                  0000
      //                        XXXX
    } else if (hasChildren && !hasSiblingsUpper && !hasSiblingsLower && !hasUpperLeftElementOneIndent && !hasLowerLeftElementOneIndent) {
      leftHasChNoUpSibNoDnSibHasUpLftMoreTab({
        currentElement,
        elementListItem,
        hasUpperLeftElementMoreIndent,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })

      //0. Find that this target has one or more children but no siblings above or below.
      // XXXX
      //      XXXX
      //            0000
      //                  XXXX
    } else if (hasChildren && !hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftHasChNoUpSibNoDnSib({
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels
      })

      //0. Find that this target has no children, but has siblings above but not below
      // XXXX
      //      XXXX
      //            XXXX
      //            0000
      //      XXXX
    } else if (!hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSib({
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels,
      })

      //0. Find that this target has no children, but has siblings above but not below
      // XXXX
      //            XXXX
      //            0000
      //      XXXX
    } else if (!hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasGrandParent && hasLowerLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSibHasParentHasLwrLeftOneIndent({
        elementListItem,
        chapterListLevels,
        hasGrandParent,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has no children, but has siblings above but not below
      // XXXX
      //            XXXX
      //            0000
      //                  |XXX|
      // XXXX
    } else if (hasMultipleTabIndents > 1 && hasSiblingsUpper && !hasSiblingsLower && !hasLowerLeftElementOneIndent) {  // && !hasChildren - we can handle whether they have children or not.
      leftHasMultTabsNoChHasUpSibNoDnSibNoLwrLeftOneIndent({
        elementListItem,
        chapterListLevels,
      })

      //0. Find that this target has no children, but has siblings above (but could have siblings below or not)
      // XXXX
      //      XXXX
      //            XXXX
      //            0000
      //      XXXX | XXXX (could have siblings)
    } else if (!hasChildren && hasSiblingsUpper && hasUpperLeftElementOneIndent) {
      leftNoChHasUpSibHasUpLftOneTab({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has children and has siblings above but not below
      // XXXX
      //      XXXX
      //            XXXX
      //            0000
      //                  XXXX
    } else if (hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftHasChHasUpSibNoDnSib({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has children, but has siblings above but not below
      // XXXX
      //      XXXX
      //            XXXX
      //            0000
      //                  XXXX
      //            XXXX
    } else if (hasChildren && hasSiblingsUpper && hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftHasChHasUpSibHasDnSibHasUprLeftOneIndent({
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels,
      })

      //0. Find that this target has no children and siblings below but not siblings above but has an upper right element with an upper left element.
      // XXXX
      //      XXXX
      //                  XXXX
      //            0000
      //            XXXX
    } else if (!hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSibHasUpLeftOneTab({
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //            XXXX
      //            0000
      //            XXXX
      //       XXXX
    } else if (!hasChildren && hasSiblingsUpper && hasSiblingsLower && !hasUpperLeftElementOneIndent && hasLowerLeftElementOneIndent) {
      leftNoChHasUpSibHasDnSibNoUpLeftOneTabHasLwrLeftOneTab({ currentElement, elementListItem, chapterListLevels, hasLowerLeftElementOneIndent })

      // XXXX
      //                |XXX| |XXX|
      //                0000
      //          XXXX
    } else if (!hasUpperLeftElementOneIndent && !hasUpperLeftElementTwoIndent && hasLowerLeftElementOneIndent) {
      leftHasLwrLeftIndentNoUprLeftIndentNoUprLeftTwoIndent({
        elementListItem,
        chapterListLevels,
        hasLowerLeftElementOneIndent,
      })

      //            XXXX
      //            0000
      //            XXXX
    } else if (!hasChildren && hasSiblingsUpper && hasSiblingsLower && !hasUpperLeftElementOneIndent) {
      leftNoChHasUpSibHasDnSibNoUpLeftOneTab({ currentElement, elementListItem, chapterListLevels })

      // XXXX
      //                   0000
      //                   XXXX
      // XXXX
    } else if (hasSiblingsUpper && !hasSiblingsLower && !hasUpperLeftElementOneIndent && !hasUpperLeftElementTwoIndent && !hasUpperLeftElementMoreIndent && !hasLowerLeftElementOneIndent) { //Might have children or not - we'll cover both here.
      leftHasUpSibNothingElseOtherThanChildren({ elementListItem, chapterListLevels })

      // XXXX
      //                   0000
      //                         |XXX|
      // XXXX
    } else if (!hasSiblingsUpper && !hasSiblingsLower && !hasUpperLeftElementOneIndent && !hasUpperLeftElementTwoIndent && !hasUpperLeftElementMoreIndent && !hasLowerLeftElementOneIndent) { //Might have children or not - we'll cover both here.
      leftHasNothingElseOtherThanChildren({ elementListItem, chapterListLevels })

    }

    //*******************************************    Right Moves    ************************************
    //*******************************************                   ************************************
    //*******************************************                   ************************************
    //*******************************************                   ************************************
  } else if (direction === 'PLUS') {
    const rightHasSiblingsUpper = elementListItem.previousElementSibling
    const rightHasSiblingsLower = elementListItem.nextElementSibling
    const hasUpperRightElementOneIndent = getUpperRightElementOneIndent(currentElement, elementListItem, chapterListLevels)
    const hasLowerRightElementOneIndent = getLowerRightElementOneIndent(currentElement, elementListItem, chapterListLevels) //{list, listItem}
    const hasUpperRightElementTwoIndent = getUpperRightElementTwoIndent(currentElement, elementListItem, chapterListLevels)
    const hasLowerRightElementTwoIndent = getLowerRightElementTwoIndent(currentElement, elementListItem, chapterListLevels) //{list, listItem}
    // 0000
    //      XXXX
    if (hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent && hasLowerRightElementOneIndent) {
      rightHasChNoLwrSibNoUpSibNoUpRtIndent({ currentElement, elementListItem, chapterListLevels, hasLowerRightElementOneIndent })

      //XXXX
      //          0000
      //                XXXX
    } else if (isFirstChildOfList && !rightHasSiblingsLower && !hasUpperRightElementOneIndent && hasLowerRightElementOneIndent) {
      rightFirstChildNoLwrSibNoUpRtOneTabHasLwrRtOneTab({ elementListItem, chapterListLevels, hasLowerRightElementOneIndent })

      //XXXX
      //          0000
      //          XXXX
    } else if (isFirstChildOfList && !hasChildren && rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightFirstChildNoChHasLwrSibNoUpRtOneTab({ currentElement, elementListItem, chapterListLevels })

      //XXXX
      //          0000
      //                  XXXX
      //          XXXX
    } else if (isFirstChildOfList && hasChildren && rightHasSiblingsLower && !hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent && hasLowerRightElementTwoIndent) {
      rightFirstChildHasChHasLwrSibNoUpRtOneTab({ elementListItem, chapterListLevels })


      //XXXX
      //          0000
      //                              XXXX
      //          XXXX
    } else if (isFirstChildOfList && hasChildren && rightHasSiblingsLower && !hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent && !hasLowerRightElementTwoIndent) {
      rightFirstChildHasChHasLwrSibNoUpRtOneTab({ elementListItem, chapterListLevels })

      // XXXX
      //      0000
    } else if (!hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent) {
      rightNoChNoUpSibNoDnSibNoUpperRtIndent({ currentElement, elementListItem, chapterListLevels })

      //            XXXX
      //      0000
      // XXXX
    } else if (!hasChildren && !rightHasSiblingsLower && hasUpperRightElementOneIndent) { //&& !hasSiblingsUpper  this shouldn't matter because the first thing that is going to be upper is a right element one intende
      rightNoChNoUpSibNoDnSibHasUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        chapterListLevels
      })

      // XXXX
      //      0000
      //            XXXX
      //      XXXX
    } else if (hasChildren && !rightHasSiblingsUpper && !hasUpperRightElementOneIndent && hasLowerRightElementOneIndent) { //&& rightHasSiblingsLower   - it shouldn't matter that there are lower siblings
      rightHasChNoUpSibHasDnSibNoUpRtIndent({ elementListItem, chapterListLevels, hasLowerRightElementOneIndent })

      //            XXXX
      //      0000
      //      XXXX
    } else if (!hasChildren && !rightHasSiblingsUpper && rightHasSiblingsLower && hasUpperRightElementOneIndent) {
      rightNoChNoUpSibHasDnSibHasUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        chapterListLevels
      })

      //      XXXX
      //      0000
      //      XXXX
    } else if (!hasChildren && rightHasSiblingsUpper && rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightNoChHasUpSibHasDnSibNoUpRtIndent({ currentElement, elementListItem, chapterListLevels })

      //      XXXX
      //      0000
      // XXXX
    } else if (!hasChildren && rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent && !hasUpperRightElementTwoIndent && !hasLowerRightElementTwoIndent) {
      rightNoChHasUpSibNoDnSibNoUpRtIndent({ elementListItem, chapterListLevels })

      //This needs to come before rightHasChHasUpSibHasLwrRtIndent
      //           XXXX
      //      0000
      //           XXXX
      //} else if (hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && hasUpperRightElementOneIndent) {
    } else if (hasChildren && hasUpperRightElementOneIndent && hasLowerRightElementOneIndent) {  // && !rightHasSiblingsUpper - this doesn't matter since the join should be the same and the sibling list will fill in.
      rightHasChNoUpSibHasUpRtOneIndent({
        elementListItem,
        hasUpperRightElementOneIndent,
        chapterListLevels
      })

      //This needs to come before rightHasChHasUpSibHasLwrRtIndent
      //      XXXX
      //           XXXX
      //      0000
      //           XXXX
      //      XXXX
      //} else if (hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && hasUpperRightElementOneIndent) {
    } else if (hasChildren && hasUpperRightElementOneIndent && hasLowerRightElementOneIndent && rightHasSiblingsUpper && rightHasSiblingsLower) {
      rightHasChHasUpSibHasDnSibHasUpRtIndentHasLwrRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        hasLowerRightElementOneIndent,
        chapterListLevels
      })

      //      XXXX
      //      0000
      //                XXXX
      //           XXXX
      //This needs to be before rightHasChHasUpSibNoDnSibNoUpRtIndent
    } else if (hasChildren && rightHasSiblingsUpper && !rightHasSiblingsLower && hasLowerRightElementOneIndent && !hasUpperRightElementOneIndent) { //&& !hasSiblingsLower whether there is a sibling lower or not doesn't matter here since the next one down is a child.
      rightHasChHasUpSibHasLwrRtIndent({
        elementListItem,
        chapterListLevels,
        hasLowerRightElementOneIndent
      })

      // XXXX|XXXX
      //      0000
      //                XXXX
      //           XXXX
      //This needs to be before rightHasChHasUpSibNoDnSibNoUpRtIndent
    } else if (hasChildren && !hasUpperRightElementOneIndent && hasLowerRightElementOneIndent) { //&& !hasSiblingsLower whether there is a sibling lower or not doesn't matter here since the next one down is a child.
      rightHasChHasLwrRtIndent({
        currentElement,
        elementListItem,
        chapterListLevels,
        hasLowerRightElementOneIndent
      })

      //This needs to be before rightHasChHasUpSibNoDnSibNoUpRtIndent
      //                  XXXX
      //      0000
      //                  XXXX
    } else if (!hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent && !rightHasSiblingsLower && !rightHasSiblingsUpper) { //&& !hasSiblingsLower whether there is a sibling lower or not doesn't matter here since the next one down is a child.
      rightNoSblUpperNoSblLowerNoUprRtOneIndentNoLwrRtOneIndent({
        elementListItem,
        chapterListLevels,
      })

      //      XXXX
      //      0000
      //           XXXX
    } else if (hasChildren && rightHasSiblingsUpper && hasLowerRightElementOneIndent && !hasUpperRightElementOneIndent) { //&& !hasSiblingsLower whether there is a sibling lower or not doesn't matter here since the next one down is a child.
      rightHasChHasUpSibNoDnSibNoUpRtIndent({
        currentElement,
        elementListItem,
        chapterListLevels,
        hasLowerRightElementOneIndent
      })

      // XXXX
      //           0000
      //           XXXX
    } else if (!hasChildren && !rightHasSiblingsUpper && rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightNoChNoUpSibHasDnSibNoUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        chapterListLevels
      })

      // XXXX
      // 0000
      //            XXXX
    } else if (!hasChildren && rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent) { //&& !hasSiblingsUpper && !hasSiblingsLower can show up if there are records further below or upward, but really, we are just going to put in another list and listItem to push this over no matter what is above or below it in this case.
      rightNoChHasUpSibNoDnSibNoUpRtIndent({ currentElement, elementListItem, chapterListLevels })

      // XXXX
      //      0000
      // XXXX
    } else if (!hasChildren && !hasUpperRightElementOneIndent) { //&& !hasSiblingsUpper && !hasSiblingsLower can show up if there are records further below or upward, but really, we are just going to put in another list and listItem to push this over no matter what is above or below it in this case.
      rightNoChNoUpSibNoDnSibNoUpRtIndent({ currentElement, elementListItem, chapterListLevels })

      //      XXXX
      //            XXXX
      //      0000
      //      XXXX
    } else if (!hasChildren && rightHasSiblingsUpper && rightHasSiblingsLower && hasUpperRightElementOneIndent) {
      rightNoChHasUpSibHasDnSibHasUpRtIndent({
        currentElement,
        elementListItem,
        chapterListLevels,
        hasUpperRightElementOneIndent
      })

      //   // XXXX
      //   //            XXXX
      //   //      0000
      //   //      XXXX
      // } else if (!hasChildren && !rightHasSiblingsUpper && rightHasSiblingsLower && hasUpperRightElementOneIndent) {
      //   rightNoChNoUpSibHasDnSibHasUpRtIndent({
      //     currentElement,
      //     elementListItem,
      //     chapterListLevels,
      //     hasUpperRightElementOneIndent
      //   })

    //   //      XXXX
    //   //            XXXX
    //   //      0000
    //   //      XXXX
    // } else if (hasUpperRightElementOneIndent && hasUpperRightElementTwoIndent) {
    //   rightHasUpRtIndentHasUpRtTwoIndent({
    //     elementListItem,
    //     chapterListLevels,
    //     hasUpperRightElementOneIndent,
    //   })

      //      XXXX
      //      0000
      //                           XXXX
      //      |XXX|
    } else if (hasChildren && rightHasSiblingsUpper && !hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent) { //It doesn't matter if there is a lower isbling or not:  && rightHasSiblingsLower
      rightHasChildrenHasUpsiblingNoUpRtIndentNoUpLeftIndent({
        elementListItem,
        chapterListLevels,
      })


      //      0000
      //                           XXXX
    } else if (hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent && !hasUpperRightElementTwoIndent) { //hasLowerRightElementTwoIndent - this one doesn't matter if it is there or not, it will be the same movement.
      rightHasChNoUpsiblingNoUpRtIndentNoRtTwoIndentNoUpLeftIndentNoLeftTwoIndent({
        elementListItem,
        chapterListLevels,
      })

      // XXXX
      //      XXXX
      //      XXXX
      //            XXXX
      // 0000
      //                           XXXX
    } else if (hasChildren && rightHasSiblingsUpper && !rightHasSiblingsLower && hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent) { //hasLowerRightElementTwoIndent - this one doesn't matter if it is there or not, it will be the same movement.
      rightHasChHasUpsiblingHasUpRtIndent({
        elementListItem,
        chapterListLevels,
        hasUpperRightElementOneIndent
      })

    }
  }
}

export const getListStructure = (currentElement, chapterListLevels) => {
  //When looking for the topList, we actually need to go all the way to the main-body-div or the editorDiv. Then we can pick up the last Ol or UL before we hit that very top.
  //    The reason for that is that when we use this structure for shift-tab for the editor in order to put the cursor into the previous span, if we are just looking
  //    for the fist OL or UL then we are stopping short and not able to get the user up into the next span above the current OL or UL - which could be many of embedded lists.
  if (currentElement) {
    let structure = []
    let editorDivTop
    let topList = currentElement.nodeName === 'UL' || currentElement.nodeName === 'OL' ? currentElement : ''
    let pointerElement
    let parent = currentElement.parentElement
    const editorDivId = getEditorDivIdFromElementId(currentElement.id)
    //Once we find the editorDivTop going backwards up the next parent, we would have saved the last topList element of the current list structure, so we are good to go and use tha topList.
    while (!(editorDivTop && topList) && parent) {
      if (parent && (parent.nodeName === 'UL' || parent.nodeName === 'OL')) topList = parent
      if (parent && (parent.nodeName === editorDivId || parent.dataset.mainBodyTag)) editorDivTop = parent
      parent = parent.parentElement
    }
    if (topList) {
      structure.push({
        level: 0,
        marginLeft: topList.style['margin-left'],
        style: topList.style.cssText,
        listStyle: topList.style['list-style'],
        listStyleType: topList.style['list-style-type'],
        element: topList
      })  //I know that we are starting with level 0 on this UL/OL record. It is because we increment the level on LI and then step back to update the LI parent's UL/OL record to one more level up.\\
      pointerElement = topList
      structure = addListChildren(structure, pointerElement.children, currentElement, chapterListLevels)
    }
    return structure
  }
}

const addListChildren = (structure, children, currentElement, chapterListLevels) => {
  const listType = getListType(children)
  for (let i = 0; i < children.length; i++) {
    const level = getListItemLevelDepth(listType, children[i], chapterListLevels)
    //If this is the LI record, then step back one record in order to update it's parent record.
    //if (children[i].nodeName === 'UL' || children[i].nodeName === 'OL') structure = incrementListItemParentLevelOne(structure)
    structure.push({
      level: level,
      marginLeft: children[i].style['margin-left'],
      style: children[i].style.cssText,
      listStyle: children[i].style['list-style'],
      listStyleType: children[i].style['list-style-type'],
      element: children[i]
    })
    structure = addListChildren(structure, children[i].children, currentElement, chapterListLevels)
  }
  return structure
}

export const getStructureIndex = (structure = [], currentElement) => {
  let index
  structure.forEach((m, i) => {
    if (m.element === currentElement) index = i
  })
  return index
}

const getAboveStructure = (structure, elementSpanIndex) => {
  if (structure) {
    let upperStructure = structure.slice(0, elementSpanIndex - 1)
    let content = upperStructure.filter(m => m.element.nodeName === 'SPAN')
    return content && content.length > 0
  }
}

const getHasChildren = (elementListItem) => {
  //We don't mean to count SPANs as children. Those are just labels and part of the elementListItem
  //We are looking for OL or UL lists which contain children listItems and text
  for (let i = 0; i < elementListItem.children.length; i++) {
    if ((elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') && elementListItem.children[i].children.length > 0) return true
  }
}

const getSiblingsUpper = (elementListItem) => {
  let siblings = []
  let loop = 0
  let previousSibling = elementListItem.previousSibling
  while (previousSibling && loop < 1000) {
    if (previousSibling.nodeName === 'LI') siblings.push(previousSibling)
    previousSibling = previousSibling.previousSibling
    loop++
  }
  return siblings?.length > 0 ? siblings : null
}

const getSiblingsLower = (elementListItem) => {
  let siblings = []
  let loop = 0
  let nextSibling = elementListItem.nextSibling
  while (nextSibling && loop < 1000) {
    if (nextSibling.nodeName === 'LI') siblings.push(nextSibling)
    nextSibling = nextSibling.nextSibling
    loop++
  }
  return siblings?.length > 0 ? siblings : null
}

const getIsFirstChildOfList = (currentElement) => {
  if (currentElement && currentElement.nodeName && currentElement.parentElement) {
    if (currentElement.nodeName === 'LI') currentElement = currentElement.firstChild
    const listItem = currentElement.parentElement
    const list = listItem.parentElement
    let isFirstListItem = list.children[0] === listItem
    let isFirstContents
    for (let i = 0; i < listItem.children.length; i++) {
      if (!isFirstContents && listItem.children[i].nodeName === 'SPAN' && listItem.children[i].id && listItem.children[i] == currentElement) {
        isFirstContents = true
        break
      }
    }
    return isFirstListItem && isFirstContents
  }
}

const getUpperLeftElementOneIndent = (currentElement, elementListItem, chapterListLevels) => {
  if (currentElement) {
    const listType = getListType(currentElement)
    let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels) //This needs to be a listItem  
    targetLevel--
    let listItem = currentElement.parentElement
    let list = listItem.parentElement
    let parentListItem = list.parentElement
    //If the parentListItem is an OL or UL, then this must be an OL/UL stacked right on top of an OL/UL so that we will get a fales reading about an upper targetLevel that matches as it looks actually below the elementListItem to see a lower one. So we'll just set this as false under this condition.
    if (parentListItem.nodeName === 'UL' || parentListItem.nodeName === 'OL') return false
    if (parentListItem.nodeName === 'LI' && getListItemLevelDepth(listType, parentListItem, chapterListLevels) === targetLevel) {
      return parentListItem
    } else {
      //Second, we will loop through the children of the parentListItem to see if there are any elements that see if there is an upper listItem that is one tab to the left compared to the current listItem
      let foundCurrentListItem = false
      for (let i = 0; i < parentListItem.children.length; i++) {
        if (!foundCurrentListItem && parentListItem.children[i].nodeName === 'LI' && getListItemLevelDepth(listType, parentListItem.children[i], chapterListLevels) === targetLevel) {
          return parentListItem.children[i]
        } else {
          if (parentListItem.children[i] === listItem) foundCurrentListItem = true
        }
      }
    }
  }
}

const getUpperLeftElementTwoIndent = (currentElement, elementListItem, chapterListLevels) => {
  if (currentElement) {
    const listType = getListType(currentElement)
    let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels) //This needs to be a listItem  
    targetLevel--
    targetLevel-- //Two tabs to the left of the currentLevel.
    let listItem = currentElement.parentElement
    let list = listItem.parentElement
    let parentListItem = list.parentElement
    if (parentListItem.nodeName === 'LI' && getListItemLevelDepth(listType, parentListItem, chapterListLevels) === targetLevel) {
      return parentListItem
    } else {
      //Second, we will loop through the children of the parentListItem to see if there are any elements that see if there is an upper listItem that is one tab to the left compared to the current listItem
      let foundCurrentListItem = false
      for (let i = 0; i < parentListItem.children.length; i++) {
        if (!foundCurrentListItem && parentListItem.children[i].nodeName === 'LI' && getListItemLevelDepth(listType, parentListItem.children[i], chapterListLevels) === targetLevel) {
          return parentListItem.children[i]
        } else {
          if (parentListItem.children[i] === listItem) foundCurrentListItem = true
        }
      }
    }
  }
}

const getGrandParent = (currentElement) => {
  if (currentElement) {
    let parent = currentElement.parentElement
    return parent && parent.parentElement
  }
}

const getUpperLeftElementMoreIndent = (currentElement, elementListItem, chapterListLevels) => {
  if (currentElement && elementListItem) {
    //If there is a parent to the target that is just one level less, then this is one indent back.
    const structure = getListStructure(currentElement, chapterListLevels)
    const elementSpanIndex = getStructureIndex(structure, currentElement)
    const targetLevel = structure[elementSpanIndex]?.level
    const grandParent = elementListItem.parentElement && elementListItem.parentElement.parentElement
    if (grandParent) {
      const parentStructure = structure.filter(m => m.element === grandParent)[0]
      if (parentStructure) {
        return parentStructure.level <= targetLevel - 2 ? parentStructure.element : ''
      }
    }
  }
}

const getLowerLeftElementOneIndent = (currentElement, elementListItem, chapterListLevels) => {
  //There may be a sibling of the parentElement but we need to be sure that it is on the same level as the currentElement
  //First, we will look at the list above to find a sibling of the given list of the currentElement
  //Second, we will go to the parentListItem to see if there is a lower listItem sibling that is one tab left in compared to the current listItem
  // Done: 6/27/2023
  //Reviewed and revised: 24 Oct 2024
  if (currentElement) {
    const listType = getListType(currentElement)
    let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels) //This needs to be a listItem  
    targetLevel--
    let listItem = currentElement.parentElement
    let list = listItem.parentElement
    let parentListItem = list.parentElement //This is where we need to look to see if there are any elements on the targetLevel
    let siblingLevel
    if (listItem.nextSibling) siblingLevel = getListItemLevelDepth(listType, listItem.nextSibling, chapterListLevels) //This needs to be a listItem  
    if (siblingLevel && siblingLevel === targetLevel) {
      return listItem.nextSibling
    } else {
      //Second, we will see if there is a lower listItem that is one tab in compared to the current listItem
      let nextSiblingListItem = list.nextElementSibling
      if (nextSiblingListItem) {
        if (nextSiblingListItem.nodeName === 'OL' || nextSiblingListItem.nodeName === 'UL') nextSiblingListItem = nextSiblingListItem.firstChild
        siblingLevel = getListItemLevelDepth(listType, nextSiblingListItem, chapterListLevels) //This needs to be a listItem parameter
        if (siblingLevel === targetLevel) return nextSiblingListItem //This should be a listItem
      }
    }
    //  Let's look at the children of parentListItem
    let parentListItemNextSibling = parentListItem.nextSibling
    if (parentListItemNextSibling) {
      siblingLevel = getListItemLevelDepth(listType, parentListItemNextSibling, chapterListLevels) //This needs to be a listItem  
      if (siblingLevel === targetLevel) return parentListItemNextSibling //This list.nextSibling should be a listItem?
    }
    let foundCurrentList
    for (let i = 0; i < parentListItem.children.length; i++) {
      if (parentListItem.children[i].nodeName === 'UL' || parentListItem.children[i].nodeName === 'OL') {
        if (!foundCurrentList && parentListItem.children[i] === list) {
          foundCurrentList = true
        } else if (foundCurrentList) {
          if (parentListItem.children[i].nodeName === 'OL' || parentListItem.children[i].nodeName === 'UL') {
            for (let c = 0; c < parentListItem.children[i].children.length; c++) {
              if (getListItemLevelDepth(listType, parentListItem.children[i].children[c], chapterListLevels) === targetLevel) {
                return parentListItem.children[i].children[c] //This is the listItem
              }
            }
          }
        }
      }
    }
  }
}

//Right moves
const getUpperRightElementOneIndent = (currentElement, elementListItem, chapterListLevels) => {
  //Let's limit this check for upper right element as soon as we find a sibling that is less than the targetLevel
  const listType = getListType(currentElement)
  let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels) //This needs to be a listItem
  targetLevel++
  let parentList = elementListItem.parentElement
  let listItemPreviousSibling = elementListItem.previousSibling
  let foundLessThanTargetLevel
  let lastMatchingLevelElement
  if (listItemPreviousSibling) {
    let loop = 0
    //Let's limit this check for upper right element as soon as we find a sibling that is less than the targetLevel. If we found a previousSibling, then we are committed to that realm if we don't find anything then we conclude that there isn't the one-indent that we are looking for
    while (!foundLessThanTargetLevel && listItemPreviousSibling && !lastMatchingLevelElement && loop < 100) {
      for (let i = 0; i < listItemPreviousSibling.children.length; i++) {
        //If we find a level that is less than the target, we then need to look inside of it with its children to see if there is a level that 
        if (getListItemLevelDepth(listType, listItemPreviousSibling.children[i], chapterListLevels) < targetLevel) {
          foundLessThanTargetLevel = true
        }
        for (let c = 0; c < listItemPreviousSibling.children[i].children.length; c++) {
          if (listItemPreviousSibling.children[i].children[c].nodeName === 'LI') {
            if (getListItemLevelDepth(listType, listItemPreviousSibling.children[i].children[c], chapterListLevels) === targetLevel) {  //getListItemLevelDepth needs to take a listItem 
              lastMatchingLevelElement = listItemPreviousSibling.children[i].children[c]
            }
          }
        }
      }
      listItemPreviousSibling = listItemPreviousSibling.previousSibling
    }
    if (lastMatchingLevelElement) return lastMatchingLevelElement
  } else {
    let parentListPreviousSibling = parentList.previousElementSibling
    if (parentListPreviousSibling) {
      for (let i = 0; i < parentListPreviousSibling.children.length; i++) {
        if (parentListPreviousSibling.children[i].nodeName === 'LI' && getListItemLevelDepth(listType, parentListPreviousSibling.children[i], chapterListLevels) === targetLevel) {  //getListItemLevelDepth needs to take a listItem 
          lastMatchingLevelElement = parentListPreviousSibling.children[i]
        }
      }
      if (lastMatchingLevelElement) return lastMatchingLevelElement
    }
  }
}

const getUpperRightElementTwoIndent = (currentElement, elementListItem, chapterListLevels) => {
  //Reach up to the previous sibling and then crawl down any tree structure to get back to the currentElement.
  // Pick up the last listItem and see if it is two tabs away.
  const listType = getListType(currentElement)
  let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels) //This needs to be a listItem  
  targetLevel++
  targetLevel++ //Add two to this targetLevel
  const listItem = currentElement.parentElement
  if (listItem) {
    const list = listItem.parentElement
    if (list) {
      let previousSiblingTree = listItem.previousElementSibling
      if (!previousSiblingTree) {
        previousSiblingTree = list.previousElementSibling
      }
      let lastListItem = ""
      lastListItem = findLastListItem(previousSiblingTree, lastListItem)
      if (getListItemLevelDepth(listType, lastListItem, chapterListLevels) === targetLevel) return lastListItem //This needs to be a listItem
    }
  }
}

const findLastListItem = (element, lastListItem) => {
  if (element && element.children) {
    for (let i = 0; i < element.children.length; i++) {
      if (element.children[i].nodeName === 'LI') lastListItem = element.children[i]
      if (element.children[i].children.length > 0) lastListItem = findLastListItem(element.children[i], lastListItem)
    }
  }
  return lastListItem
}

const getLowerRightElementTwoIndent = (currentElement, elementListItem, chapterListLevels) => {
  //Get the children of the currentElement and find the firstChild.
  const listType = getListType(currentElement)
  let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels) //This needs to be a listItem  
  targetLevel++
  targetLevel++ //Add two to this targetLevel
  const listItem = currentElement.parentElement
  let firstListItem
  for (let i = 0; i < listItem.children.length && !firstListItem; i++) {
    if (listItem.children[i].nodeName === 'LI') firstListItem = listItem.children[i]
    if (listItem.children[i].children.length > 0) {
      for (let c = 0; c < listItem.children[i].children.length && !firstListItem; c++) {
        if (listItem.children[i].children[c].nodeName === 'LI') firstListItem = listItem.children[i].children[c]
      }
    }
  }
  if (getListItemLevelDepth(listType, firstListItem, chapterListLevels) === targetLevel) return firstListItem //This needs to be a listItem  
}


const getLowerRightElementOneIndent = (currentElement, elementListItem, chapterListLevels) => {
  const listType = getListType(currentElement)
  let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels) //This needs to be a listItem  
  targetLevel++
  //Look first for the child under the elementList Item
  //Then further below, go up to the current list, look for the next sibling and see if that first child is on the target level
  // if (elementListItem.children.length > 0) {
  //   if (elementListItem.firstChild.nodeName === 'UL' || elementListItem.firstChild.nodeName === 'OL') {
  //     if (getListItemLevelDepth(listType, elementListItem.firstChild.firstChild, chapterListLevels) === targetLevel) { //This needs to be a listItem 
  //       return elementListItem.firstChild.firstChild
  //     }
  //   }
  // } 
  if (elementListItem.children.length > 0) {
    for (let i = 0; i < elementListItem.children.length; i++) {
      if (elementListItem.children[i].nodeName === 'UL' || elementListItem.children[i].nodeName === 'OL') {
        let elementList = elementListItem.children[i]
        for (let c = 0; c < elementList.children.length; c++) {
          if (getListItemLevelDepth(listType, elementList.children[c], chapterListLevels) === targetLevel) { //This needs to be a listItem 
            return {
              listItem: elementList.children[c],
              list: elementList
            }
          }
        }
      }
    }
    //Let's make sure that if there is a lowerSibling that the immediate lower right one indent exists. If there is a lowerSibling but no indented child, then return false. Otherwise, continue searching for an element for te targetLevel one over
  } else if (elementListItem.nextSibling) {
    const nextSibling = elementListItem.nextSibling
    for (let i = 0; i < nextSibling?.children.length; i++) {
      if (nextSibling.children[i].nodeName === 'OL' || nextSibling.children[i].nodeName === 'UL') {
        if (getListItemLevelDepth(listType, nextSibling.children[i].firstChild, chapterListLevels) === targetLevel) {
          return {
            listItem: nextSibling.children[i].firstChild,
            list: nextSibling.children[i]
          }
        }
      }
    }
    return false

  } else {
    const list = elementListItem.parentElement
    if (list) {
      const listNextSibling = list.nextSibling
      if (listNextSibling) {
        const listNextSiblingListItem = listNextSibling.firstChild
        if (listNextSiblingListItem && getListItemLevelDepth(listType, listNextSiblingListItem, chapterListLevels) === targetLevel) {//This needs to be a listItem in the second parameter
          return {
            listItem: listNextSiblingListItem,
            list: listNextSibling
          }
        }
      }
    }
  }
}

const leftHasMultipleTabIndentsIsFirstChNoChNoLwrSibNoLwrLeft = ({ elementListItem, chapterListLevels }) => {
  // XXXX
  //              0000
  // XXXX
  //Reviewed: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasMultipleTabIndentsIsFirstChNoChNoLwrSibNoLwrLeft ')

  // let tabs = getTabMarginCount(elementListItem) //This needs to be done before the changes to the level. Then add the tab to the increaseMarginLeft
  setList_LevelStyles({ list: elementListItem.parentElement, chapterListLevels, modifyLevel: -1 }) //This list needs to be adjusted before the listItem is adjusted.
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: -1 })
  // increaseMarginLeft(tabs--, elementListItem, chapterListLevels) //The margin-left is going to be reset to the default when this should already have tabs. 
}

const leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeftHasUpLeftTwoIndent = ({
  elementListItem,
  chapterListLevels,
}) => {
  // XXXX
  //             0000
  //                   |XXXX|
  //             XXXX
  //We will move the elementListItem over one tab level left (marginLeft), move any children over for one tab length to the right, then create a newSiblingslist below that first list for the siblings
  //1. get the parentLIst of elementListItem
  //2. get the lower siblings from elementListItem
  //3. If there are children
  //4.    move any children over for one tab length to the right
  //5. end if
  //6. if there are siblings
  //7.    Create a newSiblingsList
  //8.    Move the siblings into the newSiblingsList
  //9.   Increase the marginLeft to move the tab level to the right (since the elementListItem just got moved to the left one tab level)
  //10.   Append the newChildrenList to the elementLIstItem
  //11. end if
  //12. Decrease the elementListItem marginleft to the left for one tab-length. (Do this last so that the parentList isn't affected for the siblings - so we wait until the siblings are moved out into their new list)
  //Revised: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeftHasUpLeftTwoIndent ')
  const listType = getListType(elementListItem)
  //1. get the parentLIst of elementListItem
  const parentList = elementListItem.parentElement
  //2. get the lower siblings from elementListItem
  const siblings = getSiblingsLower(elementListItem)
  let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels)
  targetLevel--
  //3. If there are children
  if (elementListItem.children.length > 0) {
    //4.    move any children over for one tab length to the right
    for (let i = 0; i < elementListItem.children.length; i++) {
      if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
        for (let c = 0; c < elementListItem.children[i].children.length; c++) {
          if (elementListItem.children[i].children[c].nodeName === 'LI') {
            elementListItem.children[i].children[c].style.marginLeft = '0pt'
            increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
          }
        }
      }
    }
  //5. end if
  }
  //6. if there are siblings
  if (siblings?.length > 0) {
    //7.    Create a newSiblingsList
    let newSiblingsList = document.createElement(parentList.nodeName)
    newSiblingsList.id = getNextId(elementListItem.id)
    newSiblingsList.setAttribute('style', parentList.style.cssText)
    //8.    Move the siblings into the newSiblingsList
    for (let i = 0; i < siblings.length; i++) {
      //9.   Increase the marginLeft to move the tab level to the right (since the elementListItem just got moved to the left one tab level)
      newSiblingsList.append(siblings[i])
      setListItem_LevelStyles({ listItem: siblings[i], chapterListLevels, modifyLevel: -1, listType, forceTab: 1, forceLevel: targetLevel })
      siblings[i].style.marginLeft = '0pt'
      increaseMarginLeft(1, siblings[i], chapterListLevels)
    }
    //10.   Append the newChildrenList to the elementLIstItem
    elementListItem.append(newSiblingsList)
  //11. end if
  }
  //12. Decrease the elementListItem marginleft to the left for one tab-length.
  setList_LevelStyles({ list: parentList, chapterListLevels, modifyLevel: -1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
}

const leftHasLwrLeftIndentNoUprLeftIndentNoUprLeftTwoIndent = ({
  elementListItem,
  hasLowerLeftElementOneIndent,
  chapterListLevels,
}) => {
  //            XXXX
  //      0000
  //            |XXX|
  //      |XXX|
  // XXXX
  //We are going to move the elementListItem and it's children just above the hasLowerLeftElementOneIndent. Then we'll need to create a new list to move in the siblings as children to the new location of elementListItem
  //1. Get the hasLowerLeftElementOneIndent parentList
  //2. Get the siblings (if any) of the elementListItem
  //3. Create a newList for the siblings (if any) which are now going to be children to elementListItem
  //4. Move the elementLIstItem above the hasLowerLeftElementOneIndent
  //5. Move the children of elementLIstItem to the right one tab level since they were moved one tab to the left with the elementListItem
  //6. Append the newList to elementListItem
  //7. Move the siblings into the newList
  //Created: 27 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasLwrLeftIndentNoUprLeftIndentNoUprLeftTwoIndent ')

  const listType = getListType(elementListItem)
  //1. Get the hasLowerLeftElementOneIndent parentList
  const parentList = hasLowerLeftElementOneIndent.parentElement
  //2. Get the siblings (if any) of the elementListItem
  const siblings = getSiblingsLower(elementListItem)
  let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels)
  targetLevel++
  //3. Create a newList for the siblings (if any) which are now going to be children to elementListItem
  let newList = document.createElement(parentList.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', elementListItem.parentElement.style.cssText)
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: 0, listType, forceLevel: targetLevel }) //This list needs to be adjusted before the listItem is adjusted.
  //4. Move the elementLIstItem above the hasLowerLeftElementOneIndent
  parentList.insertBefore(elementListItem, hasLowerLeftElementOneIndent)
  elementListItem.setAttribute('style', hasLowerLeftElementOneIndent.style.cssText)
  //5. Move the children of elementLIstItem to the right one tab level since they were moved one tab to the left with the elementListItem
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      setList_LevelStyles({ list: elementListItem.children[i], chapterListLevels, modifyLevel: 1, listType }) //This list needs to be adjusted before the listItem is adjusted.
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          setListItem_LevelStyles({ listItem: elementListItem.children[i].children[c], chapterListLevels, modifyLevel: 1, listType })
        }
      }
    }
  }
  //6. Append the newList to elementListItem
  elementListItem.append(newList)
  //7. Move the siblings into the newList
  for (let i = 0; i < siblings.length; i++) {
    newList.append(siblings[i])
    increaseMarginLeft(-1, siblings[i], chapterListLevels)
  }
}


const leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeft = ({
  currentElement,
  elementListItem,
  chapterListLevels
}) => {
  // XXXX
  //             0000
  //                   |XXXX|
  //             XXXX
  //get the upper list (to get ready to insertBefore the existing list)
  //  Create a new listItem
  //  append the currentElement(s) to the new List
  //  decrease the multiple tab indent by one tab value
  //  Set the level character for the new list.
  //Done: 6/26/2023
  if (debugOutline) debugger
  console.log('function: leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeft ')

  const listType = getListType(elementListItem)
  const list = elementListItem.parentElement
  const parentList = list.parentElement
  let newList = document.createElement(list.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', list.style.cssText)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId(elementListItem.id)
  newListItem.setAttribute('style', elementListItem.style.cssText)
  newList.append(newListItem)
  appendListItemSpans(newListItem, elementListItem)
  parentList.insertBefore(newList, list)
  increaseMarginLeft(-1, newListItem, chapterListLevels)
  setLevelCharacter(newListItem.parentElement, chapterListLevels)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: -1, listType })
}

const rightFirstChildNoLwrSibNoUpRtOneTabHasLwrRtOneTab = ({ elementListItem, chapterListLevels, hasLowerRightElementOneIndent }) => {
  //XXXX
  //          0000
  //                XXXX
  // Done: 6/26/2023
  //1. Set the listLevel up one (before the listItem, by the way).
  //2. Set the listItemLevel up one.
  //3. Move the margin (tab) by one to the right
  //Reviewed: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightFirstChildNoLwrSibNoUpRtOneTabHasLwrRtOneTab ')

  //let tabs = getTabMarginCount(elementListItem) //This needs to be done before the changes to the level. Then add the tab to the increaseMarginLeft
  setList_LevelStyles({ list: elementListItem.parentElement, chapterListLevels, modifyLevel: 1 }) //This list needs to be adjusted before the listItem is adjusted.
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: 1 })
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
}

const rightFirstChildNoChHasLwrSibNoUpRtOneTab = ({ currentElement, elementListItem, chapterListLevels }) => {
  //XXXX
  //          0000
  //          XXXX
  //1. Get the parentList and grandParentList of the elementListItem in order to get ready to create a newList and newListItem to put in front of the parentList
  //2. Create a newList
  //3. Move the elementListItem children to the newList (Any children will come with it since it is a direct transfer)
  //4. Insert the newList before the parentList as a separate list.
  //5. Set the list and level styles according to the chapterListLevels
  //Reviewed and revised: 26 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightFirstChildNoChHasLwrSibNoUpRtOneTab ')

  const listType = getListType(elementListItem)
  //1. Get the parentList and grandParentList of the elementListItem in order to get ready to create a newList and newListItem to put in front of the parentList
  const parentList = elementListItem.parentElement
  const grandParentList = parentList.parentElement
  //2. Create a newList
  let newList = document.createElement(parentList.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', parentList.style.cssText)
  //3. Move the elementListItem children to the newList (Any children will come with it since it is a direct transfer)
  newList.append(elementListItem)
  //4. Insert the newList before the parentList as a separate list.
  grandParentList.insertBefore(newList, parentList)
  //5. Set the list and level styles according to the chapterListLevels
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: 1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: 1, listType })
}

const rightHasChHasUpSibHasLwrRtIndent = ({ elementListItem, chapterListLevels, hasLowerRightElementOneIndent }) => {
  //      XXXX
  //      0000
  //                XXXX
  //           XXXX
  //1. Get the elementListItem's previousSibling, if it exists 
  //2. If the previousSibling exists,
  //3.   Append the targetList to the end of the previousSibling
  //4.   Move the elementListItem into the first position of the targetList
  //5  else
  //6    Leave the targetList in place 
  //7.   Create a tempList
  //8.   Move the elementLIstItem into the tempList (and the children will go with it)
  //9.   Blank out the elementLIstItem
  //9. end if
  //Reviewed and revised: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightHasChHasUpSibHasLwrRtIndent ')

  const listType = getListType(elementListItem)
  //1. Get the elementListItem's previousSibling, if it exists 
  const previousSibling = elementListItem.previousSibling
  //2. If the previousSibling exists,
  if (previousSibling && previousSibling.nodeName === 'LI') {
    //3.   Append the targetList to the end of the previousSibling
    previousSibling.append(hasLowerRightElementOneIndent.list)
    //4.   Move the elementListItem into the first position of the targetList
    hasLowerRightElementOneIndent.list.insertBefore(elementListItem, hasLowerRightElementOneIndent.listItem)
    //increaseMarginLeft(1, elementListItem, chapterListLevels)
    setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: 1, listType })
    elementListItem.setAttribute('style', hasLowerRightElementOneIndent.listItem.style.cssText)
    for (let i = 0; i < elementListItem.children.length; i++) {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
      }
    }
    //5  else
  } else {
    //6    Leave the targetList in place 
    //7.   Create a tempList
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId(elementListItem.id)
    newListItem.setAttribute('style', hasLowerRightElementOneIndent.listItem.style.cssText)
    increaseMarginLeft(1, newListItem, chapterListLevels)
    //8. Move the currentElement(s) into the newlistItem from the current elementListItem
    appendListItemSpans(newListItem, elementListItem)
    //9. Move any children that are left in the current elementListItem (except the target element)
    //     append first and then increaseMarginLeft
    for (let i = 0; i < elementListItem.children.length; i++) {
      if (elementListItem.children[0] !== hasLowerRightElementOneIndent.list) {
        newListItem.append(elementListItem.children[0])
        for (let c = 0; c < elementListItem.children[0].children.length; c++) {
          if (elementListItem.children[0].children[c].nodeName === 'LI') increaseMarginLeft(-1, elementListItem.children[0].children[c], chapterListLevels)
        }
      }
    }
    //10.   Move the newListItem into the first position of the hasLowerRightElementOneIndent.list
    hasLowerRightElementOneIndent.list.insertBefore(newListItem, hasLowerRightElementOneIndent.listItem)
    //11.   Blank out the old elementListItem
    elementListItem.style.listStyle = ''
    elementListItem.style.listStyleType = ''
  }
  //9. end if
}


const rightFirstChildHasChHasLwrSibNoUpRtOneTab = ({
  elementListItem,
  chapterListLevels
}) => {
  //XXXX
  //          0000
  //                  XXXX
  //          XXXX
  //DONE 6/24/2023
  //1. Get the parentList of the elementListItem
  //2. Get the grandParentListItem of the elementListItem
  //3. Create a newList
  //4. Move the elementListItem into the newList
  //5. Insert the newList before the nextSibling of the parentList
  //6. decrease the marginRight of the children listItems by one tab since the newList is going to be moved over one tab level
  //7. The parentList is most likely blank now. Delete it if it is blank.
  //Revised:  26 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightFirstChildHasChHasLwrSibNoUpRtOneTab ')

  const listType = getListType(elementListItem)
  //1. Get the nextSibling of the elementListItem
  const nextSibling = elementListItem.nextSibling
  //2. Get the parentListItem of the elementListItem
  let parentList = elementListItem.parentElement
  let grandParentListItem
  if (parentList.nodeName === 'OL' || parentList.nodeName === 'UL') grandParentListItem = parentList.parentElement
  //3. Create a newList
  let newList = document.createElement(elementListItem.parentElement.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', elementListItem.parentElement.style.cssText)
  //4. Move the elementListItem into the newList
  newList.append(elementListItem)
  //5. Insert the newList before the nextSibling of the parentList
  grandParentListItem.insertBefore(newList, parentList)
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: 1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: 1, listType })

  //6. decrease the marginRight of the children listItems by one tab since the newList is going to be moved over one tab level
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
      }
    }
  }
  //7. The parentList is most likely blank now. Delete it if it is blank.
  if (!parentList.children.length) parentList.remove()
}

const leftHasUpStrIsFirstLvl = ({ currentElement, structure, elementListItem, chapterListLevels }) => {
  // XXXX
  // 0000
  // XXXX or XXXX (siblings or children)
  let elementList = elementListItem.parentElement
  let lowerSiblings = getLowerSiblings(elementListItem)
  let parentToStructure = structure[0].element.parentElement
  let topListSibling = elementList.nextSibling
  let newParagraph = document.createElement('p')
  newParagraph.id = getNextId(elementListItem.id)
  appendListItemSpans(newParagraph, elementListItem)
  parentToStructure.insertBefore(newParagraph, topListSibling)
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId(elementListItem.id)
  setLevelStyles(newList, chapterListLevels, elementList, "", 1)  //Directly set to level 1
  newList.append(elementListItem)
  for (let i = 0; i < lowerSiblings.length; i++) {
    newList.append(lowerSiblings[i])
  }
  parentToStructure.insertBefore(newList, topListSibling)

  if (elementListItem.children && elementListItem.children.length > 0) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}

const leftNoUpStrIsFirstLvl = ({ currentElement, structure, elementListItem }) => {
  //0. Find that there has no preceding structure and if this is a first level target moving to the left (and outside of the structure)
  // 0000
  // XXXX or XXXX (siblings or children)
  // 1. Create a paragraph to be insertBefore the OL/UL structure
  // 2. Insert the currentElement (span) inside hte paragraph
  // 3. Set the style's list-style-type attribute to 'none' (which keeps the structure there without a bullet despite the currentElement's absence)
  let childrenList = getListChildren(elementListItem)
  let parentToStructure = structure[0].element.parentElement
  let topStructureList = structure[0].element
  let newParagraph = document.createElement('p')
  newParagraph.id = getNextId(elementListItem.id)
  appendListItemSpans(newParagraph, elementListItem)
  parentToStructure.insertBefore(newParagraph, topStructureList)
  if (childrenList && childrenList.length > 0) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}


const leftNoFirstLvlNoUpLftOneTabNoLwrLftOneTab = ({
  elementListItem,
  chapterListLevels,
}) => {
  // XXXX
  //            0000
  //            XXXX
  //            XXXX
  // XXXX
  // Done: 6/26/2023
  //We will create a newList and append it inside of the elementList to put the siblings inside of it.
  //1. Get the parentList of the elementListItem
  //2. Get the siblings of the elementListItem
  //3. Create a newList
  //4. Copy the newList style from the parentList
  //5. Append the newList to the end of the elementListItem
  //6. Append the siblings inside of the newList
  //7. Move the siblings' tab level to be forced to 1. (Keep the list-character in their list)
  //8. Change the elementListItem to one list-character to the left
  //Reviewed and revised: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoFirstLvlNoUpLftOneTabNoLwrLftOneTab ')

  const listType = getListType(elementListItem)
  //1. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //2. Get the siblings of the elementListItem
  const siblings = getSiblingsLower(elementListItem)
  //3. Create a newList
  let newList = document.createElement(parentList.nodeName)
  newList.id = getNextId(elementListItem.id)
  //4. Copy the newList style from the parentList
  newList.setAttribute('style', parentList.style.cssText)
  //5. Append the newList to the end of the elementListItem
  elementListItem.append(newList)
  //6. Append the siblings inside of the newList
  for (let i = 0; i < siblings.length; i++) {
    newList.append(siblings[i])
    siblings[i].style.marginLeft = 0 //In order to reset them because the setlistItem_LevelStyles function could find that the level is well above 12 - which is the max so that it won't know how to set the chapterListLevels that only go to 12.
    //7. Move the siblings' tab level to be forced to 1. (Keep the list-character in their list)
    setListItem_LevelStyles({ listItem: siblings[i], chapterListLevels, modifyLevel: 0, listType, forceTab: 1 })
  }
  //8. Change the elementListItem to one list-character to the left
  setList_LevelStyles({ list: parentList, chapterListLevels, modifyLevel: -1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
}

const leftHasChNoUpSibNoDnSibHasDnLftOneTabHasUpLftOneTab = ({
  elementListItem,
  hasLowerLeftElementOneIndent,
  chapterListLevels
}) => {
  //0. Find that this target has no children but no siblings above or below, but has an upper left level that is one indent away (or more), but has one lower level that is one indent in.
  //This needs to be before the if condition: hasChildren && !hasSiblingsUpper && !hasSiblingsLower
  // XXXX
  //      XXXX
  //                  0000
  //            XXXX
  //1. Get the parentList from elementListItem (which we will be deleting as the elementListItem is moved to the lower left list)
  //2. Get the hasLowerLeftElementOneIndent list
  //3. Move the elementListItem into the hasLowerLeftElementOneIndent list
  //4. For the elementListItem, decrease the marginLeft by one tab level
  //5. Increase the tab level by one to the right for the elementListItem children
  //6. Delete the parentList
  //Revised: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasChNoUpSibNoDnSibHasDnLftOneTabHasUpLftOneTab ')

  const listType = getListType(elementListItem)
  //1. Get the parentList from elementListItem (which we will be deleting as the elementListItem is moved to the lower left list)
  const parentList = elementListItem.parentElement
  //2. Get the hasLowerLeftElementOneIndent list
  const targetList = hasLowerLeftElementOneIndent.parentElement
  //3. Move the elementListItem into the hasLowerLeftElementOneIndent list
  targetList.insertBefore(elementListItem, hasLowerLeftElementOneIndent)
  //4. For the elementListItem, decrease the marginLeft by one tab level
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
  //5. Increase the tab level by one to the right for the elementListItem children
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
      }
    }
  }
  //6. Delete the parentList
  if (!parentList?.children?.length) parentList.remove()
}

const leftNoChHasUpSibNoDnSibHasLwrLftOneTab = ({ currentElement, elementListItem, chapterListLevels, hasLowerLeftElementOneIndent }) => {
  //      XXXX
  //            XXXX
  //      0000
  // XXXX
  //Reviewed 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChHasUpSibNoDnSibHasLwrLftOneTab ')

  let targetListItem = hasLowerLeftElementOneIndent.nodeName === 'LI' ? hasLowerLeftElementOneIndent : hasLowerLeftElementOneIndent.firstChild
  let targetList = hasLowerLeftElementOneIndent.parentElement
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId(elementListItem.id)
  newListItem.setAttribute('style', targetListItem.style.cssText)
  appendListItemSpans(newListItem, elementListItem)
  targetList.insertBefore(newListItem, targetListItem)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}


const leftNoChNoUpSibNoDnSibNoUpperStr = ({ currentElement, elementListItem, hasLowerLeftElementOneIndent, chapterListLevels }) => {
  //0. Find that this target has no children and no siblings above or below and not structure above
  //        0000
  //  XXXX
  //Move this element into the top of the lower left list.
  //Done 6/26/2023
  //1. Move the elementListItem before the hasLowerLeftElementOneIndent in the hasLowerLeftElementOneIndent's list
  //2. The children will move with it, if any (which this function indicates that there are not any children anyway)
  //Reviewed and revised: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChNoUpSibNoDnSibNoUpperStr ')

  const oldList = elementListItem.parentElement
  const targetListItem = hasLowerLeftElementOneIndent.nodeName === 'SPAN' ? hasLowerLeftElementOneIndent.parentElement : hasLowerLeftElementOneIndent.nodeName === 'LI' ? hasLowerLeftElementOneIndent : hasLowerLeftElementOneIndent.firstChild //Assuming that the last one is an OL or UL
  targetListItem.parentElement.insertBefore(elementListItem, targetListItem)
  oldList.remove()
  setList_LevelStyles({ list: elementListItem.parentElement, chapterListLevels, modifyLevel: -1 }) //This list needs to be adjusted before the listItem is adjusted.
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: -1 })
}

const leftNoChNoUpSibNoDnSibHasLowerOneIndent = ({
  elementListItem,
  chapterListLevels,
  hasLowerLeftElementOneIndent
}) => {
  //0. Find that this target has no children and no siblings above or below and not structure above
  // XXXX
  //       XXXX
  //                  0000
  //            XXXX
  //Move this element into the top of the lower left list.
  //******
  //The new listItem to match the lower left element listItems
  //Done 6/26/2023
  //Reviewed: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChNoUpSibNoDnSibHasLowerOneIndent ')

  let targetList
  let targetListItem
  if (hasLowerLeftElementOneIndent) {
    if (hasLowerLeftElementOneIndent.nodeName === 'LI') {
      targetListItem = hasLowerLeftElementOneIndent
      targetList = hasLowerLeftElementOneIndent.parentElement
    } else if (hasLowerLeftElementOneIndent.nodeName === 'OL' || hasLowerLeftElementOneIndent.nodeName === 'UL') {
      targetList = hasLowerLeftElementOneIndent
      targetListItem = hasLowerLeftElementOneIndent.firstChild
    }
    let siblings = getLowerSiblings(elementListItem)
    let currentList = elementListItem.parentElement
    //The list item to join the lower left element
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId(elementListItem.id)
    newListItem.setAttribute('style', targetListItem.style.cssText)
    newListItem.style['margin-bottom'] = '0pt' // Just in case we are getting the style from the last listItem in the structure which gives a margin bottom to give space from the next element out of the list.
    //setLevelStyles(newListItem, chapterListLevels, targetListItem)
    newListItem.style['list-style-type'] = targetListItem.style['list-style-type']
    setLevelCharacter(newListItem.parentElement, chapterListLevels)
    appendListItemSpans(newListItem, elementListItem)
    newListItem.append(currentList)
    targetList.insertBefore(newListItem, targetListItem)
    for (let i = 0; i < siblings.length; i++) {
      increaseMarginLeft(-1, siblings[i], chapterListLevels)
    }
    elementListItem.remove()
    currentList.remove()
  }
}

const leftNoChNoUpSibNoDnSibHasUpLftOneTab = ({ currentElement, elementListItem, hasUpperLeftElementOneIndent, chapterListLevels, hasLowerLeftElementOneIndent }) => {
  //Needs to be above: !hasChildren && !hasSiblingsUpper && !hasSiblingsLower
  // XXXX
  //      XXXX
  //                  XXXX
  //            0000
  //      XXXX
  // Done: 6/26/2023
  // Reviewed and revised: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChNoUpSibNoDnSibHasUpLftOneTab ')

  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  let parentListItemNextSibling = parentListItem.nextSibling
  let parentList = parentListItem.parentElement
  parentList.insertBefore(elementListItem, parentListItemNextSibling)
  elementListItem.setAttribute('style', parentListItem.style.cssText)
  if (hasListItemElements(list)) {
    list.style['list-style-type'] = 'none'
  } else {
    list.remove()
  }
}

const leftNoChNoUpSibNoDnSib = ({ currentElement, elementListItem, chapterListLevels, chosenSegment }) => {
  //0. Find that this target has no children and no siblings above or below
  // XXXX
  //      XXXX
  //            0000
  //      XXXX
  if (debugOutline) debugger
  console.log('function: leftNoChNoUpSibNoDnSib ')

  if (!currentElement) {
    let lastChosen = chosenSegment[chosenSegment.length - 1]
    if (lastChosen) currentElement = document.querySelector(`span[id="%{lastChosen.id}"][data-type='TEXT']`)
  }
  if (currentElement) {
    let listItem = currentElement.parentElement
    let list = listItem.parentElement
    let parentListItem = list.parentElement
    let parentList = parentListItem.parentElement
    let indexInsert
    for (let i = 0; i < parentList.children.length; i++) {
      if (parentList.children[i] === parentListItem) indexInsert = i + 1 * 1
    }
    list.remove()
    //parentListItem.remove()
    setLevelStyles(listItem, chapterListLevels, parentListItem)
    if (parentList.children.length === indexInsert) {
      parentList.append(listItem)
    } else {
      parentList.insertBefore(listItem, parentList.children[indexInsert])
    }
  }
}

const leftNoChNoUpSibHasDnSibHasLwrLftOneTabHasUpLeftOneTab = ({ currentElement, elementListItem, hasUpperLeftElementOneIndent, hasLowerLeftElementOneIndent, chapterListLevels }) => {
  // XXXX             XXXXX
  //      0000    OR                0000
  //      XXXX                      XXXX
  // XXXX                     XXXX
  //Done 6/27/2023
  if (debugOutline) debugger
  console.log('function: leftNoChNoUpSibHasDnSibHasLwrLftOneTabHasUpLeftOneTab ')

  const listType = getListType(elementListItem)
  let targetList
  let targetListItem
  if (hasLowerLeftElementOneIndent) {
    if (hasLowerLeftElementOneIndent.nodeName === 'LI') {
      targetListItem = hasLowerLeftElementOneIndent
      targetList = hasLowerLeftElementOneIndent.parentElement
    } else if (hasLowerLeftElementOneIndent.nodeName === 'OL' || hasLowerLeftElementOneIndent.nodeName === 'UL') {
      targetList = hasLowerLeftElementOneIndent
      targetListItem = hasLowerLeftElementOneIndent.firstChild
    }
    let list = elementListItem.parentElement
    let newListItem = document.createElement('li')
    newListItem.id = getNextId(elementListItem.id)
    newListItem.setAttribute('style', targetListItem.style.cssText)
    appendListItemSpans(newListItem, elementListItem)
    targetList.insertBefore(newListItem, targetListItem)
    newListItem.append(list)
    let currentListItem = newListItem
    let targetLevel = getListItemLevelDepth(listType, newListItem, chapterListLevels)
    for (let i = 0; i < elementListItem.children.length;) { //Notice that we are not incrementing the i++ because the list gets shorter every time we append a child, so we always append the [0]
      if (getListItemLevelDepth(listType, elementListItem.children[0].firstChild, chapterListLevels) > targetLevel) {
        increaseMarginLeft(-1, elementListItem.children[0].firstChild, chapterListLevels)
        currentListItem.append(elementListItem.children[0])
      } else if (getListItemLevelDepth(listType, elementListItem.children[0].firstChild, chapterListLevels) === targetLevel) {
        let anotherListItem = document.createElement('LI')
        anotherListItem.id = getNextId(elementListItem.id)
        anotherListItem.setAttribute('style', targetListItem.style.cssText)
        appendListItemSpans(anotherListItem, elementListItem.children[0].firstChild)
        targetList.append(anotherListItem)
        elementListItem.children[0].remove()
        currentListItem = anotherListItem
      }
    }
    if (hasListItemElements(elementListItem)) {
      elementListItem.style['list-style-type'] = 'none'
    } else {
      elementListItem.remove()
    }
  }
}

const leftNoChNoUpSibHasDnSibHasLwrLftOneTab = ({
  elementListItem,
  hasLowerLeftElementOneIndent,
  chapterListLevels
}) => {
  // XXXX
  //      XXXX
  //                  0000
  //                  XXXX
  //           XXXX
  // There are at least two lists involved under a main list.
  // The first list has two or more elements which are pushed over two tabs
  // The lower list has an element to the left.
  // We need to place the upper elements into the lower list by making the lower siblings of the currentElement children to the moved currentElement(s)
  //Done 6/24/2023
  //1. Get the parentList (which will be deleted when it is empty at the end of this function)
  //2. Get the grandParentListItem
  //3. Move the elementListItem into the first position on top of hasLowerLeftElementOneIndent, which should be a listItem
  //4. Move the elementListItem children to the right one tab level since the elementListItem got moved left one tab level
  //5. Delete the parentList if it is empty (which it should be)
  //Reviewed and revised: 26 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChNoUpSibHasDnSibHasLwrLftOneTab ')

  //1. Get the parentList(which will be deleted when it is empty at the end of this function)
  const parentList = elementListItem.parentElement
  //2. Get the elementListItem children in order to move them under the new elementListItem location
  const childrenList = getListChildren(elementListItem)
  //2. Get the siblingsin order to make them children to the elementListItem
  const siblings = getSiblingsLower(elementListItem)
  //3. Move the elementListItem into the first position on top of hasLowerLeftElementOneIndent, which should be a listItem
  hasLowerLeftElementOneIndent.parentElement.insertBefore(elementListItem, hasLowerLeftElementOneIndent)
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
  //4. Move the elementListItem children to the right one tab level since the elementListItem got moved left one tab level
  for (let i = 0; i < childrenList.length; i++) {
    if (childrenList[i].nodeName === 'OL' || childrenList[i].nodeName === 'UL') {
      elementListItem.append(childrenList[i])
      for (let c = 0; c < childrenList[i].children.length; c++) {
        increaseMarginLeft(-1, childrenList[i].children[c], chapterListLevels)
      }
    }
  }
  //5. Delete the parentList if it is empty (which it should be)
  if (!parentList.children.length) {
    parentList.remove()
  } else {
    elementListItem.append(parentList)
    for (let i = 0; i < parentList.children.length; i++) {
      if (parentList.children[i].nodeName === 'LI') {
        increaseMarginLeft(-1, parentList.children[i], chapterListLevels)
      }
    }
  }
}

const leftHasChNoUpSibHasDnSibHasLwrLftOneTab = ({
  elementListItem,
  hasLowerLeftElementOneIndent,
  chapterListLevels
}) => {
  // XXXX
  //      XXXX
  //                  0000
  //                        XXXX
  //                  XXXX
  //           XXXX
  //1. Create a newList to hold the Siblings (to match the elementListItem parentList)
  //2. Move the siblings into the newList. There shouldn't need to be a level move for the siblings
  //3. Move the elementListItem into the list of the hasLowerLeftElementOneIndent, the children will come with it.
  //4. Move the elementListItem children over one tab
  //5. Append the newList of the siblings to the end of the moved elementListItem
  //Reviewed and reworked: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasChNoUpSibHasDnSibHasLwrLftOneTab ')

  const siblings = getLowerSiblings(elementListItem)
  //1. Before the elementListItem is moved, create a newList to hold the Siblings (to match the elementListItem parentList)
  let newList = document.createElement(elementListItem.parentElement.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', elementListItem.parentElement.style.cssText)
  //2. Move the siblings into the newList. There shouldn't need to be a level move for the siblings
  for (let i = 0; i < siblings.length; i++) {
    newList.append(siblings[i])
    setListItem_LevelStyles({ listItem: siblings[i], chapterListLevels, modifyLevel: -1 })
  }
  //3. Move the elementListItem into the list of the hasLowerLeftElementOneIndent, the children will come with it.
  hasLowerLeftElementOneIndent.parentElement.insertBefore(elementListItem, hasLowerLeftElementOneIndent)
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: -1 })
  elementListItem.setAttribute('style', hasLowerLeftElementOneIndent.style.cssText) //This is here to compensate for the exactness needed to line up the list-characters which isn't happening yet with the chapterListLevels which might have been slightly generalized at 32pt instead of 32.17pt, for example.
  //4. Move the elementListItem children over one tab
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      setList_LevelStyles({ list: elementListItem.children[i], chapterListLevels, modifyLevel: 1 }) //This list needs to be adjusted before the listItem is adjusted.
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
      }
    }
  }
  //5. Append the newList of the siblings to the end of the moved elementListItem
  elementListItem.append(newList)
}

const leftNoChNoUpSibHasDnSibHasUpLftMoreTab = ({ elementListItem, hasUpperLeftElementMoreIndent, chapterListLevels }) => {
  //0. Find that this target has no children but has siblings below but not above, but has an upper left level that is one indent away (or more).
  //This needs to be above: !hasChildren && !hasSiblingsUpper && hasSiblingsLower
  // XXXX
  //      XXXX
  //                  0000
  //                  XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Clone the target's list
  // 4. Decrease the clone's margin-left by one indent value (36pt)
  // 5. Insert the cloned target's list at the beginning of the upper left list
  // 6. Append the target in the cloned target's list
  if (debugOutline) debugger
  console.log('function: leftNoChNoUpSibHasDnSibHasUpLftMoreTab ')

  let targetListClone = elementListItem.parentElement.cloneNode(true)
  increaseMarginLeft(-1, targetListClone, chapterListLevels)
  hasUpperLeftElementMoreIndent.insertBefore(targetListClone, hasUpperLeftElementMoreIndent.firstChild)
  targetListClone.append(elementListItem)
}

const leftNoChNoUpSibHasDnSibHasUpLeftOneTab = ({
  currentElement,
  elementListItem,
  hasUpperLeftElementOneIndent,
  chapterListLevels
}) => {
  //0. Find that this target has no children and no siblings above but has siblings below
  // XXXX
  //      XXXX
  //            0000
  //            XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. If the listItem has more than one tabValue in margin-left,
  //      a. decrement by one
  //      b. decrement the level style
  //    Else
  //      4. Get the style of the upper less level
  //      5. Get the index of the upper less level and add one where the target is to be moved
  //      6. Set the listItem style to the style of the upper less level
  //      7. Insert the target at the index found above of the upper list on the left (which will take this target ouf of its sibling's list)
  //******
  //Create a new listItem to put below the upper left element
  //Move the currentElement(s) into the new listItem
  //Move the list of children and siblings under the currentElements in that new list item.
  if (debugOutline) debugger
  console.log('function: leftNoChNoUpSibHasDnSibHasUpLeftOneTab ')

  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  let parentList = parentListItem.parentElement
  let parentListItemNextSibling = parentListItem.nextSibling
  let newListItem = document.createElement(parentListItem.nodeName)
  newListItem.id = getNextId(elementListItem.id)
  newListItem.setAttribute('style', parentListItem.style.cssText)
  if (parentListItemNextSibling) {
    parentList.insertBefore(newListItem, parentListItemNextSibling)
  } else {
    parentList.append(newListItem)
  }
  appendListItemSpans(newListItem, elementListItem)
  newListItem.append(list)
  elementListItem.remove()
}

const leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasNoLowerLeft = ({
  elementListItem,
  hasUpperLeftElementOneIndent,
  chapterListLevels
}) => {
  // XXXX
  //      |XXXX|
  //      0000
  //            XXXX
  //      XXXX
  //1. Get any siblings from the elementListItem
  //2. Get the parentList of the elementListItem (which will be deleted further below)
  //3. Get the grandParentList of hasUpperLeftElementOneIndent
  //4. Get a nextSibling from the hasUpperLeftElementOneIndent
  //5. if a nextSiblings exists
  //6.    Insert the elementListItem before the nextSibling
  //7. else
  //8.    Append the elementListItem to the end of the grandParentList
  //9. end if
  //11. Move the elementListItem children over by one tab level to the right since they were all just moved over one tab to the left with the elementListItem move.
  //12. If there are any siblings
  //13.    create a newList for the siblings
  //14.    Copy the parentList style to the newList (which is where they came from originally anyway)
  //15.    Move the siblings into the newList
  //16.    Append the newList to the end of the elementListItem
  //17. end if
  //Reviewed: 28 Oct 2024
  if (debugOutline) debugger
  //1. Get any siblings from the elementListItem
  let siblings = getLowerSiblings(elementListItem)
  //2. Get the parentList of the elementListItem (which will be deleted further below)
  let parentList = elementListItem.parentElement
  //3. Get the grandParentList of hasUpperLeftElementOneIndent
  const grandParentList = hasUpperLeftElementOneIndent.parentElement
  //4. Get a nextSibling from the hasUpperLeftElementOneIndent
  const nextSibling = hasUpperLeftElementOneIndent.nextSibling
  //5. if a nextSiblings exists
  if (nextSibling) {
    //6.    Insert the elementListItem before the nextSibling
    grandParentList.insertBefore(elementListItem, nextSibling)
  //7. else
  } else {
    //8.    Append the elementListItem to the end of the grandParentList
    grandParentList.append(elementListItem) 
    elementListItem.setAttribute('style', hasUpperLeftElementOneIndent.style.cssText)
  //9. end if
  }
  //11. Move the elementListItem children over by one tab level to the right since they were all just moved over one tab to the left with the elementListItem move.
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
  //12. If there are any siblings
  if (siblings?.length > 0) {
    //13.    create a newList for the siblings
    let newList = document.createElement(parentList.nodeName)    
    newList.id = getNextId(elementListItem.id)
    //14.    Copy the parentList style to the newList (which is where they came from originally anyway)
    newList.setAttribute('style', parentList.style.cssText)
    //15.    Move the siblings into the newList
    for(let i = 0; i < siblings.length; i++) {
      newList.append(siblings[i])
    }
    //16.    Append the newList to the end of the elementListItem
    elementListItem.append(newList)
  //17. end if
  }
  if (!parentList?.children?.length) parentList.remove()
}

const leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasLowerLeft = ({
  currentElement,
  elementListItem,
  hasUpperLeftElementOneIndent,
  hasLowerLeftElementOneIndent,
  chapterListLevels
}) => {
  // XXXX
  //      0000
  //            XXXX
  //      XXXX
  // Done: 6/27/2023
  //1. Get the parentList of the elementListItem because we will need to append this list to the elementListItem since elementListItem was taken out of that original list but it still needs to be its own list underneath the elementListItem children
  //2. Get the nextSibling of hasUpperLeftElementOneIndent
  //3. If nextSibling exists
  //4.   Move the elementListItem into the parentList before the nextSibling of hasUpperLeftElementOneIndent
  //5. else
  //6.   Append elementListItem to the bottom of the parentList of hasUpperLeftElementOneIndent
  //7. end if
  //8. Move the elementListItem children listItems right one tab and keep the OL/UL that are the children already. The OL/UL level shouldn't need to change (which is to say the list-style-type)
  //9. Append the parentList (the one that held the elementListItem) to the newly placed elementListItem which will bring the siblings under the left-moved elementListItem
  //Reviewed and reworked: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasLowerLeft ')

  //1. Get the parentList of the elementListItem because we will need to append this list to the elementListItem since elementListItem was taken out of that original list but it still needs to be its own list underneath the elementListItem children
  const parentList = elementListItem.parentElement
  //2. Get the nextSibling of hasUpperLeftElementOneIndent
  if (hasUpperLeftElementOneIndent.nodeName === 'SPAN') hasUpperLeftElementOneIndent = hasUpperLeftElementOneIndent.parentElement
  if (hasUpperLeftElementOneIndent === 'OL' || hasUpperLeftElementOneIndent === 'UL') hasUpperLeftElementOneIndent = hasUpperLeftElementOneIndent.firstChild
  let nextSibling = hasUpperLeftElementOneIndent.nextSibling
  //3. If nextSibling exists
  if (nextSibling) {
    //4.   Move the elementListItem into the parentList before the nextSibling of hasUpperLeftElementOneIndent
    hasUpperLeftElementOneIndent.parentElement.insertBefore(elementListItem, nextSibling)
    //5. else
  } else {
    //6.   Append elementListItem to the bottom of the parentList of hasUpperLeftElementOneIndent
    hasUpperLeftElementOneIndent.parentElement.append(elementListItem)
    //7. end if
  }
  elementListItem.setAttribute('style', hasUpperLeftElementOneIndent.style.cssText)
  //8. Move the elementListItem children listItems right one tab and keep the OL/UL that are the children already. The OL/UL level shouldn't need to change (which is to say the list-style-type)
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      //setList_LevelStyles({ list: elementListItem.children[i], chapterListLevels, modifyLevel: 1 }) //This list needs to be adjusted before the listItem is adjusted.
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          //setListItem_LevelStyles({ listItem: elementListItem.children[i].children[c], chapterListLevels, modifyLevel: 1, listType })
          increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
  //9. Append the parentList (the one that held the elementListItem) to the newly placed elementListItem which will bring the siblings under the left-moved elementListItem
  elementListItem.append(parentList) //not newList
}

const leftHasChNoUpSibNoDnSibHasUpLftMoreTab = ({
  currentElement,
  elementListItem,
  hasUpperLeftElementMoreIndent,
  hasLowerLeftElementOneIndent,
  chapterListLevels
}) => {
  //0. Find that this target has one or more children but no siblings above or below, and that the upper left list is one more tab indent over (or more).
  //This needs to be above: hasChildren && !hasSiblingsUpper && !hasSiblingsLower
  // XXXX
  //      XXXX
  //                  0000
  //                        XXXX
  // 1. Get the parentList of the elementListItem
  // 2. Move the parentList list-character left by one.
  // 3. Decrease the marginLeft by one.
  // 4. Increase the marginLeft of the elementListItem's children (listItems) to the right by one tab level (since everything was just moved over one tab to the left.)
  //Reviewed and revised: 28 Oct 2024
  const listType = getListType(elementListItem)
  // 1. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  // 2. Move the parentList list-character left by one.
  setList_LevelStyles({ list: parentList, chapterListLevels, modifyLevel: -1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  // 3. Decrease the marginLeft by one.
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
  // 4. Increase the marginLeft of the elementListItem's children (listItems) to the right by one tab level (since everything was just moved over one tab to the left.)
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
}

const leftHasChNoUpSibNoDnSib = ({
  elementListItem,
  hasUpperLeftElementOneIndent,
  chapterListLevels
}) => {
  //0. Find that this target has one or more children but no siblings above or below.
  // XXXX
  //      XXXX
  //            0000
  //                  XXXX
  //We are going to move the elementListName just below the hasUpperLeftElementOneIndent whether there is a nextSibling to hasUpperLeftElementOneIndent or append to the grandParentListItem
  //1. Get nextSibling from hasUpperLeftElementOneIndent
  //2. Get parentList for hasUpperLeftElementOneIndent
  //3. if nextSibling exists
  //4.   insert elementListName before the nextSibling
  //5. else
  //6.   append to the parentLIst
  //7. end if
  //8. increase the marginLeft one tab level to the right for any children listItems of the elementListItems becaues the who list was just moved to the left one tab level.
  //Reviewed and revised: 26 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasChNoUpSibNoDnSib ')

  //We are going to move the elementListName just below the hasUpperLeftElementOneIndent whether there is a nextSibling to hasUpperLeftElementOneIndent or append to the grandParentListItem
  const listType = getListType(elementListItem)
  //1. Get nextSibling from hasUpperLeftElementOneIndent
  const nextSibling = hasUpperLeftElementOneIndent.nextSibling
  //2. Get parentList for hasUpperLeftElementOneIndent
  const parentList = hasUpperLeftElementOneIndent.parentElement
  //3. if nextSibling exists
  if (nextSibling) {
    //4.   insert elementListName before the nextSibling
    parentList.insertBefore(elementListItem, nextSibling)
    //5. else
  } else {
    //6.   append to the parentLIst
    parentList.append(elementListItem)
    //7. end if
  }
  elementListItem.setAttribute('style', hasUpperLeftElementOneIndent.style.cssText)
  //8. increase the marginLeft one tab level to the right for any children listItems of the elementListItems becaues the who list was just moved to the left one tab level.
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      setList_LevelStyles({ list: elementListItem.children[i], chapterListLevels, modifyLevel: 1, listType }) //This list needs to be adjusted before the listItem is adjusted.
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          setListItem_LevelStyles({ listItem: elementListItem.children[i].children[c], chapterListLevels, modifyLevel: 1, listType })
        }
      }
    }
  }
}

const leftNoChHasUpSibNoDnSib = ({
  elementListItem,
  hasUpperLeftElementOneIndent,
  chapterListLevels,
}) => {
  //0. Find that this target has no children, but has siblings above but not below
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //      XXXX
  //1. Get the hasUpperLeftElementOneIndent listItem
  //2. Get the hasUpperLeftElementOneIndent list
  //3. Get the nextSibling of hasUpperLeftElementOneIndent listItem
  //4. If there is a nextSibling
  //5.   Move the elementListItem to insert before the nextSibling of the hasUpperLeftElementOneIndent list
  //6. else
  //7.   Append the elementListItem to the end of the hasUpperLeftElementOneIndent list
  //8. end if
  //Reviewed and revised: 26 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChHasUpSibNoDnSib ')

  //1. Get the hasUpperLeftElementOneIndent listItem
  let targetListItem = hasUpperLeftElementOneIndent
  if (targetListItem.nodeName === 'SPAN') targetListItem = targetListItem.parentElement
  if (targetListItem.nodeName === 'OL' || targetListItem.nodeName === 'UL') targetListItem = targetListItem.firstChild
  //2. Get the hasUpperLeftElementOneIndent list
  const targetList = targetListItem.parentElement
  //3. Get the nextSibling of hasUpperLeftElementOneIndent listItem
  const nextSibling = targetListItem.nextSibling
  //4. If there is a nextSibling
  if (nextSibling) {
    //5.   Move the elementListItem to insert before the nextSibling of the hasUpperLeftElementOneIndent list
    targetList.insertBefore(elementListItem, nextSibling)
    //6. else
  } else {
    //7.   Append the elementListItem to the end of the hasUpperLeftElementOneIndent list
    targetList.append(elementListItem)
    //8. end if
  }
  //increaseMarginLeft(1, elementListItem, chapterListLevels)
  elementListItem.setAttribute('style', targetListItem.style.cssText)
}

const leftNoChHasUpSibNoDnSibHasParentHasLwrLeftOneIndent = ({
  elementListItem,
  chapterListLevels,
  hasGrandParent,
  hasLowerLeftElementOneIndent
}) => {
  //0. Find that this target has no children, but has siblings above but not below
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //      XXXX
  //Done 6/24/2023
  if (debugOutline) debugger
  console.log('function: leftNoChHasUpSibNoDnSibHasParentHasLwrLeftOneIndent ')

  let targetList
  let targetListItem
  if (hasLowerLeftElementOneIndent) {
    if (hasLowerLeftElementOneIndent.nodeName === 'LI') {
      targetListItem = hasLowerLeftElementOneIndent
      targetList = hasLowerLeftElementOneIndent.parentElement
    } else if (hasLowerLeftElementOneIndent.nodeName === 'OL' || hasLowerLeftElementOneIndent.nodeName === 'UL') {
      targetList = hasLowerLeftElementOneIndent
      targetListItem = hasLowerLeftElementOneIndent.firstChild
    }
    let siblings = getLowerSiblings(elementListItem)
    //let currentList = elementListItem.parentElement
    //The list item to join the lower left element
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId(elementListItem.id)
    newListItem.setAttribute('style', targetListItem.style.cssText)
    newListItem.style['list-style-type'] = targetListItem.style['list-style-type']
    setLevelCharacter(newListItem, chapterListLevels)
    appendListItemSpans(newListItem, elementListItem)
    //newListItem.append(currentList)
    targetList.insertBefore(newListItem, targetList.firstChild)
    for (let i = 0; i < siblings.length; i++) {
      increaseMarginLeft(-1, siblings[i], chapterListLevels)
    }
    elementListItem.remove()
  }
}

const leftHasMultTabsNoChHasUpSibNoDnSibNoLwrLeftOneIndent = ({
  elementListItem,
  chapterListLevels,
}) => {
  // XXXX
  //            XXXX
  //            0000
  //                  |XXX|
  //            |XXX|
  // XXXX
  // We will move the target to the left by creating a new list to come under the sibling, leave the target's children in their own list but push it over one tab level, and start another child list for any siblings of the target.
  // We will be taking the lowerSiblings and moving them to a new list under the elementListItem which will then clear anything under the previousSibling of the elementListItem so we can just append the newList to the end of the grandParentListItem
  //1. Get the parentList of the elementListItem
  //2. Get the grandParentListItem of the parentList (which is where we will start the new list under that upper listItem)
  //3. Get the previousSibling of the elementListItem 
  //4. Get the lower siblings of the elementListItem (which will become a list under the elementListItem)
  //5. Create a newList
  //6. copy the style of the parentList into the newList
  //7. Move the newList list-character left by one
  //8. Append the elementListItem to the newList
  //9. Force the tab to one tab to the right for the elementListItem (effectively removing any tabs from its original tabbed-in position)
  //11. Move any children (listItems) of the elementListItem to the right one tab level (since they were just moved left by one tab level with the elementListItem)
  //12. Create a newSiblingList
  //13. Copy the style of the parentList into the newSiblingList (which we probably don't have to change the list-character since it should be set accurately already)
  //14. Move the lower siblings into the newSiblingList
  //15. Append the newSiblingList to the end of the elementListItem
  //16. Force the tab to one tab to the right for the siblings (effectively removing any tabs from its original tabbed-in position) (any of their children will be in place after that, as well)
  //17. Append the newList to the end of the grandParentListItem
  //Created: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasMultTabsNoChHasUpSibNoDnSibNoLwrLeftOneIndent ')

  const listType = getListType(elementListItem)
  //1. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //2. Get the grandParentListItem of the parentList (which is where we will start the new list under that upper listItem)
  const grandParentListItem = parentList.parentElement
  //3. Get the previousSibling of the elementListItem 
  const previousSibling = elementListItem.previousSibling
  //4. Get the lower siblings of the elementListItem (which will become a list under the elementListItem)
  const siblings = getSiblingsLower(elementListItem)
  //5. Create a newList
  const newList = document.createElement(parentList.nodeName)
  newList.id = getNextId(elementListItem.id)
  //6. copy the style of the parentList into the newList
  newList.setAttribute('style', parentList.style.cssText)
  //7. Move the newList list-character left by one
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: -1, listType })
  //8. Append the elementListItem to the newList
  newList.append(elementListItem)
  //9. Force the tab to one tab to the right for the elementListItem (effectively removing any tabs from its original tabbed-in position)
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: 0, listType, forceTab: 1 })
  //11. Move any children (listItems) of the elementListItem to the right one tab level (since they were just moved left by one tab level with the elementListItem)
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
  //12. Create a newSiblingList
  const newSiblingsList = document.createElement(parentList.nodeName)
  newSiblingsList.id = getNextId(elementListItem.id)
  //13. Copy the style of the parentList into the newSiblingList (which we probably don't have to change the list-character since it should be set accurately already)
  newSiblingsList.setAttribute('style', parentList.style.cssText)
  //14. Move the lower siblings into the newSiblingList
  if (siblings?.length > 0) {
    for(let i = 0; i < siblings.length; i++) {
      newSiblingsList.append(siblings[i]) 
      setListItem_LevelStyles({ listItem: siblings[i], chapterListLevels, modifyLevel: 0, listType, forceTab: 1 })
      increaseMarginLeft(1, siblings[i], chapterListLevels)
    }
  }
  //15. Append the newSiblingList to the end of the elementListItem
  elementListItem.append(newSiblingsList)
  //16. Force the tab to one tab to the right for the siblings (effectively removing any tabs from its original tabbed-in position) (any of their children will be in place after that, as well)
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: -1, listType })
  //17. Append the newList to the end of the grandParentListItem
  grandParentListItem.append(newList)

}

const leftNoChHasUpSibHasUpLftOneTab = ({
  currentElement,
  elementListItem,
  hasUpperLeftElementOneIndent,
  chapterListLevels,
  hasLowerLeftElementOneIndent
}) => {
  //0. Find that this target has no children, but has siblings above (but could have siblings below or not)
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //      XXXX |XXXX| (could have siblings)
  //Reviewed 24 Oct 2024
  //1. Create a newList to hold the Siblings (to match the elementListItem parentList)
  //2. Move the siblings into the newList. There shouldn't need to be a level move for the siblings
  //3. Move the elementListItem into the list of the hasLowerLeftElementOneIndent, the children will come with it.
  //4. Move the elementListItem children over one tab
  //5. Append the newList of the siblings to the end of the moved elementListItem
  //Reviewed and reworked: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChHasUpSibHasUpLftOneTab ')

  const siblings = getLowerSiblings(elementListItem)
  //1. Before the elementListItem is moved, create a newList to hold the Siblings (to match the elementListItem parentList)
  let newList = document.createElement(elementListItem.parentElement.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', elementListItem.parentElement.style.cssText)
  if (elementListItem.parentElement.className) newList.setAttribute('className', elementListItem.parentElement.className) //This is particularly for the ATTORNEYLIST
  //2. Move the siblings into the newList. There shouldn't need to be a level move for the siblings
  for (let i = 0; i < siblings.length; i++) {
    newList.append(siblings[i])
    setListItem_LevelStyles({ listItem: siblings[i], chapterListLevels, modifyLevel: -1 })
  }
  //3. Move the elementListItem into the list of the hasLowerLeftElementOneIndent, the children will come with it.
  hasLowerLeftElementOneIndent.parentElement.insertBefore(elementListItem, hasLowerLeftElementOneIndent)
  //increaseMarginLeft(1, elementListItem, chapterListLevels)

  //4. Move the elementListItem children over one tab
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      setList_LevelStyles({ list: elementListItem.children[i], chapterListLevels, modifyLevel: 1 }) //This list needs to be adjusted before the listItem is adjusted.
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        //increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
        setListItem_LevelStyles({ listItem: elementListItem.children[i].children[c], chapterListLevels, modifyLevel: 1 })
      }
    }
  }
  //5. Append the newList of the siblings to the end of the moved elementListItem
  elementListItem.append(newList)
}

const leftHasChHasUpSibNoDnSib = ({
  currentElement,
  elementListItem,
  hasUpperLeftElementOneIndent,
  chapterListLevels,
  hasLowerLeftElementOneIndent
}) => {
  //0. Find that this target has children and has siblings above but not below
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //                  XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Save off the children of the target
  // 3. Get the style of the upper less level
  // 4. Get the index of the upper less level and add one where the target is to be moved
  // 5. Set the listItem style to the style of the upper less level
  // 6. Insert the target at the index found above of the upper list on the left (which will take it out of the populated list that it is leaving)
  // 7. Add one more margin-left tab indent value (36pt) to the children's listItems
  if (debugOutline) debugger
  console.log('function: leftHasChHasUpSibNoDnSib ')

  const listType = getListType(elementListItem)
  let siblings = getLowerSiblings(elementListItem)
  let childrenList = getListChildren(elementListItem)
  let grandParent = hasUpperLeftElementOneIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementOneIndent) indexInsert = i + 1 * 1
  }
  setLevelStyles(elementListItem, chapterListLevels, hasLowerLeftElementOneIndent)
  if (grandParent.children.length === indexInsert) {
    grandParent.append(elementListItem)
  } else {
    grandParent.insertBefore(elementListItem, grandParent.children[indexInsert])
  }
  appendCurrentElementAndSiblings(currentElement, elementListItem)
  if (siblings.length > 0 || (childrenList && childrenList.length > 0)) {
    let elementList = elementListItem.parentElement
    if (elementList) {
      let newList = document.createElement(elementList.nodeName)
      newList.id = getNextId(elementListItem.id)
      setLevelStyles(newList, chapterListLevels, elementListItem, 1)
      elementListItem.append(newList)
      const childrenListLength = childrenList.length
      for (let i = 0; i < childrenListLength; i++) {
        newList.append(childrenList[i])
        for (let c = 0; c < childrenList[i].children.length; c++) {
          increaseMarginLeft(1, childrenList[i].children[c], chapterListLevels)
        }
      }
      for (let i = 0; i < siblings.length; i++) {
        newList.append(siblings[i])
      }
      setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: -1, listType })
    }
  }
}

const leftHasChHasUpSibHasDnSibHasUprLeftOneIndent = ({
  elementListItem,
  hasUpperLeftElementOneIndent,
  chapterListLevels,
}) => {
  //0. Find that this target has children and has siblings above but not below
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //                  XXXX
  //            XXXX
  // Original June 2023 (replaced)
  //1. Get the nextSibling of hasUpperLeftElementOneIndent (If it doesn't exist, we will append lists to hasUpperLeftElementOneIndent instead of inserting before the nextSibling)
  //2. Create a newList for the lowerSiblings
  //3. Move the lowerSiblings to the newList
  //4. Move the elementListItem to follow hasUpperLeftElementOneIndent (It's children, if any will follow along with it) (this will be an append or an insertBefore depending on if the nextSibling exists or not)
  //5. Move the lowerSiblings over one tab level since they were just moved one tab level under the elementListItem
  //6. Append the newList of lowerSiblings to the elementListItem's new position
  //Reviewed and revised: 25 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasChHasUpSibHasDnSibHasUprLeftOneIndent ')


  //1. Get the parentList which may need to be deleted once it is empty (or it is going to wreak havoc for other movements later)
  const parentList = elementListItem.parentElement
  //1. Get the nextSibling of hasUpperLeftElementOneIndent (If it doesn't exist, we will append lists to hasUpperLeftElementOneIndent instead of inserting before the nextSibling)
  const nextSibling = hasUpperLeftElementOneIndent.nextSibling
  //2. Create a newList for the lowerSiblings
  let newList = document.createElement(elementListItem.parentElement.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', elementListItem.parentElement.style.cssText)
  //3. Move the lowerSiblings to the newList
  let siblings = getLowerSiblings(elementListItem)
  for (let i = 0; i < siblings.length; i++) {
    newList.append(siblings[i])
  }
  //4. Move the elementListItem to follow hasUpperLeftElementOneIndent (It's children, if any will follow along with it) (this will be an append or an insertBefore depending on if the nextSibling exists or not)
  if (nextSibling) {
    hasUpperLeftElementOneIndent.parentElement.insertBefore(elementListItem, nextSibling)
  } else {
    hasUpperLeftElementOneIndent.parentElement.append(elementListItem)
  }
  elementListItem.setAttribute('style', hasUpperLeftElementOneIndent.style.cssText)
  //5. Move the elementListItem children (before the siblings are added) over one tab level
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'LI') increaseMarginLeft(1, elementListItem.children[i], chapterListLevels)
    for (let c = 0; c < elementListItem.children[i].children.length; c++) {
      if (elementListItem.children[i].children[c].nodeName === 'LI') increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
    }
  }
  //6. Append the newList of lowerSiblings to the elementListItem's new position
  elementListItem.append(newList)
  if (parentList && !parentList.children.length) parentList.remove()
  if (newList && !newList.children.length) newList.remove()
}

const leftNoChHasUpSibNoDnSibHasUpLeftOneTab = ({
  elementListItem,
  hasUpperLeftElementOneIndent,
  chapterListLevels,
  hasLowerLeftElementOneIndent
}) => {
  //0. Find that this target has no children and siblings below but not siblings above but has an upper right element with an upper left element.
  // XXXX
  //      XXXX
  //                  XXXX
  //            0000
  //            XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Get the style of the upper less level
  // 4. Get the index of the upper less level and add one where the target is to be moved
  // 5. Set the listItem style to the style of the upper less level
  // 6. Insert the target at the index found above of the upper list on the left (which will take it out of the populated list that it is leaving)
  // 7. Do NOT delete the target's list element since there is a sibling.
  if (debugOutline) debugger
  console.log('function: leftNoChHasUpSibNoDnSibHasUpLeftOneTab ')

  let grandParent = hasUpperLeftElementOneIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementOneIndent) indexInsert = i + 1 * 1
  }
  setLevelStyles(elementListItem, chapterListLevels, hasLowerLeftElementOneIndent)
  if (grandParent.children.length === indexInsert) {
    grandParent.append(elementListItem)
  } else {
    grandParent.insertBefore(elementListItem, grandParent.children[indexInsert])
  }
  // 7. Do NOT delete the target's list element since there is a sibling.
}


const leftNoChHasUpSibHasDnSibNoUpLeftOneTabHasLwrLeftOneTab = ({ currentElement, elementListItem, chapterListLevels, hasLowerLeftElementOneIndent }) => {
  //            XXXX
  //            0000
  //            XXXX
  //       XXXX
  //1. Get the parentList of the hasLowerLeftElementOneIndent
  //2. Save the lowerSiblings of elementListItem (in order to create in a new list to put under the elementListItem)
  //3. Create a newList for the lowerSiblings (to put under the elementListItem after it is moved)
  //4. Move the lowerSiblings into the newList
  //5. Move the elementListItem into the hasLowerLeftElementOneIndent list (insertBefore)
  //6. Append the lowerSibling's newList in elementListItem
  //Created: 25 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChHasUpSibHasDnSibNoUpLeftOneTabHasLwrLeftOneTab ')

  const listType = getListType(elementListItem)
  //1. Get the parentList of the hasLowerLeftElementOneIndent
  let targetListItem = hasLowerLeftElementOneIndent
  if (targetListItem.nodeName === 'SPAN') targetListItem = targetListItem.parentElement //Just in case the hasLowerLeftElementOneIndent is a SPAN rather than the LI that we are expecting.
  let targetList = targetListItem.parentElement
  //2. Save the lowerSiblings of elementListItem (in order to create in a new list to put under the elementListItem)
  const siblings = getLowerSiblings(elementListItem)
  //3. Create a newList for the lowerSiblings (to put under the elementListItem after it is moved)
  let newList = document.createElement(elementListItem.parentElement.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', elementListItem.parentElement.style.cssText)
  //4. Move the lowerSiblings into the newList
  for (let i = 0; i < siblings.length; i++) { //The siblings will be moved so that the length will always decrease, so we alway smove siblings[0] until they are gone.
    //increaseMarginLeft(-1, siblings[0], chapterListLevels)
    setListItem_LevelStyles({ listItem: siblings[0], chapterListLevels, modifyLevel: -1, listType })
    newList.append(siblings[0])
  }
  //5. Move the elementListItem into the hasLowerLeftElementOneIndent list (insertBefore)
  targetList.insertBefore(elementListItem, targetListItem)
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
  elementListItem.setAttribute('style', targetListItem.style.cssText)
  //6. Append the lowerSibling's newList in elementListItem
  elementListItem.append(newList)
}

const leftNoChHasUpSibHasDnSibNoUpLeftOneTab = ({ currentElement, elementListItem, chapterListLevels }) => {
  //            XXXX
  //            0000
  //            XXXX
  //1. Get the parentList of the elementListItem
  //2. Get the nextSibling of the parentList
  //3. Create two lists to match the style of the existing list
  //4. The currentElement will go into the first list (but decrement the marginLeft by one tab value)
  //5. The lower siblings will go in the second list (as children of the currentElement - and decrement the marginLeft by one tab value)
  //6. If there is a nextSibling, place the newFirstList before it and place the newSecondList within the listChild listItem of newFirstList
  //7. Otherwise, append the newFirstList at the end of the grandParentListItem and place the newSecondList within the listChild listItem of newFirstList
  //8. Move the children of the newSecondList to the right one tab level.
  //9. Set the styles for the newFirstList and the NewFirstList's elementListitem
  //Reviewed and revised: 26 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftNoChHasUpSibHasDnSibNoUpLeftOneTab ')

  const listType = getListType(elementListItem)
  let siblings = getLowerSiblings(elementListItem)
  //1. Get the parentList of the elementListItem
  let parentList = elementListItem.parentElement
  let grandParentListItem = parentList.parentElement
  //2. Get the nextSibling of the parentList
  const parentListNextSibling = parentList.nextSibling
  //3. Create two lists to match the style of the existing list
  let newFirstList = document.createElement(parentList.nodeName)
  newFirstList.id = getNextId(elementListItem.id)
  newFirstList.setAttribute('style', parentList.style.cssText)
  let newSecondList = document.createElement(parentList.nodeName)
  newSecondList.id = getNextId(elementListItem.id)
  newSecondList.setAttribute('style', parentList.style.cssText)
  //4. The currentElement will go into the first list (but decrement the marginLeft by one tab value)
  newFirstList.append(elementListItem)

  //5. The lower siblings will go in the second list (No margin change is necessary)
  for (let i = 0; i < siblings.length; i++) {
    newSecondList.append(siblings[i])
  }
  //6. If there is a nextSibling, place the newFirstList before it and place the newSecondList within the listChild listItem of newFirstList
  if (parentListNextSibling) {
    grandParentListItem.insertBefore(newFirstList, parentListNextSibling)
    newFirstList.lastChild.append(newSecondList)
    //7. Otherwise, append the newFirstList at the end of the grandParentListItem and place the newSecondList within the listChild listItem of newFirstList
  } else {
    grandParentListItem.append(newFirstList)
    newFirstList.lastChild.append(newSecondList)
  }
  //8. Move the children of the newSecondList to the right one tab level.
  for (let i = 0; i < newSecondList.children.length; i++) {
    increaseMarginLeft(-1, newSecondList.children[i], chapterListLevels)
  }
  //9. Set the styles for the newFirstList and the NewFirstList's elementListitem
  setList_LevelStyles({ list: newFirstList, chapterListLevels, modifyLevel: -1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: -1 })
}

const leftHasUpSibNothingElseOtherThanChildren = ({
  elementListItem,
  chapterListLevels,
}) => {
  // XXXX
  //                   0000
  //                   XXXX
  // XXXX
  //We are just moving the list to the left one list-character to the left and then move the siblings into a children position
  //1. Get the parentList of the elementListItem
  //2. Get the siblings of the elementListItem
  //3. Move the list left one tab (for the list-character change)
  //4. move the first listItem to the left one tab (marginLeft)
  //5. Create a newList
  //6. Append the newList to the elementListItem
  //7. Move the lower siblings of the elementListItem to the newList which are now going to be children
  //8. Set the lower siblings to marginLeft as one tab 
  //Created 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasUpSibNothingElseOtherThanChildren ')

  const listType = getListType(elementListItem)
  //1. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //2. Get the siblings of the elementListItem
  const siblings = getSiblingsLower(elementListItem)
  //3. Move the list left one tab (for the list-character change)
  setList_LevelStyles({ list: parentList, chapterListLevels, modifyLevel: -1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  //4. move the first listItem to the left one tab (marginLeft)
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
  //5. Create a newList
  let newList = document.createElement(parentList.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', parentList.style.cssText)
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: 1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  //6. Append the newList to the elementListItem
  elementListItem.append(newList)
  //7. Move the lower siblings of the elementListItem to the newList which are now going to be children
  for (let i = 0; i < siblings.length; i++) {
    if (siblings[i].nodeName === 'LI') {
      newList.append(siblings[i])
      //8. Set the lower siblings to marginLeft as one tab 
      setListItem_LevelStyles({ listItem: siblings[i], chapterListLevels, modifyLevel: 0, listType, forceTab: 1 })
    }
  }
}

const leftHasNothingElseOtherThanChildren = ({
  elementListItem,
  chapterListLevels,
}) => {
  // XXXX
  //                   0000
  //                         |XXX|
  // XXXX
  //1. Get the parentList of elementListItem
  //2. Move the parentList to the left by setting the list-character down one.
  //3. Move the elementListItem to the left by one tab level.
  //4. Increase the children's tab level by one to the right but keep the list-character the same.
  //Created: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: leftHasNothingElseOtherThanChildren ')

  const listType = getListType(elementListItem)
  //1. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //2. Move the parentList to the left by setting the list-character down one.
  setList_LevelStyles({ list: parentList, chapterListLevels, modifyLevel: -1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  //3. Move the elementListItem to the left by one tab level.
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
  //4. Increase the children's tab level by one to the right but keep the list-character the same.
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
}


const increaseMarginLeft = (tabCount = 1, element, chapterListLevels) => {
  const listType = getListType(element)
  const averageMargin = getAverageMarginIncrement(listType, chapterListLevels)
  let localAddMarginLeft = averageMargin * tabCount
  if (element) {
    let marginLeft = (element.style && element.style['margin-left']) || '0'
    marginLeft = parseFloat(marginLeft.match(/-?[\d.]+/)[0])
    marginLeft = String(Number(marginLeft) + Number(localAddMarginLeft))
    if (marginLeft < 0) marginLeft = 0
    element.style['margin-left'] = marginLeft + 'pt'
  }
}

const getLowerSiblings = (elementListItem) => {
  if (elementListItem) {
    let siblings = []
    let nextSibling = elementListItem.nextSibling
    while (nextSibling) {
      siblings.push(nextSibling)
      nextSibling = nextSibling.nextSibling
    }
    return siblings
  }
}

const getUpperSiblings = (elementListItem) => {
  let siblings = []
  let previousSibling = elementListItem.previousSibling
  while (previousSibling) {
    siblings.unshift(previousSibling)
    previousSibling = previousSibling.previousSibling
  }
  return siblings
}

const getListChildren = (elementListItem) => {
  let childrenList = []
  for (let i = 0; i < elementListItem.children.length; i++) {
    if ((elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') && elementListItem.children[i].children.length > 0) childrenList.push(elementListItem.children[i])
  }
  return childrenList
}

const getTabMarginCount = (element) => {
  if (element && element.style && element.style['margin-left']) {
    let marginLeft = element.style['margin-left']
    marginLeft = marginLeft.match(/\d+/)
    let result = Math.round(marginLeft / globalAddMarginLeft)
    return result === 0 ? 1 : result
  }
}

export const getElementLevel = (element) => {
  //How many UL or OL parents exist?
  if (element) {
    let level = element.nodeName === 'OL' || element.nodeName === 'UL' || element.nodeName === 'LI' ? 1 : 0 //We don't want to skip the very listItem that we might be on to start with
    let marginLeft = element.style['margin-left'].match(/\d+/)
    let tabsAvailable = Math.round(marginLeft / globalAddMarginLeft) - 1
    level += tabsAvailable <= 0 ? 0 : tabsAvailable
    let parent = element && element.parentElement
    const editorDivId = getEditorDivIdFromElementId(element.id)
    const firstElementId = getFirstElementIdFromElementId(element.id)
    while (parent && parent.id !== firstElementId && parent.id !== editorDivId) {
      //If the element itself has tabs greater than one (36pt) then add onto the level count. We are rounding just to be sure that we aren't counting a margin-left value that might just be over 36pt but not enough to be considered an additional tab
      marginLeft = parent.style['margin-left'].match(/\d+/)
      tabsAvailable = Math.round(marginLeft / globalAddMarginLeft) - 1
      level += tabsAvailable <= 0 ? 0 : tabsAvailable
      if (parent.nodeName === 'UL' || parent.nodeName === 'OL') {
        level++ //We used to look at getTabMarginCount(parent) but if the first margin is bigger than 36, it is going to round up to 2.
      }
      parent = parent.parentElement
    }
    return level
  }
}

//***************** Functions for RIGHT Moves  ************************************

const rightHasChNoLwrSibNoUpSibNoUpRtIndent = ({ currentElement, elementListItem, chapterListLevels, hasLowerRightElementOneIndent }) => {
  // 0000
  //      XXXX
  //We are going to move the children into the elementListItem since that will preserve the leftMargin of the current listItem. Then we will move the list-character to the next one to the right.
  //1. Get the parentList of the elementListItem
  //2. Move the parentList level to the next one to the right (list-character)
  //3. Increase the marginLeft by 1 to the right
  //3. Loop through the children and append them to the end of the parentList (which will bring the children along)
  //4. Delete the hasLowerRightElementOneIndent.list
  //Revised: 26 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightHasChNoLwrSibNoUpSibNoUpRtIndent ')

  const listType = getListType(elementListItem)
  //1. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //2. Move the parentList level to the next one to the right (list-character)
  setList_LevelStyles({ list: parentList, chapterListLevels, modifyLevel: 1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  //3. Increase the marginLeft by 1 to the right
  increaseMarginLeft(1, elementListItem, chapterListLevels)
  //3. Loop through the children and append them to the end of the parentList (which will bring the children along)
  for (let i = 0; i < hasLowerRightElementOneIndent.list.children.length;) {  //Notice that we are not incrementing the i++ because the list gets shorter every time we append a child, so we always append the [0]
    if (hasLowerRightElementOneIndent.list.children[0].nodeName === 'LI') {
      hasLowerRightElementOneIndent.list.children[0].setAttribute('style', elementListItem.style.cssText)
      parentList.append(hasLowerRightElementOneIndent.list.children[0])
    }
  }
  //4. Delete the hasLowerRightElementOneIndent.list
  hasLowerRightElementOneIndent.list.remove()

  // //1. Get the parentList of the elementListItem
  // const parentList = elementListItem.parentElement
  // //2. Get the grandParentListItem of the parentList
  // const grandParentListItem = parentList.parentElement
  // //3. Get the hasLowerRightElementOneIndent.list (We already have it)
  // //4. Move the hasLowerRightElementOneIndent.list above the parentList
  // grandParentListItem.insertBefore(hasLowerRightElementOneIndent.list, parentList)
  // //5. Copy the style of the parentList into the hasLowerRightElementOneIndent.list
  // hasLowerRightElementOneIndent.list.setAttribute('style', parentList.style.cssText)
  // //6. Increase the marginLeft by one tab level of the hasLowerRightElementOneIndent.list
  // increaseMarginLeft(1, hasLowerRightElementOneIndent.list, chapterListLevels)
  // //7. Move the elementListItem into the top of the hasLowerRightElementOneIndent.list 
  // hasLowerRightElementOneIndent.list.insertBefore(elementListItem, hasLowerRightElementOneIndent.listItem)
  // //8. Match the style of the elementListItem with the hasLowerRightElementOneIndent.listItem
  // elementListItem.setAttribute('style', hasLowerRightElementOneIndent.listItem.style.cssText)
  // //9. Delete the parentList
  // parentList.remove()
  // setList_LevelStyles({ list: hasLowerRightElementOneIndent.list, chapterListLevels, modifyLevel: 1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  // for (let i = 0; i < hasLowerRightElementOneIndent.list.children.length; i++) {
  //   if (hasLowerRightElementOneIndent.list.children[i].nodeName === 'LI') {
  //     setListItem_LevelStyles({ listItem: hasLowerRightElementOneIndent.list.children[i], chapterListLevels, modifyLevel: 2, listType })
  //   }
  // }
}

const rightNoChNoUpSibNoDnSibNoUpperRtIndent = ({ currentElement, elementListItem, chapterListLevels }) => {
  // XXXX
  //      0000
  //1. Get the parentList of the elementListItem
  //1. Set the parentList of the elementListItem to the next level to the right
  //1. Increase the margin Left by one tab level to the right
  //Revised: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightNoChNoUpSibNoDnSibNoUpperRtIndent ')
  const listType = getListType(elementListItem)
  //1. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //1. Set the parentList of the elementListItem to the next level to the right
  setList_LevelStyles({ list: parentList, chapterListLevels, modifyLevel: 1, listType })
  //1. Increase the margin Left by one tab level to the right
  increaseMarginLeft(1, elementListItem, chapterListLevels)
}

const rightNoChNoUpSibNoDnSibHasUpRtIndent = ({
  currentElement,
  elementListItem,
  hasUpperRightElementOneIndent,
  chapterListLevels
}) => {
  //            XXXX
  //      0000
  // XXXX
  //Done 6/27/2023
  //1. Get the parentList of elementListItem
  //2. Move the elementListItem by appending it to the list of hasUpperRightElementOneIndent.parentElement
  //3. Move any children one tab level left since the whole elementListItem and children are being moved one tab level to the right.
  //4. If the elementListItem's parentList is empty after being moved, let's delete it. It is going to get in the way of other moves if we don't.
  //Reviewed and revised: 26 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightNoChNoUpSibNoDnSibHasUpRtIndent ')

  //1. Get the parentList of elementListItem
  const parentList = elementListItem.parentElement

  if (hasUpperRightElementOneIndent.nodeName === 'OL' || hasUpperRightElementOneIndent.nodeName === 'UL') hasUpperRightElementOneIndent = hasUpperRightElementOneIndent.firstChild
  if (hasUpperRightElementOneIndent.nodeName === 'SPAN') hasUpperRightElementOneIndent = hasUpperRightElementOneIndent.parentElement
  //2. Move the elementListItem by appending it to the list of hasUpperRightElementOneIndent.parentElement
  hasUpperRightElementOneIndent.parentElement.append(elementListItem)
  //3. Move any children one tab level left since the whole elementListItem and children are being moved one tab level to the right.
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'UL' || elementListItem.children[i].nodeName === 'OL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
  elementListItem.setAttribute('style', hasUpperRightElementOneIndent.style.cssText)
  //4. If the elementListItem's parentList is empty after being moved, let's delete it. It is going to get in the way of other moves if we don't.
  if (parentList.children.length === 0) parentList.remove()
}

const rightHasUpRtIndentHasUpRtTwoIndent = ({
  elementListItem,
  chapterListLevels,
  hasUpperRightElementOneIndent,
}) => {
  //           XXXX
  //                XXXX
  //      0000
  //This could be more simple than we think
  //1. Move the elementListItem by appending it to the list of hasUpperRightElementOneIndent.parentElement
  //Created: 25 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightHasUpRtIndentHasUpRtTwoIndent ')

  if (hasUpperRightElementOneIndent.nodeName === 'OL' || hasUpperRightElementOneIndent.nodeName === 'UL') hasUpperRightElementOneIndent = hasUpperRightElementOneIndent.firstChild
  if (hasUpperRightElementOneIndent.nodeName === 'SPAN') hasUpperRightElementOneIndent = hasUpperRightElementOneIndent.parentElement
  hasUpperRightElementOneIndent.parentElement.append(elementListItem)
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'UL' || elementListItem.children[i].nodeName === 'OL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
  elementListItem.setAttribute('style', hasUpperRightElementOneIndent.style.cssText)
}

const rightHasChildrenHasUpsiblingNoUpRtIndentNoUpLeftIndent = ({
  elementListItem,
  chapterListLevels,
}) => {
  //      XXXX
  //      0000
  //                           XXXX
  //      |XXX|
  //We are going to move the entire elementListItem with its children to the bottom of the upper sibling (and then the lower sibling, if any, will just go to the next consecutive number as the upper sibling will get the newList of the elementListItem)
  //1. Get the upper sibling
  //2. Get the parentList of the elementListItem
  //3. Create a newList (that will be appended to the bottom of the upper sibling and hold the elementListItem)
  //4. Copy the styles of the parentList to the newLIst (in preparation to move the list-character up one level)
  //5. Append the newList to the end of the previousSibling
  //6. Move the elementListItem inside the newList
  //7. Modify the newList to be the next list-character to the right
  //8. If the elementListItem has children, move the listItems to the left one tab level since they were all just pushed over one tab level to the right with the elementListItem
  //Created: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightHasChildrenHasUpsiblingNoUpRtIndentNoUpLeftIndent ')

  const listType = getListType(elementListItem)
  //1. Get the upper sibling
  const previousSibling = elementListItem.previousSibling
  //2. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //3. Create a newList (that will be appended to the bottom of the upper sibling and hold the elementListItem)
  let newList = document.createElement(parentList.nodeName)
  newList.id = getNextId(elementListItem.id)
  //4. Copy the styles of the parentList to the newLIst (in preparation to move the list-character up one level)
  newList.setAttribute('style', parentList.style.cssText)
  //5. Append the newList to the end of the previousSibling
  previousSibling.append(newList)
  //6. Move the elementListItem inside the newList
  newList.append(elementListItem)
  //7. Modify the newList to be the next list-character to the right
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: 0, listType, forceTab: 1 }) //This list needs to be adjusted before the listItem is adjusted.
  //setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: 0, listType, forceTab: 1 })
  //8. If the elementListItem has children, move the listItems to the left one tab level since they were all just pushed over one tab level to the right with the elementListItem
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
}



const rightHasChNoUpsiblingNoUpRtIndentNoRtTwoIndentNoUpLeftIndentNoLeftTwoIndent = ({
  elementListItem,
  chapterListLevels,
}) => {
  //      0000
  //                           XXXX
  //This elementListItem is already it's own list. We are just going to push it over further from the left and then move the children (listItems) to the left.
  //1. Get the parentList of the elementListItem
  //2. Set the list one more list-character to the right for another tab level
  //3. Decrease the marginLeft of the children listItems to the left by one tab to the left.
  //Created: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightHasChildrenHasUpsiblingNoUpRtIndentNoUpLeftIndent ')

  const listType = getListType(elementListItem)
  //1. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //2. Set the list one more list-character to the right for another tab level
  setList_LevelStyles({ list: parentList, chapterListLevels, modifyLevel: 1, listType }) //This list needs to be adjusted before the listItem is adjusted.
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: 1, listType })
  //3. Decrease the marginLeft of the children listItems to the left by one tab to the left.
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
}

const  rightHasChHasUpsiblingHasUpRtIndent = ({
    elementListItem,
    chapterListLevels,
    hasUpperRightElementOneIndent
  }) => {
  // XXXX
  //      XXXX
  //      XXXX
  //            XXXX
  // 0000
  //                           XXXX
  //1. get the parentList of the hasUpperRightElementOneIndent
  //2. Append the elementListItem to the end of hasUpperRightElementOneIndent
  //3. Decrease the marginLeft of the children (listItems) of elementListItem 
  //Created: 28 Oct 2024
  if (debugOutline) debugger
  //1. get the parentList of the hasUpperRightElementOneIndent
  const parentList = hasUpperRightElementOneIndent.parentElement
  //2. Append the elementListItem to the end of hasUpperRightElementOneIndent
  parentList.append(elementListItem)
  //3. Decrease the marginLeft of the children (listItems) of elementListItem 
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
}

const rightHasChNoUpSibHasDnSibNoUpRtIndent = ({ 
  elementListItem, 
  chapterListLevels, 
  hasLowerRightElementOneIndent 
}) => {
  // XXXX
  //      0000
  //                 |XXX|
  //            XXXX
  //      XXXX
  //The challenge that might not be obvious at first is that we are ending up with an inset list at the top of this sibling list. So we will be creating a new List that will be placed on top of the nextSibling of the elementListItem
  //1. Get the children from the elementListItem (in order to put them under the newListItem when elementListItem is moved there)
  //1. Create a newListItem (for the elementListItem to transfer to the top of the lower right child list)
  //2. Match the style of the newListItem to the elementListItem - at least as an anchor to start with and then we will move it over one in the code below.
  //3. Match the styleof elementListItem for the newListItem and the current children of targetList. Then the adjustment of the children together will put them in the right place as far as the level is concerned.
  //4. Move the elementListItem spans into the newListItem 
  //5. Insert the newListItem before the first child of the lower right child list
  //6. Move the lower right child list above the current parentList (insert Before the parentList from the grandParentList)
  //7. Move the lower right child list over one level now that it is moved up and left one level
  //8. Remove the old elementListItem which should now be free of the lower right child list so that that list won't be inadvertently deleted.
  //Reviewed and revised: 26 Oct 2024 
  if (debugOutline) debugger
  console.log('function: rightHasChNoUpSibHasDnSibNoUpRtIndent ')

  const listType = getListType(elementListItem)
  let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels) //This needs to be a listItem
  targetLevel++
  const list = elementListItem.parentElement
  const parentListItem = list.parentElement
  const targetList = hasLowerRightElementOneIndent.list
  const targetListItem = hasLowerRightElementOneIndent.listItem
  //1. Get the children from the elementListItem (in order to put them under the newListItem when elementListItem is moved there)
  const elementListItemChildren = getListChildren(elementListItem)
  let newChildrenList = document.createElement(list.nodeName)
  newChildrenList.id = getNextId(elementListItem.id)
  //7. Place the children under the newChildrenList
  //Get the children until we hit the targetList
  for (let i = 0; i < elementListItemChildren.length; i++) {
    if (hasLowerRightElementOneIndent.list === elementListItemChildren[i]) {
      break
    } 
    newChildrenList.append(elementListItemChildren[i])
    for (let c = 0; c < elementListItemChildren[i].children.length; c++) {
      increaseMarginLeft(-1, elementListItemChildren[i].children[c], chapterListLevels)
    }
  }
  //1. Create a newListItem (for the elementListItem to transfer to the top of the lower right child list)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId(elementListItem.id)
  //2. Match the style of the newListItem to the elementListItem - at least as an anchor to start with and then we will move it over one in the code below.
  newListItem.setAttribute('style', elementListItem.style.cssText)
  //3. Match the styleof elementListItem for the newListItem and the current children of targetList. Then the adjustment of the children together will put them in the right place as far as the level is concerned.
  for (let i = 0; i < targetList.children.length; i++) {
    targetList.children[i].setAttribute('style', elementListItem.style.cssText)
  }
  //4. Move the elementListItem spans into the newListItem 
  appendListItemSpans(newListItem, elementListItem)
  //5. Insert the newListItem before the first child of the lower right child list
  targetList.insertBefore(newListItem, targetListItem)
  for (let i = 0; i < newChildrenList.children.length; i++) {
    newListItem.append(newChildrenList.children[i])
  }
  //6. Move the lower right child list above the current parentList (insert Before the parentList from the grandParentList)
  parentListItem.insertBefore(targetList, list)
  //newListItem.append(newChildrenList)

  //7. Remove the old elementListItem which should now be free of the lower right child list so that that list won't be inadvertently deleted.
  elementListItem.remove()
  //8. Move the lower right child list over one level now that it is moved up and left one level
  for (let i = 0; i < targetList.children.length; i++) {
    setListItem_LevelStyles({ listItem: targetList.children[i], chapterListLevels, modifyLevel: 1, listType })
  }
}

const rightNoChNoUpSibHasDnSibHasUpRtIndent = ({
  currentElement,
  elementListItem,
  hasUpperRightElementOneIndent,
  chapterListLevels
}) => {
  //            XXXX
  //      0000
  //      XXXX
  //1. Get the hasUpperRightElementOneIndent which is an elementListItem
  //2. Get the elementListItemStyle
  //3. Create an elementListItem to match the hasUpperRightElementOneIndent
  //4. Append the elementListItem to the bottom of the parent of hasUpperRightElementOneIndent
  //5. Move the currentElement to the elementListItem
  //6. Delete the old currentElementListItem
  if (debugOutline) debugger
  console.log('function: rightNoChNoUpSibHasDnSibHasUpRtIndent ')

  let targetElementListItem = hasUpperRightElementOneIndent
  let targetElementList = targetElementListItem.parentElement
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId(elementListItem.id)
  //setLevelStyles(newListItem, chapterListLevels, targetElementListItem)
  newListItem.setAttribute('style', targetElementListItem.style.cssText)
  appendListItemSpans(newListItem, elementListItem)
  targetElementList.append(newListItem)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style'] = 'none'
  } else {
    elementListItem.remove()
  }
}

const rightNoChNoUpSibNoDnSibNoUpRtIndent = ({ currentElement, elementListItem, chapterListLevels }) => {
  // XXXX
  //      0000
  // XXXX
  //1. Add another elementList and elementListItem
  //2. Move the currentElement to the elementListItem
  //3. Make sure that the currentElementListItem's list-style-type is removed
  if (debugOutline) debugger
  console.log('function: rightNoChNoUpSibNoDnSibNoUpRtIndent ')

  const listType = getListType(elementListItem)
  let elementList = elementListItem.parentElement
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId(elementListItem.id)
  setLevelStyles(newList, chapterListLevels, elementList, 1)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId(elementListItem.id)
  setLevelStyles(newListItem, chapterListLevels, elementListItem, 1)
  appendCurrentElementAndSiblings(currentElement, newListItem)
  newList.append(newListItem)
  elementListItem.append(newList)
  elementListItem.style['list-style'] = 'none'
  if (elementList && !(elementList.children && elementList.children.length > 0)) elementList.remove()
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: -1, listType })
}

const rightNoChHasUpSibNoDnSibNoUpRtIndent = ({ elementListItem, chapterListLevels }) => {
  //      XXXX
  //      0000
  // XXXX
  //1. Get the upperSibling in order to append a newList with this elementListItem
  //2. Get the currentLevel and add one more for the childrenTargetLevel
  //3. Get the parentList of the elementListItem
  //4. Create a newList
  //5. Create a newListItem with the same id as the elementListItem
  //6. Copy the elementListItem's children into the newListItem 
  //7. Append the newListItem to the newList
  //8. Delete the old elementListItem
  //9. Append the newList to the parentList
  //10. Set the listItemLevel to one more over.
  //11. Set the listLevel to one more over
  //Reviewed and revised: 26 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightNoChHasUpSibNoDnSibNoUpRtIndent ')

  const listType = getListType(elementListItem)
  //1. Get the upper sibling
  const previousSibling = elementListItem.previousSibling
  //2. Get the current list level
  let targetListLevel = getListItemLevelDepth(listType, previousSibling, chapterListLevels) //This needs to be a listItem
  targetListLevel++
  //2. Get the parentList of the elementListItem
  const parentList = elementListItem.parentElement
  //3. Create a newList (that will be appended to the bottom of the upper sibling)
  let newList = document.createElement(parentList.nodeName)
  newList.id = getNextId(elementListItem.id)
  //4. Copy the styles of the parentList to the newList (in preparation to move the list-character up one level)
  newList.setAttribute('style', parentList.style.cssText)
  previousSibling.append(newList)
  //5. Move the elementListItem inside the newList
  newList.append(elementListItem)
  //5. Set the list-character according to the listLevel
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: 1, listType, forceLevel: targetListLevel }) //This list needs to be adjusted before the listItem is adjusted.
  //6. Modify the newList to be the next list-character to the right
  setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: -1, listType, forceTab: 1 })
  //7. If the elementListItem has children, move the listItems to the left one tab level since they were all just pushed over one tab level to the right with the elementListItem
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
        }
      }
    }
  }
}

const rightNoChHasUpSibHasDnSibNoUpRtIndent = ({ currentElement, elementListItem, chapterListLevels }) => {
  //      XXXX
  //      0000
  //      XXXX
  //Reviewed and revised: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightNoChHasUpSibHasDnSibNoUpRtIndent ')

  const listType = getListType(elementListItem)
  let previousSibling = elementListItem.previousElementSibling
  let elementList = elementListItem.parentElement
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId(elementListItem.id)
  newList.setAttribute('style', elementList.style.cssText)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId(elementListItem.id)
  appendListItemSpans(newListItem, elementListItem)
  newList.append(newListItem)
  previousSibling.append(newList)
  elementListItem.remove()
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: 0, listType }) //This list needs to be adjusted before the listItem is adjusted.
  setListItem_LevelStyles({ listItem: newListItem, chapterListLevels, modifyLevel: 1, listType })
}

const rightNoSblUpperNoSblLowerNoUprRtOneIndentNoLwrRtOneIndent = ({
  elementListItem,
  chapterListLevels,
}) => {
  //                  XXXX
  //      0000
  //                  XXXX
  //1. Get the parentList from elementListItem
  //1. Move the parentList list-character to the right one.
  //1. Decrease the children (listItems) of elementListItem to the left one tab since the children of elementListItem were moved right by one tab level with elementListItem
  //Revised: 28 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightNoSblUpperNoSblLowerNoUprRtOneIndentNoLwrRtOneIndent ')

  const listType = getListType(elementListItem)
  //1. Get the parentList from elementListItem
  let parentList = elementListItem.parentElement
  //1. Move the parentList list-character to the right one.
  setList_LevelStyles({ list: elementListItem, chapterListLevels, modifyLevel: 1, listType })
  increaseMarginLeft(1, elementListItem, chapterListLevels)
  //1. Decrease the children (listItems) of elementListItem to the left one tab since the children of elementListItem were moved right by one tab level with elementListItem
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'UL' || elementListItem.children[i].nodeName === 'OL') {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
      }
    }
  }
}

const rightHasChHasUpSibNoDnSibNoUpRtIndent = ({
  currentElement,
  elementListItem,
  chapterListLevels,
  hasLowerRightElementOneIndent
}) => {
  //      XXXX
  //      0000
  //           XXXX
  //Look below in the children (OL or UL lists that are underneath) that belong to this currentElement (underneath).
  // If there is a child that is just one more level up,
  //    attach to the child that belongs to the next level up (targetLevel).
  //    Any children in between are still under this currentElement bue decremented by one tab (marginLeft)
  // Else
  //    Just make the currentElement a child below the upper sibling,
  //    keep the other children (in between) as children but decrement each one by one.
  // End if
  //1. Get the elementList for the child
  //2. Get the elementListItem for the child
  //3. Get the elementListItemStyle
  // ...
  //7. Make sure that the currentElementListItem's list-style-type is removed
  //Done 6/26/2023
  //ToDo update childrenList
  if (debugOutline) debugger
  console.log('function: rightHasChHasUpSibNoDnSibNoUpRtIndent ')

  const listType = getListType(elementListItem)
  let currentLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels)
  let targetLevel = ++currentLevel
  let upperSibling = elementListItem.previousElementSibling
  let list = elementListItem.parentElement
  let childrenList = getListChildren(elementListItem)
  if (childrenList) {
    let nextLevelChild
    for (let i = 0; i < childrenList.length; i++) {
      if (getListItemLevelDepth(listType, childrenList[i], chapterListLevels) === targetLevel) {
        nextLevelChild = childrenList[i]
      }
    }
    if (nextLevelChild) {
      //    attach to the child that belongs to the next level up (targetLevel).
      //    Any children in between are still under this currentElement bue decremented by one tab (marginLeft)
      let newListItem = document.createElement('LI')
      newListItem.id = getNextId(elementListItem.id)
      setLevelStyles(newListItem, chapterListLevels, nextLevelChild.firstChild)
      appendListItemSpans(newListItem, elementListItem)
      nextLevelChild.insertBefore(newListItem, nextLevelChild.firstChild)
      for (let i = 0; i < childrenList.length; i++) {
        if (childrenList[i] !== nextLevelChild) {
          newListItem.append(childrenList[i])
          for (let c = 0; c < childrenList[i].children.length; c++) {
            increaseMarginLeft(-1, childrenList[i].children[c], chapterListLevels)
          }
        }
      }
      upperSibling.append(nextLevelChild)
    } else {
      //    Just make the currentElement a child below the upper sibling,
      //    keep the other children (in between) as children but decrement each one by one.
      let newList = document.createElement(list.nodeName)
      newList.id = getNextId(elementListItem.id)
      newList.setAttribute('style', list.style.cssText)
      newList.append(elementListItem)
      setLevelStyles(newList, chapterListLevels, list, 1)
      const childrenLength = childrenList?.children?.length || 0
      for (let i = 0; i < childrenLength; i++) {
        for (let c = 0; c < childrenLength[i].children.length; c++) {
          increaseMarginLeft(-1, childrenLength[i].children[c], chapterListLevels)
        }
      }
      upperSibling.append(newList)
      setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: -1, listType })
    }
    //elementListItem.style['list-style'] = 'none'
    elementListItem.remove()
  }
}

const rightHasChHasLwrRtIndent = ({
  currentElement,
  elementListItem,
  chapterListLevels,
  hasLowerRightElementOneIndent
}) => {
  // XXXX |XXX|
  //      0000
  //                XXXX
  //           XXXX
  //1. Get the elementListItem's previousSibling, if it exists 
  //2. If the previousSibling exists,
  //3.   Append the targetList to the end of the previousSibling
  //4.   Move the elementListItem into the first position of the targetList
  //5  else
  //6    Leave the targetList in place 
  //7.   Create a tempList
  //8.   Move the elementLIstItem into the tempList (and the children will go with it)
  //9.   Blank out the elementLIstItem
  //9. end if
  //Reviewed and revised: 24 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightHasChHasLwrRtIndent ')

  const listType = getListType(elementListItem)
  //1. Get the elementListItem's previousSibling, if it exists 
  const previousSibling = elementListItem.previousSibling
  //2. If the previousSibling exists,
  if (previousSibling && previousSibling.nodeName === 'LI') {
    //3.   Append the targetList to the end of the previousSibling
    previousSibling.append(hasLowerRightElementOneIndent.list)
    //4.   Move the elementListItem into the first position of the targetList
    hasLowerRightElementOneIndent.list.insertBefore(elementListItem, hasLowerRightElementOneIndent.listItem)
    //increaseMarginLeft(1, elementListItem, chapterListLevels)
    setListItem_LevelStyles({ listItem: elementListItem, chapterListLevels, modifyLevel: 1, listType })
    elementListItem.setAttribute('style', hasLowerRightElementOneIndent.listItem.style.cssText)
    for (let i = 0; i < elementListItem.children.length; i++) {
      for (let c = 0; c < elementListItem.children[i].children.length; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
      }
    }
    //5  else
  } else {
    //6    Leave the targetList in place 
    //7.   Create a tempList
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId(elementListItem.id)
    newListItem.setAttribute('style', hasLowerRightElementOneIndent.listItem.style.cssText)
    increaseMarginLeft(1, newListItem, chapterListLevels)
    //8. Move the currentElement(s) into the newlistItem from the current elementListItem
    appendListItemSpans(newListItem, elementListItem)
    //9. Move any children that are left in the current elementListItem (except the target element)
    //     append first and then increaseMarginLeft
    for (let i = 0; i < elementListItem.children.length; i++) {
      if (elementListItem.children[0] !== hasLowerRightElementOneIndent.list) {
        newListItem.append(elementListItem.children[0])
        for (let c = 0; c < elementListItem.children[0].children.length; c++) {
          if (elementListItem.children[0].children[c].nodeName === 'LI') increaseMarginLeft(-1, elementListItem.children[0].children[c], chapterListLevels)
        }
      }
    }
    //10.   Move the newListItem into the first position of the hasLowerRightElementOneIndent.list
    hasLowerRightElementOneIndent.list.insertBefore(newListItem, hasLowerRightElementOneIndent.listItem)
    //11.   Blank out the old elementListItem
    elementListItem.style.listStyle = ''
    elementListItem.style.listStyleType = ''
  }
  //9. end if
}

const rightNoChNoUpSibHasDnSibNoUpRtIndent = ({
  currentElement,
  elementListItem,
  hasUpperRightElementOneIndent,
  chapterListLevels
}) => {
  //  XXXX
  //           0000
  //           XXXX
  if (debugOutline) debugger
  console.log('function: rightNoChNoUpSibHasDnSibNoUpRtIndent ')

  const listType = getListType(elementListItem)
  let elementList = elementListItem.parentElement
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId(elementListItem.id)
  setLevelStyles(newList, chapterListLevels, elementList, 1)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId(elementListItem.id)
  setLevelStyles(newListItem, chapterListLevels, elementListItem, 1)
  elementList.append(newList) //We append to the list (not the listItem) and then delete the listItem which should be empty by then.
  newList.append(newListItem)
  appendListItemSpans(newListItem, elementListItem)
  if (!(elementListItem && elementListItem.children.length > 0)) {
    elementListItem.remove()
  } else {
    elementListItem.style['list-style'] = 'none'
  }
  setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: -1, listType })
}

const rightHasChNoUpSibHasUpRtOneIndent = ({
  elementListItem,
  hasUpperRightElementOneIndent,
  chapterListLevels
}) => {
  //           XXXX
  //      0000
  //           XXXX
  //We need to take into consideration children of the elementListItem which are on the same targetLevel to be merged under this elementListItem as well. Other children can continue to be children untouched (other than moved over one tab left since we are going over one tab right with the elementListItem)
  //This needs to work for whatever level the user is on. The targetLevel needs to set the joined elementListItem to the left between two separate lists merged all into one vertically.
  //1. Get the targetLevel where we are moving the elementListItem to the right
  //2. Save the targetLevelChildren (don't bother with the nonTargetLevelChildren children since they wlll stay with the elementListItem)
  //3. Get the targetList of hasUpperRightElementOneIndent
  //4. Append the elementListItem at the end of the targetList
  //5. Append the targetLevelChildren in the targetList (which are listItems. so remove the list every time the loop comes to an end for that list)
  //6. decrease the margin one tab left of the elementListItem children that are left over.
  //Revised: 25 Oct 2024
  if (debugOutline) debugger
  console.log('function: rightHasChNoUpSibHasUpRtOneIndent ')

  const listType = getListType(elementListItem)
  //1. Get the targetLevel where we are moving the elementListItem to the right
  const targetLevel = getListItemLevelDepth(listType, hasUpperRightElementOneIndent, chapterListLevels)
  //2. Save the targetLevelChildren (don't bother with the nonTargetLevelChildren children since they wlll stay with the elementListItem)
  let targetLevelChildren = []
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      if (getListItemLevelDepth(listType, elementListItem.children[i].firstChild, chapterListLevels) === targetLevel) {
        targetLevelChildren.push(elementListItem.children[i])
      }
    }
  }
  //3. Get the targetList of hasUpperRightElementOneIndent
  let targetListItem = hasUpperRightElementOneIndent.nodeName === 'SPAN' ? hasUpperRightElementOneIndent.parentElement : hasUpperRightElementOneIndent.nodeName === 'OL' || hasUpperRightElementOneIndent.nodeName === 'UL' ? hasUpperRightElementOneIndent.firstChild : hasUpperRightElementOneIndent
  let targetList = targetListItem.parentElement
  //4. Append the elementListItem at the end of the targetList
  targetList.append(elementListItem)
  elementListItem.setAttribute('style', targetListItem.style.cssText) //Copy the style which is the most straight forward to get this merged into the same level without having to get specific about levels and marginLeft tabs - which was proving inconsistent.
  //5. Append the targetLevelChildren in the targetList
  for (let i = 0; i < targetLevelChildren.length; i++) {
    for (let c = 0; targetLevelChildren[i].children.length > 0; c++) {
      targetLevelChildren[i].children[0].setAttribute('style', targetListItem.style.cssText) //Copy the style which is the most straight forward to get this merged into the same level without having to get specific about levels and marginLeft tabs - which was proving inconsistent.
      targetList.append(targetLevelChildren[i].children[0])  //Notice the 0 index instead of dynamic c. Because the length just decreased when the child was moved to the new location.
    }
    targetLevelChildren[i].remove()
  }
  //6. decrease the margin one tab left of the elementListItem children that are left over.
  for (let i = 0; i < elementListItem?.children.length || 0; i++) {
    if (elementListItem.children[i].nodeName === 'UL' || elementListItem.children[i].nodeName === 'OL') {
      for (let c = 0; c < elementListItem.children[i].children.length || 0; c++) {
        if (elementListItem.children[i].children[c].nodeName === 'LI') {
          increaseMarginLeft(-1, elementListItem.children[i].children[c], chapterListLevels)
          elementListItem.children[i].children[c].setAttribute('style', targetListItem.style.cssText) //Copy the style which is the most straight forward to get this merged into the same level without having to get specific about levels and marginLeft tabs - which was proving inconsistent.
        }
      }
    }
  }
}

const rightHasChHasUpSibHasDnSibHasUpRtIndentHasLwrRtIndent = ({
  currentElement,
  elementListItem,
  hasUpperRightElementOneIndent,
  hasLowerRightElementOneIndent,
  chapterListLevels
}) => {
  //      XXXX
  //           XXXX
  //      0000
  //           XXXX
  //      XXXX
  //0. Get the targetLevel
  //1. Save off any children on the targetLevel (hasLowerRightElementOneIndent) in order to merge them with the level that this elementListItem will be joining.
  //2. Save off any chlidren not on the targetLevel
  //3. Append this elementListItem to the parentElement of hasUpperRightElementOneIndent
  //      The gap will be filled automatically when we move the elementListItem to the new location, so we don't need to create anything new for that.)
  //4. Decrement the tab once for the nonTarget children (which would have moved with the elementListItem)
  //5. Append the targetLevel children to parentElement of hasUpperRightElementOneIndent
  //Created: 24 Oct 2024
  if (debugOutline) debugger
  const listType = getListType(elementListItem)
  //0. Get the targetLevel
  let targetLevel = getListItemLevelDepth(listType, elementListItem, chapterListLevels)
  targetLevel++
  //1. Save off any children on the targetLevel (hasLowerRightElementOneIndent) in order to merge them (later) with the level that this elementListItem will be joining.
  //2. Save off any chlidren not on the targetLevel
  let nonTargetLevelChildren = []
  let targetLevelChildren = []
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'UL' || elementListItem.children[i].nodeName === 'OL') {
      if (getListItemLevelDepth(listType, elementListItem.children[i], chapterListLevels) === targetLevel) {
        targetLevelChildren.push(elementListItem.children[i])
      } else {
        nonTargetLevelChildren.push(elementListItem.children[i])
      }
    }
  }
  //3. Append this elementListItem to the parentElement of hasUpperRightElementOneIndent
  //      The gap will be filled automatically when we move the elementListItem to the new location, so we don't need to create anything new for that.)
  hasUpperRightElementOneIndent.parentElement.append(elementListItem)
  increaseMarginLeft(-1, elementListItem, chapterListLevels)
  //4. Decrement the tab once for the nonTarget children (which would have moved with the elementListItem)
  for (let i = 0; i < nonTargetLevelChildren.length; i++) {
    setList_LevelStyles({ list: nonTargetLevelChildren[i], chapterListLevels, modifyLevel: -1, listType }) //This list needs to be adjusted before the listItem is adjusted.
    for (let c = 0; c < nonTargetLevelChildren[i].children.length; c++) {
      setListItem_LevelStyles({ listItem: nonTargetLevelChildren[i].children[c], chapterListLevels, modifyLevel: -1, listType })
    }
  }
  //5. Append the targetLevel children to parentElement of hasUpperRightElementOneIndent
  //    Be aware that the children are going to be OL or UL so we will need to take the listItems out and then delete the OL or UL
  for (let i = 0; i < targetLevelChildren.length; i++) {
    for (let c = 0; c < targetLevelChildren[i].children?.length;) { //We don't bother counting because every time an element is moved elsewhere, the count of the children goes down by one by itself.
      targetLevelChildren[i].children[0].setAttribute('style', elementListItem.style.cssText)
      hasUpperRightElementOneIndent.parentElement.append(targetLevelChildren[i].children[0]) //Notice that it is zero here as the index because every time an element is moved from the location, it is one less in length.
    }
    if (targetLevelChildren[i].children.length === 0) targetLevelChildren[i].remove()
  }
}


const rightNoChHasUpSibHasDnSibHasUpRtIndent = ({
  currentElement,
  elementListItem,
  chapterListLevels,
  hasUpperRightElementOneIndent
}) => {
  //      XXXX
  //           XXXX
  //      0000
  //      XXXX
  if (debugOutline) debugger
  let targetListItem = hasUpperRightElementOneIndent.nodeName === 'SPAN' ? hasUpperRightElementOneIndent.parentElement : hasUpperRightElementOneIndent
  let targetList = targetListItem.parentElement
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId(elementListItem.id)
  newListItem.setAttribute('style', targetListItem.style.cssText)
  appendListItemSpans(newListItem, elementListItem)
  targetList.append(newListItem)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}

const addListLevelItem = (topListElementId, chapterId, level, element, listLevels) => {
  listLevels.push({
    chapterListLevelId: 0,
    //chapterId,
    topListElementId: Number(topListElementId),
    listType: element.nodeName,
    level,
    listStyle: element.style['list-style'] ? element.style['list-style'] : '',
    listStyleType: element.style['list-style-type'] ? element.style['list-style-type'] : '',
    marginLeft: element.style['margin-left'] ? element.style['margin-left'] : '',
    paddingLeft: element.style['padding-left'] ? element.style['padding-left'] : '',
    fontFamily: element.style['font-family'] ? element.style['font-family'] : '',
    numberFormat: element.style['number-format'] ? element.style['number-format'] : '',
    margin: element.style['margin'] ? element.style['margin'] : '',
    styleInline: element.style.cssText,
  })
  return listLevels
}

const mostFrequentStyle = (array) => {
  if (array.length === 0)
    return null;
  let modeMap = {};
  let maxEl = array[0], maxCount = 1;
  for (let i = 0; i < array.length; i++) {
    let el = array[i];
    if (modeMap[el] == null)
      modeMap[el] = 1;
    else
      modeMap[el]++;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
}

const setLevelCharacter = (list, chapterListLevels) => {
  //We need to dip down to the listItem in order to see how many tab values the marginLeft entry has in order to determine this level.
  if (list) {
    const listType = getListType(list)
    let listItem = list && list.firstChild
    let targetLevel = getListItemLevelDepth(listType, listItem, chapterListLevels)
    let listItemTabs //= list && list.firstChild ? getTabMarginCount(list.firstChild) : ''
    targetLevel = listItemTabs && listItemTabs > 1 ? targetLevel + listItemTabs - 1 : targetLevel
    let listLevel = chapterListLevels.filter(m => m.level === targetLevel && m.listType === list.nodeName && m.listGroup === list.nodeName && m.listStyleType)[0]
    if (listLevel) list.style['list-style-type'] = listLevel.listStyleType
  }
}


const setLevelStyle = (listGroup, elementList, chapterListLevels, adjustLevel) => {
  const listType = getListType(elementList)
  let targetLevel = getListItemLevelDepth(listType, elementList, chapterListLevels)
  targetLevel = adjustLevel ? targetLevel + adjustLevel : targetLevel
  let listLevel = chapterListLevels.filter(m => m.level === targetLevel && m.listType === elementList.nodeName && m.listGroup === listGroup && m.styleInline)[0]
  if (listLevel) elementList.setAttribute('style', listLevel.styleInline)
}

export const setLevelStyles = (elementToSet, chapterListLevels, targetLevelElement, levelChange, directTargetLevel, addMarginLeftOption) => {
  //1. get the target level by the targetLevelElement location
  //    a. Check to see if the intended level is greater than the greatest level of chapterListLevels. If so, start the list over again by getting the remainder of the modulus of the two numbers
  //2. Find the chapterListLevel by the elementType and the target level
  //3. Set the style from chapterListLevels to the elementToSet

  //If there is a levelChange value, then we'll use the targetLevelElement's level (which is not yet the target level in this case, yet) and then add or subtract accordingly (because levelChange can be negative to go left)
  const listType = getListType(targetLevelElement)
  let targetLevel = directTargetLevel ? directTargetLevel : levelChange ? getListItemLevelDepth(listType, targetLevelElement, chapterListLevels) + levelChange : getListItemLevelDepth(listType, targetLevelElement, chapterListLevels)
  let listLevelByType = chapterListLevels.filter(m => m.listType === elementToSet.nodeName)
  let highestLevel = listLevelByType.reduce((acc = 0, m) => {
    return m.level > acc ? m.level : acc
  }, 0)
  if (targetLevel > highestLevel) targetLevel = targetLevel % highestLevel
  if (targetLevelElement) {
    let listGroup = targetLevelElement.nodeName === 'UL' || targetLevelElement.nodeName === 'OL' ? targetLevelElement.nodeName : targetLevelElement.parentElement ? targetLevelElement.parentElement.nodeName : 'LI'  //Using 'LI' here is a blatant throwing in the towel. Probably not smart, but it may never need to be used.
    let listLevel = chapterListLevels.filter(m => m.level === targetLevel && m.listType === elementToSet.nodeName && m.listGroup === listGroup)[0]
    if (listLevel) {
      elementToSet.setAttribute('style', listLevel.styleInline)
      if (listLevel.listStyle) elementToSet.style['list-style'] = listLevel.listStyle
      if (listLevel.listStyleType) elementToSet.style['list-style-type'] = listLevel.listStyleType
      if (listLevel.numberFormat) elementToSet.style['number-format'] = listLevel.numberFormat
      if (addMarginLeftOption) increaseMarginLeft(1, elementToSet, chapterListLevels)
    }
  }
}

const appendCurrentElementAndSiblings = (currentElement, newListItem) => {
  let listItem = currentElement.parentElement
  let siblings = getLowerSiblings(listItem)
  let elementListItem = currentElement.parentElement
  appendListItemSpans(newListItem, elementListItem)
  for (let i = 0; i < siblings.length; i++) {
    newListItem.append(siblings[i])
  }
}

const appendListItemSpans = (newListItem, moveFromListItem) => {
  let endOfSpansAndImages = false
  let moveChildren = moveFromListItem.children
  let loop = 0
  for (let i = 0; i < moveChildren.length; !endOfSpansAndImages && loop < 100) {
    loop++
    if (moveChildren[0].nodeName !== 'SPAN' && moveChildren[0].nodeName !== 'IMG') endOfSpansAndImages = true  //Notice that we use 0 for the index since the children are decreasing by one as they are being taken from the top to move to the new list Item
    if (!endOfSpansAndImages) {
      newListItem.append(moveChildren[0])
    } else {
      break
    }
  }
}

const hasListItemElements = (listItem) => {
  if (listItem && listItem.children) {
    for (let i = 0; i < listItem.children.length; i++) {
      let innerHTML = listItem.children[i].nodeName === 'SPAN' ? listItem.children[i].innerHTML.replace('&nbsp;', '').replace(' ', '') : ''
      if (listItem.children[i].nodeName === 'SPAN' && innerHTML.length > 0 && listItem.children[i].id) return true
      else if (listItem.children[i].nodeName === 'IMG') return true
      else if (listItem.children[i].nodeName === 'OL' || listItem.children[i].nodeName === 'UL') return true
    }
  }
}

export const mergeSameLevelAfterDelete = (deletedListItemLevel, orphanList, orphanListItem, orphanSpan, listItemPreviousSibling, listItemParent, listItem, chapterListLevels) => {
  //=> Merge the same-level list structures after a list structure in between has been deleted
  //Get the level of the list item being deleted.  (firstSpanLevel)
  //Get the level of the element below the deleted list item (we will call the orphan).
  //Get the children of the orphanSpan
  //Get the siblings of the orphanList
  //If the two levels are the same,
  //  there is nothing to do.
  //Otherwise,
  //  Get the previous sibling of the list item being deleted
  //  Get the next sibling(s) of the list item being deleted
  //  If no previous sibling and there are next sibling(s),
  //    Get the grandparent (the parent of listItemParent)
  //    Create a new OL or UL (matching the current OL or UL) from listItemParent
  //    Move the siblings into the new OL or UL and assign it to the listItemGrandParent
  //  else 
  //    Get the last child of the OL or UL list
  //    continue to loop until the last child of the last child is found.
  //    Get the level of the last child
  //    If the level is the same as the orphan element
  //      Move the list item of the orphan at the end of that list of the last child.
  //    Else if the level of the last child is less than the orphan
  //      Delete the spans of the listItem
  //      Set the listItem's list-style-type to none
  //    Else if the level of the last child is greater than the orphan
  //      leave the orphan alone in its list.
  //    End if
  //    If the orphanList is now empty, delete it. Otherwise, clear the list style type.
  //  End if
  //  Note: If the if or else are not the case here, just leave the list item as it is without doing anything else (which might be a problem later if the user wants to add another list item underneath this orphan item)
  //End if
  const listType = getListType(orphanListItem)
  let siblings = getLowerSiblings(orphanListItem)
  let orphanSpanLevel = getListItemLevelDepth(listType, orphanSpan, chapterListLevels)
  let listSiblings = getLowerSiblings(listItem)

  if (!listItemPreviousSibling && listSiblings && listSiblings.length > 0) {
    const listItemGrandparent = listItemParent.parentElement
    let newList = document.createElement(listItemParent.nodeName)
    newList.id = getNextId(listItemParent.id || listItem.id)
    newList.setAttribute('style', listSiblings[0].parentElement.style.cssText)
    const length = listSiblings.length
    for (let i = 0; i <= length; i++) {
      newList.append(listSiblings[0])
    }
    listItemGrandparent.append(newList);
    setList_LevelStyles({ list: newList, chapterListLevels, modifyLevel: -1, listType })
  } else {
    if (orphanSpanLevel !== deletedListItemLevel) {
      let parent = listItemPreviousSibling
      if (!parent) {
        parent = listItemParent
      }
      let loop = 0
      let lastChild = parent.lastChild
      while (lastChild && !(lastChild && lastChild.nodeName === 'LI') && loop < 20) {
        lastChild = lastChild.lastChild
        loop++
      }
      let lastChildLevel = getListItemLevelDepth(listType, lastChild, chapterListLevels)
      if (lastChildLevel === orphanSpanLevel) {
        //    Move the list item of the orphan at the end of that list of the last child.
        let lastChildParent = lastChild && lastChild.parentElement
        if (lastChildParent) {
          lastChildParent.append(orphanListItem)
          for (let i = 0; i < siblings.length; i++) {
            lastChildParent.append(siblings[i])
          }
        }
        if (orphanList) {
          if (orphanList.children.length === 0) {
            orphanList.remove()
          } else {
            orphanList.style['list-style-type'] = 'none'
          }
        }
        if (listItem.children.length === 0) {
          listItem.remove()
        } else {
          listItem.style['list-style-type'] = 'none'
        }
      } else if (lastChildLevel < orphanSpanLevel) {
        //    Delete the spans of the listItem
        //    Set the listItem's list-style-type to none
        for (let i = 0; i < orphanListItem.children.length; i++) {
          orphanListItem.children[i].remove()
        }
        //orphanListItem.style['list-style-type'] = 'none'
      }
    }
  }
}

export const authorDeleteListItem = (element, chapterListLevels) => {
  const listType = getListType(element)
  let img = document.querySelector(`[id="${element.id}"][data-type="DELETELISTITEM"]`)
  if (img) img.remove()
  img = document.querySelector(`[id="${element.id}~tabView${suffixId(element)}"][data-type="DELETELISTITEM"]`)
  if (img) img.remove()

  let firstSpan = document.querySelector(`[id="${element.id}"][data-type="TEXT"]`)
  let listItem = firstSpan.parentElement
  let deletedListItemLevel = getListItemLevelDepth(listType, listItem, chapterListLevels)
  let listItemPreviousSibling = listItem.previousSibling
  let listItemParent = listItem.parentElement
  let list = listItem.parentElement

  let children = listItem.children
  for (let i = 0; i < children.length; i++) {
    if (children[i].nodeName !== 'LI' && children[i].nodeName !== 'OL' && children[i].nodeName !== 'UL') {
      children[i].remove()
    }
  }
  let orphanList, orphanListItem, orphanSpan
  let foundFirstId = false
  for (let i = 0; i < listItem.children.length; i++) {
    if (!foundFirstId) {
      if (listItem.children[i].id && (listItem.children[i].nodeName === 'OL' || listItem.children[i].nodeName === 'UL')) {
        orphanList = listItem.children[i]
        foundFirstId = true
      } else if (listItem.children[i].id && listItem.children[i].nodeName === 'IL') {
        orphanListItem = listItem.children[i]
        foundFirstId = true
      }
    }
  }
  if (orphanList) {
    orphanListItem = orphanList.firstChild
  }
  if (orphanListItem) {
    let foundSpan = false
    for (let i = 0; i < orphanListItem.children.length; i++) {
      if (!foundSpan && orphanListItem.children[i].nodeName === 'SPAN' && orphanListItem.children[i].id) {
        orphanSpan = orphanListItem.children[i]
        foundSpan = true
      }
    }
  }

  //If there are any children left over, set the li list-style to 'none'
  //Else delete the LI record itself (otherwise it needs to be preserved to keep children in their place)
  if (listItem.children.length === 0) {
    listItem.remove()
  } else {
    listItem.style['list-style-type'] = 'none'
  }
  //And the list.
  if (list.children.length === 0) {
    list.remove()
  }
  //Also delete the tabView listItem and list, if they are empty.
  let firstSpanTabView = document.querySelector(`[id="${element.id}~tabView${suffixId(element)}"][data-type="TEXT"]`)
  if (firstSpanTabView) {
    let listItemTabView = firstSpanTabView.parentElement
    let listTabView = listItemTabView.parentElement
    let childrenTabView = listItemTabView.children
    for (let i = 0; i < childrenTabView.length; i++) {
      if (childrenTabView[i].nodeName !== 'LI' && childrenTabView[i].nodeName !== 'OL' && childrenTabView[i].nodeName !== 'UL') {
        childrenTabView[i].remove()
      }
    }

    if (listItemTabView.children.length === 0) {
      listItemTabView.remove()
    } else {
      listItemTabView.style['list-style-type'] = 'none'
    }
    //And the list.
    if (listTabView.children.length === 0) {
      listTabView.remove()
    }
    mergeSameLevelAfterDelete(deletedListItemLevel, orphanList, orphanListItem, orphanSpan, listItemPreviousSibling, listItemParent, listItem, chapterListLevels)
  }
}

export const getListType = (element) => {
  //If this is an OL list and any OL has a className like 'awlist', it is ATTORNEYLIST
  //else if it is a OL and the first list-style-type is 'upper-alpha', it is ALPHABET
  //else if there are lists set to list-style-type = 'none', it is NONE
  //else if it is OL, it is NUMBERED
  //else it is BULLET
  if (element && element.nodeName) {
    const baseList = getBaseList(element)
    let baseListItem = element
    if (element.nodeName === 'SPAN') baseListItem = element.parentElement
    if (element.nodeName === 'OL' || element.nodeName === 'UL') baseListItem = element.firstChild
    if (baseList && baseListItem) {
      //If this is an OL list and any OL has a className like 'awlist', it is ATTORNEYLIST
      const hasAttorneyList = baseList.querySelectorAll('ol[class*="awlist"]')
      if (baseList.nodeName === 'OL' && hasAttorneyList?.length > 0) {
        return 'ATTORNEYLIST'
        //else if it is a OL and the first list-style-type is 'upper-alph', it is ALPHABET
      } else if (baseList.nodeName === 'OL' && baseListItem.style.listStyleType === 'upper-alpha') {
        return 'ALPHABET'
        //else if there are lists set to list-style-type = 'none', it is NONE
      } else if (isListSetToNone(element)) {
        return 'NONE'
        //else if it is OL, it is NUMBERED
      } else if (baseList.nodeName === 'OL') {
        return 'NUMBERED'
        //else it is BULLET
      } else {
        return 'BULLET'
      }
    }
  }
}

const isListSetToNone = (element) => {
  const baseList = getBaseList(element)
  const lists = baseList.querySelectorAll('ul, ol');
  let isSetToNone
  lists.forEach((list) => {
    const computedStyle = window.getComputedStyle(list);
    if (computedStyle.listStyleType === 'none') isSetToNone = true
  })
  return isSetToNone
}

const setList_LevelStyles = ({ list, chapterListLevels, modifyLevel, listType, forceLevel }) => {  //modifyLevel can be negative or positive
  if (list && list.firstChild) {
    listType = listType || getListType(list)
    if (listType) setBaseList(list, listType)
    let listLevel = getListItemLevelDepth(listType, list.firstChild, chapterListLevels) //This needs to be a listItem
    listLevel = Number(listLevel) + Number(modifyLevel)
    if (forceLevel) listLevel = forceLevel
    const listStyles = chapterListLevels.filter(m => m.listType === listType && m.level === listLevel && (m.element === 'UL' || m.element === 'OL'))[0]
    if (listStyles) {
      if (listStyles.listStyleType) list.style['list-style-type'] = listStyles.listStyleType
      if (listStyles.className) list.className = listStyles.className
      if (listStyles.marginLeft) list.style['margin-left'] = listStyles.marginLeft
      if (listStyles.marginRight) list.style['margin-right'] = listStyles.marginRight
      if (listStyles.margin) list.style['margin'] = listStyles.margin
      if (listStyles.textIndent) list.style['text-indent'] = listStyles.textIndent
      if (listStyles.paddingLeft) list.style['padding-left'] = listStyles.paddingLeft
      if (listStyles.fontFamily) list.style['font-family'] = listStyles.fontFamily
      if (listStyles.listStylePosition) list.style['list-style-position'] = listStyles.listStylePosition
    }
  }
}

const setListItem_LevelStyles = ({ listItem, chapterListLevels, modifyLevel, listType, forceTab, forceLevel }) => {  //modifyLevel can be negative or positive
  listType = listType || getListType(listItem)
  if (listType) setBaseList(listItem, listType)
  let listItemLevel = getListItemLevelDepth(listType, listItem, chapterListLevels)
  listItemLevel = Number(listItemLevel) + Number(modifyLevel)
  if (forceLevel) listItemLevel = forceLevel
  const listItemStyles = chapterListLevels.filter(m => m.listType === listType && m.level === listItemLevel && m.element === 'LI')[0]
  if (listItemStyles) {
    if (listItemStyles.listStyleType) listItem.style['list-style-type'] = listItemStyles.listStyleType
    if (listItemStyles.className) listItem.className = listItemStyles.className
    //if (listItemStyles.marginLeft) { //The marginLeft is a different animal. It will change according to the modifyLevel in order to preserve the current marginLeft.
    if (listType === 'ATTORNEYLIST') {
      const averageLeft = getAverageMarginIncrement(listType, chapterListLevels)
      let tabs = getListItemTabs(listType, listItem, chapterListLevels)
      tabs = forceTab ? forceTab : tabs + modifyLevel - 1
      const theMarginLeft = listItem.style.marginLeft ? parseFloat(listItem.style.marginLeft.match(/-?[\d.]+/)[0]) : 0
      const theTextIndent = listItem.style.textIndent ? parseFloat(listItem.style.textIndent.match(/-?[\d.]+/)[0]) : 0
      let addMargin
      if (forceTab) {
        addMargin = (tabs * averageLeft)
      } else {
        addMargin = (tabs * averageLeft) + theTextIndent
      }
      listItem.style['margin-left'] = `${addMargin}pt`
    } else {
      //Take the current marginLeft of the element itself.
      //Subtract or add according to the modifyLevel of the listItemStyles.
      const currentMarginLeft = listItem.style.marginLeft ? parseFloat(listItem.style.marginLeft.match(/-?[\d.]+/)[0]) : 0
      const chapterLevelMarginLeft = listItemStyles.marginLeft ? parseFloat(listItemStyles.marginLeft.match(/-?[\d.]+/)[0]) : 0
      const resultMarginLeft = forceTab ? (forceTab * chapterLevelMarginLeft) : currentMarginLeft + (modifyLevel * chapterLevelMarginLeft)
      listItem.style['margin-left'] = `${resultMarginLeft}pt`
    }
    if (listItemStyles.marginRight) listItem.style['margin-right'] = listItemStyles.marginRight
    if (listItemStyles.margin) listItem.style['margin'] = listItemStyles.margin
    if (listItemStyles.textIndent) listItem.style['text-indent'] = listItemStyles.textIndent
    if (listItemStyles.paddingLeft) listItem.style['padding-left'] = listItemStyles.paddingLeft
    if (listItemStyles.fontFamily) listItem.style['font-family'] = listItemStyles.fontFamily
    if (listItemStyles.listStylePosition) listItem.style['list-style-position'] = listItemStyles.listStylePosition
  }
}

const getBaseList = (element) => {
  let baseList
  if (element) {
    const firstElementId = getFirstElementIdFromElementId(element.id)
    let parentElement = element.parentElement
    if (parentElement?.id == firstElementId) return element
    let loop = 0
    while (parentElement && parentElement?.id != firstElementId && loop < 20) {
      baseList = parentElement //This holds the previous list setting while we check in the loop to see if the parentElement has reached elementId "1", which is the main Div of the penspring document.
      parentElement = parentElement.parentElement
      loop++
    }
    return baseList
  }
}

const setBaseList = (element, listType) => {
  const baseList = getBaseList(element)
  if (baseList) baseList.setAttribute('data-list-type', listType)
}

export const getListItemLevelDepth = (listType, listItem, chapterListLevels) => {
  //Ensure that the listItem is a listItem
  if (listItem) {
    const firstElementId = getFirstElementIdFromElementId(listItem.id)
    if (listItem?.nodeName === 'SPAN') listItem = listItem.parentElement
    if (listItem?.nodeName === 'OL' || listItem.nodeName === 'UL') listItem = listItem.firstChild
    let level = 0
    let loop = 0
    let foundMainDiv
    while (!foundMainDiv && listItem && loop < 20) {
      //if (listItem.tagName === 'UL' || listItem.tagName === 'OL') {
      if (listItem.tagName === 'LI') {
        //There might be an OL/UL followed immediatley by a an OL/UL so be sure to count that as a level. 
        //if (listItem.firstChild.tagName !== 'OL' && listItem.firstChild.tagName !== 'UL') level++  //If there is an OL/UL followed immediately by another OL/UL without a LI, don't count it as a level
        level++  //If there is an OL/UL followed immediately by another OL/UL without a LI, don't count it as a level
        const tabs = getListItemTabs(listType, listItem, chapterListLevels)
        if (tabs > 0) level += tabs - 1 //Because naturally we need a tab for a tab which is already counted in the UL or OL level above
      }
      listItem = listItem.parentElement
      if (listItem?.id == firstElementId) foundMainDiv = true
      loop++
    }

    return level
  }
}

const getListItemTabs = (listType, listItem, chapterListLevels) => {
  const averageMargin = getAverageMarginIncrement(listType, chapterListLevels)
  const marginLeft = listItem.style.marginLeft ? parseFloat(listItem.style.marginLeft.match(/-?[\d.]+/)[0]) : 0
  const textIndent = listItem.style.textIndent ? parseFloat(listItem.style.textIndent.match(/-?[\d.]+/)[0]) : 0
  return averageMargin > 0
    ? listType === 'ATTORNEYLIST'
      ? Math.ceil((marginLeft - (textIndent ? averageMargin : 0)) / averageMargin) //If there is some value in textIndent, just replace one indent - it's just the nature of that lengthy list-character of 1.1.1.1.1. that gets unruly.
      : Math.round((marginLeft + textIndent) / averageMargin)
    : 0
}

const getAverageMarginIncrement = (listType, chapterListLevels) => {
  const marginLeftValues = chapterListLevels.filter(m => m.listType === listType && m.element === 'LI').map(m => parseFloat(m.marginLeft.match(/-?[\d.]+/)[0]))
  const sum = marginLeftValues.reduce((acc, val) => acc + val, 0);
  return Math.round(sum / (marginLeftValues.length || 1) * 100) / 100
}

export const getPrefixChapterId = (chapterId, returnAsFirstId, digitCount = 5) => {
  if (chapterId) {
    let numbers = chapterId.replace('0','').toString().match(/\d+/g).join('')  //Skip 0's in the numbers since a result leading with a 0 will need to be converted to a number which will lose leading zeroes.
    let prefixString = numbers.slice(0, digitCount)
    return returnAsFirstId ? parseInt(prefixString + '0001', 10) : parseInt(prefixString, 10)
  }
}

export const getEditorDivId = (chapterId) => {
  return `editorDiv${getPrefixChapterId(chapterId)}`
}

export const setIdByDivDisplayId = (divDisplayId, chapterId) => {
  if (divDisplayId.indexOf(`tabView`) > -1) {
    return `tabView${getPrefixChapterId(chapterId)}`
  } else {
    return getEditorDivId(chapterId)
  }
}

export const getEditorDivIdFromElementId = (elementId) => {
  //1. Get the first four digits (to leave room for elementId expansion into that fifth digit)
  //2. Find the editorDivId which matches the first four digits
  if (elementId) {
    //let numbers = elementId.toString().match(/\d+/g).join('')
    let prefixString = elementId.toString().slice(0, 4)
    let editorDiv = document.querySelector(`div[id^="editorDiv${prefixString}"]`)
    if (editorDiv) return editorDiv.id
  }
}

export const getFirstElementIdFromElementId = (elementId) => {
  const editorDivId = getEditorDivIdFromElementId(elementId)
  if (editorDivId) {
    const prefix = editorDivId.substring(editorDivId.length-5)
    return Number(String(prefix) + String('0001'))
  }
}

export const getNextId = (elementId, chapterId, incomingEditorDivId) => {
  let editorDivId
  if (incomingEditorDivId) {
    editorDivId = incomingEditorDivId
  } else if (chapterId) {
    editorDivId = getEditorDivId(chapterId)
  } else if (elementId) {
    editorDivId = getEditorDivIdFromElementId(elementId)
  }
  if (editorDivId) {
    const editorDiv = document.getElementById(editorDivId)
    if (editorDiv) {
      const spans = editorDiv.querySelectorAll('span[id]')
      let maxId = 0

      spans.forEach(span => {
        const numericId = parseInt(span.id, 10)
        if (!isNaN(numericId) && numericId > maxId) {
          maxId = numericId
        }
      })
      return ++maxId
    }
  }
}

export const suffixId = (elementId) => {
  //1. Get the first four digits (to leave room for elementId expansion into that fifth digit)
  //2. Find the editorDivId which matches the first four digits
  if (elementId instanceof HTMLElement) {
    elementId = elementId.id;
  }
  if (elementId) {
    let numbers = elementId.toString().match(/\d+/g).join('')
    let prefixString = numbers.slice(0, 5)
    return prefixString
  }
}

