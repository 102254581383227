import React from 'react'
import styles from './CurrentSentenceEditsPanelLauncher.module.css'

function CurrentSentenceEditsPanelLauncher(props) {
	const {
    setIsOpenSlideOut,
    sentenceEditCount,
	} = props

	return (
    <div className={styles.sentenceEdits} onClick={() => setIsOpenSlideOut(true)}>
      <div className={styles.smallText}>CURRENT</div>
      <div className={styles.smallText}>SENTENCE</div>
      <div className={styles.row}>
        <div className={styles.smallText}>EDITS</div>
        <div className={styles.editCount}>{sentenceEditCount}</div>
      </div>
    </div>
  )
}

export default CurrentSentenceEditsPanelLauncher