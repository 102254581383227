import React, { useState, useEffect } from 'react'
import { createConfirmToastAuto } from '../../../services/queryClient.js'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from "react-responsive"
import { useNavigate } from 'react-router'
import AssessmentEssay from '../../../components/Assessment/AssessmentEssay'
import AssessmentFillBlank from '../../../components/Assessment/AssessmentFillBlank'
import AssessmentMatching from '../../../components/Assessment/AssessmentMatching'
import AssessmentMultipleAnswer from '../../../components/Assessment/AssessmentMultipleAnswer'
import AssessmentMultipleChoice from '../../../components/Assessment/AssessmentMultipleChoice'
import AssessmentPassage from '../../../components/Assessment/AssessmentPassage'
import AssessmentTrueFalse from '../../../components/Assessment/AssessmentTrueFalse'
import BreadCrumb from '../../../components/BreadCrumb'
import ButtonWithIcon from '../../../components/ButtonWithIcon'
import classes from 'classnames'
import globalStyles from '../../../utils/globalStyles.module.css'
import MediaLinks from '../../../components/Media/MediaLinks'
import MediaListViewer from '../../../components/Media/MediaListViewer'
import OneFJefFooter from '../../../components/OneFJefFooter'
import ReactDOMServer from 'react-dom/server';  // Make sure to import ReactDOMServer
import styles from './AssessmentLearnerView.module.css'
import TextDisplay from '../../../components/TextDisplay'

function AssessmentLearnerView(props) {
  const {
    addOrUpdateAssessmentAnswer,
    assessment,
    assessmentId,
    assessmentQuestions,
    getAssessmentQuestions,
    assignmentId,
    correctAssessment,
    createWorkInAssessment,
    userPersonId,
    listLevelGeneral,
    updateAssessmentLocalAnswer,
  } = props

  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  
  const [breadCrumb, setBreadCrumb] = useState()
  const [currentIndex, setCurrentIndex] = useState()
  const [isInit, setIsInit] = useState()

  useEffect(() => {
    localStorage.setItem('preEditReviewPage', location.pathname)
  }, [])

  useEffect(() => {
    if (assessment?.assessmentId && assessment?.oneAtAtimeView && !isInit && assessmentQuestions?.length > 0) {
      setIsInit(true)
      setCurrentIndex(1)
      showOneAtATimeView(1)
      handlSetBreadCrumb()
    }
  }, [assessment, assessmentQuestions])

  const processForm = () => {
    //Check to see if all of the answers are complete.  If not, send a message that will explain which ones are not answered yet.
    let notAnswered = []
    assessmentQuestions?.length > 0 && assessmentQuestions.forEach(m => {
      if (!m.learnerAnswer && m.questionTypeCode !== 'PASSAGE') {
        let incomplete = m.sequence + ' - ' + (m.questionText.length > 35 ? m.questionText.substring(0, 35) + '...' : m.questionText)
        notAnswered = notAnswered ? notAnswered.concat(incomplete) : [incomplete]
      }
    })

    if (notAnswered && notAnswered.length > 0) {

      const noAnswerText = notAnswered && notAnswered.length > -1 && notAnswered.map((m, i) =>
        <div key={i} className={styles.textBold}>{m}</div>
      )
      const noAnswerHTML = ReactDOMServer.renderToStaticMarkup(<>{noAnswerText}</>);

      createConfirmToastAuto(`
        <div>
          <div>The following questions are not yet answered:</div>
          ${noAnswerHTML}
          <div>Do you want to submit the incomplete answers anyway?</div>
        </div>`, handleCorrectAssessment)
    } else {
      createConfirmToastAuto(`<div>Are you ready to submit this assessment?</div>`, handleCorrectAssessment)
    }
  }

  const handleCorrectAssessment = () => {
    let runFunction = () => navigate(`/assessmentCorrect/${assignmentId}/${assessmentId}/${userPersonId}`)
    correctAssessment(userPersonId, userPersonId, assessmentId, assignmentId, runFunction) //In this case, the userPersonId and studentPersonId are the same.
  }

  const showOneAtATimeView = (incomingIndex) => {
    hideAllViews()
    let nextIndex = incomingIndex ? incomingIndex : currentIndex
    if (document.getElementById(`question${nextIndex}`)) {
      document.getElementById(`question${nextIndex}`).style.display = 'inline'
      document.getElementById(`question${nextIndex}`).style.visibility = 'visible'
    }
  }

  const hideAllViews = () => {
    assessmentQuestions?.length > 1 && assessmentQuestions.forEach((m, i) => {
      if (document.getElementById(`question${i + 1 * 1}`)) {
        document.getElementById(`question${i + 1 * 1}`).style.display = 'none'
        document.getElementById(`question${i + 1 * 1}`).style.visibility = 'hidden'
      }
    })
  }

  const setNextIndexUpOrDown = (upOrDown) => {
    let nextIndex = currentIndex + upOrDown * 1
    setCurrentIndex(nextIndex)
    showOneAtATimeView(nextIndex)
    handlSetBreadCrumb()
  }

  const setNextIndexJump = (index) => {
    setCurrentIndex(index)
    showOneAtATimeView(index)
    handlSetBreadCrumb(index)
  }

  const handlSetBreadCrumb = (incomingIndex) => {
    let breadCrumb = []
    assessmentQuestions?.length > 0 && assessmentQuestions.forEach((m, i) => {
      let index = i + 1 * 1
      let option = {
        id: index,
        label: index,
        required: true,
        isAnswered: m.learnerAnswer || m.questionTypeCode === 'PASSAGE',
      }
      breadCrumb = breadCrumb && breadCrumb.length > 0 ? breadCrumb.concat(option) : [option]
    })
    setBreadCrumb(breadCrumb)
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Assessment
        </div>
        <div className={classes(styles.moreTop, styles.rowWrap)}>
          <TextDisplay label='Assessment' text={assessment && assessment.name} bigger/>
        </div>
        <hr />
        {assessmentQuestions?.length > 0 && assessmentQuestions.map((m, i) =>
          <div id={`question${i + 1 * 1}`} key={i + 1 * 1}>
            {assessment.oneAtAtimeView &&
              <div>
                <div className={styles.row}>
                  <div className={styles.breadCrumbLabel}>'questions:'</div>
                  <BreadCrumb options={breadCrumb} currentIndex={currentIndex} onClick={setNextIndexJump} />
                </div>
                <hr />
              </div>
            }
            {m.questionTypeCode === 'TRUEFALSE' &&
              <div key={i}>
                <AssessmentTrueFalse 
                  assignmentId={assignmentId}
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isTeacher={false}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onClick={addOrUpdateAssessmentAnswer} 
                  question={m} 
                  userPersonId={userPersonId} />

                <MediaLinks
                  audioLabel={'File for'}
                  audioTitle={'Assessment Answers'}
                  runFunction={() => getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBase={{
                    recordType: `AssessmentLearnerAnswer${userPersonId}`,
                    recordId: m.assessmentQuestionId,
                    section: 'question',
                    indexNbr: 0,
                  }}
                  userPersonId={userPersonId}
                  {...props} />
                <MediaListViewer
                  recordType={`AssessmentLearnerAnswer${userPersonId}`}
                  recordId={m.assessmentQuestionId}
                  section={'question'}
                  index={0}
                  userPersonId={userPersonId}
                  runFunction={() => props.getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBinaries={m?.mediaBinaries} />
              </div>
            }
            {m.questionTypeCode === 'MULTIPLECHOICE' &&
              <div key={i}>
                <AssessmentMultipleChoice 
                  assignmentId={assignmentId}
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isTeacher={false}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onClick={addOrUpdateAssessmentAnswer} 
                  question={m}
                  userPersonId={userPersonId} />

                <MediaLinks
                  audioLabel={'File for'}
                  audioTitle={'Assessment Answers'}
                  runFunction={() => getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBase={{
                    recordType: `AssessmentLearnerAnswer${userPersonId}`,
                    recordId: m.assessmentQuestionId,
                    section: 'question',
                    indexNbr: 0,
                  }}
                  userPersonId={userPersonId}
                  {...props} />
                <MediaListViewer
                  recordType={`AssessmentLearnerAnswer${userPersonId}`}
                  recordId={m.assessmentQuestionId}
                  section={'question'}
                  index={0}
                  userPersonId={userPersonId}
                  runFunction={() => props.getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBinaries={m?.mediaBinaries} />
              </div>
            }
            {m.questionTypeCode === 'MULTIPLEANSWER' &&
              <div key={i}>
                <AssessmentMultipleAnswer 
                  assignmentId={assignmentId}
                  answers={m.answers} 
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isTeacher={false}
                  learnerAnswer={m.learnerAnswer} 
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onClick={addOrUpdateAssessmentAnswer} 
                  question={m}
                  userPersonId={userPersonId} />

                <MediaLinks
                  audioLabel={'File for'}
                  audioTitle={'Assessment Answers'}
                  runFunction={() => getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBase={{
                    recordType: `AssessmentLearnerAnswer${userPersonId}`,
                    recordId: m.assessmentQuestionId,
                    section: 'question',
                    indexNbr: 0,
                  }}
                  userPersonId={userPersonId}
                  {...props} />
                <MediaListViewer
                  recordType={`AssessmentLearnerAnswer${userPersonId}`}
                  recordId={m.assessmentQuestionId}
                  section={'question'}
                  index={0}
                  userPersonId={userPersonId}
                  runFunction={() => props.getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBinaries={m?.mediaBinaries} />
              </div>
            }
            {m.questionTypeCode === 'ESSAY' &&
              <div key={i}>
                <AssessmentEssay 
                  assessmentQuestionId={m.assessmentQuestionId}
                  assessmentTitle={assessment && assessment.name}
                  bigTextDisplay={assessment.bigTextDisplay}
                  createWorkInAssessment={createWorkInAssessment}
                  isTeacher={false}
                  keywords={m.keywordPhrases}
                  listLevelGeneral={listLevelGeneral}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onChange={addOrUpdateAssessmentAnswer}
                  question={m}
                  userPersonId={userPersonId} />

                <MediaLinks
                  audioLabel={'File for'}
                  audioTitle={'Assessment Answers'}
                  runFunction={() => getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBase={{
                    recordType: `AssessmentLearnerAnswer${userPersonId}`,
                    recordId: m.assessmentQuestionId,
                    section: 'question',
                    indexNbr: 0,
                  }}
                  userPersonId={userPersonId}
                  {...props} />
                <MediaListViewer
                  recordType={`AssessmentLearnerAnswer${userPersonId}`}
                  recordId={m.assessmentQuestionId}
                  section={'question'}
                  index={0}
                  userPersonId={userPersonId}
                  runFunction={() => props.getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBinaries={m?.mediaBinaries} />
              </div>
            }
            {m.questionTypeCode === 'PASSAGE' &&
              <div key={i}>
                <AssessmentPassage 
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isTeacher={false}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  question={m} 
                  userPersonId={userPersonId} />

              </div>
            }
            {m.questionTypeCode === 'FILLBLANK' &&
              <div key={i}>
                <AssessmentFillBlank 
                  assignmentId={assignmentId}
                  answerVariations={m.answerVariations}
                  assessmentTitle={assessment && assessment.name} 
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isTeacher={false}
                  learnerAnswer={m.learnerAnswer}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onClick={addOrUpdateAssessmentAnswer} 
                  question={m} 
                  updateAssessmentLocalAnswer={updateAssessmentLocalAnswer} />

                <MediaLinks
                  audioLabel={'File for'}
                  audioTitle={'Assessment Answers'}
                  runFunction={() => getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBase={{
                    recordType: `AssessmentLearnerAnswer${userPersonId}`,
                    recordId: m.assessmentQuestionId,
                    section: 'question',
                    indexNbr: 0,
                  }}
                  userPersonId={userPersonId}
                  {...props} />
                <MediaListViewer
                  recordType={`AssessmentLearnerAnswer${userPersonId}`}
                  recordId={m.assessmentQuestionId}
                  section={'question'}
                  index={0}
                  userPersonId={userPersonId}
                  runFunction={() => props.getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBinaries={m?.mediaBinaries} />
              </div>
            }
            {m.questionTypeCode === 'MATCHING' &&
              <div key={i}>
                <AssessmentMatching 
                  assignmentId={assignmentId}
                  addOrUpdateAssessmentAnswer={addOrUpdateAssessmentAnswer} 
                  answers={m?.answers}
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isTeacher={false}
                  learnerAnswer={m.learnerAnswer}
                  matches={m?.matches}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onClick={addOrUpdateAssessmentAnswer} 
                  question={m}
                  userPersonId={userPersonId}
                  viewMode={'StudentView'} />

                <MediaLinks
                  audioLabel={'File for'}
                  audioTitle={'Assessment Answers'}
                  runFunction={() => getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBase={{
                    recordType: `AssessmentLearnerAnswer${userPersonId}`,
                    recordId: m.assessmentQuestionId,
                    section: 'question',
                    indexNbr: 0,
                  }}
                  userPersonId={userPersonId}
                  {...props} />
                <MediaListViewer
                  recordType={`AssessmentLearnerAnswer${userPersonId}`}
                  recordId={m.assessmentQuestionId}
                  section={'question'}
                  index={0}
                  userPersonId={userPersonId}
                  runFunction={() => props.getAssessmentQuestions(props.userPersonId, m.assessmentId)}
                  mediaBinaries={m?.mediaBinaries} />
              </div>
            }
            {assessment.oneAtAtimeView &&
              <div className={styles.buttonPair}>
                {currentIndex !== 1 &&
                  <a className={styles.prevButton} onClick={() => setNextIndexUpOrDown(-1)}>{`< Previous`}</a>
                }
                {currentIndex !== assessmentQuestions.length &&
                  <ButtonWithIcon label={'Next >'} icon={'checkmark_circle'} onClick={() => setNextIndexUpOrDown(1)} />
                }
                {currentIndex === assessmentQuestions.length &&
                  <div className={styles.rowRight}>
                    <ButtonWithIcon label={'Finish'} icon={'checkmark_circle'} onClick={processForm} />
                  </div>
                }
              </div>
            }
          </div>
        )}
        {assessmentQuestions && assessmentQuestions.length > 0 && !assessment.oneAtAtimeView &&
          <div className={styles.rowRight}>
            <ButtonWithIcon label={'Finish'} icon={'checkmark_circle'} onClick={processForm} />
          </div>
        }
      </div>
      <OneFJefFooter />
    </div>
  )
}


export default AssessmentLearnerView