import React, { useState, useRef } from 'react'
import styles from './TableAddModal.module.css'
import ButtonWithIcon from '../ButtonWithIcon'
import InputText from '../InputText'
import { useMediaQuery } from "react-responsive"
import { createInfoToastAuto } from '../../services/queryClient'
import classes from 'classnames'
import * as editorService from '../../services/editor-dom'
import * as editorTableStructure from '../../services/editor-table-structure'

const TableAddModal = (props) => {
	const { 
    createNewTable,
		onCancel,
		onClose, 
    editorDivId,
    openTableAddModal,
	} = props

	const containerRef = useRef(null);

	const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [rows, setRows] = useState(2)
  const [columns, setColumns] = useState(2)
  

	const processForm = (event) => {
		event.stopPropagation()
		event.preventDefault()
		let missingFields = ''

    if (!rows) missingFields += "\nHow many rows do you want?"
		if (!columns) missingFields += "\nHow many columns do you want?"

		if (missingFields) {
			createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`)
		} else {
			setSubmitSuccess(true)
      createNewTable(rows, columns)
			setSubmitSuccess(false)
			onClose()
		}
	}

  const chooseRowsColumns = (event) => {
    const rowColumnTable = document.getElementById('rowColumnTable')
    const cell = event.target;
    if (cell.tagName === 'TD') {
      const row = cell.parentElement;
      const rowIndex = [...row.parentElement.children].indexOf(row);
      const cellIndex = [...row.children].indexOf(cell);

      // Reset all cells
      resetTableColors(rowColumnTable);

      // Color upper rows
      for (let i = 0; i <= rowIndex; i++) {
        const upperRow = rowColumnTable.rows[i];
        for (let j = 0; j <= cellIndex; j++) {
          upperRow.cells[j].style.backgroundColor = 'lightblue';
        }
      }
    }
  }

  const resetTableColors = (rowColumnTable) => {
    for (let row of rowColumnTable.rows) {
      for (let cell of row.cells) {
        cell.style.backgroundColor = '';
      }
    }
  }

  const resetBackground = () => {
    const rowColumnTable = document.getElementById('rowColumnTable')
    resetTableColors(rowColumnTable)
  }

  const chooseTableDrag = (event) => {
    const cell = event.target;
    if (cell.tagName === 'TD') {
      const row = cell.parentElement;
      setRows([...row.parentElement.children].indexOf(row) + 1); // +1 for 1-based index
      setColumns([...row.children].indexOf(cell) + 1); // +1 for 1-based index
    }
  }

	return (
    <div className={classes(styles.overlay, openTableAddModal === editorDivId ? styles.show : styles.hide)}>
			<div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
				<div className={styles.background} ref={containerRef}>
					<div className={styles.titleWhite}>
						Add a New Table
					</div>
          <div className={styles.row}>
            <InputText
              id={`rowCount`}
              name={`rowCount`}
              size={"super-short"}
              whiteText
              label={'Rows'}
              value={rows || ''}
              onChange={(event) => setRows(event.target.value)} />
            <InputText
              id={`columnCount`}
              name={`columnCount`}
              size={"super-short"}
              whiteText
              label={'Columns'}
              value={columns || ''}
              onChange={(event) => setColumns(event.target.value)} />
          </div>
          <div className={styles.instructions}>Enter the rows and columns above<br/> or drag over the table below and click on the size that you want.</div>
          <table id={'rowColumnTable'} onMouseOver={chooseRowsColumns} onMouseOut={resetBackground} onClick={chooseTableDrag}>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
          </table>

					<div className={styles.buttonsCenter}>
						<div className={styles.buttonPosition}>
							<span className={styles.cancelButton} onClick={onCancel}>
								Cancel
							</span>
							<ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={submitSuccess} />
						</div>
					</div>					
				</div>
			</div>
		</div>	
	)
}

export default TableAddModal
