import React, {useEffect} from 'react';
import styles from './TabPage.module.css';
import classes from 'classnames';
import EditListChoice from '../EditListChoice'
import {useMediaQuery} from "react-responsive";

function TabPage(props) {
	//If this is a new document, then include the Save button for the author, but if the author leaves the page with or without
	//  saving, then save the chapterText and process it for hrefId-s anyway.
	const {
		chosenTab,
		className,
    isRightSide,
		keyIndex,
		navClose,
		navText,
		onClick,
		showListAfterQuantity,
		showZeroCount,
		tabsData,
    viewSide,
    workSummaries,
	} = props;

  const isLargerLaptop = useMediaQuery({ query: '(min-width: 1600px)' })
  const isMediumLaptop = useMediaQuery({ query: '(min-width: 1400px) and (max-width: 1599px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 1050px) and (max-width: 1399px)' })
  const isSmallTablet = useMediaQuery({ query: '(min-width: 750px) and (max-width: 1049px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 749px)' }) 

  const getOptions = () => {
    let newOptions = []
    tabsData.filter(m => (isTablet || isSmallTablet || isMobile) || workSummaries?.length > 1 || (!(isTablet || isSmallTablet || isMobile) && m.id !== tabsData[0].id)).forEach((m, i) => { //When it is mobile, we want all editors and the author to be in the drop down list. Otherwize cut out the first Tab person out for the tabView tabs
      if ((isTablet || isSmallTablet || isMobile) || i >= showListAfterQuantity-1) {
        let option = {
          id: m.id,
          label: m.label + ' (' + m.count + ')'
        }
        newOptions.push(option)
      }
    })
    return newOptions
  }

	const getChoiceLabel = () => {
		if (chosenTab && chosenTab !== '0') {
			const options = getOptions(tabsData)
			let choiceOption = options.filter(m => m.id === chosenTab)[0]
			return choiceOption ? choiceOption.label.length > 13 ? choiceOption.label.substring(0, 13) + '...' : choiceOption.label : ''
		}
	}

	//If the showListAfterQuantity is greater than the tabsData count plus 1, then show the author in a tab and the rest in a single select list.
	return (
		<div className={styles.container} key={keyIndex}>
      {tabsData && !(isTablet || isSmallTablet || isMobile) && tabsData.length > 0 && (workSummaries?.length <= 1 || isRightSide) &&
				<div className={classes(styles.tabs, styles.row, className)}>
					{tabsData.map((tab, i) => {
            if (showListAfterQuantity > i) {
              return (
                <div key={i} className={styles.columns}>
                  {tab.isAuthor ? <span className={styles.aboveTab}>AUTHOR</span> :
                    <span className={styles.aboveTab}>&nbsp;</span>}
                  <a onClick={() => onClick(tab.id, viewSide)}
                     className={classes(styles.row, styles.tabLink, (tab.id === chosenTab ? styles.chosen : styles.notChosen)) //eslint-disable-line
                     }>
                    {tab.editorColor && <div className={styles.colorBox} style={{backgroundColor: tab.editorColor}}/>}
                    {tab.label}
                    {(showZeroCount || tab.count) &&
                      <span className={styles.editCount}>
                                    {tab.count
                                      ? tab.count
                                        ? tab.count
                                        : (showZeroCount ? 0 : '')
                                      : showZeroCount
                                        ? 0
                                        : ''
                                    }
                                    </span>
								    }
                  </a>
                </div>
					  )}}
          )}
					{navClose && <span className={styles.closeText} onClick={navClose}>{navText}</span>}
				</div>
			}
      {tabsData && (isTablet || isSmallTablet || isMobile || workSummaries?.length > 1 || (showListAfterQuantity && showListAfterQuantity < tabsData.length - 1)) && !isRightSide &&
        <select onChange={(event) => onClick(event.target.value)} title={'Author and editors'} className={styles.selectList} key={'Writers'}>
          <option value="">writers</option>
          <option value={props.personId}>[clear]</option>
          {getOptions(tabsData)?.length > 0 && getOptions(tabsData).map(m => 
            <option value={m.id}>{m.label}</option>
          )}
        </select>
			}
			{props.setEditChosen &&
				<div className={styles.editList}>
					<EditListChoice 
            editChosen={props.editChosen}
            editOptions={props.editOptions} 
            setEditChosen={props.setEditChosen} />
				</div>
			}
		</div>
	)
}

export default TabPage;