import * as types from '../actions/actionTypes'
//import { doSort } from '../utils/sort.js'

//It doesn't look like this is used any more. The getWorkEditReview has multiple workSummaries now.  See reducer work-edit-review

export default function (state = [], action) {
  switch (action.type) {
    case types.WORK_SEGMENTS_INIT:
      return [...action.payload]

    default:
      return state
  }
}

export const selectWorkSegments = (state) => state
