import React, { useRef } from 'react'
import styles from './AudioRecordingModal.module.css'
import RecordButtonsSilver from '../RecordButtonsSilver'
import classes from 'classnames'
import { useMediaQuery } from "react-responsive"

function AudioRecordingModal(props) {
  const { 
    //blobUrl, 
    isOpen,
    label,
    recorder,
    setIsOpen,
    startRecording, 
    stopRecording, 
    title,
  } = props
  
  const containerRef = useRef(null)
  const audioControl = useRef(null)
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          {/* <audio controls={"controls"} src={blobUrl} type={"audio/mpeg"} ref={audioControl} style={{visibility: 'hidden'}}>
            This browser does not support this audio control
          </audio> */}
          <RecordButtonsSilver 
            audioControl={audioControl}
            label={label}
            recorder={recorder}
            setIsOpen={setIsOpen}
            startRecording={startRecording}
            stopRecording={stopRecording}
            title={title} />
        </div>
      </div>
    </div>
  )
}

export default AudioRecordingModal