import React, { useEffect } from 'react'
import AssessmentAiGeneratedView from '../views/Assessment/AssessmentAiGeneratedView'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionAssessment from '../actions/assessment.js'
import * as actionQuestionTypes from '../actions/question-types.js'
import {
  selectMe,
  selectAssessment,
  selectQuestionTypes,
} from '../store.js'

// takes values from the redux store and maps them to props
const mapStateToProps = (state) => {
  let me = selectMe(state)
  let assessment = selectAssessment(state)

  return {
    assessment,
    langCode: me.langCode,
    userPersonId: me.personId,
    questionTypes: selectQuestionTypes(state),
  }
}

const bindActionsToDispatch = dispatch => ({
  getAssessment: (personId, assessmentId) => dispatch(actionAssessment.getAssessment(personId, assessmentId)),
  getQuestionTypes: (personId) => dispatch(actionQuestionTypes.init(personId)),
  generateAiQuizAssessmentQuestions: (assessmentId, quizContent, setup) => dispatch(actionAssessmentQuestions.generateAiQuizAssessmentQuestions(assessmentId, quizContent, setup)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const {
    assignmentId,
    getAssessment,
    getQuestionTypes,
    userPersonId,
  } = props

  const params = useParams()

  useEffect(() => {
    if (userPersonId, params.assessmentId) {
      getAssessment(userPersonId, params.assessmentId)
      getQuestionTypes(userPersonId)
    }
  }, [userPersonId, params.assessmentId, assignmentId])

  return <AssessmentAiGeneratedView {...props} assessmentId={params.assessmentId} />
}

export default storeConnector(Container)
