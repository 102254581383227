import React from 'react'
import styles from './AssessmentPassage.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import MediaListViewer from '../../Media/MediaListViewer'
import classes from 'classnames'
import QuestionLabel from '../../QuestionLabel'

function AssessmentPassage(props) {
  const {
    bigTextDisplay,
    className = "",
    isTeacher,
    mediaBinaries,
    question,
    userPersonId
  } = props

  return (
    <div className={classes(className, styles.container)}>
      <QuestionLabel label={'Passage'}/>
      <div className={classes(styles.row, styles.questionLine)}>
        <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question.sequence}.
        </div>
        <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{question.questionText}</div>
      </div>
      <MediaListViewer
        recordType={'AssessmentQuestion'}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      {isTeacher &&
        <div className={styles.answerLeft}>
          <span className={styles.text}>
            This is intended to be a reading or example of a problem which will be followed by two or more questions.
          </span><br/>
        </div>
      }
    </div>
  )
}

export default AssessmentPassage