import React, { useEffect } from 'react'
import AssessmentCorrectSameAllView from '../views/Assessment/AssessmentCorrectSameAllView'
import { connect } from 'react-redux'
import { doSort } from '../utils/sort.js'
import * as actionAssessmentCorrect from '../actions/assessment-correct'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionAssessment from '../actions/assessment.js'
import * as actionWorks from '../actions/works.js'
//import * as actionMyVisitedPages from '../actions/my-visited-pages.js'
import { useParams } from 'react-router-dom'
import {
  selectMe,
  selectAssessmentQuestions,
  selectAssessment,
  selectAssessmentCorrectSameAllStudents,
  selectListLevelGeneral,
} from '../store.js'

const mapStateToProps = (state) => {
  let me = selectMe(state)
  let students = [] //selectStudents(state)
  let assessmentCorrectSameAllStudents = selectAssessmentCorrectSameAllStudents(state)
  students = assessmentCorrectSameAllStudents && assessmentCorrectSameAllStudents.studentsCompleted && assessmentCorrectSameAllStudents.studentsCompleted.length > 0
    ? students.length > 0 && students.filter(m => {
      let foundStudent = false
      assessmentCorrectSameAllStudents.studentsCompleted && assessmentCorrectSameAllStudents.studentsCompleted.length > 0 && assessmentCorrectSameAllStudents.studentsCompleted.forEach(s => {
        if (m.id === s.id) foundStudent = true
      })
      return foundStudent
    })
    : []
  let assessment = selectAssessment(state)
  let assessmentQuestions = selectAssessmentQuestions(state)
  assessmentQuestions = assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.map(m =>
  ({
    ...m,
    id: m.assessmentQuestionId,
    label: m.sequence + '. - ' + m.questionText,
    assessmentQuestionId: m.assessmentQuestionId,
    questionText: m.questionText,
  })
  )
  assessmentQuestions = doSort(assessmentQuestions, { sortField: 'sequence', isAsc: true, isNumber: true })

  let correctionTypeFilter = [{
    label: 'All',
    id: 'all',
  },
  {
    label: 'Pending essays',
    id: 'pendingEssays',
  },
  {
    label: 'Wrong answers',
    id: 'wrongAnswers',
  },
  {
    label: 'Correct answer',
    id: 'correctAnswers',
  },
  ]

  return {
    assessment,
    assessmentCorrectSameAllStudents: assessmentCorrectSameAllStudents && assessmentCorrectSameAllStudents.details,
    assessmentQuestions,
    correctionTypeFilter,
    isAuthor: assessment.personId === me.personId,
    langCode: me.langCode,
    learnerFullName: me.fname + ' ' + me.lname,
    listLevelGeneral: selectListLevelGeneral(state),
    personId: me.personId,
    students,
  }
}

const bindActionsToDispatch = dispatch => ({
  getAssessment: (personId, assessmentId) => dispatch(actionAssessment.getAssessment(personId, assessmentId)),
  getAssessmentQuestions: (studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentQuestions.getAssessmentQuestions(studentPersonId, assessmentId, assignmentId)),
  getCorrectedAssessment: (personId, studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentCorrect.getCorrectedAssessment(personId, studentPersonId, assessmentId, assignmentId)),
  retakeTest: (personId, assignmentId, assessmentId, runFunction) => dispatch(actionAssessmentCorrect.retakeTest(personId, assignmentId, assessmentId, runFunction)),
  teacherEssayResponse: (personId, teacherResponse) => dispatch(actionAssessmentCorrect.teacherEssayResponse(personId, teacherResponse)),
  getSameCorrectedAssessmentAllStudents: (personId, assessmentId, assessmentQuestionId) => dispatch(actionAssessmentCorrect.getSameCorrectedAssessmentAllStudents(personId, assessmentId, assessmentQuestionId)),
  updateTeacherAssessmentLearnerAnswer: (personId, studentPersonId, assessmentQuestionId, score, assignmentId) => dispatch(actionAssessmentQuestions.updateTeacherAssessmentLearnerAnswer(personId, studentPersonId, assessmentQuestionId, score, assignmentId)),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
  //    setMyVisitedPage: (personId, myVisitedPage) => dispatch(actionMyVisitedPages.setMyVisitedPage(personId, myVisitedPage)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const params = useParams()
  const {
    assessmentId,
    assessmentQuestionId,
    getAssessmentQuestions,
    assignmentId,
    getAssessment,
    getListLevelGeneral,
    getSameCorrectedAssessmentAllStudents,
    personId,
    studentPersonId,
  } = props

  useEffect(() => {
    getAssessment(personId, assessmentId)
    getSameCorrectedAssessmentAllStudents(personId, assessmentId, assessmentQuestionId)
    getAssessmentQuestions(studentPersonId, assessmentId, assignmentId)
    getListLevelGeneral()
    //props.location && props.location.pathname && setMyVisitedPage(personId, { path: this.props.location.pathname, description: `Correct Same Assessment Question for All Students` })
  }, [personId, assessmentId, assessmentQuestionId, studentPersonId])

  return <AssessmentCorrectSameAllView {...props}
    assessmentQuestionId={params.assessmentQuestionId || ''}
    assignmentId={params.assignmentId}
    assessmentId={params.assessmentId} />

}

export default storeConnector(Container)
