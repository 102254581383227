import React, { useState, useEffect } from 'react';
import styles from './ToggleBoardMobile.module.css'
import { createInfoToastAuto } from '../../services/queryClient'

function ToggleBoardMobile(props) {
	const { 
		chapterId,
    clearAllEditTypesAndIcons, 
		currentElement, 
		editTrackChanges, 
		goToNextSentence, 
		isTranslation, 
		keepCommentOn,
		keyIndex,
		moveTranslationToEditor, 
		personId,
    scrollTogether,
		segments,
		setAddList, 
		setAddListItem, 
		setAddParagraphBreak, 
		setAddParagraphSentence, 
		setAddSentence, 
		setChangeList,
		setChangeStyle, 
		setDeleteListItem, 
		setDeleteParagraphBreak, 
		setDeleteSentence, 
		setEditTrackChanges, 
		setGoToNextSentence, 
		setKeepCommentOn, 
		setMoveSentences, 
		setMoveTranslationToEditor, 
		setReorderListItems, 
		showEditIcons,
		setShowEditIcons,

    setScrollTogether,

    setAddTable,
    setAddTableRow,
    setAddTableColumn,
    setAddTableCell,
    setDeleteTable,
    setDeleteTableRow,
    setDeleteTableColumn,
    setDeleteTableCell,
    setTableSettings,
    setAddImage,
    setDeleteImage,
    setImageSettings,

	} = props

  const [choice, setChoice] = useState()
	const [label, setLabel] = useState()
	const [isInList, setIsInList] = useState()
  const [options, setOptions] = useState()
  const [listOptions, setListOptions] = useState()
  const [tableOptions, setTableOptions] = useState()
  const [imageOptions, setImageOptions] = useState()
  const [switchOptions, setSwitchOptions] = useState()
  const [hasListStructure, setHasListStructure] = useState()
  const [hasTableStructure, setHasTableStructure] = useState()
  
	const [isAuthor, setIsAuthor] = useState()

	useEffect(() => {
		if (chapterId && segments?.length > 0) {
			const listItems = segments.filter(m => m.type === 'LI' && m.chapterId === chapterId)
			setHasListStructure(listItems?.length > 0)
      const tableItems = segments.filter(m => m.type === 'TABLE' && m.chapterId === chapterId)
      setHasTableStructure(tableItems?.length > 0)
			const newIsAuthor = segments.filter(m => m.workOwners?.length > 0 && m.workOwners.indexOf(personId) > -1 && m.chapterId === chapterId)
			setIsAuthor(newIsAuthor?.length > 0)
		} else {
			setIsAuthor(false)
		}
	}, [chapterId, segments])

	useEffect(() => {
		let newOptions = []
    newOptions.push({ id: '', label: '[clear]', text: '[clear]' })
		if (!isTranslation && !isAuthor) newOptions.push({id: 'ADDPARAGRAPH', label: 'Add paragraph break', text: 'Add paragraph break'})
		if (!isTranslation && !isAuthor) newOptions.push({id: 'ADDPARAGRAPHSENTENCE', label: 'Add paragraph and sentence', text: 'Add paragraph and sentence'})
		if (!isTranslation && !isAuthor) newOptions.push({id: 'ADDSENTENCE', label: 'Add sentence', text: 'Add sentence'})
		if (!isTranslation) newOptions.push({id: 'CHANGESTYLE', label: 'Change style', text: 'Change style'})
		if (!isTranslation && !isAuthor) newOptions.push({id: 'DELETEPARAGRAPH', label: 'Delete paragraph break', text: 'Delete paragraph break'})
		if (!isTranslation) newOptions.push({id: 'DELETESENTENCE', label: 'Delete sentence', text: 'Delete sentence'})
		if (!isTranslation) newOptions.push({id: 'MOVESENTENCE', label: 'Move one or more sentences', text: 'Move one or more sentences'})
		
		setOptions(newOptions)

    let newListOptions = []
    if (!isTranslation) newListOptions.push({ id: 'ADDLIST', label: 'Add list', text: 'Add list' })
    if (!isTranslation && hasListStructure) newListOptions.push({ id: 'ADDLISTITEM', label: 'Add list item', text: 'Add list item' })
    if (!isTranslation && hasListStructure) newListOptions.push({ id: 'DELETELISTITEM', label: 'Delete list item', text: 'Delete list item' })
    if (!isTranslation) newListOptions.push({ id: 'CHANGELIST', label: 'Change list type', text: ' disabled={!isInList}>Change list type' }) // && isInList
    if (hasListStructure) newListOptions.push({ id: 'REORDERLISTITEMS', label: 'Reorder a list level', text: 'Reorder a list level' })
    setListOptions(newListOptions)

    let newTableOptions = []
    if (!isTranslation) newTableOptions.push({ id: 'ADDTABLE', label: 'Add new table', text: 'Add new table' })
    if (!isTranslation && hasTableStructure) newTableOptions.push({ id: 'ADDTABLEROW', label: 'Add new row', text: 'Add new row' })
    if (!isTranslation && hasTableStructure) newTableOptions.push({ id: 'ADDTABLECOLUMN', label: 'Add new column', text: 'Add new column' })
    if (!isTranslation && hasTableStructure) newTableOptions.push({ id: 'ADDTABLECELL', label: 'Add new cell', text: 'Add new cell' })
    if (!isTranslation && hasTableStructure) newTableOptions.push({ id: 'DELETETABLE', label: 'Delete table', text: 'Delete table' })
    if (!isTranslation && hasTableStructure) newTableOptions.push({ id: 'DELETETABLEROW', label: 'Delete row', text: 'Delete row' })
    if (!isTranslation && hasTableStructure) newTableOptions.push({ id: 'DELETETABLECOLUMN', label: 'Delete column', text: 'Delete column' })
    if (!isTranslation && hasTableStructure) newTableOptions.push({ id: 'DELETETABLECELL', label: 'Delete cell', text: 'Delete cell' })
    if (!isTranslation && hasTableStructure) newTableOptions.push({ id: 'TABLESETTINGS', label: 'Table settings', text: 'Table settings' })
    setTableOptions(newTableOptions)

    let newImageOptions = []
    if (!isTranslation) newImageOptions.push({ id: 'ADDIMAGE', label: 'Add image', text: 'Add image' })
    if (!isTranslation) newImageOptions.push({ id: 'DELETEIMAGE', label: 'Delete image', text: 'Delete image' })
    if (!isTranslation) newImageOptions.push({ id: 'IMAGESETTINGS', label: 'Image settings', text: 'Image settings' })
    setImageOptions(newImageOptions)

    let newSwitchOptions = []
    newSwitchOptions.push({ id: 'KEEPCOMMENTON', label: `${keepCommentOn ? 'ON - ' : 'OFF - '} Keep Comment On` })
    newSwitchOptions.push({ id: 'SHOWTRACKCHANGES', label: `${editTrackChanges ? 'ON - ' : 'OFF - '} Show Track Changes` })
    newSwitchOptions.push({ id: 'SCROLLTOGETHER', label: `${scrollTogether ? 'ON - ' : 'OFF - '} Scroll Views Together` })
    newSwitchOptions.push({ id: 'SHOWEDITICONS', label: `${showEditIcons ? 'ON - ' : 'OFF - '} Show Edit Icons` })
    if (isTranslation) newSwitchOptions.push({ id: 'MOVETRANSLATION', label: `${moveTranslationToEditor ? 'ON - ' : 'OFF - '} Move Translation to Editor` })
    if (isTranslation) newSwitchOptions.push({ id: 'GOTONEXTSENTENCE', label: `${goToNextSentence ? 'ON - ' : 'OFF - '} Go to Next Sentence` })
    setSwitchOptions(newSwitchOptions)

  }, [hasListStructure, isAuthor, isTranslation, keepCommentOn, editTrackChanges, showEditIcons, moveTranslationToEditor, goToNextSentence])

	useEffect(() => {
		if (currentElement && currentElement.nodeName) {
			setIsInList(currentElement.nodeName === 'LI'
				|| currentElement.nodeName === 'OL'
				|| currentElement.nodeName === 'UL'
				|| currentElement?.parentElement?.nodeName === 'LI'
				|| currentElement?.parentElement?.nodeName === 'OL'
				|| currentElement?.parentElement?.nodeName === 'UL')

      if (!hasListStructure) setHasListStructure(currentElement.nodeName === 'LI' || currentElement?.parentElement?.nodeName === 'LI')
		}
  }, [currentElement, keepCommentOn, editTrackChanges, showEditIcons, moveTranslationToEditor, goToNextSentence])

	const showAndRepeatMessage = (choice) => {
		//This is a trick to be able to show the message again in case it went by too fast and the user didn't realize they could click on the 'pause' button to hold the long messages
		//This way they can click on the drop down list option again to repeat the message. Otherwise, the onChange method of the select list will not let the message repeat
		//	because the choice really hasn't "changed". But this function si called by the 'onClick' of the select list.
		if (choice === "MOVESENTENCE") {
			createInfoToastAuto(
				`<div>
					<div>1. Click on the beginning sentence.</div>
					<div>2. Click on the last sentence or click on the icon at the end of the last sentence.</div>
					<div>3. Click on a target where you want to move the sentence(s).</div></div>`)
		} else if (choice === "CHANGESTYLE") {
			createInfoToastAuto(
				`<div>1. Click on the beginning sentence.
							2. Choose the last sentence (or its icon).
							3. A pop-up will give you style options.</div>`)
		}	
	}

	const handleSetChoice = (event) => {
    const choice = event.target.value
    const option = options.filter(m => m.id === choice)[0]

		if (!choice || choice == 0) {
			clearAllEditTypesAndIcons()
      setChoice('')
      setLabel('')
		} else if (choice === "ADDLIST") {
			setAddList(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "CHANGELIST") {
			setChangeList(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "ADDLISTITEM") {
			setAddListItem(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "ADDPARAGRAPH") {
			setAddParagraphBreak(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "ADDPARAGRAPHSENTENCE") {
			setAddParagraphSentence(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "ADDSENTENCE") {
			setAddSentence(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "DELETELISTITEM") {
			setDeleteListItem(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "DELETEPARAGRAPH") {
			setDeleteParagraphBreak(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "DELETESENTENCE") {
			setDeleteSentence(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "REORDERLISTITEMS") {
			setReorderListItems(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
		} else if (choice === "MOVESENTENCE") {
			setMoveSentences(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
      showAndRepeatMessage(choice)
		} else if (choice === "CHANGESTYLE") {
			setChangeStyle(true)
      setChoice('')
			setLabel(option.label.length > 35 ? option.label.substring(0,35) + '...' : option.label)
      showAndRepeatMessage(choice)
    } else if (choice === 'ADDTABLE') {
      setAddTable(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'ADDTABLEROW') {
      setAddTableRow(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'ADDTABLECOLUMN') {
      setAddTableColumn(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'ADDTABLECELL') {
      setAddTableCell(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'DELETETABLE') {
      setDeleteTable(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'DELETETABLEROW') {
      setDeleteTableRow(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'DELETETABLECOLUMN') {
      setDeleteTableColumn(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'DELETETABLECELL') {
      setDeleteTableCell(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'TABLESETTINGS') {
      setTableSettings(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'ADDIMAGE') {
      setAddImage(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'DELETEIMAGE') {
      setDeleteImage(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
    } else if (choice === 'IMAGESETTINGS') {
      setImageSettings(true)
      setChoice('')
      setLabel(option.label.length > 35 ? option.label.substring(0, 35) + '...' : option.label)
		} else if (choice === "KEEPCOMMENTON") {
			setKeepCommentOn(!keepCommentOn)
      setChoice('')
      setLabel('')
		} else if (choice === "SHOWTRACKCHANGES") {
			setEditTrackChanges(!editTrackChanges)
      setChoice('')
      setLabel('')
    } else if (choice === "SCROLLTOGETHER") {
      setScrollTogether(!scrollTogether)
      setChoice('')
      setLabel('')
    // } else if (choice === "EDITORFULLTEXT") {  //This doesn't make sense for mobile since we have to switch between editors when the user wants to see the full text through the 'writers' list.
		// 	setShowEditorFullText(!showEditorFullText)
		} else if (choice === "MOVETRANSLATION") {
			setMoveTranslationToEditor(!moveTranslationToEditor)
      setChoice('')
      setLabel('')
		} else if (choice === "GOTONEXTSENTENCE") {
			setGoToNextSentence(!goToNextSentence)
      setChoice('')
      setLabel('')
		} else if (choice === "SHOWEDITICONS") {
			setShowEditIcons(!showEditIcons)
      setChoice('')
      setLabel('')
		}
	}

	return (
    <div>
      <select onChange={handleSetChoice} title={'Edit options and switches'} className={styles.selectList} key={keyIndex} value={choice}>
        <option value="">options (clear)</option>
        {options?.length > 0 && options.map((m, i) => {
          return !(isTranslation && m.hideIfTranslation) ? <option key={i} value={m.id}>{m.label}</option> : null
        })}
        <optgroup key={'lists'} label={'Lists'}>
          {listOptions?.length > 0 && listOptions.map((m, i) => {
            return !(isTranslation && m.hideIfTranslation) ? <option key={i} value={m.id}>{m.label}</option> : null
          })}
        </optgroup>
        <optgroup key={'tables'} label={'Tables'}>
          {tableOptions?.length > 0 && tableOptions.map((m, i) => {
            return !(isTranslation && m.hideIfTranslation) ? <option key={i} value={m.id}>{m.label}</option> : null
          })}
        </optgroup>
        <optgroup key={'images'} label={'Images'}>
          {imageOptions?.length > 0 && imageOptions.map((m, i) => {
            return !(isTranslation && m.hideIfTranslation) ? <option key={i} value={m.id}>{m.label}</option> : null
          })}
        </optgroup>
        <optgroup key={'switches'} label={'Switches - on or off'}>
          {switchOptions?.length > 0 && switchOptions.map((m, i) => {
            return !(isTranslation && m.hideIfTranslation) ? <option key={i} value={m.id}>{m.label}</option> : null
          })}
        </optgroup>
      </select>
      <div className={styles.choiceLabel}>{label}</div >
    </div>
	)
}

export default ToggleBoardMobile
