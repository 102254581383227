import React from 'react';
import styles from './TextDisplay.module.css';
import classes from 'classnames';

export default ({ text, label, className, labelClassName, nowrap = false, noDisplayIfBlank, bigger, whiteText }) => {
  return noDisplayIfBlank && !text ? null : (
    <div className={classes(styles.container, className, (nowrap ? styles.nowrap : ''), whiteText ? styles.whiteText : '')}>
      <span className={classes(labelClassName ? labelClassName : styles.label, (nowrap ? styles.nowrap : ''))}>{label}</span>
      <span className={classes(bigger ? styles.biggerText : styles.text, (nowrap ? styles.nowrap : ''))}>{text}</span>
    </div>
  )
}
