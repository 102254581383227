import React from 'react'
import styles from './QuestionLabel.module.css'

export default ({label, subLabel}) => {
  return (
    <div>
      <hr className={styles.hrHeight}/>
      <div className={styles.row}>
        <div className={styles.questionType}>{label}</div>
        <div className={styles.subLabel}>{subLabel}</div>
      </div>
    </div>
  )
}
