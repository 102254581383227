import React, {useState, useEffect, useRef} from 'react'
import styles from './CameraPhotoUploadModal.module.css'
import FileUploadPenspring from '../FileUploadPenspring'
import ButtonWithIcon from '../../ButtonWithIcon'
import { useMediaQuery } from "react-responsive"
import { useAppContext } from "../../../../AppContext.js";
import classes from 'classnames'

function CameraPhotoUploadModal(props) {
  const { 
    isOpen,
    setIsOpen,
    submitCameraPhoto,
  } = props

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const { pageVisitor } = useAppContext();

  const [isSmartPhone, setIsSmartPhone] = useState(false)
  const [image, setImage] = useState(null)
  
  const containerRef = useRef(null)
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  const audioRef = useRef(null) // Ref for the audio element

  useEffect(() => {
    setIsSmartPhone(isSmartphone())
  }, [])

  const stopExistingStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject
      const tracks = stream.getTracks()
      tracks.forEach(track => track.stop()) // Stop all tracks
      videoRef.current.srcObject = null // Clear the srcObject
    }
  }

  useEffect(() => {
    if (!image) {
      stopExistingStream()

      const startWebcam = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true })
          if (videoRef.current) {
            videoRef.current.srcObject = stream
            // videoRef.current.onloadedmetadata = () => {
            //   videoRef.current.play() // Play only after the metadata is loaded
            // }          
          }
        } catch (err) {
          console.error("Error accessing the webcam: ", err)
        }
      }      

      startWebcam()
    }
  }, [image])


  const captureImage = () => {
    const video = videoRef.current
    const canvas = canvasRef.current

    if (canvas && video) {
      const context = canvas.getContext("2d")

      // Video aspect ratio and canvas dimensions
      const videoAspectRatio = video.videoWidth / video.videoHeight
      const canvasAspectRatio = canvas.width / canvas.height

      let sx, sy, sWidth, sHeight

      // If the video aspect ratio is wider than the canvas, we crop from the sides
      if (videoAspectRatio > canvasAspectRatio) {
        sHeight = video.videoHeight
        sWidth = canvasAspectRatio * sHeight
        sx = video.videoWidth - sWidth // Crop the right side of the video
        sy = 0
      } else {
        // If the video aspect ratio is taller, crop from top/bottom
        sWidth = video.videoWidth
        sHeight = sWidth / canvasAspectRatio
        sx = 0
        sy = (video.videoHeight - sHeight) / 2 // Align vertically center
      }

      // Draw the cropped portion of the video onto the canvas
      context.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height)

      // Get the image data as a base64 string
      const imageData = canvas.toDataURL("image/png")
      submitCameraPhoto(imageData) // Save the captured image to the calling-parent components
      setImage(imageData) //The local version which is only used to determine if the camera should be stopped

      // Optionally, play a snapshot sound
      if (audioRef.current) {
        audioRef.current.play()
      }
      pageVisitor({ p: 'TetxImageCamera', n: 'Chose to capture the image ' })
      setIsOpen(false)
      setImage(null)
    }
  }

  const handleSetPicture = (event) => {
    const fileLocal = event.target.files[0];
    if (fileLocal) {
      submitCameraPhoto(fileLocal)
      //setTimeout(() => setImagePath(URL.createObjectURL(fileLocal)), 300);
    }
  }

	return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          Camera Photo Upload
          {isSmartPhone && 
            <form method="post" encType="multipart/form-data">
              <FileUploadPenspring
                captureCameraOnly={'camera'}
                label={'Take a picture from your device'}
                subLabel='Only one file is allowed at a time - bmp, jpg, png, pbm, webp'
                setFile={handleSetPicture}
                fileTypes='image/bmp,image/jpeg,image/png,image/x-portable-bitmap,image/webp' /> 
            </form>
          }
          {!isSmartPhone && 
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {!image && 
                <video
                  ref={videoRef}
                  autoPlay
                  style={{
                    width: "300px", // Set larger width of the video feed
                    height: "388px", // Set larger height of the video feed
                    objectFit: "cover", // This scales and centers the video feed
                    objectPosition: "right center", // Align video to the left
                    border: "1px solid black",
                    overflow: "hidden", // Hide the overflow of the video
                    marginLeft: '50px'
                  }}
                />
              }
              <canvas
                ref={canvasRef}
                style={{ display: "none" }}
                width={612} // Match the video width
                height={792} // Match the video height
              />
              {image && 
                <div>
                  <h2>Captured Image:</h2>
                  <img
                    src={image}
                    alt="Captured"
                    width={300}
                    height={388}
                  />
                </div>
              }
              {!image && <button onClick={() => captureImage()}>Capture</button>}
            </div>
          }
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <ButtonWithIcon
                label={'Close'}
                icon={'checkmark0'}
                onClick={() => setIsOpen(false)}
                submitSuccess={false} />
            </div>
          </div>          
        </div>
        <audio ref={audioRef} src="/snapshot.mp3" />
      </div>
    </div>
	)
}

// Utility function to detect if the user is on a smartphone
const isSmartphone = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return true
  }

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return true
  }

  return false // Assume desktop if not mobile
}


export default CameraPhotoUploadModal