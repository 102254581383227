import MoveTarget from '../assets/Inline Icons/move-target.svg'
import * as editorService from './editor-dom'

let globalAddTableSequence

export const setDeleteImageAndIcon = (personId, chosenTab, divDisplayId, edits, tabsData, editorName, showEditIcons, suffixId) => {
  //1. For the author, put the edit icon in front of the table to be deleted.
  //2. For the editor, 
  //3.    put the icon in front of the nextSibling.
  //4.    Delete the table
  let addTableEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'DELETEIMAGE')
  addTableEdits?.length > 0 && addTableEdits.forEach(edit => {
    const editPerson = divDisplayId.indexOf('tabView') > -1 ? edit.personId === chosenTab : edit.personId === personId
    const imageElementId = divDisplayId.indexOf('tabView') > -1 ? edit.elementId + `~tabView${suffixId}` : edit.elementId
    const image = document.querySelector(`img[id="${imageElementId}"]`)
    if (image) {
      const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      const currentEditorName = edit.firstName + ' ' + edit.lastName
      const editorColor = editorService.getEditorColor(edit.personId, tabsData, 'withoutSymbol')

      if (showEditIcons) {
        let img = document.createElement('img')
        img.id = imageElementId
        img.src = `/inline/image-delete-${editorColor}.png`
        img.height = 22 //Change of the icon size (which we ought to make dynamic for the user's preference)
        img.style.cursor = 'pointer'
        img.title = currentEditorName
        img.style.marginRight = '3px'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.setAttribute('data-type', edit.type)
        img.setAttribute('data-person-id', edit.personId)
        img.setAttribute('data-edit-segment-id', edit.editSegmentId)

        if (image.id.indexOf('tabView') > -1) debugger
        if (editPerson) {
          if (image.nextSibling) {
            image.parentElement.insertBefore(img, image.nextSibling)
          } else {
            image.parentElement.append(img)
          }
          image.remove()
        } else {
          image.parentElement.insertBefore(img, image)
        }
      }
    }
  })
}

