import React, { createContext, useContext } from "react";
import axios from 'axios'
import { apiHost } from './app/api_host.js'
let debounceTimer

// Create the context
const AppContext = createContext();

// Create a custom hook for easier access
export const useAppContext = () => {
  return useContext(AppContext);
};

// Define the Provider
export const AppProvider = ({ children }) => {

  const pageVisitor = (visitor) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() =>     
      fetch(`${apiHost}ebi/pageVisit/add`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
          "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
        body: JSON.stringify(visitor),
      })
        .catch((error) => {
          console.error("Error:", error);
        })
      , 200)
  };

  const addMediaBinary = (file, mediaBinary, runFunction=()=>{}) => {
    let fileData = new FormData();

    fileData.append("file", file)
    fileData.append('media', JSON.stringify(mediaBinary));

    axios.post(`${apiHost}ebi/mediaBinary/add`,
      fileData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
          'Content-Type': 'multipart/form-data', 
        }
      })
      .then(() => {
        setTimeout(() => runFunction(), 500)
      })
  };

  const removeMediaBinary = (mediaBinaryId, runFunction = () => { }) => {
    fetch(`${apiHost}ebi/mediaBinary/remove/${mediaBinaryId}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(() => {
        setTimeout(() => runFunction(), 500)
      })
      .catch((error) => {
        console.error("Error:", error);
      })
  };



  return (
    <AppContext.Provider value={{ pageVisitor, addMediaBinary, removeMediaBinary }}>
      {children}
    </AppContext.Provider>
  );
};
