import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles from './GroupSettingsView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import GroupTools from '../../components/GroupTools';
import InputText from '../../components/InputText';
import TextDisplay from '../../components/TextDisplay';
import numberFormat from '../../utils/numberFormat.js';
import OneFJefFooter from '../../components/OneFJefFooter';
import { useAppContext } from "../../../AppContext.js";

function GroupSettingsView(props) {
  const {personId, groupSummary, setGroupCurrentSelected, deleteGroup, updatePersonConfig, personConfig} = props;
  const { pageVisitor } = useAppContext();

  const [isLocalNotEditMode, setIsLocalNotEditMode] = useState(true)
  const [groupName, setGroupName] = useState('')
  const [internalId, setInternalId] = useState('')
  const [description, setDescription] = useState('')
  const [groupNameError, setGroupNameError] = useState('')
  const [errors, setErrors] = useState()

  useEffect(() => {
    const {groupSummary} = props;
    setGroupName(groupSummary.groupName)
    setInternalId(groupSummary.internalId ? groupSummary.internalId : '- -')
    setDescription(groupSummary.description ? groupSummary.description : '- -')
    pageVisitor({ p: 'GroupSettingsView', n: 'Entered group settings view' })
  }, [])

  const handleCancelEdit = () => {
    const {groupSummary} = props;
    setGroupName(groupSummary.groupName)
    setInternalId(groupSummary.internalId ? groupSummary.internalId : '- -')
    setDescription(groupSummary.description ? groupSummary.description : '- -')
    toggleEditMode();
  }

  const toggleEditMode = () => {
    if (isLocalNotEditMode) {
      setIsLocalNotEditMode(false)
      setInternalId( internalId === '- -' ? '' : internalId)
      setDescription(description === '- -' ? '' : description)
    } else {
      setIsLocalNotEditMode(true)
      setInternalId( internalId ? internalId : '- -')
      setDescription(description ? description : '- -')
    }
    pageVisitor({ p: 'GroupSettingsView', n: 'Toggle edit mode' })
  }

  const handleNameChange = (event) => {
    setGroupName(event.target.value)
    pageVisitor({ p: 'GroupSettingsView', n: 'Changed name' })
  }

  const handleInternalIdChange = (event) => {
    setInternalId(event.target.value)
    pageVisitor({ p: 'GroupSettingsView', n: 'Changed internal id' })
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
    pageVisitor({ p: 'GroupSettingsView', n: 'Changed description' })
  }

  const handleSubmit = () => {
    const {updateGroup, groupSummary, personId} = props;
    let isValid = true;
    if (!groupName) {
        setGroupNameError("Please enter a group name" )
        isValid = false;
    }
    isValid && updateGroup(personId, groupSummary.groupId, groupName, internalId, description, "STAY");
    isValid && toggleEditMode();
    pageVisitor({ p: 'GroupSettingsView', n: 'Submited group details' })
  }


    return (
      <div className={styles.container}>
          <div className={globalStyles.pageTitle}>
              Class Settings
          </div>
          <div className={styles.subTitle}>
              <GroupTools personId={personId} summary={groupSummary} currentTool={'groupSettings'} showDelete={true}
                  setGroupCurrentSelected={setGroupCurrentSelected} deleteGroup={deleteGroup}
                  isOwner={personId === groupSummary.ownerPersonId} className={styles.tools}
                  updatePersonConfig={updatePersonConfig} personConfig={personConfig} />
          </div>
          <hr />
          {isLocalNotEditMode &&
              <div>
                  <div className={styles.row}>
                      <TextDisplay label={`Group name`} text={groupName} />
                      <button className={styles.editButton} onClick={toggleEditMode}>Edit</button>
                  </div>
                  <TextDisplay label={`Internal id`} text={internalId} />
                  <TextDisplay label={`Description`} text={description} />
              </div>
          }

          {!isLocalNotEditMode &&
              <ul className={styles.unorderedList}>
                  <div className={styles.row}>
                      <InputText
                          value={groupName}
                          size={"medium-long"}
                          label={"Group name"}
                          name={"groupName"}
                          inputClassName={styles.input}
                          onChange={handleNameChange}
                          error={groupNameError}/>
                      <div className={styles.column}>
                          <button className={styles.editButton} onClick={handleSubmit}>Save</button>
                          <span className={styles.cancelButton} onClick={handleCancelEdit}>Cancel</span>
                      </div>
                  </div>
                  <InputText
                      value={internalId || ''}
                      size={"medium"}
                      label={"Internal Id (optional)"}
                      name={"internalId"}
                      inputClassName={styles.input}
                      onChange={handleInternalIdChange}/>
                  <div className={styles.column}>
                      <span className={styles.label}>Description (optional)</span>
                      <textarea rows={5} cols={42} value={description || ''} id={`description`} className={styles.messageBox}
                          onChange={(event) => handleDescriptionChange(event)}></textarea>
                  </div>
                  <hr />
              </ul>
          }
          <hr />
          <div>
              <Link to={`/workAddNew/${groupSummary && groupSummary.groupId}`} className={styles.addNew}>
                  {groupSummary.groupTypeName === 'FACILITATORLEARNER' ? 'Add new assignment' : 'Add new document'}
                  <span className={styles.count}>{numberFormat(groupSummary.workSummaries.length || 0)}</span>
              </Link>
          </div>
          <hr />
          <div>
              <Link to={`/groupMemberAdd`} className={styles.addNew}>
                  {groupSummary.groupTypeName === 'FACILITATORLEARNER' ? 'Add new learner' : 'Add new member'}
                  <span className={styles.count}>{numberFormat(groupSummary.members.length || 0)}</span>
              </Link>
          </div>
          <hr />
          <OneFJefFooter />
      </div>
  )
}

export default GroupSettingsView;
