import React, { useState } from 'react'
import styles from './MarketingEditForumClub.module.css'
import classes from 'classnames'
import penspringSmall from "../../assets/logos/penspring_reg.png"
import canvasSimpleRed from "../../assets/Canvas/CanvasSimpleRed.png"

function MarketingEditForumClub(props) {
  const { isTablet } = props

  const [isExpandedEditing, setIsExpandedEditing] = useState();
  const [isExpandedCanvas, setIsExpandedCanvas] = useState();
  const [isExpandedForums, setIsExpandedForums] = useState();
  const [isExpandedClubs, setIsExpandedClubs] = useState();
  const [isExpandedEvals, setIsExpandedEvals] = useState();

  return (
    <div className={styles.container}>
      <div className={classes(styles.segoeHeader, isTablet ? styles.column : styles.row, isTablet && `${styles.textContainer} ${isExpandedEditing ? styles.expanded : ""}`)}
          onClick={() => isTablet ? setIsExpandedEditing(!isExpandedEditing) : {}}>
        <div className={isTablet ? styles.spaceAlignMobile : styles.spaceAlign}>Editing - <span className={styles.readMore}>{!isTablet ? '' : isExpandedEditing ? 'read less' : 'read more'}</span></div>
        <div className={styles.segoeTop}>
          because <img className={styles.penspringLogoBigger} src={penspringSmall} alt="penspring" />handles editors like nobody's business!
          <div className={styles.segoeSmall}>&bull; Google Docs and Microsoft Word don't have this.</div>
          <div className={styles.segoeSmall}>&bull; <img className={styles.penspringLogo} src={penspringSmall} alt="penspring" />tracks 22 edit types like reordering lists, indenting lists, moving sentences...</div>
          <div className={styles.segoeSmall}>&bull; And <img className={styles.penspringLogo} src={penspringSmall} alt="penspring" />tracks original-language changes to be revisited by translators.</div>
          <div className={styles.segoeSmall}>&bull; As the author, you can reorder lists and delete sentences with <img className={styles.penspringLogo} src={penspringSmall} alt="penspring" />edit options.</div>
          <div className={styles.segoeSmall}>&bull; Works with PDF, Word, Google Docs, OpenOffice, Pages, OCR images, and your camera.</div>
        </div>
      </div>
      <div className={classes(styles.segoeHeader, isTablet ? styles.column : styles.row, isTablet && `${styles.textContainer} ${isExpandedCanvas ? styles.expanded : ""}`)}
          onClick={() => isTablet ? setIsExpandedCanvas(!isExpandedCanvas): {}}>
        <div className={isTablet ? styles.spaceAlignMobile : styles.spaceAlign}><img className={styles.canvas} src={canvasSimpleRed} alt="canvas" />- <span className={styles.readMore}>{!isTablet ? '' : isExpandedCanvas ? 'read less' : 'read more'}</span></div>
        <div className={styles.segoeTop}>
          because <img className={styles.penspringLogoBigger} src={penspringSmall} alt="penspring" />is an add-on and coordinates scores 
          <div className={styles.segoeSmall}>&bull; Get this! There are student assignment types built on top of <img className={styles.penspringLogo} src={penspringSmall} alt="penspring" />:</div>
          <div className={styles.segoeSmall}>&bull; <span className={styles.gold}>Dynamic quizzes with essays</span>&nbsp;- with the option to create the questions with AI.</div>
          <div className={styles.segoeSmall}>&bull; <span className={styles.gold}>Discussion groups</span>&nbsp;- with surgical editing while keeping author's text intact.</div>
          <div className={classes(styles.segoeSmall, styles.moreIndent)}>(Don't let students write half-truths and throw off the others! Catch it fast.)</div>
          <div className={styles.segoeSmall}>&bull; <span className={styles.gold}>Peer group papers and editing assignments</span>&nbsp;- with edit participation reports!</div>
          <div className={styles.segoeSmall}>&bull; <span className={styles.gold}>AI vs. Creative Writing</span> &nbsp;(which proves the paper was written by the student).</div>
        </div>
      </div>

      <div className={classes(styles.segoeHeader, isTablet ? styles.column : styles.row, isTablet && `${styles.textContainer} ${isExpandedForums ? styles.expanded : ""}`)}
          onClick={() => isTablet ? setIsExpandedForums(!isExpandedForums): {}}>
        <div className={isTablet ? styles.spaceAlignMobile : styles.spaceAlign}>Forums - <span className={styles.readMore}>{!isTablet ? '' : isExpandedForums ? 'read less' : 'read more'}</span></div>
        <div className={styles.segoeTop}>
          because it is words, people, and refining communication.
          <div className={styles.segoeSmall}>&bull; It is the <img className={styles.penspringLogo} src={penspringSmall} alt="penspring" />innovation to allow non-intrusive editing of anyone's entry.</div>
          <div className={styles.segoeSmall}>&bull; The author of the entry can decide to accept or reject any edit before it changes her text.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; A forum is a discussion group which is natural for <img className={styles.penspringLogoSmaller} src={penspringSmall} alt="penspring" />too.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Automated abuse-watchers naturally throttle students' bad entries.</div>
        </div>
      </div>

      <div className={classes(styles.segoeHeader, isTablet ? styles.column : styles.row, isTablet && `${styles.textContainer} ${isExpandedClubs ? styles.expanded : ""}`)}
          onClick={() => isTablet ? setIsExpandedClubs(!isExpandedClubs) : {}}>
        <div className={isTablet ? styles.spaceAlignMobile : styles.spaceAlign}>Clubs - <span className={styles.readMore}>{!isTablet ? '' : isExpandedClubs ? 'read less' : 'read more'}</span></div>
        <div className={styles.segoeTop}>
          because clubs are more than just glorified discussion groups
          <div className={styles.segoeSmall}>&bull; Members can edit others' entries (without overwriting the public version, of course).</div>
          <div className={styles.segoeSmall}>&bull; The author of the entries can accept or reject the suggested edits.</div>
          <div className={styles.segoeSmall}>&bull; The club president can enter club meetings with dates and times.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; School admins approve clubs.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; School senators or admins can approve funding requests.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Automated abuse-watchers naturally throttle students' bad entries.</div>
        </div>
      </div>

      <div className={classes(styles.segoeHeaderSmaller, isTablet ? styles.column : styles.row, isTablet && `${styles.textContainer} ${isExpandedEvals ? styles.expanded : ""}`)}
          onClick={() => isTablet ? setIsExpandedEvals(!isExpandedEvals) : {}}>
        <div>
          <div className={isTablet ? styles.topSmallMobile : styles.topSmall}>STAFF & FACULTY</div>
          <div className={isTablet ? styles.spaceAlignMobile : styles.spaceAlign}>Evaluations - <span className={styles.readMore}>{!isTablet ? '' : isExpandedEvals ? 'read less' : 'read more'}</span></div>
        </div>
        <div className={isTablet ? styles.segoeTopMoreMobile : styles.segoeTopMore}>
          because there is editing to clarify the evaluation details.
          <div className={styles.segoeSmall}>&bull; It's just another function that <img className={styles.penspringLogo} src={penspringSmall} alt="penspring" />enhances for convenience!</div>
          <div className={styles.segoeSmall}>&bull; And who else can cater to both Staff and Faculty (who function differently by nature)?</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; School admins can refine campus goals and get feedback from summary reports.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Deans can manage their annual evaluations and tenure processes.</div>
          <div className={styles.segoeSmallBoldLeft}>&bull; Efficiency and ease in all things with <img className={styles.penspringLogoSmaller} src={penspringSmall} alt="penspring" />.</div>
        </div>
      </div>
    </div>
  )
}

export default MarketingEditForumClub