import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './AssessmentCorrectSameAllView.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import SelectSingleDropDown from '../../../components/SelectSingleDropDown'
import TextDisplay from '../../../components/TextDisplay'
import RadioGroup from '../../../components/RadioGroup'
import MessageModal from '../../../components/MessageModal'
import LinkDisplay from '../../../components/LinkDisplay'
import Icon from '../../../components/Icon'
import AssessmentTrueFalse from '../../../components/Assessment/AssessmentTrueFalse'
import AssessmentMultipleChoice from '../../../components/Assessment/AssessmentMultipleChoice'
import AssessmentMultipleAnswer from '../../../components/Assessment/AssessmentMultipleAnswer'
import AssessmentEssay from '../../../components/Assessment/AssessmentEssay'
import AssessmentPassage from '../../../components/Assessment/AssessmentPassage'
import InputText from '../../../components/InputText'
import OneFJefFooter from '../../../components/OneFJefFooter'
import classes from 'classnames'

function AssessmentCorrectSameAllView(props) {
  const {
    assessment,
    assessmentCorrectSameAllStudents,
    assessmentId,
    assessmentQuestions,
    assignmentId,
    correctionTypeFilter,
    getSameCorrectedAssessmentAllStudents,
    isTeacher,
    personId,
    teacherEssayResponse,
    updateTeacherAssessmentLearnerAnswer,
    listLevelGeneral,
  } = props

  const navigate = useNavigate()

  const [assessmentQuestionId, setAssessmentQuestionId] = useState()
  const [correctionTypes, setCorrectionTypes] = useState('all')
  const [filteredQuestions, setFilteredQuestions] = useState()
  const [isInitScore, setIsInitScore] = useState()
  const [isSearch, setIsSearch] = useState(false)
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [localQuestionView, setLocalQuestionView] = useState([])
  const [scores, setScores] = useState([])
  const [studentPersonId, setStudentPersonId] = useState() // ?? props.studentPersonId  I don't think that this is used.

  useEffect(() => {
    setStudentPersonId(props.studentPersonId)
  }, [])

  useEffect(() => {
    if (!isInitScore && assessmentCorrectSameAllStudents && assessmentCorrectSameAllStudents.length > 0) {
      let newScores = []
      assessmentCorrectSameAllStudents?.length > 0 && assessmentCorrectSameAllStudents.forEach(m => newScores[m.personId] = m.score)
      setIsInitScore(true)
      setScores(newScores)
    }
  }, [isInitScore])

  useEffect(() => {
    let newFilteredQuestions = assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.filter(m => m.assessmentQuestionId === assessmentQuestionId)

    if (correctionTypes !== "all" && assessmentCorrectSameAllStudents && assessmentCorrectSameAllStudents.length > 0) {
      let questionIds = []
      if (correctionTypes === "pendingEssays") {
        questionIds = assessmentCorrectSameAllStudents.reduce((acc, m) => m.pendingCorrection ? acc.concat(m.assessmentQuestionId) : acc, [])
      } else if (correctionTypes === "correctAnswers") {
        questionIds = assessmentCorrectSameAllStudents.reduce((acc, m) => m.isCorrect ? acc.concat(m.assessmentQuestionId) : acc, [])
      } else if (correctionTypes === "wrongAnswers") {
        questionIds = assessmentCorrectSameAllStudents.reduce((acc, m) => !m.isCorrect && !m.pendingCorrection ? acc.concat(m.assessmentQuestionId) : acc, [])
      }
      newFilteredQuestions = newFilteredQuestions.filter(m => questionIds.indexOf(m.assessmentQuestionId) > -1)
    }
    setFilteredQuestions(newFilteredQuestions)
  }, [assessmentQuestions, assessmentQuestionId])

  const submitEssayResponse = (assessmentQuestionId, teacherResponse) => {
    teacherResponse.studentPersonId = studentPersonId
    teacherResponse.assessmentQuestionId = assessmentQuestionId
    teacherResponse.assessmentId = assessmentId
    teacherEssayResponse(personId, teacherResponse)
    //getCorrectedAssessment(personId, studentPersonId, assessmentId, assignmentId)  Don't call   The function above, teacherEssayResponse will do the calling already ... after the update.
  }

  const handleRetakeTestClose = () => {

  }

  const handleRetakeTest = () => {
    
  }

  const toggleSearch = () => {
    setIsSearch(!isSearch)
  }

  const changeCorrectionTypes = (correctionTypes) => {
    setCorrectionTypes(correctionTypes)
  }

  const handleQuestionChange = (event) => {
    setAssessmentQuestionId(event.target.value)
    setIsInitScore(false)
    setScores({})
    getSameCorrectedAssessmentAllStudents(personId, assessmentId, event.target.value)
    navigate(`/assessmentCorrectSameAll/${assignmentId}/${assessmentId}/${event.target.value}`)
  }

  const onBlurScore = (studentPersonId, event) => {
    if (isTeacher) {
      updateTeacherAssessmentLearnerAnswer(personId, studentPersonId, assessmentQuestionId, event.target.value, assignmentId)
    }
  }

  const handleScore = (personId, event) => {
    setScores({ ...scores, personId: event.target.value })
  }

  return (
    <div className={styles.container}>
      <div className={globalStyles.pageTitle}>
        Correct Same Assessment Question for All Students
      </div>
      <div className={(styles.moreTop, styles.rowWrap)}>
        {/* <TextDisplay label={`Course`} className={styles.textDisplay} text={course && course.courseName} /> */}
        <TextDisplay label='Assessment' className={styles.textDisplay} text={assessment && assessment.name} />
        {!isTeacher && !(assessment.dueDate && assessment.dueDate < new Date()) && 
          <a onClick={() => setIsShowingModal(true)} className={classes(styles.link, styles.row)}>
            {`Re-take ${assessment && assessment.courseContentTypeName}`}
          </a>
        }
      </div>
      <div className={styles.rowWrap}>
        <div>
          <SelectSingleDropDown
            id={'assessmentQuestionId'}
            value={assessmentQuestionId}
            label={'Question'}
            options={assessmentQuestions}
            height={`medium`}
            className={styles.singleDropDown}
            onChange={handleQuestionChange} />
        </div>
        <Link to={`/assessmentCorrectSummary/${assignmentId}/${assessmentId}`} className={classes(styles.moreLeft, globalStyles.link)}>
          Summary Report
        </Link>
      </div>
      <hr />
      <a onClick={toggleSearch} className={classes(styles.row, styles.search)}>
        <Icon pathName={'magnifier'} premium={true} className={styles.icon} />
        {isSearch ? 'Hide filter controls' : 'Show filter choices for question types?'}
      </a>
      <hr />
      {isSearch &&
        <div className={styles.backgroundGray}>
          <RadioGroup
            title={'View correction types'}
            data={correctionTypeFilter}
            horizontal={true}
            className={styles.radio}
            initialValue={correctionTypes || 'all'}
            onClick={changeCorrectionTypes} />
          <hr />
        </div>
      }
      {assessmentCorrectSameAllStudents?.length > 0 && assessmentCorrectSameAllStudents.map((studentAnswer, i) =>
        <div key={i}>
          {filteredQuestions && filteredQuestions.length > 0 && filteredQuestions.map((m, i) => {
            let correctControls = ''

            if (isTeacher) {
              correctControls =
                <div>
                  <div className={styles.headerLabel}>{studentAnswer.personName}</div>
                  <InputText size={"super-short"}
                    value={scores[studentAnswer.personId] === 0
                      ? 0
                      : scores[studentAnswer.personId]
                        ? scores[studentAnswer.personId]
                        : ''
                    }
                    numberOnly={true}
                    maxLength={6}
                    name={m.assessmentQuestionId}
                    onChange={(event) => handleScore(studentAnswer.personId, event)}
                    onBlur={(event) => onBlurScore(studentAnswer.personId, event)} />
                </div>
            }
            if (m.questionTypeCode === 'TRUEFALSE') {
              return (
                <div key={i}>
                  {correctControls}
                  <AssessmentTrueFalse 
                    assessmentCorrect={assessmentCorrectSameAllStudents} 
                    isTeacher={isTeacher}
                    nameKey={i} 
                    personId={personId} 
                    question={m} />
                  <TextDisplay text={m.pointsPossible} label='Points' />
                  <div className={styles.linkDisplay}>
                    {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                      <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                    )}
                  </div>
                  <hr className={styles.hrHeight} />
                </div>
              )
            } else if (m.questionTypeCode === 'MULTIPLECHOICE') {
              return (
                <div key={i}>
                  {correctControls}
                  <AssessmentMultipleChoice 
                    assessmentCorrect={assessmentCorrectSameAllStudents} 
                    isTeacher={isTeacher}
                    nameKey={i} 
                    userPersonId={personId} 
                    question={m} />
                  <TextDisplay text={m.pointsPossible} label='Points' />
                  <div className={styles.linkDisplay}>
                    {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                      <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                    )}
                  </div>
                  <hr className={styles.hrHeight} />
                </div>
              )
            } else if (m.questionTypeCode === 'MULTIPLEANSWER') {
              return (
                <div key={i}>
                  {correctControls}
                  <AssessmentMultipleAnswer 
                    answers={m.answers} 
                    assessmentCorrect={assessmentCorrectSameAllStudents} 
                    isTeacher={isTeacher}
                    nameKey={i} 
                    userPersonId={personId}
                    mediaBinaries={m?.mediaBinaries} 
                    question={m} 
                    />
                  <TextDisplay text={m.pointsPossible} label='Points' />
                  <div className={styles.linkDisplay}>
                    {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                      <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                    )}
                  </div>
                  <hr className={styles.hrHeight} />
                </div>
              )
            } else if (m.questionTypeCode === 'ESSAY') {
              let correct = assessmentCorrectSameAllStudents?.length > 0 && assessmentCorrectSameAllStudents.filter(a => a.assessmentQuestionId === m.assessmentQuestionId)[0]
              let score = correct && correct.score ? correct.score : 0

              return (
                <div key={i}>
                  {correctControls}
                  <AssessmentEssay 
                    assessmentCorrect={assessmentCorrectSameAllStudents}
                    isSubmitted={true} 
                    isTeacher={isTeacher} 
                    nameKey={i} 
                    userPersonId={personId} 
                    question={m} 
                    listLevelGeneral={listLevelGeneral}
                    submitEssayResponse={submitEssayResponse} />
                  <TextDisplay text={m.keywordCountAccuracy || score ? `${score} out of ${m.pointsPossible}` : 'pending teacher review'} label='Points' />
                  <div className={styles.linkDisplay}>
                    {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                      <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                    )}
                  </div>
                  <hr className={styles.hrHeight} />
                </div>
              )
            } else if (m.questionTypeCode === 'PASSAGE') {
              return (
                <div key={i}>
                  <AssessmentPassage 
                    isTeacher={isTeacher} 
                    nameKey={i} 
                    question={m} />
                  <div className={styles.linkDisplay}>
                    {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                      <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                    )}
                  </div>
                  <hr className={styles.hrHeight} />
                </div>
              )
            }
            return m
          })}
        </div>)
      }
      <OneFJefFooter />
      {isShowingModal &&
        <MessageModal handleClose={handleRetakeTestClose} heading={<div className={styles.row}>{`Re-take ${assessment?.courseContentTypeName}`}</div>}
          explainJSX={`Are you sure you want to retake this ${assessment && assessment.courseContentTypeName}?`} isConfirmType={true}
          onClick={handleRetakeTest} />
      }
    </div>
  )
}


export default AssessmentCorrectSameAllView