import React, { useState, useEffect } from 'react'
import styles from './CheckboxToggle.module.css'
import classes from 'classnames'
import YesChosen from '../../assets/YesNoButtons/Yes-chosen.png'
import YesNotChosen from '../../assets/YesNoButtons/Yes-notChosen.png'
import NoChosen from '../../assets/YesNoButtons/No-chosen.png'
import NoNotChosen from '../../assets/YesNoButtons/No-notChosen.png'
import { useMediaQuery } from 'react-responsive'

function CheckboxToggle(props) {
  const {
    checked = 'PRERESPONSE',
    className,
    disabled = false,
    keyIndex,
    label = "",
    labelClass = "",
    onClick,
    position = 'before',
    whiteText,
  } = props

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' }) 
  const [chosen, setChosen] = useState(checked)

  useEffect(() => {
    if (checked === null || checked === undefined) {
      setChosen('PRERESPONSE') 
    } else {
      setChosen(checked === 'PRERESPONSE' ? null : checked) //checked will most likely be filled in with an answer guid
    }
  }, [checked])

  const handleClick = (newChosen) => {
    if (disabled) {
      onClick()
      return // Prevent changes if disabled
    }
    setChosen(newChosen) // Set to yes or no when clicked
    onClick(newChosen)
  }

  return (
    <div key={keyIndex} className={classes(isMobile ? styles.containerMobile : styles.container, className)}>
      {position === 'before' ?
        <div className={styles.toggleContainer}>
          <div onClick={() => handleClick('yes')}>
            <img src={chosen === null || chosen == 'PRERESPONSE' ? YesNotChosen : chosen === 'yes' ? YesChosen : YesNotChosen} alt="Toggle Button" className={styles.toggleImage} />
          </div>
          <div onClick={() => handleClick('no')}>
            <img src={chosen === null || chosen == 'PRERESPONSE' ? NoNotChosen : chosen === 'no' ? NoChosen : NoNotChosen} alt="Toggle Button" className={styles.toggleImage} />
          </div>
        </div>
        : ''
      }
      <a className={classes(styles.label, labelClass, (disabled ? styles.lowOpacity : styles.labelHover), (whiteText ? styles.whiteText : ''))} onClick={() => handleClick('yes')}>{label}</a>
      {position !== 'before' ?
        <div className={styles.toggleContainer}>
          <div onClick={() => handleClick('yes')}>
            <img src={chosen === null || chosen == 'PRERESPONSE' ? YesNotChosen : chosen === 'yes' ? YesChosen : YesNotChosen} alt="Toggle Button" className={styles.toggleImage} />
          </div>
          <div onClick={() => handleClick('no')}>
            <img src={chosen === null || chosen == 'PRERESPONSE' ? NoNotChosen : chosen === 'no' ? NoChosen : NoNotChosen} alt="Toggle Button" className={styles.toggleImage} />
          </div>
        </div>
        : ''
      }
    </div>
  )
}

export default CheckboxToggle
