import React, { useState, useEffect } from 'react'
import {useParams} from 'react-router-dom'
import AssessmentLearnerView from '../views/Assessment/AssessmentLearnerView'
import { connect } from 'react-redux'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionAssessment from '../actions/assessment.js'
import * as actionWorks from '../actions/works.js'
import * as actionAssessmentCorrect from '../actions/assessment-correct.js'
import moment from 'moment'
import { createInfoToastAuto } from '../services/queryClient.js'
import { formatDateYYYYMMDDHHMMSS } from '../utils/dateFormat.js'

import {
  selectMe,
  selectAssessment,
  selectAssessmentQuestions,
  selectListLevelGeneral,
  selectQuestionTypes,
} from '../store.js'

const mapStateToProps = (state) => {
  let me = selectMe(state)
  let assessment = selectAssessment(state)
  let assessmentQuestions = selectAssessmentQuestions(state)
  let sequenceCount = assessmentQuestions && assessmentQuestions.length
  let sequences = []
  for (var i = 1; i <= sequenceCount; i++) {
    let option = { id: String(i), value: String(i), label: String(i) }
    sequences = sequences ? sequences.concat(option) : [option]
  }

  return {
    assessment,
    assessmentQuestions,
    isTeacher: assessment.personId === me.personId,
    listLevelGeneral: selectListLevelGeneral(state),
    userPersonId: me.personId,
    questionTypes: selectQuestionTypes(state),
    sequences,
  }
}

const bindActionsToDispatch = dispatch => ({
  getAssessment: (personId, assessmentId) => dispatch(actionAssessment.getAssessment(personId, assessmentId)),
  getAssessmentQuestions: (studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentQuestions.getAssessmentQuestions(studentPersonId, assessmentId, assignmentId)),
  addOrUpdateAssessmentAnswer: (personId, assessmentQuestionId, answer, assignmentId) => dispatch(actionAssessmentQuestions.addOrUpdateAssessmentAnswer(personId, assessmentQuestionId, answer, assignmentId)),
  correctAssessment: (personId, studentPersonId, assessmentId, assignmentId, runFunction) => dispatch(actionAssessmentCorrect.correctAssessment(personId, studentPersonId, assessmentId, assignmentId, runFunction)),
  removeLearnerAnswerFile: (personId, assessmentQuestionId, fileUploadId) => dispatch(actionAssessmentQuestions.removeLearnerAnswerFile(personId, assessmentQuestionId, fileUploadId)),
  updateAssessmentLocalAnswer: (assessmentId, assessmentQuestionId, answer) => dispatch(actionAssessmentQuestions.updateAssessmentLocalAnswer(assessmentId, assessmentQuestionId, answer)),
  createWorkInAssessment: (assessmentQuestionId) => dispatch(actionAssessmentQuestions.createWorkInAssessment(assessmentQuestionId)),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
//  setMyVisitedPage: (personId, myVisitedPage) => dispatch(actionMyVisitedPages.setMyVisitedPage(personId, myVisitedPage)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const {
    assessment,
    getAssessmentQuestions,
    getAssessment,
    getListLevelGeneral,
    userPersonId,
  } = props

  const params = useParams()
  const [isInit, setIsInit] = useState()
  const [pastDue, setPastDue] = useState()
  let debounceTimer

  useEffect(() => {
    if (!isInit && userPersonId && params.assessmentId && params.assignmentId) {
      setIsInit(true)
      getAssessmentQuestions(userPersonId, params.assessmentId, params.assignmentId)
      getAssessment(userPersonId, params.assessmentId)
      getListLevelGeneral()
      // if (params.isRetakeQuiz !== 'notRetake' && props.assessmentQuestions?.length > 0) {
      //   setAssessmentQuestions(props.assessmentQuestions.map(question => ({
      //     ...question,
      //     learnerAnswer: ''
      //   })))
      // }
    }
    // Optional: Tracking page visits (ensure this side effect is appropriate here or may need separation)
    // props.location && props.location.pathname && setMyVisitedPage(userPersonId, {path: props.location.pathname, description: `Student Assessment`})
  }, [isInit, userPersonId, params.assessmentId, params.assignmentId, props.assessmentQuestions, params.isRetakeQuiz])


  useEffect(() => {
    if (assessment.dueDate) {
      const dueDate = formatDateYYYYMMDDHHMMSS(assessment.dueDate)
      const nowDate = moment().format('YYYY-MM-DD HH:mm:ss')
      if (dueDate < nowDate) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => createInfoToastAuto(`<div>The due date for this assessment has passed.</div>`), 200)
        setTimeout(() => window.location = `/myWorks`, 4000)
        setPastDue(true)
      }
    }
  }, [assessment]) 


  return assessment && assessment.assessmentId && !pastDue
    ? <AssessmentLearnerView {...props} benchmarkTestId={params.benchmarkTestId} assignmentId={params.assignmentId} assessmentId={params.assessmentId} /> 
    : null
}

export default storeConnector(Container)
