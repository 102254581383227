import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from "react-router-dom";
import AssessmentSolutionDisplay from '../AssessmentSolutionDisplay/AssessmentSolutionDisplay'
import AssessmentStudentResponseSetting from '../AssessmentStudentResponseSetting/AssessmentStudentResponseSetting'
import classes from 'classnames'
import globalStyles from '../../../utils/globalStyles.module.css'
import Icon from '../../Icon'
import InputTextArea from '../../InputTextArea'
import MediaLinks from '../../Media/MediaLinks'
import MediaListViewer from '../../Media/MediaListViewer'
import MessageModal from '../../MessageModal'
import PointsDisplay from '../../PointsDisplay'
import QuestionLabel from '../../QuestionLabel'
import SelectSingleDropDown from '../../SelectSingleDropDown'
import styles from './AssessmentMatching.module.css'
import TextDisplay from '../../TextDisplay'
import debounce from 'lodash.debounce'; // Consider using lodash's debounce function

const alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
/*
  The learnerAnswer and the correctAnswer should not be used at the same time.  The correctAnswer comes through when it is the content manager.
    The learnerAnswer comes through when it is the learner taking the text.
    But then again, it is going to be necessary to show the correct answer with the learner answer.  In that case, we'll have to use TextDisplay
    such as "[ ANSWER ]" in green to show the correct answer when the learnerAnswer is present.
*/
function AssessmentMatching(props) {
  const {
    addOrUpdateAssessmentAnswer,
    answers,
    assessmentCorrect,
    assessmentQuestionId,
    assignmentId,
    bigTextDisplay,
    handleMatchingCorrectAnswers,
    handleMultipleAnswers,
    handleMultipleToMatch,
    isTeacher,
    matches,
    mediaBinaries,
    onBlurScore,
    userPersonId,
    removeMatchingRemoveLine,
    score,
    setScore,
    viewMode = 'AddOrUpdate',
  } = props

  const location = useLocation();
  const isJustItemSetupPage = location.pathname.indexOf('assessmentItemSetup') > -1


  const [displayEntries, setDisplayEntries] = useState()
  const [isShowingModal_removeLine, setIsShowingModal_removeLine] = useState(false)
  const [question, setQuestion] = useState({})
  const [removeIndex, setRemoveIndex] = useState()

  useEffect(() => {
    if (!assessmentQuestionId && !question?.answers?.length && !displayEntries) setDisplayEntries(6)
  }, [question, assessmentQuestionId])

  useEffect(() => {
    if (props.question) {
      let newQuestion = {...props.question}

      newQuestion.correctAnswer = typeof newQuestion.correctAnswer === 'object'
        ? [...newQuestion.correctAnswer]
        : newQuestion.correctAnswer?.length > 0
          ? newQuestion.correctAnswer.split(',')
          : []

      if (newQuestion.learnerAnswer) {
        const updatedLearnerAnswer = (() => {
          const learnerAnswer = newQuestion.learnerAnswer?.learnerAnswer
          if (Array.isArray(learnerAnswer)) return [...learnerAnswer]
          if (typeof learnerAnswer === 'string' && learnerAnswer.length > 0) return learnerAnswer.split(',')
          return []
        })()

        newQuestion.learnerAnswer = { ...newQuestion.learnerAnswer, learnerAnswer: updatedLearnerAnswer}
      }

      setQuestion(newQuestion)
      if (!displayEntries) setDisplayEntries(newQuestion?.answers?.length)
    }
  }, [props.question])

  const handleRemoveLineOpen = (removeIndex) => {
    setIsShowingModal_removeLine(true)
    setRemoveIndex(removeIndex)
  }
  const handleRemoveLineClose = () => setIsShowingModal_removeLine(false)
  const handleRemoveLine = () => {
    removeMatchingRemoveLine(removeIndex)
    handleRemoveLineClose()
  }

  const handleAddMoreEntries = (event) => {
    let newDisplay = event.target.value
    newDisplay = newDisplay > 26 ? 26 : newDisplay
    setDisplayEntries(newDisplay)
  }


  const debouncedUpdate = useCallback(debounce((userPersonId, assessmentQuestionId, learnerAnswerString, assignmentId) => {
    addOrUpdateAssessmentAnswer(userPersonId, assessmentQuestionId, learnerAnswerString, assignmentId)
  }, 300), [])

  const handleLearnerAnswer = (event, index) => {
    setQuestion(prevQuestion => {
      const newLearnerAnswers = prevQuestion?.learnerAnswer?.learnerAnswer ? [...prevQuestion.learnerAnswer.learnerAnswer] : []
      if (index >= 0 && index < newLearnerAnswers.length) {
        newLearnerAnswers[index] = event.target.value
      } else {
        newLearnerAnswers[index] = event.target.value
      }

      return {
        ...prevQuestion,
        learnerAnswer: {
          ...prevQuestion.learnerAnswer,
          learnerAnswer: newLearnerAnswers
        }
      }
    })

    setQuestion(prevQuestion => {
      const learnerAnswerString = prevQuestion.learnerAnswer.learnerAnswer.join(',')
      debouncedUpdate(userPersonId, assessmentQuestionId, learnerAnswerString, assignmentId)
      return prevQuestion 
    })
  }

  let answerOptions = []
  let indexLimit = viewMode === 'StudentView' ? answers && answers.length : displayEntries
  for (let i = 0; i < indexLimit; i++) {
    let option = { id: alpha[i], label: alpha[i] }
    answerOptions = answerOptions && answerOptions.length > 0 ? answerOptions.concat(option) : [option]
  }

  let displayOptions = []
  for (let i = 1; i <= 26; i++) {
    let option = { id: i, label: i }
    displayOptions = displayOptions?.length > 0 ? displayOptions.concat(option) : [option]
  }

  //1. Loop through the matching records (with a starting default of 6)
  //2. Show the answer box on the left, the first list of matching values and the right-side list of values to match.
  //3. Allow each displayEntries to have a picture and/or file.
  let linesToMatch = []
  let linesToMatchLeft = []
  let linesToMatchRight = []

  if (viewMode === 'AddOrUpdate') {
    for (let index = 0; index < displayEntries; index++) {
      linesToMatchLeft.push(
        <div key={index}>
          <div className={styles.row}>
            <div>
              <SelectSingleDropDown
                label={'Answer'}
                value={question.correctAnswer?.[index] || ''}
                options={answerOptions}
                whiteText
                height={bigTextDisplay ? 'bigtext' : ''}
                className={bigTextDisplay ? globalStyles.bigText : ''}
                labelClass={bigTextDisplay ? globalStyles.bigText : ''}
                selectClass={bigTextDisplay ? globalStyles.bigText : ''}
                onChange={(event) => handleMatchingCorrectAnswers(event, index)} />
              {isTeacher &&
                ((question && question.correctAnswer?.[index]) || (question && answers[index]) || (question && matches[index])) &&
                <div onClick={() => handleRemoveLineOpen(index)} className={classes(styles.link, styles.red, styles.moreTop)}>remove</div>
              }
            </div>
            <InputTextArea
              label={`${index + 1 * 1}.`}
              value={answers[index]?.answerText || ''}
              onChange={(event) => handleMultipleAnswers(event, index)}
              whiteText
              textareaClass={bigTextDisplay ? globalStyles.bigText : ''}
              inputClassName={bigTextDisplay ? globalStyles.bigText : ''}
              labelClass={bigTextDisplay ? globalStyles.bigText : ''}
              autoComplete={'dontdoit'} />
          </div>
          <MediaLinks
            audioLabel={'File for'}
            audioTitle={'Assessment Question'}
            runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
            mediaBase={{
              recordType: 'AssessmentQuestion',
              recordId: assessmentQuestionId,
              section: 'answer',
              indexNbr: index,
            }}
            userPersonId={userPersonId}
            {...props} />
          <MediaListViewer
            recordType={'AssessmentQuestion'}
            recordId={assessmentQuestionId}
            section={'answer'}
            index={index}
            userPersonId={userPersonId}
            runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
            mediaBinaries={mediaBinaries} />
          <hr />
        </div>
      )
    }

    for (let index = 0; index < displayEntries; index++) {
      linesToMatchRight.push(
        <div className={styles.rightSidePosition} key={index}>
          <InputTextArea
            label={`${alpha[index]}.`}
            value={matches[index]?.toMatchText || ''}
            onChange={(event) => handleMultipleToMatch(event, index)}
            whiteText
            textareaClass={bigTextDisplay ? globalStyles.bigText : ''}
            inputClassName={bigTextDisplay ? globalStyles.bigText : ''}
            labelClass={bigTextDisplay ? globalStyles.bigText : ''}
            autoComplete={'dontdoit'} />
          <MediaLinks
            audioLabel={'File for'}
            audioTitle={'Assessment Question'}
            runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
            mediaBase={{
              recordType: 'AssessmentQuestion',
              recordId: assessmentQuestionId,
              section: 'matching',
              indexNbr: index,
            }}
            userPersonId={userPersonId}
            {...props} />
          <MediaListViewer
            recordType={'AssessmentQuestion'}
            recordId={assessmentQuestionId}
            section={'matching'}
            index={index}
            userPersonId={userPersonId}
            runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
            mediaBinaries={mediaBinaries} />
          <hr />
        </div>
      )
    }
  } else if (viewMode === 'TeacherView') {
    for (let index = 0; index < answers?.length; index++) {
      //if (question.correctAnswer?.[index] !== '0') {
        linesToMatchLeft.push(
          <div key={`question${index}`} className={styles.spaceBottom}>
            <div className={styles.row} key={index}>
              <TextDisplay label='Answer' text={<div className={classes(styles.overSizeText, styles.littleLeft)}>{question?.correctAnswer?.[index]}</div>} />
              <div className={classes(styles.moreLeft, styles.overSizeText)}>{index + 1 * 1}</div>
              <div className={styles.text}>{answers[index]?.answerText}</div>
            </div>
            <MediaListViewer
              recordType={'AssessmentQuestion'}
              recordId={assessmentQuestionId}
              section={'answer'}
              index={index}
              userPersonId={userPersonId}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBinaries={mediaBinaries} />
          </div>
        )
      //}
    }
    for (let index = 0; index < answers?.length; index++) {
      //if (question.correctAnswer?.[index] !== '0') {
        linesToMatchRight.push(
          <div key={`match${index}`}>
            <div className={styles.row} key={index}>
              <div
                className={classes(styles.overSizeText, styles.moreLeft)}>{alpha[index]}</div>
              <div className={styles.text}>{matches[index]?.toMatchText}</div>
            </div>
            <MediaListViewer
              recordType={'AssessmentQuestion'}
              recordId={assessmentQuestionId}
              section={'matching'}
              index={index}
              userPersonId={userPersonId}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBinaries={mediaBinaries} />
          </div>
        )
      //}
    }
  } else if (viewMode === 'StudentView') {
    for (let index = 0; index < answers?.length; index++) {
      //if (question.correctAnswer?.[index] !== '0') {
        linesToMatchLeft.push(
          <div key={index}>
            <div className={styles.row}>
              <div>
                <SelectSingleDropDown
                  label={'Answer'}
                  value={(question?.learnerAnswer?.learnerAnswer?.length > 0 && question.learnerAnswer.learnerAnswer[index]) || ''}
                  options={answerOptions}
                  height={bigTextDisplay ? 'bigtext' : 'small-narrow'}
                  className={classes(styles.moreBottomMargin, bigTextDisplay ? globalStyles.bigText : '')}
                  labelClass={bigTextDisplay ? globalStyles.bigText : ''}
                  selectClass={bigTextDisplay ? globalStyles.bigText : ''}
                  required={true}
                  whenFilled={question.learnerAnswer && question.learnerAnswer.learnerAnswer && question.learnerAnswer.learnerAnswer[index]}
                  onChange={(event) => handleLearnerAnswer(event, index)} />
              </div>
              <div className={classes(styles.column, styles.moreTop)}>
                <div
                  className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.overSizeText)}>{index + 1 * 1}</div>
                <div
                  className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.text)}>{answers[index]?.answerText}</div>
              </div>
            </div>
            <MediaListViewer
              recordType={'AssessmentQuestion'}
              recordId={assessmentQuestionId}
              section={'answer'}
              index={index}
              userPersonId={userPersonId}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBinaries={mediaBinaries} />
          </div>
        )
      //}
    }
    for (let index = 0; index < answers?.length; index++) {
      //if (question.correctAnswer?.[index] !== '0') {
        linesToMatchRight.push(
          <div className={styles.row} key={index}>
            <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.overSizeText, styles.moreLeft)}>{alpha[index]}</div>
            <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.text)}>{matches[index]?.toMatchText}</div>
            <MediaListViewer
              recordType={'AssessmentQuestion'}
              recordId={assessmentQuestionId}
              section={'matching'}
              index={index}
              userPersonId={userPersonId}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBinaries={mediaBinaries} />
          </div>
        )
      //}
    }
  } else if (viewMode === 'CorrectedView') {
    for (let index = 0; index < answers?.length; index++) {
      let learnerAnswer = question?.learnerAnswer?.learnerAnswer?.[index]
      let correctAnswer = question?.correctAnswer?.[index]
      let isCorrect = learnerAnswer === correctAnswer

      if (question.correctAnswer?.[index] !== '0') {
        linesToMatch.push(
          <div className={styles.rowMatching} key={index}>
            <div className={styles.columnGrow}>
              <div className={classes(styles.column, styles.moreTop)}>
                <div
                  className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.overSizeText)}>{index + 1 * 1}</div>
                <div
                  className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.text)}>{answers[index]?.answerText}</div>
              </div>
              <TextDisplay label={`Answer`} text={
                <div>
                  <div className={styles.row}>
                    <Icon pathName={isCorrect ? 'checkmark0' : 'cross_circle'}
                      fillColor={isCorrect ? 'green' : 'red'}
                      premium={true} className={styles.icon} />
                    <div
                      className={classes((bigTextDisplay ? globalStyles.bigText : ''), (isCorrect ? styles.correctText : styles.wrongText))}>
                      {learnerAnswer}
                    </div>
                  </div>
                  {!isCorrect &&
                    <TextDisplay label={`Correct`} text={
                      <div>
                        <div
                          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.correctText)}>
                          {`${correctAnswer}. ${matches[alpha.indexOf(correctAnswer)]}`}
                        </div>
                      </div>
                    } />
                  }
                  <MediaListViewer
                    recordType={'AssessmentQuestion'}
                    recordId={assessmentQuestionId}
                    section={'answer'}
                    index={index}
                    userPersonId={userPersonId}
                    runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
                    mediaBinaries={mediaBinaries} />
                </div>
              } />
            </div>
          </div>
        )
      }
    }
    for (let index = 0; index < answers?.length; index++) {
      if (question.correctAnswer?.[index] !== '0') {
        linesToMatchRight.push(
          <div>
            <div className={styles.row} key={index}>
              <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.overSizeText, styles.moreLeft)}>{alpha[index]}</div>
              <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.text)}>{matches[index]?.toMatchText}</div>
            </div>
            <MediaListViewer
              recordType={'AssessmentQuestion'}
              recordId={assessmentQuestionId}
              section={'matching'}
              index={index}
              userPersonId={userPersonId}
              runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
              mediaBinaries={mediaBinaries} />
          </div>
        )
      }
    }
  }
  
  return (
    <div className={styles.container}>
      <QuestionLabel label={'Matching'} />
      <div className={classes(styles.row, styles.questionLine)}>
        {assessmentCorrect && question?.learnerAnswer?.assessmentLearnerAnswerId
          ? question?.learnerAnswer?.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
        <div className={styles.row}>
          {question?.sequence && <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{`${question.sequence || ''}. `}</div>}
          {!isJustItemSetupPage &&
            <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>
              {question.questionText}
            </div>
          }
        </div>
      </div>
      <MediaListViewer
        recordType={'AssessmentQuestion'}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={question?.mediaBinaries} />
      <AssessmentStudentResponseSetting question={question} />
      <PointsDisplay
        assessmentCorrect={assessmentCorrect}
        assessmentQuestionId={assessmentQuestionId}
        className={styles.littleRight}
        isTeacher={question.isOwner}
        onBlurScore={onBlurScore}
        pending={!question.learnerAnswer || question.learnerAnswer?.pendingCorrection}
        pointsPossible={question?.pointsPossible}
        score={score}
        setScore={setScore}
        studentPersonId={question?.learnerAnswer?.personId} />
        
      {viewMode === 'AddOrUpdate' &&
        <div className={classes(styles.moreLeft, styles.moreBottom)}>
          <SelectSingleDropDown
            label={'Number of questions'}
            value={displayEntries}
            options={displayOptions}
            whiteText
            height={bigTextDisplay ? 'bigtext' : ''}
            className={classes(styles.moreBottomMargin, bigTextDisplay ? globalStyles.bigText : '')}
            labelClass={bigTextDisplay ? globalStyles.bigText : ''}
            selectClass={bigTextDisplay ? globalStyles.bigText : ''}
            onChange={handleAddMoreEntries} />
        </div>
      }
      {viewMode !== 'AddOrUpdate' && isTeacher &&
        <TextDisplay label={'Number of questions'} text={displayEntries} />
      }
      <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.moreLeft, styles.row)}>
        {linesToMatch}
        {linesToMatchLeft?.length > 0 &&
          <div className={classes(styles.moreBottom, styles.cellGrow)}>
            <div
              className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.headerText)}>
              Questions
            </div>
            <div>
              {linesToMatchLeft}
            </div>
          </div>
        }
        {linesToMatchRight?.length > 0 &&
          <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.moreLeft, styles.cellGrow)}>
            <div
              className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.headerText)}>
              To Match:
            </div>
            <div>
              {linesToMatchRight}
            </div>
          </div>
        }
      </div>
      <MediaListViewer
        label={`Student's response`}
        recordType={`AssessmentLearnerAnswer${question?.learnerAnswer?.personId}`}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      {viewMode !== 'AddOrUpdate' && isTeacher &&
        <AssessmentSolutionDisplay question={question} userPersonId={userPersonId} className={styles.solutionDisplay} />
      }
      {isShowingModal_removeLine &&
        <MessageModal 
          handleClose={handleRemoveLineClose}
          heading={'Remove this control line?'}
          explainJSX={"Are you sure you want to remove this line of the matching assessment question?"}
          isConfirmType={true}
          onClick={handleRemoveLine} />
      }
    </div>
  )
}

export default AssessmentMatching