import React from 'react';
import styles from './EditTable.module.css';
import classes from 'classnames';

export default ({
  className = "", 
  clickFunction,
  data = [], 
  headings = [], 
  sendToReport, 
  sortByHeadings = {}, 
}) => {

	return (
		<div className={classes(className, styles.container)}>
			<table className={styles.tableClass}>
				<tbody>
				{headings && headings[0] && !!headings[0].upperHeader &&
					<tr>
              <th colSpan={10} className={styles.upperHeader}>{headings[0].upperHeader}</th>
					</tr>
				}
				{headings && headings[0] && !!headings[0].subUpperHeader &&
					<tr>
						<th colSpan={10} className={styles.upperHeader}>{headings[0].subUpperHeader}</th>
					</tr>
				}
				{headings && <tr className={styles.tallRow}>
					{headings.map((heading, i) => {
						return (
							<th key={i}
							    onClick={heading.pathLink
								    ? () => sendToReport(heading.pathLink)
								    : heading.clickFunction
									    ? heading.clickFunction
									    : () => {
									    }}
							    className={classes((heading.languageNames && heading.languageNames.length > 0 ? styles.headerOverride : ''),
								    (heading.pathLink || heading.clickFunction ? styles.tdLink : ''),
								    (heading.verticalText ? styles.rotate : ''),
								    (heading.tightText ? styles.headerOverride : ''))}>
								{heading.label &&
									<div>
                    <span>
                      <span className={heading.verticalText ? styles.textLeft : ''}>
                          {heading.label}
                        {heading.subLabel && <br/>}
                        {heading.subLabel}
                        {heading.languageNames && heading.languageNames.length > 0 &&
                          heading.languageNames.map((lang, index) => {  //eslint-disable-line
                            if (heading.workLanguageName !== lang) {
                              return <div key={index}
                                          className={styles.languageName}>{lang}</div>
                            }
                          })
                        }
                      </span>
                    </span>
									</div>
								}
							</th>
						)
					})
					}
				</tr>
				}
				{headings.length > 1 && data.map((row, d) => {
					if (row && row.row && row.row.length > 0) row = row.row
					return (
						<tr key={d}>
							{row && row.length > 0 && row.map((cell, i) => {
									return (
										<td key={cell.key || i}
										    className={classes(styles.column, (cell.pathLink || cell.clickFunction ? styles.tdLink : ''),
											    (d % 2 ? styles.goldBack : styles.blueBack),
											    (cell.nowrap ? styles.noWrap : ''),
											    (cell.cellColor === 'green' ? styles.greenBack : cell.cellColor === 'red' ? styles.redBack : ''))}
										    onClick={cell.pathLink
											    ? () => sendToReport(cell.pathLink)
											    : cell.clickFunction
												    ? cell.clickFunction
												    : () => {
												    }
										    }>
											{cell.value === 0 || cell.value === '0' ? '' : cell.value}
										</td>
									)
								}
							)}
						</tr>
					)}
				)}
				{(!data || data.length === 0 || headings.length === 1) &&
					<tr>
						<td className={styles.column}>
						</td>
					</tr>
				}
				{(!data || data.length === 0 || headings.length === 1) &&
					<tr>
						<td className={classes(styles.column, styles.emptyReport)} colSpan={5}>
							{'No data found. Consider changing your search criteria.'}
						</td>
					</tr>
				}
				</tbody>
			</table>
		</div>
	)
};
