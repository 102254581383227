import React, { useState, useRef, useEffect } from 'react'
import styles from './ListChangeModal.module.css'
import ButtonWithIcon from '../ButtonWithIcon'
import MessageModal from '../MessageModal'
import RadioGroup from '../RadioGroup'
import { useMediaQuery } from "react-responsive"
import { createInfoToastAuto } from '../../services/queryClient'
import classes from 'classnames'
import AlphabetStraight from '../../assets/List icons/alphabetStraight.png'
import BulletList from '../../assets/List icons/bulletList.png'
import BulletStraight from '../../assets/List icons/bulletStraight.png'
import NoneList from '../../assets/List icons/noneList.png'
import NumberedList from '../../assets/List icons/numberedList.png'
import NumberedStraight from '../../assets/List icons/numberedStraight.png'
import AttorneyList from '../../assets/List icons/attorneyList.png'
import * as editorService from '../../services/editor-dom'

//The choice of the list has some things to take into consideration.
//A bullet list will use UL parent elements - unordered list. 
//	There are not custom bullets implemented at the moment. We are just taking the default HTML bullet list that comes with its levels.
//A numbered list uses OL parent element - ordered list.
//  When the user chooses the straight A., B., C. list, it really is just the first list-style-type that changes. Otherwise, it takes the default ordered list level characters (just like Microsoft Word does)
//  The harder numbered list is hte 'attorney list' which is 1. 1.1., 1.1.1. which is a Microsoft Word thing that requires a reference to a level of a style class name: awlist1, awlist2.
//	So the funny thing is that the 1., 2., 3., 4., "straight" list is nothing more than just the regular ordered list by default. If the user tabs in, it will continue with 1., a., i., A., etc.
//		It is just a user interface thing to calm the nerves of the user if they just want a straight list without all of that other busy details for indented lists.

const ListChangeModal = (props) => {
	const { 
		addListTarget,
		convertTextToList,
		createNewList,
		onCancel,
		onClose, 
		savedElementsForEdit,
		savedElementTextAnchorNode,
		updateConvertAddListEdit,
    editorDivId,
    openListModal,
	} = props

	const containerRef = useRef(null);

	const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
	const [listType, setListType] = useState()
	const [submitSuccess, setSubmitSuccess] = useState(false)
	const [applyList, setApplyList] = useState('')
	const [openSentenceRangeMessage, setOpenSentenceRangeMessage] = useState(false)
  const [applyOptions, setApplyOptions] = useState([])
	const [editorDiv, setEditorDiv] = useState([])

  useEffect(() => {
    if (editorDivId) setEditorDiv(document.getElementById(editorDivId))
  }, [editorDivId])

	useEffect(() => {
		const isInList = savedElementsForEdit?.length > 0 && savedElementsForEdit.filter(element => element.nodeName === 'LI' || element.nodeName === 'UL' || element.nodeName === 'OL')
		let newApplyOptions = []
		if (!isInList?.length > 0 && addListTarget) { 
			newApplyOptions.push({ id: 'STARTNEW', label: 'Start a new list at the target location' })
			setApplyList('STARTNEW')
		}
		if (isInList?.length || props.changeList) {
			newApplyOptions.push({ id: 'CHANGELIST', label: 'Convert the existing list where the cursor is located' })
			setApplyList('CHANGELIST')
		} 
		if (savedElementsForEdit?.length > 0 && !isInList?.length > 0 && !props.changeList) {
			newApplyOptions.push({ id: 'TEXTCONVERT', label: 'Convert the selected text to a list' })
			if (!applyList) setApplyList('TEXTCONVERT')
		} 
		if (props.addList && props.convertAddListSequence >= 1 && !props.changeList) {
			newApplyOptions.push({ id: 'TEXTSELECT', label: 'Select one or more sentences to start a new list' })
			if (!applyList) setApplyList('TEXTSELECT')
		}
		setApplyOptions(newApplyOptions)
	}, [savedElementsForEdit, props.changeStyle, addListTarget, props.addList, props.changeList, props.convertAddListSequence ])

	// useEffect(() => {
	// 	const handleClickOutside = (event) => {
	// 		if (containerRef.current && !containerRef.current.contains(event.target) && event.target.innerText !== 'OK' && event.target.innerText !== 'pause') {
	// 			onClose()
	// 		}
	// 	}
	// 	const handleKeyDown = (event) => {
	// 		if (event.key === 'Escape' && containerRef.current && event.target.innerText !== 'OK' && event.target.innerText !== 'pause') {
	// 			onClose()
	// 		}
	// 	};
	// 	document.addEventListener('mousedown', handleClickOutside);
	// 	document.addEventListener('keydown', handleKeyDown);
	// 	return () => {
	// 		document.removeEventListener('mousedown', handleClickOutside);
	// 		document.removeEventListener('keydown', handleKeyDown);
	// 	}
	// }, []);

	const handleSetListType = (newListType) => {
		if (props.changeList) {
			const parentListElement = editorService.getParentListElement(props.currentElement, props.chapterId)
			if (parentListElement) {
				const currentListType = editorService.getCurrentListType(parentListElement)
				if (currentListType === newListType) {
					createInfoToastAuto(`<div>The list type chosen is the same type as the current list.</div>`)	
				}
			}
		}
		setListType(newListType)
	}

	const handleSetApplyList = (value) => {
		if (value === 'TEXTSELECT') {
			setOpenSentenceRangeMessage(true)
		} else {
			setApplyList(value)
		}
	}

	const processForm = (event) => {
		event.stopPropagation()
		event.preventDefault()
		let missingFields = ''

		if (!applyList) missingFields += "\nHow do you want to create a list?"
		if (!listType) missingFields += "\nChoose a list type."

		if (missingFields) {
			createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`)
		} else {
			setSubmitSuccess(true)

			if (applyList === 'STARTNEW') {
				//1. Create an addOrUpdateEdit for ADDLIST edit type tied to a given paragraph
				//		a. include the element list - including paragraphs and spans.
				//2. Prepare to save the segments off to a table AddListEditEntry with a sequence of segments that do exist among those which might be new by this editor.
				if (addListTarget) {
					props.saveRevision()
					createNewList(listType)
				} else {
					createInfoToastAuto(`There is not a target found to create this list. Please return to the editing page to choose a target from 'Add List' edit option.`)
				}
			} else if (applyList === 'CHANGELIST') {
				const parentListElement = editorService.getParentListElement(props.currentElement, props.chapterId)
				if (parentListElement) {
					const currentListType = editorService.getCurrentListType(parentListElement)
          if (currentListType && listType && (currentListType === listType || (currentListType.indexOf('BULLET') > -1 === listType.indexOf('BULLET') > -1))) {
						createInfoToastAuto(`<div>The list type chosen is the same type as the current list.</div>`)	
					} else {
            editorService.changeListType({ 
              addOrUpdateEdit: props.addOrUpdateEdit, 
              chapterId: props.chapterId, 
              editorName: props.editorName, 
              getWorkEditReviewFilled: props.getWorkEditReviewFilled, 
              isAuthor: props.isAuthor, 
              languageId: props.languageId, 
              listLevelGeneral: props.listLevelGeneral,
              listType, 
              parentListElement, 
              personId: props.personId, 
            })
					}
				}

			} else if (applyList === 'TEXTCONVERT') {
				let firstPartialSpan
				let firstPartialSpanRightSide
				let lastPartialSpan
				let lastPartialSpanLeftSide
				let fullChosenElements = savedElementsForEdit
				let targetParagraph 

				//Only partial text of a single span was selected which results in a #text node.
				if (savedElementsForEdit.length === 1 && savedElementsForEdit[0].nodeName === '#text') {
					firstPartialSpan = savedElementTextAnchorNode //This comes from saveSelection in AuthoringEditor when the text node is going to be assigned to the selection-container DIV and we'll lose its actual parent.
					firstPartialSpanRightSide = savedElementsForEdit[0].textContent  //In this case this is most likely a middle setion of the span and not the entire right side. So we'll have to take this into consideration when comparing to the editor's version of the span to see what is left to move. We have to create a new paragraph anyway after the list for the rest of this paragraph (most likely - if there are spans left over on the right side)
					fullChosenElements = [savedElementTextAnchorNode]
					targetParagraph = firstPartialSpan.parentElement.nextSibling //We'll most likely be adding a new paragraph after the new list since there will most likely be sentences left over

				// //Is this possible? I would think that this is covered in the #text node above.
				// } else if (savedElementsForEdit.length === 1 && savedElementsForEdit[0].nodeName === 'SPAN') {
				// 	firstPartialSpan = savedElementsForEdit[0]
				// 	firstPartialSpanRightSide = savedElementsForEdit[0].innerHTML
				// 	fullChosenElements = [savedElementsForEdit[0]]
				// 	targetParagraph = firstPartialSpan.parentElement.nextSibling

				} else {
					const firstEditorSpan = editorDiv.querySelector(`span[id="${savedElementsForEdit[0].id}"][data-type="TEXT"]`)
					if (savedElementsForEdit[0].innerHTML !== firstEditorSpan.innerHTML) {
						firstPartialSpan = savedElementsForEdit[0]
						firstPartialSpanRightSide = savedElementsForEdit[0].innerHTML
					}
					const lastEditorSpan = editorDiv.querySelector(`span[id="${savedElementsForEdit[savedElementsForEdit.length-1].id}"][data-type="TEXT"]`)
					if (savedElementsForEdit[savedElementsForEdit.length-1].innerHTML !== lastEditorSpan.innerHTML) {
						lastPartialSpan = savedElementsForEdit[savedElementsForEdit.length - 1]
						lastPartialSpanLeftSide = savedElementsForEdit[savedElementsForEdit.length - 1].innerHTML
					}
					//If the paragraph is partially chosen, then the spans chosen are going to be moved into the list so the paragraph will be the target where the list will precede the
					//	paragraph. Otherwise, we will choose the next paragraph since the spans will be moved into the list and the paragraph element will be deleted.
					//Be careful here: If a pararaph border was not crossed, the savedElementsForEdit will only contain spans. In the case of no border crossed, pick up the paragraph of the last span selected.
					//	But the nature of the beast here is that savedElementsForEdit is its own collection so that the parent is going to be a div. So we need to go back to the editor for the lastSpan version to get the paragraph.
					const spansOnly = savedElementsForEdit.filter(m => m.nodeName === 'SPAN')
					const lastSpanSelected = spansOnly[spansOnly.length - 1]
					const paragraphs = savedElementsForEdit.filter(m => m.nodeName === 'P')
					let lastParagraph
					if (paragraphs?.length > 0) {
						lastParagraph = paragraphs[paragraphs.length - 1]
					} else {
						const editorSpan = editorDiv.querySelector(`span[id="${lastSpanSelected.id}"][data-type="TEXT"]`)
						if (editorSpan) {
							lastParagraph = editorSpan.parentElement
						}
					}
					if (lastParagraph) {
						//Remember that savedElementsForEdit is its own limited collection. In order to compare the actual last paragraph in the full editor, we need to get the paragraph from the editorDiv.
						const editorParagraph = editorDiv.querySelector(`p[id="${lastParagraph.id}"]`)
						let lastSpanInParagraph
						for (let i = editorParagraph.children.length; i >= 0; i--) {
							if (!lastSpanInParagraph && editorParagraph.children[i]?.nodeName === 'SPAN' && editorParagraph.children[i]?.id && !isNaN(editorParagraph.children[i]?.id)) {
								lastSpanInParagraph = editorParagraph.children[i]
							}
						}
						const isCompleteParagraph = lastSpanSelected === lastSpanInParagraph
						targetParagraph = isCompleteParagraph ? lastParagraph.nextSibling : lastParagraph
					}
				}

				convertTextToList(firstPartialSpan,
					firstPartialSpanRightSide,
					lastPartialSpan,
					lastPartialSpanLeftSide,
					fullChosenElements,
					targetParagraph,
					listType)
				props.setAddList(false)

				if (props.isAuthor) {
					setTimeout(() => {
						const saveSegments = editorService.gatherSegmentsToSave(props.segments, props.chapterId, props.editLanguageId, props.getNextId)
						props.addOrUpdateSegments(props.personId, saveSegments)
						props.setSaveWorkSpaceTime(new Date())
						props.setChangeCounts(0)
					}, 1000)
				} else {
					setTimeout(() => props.getWorkEditReviewFilled(), 1000)
				}

			} else if (applyList === 'TEXTSELECT') {
				updateConvertAddListEdit('ChoseListType', null, props.languageId, null, listType)
			}
			setSubmitSuccess(false)
			onClose()
		}
	}

	const getNumberedListStyle = () => {
		if (listType === 'NUMBEREDLIST' || listType === 'NUMBEREDSTRAIGHT' || listType === 'ATTORNEYLIST' || listType === 'ALPHABETSTRAIGHT') {
			return styles.imageLabelChosen
		} else {
			return styles.imageLabel
		}
	} 

	return (
    <div className={classes(styles.overlay, openListModal === editorDivId ? styles.show : styles.hide)}>
			<div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
				<div className={styles.background} ref={containerRef}>
					<div className={styles.titleWhite}>
						Numbered or Unordered List
					</div>
					<RadioGroup
						data={applyOptions || [{id: '', label: ''}]}
						name={`applyList`}
						labelClass={styles.textWhite}
						initialValue={applyList || ''}
						personId={props.personId}
						onClick={(event) => handleSetApplyList(event)}/>
					<div className={styles.row}>
						<div className={styles.column}>
							<div className={styles.row}>
								<div className={listType === 'BULLETLIST' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetListType('BULLETLIST')}>
									<div className={listType === 'BULLETLIST' || listType === 'BULLETSTRAIGHT' ? styles.imageLabelChosen : styles.imageLabel}>Bullet list</div>
									<div>
										<img src={BulletList} height={60} className={styles.imageBorder}/>
									</div>
								</div>
							</div>			
							<div className={styles.rowTopSpace}>
								<div className={listType === 'BULLETSTRAIGHT' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetListType('BULLETSTRAIGHT')}>
									<img src={BulletStraight} height={60} className={styles.imageBorder}/>
								</div>
							</div>			
						</div>
						<div style={{marginRight: '10px'}}>
							<div className={getNumberedListStyle()}>Numbered list</div>
							<div className={styles.row}>
								<div className={styles.column}>
									<div className={styles.row}>
										<div className={listType === 'NUMBEREDLIST' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetListType('NUMBEREDLIST')}>
											<img src={NumberedList} height={60} className={styles.imageBorder}/>
										</div>
									</div>			
									<div className={styles.rowTopSpace}>
										<div className={listType === 'NUMBEREDSTRAIGHT' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetListType('NUMBEREDSTRAIGHT')}>
											<img src={NumberedStraight} height={60} className={styles.imageBorder}/>
										</div>
									</div>			
								</div>
								<div className={styles.column}>
									<div className={styles.row}>
										<div className={listType === 'ATTORNEYLIST' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetListType('ATTORNEYLIST')}>
											<img src={AttorneyList} height={60} className={styles.imageBorder}/>
										</div>
									</div>			
									<div className={styles.rowTopSpace}>
										<div className={listType === 'ALPHABETSTRAIGHT' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetListType('ALPHABETSTRAIGHT')}>
											<img src={AlphabetStraight} height={60} className={styles.imageBorder}/>
										</div>
									</div>			
								</div>
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.row}>
								<div className={listType === 'NONELIST' ? styles.imageSectionChosen : styles.imageSection} onClick={() => handleSetListType('NONELIST')}>
									<div className={listType === 'NONELIST' ? styles.imageLabelChosen : styles.imageLabel}>None</div>
									<div>
										<img src={NoneList} height={60} className={styles.imageBorder}/>
									</div>
								</div>
							</div>			
						</div>
					</div>
					<div className={styles.buttonsCenter}>
						<div className={styles.buttonPosition}>
							<span className={styles.cancelButton} onClick={onCancel}>
								Cancel
							</span>
							<ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={submitSuccess} />
						</div>
					</div>					
				</div>
			</div>
			<MessageModal
				show={openSentenceRangeMessage} handleClose={() => setOpenSentenceRangeMessage(false)}
				explain={`In order to convert text to a list, I will turn on the 'Add List' edit option and return you to the editing page so you can click on the first sentence. Then click on the end icon that is the range you want for the list.`}
				isConfirmType={true}
				onClick={() => { setOpenSentenceRangeMessage(false); props.setAddList(true); onClose() }} />
		</div>	
	)
}

export default ListChangeModal
