import React from 'react'
import { useLocation } from "react-router-dom";
import AssessmentSolutionDisplay from '../AssessmentSolutionDisplay'
import AssessmentStudentResponseSetting from '../../Assessment/AssessmentStudentResponseSetting'
import classes from 'classnames'
import globalStyles from '../../../utils/globalStyles.module.css'
import Icon from '../../Icon'
import MediaListViewer from  '../../Media/MediaListViewer'
import PointsDisplay from '../../PointsDisplay'
import QuestionLabel from '../../QuestionLabel'
import RadioGroupToggle from '../../RadioGroupToggle'
import styles from './AssessmentMultipleChoice.module.css'

/*
  The learnerAnswer and the correctAnswer should not be used at the same time.  The correctAnswer comes through when it is the content manager.
    The learnerAnswer comes through when it is the learner taking the text.
    But then again, it is going to be necessary to show the correct answer with the learner answer.  In that case, we'll have to use TextDisplay
    such as "[ ANSWER ]" in green to show the correct answer when the learnerAnswer is present.
*/

function AssessmentMultipleChoice(props) {
  const {
    assessmentCorrect,
    assessmentQuestionId,
    assignmentId,
    bigTextDisplay,
    className = "",
    disabledMessage,
    isTeacher,
    mediaBinaries,
    nameKey,
    onBlurScore,
    onClick,
    userPersonId,
    question = {},
    score,
    setScore,
  } = props

  const location = useLocation();
  const isLearnerPage = location.pathname.indexOf('assessmentLearner') > -1
  const isCorrectPage = location.pathname.indexOf('assessmentCorrect') > -1
  const isItemSetupPage = location.pathname.indexOf('assessmentQuestion') > -1 || location.pathname.indexOf('assessmentItemSetup') > -1

  const sendAssessmentAnswer = (value) => {
    if (onClick) onClick(userPersonId, question.assessmentQuestionId, value, assignmentId)
  }

  const handleLearnerAnswer = (value) => {
    if (onClick) onClick(userPersonId, question.assessmentQuestionId, value, assignmentId)
  }

  //let correct = (assessmentCorrect && assessmentCorrect.length > 0 && assessmentCorrect.filter(m => m.assessmentQuestionId === question.assessmentQuestionId)[0]) || {}
  let isAssessmentCorrected = assessmentCorrect?.assessmentId

  let alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
  let data = []
  let correctOne = ''

  question?.answers?.length > 0 && question.answers.forEach((m, i) => {
    //if (m.isCorrect) correctOne = m.assessmentQuestionAnswerOptionId

    data.push({
      label: <div className={styles.column}>
                <div>{alpha[i] + '. ' + (m.answerText || '')}</div>
                <MediaListViewer
                  recordType={'AssessmentQuestion'}
                  recordId={assessmentQuestionId}
                  section={'answer'}
                  index={i}
                  userPersonId={userPersonId}
                  runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
                  mediaBinaries={mediaBinaries} />
              </div>,
      id: m.assessmentQuestionAnswerOptionId,
      correction: question?.learnerAnswer?.isSubmitted
        ? question.correctAnswer === m.assessmentQuestionAnswerOptionId || m.isCorrect
          ? "correct"
          : question?.learnerAnswer?.isCorrect
            ? ""
            : "wrong"
        : ''
    })
  })

  return (
    <div className={classes(className, styles.container)}>
      <QuestionLabel label={'Multiple Choice'} subLabel={`Choose only one answer`} />
      <div className={classes(styles.row, styles.questionLine)}>
        {assessmentCorrect && question?.learnerAnswer?.assessmentLearnerAnswerId
          ? question?.learnerAnswer?.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question.sequence}.
        </div>
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{question.questionText}</div>
      </div>
      <MediaListViewer
        recordType={'AssessmentQuestion'}
        recordId={assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      <AssessmentStudentResponseSetting question={question} />
      <PointsDisplay 
        assessmentCorrect={assessmentCorrect}
        assessmentQuestionId={assessmentQuestionId}
        className={styles.littleRight} 
        isTeacher={question.isOwner}
        onBlurScore={onBlurScore}
        pending={!question.learnerAnswer || question.learnerAnswer?.pendingCorrection}
        pointsPossible={question.pointsPossible} 
        score={score} 
        setScore={setScore} 
        studentPersonId={question?.learnerAnswer?.userPersonId} />
      <RadioGroupToggle
        data={data}
        name={nameKey || `multipleChoice`}
        disabled={disabledMessage || question?.learnerAnswer?.isSubmitted}
        disabledMessage={disabledMessage}
        horizontal={false}
        titleClass={bigTextDisplay ? globalStyles.bigText : ''}
        labelClass={bigTextDisplay ? globalStyles.bigText : ''}
        className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.radio)}
        initialValue={(isItemSetupPage && correctOne) || question?.learnerAnswer?.learnerAnswer}
        personId={userPersonId}
        onClick={disabledMessage
          ? () => disabledMessage()
          : isItemSetupPage ? sendAssessmentAnswer : handleLearnerAnswer
        } />
      <MediaListViewer
        label={`Student's response`}
        recordType={`AssessmentLearnerAnswer${question?.learnerAnswer?.personId}`}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      {(isTeacher || assessmentCorrect) &&
        <AssessmentSolutionDisplay question={question} userPersonId={userPersonId} className={styles.solutionDisplay} />
      }
    </div>
  )
}

export default AssessmentMultipleChoice