import React from 'react'
import styles from './EditorFontNameSizeBold.module.css'

function EditorFontNameSizeBold(props) {
	const {
    fontNameSize = {}
	} = props

  const translateToRounded = (fontSize) => {
    const number = parseFloat(fontSize)
    const roundedNumber = Math.round(number)
    return `${roundedNumber}px`
  }

  const getFontFamily = () => {
    if (fontNameSize?.fontFamily) {
      if (fontNameSize.fontFamily.indexOf(',')) {
        const fontFamilies = fontNameSize.fontFamily.split(',')
        return fontFamilies[0]
      }
      return fontNameSize.fontFamily
    }
    return ''
  }

	return (
    <div className={styles.fontDisplay}>
      <div>
        <span className={styles.italicsGray}>bold:</span>
        <span className={styles.labelPosition}>{!fontNameSize?.fontFamily ? '' : fontNameSize?.isBold ? 'Yes' : 'No'}</span>
      </div>
      <div>
        <span className={styles.italicsGray}>font:</span>
        <span className={styles.labelPosition}>{getFontFamily()}</span>
      </div>
      <div>
        <span className={styles.italicsGray}>size:</span>
        <span className={styles.labelPosition}>{!fontNameSize?.fontFamily ? '' : translateToRounded(fontNameSize?.fontSize)}</span>
      </div>
    </div>
  )
}

export default EditorFontNameSizeBold