import React, { useState } from 'react'
import styles from './AssessmentEssayKeyword.module.css'
import classes from 'classnames'
import InputText from '../../InputText'
import EditTable from '../../EditTable'
import SelectSingleDropDown from '../../SelectSingleDropDown'
import Icon from '../../Icon'
import { createConfirmToastAuto } from '../../../services/queryClient'

function AssessmentEssayKeyword(props) {
  const {
    addKeywordPhrase,
    className = "",
    keywordCountAccuracy,
    keywordPhrases,
    removeKeywordPhrase,
    updateKeywordCountAccuracy,
  } = props

  const [keywordPhrase, setKeywordPhrase] = useState()

  const handleAddKeywordPhrase = () => {
    if (keywordPhrase) {
      addKeywordPhrase(keywordPhrase)
      setKeywordPhrase('')
    }
  }

  const handleEnterKey = (event) => {
    event.key === "Enter" && handleAddKeywordPhrase()
  }

  const handleRemoveOpen = (keywordIndex) => { 
    createConfirmToastAuto(`<div>Are you sure you want to delete this keyword phrase?</div>`, () => removeKeywordPhrase(keywordIndex))
  }

  let headings = [{}, {}]

  let data = keywordPhrases && keywordPhrases.length > 0 && keywordPhrases.map((keyPhrase, i) =>
    [
      {
        value: <div onClick={() => handleRemoveOpen(i)}>
          <Icon pathName={'cross_circle'} premium={true} fillColor={'maroon'} className={styles.icon} />
        </div>
      },
      { value: <div className={styles.label}>{keyPhrase}</div> },
    ]
  )

  data = data && data.length > 0 ? data : [[{ value: '' }, { value: <div className={styles.noRecords}>No keywords or phrases entered</div>, colSpan: 4 }]]

  let keywordCounts = keywordPhrases && keywordPhrases.length > 0 && keywordPhrases.map((acc, i) => (
    { id: i + 1, label: i + 1 }
  ))

  return (
    <div className={classes(className, styles.container)}>
      <div className={styles.instructions}>
        If you enter at least one keyword or phrase and choose at least <br/>
        one keyword for grading, then this essay will be graded automatically.  <br />
        Otherwise, grading will be done manually.<br />
      </div>
      <div className={styles.row}>
        <InputText
          id={`keywordPhrase`}
          name={`keywordPhrase`}
          size={"medium-long"}
          onEnterKey={handleEnterKey}
          whiteText
          label={"Keyword"}
          instructionsBelow={true}
          value={keywordPhrase || ''}
          onChange={(event) => setKeywordPhrase(event.target.value)} />
        <div className={classes(styles.link, styles.row, styles.topPosition)} onClick={handleAddKeywordPhrase}>
          <Icon pathName={'plus'} className={styles.iconSmall} fillColor={'lightgreen'} />
          <div className={styles.moreTop}>Add</div>
        </div>
      </div>
      <EditTable labelClass={styles.tableLabelClass} headings={headings} data={data} noCount={true} />
      {keywordPhrase && data && data.length > 0 &&
        <div>
          <SelectSingleDropDown
            id={`keywordCountAccuracy`}
            name={`keywordCountAccuracy`}
            label={"How many keywords does the student need to enter to get full credit?"}
            value={keywordCountAccuracy || ''}
            options={keywordCounts}
            className={styles.moreBottomMargin}
            height={`short`}
            onChange={updateKeywordCountAccuracy} />
        </div>
      }
    </div>
  )
}

export default AssessmentEssayKeyword