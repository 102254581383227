import React, { useState, useEffect, useRef } from 'react'
import styles from './VideoCameraUploadModal.module.css'
import { useMediaQuery } from 'react-responsive'
import { useAppContext } from '../../../../AppContext.js'
import classes from 'classnames'
import ButtonWithIcon from '../../ButtonWithIcon'
import FilmOldCamera from '../../../assets/CameraVideo/FilmOldCamera.png'
import FilmOldCameraLight from '../../../assets/CameraVideo/FilmOldCameraLight.png'

function VideoCameraUploadModal(props) {
  const {
    isOpen,
    setIsOpen,
    submitVideoCamera,
  } = props

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const { pageVisitor } = useAppContext()

  const [isSmartPhone, setIsSmartPhone] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [videoFile, setVideoFile] = useState(null)

  const videoRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const recordedChunksRef = useRef([])

  useEffect(() => {
    setIsSmartPhone(isSmartphone())
    return () => {
      setVideoFile(prevState => null)
    }
  }, [])

  const stopExistingStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject
      const tracks = stream.getTracks()
      tracks.forEach(track => track.stop())
      videoRef.current.srcObject = null
    }
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()
    }
  }

  useEffect(() => {
    if (isOpen) {
      const startWebcam = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true })
          if (videoRef.current) {
            videoRef.current.srcObject = stream
          }
        } catch (err) {
          console.error('Error accessing the webcam: ', err)
        }
      }

      startWebcam()
    } else {
      stopExistingStream()
    }

    return () => stopExistingStream()
  }, [isOpen])

  const startRecording = () => {
    const stream = videoRef.current.srcObject
    if (!stream) return

    recordedChunksRef.current = []
    const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' })

    mediaRecorder.ondataavailable = event => {
      if (event.data.size > 0) {
        recordedChunksRef.current.push(event.data)
      }
    }

    mediaRecorder.onstop = () => {
      const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' })
      const videoFile = new File([blob], `${Date.now()}.webm`, {
        type: 'video/webm',
      })
      setVideoFile(videoFile)
    }

    mediaRecorder.start()
    mediaRecorderRef.current = mediaRecorder
    setIsRecording(true)

    // Stop recording after 10 seconds
    setTimeout(() => {
      if (mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop()
        setIsRecording(false)
      }
    }, 10000)
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()
      setIsRecording(false)
    }
  }

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background}>
          Video Recording
          {!isSmartPhone && (
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <video
                ref={videoRef}
                autoPlay
                style={{
                  width: '300px',
                  height: '388px',
                  objectFit: 'cover',
                  border: '1px solid black',
                  marginRight: '20px',
                }}
              />
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <img src={FilmOldCamera} height={'100'} width={'auto'} alt={'camera'} className={`${styles.image} ${!isRecording ? styles.showCamera : styles.hideCamera}`} />
                <img src={FilmOldCameraLight} height={'100'} width={'auto'} alt={'camera'} className={`${styles.image} ${isRecording ? styles.showCamera : styles.hideCamera}`} />
                {videoFile && (
                  <div>
                    <video
                      controls
                      src={URL.createObjectURL(videoFile)}
                      style={{ width: '300px', marginTop: '100px' }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={() => setIsOpen(false)}>
                Cancel
              </span>
              <ButtonWithIcon
                label={isRecording ? 'Stop Recording' : 'Start Recording'}
                addClassName={styles.maxWidth}
                icon={'checkmark0'}
                onClick={() => isRecording ? stopRecording() : startRecording()}
                submitSuccess={false} />
              <ButtonWithIcon
                label={'Submit'}
                icon={'checkmark0'}
                onClick={() => { submitVideoCamera(videoFile); setIsOpen(false); setTimeout(() => setVideoFile(prevState => null), 500) }}
                disabled={!videoFile}
                submitSuccess={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Utility function to detect if the user is on a smartphone
const isSmartphone = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return true
  }

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return true
  }

  return false // Assume desktop if not mobile
}

export default VideoCameraUploadModal
