import React, { useState, useEffect } from "react"
import styles from './ImageScroller.module.css'
import TheAuthorRules from "../../assets/landing/TheAuthorRules.png"
import OldWordProcessors from '../../assets/landing/OldWordProcessors.png'
import NewWritingProcessor from '../../assets/landing/NewWritingProcessor.png'
//import Unreasonable from '../../assets/landing/Unreasonable.png'
import classes from 'classnames'

const images = [TheAuthorRules, OldWordProcessors, NewWritingProcessor]

export default function ImageScroller() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isPaused, setIsPaused] = useState(false)

  useEffect(() => {
    if (isPaused) return; // Stop automatic scrolling when paused

    // Determine the duration for the current image
    const duration = currentIndex === images.length - 1 ? 10000 : 6000; // Last image: 6s, others: 3s

    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, duration);

    return () => clearTimeout(timer);
  }, [currentIndex, isPaused]);

  const handleDotClick = (index) => {
    setCurrentIndex(index)
    setIsPaused(true)
  }

  return (
    <div>
      <div className={styles.imageContainer}>
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Slide ${index + 1}`}
            className={classes(styles.image, index === currentIndex ? styles.active : index < currentIndex ? styles.inactive : "")}
          />
        ))}
      </div>
      <div className={styles.dotsContainer}>
        {images.map((_, index) => (
          <div
            key={index}
            className={classes(styles.dot, index === currentIndex ? styles.active : "")}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
    </div>
  )
}
