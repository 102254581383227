import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styles from './LoginDesktopView.module.css'
import LogoSlogan from '../../components/LogoSlogan'
import Logo from "../../assets/logos/penspring_reg.png";
import LoginControls from '../../components/LoginControls'
import ImageScroller from '../../components/ImageScroller'
import OneFJefFooter from '../../components/OneFJefFooter'
import MarketingEditForumClub from '../../components/MarketingEditForumClub'
import MarketingFrontPageSteps from '../../components/MarketingFrontPageSteps'
import YouTube from '../../assets/YouTube.png'
import Unreasonable from '../../assets/landing/Unreasonable.png'

//import TheAuthorRules from "../../assets/landing/TheAuthorRules.png"
import ChooseMultipleSentence from "../../assets/landing/Final/MultipleEditors.png"
import AddDeleteEdits from "../../assets/landing/Final/EditControls.png"
import EditReport from "../../assets/landing/Final/EditReport.png"
import MoveSentences from "../../assets/landing/Final/MoveSentences.png"
import AIvsCreativeWriting from "../../assets/landing/Final/AIvsCreativeWriting.png"
import OutlineReorder from "../../assets/landing/Final/OrderedListSequence.png"
import OutlineAddDelete from "../../assets/landing/Final/OutlineEdits.png"
import LanguageVersions from "../../assets/landing/Final/LanguageVersions.png"

function LoginDesktopView(props) {
  const { loginData } = props
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const isSmallerMobile = useMediaQuery({ query: '(max-width: 727px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1200px)' })

  const setExplanation = (header, video, time, explanation) => {
    return (
      <div className={isSmallerMobile ? styles.optionMobile : styles.option}>
        <div className={styles.rowSpace}>
          <div className={styles.subLabel}>
            {header}
          </div>
          <div>
            <a href={`/video/${video}`} target='penspringVideo' className={styles.youtubeChoice}>
              <img src={YouTube} className={styles.youtube} />
              <div className={styles.videoTime}>{time}</div>
            </a>
          </div>
        </div>
        <div className={styles.explanation}>{explanation}</div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {!isTablet && 
        <div className={styles.centeredLogo}>
          <div className={styles.logoSlogan}>
            <LogoSlogan larger />
          </div>
          <div className={styles.loginPosition}>
            <LoginControls loginData={loginData} showLoginControl={props.showLoginControl} {...props} />
          </div>
        </div>
      }
      {isTablet &&
        <div style={{marginBottom: '40px'}}>
          <LogoSlogan larger />
          <LoginControls loginData={loginData} showLoginControl={props.showLoginControl} {...props} />
        </div>
      }
      <div className={styles.centerItem}>
        <div>
          <img src={Unreasonable} alt="unreasonable" className={styles.subImage} />
        </div>
        <ImageScroller />
        {/* <img src={TheAuthorRules} alt="Author Rules" className={isSmallerMobile ? styles.image : styles.responsiveImage} /> */}
        <MarketingFrontPageSteps isTablet={isTablet} />
        <MarketingEditForumClub isTablet={isTablet} />
      </div>
      {/* <div className={styles.imageText}>
        <img src={isSmallestMobile ? MultipleEditorsSliced_mobile : MultipleEditorsSliced} alt="MultipleEditors" className={isSmallerMobile ? styles.image : styles.responsiveImage}/>
      </div> */}
      {/* <div className={styles.centered}>
        <QuickSend isMobile={is500Mobile} />
      </div> */}
      <hr/>
      
      {setExplanation('multiple editors', 'MultipleEditors', '3:18', `Any sentence that has been edited will have a slight background color. When you click on that sentence, the sentence versions will appear on the 
          left side panel.You can vote-up or vote-down.You can choose that sentence for yourself and continue to refine it.`)}
      <div className={styles.mySlides}>
        <div className={styles.slideshowText}></div>
        <img src={ChooseMultipleSentence} alt="logo" style={{ width: '90%', marginLeft: '10px' }} />
      </div>
      {setExplanation('edit controls', 'EditControls', '2:03', `The editor's view will change as if they are clobbering the author's original text. Symbols will be left behind to show where 
        changes were made. On the right side, the author's version as well as any other editors' versions will be displayed according to their changes.`)}
      <div className={styles.mySlides}>
        <div className={styles.slideshowText}></div>
        <img src={AddDeleteEdits} alt="logo" style={{ width: '90%', marginLeft: '10px' }} />
      </div>
      {setExplanation('edit reports', 'EditReport', '2:15', <div>The report of the sentences affected by the editors' efforts are revealed 
        in <img src={Logo} alt={'penspring'} style={{ height: '18px', position: 'relative', top: '3px' }} />. Editors that follow the first editor 
        can vote-up and vote-down their sentences as well as choose to refine the sentence or give an entirely different perspective that the author can choose 
        from. There is a count of the votes that an editor has cast. One intriguing count in particular may be how many sentences the author has accepted.</div>)}
      <div className={styles.mySlides}>
        <div className={styles.slideshowText}></div>
        <img src={EditReport} alt="logo" style={{ width: '90%', marginLeft: '10px' }} />
      </div>
      {setExplanation('move sentences', 'MoveSentences', '1:01', `When an editor chooses to move sentences or paragraphs from one location to another, icons are left behind to 
        mark the beginning and the end of the move. A third icon shows the target of the move. The sentences will move for the editor so that they can continue to refine
        the text. But for the author and other editors, the sentences are left in place but marked with the icons.`)}
      <div className={styles.mySlides}>
        <div className={styles.slideshowText}></div>
        <img src={MoveSentences} alt="logo" style={{ width: '90%', marginLeft: '10px' }} />
      </div>
      {setExplanation('a.i. vs creative writing', 'AIvsCreativeWriting', '1:33', `See how a document has evolved over time! You can click on the graph to see the state of the
        document at that time, including the edits pending. You can also see the entire history of any given sentence that has been changed over time. This can prove the
        creative writing effort that a student has given which cannot be cheated by the use of artificial intelligence.`)}
      <div className={styles.mySlides}>
        <div className={styles.slideshowText}></div>
        <img src={AIvsCreativeWriting} alt="logo" style={{ width: '90%', marginLeft: '10px' }} />
      </div>
      {setExplanation('change list sequence', 'OutlineOrderedList', '1:14', <div>The outline is controlled by <img src={Logo} alt={'penspring'} style={{ height: '18px', position: 'relative', top: '3px' }} /> which 
      allows an editor to modify the order of a list, such as alphabetically order or by order of priority. The author can accept this edit and continue to use the same reorder 
      tool to make their own changes.</div>)} 

      <div className={styles.mySlides}>
        <div className={styles.slideshowText}></div>
        <img src={OutlineReorder} alt="logo" style={{ width: '90%', marginLeft: '10px' }} />
      </div>
      {setExplanation('outline edits', 'OutlineEdits', '2:10', `And there are other edit controls specific to ordered or unordered lists. An editor can suggest a different level of
        an outline item. Or, a new item can be added. An editor can even enter in many items in a new sequence. A tab icon will show how many times the editor has tabbed backwards
        or forwards as an edit for the author to consider.`)}
      <div className={styles.mySlides}>
        <div className={styles.slideshowText}></div>
        <img src={OutlineAddDelete} alt="logo" style={{ width: '90%', marginLeft: '10px' }} />
      </div>
      {setExplanation('language versions', 'LanguageVersions', '2:41', <div>Multiple translators can work on the same document and check each others' work. If the author returns to a native 
        language version and makes an update, <img src={Logo} alt={'penspring'} style={{ height: '18px', position: 'relative', top: '3px' }} /> keeps track of each sentence that
        was updated. The translators will be prompted with a list to jump to each changed sentence so that they can see the previous version of that sentence in both languages and
        consider an updated translation. Now, you've got to admit that that is cool!</div>)}
      <div className={styles.mySlides}>
        <div className={styles.slideshowText}></div>
        <img src={LanguageVersions} alt="logo" style={{ width: '90%', marginLeft: '10px' }} />
      </div>
      <div className={styles.centerFooter}>
        <OneFJefFooter />
      </div>
      {/* {!isMobile && 
        <div className={isMobile ? styles.freeSmallMobile : styles.freeSmall}>
          <div>The use of penspring is free for one author, two editors and five documents.</div>
          <div>For $10 per month, you get 5 authors, 25 editors and 50 documents.</div>
          <div>For $50 per month, you get 10 authors, 250 editors and 500 documents.</div>
          <div>For unlimited licenses and corporate site licenses, please contact us:</div>
          <div><a mailto={'jef@penspring.com'}>jef@penspring.com</a></div>
        </div>
      }
      {isMobile &&
        <div className={isMobile ? styles.freeSmallMobile : styles.freeSmall}>
          <div>The use of penspring is free for one author, two editors and five documents. For $10 per month, you get 1000 authors, 1000 editors and 1000 documents. For unlimited licenses and corporate site licenses, please contact us:</div>
          <div><a mailto={'jef@penspring.com'}>jef@penspring.com</a></div>
        </div>
      } */}
    </div>
  )
}

export default LoginDesktopView
