import React, { useState } from 'react'
import styles from './RecordButtonsSilver.module.css'
import TapeReel from '../TapeReel'
function RecordButtonsSilver(props) {
  const {
    audioControl,
    setIsOpen,
    startRecording,
    stopRecording,
    title,
  } = props

  const [chosen, setChosen] = useState('stop')
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [intervalId, setIntervalId] = useState()

  const handleStartRecording = () => {
    startRecording()
    setProgress(0)
    const id = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 20) {
          clearInterval(id)
          stopRecording()
          return 20 // Ensure progress doesn't exceed 20
        }
        return prevProgress + 1 // Increment progress
      })
    }, 1000)
    setIntervalId(id)
  }

  return (
    <div className={styles.mainDiv}>
      <div className={styles.titleText}>
        Voice Recording
      </div>
      <div className={styles.labelText}>
        for {title}
      </div>
      <div className={styles.row}>
        <TapeReel isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
        <div className={styles.progressSeconds}>{progress} of 20 seconds</div>
      </div>
      <form className={styles.mainForm}>
        <label className={styles.label}>
          <input 
            type="radio" 
            name="band" 
            value="record" 
            className={styles.input} 
            checked={chosen === 'record'} 
            onChange={() => { }}
            onClick={() => { setChosen('record'); setIsPlaying(true); handleStartRecording(); }}/>
          <span className={styles.spanLift}>RECORD</span>
        </label>
        <label className={styles.label}>
          <input 
            type="radio" 
            name="band" 
            value="stop" 
            className={styles.input} 
            checked={chosen === 'stop'} 
            onChange={() => { }}
            onClick={() => { setChosen('stop'); setIsPlaying(false); stopRecording(); clearInterval(intervalId); }} />
          <span className={styles.spanLift}>STOP</span>
        </label>
        <label className={styles.label}>
          <input 
            type="radio" 
            name="band" 
            value="play" 
            className={styles.input} 
            checked={chosen === 'play'} 
            onChange={() => { }}
            onClick={() => { 
              setIsPlaying(true)
              setChosen('play');
              clearInterval(intervalId);
              setTimeout(() => setIsPlaying(false), progress);
              if (chosen === 'record') {
                stopRecording(); 
                setTimeout(() => audioControl.current.play(), 200)
              } else {
                audioControl.current.play();
              } 
            }} /><span className={styles.spanLift}>PLAY</span>
        </label>
        <label className={styles.label}>
          <input 
            type="radio" 
            name="band" 
            value="save" 
            className={styles.input} 
            checked={chosen === 'save'} 
            onChange={() => {}}
            onClick={() => { 
              if (chosen === 'record') {
                stopRecording();
                setTimeout(() => audioControl.current.play(), 200)
              } else {
                setIsOpen(false); 
              }
              setIsPlaying(false);
              setChosen('stop'); 
              clearInterval(intervalId); 
            }} /><span className={styles.spanLift}>SAVE</span>
        </label>            
        <div className={styles.cancelButton} onClick={() => { setIsOpen(false); setChosen('stop'); clearInterval(intervalId); setIsPlaying(false); }} tabIndex={0}>
          Close
        </div>
      </form>
    </div>
  )
}

export default RecordButtonsSilver