import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router'
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './EditorInviteNameView.module.css';
import InputText from '../../components/InputText';
import InputTextArea from '../../components/InputTextArea';
import InvitesPending from '../../components/InvitesPending';
import Accordion from '../../components/Accordion';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import WorkFilter from '../../components/WorkFilter';
import WorkSummaryLine from '../../components/WorkSummaryLine';
import MessageModal from '../../components/MessageModal';
import Checkbox from '../../components/Checkbox';
import OneFJefFooter from '../../components/OneFJefFooter';
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import classes from 'classnames'
import { formatPhoneNumber } from '../../utils/numberFormat.js';
import { useAppContext } from "../../../AppContext.js";

function EditorInviteNameView(props) {
	const {
		personId,
		updateFilterByField,
		clearFilters,
		workSummaries,
		editorInviteName,
		editorInviteWorkAssign,
		editorInvitePending,
		deleteInvite,
		acceptInvite,
		resendInvite,
		filterScratch,
		workFilterOptions,
		saveNewSavedSearch,
		works,
		groupOptions
	} = props;

	const navigate = useNavigate()
  const params = useParams()
  const { pageVisitor } = useAppContext();

	const [errorPhone, setErrorPhone] = useState('');
	const [errorEmailAddress, setErrorEmailAddress] = useState('');
	const [isSending, setIsSending] = useState(false)
	const [errorFirstName, setErrorFirstName] = useState('')
	const [inviteMessage, setInviteMessage] = useState('')
	const [contactMatches, setContactMatches] = useState([])
	const [isShowingModal, setIsShowingModal] = useState(false)
	const [worksGranted, setWorksGranted] = useState([])
	const [groupChosen, setGroupChosen] = useState()
	const [user, setUser] = useState({
		firstName: '',
		lastName: '',
		emailAddress: '',
		phone: '',
	})

	useEffect(() => {
		if (editorInviteName && editorInviteName.firstName)
			setUser({
				firstName: editorInviteName.firstName,
				lastName: editorInviteName.lastName,
				emailAddress: editorInviteName.emailAddress,
				phone: editorInviteName.phone,
			});
	}, [props.editorInviteName])

	useEffect(() => {
		setGroupChosen(params.groupChosen)
    pageVisitor({ p: 'EditorInviteNameView', n: 'group was chosen' })
	}, [params.groupChosen])

	const handleMessageChange = (event) => {
		setInviteMessage(event.target.value)
	}

	const findContactMatches = (emailAddress, phone) => {
		const {contacts} = props;
		if (phone && phone.length === 10) {
			setContactMatches(contacts && contacts.length > 0 && contacts.filter(m => m.phone && m.phone.indexOf(phone) > -1));
		}
	}

	const handleWorkGranted = (event, workId) => {
		event.stopPropagation()
		//event.preventDefault()
		let newWorksGranted = [...worksGranted]
		if (newWorksGranted.indexOf(workId) > -1) {
			newWorksGranted.splice(newWorksGranted.indexOf(workId), 1)
		} else {
			newWorksGranted.push(workId)
		}
		setWorksGranted(newWorksGranted)
    pageVisitor({ p: 'EditorInviteNameView', n: 'Granted access to work' })
	}

	const hasWorkGranted = (workId) => {
		if (worksGranted) {
			for (let i = 0; i < worksGranted.length; i++) {
				if (String(worksGranted[i]).toLowerCase() === String(workId).toLowerCase()) return true
			}
		}
		return false;
	}

	const changeUser = (event) => {
		const field = event.target.name
		let update = {...user}
		update[field] = event.target.value
		if (field === "firstName") setErrorFirstName('')
		if (field === "emailAddress") {
			findContactMatches(event.target.value, '')
			setErrorEmailAddress('')
			update.emailAddress = event.target.value.replace(/ /g, '')
		}
		if (field === "phone") {
			findContactMatches('', event.target.value)
			update.phone = update.phone.replace(/\D+/g, '')
			setErrorPhone('')
		}
		setUser(update)
	}

	const processForm = (event) => {
		event && event.preventDefault();
		let hasError = false;

		if (!user.firstName) {
			hasError = true;
			setErrorFirstName("First name required.")
		}

		if (!user.emailAddress) {
			hasError = true
			setErrorEmailAddress(`Email address is required.`)
		}

		if (!validateEmailAddress(user.emailAddress)) {
			hasError = true
			setErrorEmailAddress(`The email address is the wrong format.`)
		}

		if (user.phone && ('' + user.phone).replace(/\D/g, '').length !== 10) {
			hasError = true
			setErrorPhone(`The phone number entered is not 10 digits`)
		}

		if (!hasError) {
			if (!(worksGranted && worksGranted.length > 0) && (!groupChosen || groupChosen === 0)) {
				setIsShowingModal(true)
			} else {
				submitSend();
        pageVisitor({ p: 'EditorInviteNameView', n: 'Process form: submit invite' })
			}
		}
	}

	const submitSend = () => {
		const {sendEditorInvite, owner_personId} = props;
		let friendEditor = {
			firstName: user.firstName,
			lastName: user.lastName,
			emailAddress: user.emailAddress,
			phone: user.phone,
			inviteMessage,
			groupId: groupChosen && groupChosen !== '0' ? groupChosen : ''
		}

		let grantedWorkAssign = []

		worksGranted && worksGranted.length > 0 && worksGranted.forEach(workId => {
			let option = {}
			let work = workSummaries && workSummaries.length > 0 && workSummaries.filter(f => f.workId === workId)[0]
			if (work) {
				option.workId = workId
				option.chapterIdList = work.chapterOptions && work.chapterOptions.length > 0 && work.chapterOptions.reduce((acc, c) => acc && acc.length > 0 ? acc.concat(c.chapterId) : [c.chapterId], [])
				option.languageIdList = [work.languageId_current]
				grantedWorkAssign.push(option)
			}
		})

		sendEditorInvite(owner_personId, friendEditor, grantedWorkAssign, () => navigate(-1)) //, () => navigate("/giveAccessToEditors"));  Don't go to another page.  Just stay.
		setIsSending(true)
		setUser({ firstName: '', lastName: '', emailAddress: '', phone: '', inviteMessage: ''});
		setInviteMessage('');
		setWorksGranted([])
		setIsShowingModal(false)
	}

	const handleSelectAllWorks = () => {
		if (hasAllChecked()) {
			setWorksGranted([])
      pageVisitor({ p: 'EditorInviteNameView', n: 'Granted access to all works' })
		} else {
			//let allWorks = works && works.length > 0 && works.reduce((acc, m) => acc = acc && acc.length > 0 ? acc.concat[m.workId] : [m.workId], [])
			let allWorks = []
			works && works.length > 0 && works.forEach((m) => {
				allWorks.push(m.workId)
			})
			setWorksGranted(allWorks)
      pageVisitor({ p: 'EditorInviteNameView', n: 'Granted access to work one-by-one' })
		}
	}

	const hasAllChecked = () => {
		return (worksGranted && worksGranted.length > 0 && works && works.length > 0 && worksGranted.length === works.length) || false
	}

	const handleFormatPhone = () => {
		if (user && user.phone && ('' + user.phone).replace(/\D/g, '').length !== 10) {
			setErrorPhone(`The phone number entered is not 10 digits`);
		} else if (user && formatPhoneNumber(user.phone)) {
			setUser({ ...user, phone: formatPhoneNumber(user.phone) });
			setErrorPhone('')
		}
	}

	const validateEmailAddress = (emailAddress) => {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
		return re.test(emailAddress)
	}

	return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={styles.titleLine}>
          <span className={globalStyles.pageTitle}>Invite New Editor</span>
        </div>
				<InvitesPending 
					invites={editorInvitePending} 
					personId={personId} 
					deleteInvite={deleteInvite}
					sendFirstNotifications={props.sendFirstNotifications}
					getInvitesPending={props.getInvitesPending}
					acceptInvite={acceptInvite}
					resendInvite={resendInvite}/>
					
        <div className={styles.background}>
          <div className={styles.formLeft}>
            <InputText
              size={"medium"}
              name={"firstName"}
              label={"First name"}
              value={user.firstName}
              onChange={changeUser}
              inputClassName={styles.textWhite}
							required={true}
							whenFilled={user.firstName}
              error={errorFirstName}/>
            <InputText
              size={"medium"}
              name={"lastName"}
              label={"Last name"}
              value={user.lastName}
              inputClassName={styles.textWhite}
							required={true}
							whenFilled={user.lastName}
              onChange={changeUser}/>
            <hr/>
						<div className={styles.row}>
							<InputText
								size={"medium-long"}
								name={"emailAddress"}
								label={"Email address"}
								value={user.emailAddress}
								onChange={changeUser}
								onBlur={(event) => validateEmailAddress(event.target.value)}
								inputClassName={styles.textWhite}
								labelClassName={styles.textWhite}
								required={true}
								whenFilled={user.emailAddress}
								error={errorEmailAddress} />
						</div>
            <div className={styles.row}>
              <InputText
                size={"medium-short"}
                name={"phone"}
                label={"Cell phone number (optional for text messages)"}
                value={user.phone}
                onChange={changeUser}
								onBlur={handleFormatPhone}
                inputClassName={styles.textWhite}
                labelClassName={styles.textWhite}
                instructionClassName={styles.offWhite}
                instructions={``}
                error={errorPhone}/>
            </div>
            <hr/>
          </div>
          <div className={styles.textarea}>
            <InputTextArea label={'Description (optional)'}
                           value={inviteMessage}
                           onChange={(event) => handleMessageChange(event)}
                           //inputClassName={styles.messageBox}
                           labelClass={styles.textWhite} />
          </div>
          {/*{contactMatches && contactMatches.length > 0 &&*/}
          {/*  <div>*/}
          {/*    <span className={styles.matches}>Found existing contacts: {contactMatches.length}</span><br/>*/}
          {/*    {contactMatches.map( (contactSummary, i) => {*/}
          {/*      return (*/}
          {/*        <Accordion title={'contactSummary'} key={i}>*/}
          {/*          /!*contactSummary={contactSummary} expanded={false} key={i} className={styles.accordionTitle} onTitleClick={() => {}}*!/*/}
          {/*          /!*  showAssignWorkToEditor={false} onContactClick={setContactCurrentSelected} personId={personId}>*!/*/}
          {/*          <ContactSummary key={i*100} summary={contactSummary} className={styles.contactSummary} showAccessIcon={true}*/}
          {/*                          userPersonId={contactSummary.personId} noShowTitle={true}/>*/}
          {/*        </Accordion>*/}
          {/*      )*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*}*/}

          {works && works.length > 10 &&
            <Accordion title={'Document search options'}>
              {/*<AccordionItem expanded={false} filterScratch={filterScratch} filterOptions={workFilterOptions} savedFilterIdCurrent={savedFilterIdCurrent}*/}
              {/*        updateSavedSearch={updateSavedSearch} deleteSavedSearch={deleteSavedSearch} chooseSavedSearch={chooseSavedSearch}*/}
              {/*        updateFilterByField={updateWorkFilterByField} updateFilterDefaultFlag={updateFilterDefaultFlag} personId={owner_personId}*/}
              {/*        clearFilters={clearFilters} >*/}
              <WorkFilter personId={personId} workFilter={filterScratch} className={styles.workFilter}
                          updateFilterByField={updateFilterByField}
                          clearFilters={clearFilters} saveNewSavedSearch={saveNewSavedSearch} hideSourceStatus={true}
                          savedSearchOptions={workFilterOptions}/>
            </Accordion>
          }

					<hr style={{ marginTop: '20px' }} />
					<div>
						<div className={styles.row}>
							<div className={styles.selectList}>
								<SelectSingleDropDown
									id={`groupList`}
									label={'Existing group'}
									value={groupChosen || ''}
									options={groupOptions}
									height={`medium-short`}
									labelClass={styles.textWhite}
									onChange={(event) => setGroupChosen(event.target.value)} />
							</div>
							<div className={classes(styles.moreTop, styles.row)}>
								<div className={classes(styles.spaceForOr, styles.leftSpace)}>OR</div>
								<div className={styles.link} onClick={() => navigate(`/groupAddNew`)}>Create a new group</div>
							</div>
						</div>
					</div>
          <hr/>
					{(!groupChosen || groupChosen === '0') && 
						<div>
							{works && works.length > 0 &&
								<div className={styles.text}>
									<div className={styles.row}>
										<div className={styles.spaceForOr}>OR</div>
										<div className={styles.littleTop}>
											Choose one or more checkboxes to grant this editor access to your documents.
										</div>
									</div>
									<div className={styles.selectOrClearAll}>
										<a onClick={() => handleSelectAllWorks(true)} className={styles.textLabel}> set all </a> |
										<a onClick={() => handleSelectAllWorks(false)} className={styles.textLabel}> clear all </a>
									</div>
								</div>
							}
							{workSummaries && workSummaries.map((w, i) => {
								let chaptersChosen = [];
								let languagesChosen = [];
								let workAssign = editorInviteWorkAssign && editorInviteWorkAssign.length > 0 && editorInviteWorkAssign.filter(m => m && m.workId === w.workId)[0];
								if (workAssign) {
									chaptersChosen = workAssign.chapterIdList;
									languagesChosen = workAssign.languageIdList;
								}

								return (
									<div className={styles.workSummary} key={i}>
										<Checkbox id={w.workId}
															label={''} keyIndex={'c' + i}
															checked={hasWorkGranted(w.workId)}
															defaultValue={false}
															onClick={(event) => handleWorkGranted(event, w.workId)}
															labelClass={styles.textWhite}
															checkboxClass={styles.checkboxAll}/>
										<div onClick={(event) => handleWorkGranted(event, w.workId)}>
											<WorkSummaryLine work={w} nameLength={150} lightColorText keyIndex={i} includeEditors includeDates showDocumentDetails labelWhite/>
										</div>
									</div>
								)
							})}
							{!(workSummaries && workSummaries.length > 0) && 
								<div className={styles.text}>There are not any documents to share, yet.</div>
							}
						</div>
					}
				</div>
        <div className={styles.buttonsCenter}>
          <div className={styles.buttonPosition}>
            <span className={styles.cancelButton} onClick={() => navigate(-1)}>
              Cancel
            </span>
            <div className={styles.buttonWidth}>
              <ButtonWithIcon icon={'chat_bubbles'} onClick={processForm} label={'Send'} submitSuccess={isSending}/>
            </div>
          </div>
        </div>
        <OneFJefFooter/>
				<MessageModal show={isShowingModal} handleClose={() => setIsShowingModal(false)} heading={`Send Invitation without Giving Access?`}
                      explain={`It appears that you have not chosen a group nor granted access to one or more documents? Do you want to continue anyway?`}
                      isConfirmType={true} onClick={submitSend}/>
      </div>
		</div>
	);
}

export default EditorInviteNameView;
