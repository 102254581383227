import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import globalStyles from '../../utils/globalStyles.module.css'
import styles from './MyProfileView.module.css'
import InputText from '../../components/InputText'
import Icon from '../../components/Icon'
import penspringSmall from '../../assets/logos/penspring_reg.png'
import classes from 'classnames'
import OneFJefFooter from '../../components/OneFJefFooter'
import {formatPhoneNumber} from '../../utils/numberFormat.js'
import ButtonWithIcon from "../../components/ButtonWithIcon"
import MessageModal from "../../components/MessageModal"
import LoginControls from "../../components/LoginControls"
import LogoSlogan from "../../components/LogoSlogan"
import TextOptin from '../../components/TextOptIn'
import { useAppContext } from "../../../AppContext.js";

function MyProfileView(props) {
  const {
    createNew, 
    emailAddress, 
    firstName, 
    friendInvitation, 
    getEditorInviteDecline, 
    inviteCode, 
    lastName, 
    phone,
    setMyProfileNew, 
    userProfile, 
  } = props
  const navigate = useNavigate()
  const { pageVisitor } = useAppContext();
  
  const [isInit, setIsInit] = useState(false)
  const [showPenspringAccount, setShowPenspringAccount] = useState(!inviteCode)
  const [isShowingModal_remove, setIsShowingModal_remove] = useState(false)
  const [user, setUser] = useState('')
  const [errorFirstName, setErrorFirstName] = useState('')
  const [errorLastName, setErrorLastName] = useState('')
  const [errorUsername, setErrorUsername] = useState('')
  const [errorPhone, setErrorPhone] = useState('')
  const [errorEmailAddress, setErrorEmailAddress] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [usernameExists, setUsernameExists] = useState(false)
  const [waitingForVerifyResponse, setWaitingForVerifyResponse] = useState(false)
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [missingFields, setMissingFields] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  //These would be coming from the params due to the editor-invite-code page of a response to an invite link.
  useEffect(() => {
    //If this has parameers coming through for the fistName and all
    if (!user) {
      setUser({
        inviteCode,
        firstName: firstName ? firstName.replace('null', '').replace('undefined', '').replace('EMPTY', '') : '',
        lastName: lastName ? lastName.replace('null', '').replace('undefined', '').replace('EMPTY', '') : '',
        username: '',
        emailAddress: emailAddress ? emailAddress.replace('null', '').replace('undefined', '').replace('EMPTY', '') : '',
        phone: phone === 'EMPTY' ? '' : phone,
        password: '',
      })
      pageVisitor({ p: 'MyProfileView', n: `An existing user is editing their record. Is invite code? ${inviteCode}` })
    }
  }, [firstName, lastName, emailAddress, inviteCode])

  useEffect(() => {
    if (!isInit) {
      if (friendInvitation) {
        setUser({
          inviteCode: friendInvitation.friendInvitationId,
          googleId: '',
          socialMediaToken: '',
          firstName: !friendInvitation.fname || friendInvitation.fname === 'null' ? '' : friendInvitation.fname,
          lastName: !friendInvitation.lname || friendInvitation.lname === 'null' ? '' : friendInvitation.lname,
          username: '',
          emailAddress: !friendInvitation.email || friendInvitation.email === 'null' ? '' : friendInvitation.email,
          phone: !friendInvitation.phone || friendInvitation.phone === 'null' ? '' : friendInvitation.phone,
          password: '',
        })
        setIsInit(true)
      } else if (userProfile && userProfile.username) {
        setUser(userProfile)
        setIsInit(true)
      }
    }
  }, [inviteCode, userProfile && userProfile.username])

  useEffect(() => {
    if (waitingForVerifyResponse) {
      if (props.usernameToVerify === 'FOUND') {
        setErrorUsername(`Username already exists`)
        setUsernameExists(true)
      } else {
        setErrorUsername('')
        setUsernameExists(false)
      }
      setWaitingForVerifyResponse(false)
    }
    pageVisitor({ p: 'MyProfileView', n: 'Verifying username' })
  },[props.usernameToVerify])

  const changeUser = (event) => {
    let newUser = Object.assign({}, user)
    let field = event.target.name
    let value = event.target.value
    newUser[field] = value
    field === "firstName" && setErrorFirstName('')
    field === "lastName" && setErrorLastName('')
    if (field === "username") {
      setErrorUsername('')
      newUser.username = newUser.username.replace(/ /g, '')
    }
    field === "password" && setErrorPassword('')
    field === "phone" && setErrorPhone('')
    if (field === "emailAddress") {
      setErrorEmailAddress('')
      newUser.emailAddress = newUser.emailAddress.replace(/ /g, '')
    }
    setUser(newUser)
  }

  const handleUsernameCheck = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (!user.username) {
      setErrorUsername(`A username is required`)
      return
    }
    checkUsername(user.personId)
    pageVisitor({ p: 'MyProfileView', n: 'Checking username' })
  }

  const checkUsername = (runFunction=()=>{}) => {
    setUsernameExists(false)
    setWaitingForVerifyResponse(true)
    Promise.all([props.verifyUsername(user.personId, user.username)]).then(runFunction)
  }

  const processForm = (event) => {
    event && event.preventDefault()
    setSubmitSuccess(true)
    //let data = new FormData()
    //data.append('file', selectedFile)
    let newMissingFields = ""

    if (!user.firstName) {
      setErrorFirstName("First name is required." )
      newMissingFields += "<br/>First name"
    }

    if (!user.lastName) {
      setErrorLastName(`Last name is required`)
      newMissingFields += "<br/>Last name"
    }

    if ((!user.emailAddress || !validateEmailAddress(user.emailAddress)) || (!user.phone || (user.phone && ('' + user.phone).replace(/\D/g, '').length !== 10))) {
      if (!user.emailAddress) {
        setErrorEmailAddress(`Email address is required`)
        newMissingFields += "<br/>Email address"
      }

      if (!validateEmailAddress(user.emailAddress)) {
        setErrorEmailAddress(`Wrong format for email address`)
        newMissingFields += "<br/>Wrong format for email address"
      } else if (user.phone && ('' + user.phone).replace(/\D/g, '').length !== 10) {
        setErrorPhone(`The phone number entered is not 10 digits`)
        newMissingFields += "<br/>The phone number entered is not 10 digits"
      } else {
        setErrorPhone(`An email address or a cell number is required`)
        newMissingFields += "<br/>An email address or a cell number is required"
      }
    }

    if (!user.username) {
      setErrorUsername(`Username is required`)
      newMissingFields += "<br/>Username"
    }

    if ((createNew === 'c' || createNew === 'p' || createNew === 'reset' || createNew === 'grp') && !user.password) { //c is create New, p is password is missing on an existing record. reset is for forgotten password. grp is for group
      setErrorPassword(`Password is required`)
      newMissingFields += "<br/>Password"
    }

    if (usernameExists || props.usernameToVerify === 'FOUND') {
      setErrorUsername(`Username already exists`)
      newMissingFields += "<br/>Username already exists"
    }

    if (!newMissingFields) {
      setMyProfileNew(user)
    } else {
      setMissingFields('<div>' + newMissingFields + '</div>')
      setShowMissingFields(true)
    }
    setSubmitSuccess(false)
    pageVisitor({ p: 'MyProfileView', n: 'Submitting record' })
  }

  const handleEnterKey = (event) => {
    event.key === "Enter" && processForm()
  }

  const validateEmailAddress = (emailAddress) => {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
    return re.test(emailAddress)
  }

  const handleFormatPhone = () => {
    if (user && user.phone && ('' + user.phone).replace(/\D/g, '').length !== 10) {
      setErrorPhone(`The phone number entered is not 10 digits`)
    } else if (user && formatPhoneNumber(user.phone)) {
      setUser({...user, phone: formatPhoneNumber(user.phone)})
      setErrorPhone('' )
    }
  }

  const handleDeclineInvitation = () => {
    getEditorInviteDecline(inviteCode)
    setIsShowingModal_remove(false)
    pageVisitor({ p: 'MyProfileView', n: `Declined invitation: ${inviteCode}` })
  }

  const showCreateLabel = () => 
    <div onClick={() => setShowPenspringAccount(!showPenspringAccount)} className={styles.createAccount}>
      or create a new 
      <img src={penspringSmall} height={17} className={styles.penspringSmall} />
      account</div>

  return (
    <section className={styles.container}>
      <div className={styles.mainDiv}>
        <LogoSlogan />
        <LoginControls loginData={user} showLoginControl={props.showLoginControl} {...props} />
        <div className={globalStyles.pageTitle}>
          {/* This is here with showCreateLabel because in the case of an inviteCode present, the user could also log in with Google or Facebook as an option. So the penspring is an option as well. */}
          {/* e refers to create new.  p refers to an existing record but missing a password which can be set on this page. */}
          {createNew === 'c' || createNew === 'p' || (createNew && createNew.toLowerCase() === 'grp') ? inviteCode ? showCreateLabel() : `Create a New Account` : `My Profile`}
        </div>
        <hr />
        {/* {showPenspringAccount &&  */}
          <div className={styles.centered}>
            <div className={styles.centered}>
              <div className={styles.nameFull}>
                <InputText
                  size={"medium-left"}
                  name={"firstName"}
                  label={`First name`}
                  value={user.firstName || ''}
                  onChange={changeUser}
                  onEnterKey={handleEnterKey}
                  required={true}
                  whenFilled={user.firstName}
                  inputClassName={styles.inputNoBold}
                  error={errorFirstName} />

                <InputText
                  size={"medium-right"}
                  name={"lastName"}
                  label={`Last name`}
                  value={user.lastName || ''}
                  onEnterKey={handleEnterKey}
                  inputClassName={styles.inputNoBold}
                  required={true}
                  whenFilled={user.lastName}
                  onChange={changeUser}
                  error={errorLastName}  />
              </div>

              <InputText
                size={"medium"}
                name={"emailAddress"}
                label={`Email address`}
                value={user.emailAddress || ''}
                onChange={changeUser}
                onBlur={validateEmailAddress}
                onEnterKey={handleEnterKey}
                height={`medium`}
                inputClassName={styles.inputNoBold}
                required={true}
                whenFilled={user.emailAddress}
                error={errorEmailAddress} />

              <InputText
                inputType={'tel'}
                size={'medium'}
                name={'phone'}
                label={`Cell phone (for text messaging) optional`}
                value={user.phone || ''}
                onChange={changeUser}
                onBlur={handleFormatPhone}
                onEnterKey={handleEnterKey}
                height={`medium`}
                inputClassName={styles.inputNoBold}
                error={errorPhone} />

              <div className={styles.authentication}>
                <div className={styles.row}>
                  <InputText
                    label={`Username`}
                    name={`username`}
                    size={'medium'}
                    value={user.username || ''}
                    onChange={changeUser}
                    onBlur={(event) => handleUsernameCheck(event)}
                    required={true}
                    whenFilled={user.username}
                    autoComplete='nope'
                    error={errorUsername} />

                  <div className={classes(globalStyles.link, styles.row, styles.muchTop)}>
                    <Icon pathName={props.usernameToVerify === 'FOUND' ? 'warning' : 'checkmark0'}
                          className={styles.icon} premium={props.usernameToVerify !== 'FOUND'}
                          fillColor={props.usernameToVerify === 'FOUND' ? 'red' : 'green'}/>
                    {/*<Button label={'Verify'} onClick={(event) => handleUsernameCheck(event)} addClassName={styles.verifyButton} />*/}
                  </div>
                </div>
                {(createNew === 'c' || createNew === 'p' || createNew === 'reset' || createNew === 'grp') && //c means create new.
                  <InputText
                    size={'medium'}
                    isPasswordType={true}
                    label={`Password`}
                    name={`password`}
                    value={user.password || ''}
                    onChange={changeUser}
                    onEnterKey={handleEnterKey}
                    height={`medium`}
                    inputClassName={styles.inputNoBold}
                    autocomplete='nope'
                    required={true}
                    whenFilled={user.password}
                    error={errorPassword}/>
                }
              </div>
              {/*<InputFile label={`Add a profile picture`} isCamera={true} onChange={handleInputFile} isResize={true}/>*/}
              {/*{user.profilePictures && user.profilePictures.length > 0 && user.profilePictures.map((p, i) =>*/}
              {/*		<img key={i} width={100} src={p.fileUrl} alt={'profile'}/>*/}
              {/*)}*/}
            </div>
          </div>
          {/* } */}
          <div className={styles.textOptinPosition}>
            <TextOptin />
          </div>
          <div>
            <div className={styles.buttonCenter}>
              {props.friendInvitation && props.friendInvitation.inviteCodeShort && props.createNew !== 'reset' && props.createNew !== 'grp' &&
                <ButtonWithIcon label={'Decline'} icon={'trash2'} onClick={() => setIsShowingModal_remove(true)} addClassName={styles.floatLeft} changeRed/>
              }
              <span className={styles.cancelButton} onClick={() => navigate(-1)}>
                Cancel
              </span>
              {/* {showPenspringAccount &&  */}
                <ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={() => checkUsername(() => processForm())} changeOrange submitSuccess={submitSuccess} />
              {/* } */}
            </div>
            {!createNew &&
              <div className={styles.resetPassword}>
                <a href={`/resetPassword`}
                  className={styles.resetPassword}>
                  Reset password
                </a>
              </div>
            }
            <MessageModal 
              show={isShowingModal_remove} 
              handleClose={() => setIsShowingModal_remove(false)} 
              heading={`Decline this invitation?`}
              explainJSX={`Are you sure you want to decline this invitation?`} 
              isConfirmType={true}
              onClick={handleDeclineInvitation} />
            <MessageModal 
              show={missingFields} 
              handleClose={() => setMissingFields('')}
              onClick={() => setMissingFields('')} 
              heading={`Missing Information`}
              explain={missingFields} />
          </div>
        <OneFJefFooter />
      </div>
    </section>
  );
}

export default MyProfileView;
