import React from 'react';
import styles from './CommentAndTranslationSaveInfo.module.css';
import ButtonWithIcon from '../ButtonWithIcon'
import classes from "classnames";
import Icon from '../Icon'

export default ({
  comment,
  currentElement,
  handleCommentOpen,
  handleCommentSave,
  handleEditIconLegendOpen,
  isShowCommentMessage,
  isTranslation,
  keepCommentOn,
  saveTranslation,
  segment,
  setComment,
  setIsShowCommentMessage,
  setTranslation,
  translation,
}) => {

  const clearComment = () => {
    setComment('')
    if (segment && segment.comment) handleCommentSave()
  }

  return (
    <div className={styles.container}>
      {isTranslation &&
        <div className={styles.outerBorder}>
          <span className={styles.myEdit}>{`My translation (${isTranslation.languageName}):`}</span>
          <textarea
            id={'translationDiv'}
            rows={5} cols={52}
            value={translation || ''}
            onChange={(event) => setTranslation(event.target.value)}
            spellCheck="false"
            className={styles.editorDiv}></textarea>
        </div>
      }
      <div className={styles.rowSpread}>
        <div className={classes(styles.row, styles.topMargin)}>
          {currentElement &&
            <div className={styles.row} onClick={handleCommentOpen} title={'Make a comment about this sentence or edit.'}>
              <Icon pathName={'comment_text'} premium={true} fillColor={'white'} />
              <div className={styles.addComment}>{isShowCommentMessage ? 'close comment' : 'add comment'}</div>
            </div>
          }
        </div>
        {isTranslation && currentElement &&
          <div className={styles.row}>
            {/* I'm having trouble with the cursor when the next button is pressed and the cursor is at the beginning of that next sentence, something is forcing the cursor to jump back one to the previous sentence  */}
            {/* {isTranslation && <a className={styles.prevButton} onClick={() => handleNextOrPrevButton('PREV')}><Icon pathName={'arrow_right'} premium={false} className={styles.flipHorizontal}/></a>}
              {isTranslation && <a className={styles.nextButton} onClick={() => handleNextOrPrevButton('NEXT')}><Icon pathName={'arrow_right'} premium={false} className={styles.noFlipButSize}/></a>} */}
            <ButtonWithIcon icon={'checkmark0'} label={'Save'} onClick={saveTranslation} addClassName={styles.buttonWidth} smaller />
          </div>
        }
        <div className={styles.buttonRow} onClick={handleEditIconLegendOpen} title={`See explanations for the icon actions.`}>
          <Icon pathName={`info`} className={styles.launchButton} />
        </div>
      </div>
      <div className={(currentElement && keepCommentOn) || (isShowCommentMessage && currentElement) ? styles.showCommentBox : styles.hideCommentBox}>
        <span className={styles.myEdit}>{`Comment:`}</span>
        <textarea
          rows={5} cols={52}
          value={comment || ''}
          onChange={(event) => setComment(event.target.value)}
          className={styles.editorDiv}></textarea>
        <div className={styles.rowPadding}>
          {!keepCommentOn && <a className={styles.cancelCommentButton} onClick={() => setIsShowCommentMessage(false)}>close</a>}
          <a className={styles.cancelCommentButton} onClick={clearComment}>clear</a>
          <ButtonWithIcon icon={'checkmark0'} label={'Save'} onClick={handleCommentSave} addClassName={styles.buttonWidth} smaller />
        </div>
      </div>
    </div>
  )
};
