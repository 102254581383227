import React, { useState, useEffect } from 'react';
import styles from './DiscussionEntryOptions.module.css';
import Icon from '../Icon'
import classes from 'classnames'
import ToastInputText from '../ToastInputText'
import WorkSummaryLine from '../WorkSummaryLine'
import { guidEmpty } from '../../utils/GuidValidate'
import { createConfirmToastAuto } from '../../services/queryClient.js'
import * as editorService from '../../services/editor-dom'

const DiscussionEntryOptions = (props) => {
	const {
		keyIndex,
		currentEditorDiv,
		editorDivId,
		isAuthor,
		setCurrentEditorDiv,
		workSummary,
	} = props

  const [showDiscussionSubject, setShowDiscussionSubject] = useState()
	const [canDelete, setCanDelete] = useState()

  useEffect(() => {
    const hasReply = props.workSummaries?.length > 0 && props.workSummaries.filter(m => m.discussionReplyToDiscussionEntryId === workSummary.discussionEntryId)[0]
    setCanDelete(!(hasReply && hasReply.workId))
  }, [props.workSummaries, workSummary])

	const handleVoteEntry = (type) => {
		const voteEntry = {
			assignmentId: workSummary.assignmentId,
			discussionEntryId: workSummary.discussionEntryId,
			voterPersonId: props.personId,
			voteUpFlag: type === 'VoteUp' ? 1 : 0,
			voteDownFlag: type === 'VoteDown' ? 1 : 0,
			voteTrollFlag: type === 'VoteTroll' ? 1 : 0,
			trollPersonId: type === 'VoteTroll' ? workSummary.authorPersonId : guidEmpty,
			languageId: props.editLanguageId,
			entryDate: '1901-01-01',
		}
		props.voteDiscussionReply(voteEntry)
	}

	const hideZero = (value) => {
		if (!(!value || value === 0)) return value
	}

	const handleAddDiscussionReply = (title, isFirstLevelPost) => {
		//Notice that for a post (in contrast to a general reply to the entry in question) we are getting the original or teacher's discussion for the discussionEntryId as far
		//  as the parent/child relationship is concerned.
		const runFunction = (latestWorkSummary) => 
			setTimeout(() => {
        editorService.setCursorInFirstSpan(latestWorkSummary.chapterId_current)
        const editorDivId = editorService.getEditorDivIdFromChapterId(latestWorkSummary.chapterId_current)
				if (editorDivId) setCurrentEditorDiv(editorDivId)
        props.setGlobalWorkSummary(latestWorkSummary)
			}, 2000)
		props.addDiscussionReply(workSummary.assignmentId, props.editLanguageId, props.workSummaries[0].discussionEntryId, title, isFirstLevelPost, runFunction)
	}

	const confirmDiscussionEntryAdd = () => {
		createConfirmToastAuto('<div>Are you sure you want to add a reply to this discussion entry?</div>', () => handleAddDiscussionReply('', ''))
	}

	const confirmDiscussionEntryDelete = () => {
		createConfirmToastAuto('<div>Are you sure you want to delete this discussion entry?</div>', () => props.deleteDiscussionReply(workSummary.assignmentId, props.editLanguageId, workSummary.discussionEntryId))
	}

	return (
		<div className={styles.row} key={keyIndex}>
			{!isAuthor &&
				<div className={styles.discussionLink} onClick={() => confirmDiscussionEntryAdd()}>
					<Icon pathName={'reply_all'} premium={true} />
					<div className={styles.textSpace}>reply</div>
				</div>
			}
			<div className={styles.discussionLink} onClick={() => { setShowDiscussionSubject(!showDiscussionSubject); setCurrentEditorDiv();}}>
				{/* <Icon pathName={'plus'} premium={false} fillColor={'forestgreen'}/> */}
				<div className={styles.plus}>+</div>
				<div className={styles.textSpace}>New post</div>
			</div>
      {isAuthor && canDelete && 
				<div className={styles.discussionLink} onClick={() => confirmDiscussionEntryDelete()}>
					<Icon pathName={'trash2'} premium={true} fillColor={'pink'} />
					<div className={styles.textSpace}>delete</div>
				</div>
			}
			<div className={editorDivId === currentEditorDiv ? styles.discussionLinkRed : styles.discussionLink} onClick={() => setCurrentEditorDiv(editorDivId === currentEditorDiv ? '' : editorDivId)}>
				<Icon pathName={'pencil0'} premium={true}  />
				<div className={styles.textSpace}>{editorDivId === currentEditorDiv ? 'done' : 'edit'}</div>
			</div>
			{!isAuthor &&
				<span className={styles.row}>
					<a onClick={() => handleVoteEntry('VoteUp')} title={'Vote up +1'} className={styles.voteIcon}>
							<Icon pathName={`thumbs_up0`} premium={true}/>
					</a>
					<span className={styles.voteCount}>{hideZero(workSummary.discussionVoteUpCount)}</span>
	      </span>
			}
			{!isAuthor &&
				<span className={styles.row}>
					<a onClick={() => handleVoteEntry('VoteDown')} title={'Vote down +1'} className={styles.voteIcon}>
						<Icon pathName={`thumbs_down0`} premium={true} />
					</a>
					<span className={styles.voteCount}>{hideZero(workSummary.discussionVoteDownCount)}</span>
				</span>
			}
			{!isAuthor &&
				<span className={styles.row}>
					<a onClick={() => handleVoteEntry('VoteTroll')} title={'Vote troll (obnoxious) +1'} className={classes(styles.row, styles.voteIcon)}>
						<Icon pathName={`blocked`} fillColor={'red'} className={styles.imageBlocked} />
						<Icon pathName={`user_minus0`} premium={true} className={styles.imageOverlay} />
					</a>
					<span className={styles.voteCountTroll}>{hideZero(workSummary.discussionVoteTrollCount)}</span>
				</span>
			}
			<WorkSummaryLine work={workSummary}
				showStatsOnly
				hideActionIcons
				personId={workSummary.authorPersonId}
				darkerLabel
				noFocusColor
				showDocumentDetails/>

			<ToastInputText
				show={showDiscussionSubject}
				long
				message="Add Discussion Post Subject"
				label="Subject"
				onSubmit={(title) => handleAddDiscussionReply(title, true)}
				onClose={() => setShowDiscussionSubject(false)} />
		</div>
	)
};

export default DiscussionEntryOptions