import React, {useState} from 'react'
import styles from './MediaLinks.module.css'
import classes from 'classnames'
import FileUploadModal from '../FileUploadModal'
import HandwritingEntryModal from '../HandwritingEntryModal'
import AudioRecordingModal from '../AudioRecordingModal'
import CameraPhotoUploadModal from '../CameraPhotoUploadModal'
import VideoCameraUploadModal from '../VideoCameraUploadModal'
import WebsiteLinkEntry from '../../WebsiteLinkEntry'
import { createInfoToastAuto } from '../../../services/queryClient'
import { useAppContext } from '../../../../AppContext'
import * as mediaService from '../../../services/media-files'
import MicRecorder from 'mic-recorder-to-mp3'
const recorder = new MicRecorder({ bitRate: 128 })

function MediaLinks(props) {
  const {
    audioLabel = 'File for',
    audioTitle,
    mediaBase,
    userPersonId,
    runFunction,
  } = props

  //The index is not used in all cases of the question types, but we'll send it along even if it is blank or null
  const { addMediaBinary } = useAppContext();

  const [isShowingFileUpload, setIsShowingFileUpload] = useState()
  const [isShowingHandwritingEntry, setIsShowingHandwritingEntry] = useState()
  const [isShowingAudioRecording, setIsShowingAudioRecording] = useState()
  const [isShowingCameraPhotoUpload, setIsShowingCameraPhotoUpload] = useState()
  const [isShowingVideoCameraUpload, setIsShowingVideoCameraUpload] = useState()
  const [isOpenLinkEntry, setIsOpenLinkEntry] = useState(false)

  const [blobUrl, setBlobUrl] = useState()
  
  const startRecording = () => {
    recorder.start().then(() => {
    }).catch((e) => {
      createInfoToastAuto(`<div>There was an audio error. Please try again.</div>`)
    })
  }

  const playAudio = () => {
    recorder.getMp3().then(() => {
    }).catch((e) => {
      createInfoToastAuto(`<div>There was an audio error. Please try again.</div>`)
    })
  }

  const submitFileUpload = (file) => {
    const newMedia = { ...mediaBase, fileName: file.name, fileType: file.type, mediaType: 'upload' }
    addMediaBinary(file, newMedia, runFunction)
  }
  
  const submitSketch = (base64Image) => {
    const mimeType = base64Image.match(/data:(.*?);base64/)?.[1] || 'application/octet-stream';
    const blob = mediaService.base64ToBlob(base64Image, mimeType);
    const file = new File([blob], "sketch.png", { type: mimeType });
    const newMedia = { ...mediaBase, fileName: file.name, fileType: file.type, mediaType: 'sketch' }
    addMediaBinary(file, newMedia, runFunction)
  }

  const stopRecording = () => {
    try {
      recorder.stop().getMp3().then(([buffer, blob]) => {
        const file = new File([blob], "audio.mpeg", { type: "audio/mpeg" }); // Correctly use the Blob
        const newMedia = { ...mediaBase, fileName: file.name, fileType: file.type, mediaType: 'audio' }
        addMediaBinary(file, newMedia, runFunction)
      })
    } catch (error) {
      createInfoToastAuto(`<div>There was an audio error. Please try again.</div>`)
    }
  }

  const submitCameraPhoto = (base64Image) => {
    const file = mediaService.convertPhotoBase64ToFile(base64Image)
    const newMedia = { ...mediaBase, fileName: file.name, fileType: file.type, mediaType: 'camera' }
    addMediaBinary(file, newMedia, runFunction)
  }

  const submitVideoCamera = (videoFile) => {
    const newMedia = { ...mediaBase, fileName: videoFile.name, fileType: videoFile.type, mediaType: 'video' }
    addMediaBinary(videoFile, newMedia, runFunction)
  }

  const submitWebsiteLink = (websiteLink, linkDisplayText) => {
    const newMedia = { ...mediaBase, fileName: websiteLink, fileType: '', mediaType: 'website', words: linkDisplayText }
    addMediaBinary(null, newMedia, runFunction)
  }

  return (
    <div>
      <div className={classes(styles.row, styles.linkRow)}>
        <a onClick={() => setIsShowingFileUpload(true)} className={styles.link}>
          upload
        </a>
        <div className={styles.bar}>|</div>
        <a onClick={() => setIsShowingHandwritingEntry(true)} className={styles.link}>
          by hand
        </a>
        <div className={styles.bar}>|</div>
        <a onClick={() => setIsShowingAudioRecording(true)} className={styles.link}>
          audio
        </a>
        <div className={styles.bar}>|</div>
        <a onClick={() => setIsShowingCameraPhotoUpload(true)} className={styles.link}>
          camera
        </a> 
        <div className={styles.bar}>|</div>
        <a onClick={() => setIsShowingVideoCameraUpload(true)} className={styles.link}>
          video
        </a>
        <div className={styles.bar}>|</div>
        <a onClick={() => setIsOpenLinkEntry(true)} className={styles.link}>
          website
        </a>
      </div>

      <FileUploadModal
        isOpen={isShowingFileUpload}
        userPersonId={userPersonId}
        setIsShowingFileUpload={setIsShowingFileUpload}
        submitFileUpload={submitFileUpload}
      />
      <HandwritingEntryModal
        isOpen={isShowingHandwritingEntry}
        setIsOpen={setIsShowingHandwritingEntry}
        onSubmit={submitSketch}
      />
      <AudioRecordingModal
        blobUrl={blobUrl}
        isOpen={isShowingAudioRecording}
        label={audioLabel}
        recorder={recorder}
        setIsOpen={setIsShowingAudioRecording}
        startRecording={startRecording}
        stopRecording={stopRecording}
        title={audioTitle} />

      <CameraPhotoUploadModal
        isOpen={isShowingCameraPhotoUpload}
        setIsOpen={setIsShowingCameraPhotoUpload}
        submitCameraPhoto={submitCameraPhoto}
      />
      <VideoCameraUploadModal
        blobUrl={blobUrl}
        isOpen={isShowingVideoCameraUpload}
        setIsOpen={setIsShowingVideoCameraUpload}
        submitVideoCamera={submitVideoCamera}
      />
      <WebsiteLinkEntry 
        isOpen={isOpenLinkEntry} 
        onSave={submitWebsiteLink} 
        onCancel={() => setIsOpenLinkEntry(false)} 
        anchorNode={null} />
    </div>
  )
}

export default MediaLinks