import React, {useState, useEffect} from 'react';
import styles from './EditorDivFormatControlsMobile.module.css'
import ColorPickerModal from '../ColorPickerModal'
import MobileSelectListFloat from '../../components/MobileSelectListFloat';
import InputText from '../../components/InputText';
import Icon from '../../components/Icon';
import Checkbox from '../../components/Checkbox';

function EditorDivFormatControlsMobile(props) {
	const { 
		allSelectionData,
    clearSelection,
		eraseFormatting, 
		handleFontColorChoice,
		handleFontNameChoice,
		handleFontSizeChoice,
		handleHtmlCharacterInsert,
		htmlCharacterOptions, 
		isOpenLinkEntry, 
		isTranslation,
		keyIndex,
		redo,
		saveSelection,
		setAddList,
		setFormatChoice, 
		setIsOpenLinkEntry, 
		setListChoice, 
		setParagraphAlign, 
		setParagraphIndentChoice, 
		undo, 
	} = props

	//Notice that the HeaderlessSelect in this case has a variable runFunction and currentOptionsn so that we can run the HeaderlessSelect lists which are on the desktop version while we are working with this HeaderlessSelect.
	
	const [filteredOptions, setFilteredOptions] = useState([])
	const [showFontSizeList, setShowFontSizeList] = useState(false)
	const [showHtmlCharacters, setShowHtmlCharacters] = useState(false)
	const [htmlCharactersFiltered, setHtmlCharactersFiltered] = useState()
	const [searchHtmlCharacters, setSearchHtmlCharacters] = useState()
	const [charactersChosenFavorites, setCharactersChosenFavorites] = useState([])
	const [showCharacterFilters, setShowCharacterFilters] = useState(false)
  const [characterFilters, setCharacterFilters] = useState('')
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [localChoice, setLocalChoice] = useState()

	useEffect(() => {
		let charactersChosen = localStorage.getItem('htmlCharacterEntitiesChosen')
		if (charactersChosen) setCharactersChosenFavorites(JSON.parse(charactersChosen))
		let htmlCharacterFilters = localStorage.getItem('characterFilters')
		if (htmlCharacterFilters) {
			setCharacterFilters(htmlCharacterFilters)
		} else {
			setCharacterFilters('Bullet Regular ')
			localStorage.setItem('characterFilters', 'Bullet Regular ')
		}
	}, [])

	useEffect(() => {
		if (htmlCharacterOptions?.length > 0) {
			let newSearch = []
			if (characterFilters) {
				const filters = characterFilters.trim().split(/\s+/)
				htmlCharacterOptions.forEach(option => {
					let foundMatch = false
					filters.forEach(filter => {
						if (option.tag.indexOf(filter) > -1) {
							foundMatch = true
						}
					})
					const alreadyEntered = newSearch.filter(m => m.id === option.id)[0]
					if (foundMatch && !alreadyEntered) newSearch.push(option)
				})
			}
			if (searchHtmlCharacters) {
				newSearch = newSearch.filter(m => m.description.toLowerCase().indexOf(searchHtmlCharacters.toLowerCase()) > -1 || m.id.indexOf(searchHtmlCharacters.toLowerCase()) > -1)
			}
			setHtmlCharactersFiltered(newSearch)
		}
	}, [searchHtmlCharacters, htmlCharacterOptions, characterFilters])

	const chooseCharacter = (newCharacter) => {
		let newChosen = charactersChosenFavorites?.length > 0 ? [ ...charactersChosenFavorites] : []
		let existChosen = newChosen.filter(character => character === newCharacter)[0]
		if (!existChosen) {
			newChosen.push(newCharacter)
			setCharactersChosenFavorites(newChosen)
			localStorage.setItem('htmlCharacterEntitiesChosen', JSON.stringify(newChosen))
		}
    handleHtmlCharacterInsert(newCharacter)
	}

	const deleteCharacter = (newCharacter) => {
		let newChosen = charactersChosenFavorites?.length > 0 ? [ ...charactersChosenFavorites] : []
		newChosen = newChosen.filter(character => character !== newCharacter)
		setCharactersChosenFavorites(newChosen)
		handleHtmlCharacterInsert(newChosen)
		localStorage.setItem('htmlCharacterEntitiesChosen', JSON.stringify(newChosen))
	}

	const handleCharacterFilter = (filterOption) => {
		let newFilters = characterFilters
		if (newFilters.indexOf(filterOption) > -1) {
			newFilters = newFilters.replace(filterOption, '')
		} else {
			newFilters = newFilters + ' ' + filterOption
		}
		setCharacterFilters(newFilters)
		localStorage.setItem('characterFilters', newFilters)
	}

	let toolOptions = [
		{
			id: 'UNDO',
			label: 'Undo',
			action: () => undo(),
			hideIfTranslation: false
		},
		{
			id: 'REDO',
			label: 'Redo',
			action: () => redo(),
			hideIfTranslation: false
		},
		{
			id: '',
      label: '__________________',
			action: () => { },
			hideIfTranslation: false
		},
		{
			id: 'BOLD',
			label: 'Bold',
			action: () => setFormatChoice({ formatType: 'bold', fontValue: '', allSelectionData }),
			hideIfTranslation: false
		},
		{
			id: 'ITALIC',
			label: 'Italic',
			action: () => setFormatChoice({ formatType: 'italic', fontValue: '', allSelectionData }),
			hideIfTranslation: false
		},
		{
			id: 'UNDERLINE',
			label: 'Underline',
			action: () => setFormatChoice({ formatType: 'underline', fontValue: '', allSelectionData }),
			hideIfTranslation: false
		},
		{
			id: 'STRIKEOUT',
			label: 'Line-through',
			action: () => setFormatChoice({ formatType: 'strikeout', fontValue: '', allSelectionData }),
			hideIfTranslation: false
		},
		{
			id: 'SUBSCRIPT',
			label: 'Subscript',
			action: () => setFormatChoice({ formatType: 'subscript', fontValue: '', allSelectionData }),
			hideIfTranslation: false
		},
		{
			id: 'SUPERSCRIPT',
			label: 'Superscript',
			action: () => setFormatChoice({ formatType: 'superscript', fontValue: '', allSelectionData }),
			hideIfTranslation: false
		},
		{
			id: 'ERASEFORMATTING',
			label: 'Erase formatting',
			action: () => eraseFormatting(),
			hideIfTranslation: false
		},
		{
			id: '',
      label: '__________________',
			action: () => {},
			hideIfTranslation: false
		},
		{
			id: 'FONTNAME',
			label: 'FONT NAME',
      //action: () => setShowFontNameList(true),
			action: () => {},
			hideIfTranslation: true
		}
  ]

  props.textStyleFonts?.length > 0 && props.textStyleFonts.map(m => {
    toolOptions.push({
      id: m.name,
      label: m.name,
      action: () => handleFontNameChoice(m.name),
      className: styles[`${m.name.replace(/ /g, '')}`],
      hideIfTranslation: true
    })
  })

  toolOptions = toolOptions.concat([
    {
      id: '',
      label: '__________________',
      action: () => { },
      hideIfTranslation: false
    },
		{
			id: 'FONTSIZE',
			label: 'FONT SIZE',
			action: () => setShowFontSizeList(true) ,
			hideIfTranslation: true
		}])

  const sizes = [6, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72]
  sizes.map(size => {
    toolOptions.push({
      id: `${size}px`,
      label: `${size}px`,
      action: () => handleFontSizeChoice(`${size}px`),
      hideIfTranslation: true
    })
  })

  toolOptions = toolOptions.concat([
    {
      id: '',
      label: '__________________',
      action: () => { },
      hideIfTranslation: false
    },
		{
			id: 'FONTCOLOR',
			label:  'Font color',
			action: () => handleSetTool('FONTCOLOR'),
			hideIfTranslation: true
		},
		{
			id: '',
      label: '__________________',
			action: () => { },
			hideIfTranslation: false
		},
		{
			id: 'NUMBEREDLIST',
			label: 'Convert text to a list',
			action: () => setAddList(true),
			hideIfTranslation: true
		},
		{
			id: 'HTMLCHARACTERS',
			label: 'Symbol insert',
			action: () => setShowHtmlCharacters(true),
			hideIfTranslation: true
		},
		{
			id: '',
			label: '__________________',
			action: () => { },
			hideIfTranslation: false
		},
		{
      id: '',
      label: 'Before choosing, place the\ncursor in location.',
			className: styles.instructionsInList,
      hideIfTranslation: false
		},
		{
			id: 'PARAGRAPHLEFTALIGN',
			label: 'Paragraph left align',
			action: () => setParagraphAlign('left'),
			hideIfTranslation: true
		},
		{
			id: 'PARAGRAPHCEMTERALIGN',
			label: 'Paragraph center align',
			action: () => setParagraphAlign('center'),
			hideIfTranslation: true
		},
		{
			id: 'PARAGRAPHRIGHTALIGN',
			label: 'Paragraph right align',
			action: () => setParagraphAlign('right'),
			hideIfTranslation: true
		},
		{
			id: 'PARAGRAPHJUSTIFYALIGN',
			label: 'Paragraph justify align',
			action: () => setParagraphAlign('justify'),
			hideIfTranslation: true
		},
		{
			id: 'TEXTINDENT',
			label: 'Indent',
			action: () => setParagraphIndentChoice('textindent'),
			hideIfTranslation: true
		},
		{
			id: 'MARGINLEFT',
			label: 'Block indent',
			action: () => setParagraphIndentChoice('marginleft'),
			hideIfTranslation: true
		},
		{
			id: 'MARGINRIGHT',
			label: 'Undo block indent',
			action: () => setListChoice('marginright'),
			hideIfTranslation: true
		},
		{
			id: 'INTERNETLINK',
			label: 'Internet link',
			action: () => setIsOpenLinkEntry(!isOpenLinkEntry),
			hideIfTranslation: false
		},
	])

	const handleSetTool = (event) => {
    if (event.target.value !== '') {
      const toolOption = toolOptions.filter(m => m.id === event.target.value)[0]
      if (event.target.value === 'FONTCOLOR') {
        setOpenColorPicker(true)
      } else if (toolOption) {
				toolOption.action()
			}
		}
    setLocalChoice('')
    setTimeout(() => clearSelection(), 1000)
	}

	useEffect(() => {
		let newFiltered = toolOptions
		if (isTranslation) {
			newFiltered = toolOptions.filter(m => !m.hideIfTranslation)
		}
		setFilteredOptions(newFiltered)
	}, [isTranslation])

	return (
		<div className={styles.container} key={keyIndex}>
      <select onChange={handleSetTool} title={'Fonts, styles and lists'} className={styles.selectList} value={localChoice}>
        <option value="">b i u</option>
        <option value="">[clear]</option>
        {filteredOptions?.length > 0 && filteredOptions.map((m, i) => {
          return !(isTranslation && m.hideIfTranslation) ? <option key={i} value={m.id} className={m.className}>{m.label}</option> : null
        })}
      </select>
			<MobileSelectListFloat keyIndex={'HtmlCharacters'}
				open={showHtmlCharacters}
				setOpen={setShowHtmlCharacters}
				label={'Symbol insert'}
				header={<div className={styles.listHeader}>Symbol insert</div>}
				onSelect={chooseCharacter}
				listOptions={htmlCharactersFiltered} >
				<div style={{ width: '150px', margin: '10px 5px' }}>
					<InputText
						size={'medium'}
						name={'searchHtmlCharacters'}
						label={'Search'}
						value={searchHtmlCharacters}
						onChange={(event) => setSearchHtmlCharacters(event.target.value)} />
					<div style={{ width: '220px' }}>
						{charactersChosenFavorites?.length > 0 && <div className={styles.labelSpace}>Favorites</div>}
						{charactersChosenFavorites?.length > 0 && charactersChosenFavorites.map((character, i) => {
							const option = htmlCharacterOptions.filter(m => m.id === character)[0]
							return (
								<div className={styles.rowSpace} key={`div${i}`}>
									<div dangerouslySetInnerHTML={{ __html: character + " " + option?.description }}
										onClick={() => chooseCharacter(character)}
										style={{ cursor: 'pointer', fontSize: '11pt', marginLeft: '7px' }} />
									<div className={styles.deleteTrash} onClick={() => deleteCharacter(character)}>
										<Icon pathName={'trash2'} premium={true} fillColor={'red'} />
									</div>
								</div>
							)
						})}
						{charactersChosenFavorites?.length > 0 && <hr />}
					</div>
					<div style={{ width: '220px' }}>
						<div className={styles.row}>
							<div>Filters {characterFilters.trim().split(/\s+/).length || 0}/5</div>
							<div onClick={() => setShowCharacterFilters(!showCharacterFilters)} className={styles.showHideFilter}> {showCharacterFilters ? 'hide' : 'show'}</div>
						</div>
						{showCharacterFilters &&
							<div>
								<Checkbox
									label={`Regular`}
									checked={characterFilters.indexOf('Regular') > -1}
									onClick={() => handleCharacterFilter('Regular')} />
								<Checkbox
									label={`Bullets`}
									checked={characterFilters.indexOf('Bullet') > -1}
									onClick={() => handleCharacterFilter('Bullet')} />
								<Checkbox
									label={`Extended bullets`}
									checked={characterFilters.indexOf('ExtendedBull') > -1}
									onClick={() => handleCharacterFilter('ExtendedBull')} />
								<Checkbox
									label={`Latin`}
									checked={characterFilters.indexOf('Latin') > -1}
									onClick={() => handleCharacterFilter('Latin')} />
								<Checkbox
									label={`Cyrillic`}
									checked={characterFilters.indexOf('Cyrillic') > -1}
									onClick={() => handleCharacterFilter('Cyrillic')} />
							</div>
						}
						{charactersChosenFavorites?.length > 0 && <hr />}
					</div>
				</div>
			</MobileSelectListFloat>
      <ColorPickerModal
        forceOpen={openColorPicker}
        label={''}
        labelLeftpx={'-17px'}
        onSelect={handleFontColorChoice}
        saveSelection={saveSelection} />
		</div>
	)
}

export default EditorDivFormatControlsMobile
