import React from 'react'
import { useNavigate } from 'react-router'
import styles from './AssessmentQuestionActionOptions.module.css'
import classes from 'classnames'
import SelectSingleDropDown from '../../SelectSingleDropDown'

function AssessmentQuestionActionOptions(props) {
  const {
    assessmentId,
    assessmentQuestionId,
    handleCannotChangeOpen,
    handleRemoveQuestionOpen,
    isPublished,
    reorderSequence,
    sequence,
    sequences,
  } = props

  const navigate = useNavigate()

  return (
    <div className={classes(styles.row, styles.linkRow)}>
      <a
        onClick={isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(assessmentQuestionId)}
        className={styles.link}>remove</a> |
      <a
        onClick={isPublished ? handleCannotChangeOpen : () => navigate(`/assessmentItemSetup/${assessmentId}/${assessmentQuestionId}`)}
        className={styles.link}>edit</a> |
      {!isPublished &&
        <div>
          <SelectSingleDropDown
            id={assessmentQuestionId}
            name={assessmentQuestionId}
            label={'Sequence'}
            labelLeft={true}
            value={sequence}
            noBlank={true}
            options={sequences}
            className={styles.dropdown}
            onChange={(event) => reorderSequence(assessmentQuestionId, event)} />
        </div>
      }
    </div>
  )
}

export default AssessmentQuestionActionOptions