import React from 'react';
import styles from './InputText.module.css';
import classes from 'classnames';
import Required from '../Required';
import { createInfoToastAuto } from '../../services/queryClient'

function InputText(props) {
	const {
		autoComplete,
		autoFocus,
		defaultValue,
		disabled,
		error,
		height,
		inputClassName,
    inputType,
		instructionClassName,
		instructions,
		instructionsBelow,
		isPasswordType = false,
		label,
		labelClassName,
		maxLength = 500,
		maxNumber,
		name,
		noShadow,
		numberOnly,
		onBlur,
		onChange,
		onDoubleClick = () => {},
		onEnterKey,
		placeholder,
		required = false,
		size,
		value,
		whenFilled,
    alignTextRight,
    biggerTextSize,
    whiteText,
	} = props;

	const isNumbersOnly = (event) => {
		let value = event.target.value
		if (numberOnly) {
			value = event.target.value.replace(/[^0-9]/g, '')
			event.target.value = value
		}

		if (event.target.value === 'x') {
			onChange(event);
		} else if (maxNumber && Number(event.target.value) > Number(maxNumber)) {
			createInfoToastAuto(`<div>The number you entered is greater than the maximum allowed.</div>`)
		} else {
			onChange(event);
		}
	}

	return (
		<div className={classes(styles.container, inputClassName)}>
			<div className={styles.row}>
        {label && <span htmlFor={name} className={classes(required ? styles.requiredLabelText : styles.labelText, labelClassName, whiteText ? styles.whiteText : '')}>{label}</span>}
				<Required setIf={required} setWhen={whenFilled}/>
			</div>
			<div className={instructionsBelow ? styles.column : styles.row}>
				<input
					onChange={isNumbersOnly}
					onKeyUp={onEnterKey}
          type={inputType ? inputType : isPasswordType ? `password` : `text`}
					id={name}
					name={name}
					disabled={disabled}
					autoFocus={autoFocus}
					placeholder={placeholder}
					onBlur={onBlur}
					onDoubleClick={onDoubleClick}
					maxLength={maxLength || 75}
					style={{opacity: disabled ? '.6' : '1'}}
					className={classes(disabled ? styles.lowOpacity : '', 
            alignTextRight ? styles.alignTextRight : '',
            biggerTextSize ? styles.biggerTextSize : '',
            styles[`size${height}`], noShadow ? styles.noShadow : '', 
						size === `medium-left` ? styles.cutRight : '',
						size === `medium-right` ? styles.cutLeft : '',
						size === `long` ? styles.input_long : size === `medium` ? styles.input_medium : (size === `medium-short` || size === `medium-left` || size === `medium-right`) ? styles.input_mediumShort : size === `medium-long` ? styles.input_mediumLong : size === `super-short` ? styles.input_superShort : styles.input_short)}
					value={value}
					defaultValue={defaultValue}
					autoComplete={autoComplete || 'nope'}/>
				<span className={classes(styles.instructions, instructionClassName, whiteText ? styles.whiteText : '')}>{instructions}</span>
			</div>
			{error && <div className={styles.alertMessage}>{error}</div>}
		</div>
	);
}

export default InputText;