import React, { useState, useEffect } from 'react'
import styles from './AudioPlayer.module.css'
import AudioDisplay from '../../AudioDisplay'

function AudioPlayer(props) {
  const {
    audioFile,
    keyIndex,
    label = 'Answer audio',
    userPersonId,
    studentPersonId,
  } = props

  const [answerAudioSrc, setAnswerAudioSrc] = useState(null)
  const [isInit, setIsInit] = useState(null)

  useEffect(() => {
    let url;

    if (audioFile) {
      try {
        // Check if the string is Base64-encoded or a URL
        if (audioFile.startsWith("http") || audioFile.startsWith("blob:")) {
          // Handle as a URL directly
          url = audioFile;
        } else {
          // Check if the Base64 string has the correct prefix
          const hasPrefix = audioFile.startsWith("data:audio/");
          const base64String = hasPrefix ? audioFile.split(",")[1] : audioFile;
          const mimeType = hasPrefix
            ? audioFile.split(",")[0].split(":")[1].split(";")[0]
            : "audio/mpeg";

          // Decode Base64
          const binaryString = atob(base64String);
          const byteArray = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
          }

          // Create Blob and URL
          const blob = new Blob([byteArray], { type: mimeType });
          url = URL.createObjectURL(blob);
        }

        setAnswerAudioSrc(url);
      } catch (error) {
        console.error("Error processing answer audio:", error);
      }
    }

    // Clean up the URL when the component unmounts
    return () => {
      if (url && url.startsWith("blob:")) {
        URL.revokeObjectURL(url);
      }
    };
  }, [audioFile]);

  return !audioFile ? null : (
    <div key={keyIndex}>
      {audioFile?.length > 20 &&
        <div>
          {/* <div className={styles.answerAudioFile}>
            {label}
          </div> */}
          <AudioDisplay 
            src={answerAudioSrc} 
            preload={'auto'} 
            controls="controls" 
            isSmall={true} 
            isOwner={studentPersonId === userPersonId} />
        </div>
      }
    </div>
  )
}

export default AudioPlayer