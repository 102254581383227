import React, { useCallback, useState, useRef, useEffect } from 'react'
import styles from './FileUploadModal.module.css'
import WorkListPenspringDrive from '../../WorkListPenspringDrive'
import PSLogo from '../../../assets/logos/homescreen48.png'
import PenspringSmall from '../../../assets/penspring_medium_noreg.png'
import FileUploadDropzone from '../../FileUploadDropzone'
import GoogleDrive from '../../GoogleDrive'
import ButtonWithIcon from '../../ButtonWithIcon'
import FileUploadDisplay from '../FileUploadDisplay'
import WorkSummaryLine from '../../WorkSummaryLine'
import { guidEmpty } from '../../../utils/GuidValidate'
import { useMediaQuery } from "react-responsive"
import { useAppContext } from "../../../../AppContext.js"
import classes from 'classnames'
import { createInfoToastAuto } from '../../../services/queryClient.js'

export default function FileUploadModal(props) {
  const {
    isOpen,
    userPersonId,
    setIsShowingFileUpload,
    submitFileUpload,
  } = props

  const { pageVisitor } = useAppContext()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const containerRef = useRef(null)

  const [fileLocal, setFileLocal] = useState()
  const [fileGoogle, setFileGoogle] = useState({})
  const [filePenspring, setFilePenspring] = useState() //This is just a work record
  const [googleDriveOpen, setGoogleDriveOpen] = useState(false)
  const [penspringDriveOpen, setPenspringDriveOpen] = useState(false)
  const [isFileUploadDisplayOpen, setIsFileUploadDisplayOpen] = useState(false)

  const handleClose = useCallback(() => {
    setIsFileUploadDisplayOpen(false)
    setFileLocal(null)
    setFileGoogle(null)
    setFilePenspring(null)
    setGoogleDriveOpen(false)
    setPenspringDriveOpen(false)
    setIsShowingFileUpload(false)
  }, [])

  useEffect(() => {
    return () => handleClose()
  }, [handleClose])

  useEffect(() => {
    if (fileLocal?.type || fileGoogle?.type) {
      setIsFileUploadDisplayOpen(true)
    }
  }, [fileLocal, fileGoogle])

  const acceptAndSubmitFile = () => {
    const sendFile = fileLocal?.type ? fileLocal : fileGoogle?.type ? fileGoogle : null
    if (!sendFile) {
      createInfoToastAuto(`<div>It appears that a file has not been chosen.</div>`)      
    } else {
      submitFileUpload(sendFile)
      handleClose()
    }
  }

  useEffect(() => {
    if (fileLocal?.type || fileGoogle?.type) {
      setIsFileUploadDisplayOpen(true)
    }
  }, [fileLocal, fileGoogle])

  const handleSetFileLocal = (incomingFile) => {
    if (!fileLocal) {
      const extension = incomingFile.name && incomingFile.name.length > 0 && incomingFile.name.substring(incomingFile.name.lastIndexOf('.'))
      setFileLocal(incomingFile)
      setFileGoogle({})
      setFilePenspring()
      setIsFileUploadDisplayOpen(true)
    }
  }

  const handleSetFileGoogle = (incomingFile) => {
    if (!incomingFile) {  //Then the intention here is to unchoose the previously chosen Google file to start again.
      setFileGoogle({})
    } else {
      const { blob, file } = incomingFile
      setFileGoogle({ blob, file })
    }
    setFileLocal(null)
    setFilePenspring(null)
    setPenspringDriveOpen(false)
    setIsFileUploadDisplayOpen(true)
    pageVisitor({ p: 'workAddView', n: 'Chose a Google Docs file' })
  }

  const handleSetPenspringFile = (filePenspring) => {
    setFileGoogle({})
    setFileLocal(null)
    setFilePenspring(filePenspring)
    setGoogleDriveOpen(false)
    setPenspringDriveOpen(false)
    pageVisitor({ p: 'workAddView', n: 'chose an existing penspring file' })
  }

  const processForm = () => {
    let missingFields = ''
    if (!fileLocal && !(fileGoogle && fileGoogle.file && fileGoogle.file.id) && !filePenspring) {
      missingFields += "\nPlease choose a file to upload"
    }
    if (missingFields) {
      createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`)
      pageVisitor({ p: 'FileUploadModal', n: `Error in submitting: ${missingFields}` })
    } else {
      if (fileLocal) {
        //fileData.append("file", fileLocal)
      } else if (fileGoogle && fileGoogle.file) {
        //fileData.append("file", fileGoogle.blob, fileGoogle.file.name)
      } else if (filePenspring) {
        //do something
      }

    }
  }

  
  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <div className={styles.titleRequired}>
            Choose an Existing File
          </div>
          <div>
            <FileUploadDropzone
              isMobile={isMobile}
              fileTypesMode='ALL'
              subLabel={'Only one file is allowed at a time - Microsoft Word, HTML, OpenOffice, PDF, Richtext, data and text.'}
              setOpenViewer={setIsFileUploadDisplayOpen}
              setFile={handleSetFileLocal} />

            <GoogleDrive
              fileGoogle={fileGoogle}
              handleSetFileGoogle={handleSetFileGoogle}
              setGoogleDriveOpen={setGoogleDriveOpen}
              googleDriveOpen={googleDriveOpen}
              isMobile={isMobile}
              includeGoogleOr={!isMobile}
              {...props} />

            <div className={isMobile ? styles.penspringDriveMobile : styles.penspringDrive}>
              {!isMobile &&
                <div className={styles.positionOr}>- OR -</div>
              }
              <div className={isMobile ? styles.rowPenspringMobile : styles.rowPenspring} onClick={() => setPenspringDriveOpen(!penspringDriveOpen)}>
                <img src={PSLogo} height={25} />
                <div className={styles.existingPenspring}>
                  <img src={PenspringSmall} height={19} className={styles.penspringBackground} style={{ position: 'relative', top: '3px' }} />
                </div>
                <div>file</div>
              </div>
              {filePenspring && filePenspring.workId && filePenspring.workId !== guidEmpty &&
                <div style={{ marginTop: '10px' }}>
                  <WorkSummaryLine work={filePenspring}
                    isWorkAddView={true} //This is for the Mine subfolder display on WorkAddView to choose a file (which will override showEditLink, by the way)
                    showEditLink //Because this is on the Myworks and if it is on mobile, then show the pencil at the beginning for a short cut.
                    onClick={handleSetPenspringFile}
                    personId={userPersonId}
                    nameLength={150}
                    darkerBackground={true}
                    darkerLabel
                    includeDates
                    chosenWork={filePenspring}
                    thisWorkFolderId={''}
                    showExplorerOptions
                    noFocusColor={!(true)}
                    showDocumentDetails={true}
                    // moveWorkToFolder={moveWorkToFolder}
                    // isMoveWorkId={isMoveWorkId}
                    // deleteWorkFromFolder={deleteWorkFromFolder}
                    mineOrOthers={'Mine'}
                    hasParentFolderGroup={false}
                    {...props} />
                </div>
              }
              <div className={penspringDriveOpen ? styles.showPenspringDrive : styles.hide}>
                <WorkListPenspringDrive
                  openList={penspringDriveOpen}
                  setWork={handleSetPenspringFile}
                  work={filePenspring} {...props} />
              </div>
            </div>
          </div>
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={handleClose}>
                Cancel
              </span>
              <ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={acceptAndSubmitFile} submitSuccess={false} />
            </div>
          </div>
        </div>
      </div>
      <FileUploadDisplay 
        acceptAndSubmitFile={acceptAndSubmitFile}
        file={fileLocal || fileGoogle}
        fileType={fileLocal?.type || fileGoogle?.type}
        fileUrl={fileLocal?.type || fileGoogle?.type ? URL.createObjectURL(fileLocal || fileGoogle) : null}
        isOpen={isFileUploadDisplayOpen} //This isOpen is the current FileUploadModal (this file) and then the FileUploadDisplay which is the viewer.
        setIsOpen = {(state) => { if (!state) {
            setTimeout(() => setIsFileUploadDisplayOpen(false), 0); // Force update
          } else {
            setIsFileUploadDisplayOpen(state);
          }
        }} />
    </div>
  )
}


