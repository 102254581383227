import React, { useEffect } from 'react';
import styles from './InvitationExpiredView.module.css';
import Logo from "../../assets/logos/penspring_reg.png"
import { createInfoToastAuto } from '../../services/queryClient.js'

function InvitationExpiredView(props) {

  useEffect(() => {
    createInfoToastAuto(`<div>We are checking to see if this is a valid or expired invitation.</div>`)
  }, [])

  return (
    <div style={{ marginLeft: '100px', marginTop: '40px', alignItems: 'center' }}>
      This is an invalid or expired invitation<br /><br />
      Go to<br /> <a href={'/'} style={{ color: '#0f6078', cursor: 'pointer', textDecoration: 'none' }}>
        the <img src={Logo} alt={'penspring'} style={{ height: '18px', position: 'relative', top: '4px' }} />home page</a>
    </div>
  )
}

export default InvitationExpiredView
