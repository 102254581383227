import React, {useState} from 'react';
import styles from './InputTextArea.module.css';
import classes from 'classnames';
import MessageModal from '../MessageModal';
import Required from '../Required';

function InputTextArea(props) {
	const {
    autoComplete='dontdoit', 
    autoFocus, 
    boldText, 
    columns=40, 
    defaultValue, 
    error, 
    inputClassName="", 
    instructions, 
    instructionsBelow, 
    label, 
    labelClass="", 
    maxLength=1500, 
    name, 
    onBlur, 
    onChange, 
    onEnterKey,
    placeholder, 
    required=false, 
    rows=5, 
    textareaClass,
    value, 
    whenFilled, 
    whiteText,
  } = props;

	const [isShowingModal_greaterThan, setIsShowingModal_greaterThan] = useState(false)
	const isTextLengthLimit = (event) => {
			let textLength = event.target.value && event.target.value.length
			if (textLength > maxLength ) {
					handleGreaterThanMaxOpen()
		  } else {
			  	onChange(event)
		  }
  }

	const handleGreaterThanMaxOpen = () => setIsShowingModal_greaterThan(true)
  const handleGreaterThanMaxClose = () => setIsShowingModal_greaterThan(false)

  return (
    <div className={classes(styles.container, inputClassName)}>
        <div className={styles.row}>
          {label && <span htmlFor={name} className={classes(styles.label, labelClass, required ? styles.lower : '', whiteText ? styles.whiteText : '')}>{label}</span>}
            <Required setIf={required} setWhen={whenFilled}/>
        </div>
				<div className={instructionsBelow ? styles.column : styles.row}>
						<textarea
										id={name}
										name={name}
										value={value || ''}
		                defaultValue={defaultValue}
										rows={rows}
										cols={columns}
										onChange={isTextLengthLimit}
										autoFocus={autoFocus}
		                placeholder={placeholder}
										onBlur={onBlur}
										onKeyUp={onEnterKey}
		                maxLength={maxLength || 100}
										className={classes(styles.commentTextarea, textareaClass, (boldText ? styles.bold : ''))}
										autoComplete={autoComplete}>
						</textarea>
            <span className={styles.instructions}>{instructions}</span>
        </div>
        {error && <div className={styles.alertMessage}>{error}</div>}
        <MessageModal show={isShowingModal_greaterThan} handleClose={handleGreaterThanMaxClose} heading={`Text length limit`}
           explainJSX={`The text you entered is longer than the maximum allowed.`} onClick={handleGreaterThanMaxClose} />
    </div>
  )
}

export default InputTextArea