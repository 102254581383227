import React, { useState } from 'react';
import styles from './MyGroupsView.module.css';
import * as guid from '../../utils/GuidValidate.js';
import WorkFilter from '../../components/WorkFilter';
import GroupFileTreeSubContents from '../../components/GroupFileTreeSubContents';
import GroupSectionControl from '../../components/GroupSectionControl';
import Icon from '../../components/Icon';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import Checkbox from '../../components/Checkbox';
import OneFJefFooter from '../../components/OneFJefFooter';
import classes from 'classnames';
import { useAppContext } from "../../../AppContext.js";

const searchGroupCount = 10  //Until the search controls are shown - but either mine or others have to be greater than this number

function MyGroupsView(props) {
  const {
    addGroupFolder,
    assignGroupToGroupFolder,
    chooseSavedSearch,
    clearFilters,
    deleteGroup,
    deleteGroupFolder,
    deleteGroupFromFolder,
    deleteSavedSearch,
    fetchingRecord,
    fileTreeExplorer = [],
    filterScratch,
    getMyGroups,
    group,
    personId,
    savedFilterIdCurrent,
    saveNewSavedSearch,
    setGradebookScoreByPenspring,
    setPenspringDistributeSubmitted,
    setPenspringHomeworkSubmitted,
    updateFilterByField,
    updateFilterDefaultFlag,
    updateSavedSearch,
    workFilterOptions,
  } = props;

  const { pageVisitor } = useAppContext();

  // const [isShowingModal, setIsShowingModal] = useState(false);
  // const [searchString, setSearchString] = useState('');
  // const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  // const [searchFoundCount, setSearchFoundCount] = useState(null);
  // const [isAllToggledExpanded, setIsAllToggledExpanded] = useState(false);
  const [chosenGroup, setChosenGroup] = useState('');
  const [groupFolderMineExpanded, setGroupFolderMineExpanded] = useState(props.personConfig && props.personConfig.groupFolderMineExpanded);
  const [groupFolderOthersExpanded, setGroupFolderOthersExpanded] = useState(props.personConfig && props.personConfig.groupFolderOthersExpanded);
  const [groupFolderId, setGroupFolderId] = useState();
  const [showSearchControls, setShowSearchControls] = useState(false);
  const [isMoveGroupId, setIsMoveGroupId] = useState();

  // useEffect(() => {
  // }, [isAllToggledExpanded])

  const hasChosenGroupInFolder = (groupFolderId) => {
    if (!chosenGroup) return false
    let foundMatch = false
    for (let i = 0; i < fileTreeExplorer.length; i++) {
      if (fileTreeExplorer[i].groupFolderId === groupFolderId) {
        foundMatch = fileTreeExplorer[i].files && fileTreeExplorer[i].files.length > 0 && fileTreeExplorer[i].files.filter(f => f.groupId.toLowerCase() === chosenGroup.groupId.toLowerCase())[0]
        if (!!foundMatch) break
      }
      if (fileTreeExplorer[i].subFolders && fileTreeExplorer[i].subFolders.length > 0) foundMatch = searchForGroupInFolder(fileTreeExplorer[i].subFolders, groupFolderId)
      if (!!foundMatch) break
    }
    return foundMatch
  }

  const searchForGroupInFolder = (subFolders, groupFolderId) => {
    let foundMatch = false
    for (let i = 0; i < subFolders.length; i++) {
      if (subFolders[i].groupFolderId === groupFolderId) {
        foundMatch = subFolders[i].files && subFolders[i].files.length > 0 && subFolders[i].files.filter(f => f.groupId.toLowerCase() === chosenGroup.groupId.toLowerCase())[0]
        if (!!foundMatch) break
      }
      if (subFolders[i].subFolders && subFolders[i].subFolders.length > 0) foundMatch = searchForGroupInFolder(subFolders[i].subFolders, groupFolderId)
      if (!!foundMatch) break
    }
    pageVisitor({ p: 'MyGroupsView', n: 'search for group in folder' })
    return foundMatch
  }

  const moveGroupToFolder = (type, groupFolderId) => {
    if (type === 'end') props.assignGroupToGroupFolder(personId, isMoveGroupId, groupFolderId)
    setIsMoveGroupId(type === 'start' ? chosenGroup.groupId : null)
    pageVisitor({ p: 'MyGroupsView', n: 'Move group to folder' })
  }

  const toggleSearchControls = () => setShowSearchControls(!showSearchControls);

  const handleToggleAllExpanded = (expandAll) => {
    const {personId, updatePersonConfig} = props;
    // setGroupFolderMineExpanded(expandAll);
    // setGroupFolderOthersExpanded(expandAll);
    // setAllPeopleExpanded(expandAll)
    //setTimeout(() => getMyGroups(personId), 500)
    //setTimeout(() => window.location.reload(true), 500)
    let groupIds = []
    if (expandAll) {
      groupIds = fileTreeExplorer.reduce((acc, m) => acc = acc && acc.length > 0 ? acc.concat(m.groups[0].groupId) : [m.groups[0].groupId], [])
    }
    localStorage.setItem("peopleExpanded", groupIds.toString());
    window.location.reload(true)
    pageVisitor({ p: 'MyGroupsView', n: `Toggle all expensed {$expandAll}` })
  }

  const setAllPeopleExpanded = (expandAll) => {
    if (expandAll) {
      const groupIds = fileTreeExplorer.reduce((acc, m) => {
        m.groups && m.groups.map(g => {
          acc = acc.length > 0 ? acc.concat(g.groupId) : [g.groupId]
        })
        return acc
      }, [])
      localStorage.setItem("peopleExpanded", groupIds)
    } else {
      localStorage.setItem("peopleExpanded", [])
    }
  }

  const handleUpdatePersonConfig = (field) => {
    const {updatePersonConfig, personId} = props;
    if (field === 'GroupFolderMineExpanded') {
      updatePersonConfig(personId, 'GroupFolderMineExpanded', !groupFolderMineExpanded)
      setGroupFolderMineExpanded(!groupFolderMineExpanded)
    }
    if (field === 'GroupFolderOthersExpanded') {
      updatePersonConfig(personId, 'GroupFolderOthersExpanded', !groupFolderOthersExpanded)
      setGroupFolderOthersExpanded(!groupFolderOthersExpanded)
    }
  }

  const fileLength = () => {
    let mineLength = (fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.filter(m => m.mineOrOthers === 'Mine').length) || 0
    let othersLength = (fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.filter(m => m.mineOrOthers === 'Others').length) || 0
    return mineLength > othersLength ? mineLength : othersLength
  }

  const getMyGroupCount = () => {
    const mineList = fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.filter(m => m.mineOrOthers === 'Mine')
    return mineList.length
  }

  const getOthersGroupCount = () => {
    const othersList = fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.filter(m => m.mineOrOthers === 'Others')
    return othersList.length
  }

  return (
    <div className={styles.container}>
      <div className={styles.topStart}>
        {fileLength() > searchGroupCount &&
          <hr/>
        }
        {fileLength() > searchGroupCount &&
          <div onClick={toggleSearchControls} className={classes(styles.menuItem, styles.row)}>
            <Icon pathName={'magnifier'} premiuim={true} className={styles.icon}/>
            <div className={styles.link}>{showSearchControls ? `hide search controls` : `show search controls`}</div>
          </div>
        }
        {group &&
          <div className={styles.subTitle}>{`For group: `} {group && group.groupName}</div>
        }
        {showSearchControls &&
          <div className={styles.menuItem}>
            <div>
              <SelectSingleDropDown
                id={`filter`}
                label={'Saved searches'}
                value={savedFilterIdCurrent ? savedFilterIdCurrent : ''}
                options={workFilterOptions}
                error={''}
                height={`medium`}
                selectClass={styles.selectList}
                labelClass={styles.listLabel}
                onChange={(event) => chooseSavedSearch(personId, event.target.value)}/>
            </div>
            {guid.isGuidNotEmpty(savedFilterIdCurrent) &&
              <div>
                <div className={classes(styles.row, styles.marginLessTop)}>
                  <a onClick={() => updateSavedSearch(personId, savedFilterIdCurrent)} className={styles.linkStyle}>
                    <Icon pathName={`floppy_disk`} className={styles.image}/>
                  </a>
                  <a onClick={() => deleteSavedSearch(personId, savedFilterIdCurrent)} className={styles.linkStyle}>
                    <Icon pathName={`garbage_bin`} className={styles.image}/>
                  </a>
                  <a onClick={() => clearFilters(personId)} className={styles.linkStyle}>
                    <Icon pathName={`document_refresh`} className={styles.image}/>
                  </a>
                  <Checkbox
                    id={`filterDefault`}
                    label={`Default`}
                    labelClass={styles.labelCheckbox}
                    position={`before`}
                    checked={filterScratch.defaultFlag}
                    onClick={() => updateFilterDefaultFlag(personId, savedFilterIdCurrent, !filterScratch.defaultFlag)}
                    className={styles.checkbox}/>
                </div>
              </div>
            }
            <WorkFilter personId={personId} workFilter={filterScratch} className={styles.workFilter}
                        updateFilterByField={updateFilterByField}
                        clearFilters={clearFilters} saveNewSavedSearch={saveNewSavedSearch}
                        savedSearchOptions={workFilterOptions}/>
          </div>
        }
        {/*<Loading loadingText={`Loading`} isLoading={fetchingRecord && fetchingRecord.works} />*/}
        {/*!fetchingRecord.works &&*/}
        <br/><br/>
        <div className={classes(styles.menuItem, styles.rowExpandAll)}>
          <div className={styles.link} onClick={() => handleToggleAllExpanded(true)}>{`expand all`}</div>
          <div className={styles.divider}> |</div>
          <div className={styles.link} onClick={() => handleToggleAllExpanded(false)}>{`collapse all`}</div>
        </div>
        <GroupSectionControl expanded={groupFolderMineExpanded == undefined || !!groupFolderMineExpanded}
                             label={'My Groups and Contacts'}
                             groupCount={getMyGroupCount()}
                             personId={personId}
                             mineOrOthers={'Mine'}
                             chosenGroup={chosenGroup}
                             setChosenGroup={setChosenGroup}
                             deleteGroup={deleteGroup}
                             moveGroupToFolder={moveGroupToFolder}
                             groupFolderId={groupFolderId}
                             isMoveGroupId={isMoveGroupId}
                             hasChosenGroupInFolder={hasChosenGroupInFolder}
                             onClick={() => handleUpdatePersonConfig('GroupFolderMineExpanded')}
                             addGroupFolder={addGroupFolder}
                             deleteGroupFromFolder={deleteGroupFromFolder}/>

        <div className={groupFolderMineExpanded == undefined || !!groupFolderMineExpanded ? styles.show : styles.hide}>
          <GroupFileTreeSubContents
            fileTreeExplorer={fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.filter(m => m.mineOrOthers === 'Mine')}
            isParentExpanded={groupFolderMineExpanded == undefined || !!groupFolderMineExpanded}
            assignGroupToGroupFolder={assignGroupToGroupFolder}
            personId={personId} 
            mineOrOthers={'Mine'}
            setPenspringHomeworkSubmitted={setPenspringHomeworkSubmitted}
            getMyGroups={getMyGroups} 
            fetchingRecord={fetchingRecord}
            deleteGroup={deleteGroup}
            hasChosenGroupInFolder={hasChosenGroupInFolder}
            setGradebookScoreByPenspring={setGradebookScoreByPenspring}
            chosenGroup={chosenGroup} 
            setChosenGroup={setChosenGroup}
            setPenspringDistributeSubmitted={setPenspringDistributeSubmitted}
            isMoveGroupId={isMoveGroupId} 
            moveGroupToFolder={moveGroupToFolder}
            deleteGroupFolder={deleteGroupFolder} 
            addGroupFolder={addGroupFolder}
            getWorkByGroupIds={props.getWorkByGroupIds}
            peopleByGroupIds={props.peopleByGroupIds}
            getPeopleByGroupIds={props.getPeopleByGroupIds}
            workByGroupIds={props.workByGroupIds}
            deleteGroupFromFolder={deleteGroupFromFolder}/>
        </div>
        <GroupSectionControl expanded={groupFolderOthersExpanded == undefined || !!groupFolderOthersExpanded}
                             label={'Groups I am in'}
                             groupCount={getOthersGroupCount()}
                             personId={personId}
                             mineOrOthers={'Others'}
                             chosenGroup={chosenGroup}
                             setChosenGroup={setChosenGroup}
                             deleteGroup={deleteGroup}
                             moveGroupToFolder={moveGroupToFolder}
                             groupFolderId={groupFolderId}
                             isMoveGroupId={isMoveGroupId}
                             hasChosenGroupInFolder={hasChosenGroupInFolder}
                             onClick={() => handleUpdatePersonConfig('GroupFolderOthersExpanded')}
                             addGroupFolder={addGroupFolder}
                             noMenu={true} 
                             deleteGroupFromFolder={deleteGroupFromFolder}/>

        <div className={groupFolderOthersExpanded == undefined || !!groupFolderOthersExpanded ? styles.show : styles.hide}>
          <GroupFileTreeSubContents
            fileTreeExplorer={fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.filter(m => m.mineOrOthers === 'Others')}
            isParentExpanded={groupFolderOthersExpanded == undefined || !!groupFolderOthersExpanded}
            personId={personId} 
            mineOrOthers={'Others'}
            setPenspringHomeworkSubmitted={setPenspringHomeworkSubmitted}
            getMyGroups={getMyGroups} 
            fetchingRecord={fetchingRecord}
            deleteGroup={deleteGroup}
            hasChosenGroupInFolder={hasChosenGroupInFolder}
            setGradebookScoreByPenspring={setGradebookScoreByPenspring}
            chosenGroup={chosenGroup} 
            setChosenGroup={setChosenGroup}
            setPenspringDistributeSubmitted={setPenspringDistributeSubmitted}
            isMoveGroupId={isMoveGroupId} 
            moveGroupToFolder={moveGroupToFolder}
            deleteGroupFolder={deleteGroupFolder} 
            addGroupFolder={addGroupFolder}
            peopleByGroupIds={props.peopleByGroupIds}
            getPeopleByGroupIds={props.getPeopleByGroupIds}
            workByGroupIds={props.workByGroupIds}
            deleteGroupFromFolder={deleteGroupFromFolder} />
        </div>
      </div>
      <OneFJefFooter/>
    </div>
  )
}

export default MyGroupsView;
