import * as types from '../actions/actionTypes';

export default function(state = [], action) {
    switch(action.type) {
        case types.GROUP_JOIN_INVITE_CODE:
            return action.payload;

        default:
            return state;
    }
}

export const selectGroupJoinInviteCode = (state) => state;
