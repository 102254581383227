import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router'
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './EditorInviteGoogleContactsView.module.css';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import OneFJefFooter from '../../components/OneFJefFooter';
import ToastInputText from '../../components/ToastInputText';
import Checkbox from '../../components/Checkbox'
import InputText from '../../components/InputText'
import ContactSummaryLine from '../../components/ContactSummaryLine'
import classes from "classnames";
import jwtDecode from 'jwt-decode'
import { doSort } from '../../utils/sort'
import {guidEmpty} from '../../utils/GuidValidate'
import { createInfoToastAuto } from '../../services/queryClient';

function EditorInviteGoogleContactsView(props) {
	const {
		personId,
		groupOptions,
		group = { contacts: [] },
		contactsAll,  //We are now tracking contacts with SocialMediaListId which would match up with this Google resourceName
		contactMembers, //I don't think that this particular list is being so helpful. 
		checkContactMembers,
		addOrUpdateGroup,
		getGroups,
		getGroupByName,
		groupByName
	} = props;

	const navigate = useNavigate()
  const params = useParams()
	const [groupChosen, setGroupChosen] = useState()
	const [filterContactName, setFilterContactName] = useState('')
	const [contactsFiltered, setContactsFiltered] = useState()
	const [contacts, setContacts] = useState()
	const [isIncludeIndividual, setIsIncludeIndividual] = useState([])
	const [addNewGroup, setAddNewGroup] = useState()

	useEffect(() => {
		setGroupChosen(groupByName && groupByName.groupId)
	}, [groupByName])

	useEffect(() => {
		let newIsIncludeIndividual = []
		contactMembers && contactMembers.length > 0 && contactMembers.forEach(m => {
			if (m.isFriendInvitation || m.isPersonFriend) {
				newIsIncludeIndividual.push({emailAddress: m.emailAddress, socialMediaListId: m.socialMediaListId})
			}
		})
		setIsIncludeIndividual(newIsIncludeIndividual)
	}, [contactMembers])

	useEffect(() => {
		setGroupChosen(params.groupChosen)
	}, [params.groupChosen])

	useEffect(() => {
	  let filtered = contacts && contacts.length > 0 ? [...contacts] : []
	  if (filterContactName) {
			filtered = filtered && filtered.length > 0 && filtered.filter(m => m.firstName.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1 || m.lastName.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1 || m.emailAddress.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1)
		}
		filtered = doSort(filtered, { sortField: 'firstName', isAsc: true, isNumber: false })
		if (contactMembers && contactMembers.length > 0) {
			filtered = contactMembers && contactMembers.length > 0 && filtered.length > 0 && filtered.map(m => {
				const contact = contactMembers.filter(c => c.emailAddress === m.emailAddress)[0]
				if (contact && contact.emailAddress) {
					if ((groupChosen === guidEmpty || groupChosen === "" || !groupChosen) && contact.isPersonFriend) {
						m.isGroupMember = true //Even though this is an ethereal group called Invdividual Contacts or something similar, we will us isGroupMember to mark the checkbox.
						m.groupNames = contact.personGroups.reduce((acc, g) => acc = acc && acc.length > 0 ? acc.concat(g.groupName) : [g.groupName], [])
					} else {
						const matchesGroup = contact.personGroups.filter(g => g.groupId === groupChosen)[0]
						if (matchesGroup && matchesGroup.groupId) m.isGroupMember = true
						else if (contact.isMember) m.isPenspringMember = true
						else if (contact.isFriendInvitation) m.isFriendInvitation = true
						m.groupNames = contact.personGroups.filter(g => g.groupId !== groupChosen).reduce((acc, g) => acc = acc && acc.length > 0 ? acc.concat(g.groupName) : [g.groupName], [])
					}
				}
				return m
			})
		}
	  setContactsFiltered(filtered)
	}, [contacts, filterContactName, groupChosen, contactMembers])

	function handleGoogleCallbackResponse(response) {
		const userJwt = jwtDecode(response.credential)
		localStorage.setItem("googleToken", JSON.stringify(response.credential))
		if (response) props.login({
			isNewAccount: false,
			googleId: userJwt.sub,
			socialMediaToken: response.credential,
			firstName: userJwt.given_name,
			lastName: userJwt.family_name,
			emailAddress: userJwt.email,
			emailAddressConfirm: userJwt.email,
			username: userJwt.email,
			clave: '',
			claveConfirm: '',
			recaptchaResponse: '',
			inviteCode: props.inviteCodeShort,
		}, props.inviteCodeShort, false);
	}

	useEffect(() => {
		/* global google */
		google.accounts.id.initialize({
			client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
			callback: handleGoogleCallbackResponse,
		})

		google.accounts.id.renderButton(
			document.getElementById('signInDiv'),
			{ theme: "outline", size: "large" }
		)

		const getAndSetAccessTokenApi = (accessTokenApi) => {
			accessTokenApi.requestAccessToken()
		}

		const callForAllContacts = async (accessToken) => {
			const baseUrl = 'https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses,photos&pageSize=2000';
			let contactList = [];
			let nextPageToken = null;

			do {
				const response = await fetch(`${baseUrl}${nextPageToken ? `&pageToken=${nextPageToken}` : ''}`, {
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						'Authorization': `Bearer ${accessToken}`,
					},
				});

				if(!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

    	  const data = await response.json();
				data && data.connections && data.connections.length > 0 && data.connections.forEach(m => {
					if (m.emailAddresses && m.emailAddresses.length > 0 && m.names && m.names.length > 0) {
						contactList.push({
							firstName: m.names[0].givenName || '',
							lastName: m.names[0].familyName || '',
							emailAddress: m.emailAddresses[0].value,
							photo: m.photos && m.photos.length > 0 ? m.photos[0].url : '',
							socialMediaListId: m.resourceName
						})
					}
				})
				nextPageToken = data.nextPageToken;					
			} while (nextPageToken)

			const contactMatches = contactList?.length > 0 && contactList.reduce((acc, m) => {
				const match = {emailAddress: m.emailAddress, socialMediaListId: m.socialMediaListId}
				acc = acc && acc.length > 0 ? acc.concat(match) : [match]
				return acc
			}, [])
			checkContactMembers(contactMatches)
			setContacts(contactList)
		}

		getAndSetAccessTokenApi(google.accounts.oauth2.initTokenClient({
			client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
			scope: "https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly",
			callback: (tokenResponse) => {
				if (tokenResponse && tokenResponse.access_token) {
					callForAllContacts(tokenResponse.access_token)
				}
			}
		}))
	}, [])

	const handleIncludeIndividual = (contact) => {
		//Notice that the groupId is set to guidEmpty here so that this individual contact (or direct contact) does not add another personGroupAssign record for the group on this page (if it is chosen)
		let newIsIncludeIndividual = [...isIncludeIndividual]
		const hasRecord = isIncludeIndividual.filter(m => (contact.emailAddress && m.emailAddress !== contact.emailAddress) || (contact.socialMediaListId && m.socialMediaListId !== contact.socialMediaListId))[0]
		if (hasRecord && (hasRecord.emailAddress || hasRecord.socialMediaListId)) {
			newIsIncludeIndividual = newIsIncludeIndividual.filter(m => (contact.emailAddress && m.emailAddress !== contact.emailAddress) || (contact.socialMediaListId && m.socialMediaListId !== contact.socialMediaListId))
			props.assignContactToGroup(personId, contact.personId, guidEmpty, contact.firstName, contact.lastName, contact.photo, contact.emailAddress, contact.socialMediaListId, false, true) //Notice that includeIndividualContact is set to true but delete is set to true which means to delete the includeIndividualContact
		} else {
			newIsIncludeIndividual.push({ emailAddress: contact.emailAddress, socialMediaListId: contact.socialMediaListId })
			props.assignContactToGroup(personId, contact.personId, guidEmpty, contact.firstName, contact.lastName, contact.photo, contact.emailAddress, contact.socialMediaListId, true, true)
		}
		setIsIncludeIndividual(newIsIncludeIndividual)
	}

	const getIsIncludeIndividual = (emailAddress, socialMediaListId) => {
		const hasRecord = isIncludeIndividual.filter(m => (emailAddress && m.emailAddress !== emailAddress) || (socialMediaListId && m.socialMediaListId !== socialMediaListId))[0]
		if (hasRecord && (hasRecord.emailAddress || hasRecord.socialMediaListId)) return true
	}

	const isChecked = (contact) => {
		//If the groupId for this page is guidEmpty then it is the theoretical "Individual Contacts" group.
		//	If contact.isPersonFriend or contact.isFriendInvitation 
		//		return TRUE
		//otherwise there is a groupId to see if the contact has a record in contactMembers.personGroups
		//	if group is found in contactMembers.personGroups
		//		return TRUE
		//end if
		if (!groupChosen || groupChosen === guidEmpty || groupChosen == 0) {
			const hasRecord = isIncludeIndividual.filter(m => (contact.emailAddress && m.emailAddress === contact.emailAddress) || (contact.socialMediaListId && m.socialMediaListId === contact.socialMediaListId))[0]
			if (hasRecord && (hasRecord.emailAddress || hasRecord.socialMediaListId)) return true;
		} else {
			const person = contactsAll?.filter(m => (contact.socialMediaListId && m.socialMediaListId === contact.socialMediaListId) || (contact.emailAddress && m.emailAddress.toLowerCase() === contact.emailAddress.toLowerCase()))[0]
			const hasGroup = person?.groups?.filter(m => m.groupId == groupChosen)[0]
			if (hasGroup && hasGroup.groupId) return true;
		}		
	  return false
	}

	const showInvitationStatus = (contact) => {
		//We will use the contactMembers for this answer.
		//If the groupId for this page is guidEmpty then it is the theoretical "Individual Contacts" group.
		//	If contact.isFriendInvitation 
		//		Show the label for "Invitation Pending"
		//otherwise there is a groupId to see if the contact has a record in contactMembers.personGroups
		//	Show the checkbox to include this user in your Individual Contacts as well.
		//	if contact.isPersonAssign or contact.isFriendInvitation
		//		Check the checkbox automatically
		//		If contact.isFriendInvitation 
		//			Show the label for "Invitation Pending"
		//end if
		if (!groupChosen || groupChosen === guidEmpty || groupChosen == 0) {
			if (contact.isFriendInvitation) return <div key={contact.personId} className={styles.invitationPending}>Invitation pending</div>
		} else if (isChecked(contact)) {
			return (
				<div key={contact.emailAddress} className={styles.checkboxIndividual}>
					<input 
						type='checkbox' 
						id={`includeIndividual${contact.emailAddress}`} 
						checked={getIsIncludeIndividual(contact.emailAddress, contact.socialMediaListId) || contact.isPersonAssign || contact.isFriendInvitation || false} 
						onChange={() => handleIncludeIndividual(contact)}/>
					<label 
						htmlFor={`includeIndividual${contact.emailAddress}`} 
						className={styles.includeText}>
							Also, include this contact in my Individual Contacts.
					</label>
				</div>
			)
		}
		return false
	}

	const getSubText = (contact) => {
		let textLine = ""
		if (contact.isGroupMember) {
			if (!groupChosen || groupChosen == 0 || groupChosen === guidEmpty) {
				textLine = 'Groups: ' + contact.groupNames.join(",") + " - "
			} else if ((contact.groupNames && contact.groupNames.length > 0) || contact.isPersonFriend) {
				if (contact.groupNames && contact.groupNames.length > 0) {
					textLine = 'Other groups: ' + contact.groupNames.join(",") 
				}
				if (contact.isPersonFriend) {
					textLine += (textLine ? ' - ' : '') + "Also in your Invdividual contacts" 
				}
			} else if (contact.isMember || contact.isFriendInvitation) {
				if (contact.isMember)  textLine = "Is a Penspring member."
				if (contact.isFriendInvitation) textLine = (textLine ? ' ' : '') + "You have already invited this person."
			}
		}
	}

	const handleAssignContactToGroup = (contact) => {
		const contactMatches = contacts?.length > 0 && contacts.reduce((acc, m) => {
			const match = {emailAddress: m.emailAddress, socialMediaListId: m.socialMediaListId }
			acc = acc && acc.length > 0 ? acc.concat(match) : [match]
			return acc
		}, [])
    createInfoToastAuto(`<div>The invitation is being recorded.</div>`)
		props.assignContactToGroup(personId, contact.personId, groupChosen, contact.firstName, contact.lastName, contact.photo, contact.emailAddress, contact.socialMediaListId, isChecked(contact.emailAddress), getIsIncludeIndividual(contact.emailAddress, contact.socialMediaListId))
		setTimeout(() => checkContactMembers(contactMatches), 500)
	} 

	const submitAddNewGroup = (groupName) => {
		Promise.all([addOrUpdateGroup({
			groupId: guidEmpty,
			personId,
			groupName,
			languageId: 1,
			internalId: '',
			description: '',
			workFolderId: '',
		}, `NONE`)]).then(setTimeout(() => getGroups(personId), 500)).then(setTimeout(() => getGroupByName(personId, groupName), 500))
		setAddNewGroup(false)
	}

	return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={styles.titleLine}>
          <span className={globalStyles.pageTitle}>Google Contacts</span>
        </div>
				<div className={classes(styles.row, styles.moreBottom)}>
					<div>
						<div>
							<span htmlFor={'groupList'} className={styles.labelText}>Group</span>
						</div>
						<select
							id={'groupList'}
							value={groupChosen || ''}
							onChange={(event) => {
								setGroupChosen(event.target.value)
								if (event.target.value === 'NEWGROUP') {
									setAddNewGroup(true)
								} else {
									props.getGroupContactsWorks(personId, event.target.value)
								}
							}}
							className={styles.selectList}
						>
							<option value="0">Individual Contacts</option>
							{groupOptions && groupOptions.length > 0 && groupOptions.map((option, index) => 
								<option key={index} value={option.id} dangerouslySetInnerHTML={{ __html: option.label }}></option>
							)}
							<optgroup key={9999} label={'------------------------'}>
								<option value='NEWGROUP'>Add a New Group</option>
							</optgroup>
						</select>
					</div>
					<InputText
						size={"medium"}
						name={"filterContactName"}
						label={`Filter contact name or email address`}
						value={filterContactName}
						onChange={(event) => setFilterContactName(event.target.value)} />
				</div>
				<div style={{height: '350px', overflowY: 'auto'}}>
					{contactsFiltered && contactsFiltered.length > 0 && contactsFiltered.map((w, i) => 
						<div key={i + 'summary'} onClick={() => handleAssignContactToGroup(w)} style={{cursor: 'pointer'}}>
							<div className={styles.rowCheckbox} >
								<Checkbox
									label={``}
									checked={isChecked(w)}
									onClick={() => {}}/>
								<div onClick={() => {}}>
									<ContactSummaryLine 
										contact={w} 
										nameLength={150} 
										keyIndex={i + 'line'} 
										subText={getSubText(w)}
										LighterBackground 
										showPhoto 
										showEmailAddress={false}
										showNumbersPenspringMember />
								</div>
							</div>
							{showInvitationStatus(w)}
						</div>
					)}
					{!(contacts && contacts.length > 0) && 
						<div className={styles.noContacts}>There are not any individual contacts to include in this group, yet</div>
					}
				</div>
				<div className={styles.buttonWidth}>
					<ButtonWithIcon icon={'checkmark0'} onClick={() => navigate(-1)} label={'Done'} submitSuccess={false}/>
				</div>
        <OneFJefFooter/>
      </div>
			<ToastInputText
				show={addNewGroup}
				label={`Group name`}
				message="New Group Name"
				onSubmit={submitAddNewGroup}
				onClose={() => setAddNewGroup(false)} />
		</div>
	);
}

export default EditorInviteGoogleContactsView;

