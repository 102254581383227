import React from 'react';
import styles from './VideoPlayer.module.css';
import { useMediaQuery } from 'react-responsive';
import { useAppContext } from '../../../../AppContext.js';
import classes from 'classnames';
import ButtonWithIcon from '../../ButtonWithIcon'

function VideoPlayer(props) {
  const {
    isOpen,
    setIsOpen,
    videoUrl,
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const { pageVisitor } = useAppContext();


  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background}>
          Video Player
          {videoUrl && (
            <div className={styles.modal}>
              <video
                src={videoUrl}
                controls
                autoPlay
                style={{
                  width: "100%",
                  maxHeight: "500px",
                  border: "1px solid black",
                }}
              />
            </div>
          )}
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <ButtonWithIcon
                label={'Done'}
                icon={'checkmark0'}
                onClick={() => setIsOpen(false)}
                submitSuccess={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer