import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import InvitationExpiredView from '../views/InvitationExpiredView'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import * as actionMyProfile from '../actions/my-profile.js'
import * as actionGroups from '../actions/groups.js'
import * as loginUser from "../actions/login"
import * as actionEditorInvite from "../actions/editor-invite-response.js"
import { selectMe, selectMyProfile, selectEditorInviteIsActive, selectUsernameToVerify, selectFriendInvitation } from '../store.js'

const mapStateToProps = (state) => ({
  ...selectMe(state),
  userProfile: selectMyProfile(state),
  isActiveInvite: selectEditorInviteIsActive(state),
  usernameToVerify: selectUsernameToVerify(state),
  friendInvitation: selectFriendInvitation(state),
})

const mapDispatchToProps = {
  getMyProfile: actionMyProfile.getMyProfile,
  setMyProfileNew: actionMyProfile.setMyProfileNew,
  updateMyProfile: actionMyProfile.updateMyProfile,
  login: loginUser.login,
  logout: loginUser.logout,
  addUserToGroupInvite: actionGroups.addUserToGroupInvite,
  getInviteFromShortCode: actionEditorInvite.getInviteFromShortCode,
  isEditorInviteActive: actionEditorInvite.isEditorInviteActive,
}

function HomeContainer({
  addUserToGroupInvite,
  friendInvitation,
  getInviteFromShortCode,
  getMyProfile,
  isActiveInvite,
  isEditorInviteActive,
  personId,
}) {
  const navigate = useNavigate()

  const { inviteCodeShort, createNew } = useParams()

  useEffect(() => {
    if (inviteCodeShort && createNew !== 'reset') {
      if (!!personId) getMyProfile(personId)
      isEditorInviteActive(inviteCodeShort)
      getInviteFromShortCode(inviteCodeShort)
    }
  }, [friendInvitation, inviteCodeShort, createNew])

  const isValidToken = () => {
    const token = localStorage.getItem('authToken');
    if (!token) return false;

    try {
      const decoded = jwtDecode(token);
      return new Date().getTime() < decoded.exp * 1000 && decoded.emailAddress.toLowerCase() === friendInvitation.email.toLowerCase();
    } catch (error) {
      console.error("Failed to decode JWT:", error);
      return false;
    }
  };

  useEffect(() => {
    if (isValidToken()) {
      addUserToGroupInvite(inviteCodeShort);
      setTimeout(() => navigate('/myWorks'), 500);
    }
    if (inviteCodeShort) localStorage.setItem('inviteCodeShort', inviteCodeShort)
  }, [isValidToken, isActiveInvite, createNew, inviteCodeShort, navigate]);

  if (!inviteCodeShort || (!isActiveInvite && createNew !== 'reset')) {
    return <InvitationExpiredView />
  }

  if (isActiveInvite === 'VALID' || createNew === 'reset') {
    switch (createNew) {
      case 'e': // existing user
        isValidToken() ? navigate('/myWorks') : navigate('/login')
        break
      default:
        navigate(`/myProfile/${friendInvitation.friendInvitationId}/${friendInvitation.fname}/${friendInvitation.lname || 'EMPTY'}/${friendInvitation.email}/${friendInvitation.phone || 'EMPTY'}/${createNew}`)
        break
    }
  }

  return null // Ensure all paths return a value or component
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
