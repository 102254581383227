import React, {useEffect, useState, useRef} from 'react'
import styles from './MobileSelectListFloat.module.css'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export default function MobileSelectListFloat({ 
	onSelect = () => {}, 
	open,
	header,
	setOpen,
	listOptions, 
	divDisplayId, 
	children, 
	addClassName, 
	keyIndex,
}) {

  //let anchorEl = useRef(null)
  const containerRef = useRef(null)
	const [anchorEl, setAnchorEl] = useState(null)

	// const handleClick = (event) => {
	// 	setAnchorEl(event.currentTarget)
	// }

	const handleClose = () => {
		setAnchorEl(null)
	}

  useEffect(() => {
    const handleClickOutside = (event) => {
      let isInputControl = false
      let element = event.target
      let loop = 0
      while (element && loop < 10 && !isInputControl) {
        isInputControl = element.id && element.id === 'searchHtmlCharacters'
        element = element.nextSibling
        loop++
      }
      if (!isInputControl && containerRef.current && !containerRef.current.contains(event.target)) {
        handleClose()
        setOpen(false)
      }
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && containerRef.current) { // ESC key
        handleClose()
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keyup', handleKeyDown)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keyup', handleKeyDown)
    }
  }, [open])


	const checkForClose = (event) => {
		event.stopPropagation()
		const element = event && event.target
		if (element && element.innerText === 'Next') handleClose()
	}

	return (
    <div onClick={checkForClose} style={{ height: '10px', position: 'relative', left: '10px !important', top: '20px' }} key={keyIndex} className={styles.container} ref={containerRef}>
			<Menu
				anchorEl={anchorEl}
				id="drop-down-list"
        style={{position: 'relative', left: '100px'}}
				open={open}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						fontSize: '11px',
						maxHeight: '90vh',
						overflowY: 'auto',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						backgroundColor: '#92dbf1',
						mt: 1,
						'& .MuiAvatar-root': {
							width: 32,
							height: 22,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 4,
							backgroundColor: '#92dbf1',
							transform: 'translateY(-50%) rotate(45deg)',
						},
					},
				}}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
			>
				<div className={addClassName}>
					{header &&
						<div className={styles.header} onClick={() => setOpen(false)}>
							{header}
						</div>
					}
					{children}
					{listOptions?.length > 0 && listOptions.map((m, i) =>
            <div key={`menu${m.id}`}>
              <MenuItem 
                onClick={(event) => {
                  event.stopPropagation()
                  onSelect(m.id, event, divDisplayId)
                  setOpen(false)
                }
              }>
                <span>{m.label}</span>
              </MenuItem>
            </div>
					)}
				</div>
			</Menu>
		</div>
	)
}