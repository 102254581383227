import React, { useRef, useState } from 'react'
import styles from './HandwritingEntryModal.module.css'
import classes from 'classnames'
import ButtonWithIcon from '../../ButtonWithIcon'
import { useMediaQuery } from "react-responsive"

function HandwritingEntryModal(props) {
  const {
    isOpen,
    setIsOpen,
    onSubmit,
  } = props

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const canvasRef = useRef(null)
  let containerRef = useRef(null)
  
  const [isDrawing, setIsDrawing] = useState(false)

  const startDrawing = (event) => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    ctx.lineJoin = 'round'
    ctx.lineCap = 'round'
    ctx.lineWidth = 2
    ctx.strokeStyle = '#000'

    const rect = canvas.getBoundingClientRect()
    const x = 'touches' in event ? event.touches[0].clientX - rect.left : event.clientX - rect.left
    const y = 'touches' in event ? event.touches[0].clientY - rect.top : event.clientY - rect.top

    ctx.beginPath()
    ctx.moveTo(x, y)
    setIsDrawing(true)
  }

  const draw = (event) => {
    if (!isDrawing) return

    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const rect = canvas.getBoundingClientRect()
    const x = 'touches' in event ? event.touches[0].clientX - rect.left : event.clientX - rect.left
    const y = 'touches' in event ? event.touches[0].clientY - rect.top : event.clientY - rect.top

    ctx.lineTo(x, y)
    ctx.stroke()
  }

  const stopDrawing = () => {
    setIsDrawing(false)
  }

  const clearCanvas = () => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  const saveHandwriting = () => {
    const canvas = canvasRef.current
    if (!canvas) return

    const dataURL = canvas.toDataURL('image/png') // Save as a base64 image
    onSubmit(dataURL)
    setIsOpen(false)
  }

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <div>Handwriting</div>
          <canvas
            ref={canvasRef}
            width={600}
            height={400}
            style={{ border: '1px solid #000', touchAction: 'none', backgroundColor: 'white' }}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseOut={stopDrawing}
            onTouchStart={startDrawing}
            onTouchMove={draw}
            onTouchEnd={stopDrawing}
          ></canvas>
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={clearCanvas}>
                Clear
              </span>
              <span className={styles.cancelButton} onClick={() => setIsOpen(false)}>
                Cancel
              </span>
              <ButtonWithIcon
                label={'Submit'}
                icon={'checkmark0'}
                onClick={saveHandwriting}
                submitSuccess={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HandwritingEntryModal
