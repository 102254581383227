import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import MediaListViewer from '../../Media/MediaListViewer'
import styles from './AssessmentSolutionDisplay.module.css'
import classes from 'classnames'

function AssessmentSolutionDisplay(props) {
  const {
    className,
    isTeacher,
    question,
    userPersonId,
  } = props

  const location = useLocation();
  const isLearnerPage = location.pathname.indexOf('assessmentLearner') > -1
  const isCorrectPage = location.pathname.indexOf('assessmentCorrect') > -1
  const isItemSetupPage = location.pathname.indexOf('assessmentQuestion') > -1 || location.pathname.indexOf('assessmentItemSetup') > -1


  const isValidBase64 = (str) => {
    try {
      return btoa(atob(str)) === str
    } catch (e) {
      return false
    }
  }

  useEffect(() => {
    let url;

    if (question?.solutionAudio && isValidBase64(question.solutionAudio)) {
      try {
        const binaryString = atob(question.solutionAudio); // Decode Base64
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: "audio/mpeg" });
        url = URL.createObjectURL(blob);
      } catch (error) {
        console.error("Error processing solution audio:", error);
      }
    }

    // Clean up the URL when the component unmounts
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [question?.solutionAudio]);

	return (
    <div className={classes(styles.container, className)}>
      {question?.questionTypeCode && (isCorrectPage || isItemSetupPage) && !isLearnerPage && 
        <div>
          <div className={styles.headerLabelBold}>Solution</div>
          {isTeacher  && 
            <div className={styles.instructions}>
              After the quiz is corrected, this explanation or media will be displayed.
            </div>
          }
          <div className={styles.text}>{question.solutionText}</div>
          <MediaListViewer
            recordType={'AssessmentQuestion'}
            recordId={question.assessmentQuestionId}
            section={'solution'}
            index={0}
            userPersonId={userPersonId}
            runFunction={() => props.getAssessmentQuestions(props.userPersonId, question.assessmentId)}
            mediaBinaries={question?.mediaBinaries} />
        </div>
      }
    </div>
	)
}

export default AssessmentSolutionDisplay