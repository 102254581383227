import React from 'react';
import { useLocation } from "react-router-dom";
import styles from './PointsDisplay.module.css';
import classes from 'classnames';
import TextDisplay from '../TextDisplay'
import InputText from '../InputText'
import CheckboxToggle from '../CheckboxToggle'

function PointsDisplay(props) {
  const {
    assessmentQuestionId,
    className,
    isCorrect,
    isTeacher,
    label = 'Points',
    onBlurScore,
    onCorrectShowIsCorrect,
    pending,
    pointsPossible,
    updateAssessmentQuestionAnswerCorrect,
    score,
    setScore,
    studentPersonId,
  } = props

  const location = useLocation();
  const isLearnerPage = location.pathname.indexOf('assessmentLearner') > -1
  const isCorrectPage = location.pathname.indexOf('assessmentCorrect') > -1
  const isEditReviewPage = location.pathname.indexOf('editReview') > -1
  const isItemSetupPage = location.pathname.indexOf('assessmentQuestion') > -1

  const handleSetScore = (event) => {
    setScore(event.target.value, studentPersonId, assessmentQuestionId)
  }

  const handleBlurScore = (event) => {
    onBlurScore(event.target.value, studentPersonId, assessmentQuestionId)
  }

  const setSpoints = () => {
    setScore(pointsPossible, studentPersonId, assessmentQuestionId)
    onBlurScore(pointsPossible, studentPersonId, assessmentQuestionId)
  }

  return ( 
    <div className={classes(styles.container, className)}>
      <TextDisplay label={label} text={isItemSetupPage || isLearnerPage || (isEditReviewPage && !isTeacher)
        ? <div className={styles.pointsText}>{pointsPossible}</div>
        : (isCorrectPage && isTeacher) || (isItemSetupPage && isTeacher)
          ? <div className={styles.row}>
              <InputText size={'super-short'}
                label={''}
                style={{ position: 'relative', top: '-2px' }}
                inputClassName={styles.positionTop}
                value={score === 0 ? '0' : score || ''}
                numberOnly={true}
                maxLength={6}
                alignTextRight
                biggerTextSize
                onChange={handleSetScore}
                onBlur={handleBlurScore} />
            <div className={classes(styles.littleTop, styles.row, isTeacher ? styles.pointer : '')} onClick={isTeacher ? setSpoints : () => {}}>
              <div className={styles.plainInputText}>out of</div>
              <div className={styles.pointsInputText}>
                {pointsPossible}
              </div>
            </div>
            {onCorrectShowIsCorrect &&
              <CheckboxToggle
                className={styles.checkboxLeft}
                label={'Is correct?'}
                checked={isCorrect
                  ? 'yes'
                  : isCorrect === null || isCorrect === undefined
                    ? 'PRERESPONSE'
                    : 'no'
                }
                onClick={(yesNo) => updateAssessmentQuestionAnswerCorrect(assessmentQuestionId, studentPersonId, yesNo === 'yes')} />
            }
          </div>
          : pending
            ? <div className={(styles.littleTop, styles.row)}>
                <div className={styles.plainText}>pending</div>
                <div className={styles.pointsText}>{pointsPossible}</div>
              </div>
            : <div className={(styles.littleTop, styles.row)}>
                <div className={styles.pointsText}>{Math.round(score * 10) / 10 || '0'}</div>
                <div className={styles.plainText}>out of</div>
                <div className={styles.pointsText}>{pointsPossible}</div>
              </div>
      } />
      {/* <div className={styles.checkbox}>
        <Checkbox
          id={'isCorrect'}
          label={'Mark this answer as correct'}
          labelClass={styles.checkboxLabel}
          checked={(correct && correct.isCorrect) || false}
          onClick={toggleCheckbox}
          className={styles.button} />
      </div> */}

    </div>
  )
};


export default PointsDisplay