import React, { useEffect } from 'react'
import styles from './ToastAddListDecision.module.css'
import toast from "react-hot-toast"
import Checkbox from '../Checkbox'
import Icon from '../Icon'
import { createInfoToastAuto } from '../../services/queryClient'

const ToastAddListDecision = ({ 
  globalSuffixId,
  insertAddListTargets, 
  label = 'How do you want to create a list?',
  onClose, 
  show, 
  startConvertAddListSequence, 
}) => {

	useEffect(() => {
		let toastId = null

    if (show) {
			toastId = toast((t) => (
				<div style={{margin: '0px', padding: '0px'}}>
					<div className={styles.upperBackground}>
						<Icon pathName={'list3'} premium={true} fillColor={'white'} />
						<div className={styles.headerLabel}>{label}</div>
					</div>
					<div className={styles.columnDecision}>
						<div className={styles.topSpace}>
							<Checkbox
								label={`Choose a location for a new list?`}
								checked={false}
								onClick={() => { 
									closeHandler(t) 
                  insertAddListTargets(globalSuffixId) 
									createInfoToastAuto(`<div>Choose a target icon: <img src='/inline/move-target.svg' alt='target' height='28'/></div>`)
									onClose() 
								}} />
						</div>
						<div className={styles.topSpace}>
							<Checkbox
								label={`Select sentences to convert to a list?`}
								checked={false}
								onClick={() => { 
									closeHandler(t) 
									startConvertAddListSequence() 
									createInfoToastAuto(`
										<div>
											<div style="display: flex; flex-direction: row;">
												<img src='/inline/convert-add-list-question-start.png' height='19' style="margin-right: 8px; margin-top: 4px;" />
												<div className={styles.instructionText}>1. Click on the beginning sentence.</div>
											</div>
											<div style="display: flex; flex-direction: row;">
												<img src='/inline/convert-add-list-question-end.png' height='19' style="margin-right: 8px; margin-top: 4px;" />
												<div className={styles.instructionText}>2. Choose the last sentence (or its icon).</div>
											</div>
											<div style="display: flex; flex-direction: row;">
												<div className={classes(styles.missingIcon, styles.instructionText)}>3. A pop-up will give you list type options.</div>
											</div>
										</div>`, 4000)
									onClose();}} />
						</div>
					</div>
					<div className={styles.rowRight}>
						<span className={styles.cancelButton} onClick={() => {closeHandler(t); onClose()}}>
							Cancel
						</span>
					</div>
				</div>
			), {
				duration: Infinity,
				style: { maxWidth: "75em" },
			});
		}

		return () => {
			if (toastId) {
				toast.dismiss(toastId);
			}
		};
	}, [show, label]);

	const closeHandler = (t) => {
		toast.dismiss(t.id)
	}

	return null // This component does not render anything itself
}

export default ToastAddListDecision
