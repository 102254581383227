import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import AssessmentSolutionDisplay from '../AssessmentSolutionDisplay'
import AssessmentStudentResponseSetting from '../../Assessment/AssessmentStudentResponseSetting'
import CheckboxToggle from '../../CheckboxToggle'
import classes from 'classnames'
import globalStyles from '../../../utils/globalStyles.module.css'
import Icon from '../../Icon'
import MediaListViewer from '../../Media/MediaListViewer'
import PointsDisplay from '../../PointsDisplay'
import QuestionLabel from '../../QuestionLabel'
import styles from './AssessmentMultipleAnswer.module.css'


/*
  The learnerAnswer and the correctAnswer should not be used at the same time.  The correctAnswer comes through when it is the content manager.
    The learnerAnswer comes through when it is the learner taking the text.
    But then again, it is going to be necessary to show the correct answer with the learner answer.  In that case, we'll have to use TextDisplay
    such as "[ ANSWER ]" in green to show the correct answer when the learnerAnswer is present.
*/

function AssessmentMultipleAnswer(props) {
  const {
    assessmentCorrect,
    assessmentQuestionId,
    assignmentId,
    bigTextDisplay,
    className = '',
    disabledMessage,
    isTeacher,
    mediaBinaries,
    onBlurScore,
    onClick,
    question = {},
    score,
    setScore,
    userPersonId,
  } = props

  const location = useLocation();
  const isLearnerPage = location.pathname.indexOf('assessmentLearner') > -1
  const isCorrectPage = location.pathname.indexOf('assessmentCorrect') > -1
  const isItemSetupPage = location.pathname.indexOf('assessmentQuestion') > -1 || location.pathname.indexOf('assessmentItemSetup') > -1

  const [correctAnswer, setCorrectAnswer] = useState()
  const [learnerAnswer, setLearnerAnswer] = useState()
  const [answers, setAnswers] = useState()

  useEffect(() => {
    if (question) {
      setCorrectAnswer(question.correctAnswer)
      setLearnerAnswer(question.learnerAnswer?.learnerAnswer)
    }
  }, [question])

  useEffect(() => {
    setAnswers(!!correctAnswer
      ? correctAnswer?.split(",")
      : learnerAnswer?.learnerAnswer?.length > 0
        ? learnerAnswer?.learnerAnswer?.split(",")
        : [])
  }, [correctAnswer, learnerAnswer])

  const handleAnswer = (checkboxValue, answer = [], assessmentQuestionAnswerOptionId) => {
    if (checkboxValue === 'no' && assessmentQuestionAnswerOptionId) {
      answer = answer?.length > 0 && answer.replace(assessmentQuestionAnswerOptionId, '')
    } else {
      if (!answer || (answer && answer.indexOf(assessmentQuestionAnswerOptionId) === -1)) {
        answer = answer ? answer + ',' + assessmentQuestionAnswerOptionId : assessmentQuestionAnswerOptionId
      }
    }
    if (onClick) onClick(userPersonId, question.assessmentQuestionId, answer, assignmentId)
  }

  const isAssessmentCorrect = assessmentCorrect && assessmentCorrect.assessmentId

  let alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

  let learnerAnswers = (question.learnerAnswer && question.learnerAnswer.learnerAnswer) || []
  if (typeof learnerAnswers === 'string') learnerAnswers = learnerAnswers.split(",")

  let correctAnswers = question.correctAnswer || []
  if (typeof correctAnswers === 'string') correctAnswers = correctAnswers.split(",")

  return (
    <div className={classes(className, styles.container)}>
      <QuestionLabel label={'Multiple Answer'} subLabel={`Choose one or more answers which apply.`} />
      <div className={classes(styles.row, styles.questionLine)}>
        {isAssessmentCorrect && question?.learnerAnswer?.assessmentLearnerAnswerId
          ? question?.learnerAnswer?.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question.sequence}.
        </div>
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{question.questionText}</div>
      </div>
      <MediaListViewer
        recordType={'AssessmentQuestion'}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      <AssessmentStudentResponseSetting question={question} />
      <PointsDisplay
        assessmentCorrect={assessmentCorrect}
        assessmentQuestionId={assessmentQuestionId}
        className={styles.littleRight}
        isTeacher={question.isOwner}
        onBlurScore={onBlurScore}
        pending={!question.learnerAnswer || question.learnerAnswer?.pendingCorrection}
        pointsPossible={question.pointsPossible}
        score={score}
        setScore={setScore}
        studentPersonId={question?.learnerAnswer?.userPersonId} />
      {question.answers && question.answers.length > 0 && question.answers.map((m, i) => {
        return (
          <div key={i} className={styles.checkboxLeft}>
            <CheckboxToggle
              id={i}
              label={alpha[i] + '. ' + (m.answerText || '')}
              disabled={disabledMessage || question?.learnerAnswer?.isSubmitted}
              checked={(isItemSetupPage && m.isCorrect)
                || ((isLearnerPage || isCorrectPage) && question?.learnerAnswer?.learnerAnswer?.indexOf(m.assessmentQuestionAnswerOptionId) > -1)
                ? 'yes'
                : (isItemSetupPage && !m.isCorrect)
                  ? 'no'
                  : (!isItemSetupPage && !question?.learnerAnswer)
                    ? 'PRERESPONSE'
                    : question?.learnerAnswer?.isSubmitted
                      ? 'no'
                      : 'PRERESPONSE'
              }
              onClick={disabledMessage
                ? () => disabledMessage() //() => toggleCheckbox(m.assessmentQuestionAnswerOptionId)
                : (checkboxValue) => handleAnswer(checkboxValue, question.learnerAnswer && question.learnerAnswer.learnerAnswer, m.assessmentQuestionAnswerOptionId)
                //: () => createInfoToastAuto(`<div>You are in the correction view. Changes to the answers cannot be made.</div>`)
              }
              personId={userPersonId}
              labelClass={classes((bigTextDisplay ? globalStyles.bigText : ''),
                question?.learnerAnswer?.isSubmitted
                  ? m.isCorrect
                    ? styles.labelCorrect
                    : styles.labelWrong
                  : styles.label
              )} />

            <div className={styles.bigIndent}>
              <MediaListViewer
                recordType={'AssessmentQuestion'}
                recordId={question.assessmentQuestionId}
                section={'answer'}
                index={i}
                userPersonId={userPersonId}
                runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
                mediaBinaries={question?.mediaBinaries} />
            </div>
          </div>
        )
      })}
      <MediaListViewer
        label={`Student's response`}
        recordType={`AssessmentLearnerAnswer${question?.learnerAnswer?.personId}`}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      {(isTeacher || assessmentCorrect) &&
        <AssessmentSolutionDisplay question={question} userPersonId={userPersonId} className={styles.solutionDisplay} />
      }
    </div>
  )
}

export default AssessmentMultipleAnswer