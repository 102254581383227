
export const hexToByteArray = (hexString) => {
  // Remove `0x` if it exists
  hexString = hexString.startsWith("0x") ? hexString.slice(2) : hexString

  // Split into pairs and convert to byte array
  const byteArray = new Uint8Array(
    hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  )

  return byteArray
}

export const base64ToBlob = (base64, mimeType) => {
  const base64Data = base64.includes(',') ? base64.split(',')[1] : base64.trim();
  try {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  } catch (error) {
    console.error('Error decoding Base64 string:', error);
    return null; // Return null if decoding fails
  }  
};

export const  convertPhotoBase64ToFile = (photoData) => {
  const base64 = photoData.split(",")[1]; // Get the base64 part
  const mimeType = photoData.split(",")[0].match(/:(.*?);/)[1]; // Extract MIME type
  const binaryString = atob(base64); // Decode base64 to binary string
  const binaryData = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }
  return new File([binaryData], `image.png`, { type: mimeType });
}

export const convertVideoBase64ToFile = () => {

}

export const convertFileToBase64 = async (file) => {
  const result = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result); // Contains the Base64 string
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file); // Reads the file as a Base64 data URL
  });
  return result;
}

export const getBase64Data = (dataURL) => {
  const base64Data = dataURL.split(",")[1];
  return base64Data;
};


export const hasMedia = (mediaBinaries, section) => {
  if (mediaBinaries?.length > 0 && mediaBinaries.filter(m => m.section === section)) {
    return true;
  }
}