import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router'
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './EditorInviteContactListView.module.css';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import ToastInputText from '../../components/ToastInputText';
import MessageModal from '../../components/MessageModal'
import OneFJefFooter from '../../components/OneFJefFooter';
import Checkbox from '../../components/Checkbox'
import InputText from '../../components/InputText'
import ContactSummaryLine from '../../components/ContactSummaryLine'
import classes from "classnames";
import { guidEmpty } from '../../utils/GuidValidate'

function EditorInviteContactListView(props) {
	const {
		personId,
		groupOptions,
		contacts = [],
		group = { contacts: [] },
		addOrUpdateGroup,
		getGroups,
		getGroupByName,
		groupByName
	} = props;

	//The paradigm for these lists is to list all of the contacts that a user has personally in any groups that they own as well as their individual contacts. 
	//This way they can choose any group contacts to become individual contacts (without a group) and then include them in another group after being in an original group.

	const navigate = useNavigate()
  const params = useParams()
	const [groupChosen, setGroupChosen] = useState()
	const [filterContactName, setFilterContactName] = useState('')
	const [contactsFiltered, setContactsFiltered] = useState()
	const [addNewGroup, setAddNewGroup] = useState()
	const [deletePermanent, setDeletePermanent] = useState({})
	
	useEffect(() => {
		setGroupChosen(groupByName && groupByName.groupId)
	}, [groupByName])

	useEffect(() => {
		setGroupChosen(params.groupChosen)
	}, [params.groupChosen])

	useEffect(() => {
	  let filtered = contacts && contacts.length > 0 ? [...contacts] : []
	  if (filterContactName)
	    filtered = filtered && filtered.length > 0 && filtered.filter(m => m.firstName.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1 || m.lastName.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1)
	  // if (groupChosen && groupChosen !== '0') {
	  //   filtered = filtered && filtered.length > 0 && filtered.filter(m => m.groupIds.indexOf(groupChosen) > -1)
	  // }
	  setContactsFiltered(filtered)
	}, [contacts, filterContactName]) //, groupChosen

	const isChecked = (personId) => {
	  const isAssigned = group.contacts && group.contacts.length > 0 && group.contacts.filter(f => f.personId === personId)[0]
	  return !!isAssigned
	}

	const submitAddNewGroup = (groupName) => {
		Promise.all([addOrUpdateGroup({
			groupId: guidEmpty,
			personId,
			groupName,
			languageId: 1,
			internalId: '',
			description: '',
			workFolderId: '',
		}, `NONE`)]).then(setTimeout(() => getGroups(personId), 500)).then(setTimeout(() => getGroupByName(personId, groupName), 500))
		setAddNewGroup(false)
	}

	const handleToggleContact = (contact, group) => {
		if (group.groupId === guidEmpty && !(contact.groups && contact.groups.length > 0)) {
			setDeletePermanent({ contact, group})
		} else {
			props.assignContactToGroup(personId, contact.personId, group.groupId, contact.firstName, contact.lastName, contact.photo, contact.emailAddress, isChecked(contact.personId))
		}
	}

	const submitDeletePermanent = () => {
		const {contact, group} = deletePermanent
		props.assignContactToGroup(personId, contact.personId, group.groupId, contact.firstName, contact.lastName, contact.photo, contact.emailAddress, isChecked(contact.personId))
		setDeletePermanent({})
	}

	return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={styles.titleLine}>
          <span className={globalStyles.pageTitle}>My Contact List</span>
        </div>
				<div className={classes(styles.row, styles.moreBottom)}>
					<div>
						<div>
							<span htmlFor={'groupList'} className={styles.labelText}>Group</span>
						</div>
						<select
							id={'groupList'}
							value={groupChosen || ''}
							onChange={(event) => {
								setGroupChosen(event.target.value)
								if (event.target.value === 'NEWGROUP') {
									setAddNewGroup(true)
								} else {
									props.getGroupContactsWorks(personId, event.target.value)
								}
							}}
							className={styles.selectList}
						>
							<option value="0">Individual Contacts</option>
							{groupOptions && groupOptions.length > 0 && groupOptions.map((option, index) => <option key={index} value={option.id} dangerouslySetInnerHTML={{ __html: option.label }}></option>)}
							<optgroup key={9999} label={'------------------------'}>
								<option value='NEWGROUP'>Add a New Group</option>
							</optgroup>
						</select>
					</div>
					<InputText
						size={"medium"}
						name={"filterContactName"}
						label={`Filter contact name`}
						value={filterContactName}
						onChange={(event) => setFilterContactName(event.target.value)} />
				</div>
				<div className={styles.works}>
					{contactsFiltered && contactsFiltered.length > 0 && contactsFiltered.map((w, i) =>
						<div className={styles.row} key={i + 'summary'}>
							<Checkbox
								label={``}
								checked={isChecked(w.personId)}
								onClick={() => handleToggleContact(w, group)}/>
							<div onClick={() => handleToggleContact(w, group)} className={styles.pointer}>
								<ContactSummaryLine contact={w} nameLength={150} keyIndex={i + 'line'} LighterBackground showGroupNames/>
							</div>
						</div>
					)}
					{!(contacts && contacts.length > 0) && 
						<div className={styles.noContacts}>There are not any individual contacts to include in this group, yet</div>
					}
				</div>

				<div className={styles.buttonWidth}>
					<ButtonWithIcon icon={'checkmark0'} onClick={() => navigate(-1)} label={'Done'} submitSuccess={false}/>
				</div>
        <OneFJefFooter/>
      </div>
			<ToastInputText
				show={addNewGroup}
				label={`Group name`}
				message="New Group Name"
				onSubmit={submitAddNewGroup}
				onClose={() => setAddNewGroup(false)} />
			<MessageModal show={Object.keys(deletePermanent).length > 0}
				handleClose={() => setDeletePermanent({})}
				heading={`Permanently Delete This Contact?`}
				explain={`This contact doesn't belong to a group. If you delete this contact from your individual contacts, yu would have to reinvite the person.`}
				onClick={submitDeletePermanent} isConfirmType />

		</div>
	);
}

export default EditorInviteContactListView;
