import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import * as editorService from '../../../services/editor-dom'
import AssessmentEssayControlsOptions from '../AssessmentEssayControlsOptions'
import AssessmentResponseTypeRequired from '../AssessmentResponseTypeRequired'
import AssessmentSolutionDisplay from '../AssessmentSolutionDisplay'
import AssessmentStudentResponseSetting from '../../Assessment/AssessmentStudentResponseSetting'
import classes from 'classnames'
import globalStyles from '../../../utils/globalStyles.module.css'
import Icon from '../../Icon'
import MediaListViewer from '../../Media/MediaListViewer'
import PointsDisplay from '../../PointsDisplay'
import QuestionLabel from '../../QuestionLabel'
import styles from './AssessmentEssay.module.css'
import TextDisplay from '../../TextDisplay'


function AssessmentEssay(props) {
  const {
    assessmentCorrect,
    bigTextDisplay,
    className = "",
    createWorkInAssessment,
    isTeacher,
    listLevelGeneral,
    mediaBinaries,
    nameKey,
    onBlurScore,
    updateAssessmentQuestionAnswerCorrect,
    userPersonId,
    score,
    setScore, 
  } = props

  const location = useLocation();
  const isLearnerPage = location.pathname.indexOf('assessmentLearner') > -1
  const isCorrectPage = location.pathname.indexOf('assessmentCorrect') > -1
  const isItemSetupPage = location.pathname.indexOf('assessmentQuestion') > -1 || location.pathname.indexOf('assessmentItemSetup') > -1

  const [entry, setEntry] = useState({
    essayResponse: '',
    score: '',
    isCorrect: '',
  })
  const [correct, setCorrect] = useState({})
  const [question, setQuestion] = useState({})

  useEffect(() => {
    const workSummary = question?.learnerAnswer?.workSummary
    if (workSummary) {
      editorService.setSegments({
        addChapterListLevels: () => {},
        chapterId: workSummary.chapterId_current,
        chapterListLevels: [],
        chosenTab: null,
        divDisplayId: editorService.getEditorDivId(workSummary.chapterId_current),
        editorName: {firstName: workSummary.firstName, lastName: workSummary.lastName },
        edits: workSummary.editSegments,
        isAuthor: true,
        isTranslation:false,
        listLevelGeneral,
        personId: userPersonId,
        segments: workSummary.workSegments,
        showEditIcons: true,
        tabsData: [],
        workSummary,
        removeEmptyParagraphs: true,
      })
    }
  }, [question])

  useEffect(() => {
    if (props.question) {
      setQuestion(props.question)
    }
  }, [props.question])

  useEffect(() => {
    let correct = (assessmentCorrect?.length > 0 && assessmentCorrect.filter(m => m.assessmentQuestionId === question.assessmentQuestionId)[0]) || {}
    if (assessmentCorrect !== props.assessmentCorrect) {
      setEntry({
        essayResponse: isTeacher && assessmentCorrect ? assessmentCorrect.teacherEssayResponse : assessmentCorrect ? assessmentCorrect.learnerAnswer : '',
        score: correct && correct.score,
        isCorrect: correct && correct.isCorrect,
      })
    }
    setCorrect(correct)
  }, [props.assessmentCorrect])

  const handleCreateWorkInAssessment = () => {
    createWorkInAssessment(question.assessmentQuestionId)
  }

  return (
    <div className={classes(className, styles.container)} key={nameKey}>
      <QuestionLabel label={'Essay'} />
      <div className={classes(styles.row, styles.questionLine)}>
        {assessmentCorrect && assessmentCorrect.length > 0
          ? correct.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
        <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>
          {question.sequence}.
        </div>
        <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>
          {question.questionText}
        </div>
      </div>
      <MediaListViewer
        recordType={'AssessmentQuestion'}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      <AssessmentStudentResponseSetting question={question} />

      <PointsDisplay 
        assessmentCorrect={assessmentCorrect}
        assessmentQuestionId={question.assessmentQuestionId}
        isCorrect={question?.learnerAnswer?.isCorrect}
        isTeacher={question.isOwner}
        onBlurScore={onBlurScore}
        pending={!question.learnerAnswer || question.learnerAnswer?.pendingCorrection}
        onCorrectShowIsCorrect={true}
        pointsPossible={question.pointsPossible} 
        updateAssessmentQuestionAnswerCorrect={updateAssessmentQuestionAnswerCorrect}
        score={score || question.learnerAnswer?.score}
        setScore={setScore} 
        studentPersonId={question?.learnerAnswer?.personId} />

      <hr />
      <AssessmentEssayControlsOptions 
        handleCreateWorkInAssessment={handleCreateWorkInAssessment}
        isCorrectPage={isCorrectPage}
        isItemSetupPage={isItemSetupPage}
        isLearnerPage={isLearnerPage}
        isTeacher={isTeacher}
        question={question} />
      <hr />
      <MediaListViewer
        label={`Student's response`}
        recordType={`AssessmentLearnerAnswer${question?.learnerAnswer?.personId}`}
        recordId={question.assessmentQuestionId}
        section={'question'}
        index={0}
        userPersonId={userPersonId}
        runFunction={() => props.getAssessmentQuestions(userPersonId, question.assessmentId)}
        mediaBinaries={mediaBinaries} />
      {(isTeacher || assessmentCorrect) &&
        <AssessmentSolutionDisplay question={question} userPersonId={userPersonId} className={styles.solutionDisplay} isTeacher={isTeacher} />
      }
    </div>
  )
}

export default AssessmentEssay