import React from 'react';
import styles from './EditListChoice.module.css';

function EditListChoice(props) {
  const {
    editOptions = [],
    handleSetEditChosen,
  } = props;

  const getEditChosen = (event) => {
    const id = event.target.value
    let editOption = editOptions.filter(m => Number(m.id) === Number(id))[0]
    if (editOption) handleSetEditChosen(id, id ? editOption.label : '')
  }

  return (
    <div>
      <select 
          key={'Edits'}
          onChange={(event) => getEditChosen(event)} 
          title={'Edit counts and locations'} 
          className={styles.selectList}>

        <option value="">edits</option>
        {editOptions?.length > 0 && editOptions.map((m, i) => 
          <option key={i} value={m.id}>{m.label}</option>
        )}
        
      </select>
      <div className={styles.count}>{editOptions?.length || 0}</div>
    </div>
  )
}

export default EditListChoice