import React from 'react'
import styles from './DiscussionStats.module.css'
import DateMoment from '../DateMoment'

function DiscussionStats(props) {
	const {
    darkerLabel,
    includeDates,
    includeEditors,
    isTranslation,
    labelWhite,
    maroonBackground,
    work = {discussionStats: {}},
	} = props

	return (
    <div className={styles.rowTight}>
      <div title={'Posts'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Posts</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.discussionStats?.posts || '0'}</div>
      </div>
      <div title={'Replies'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Replies</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.discussionStats?.replies || '0'}</div>
      </div>
      {work.activeDate && includeDates &&
        <div title={'Active date'} className={styles.row}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>active</div>
          <div className={darkerLabel ? styles.countDark : styles.countWhite}>
            {!work.activeDate ? '- -' :
              <DateMoment date={work.activeDate} label={''} format={'D MMM YYYY'} className={styles.date}
                dateTextClass={styles.dateTextClass} />}
          </div>
        </div>
      }
      {work.dueDate && includeDates &&
        <div title={'Due date'} className={styles.row}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>due</div>
          <div className={darkerLabel ? styles.countDark : styles.countWhite}>
            {!work.dueDate ? '- -' :
              <DateMoment date={work.dueDate} label={''} format={'D MMM YYYY'} className={styles.date}
                dateTextClass={styles.dateTextClass} />}
          </div>
        </div>
      }
    </div>	)
}

export default DiscussionStats