import React, { useState, useEffect } from 'react';
import styles from './WebsiteLinkEntry.module.css';
import {Popover} from "@mui/material";
import InputText from "../InputText";
import ButtonWithIcon from "../ButtonWithIcon";
import { createInfoToastAuto } from '../../services/queryClient'

function WebsiteLinkEntry(props) {
	const {
    isOpen, 
    onSave, 
    onCancel, 
    anchorNode 
  } = props;

	const [websiteLink, setWebsiteLink] = useState('https://')
	const [linkDisplayText, setLinkDisplayText] = useState('')
	const [websiteLinkError, setWebsiteLinkError] = useState('')
	const [linkDisplayTextError, setLinkDisplayTextError] = useState('')

	useEffect(() => {
		if (anchorNode) {
			setWebsiteLink(anchorNode.nodeName === 'A' ? anchorNode.dataset.hrefLink : '')
			setLinkDisplayText(anchorNode.nodeName === 'A' ? anchorNode.innerText : '')
		}
	}, [anchorNode])

  useEffect(() => {
    return () => {
      setLinkDisplayText('')
      setWebsiteLink('https://')
    }
  }, [])

	const processForm = () => {
    let missingFields = ''

		if (!websiteLink) {
			setWebsiteLinkError('A website link is required.')
      missingFields += "\nA website link is required."
		} else if (!isValidWebsite(websiteLink)) {
      missingFields += "\nThe website doesn't seem to be valid."
      if (websiteLink.indexOf('https') === -1) {
        missingFields += "\nhttps is required."
      }
    }
		if (!linkDisplayText) {
      missingFields += "\nA display name is required."
		}
    if (missingFields) {
      createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`)
    } else {
      onSave(websiteLink, linkDisplayText)
      setLinkDisplayText('')
      setWebsiteLink('https://')
      onCancel()
    }
	}

  const isValidWebsite = (url) => {
    const websiteRegex = /^https:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return websiteRegex.test(url);
  }


	return (
	  <Popover
		  open={isOpen}
		  anchorReference="anchorPosition"
		  anchorPosition={{ top: 100, left: 100 }}
		  anchorOrigin={{
			  vertical: 'top',
			  horizontal: 'left',
		  }}
		  transformOrigin={{
			  vertical: 'top',
			  horizontal: 'left',
		  }}
	  >
		  <div className={styles.container}>
			  Enter a website address with the text you want displayed
			  <InputText
				  size={"long"}
				  value={websiteLink}
				  label={`Website`}
				  onChange={(event) => {
					  setWebsiteLink(event.target.value)
					  setWebsiteLinkError('')
				  }}
				  required={true}
          whenFilled={websiteLink && isValidWebsite(websiteLink)}
				  error={websiteLinkError} />

			  <InputText
				  size={"long"}
				  value={linkDisplayText}
				  label={`Display name for the website`}
				  onChange={(event) => {
					  setLinkDisplayText(event.target.value)
					  setLinkDisplayTextError('')
				  }}
				  required={true}
				  whenFilled={linkDisplayText}
				  error={linkDisplayTextError} />

				<div className={styles.rowRight}>
				  <a className={styles.noButton} onClick={onCancel}>Cancel</a>
					<ButtonWithIcon label={'Save'} icon={'link2'} onClick={processForm}/>
			  </div>
		  </div>
	  </Popover>
  )
}


export default WebsiteLinkEntry;