import * as types from '../actions/actionTypes';

export default function (state = [], action) {
	switch (action.type) {
		case types.GROUP_MEMBERS_INIT: {
			return action.payload;
		}

		default:
			return state;
	}
}

export const selectGroupMembers = (state) => state;
