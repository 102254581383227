import React from "react"
import styles from "./EditTableStickyLeft.module.css"
import classes from 'classnames'

function EditTableStickyLeft(props) {
  const { 
    className = "", 
    data = [], 
    headings = [], 
    isMobile,
    noColorStripe, 
    sendToReport, 
  } = props

  
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          {headings && 
            <tr>
              <th className={classes(styles.stickyColumn, styles.narrow)}></th>
                {headings.map((heading, i) => {
                  return (
                  <th key={`th${i}`}
                    onClick={heading.pathLink
                      ? () => sendToReport(heading.pathLink)
                      : heading.clickFunction
                        ? heading.clickFunction
                        : () => { }}
                    className={styles.rotate}>
                    {heading.label &&
                      <div data-rh={heading.reactHint} title={heading.reactHint}>
                        {heading.label}
                        {heading.subLabel}
                        {heading.languageNames && heading.languageNames.length > 0 &&
                          heading.languageNames.map((lang, index) => {  //eslint-disable-line
                            if (heading.workLanguageName !== lang) {
                              return <div key={index} className={styles.languageName}>{lang}</div>
                            }
                          })
                        }
                      </div>
                    }
                  </th>
                )})
              }
            </tr>
          }
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={`tr${i}`}>
              {row?.length > 0 && row.map((cell, index) => {
                return (
                  <td className={classes(index === 0 ? styles.stickyColumn : '', isMobile ? styles.narrow : '')}
                      key={`td${index}`}
                      data-rh={cell.reactHint}
                      title={cell.reactHint}
                      colSpan={cell.colSpan ? 20 : 1}
                      onClick={cell.pathLink
                        ? () => sendToReport(cell.pathLink)
                        : cell.clickFunction
                          ? cell.clickFunction
                          : () => { }
                      } 
                      style={{ color: cell.color ? cell.color : '', textAlign: index > 0 ? 'center' : 'left' }}>
                    {cell.value === 0 ? '' : cell.value}
                  </td>
              )}
            )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default EditTableStickyLeft
