import React, { useEffect, useRef, useState } from 'react'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist' // For PDFs
import { renderAsync } from 'docx-preview' // For Word documents
import ButtonWithIcon from '../../ButtonWithIcon'
import { useMediaQuery } from "react-responsive"
import classes from 'classnames'
import styles from './FileUploadDisplay.module.css'
// Configure PDF.js worker
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.15.349/pdf.worker.min.js`

export default function FileUploadDisplay(props) {
  const {
    acceptAndSubmitFile,
    fileUrl,
    fileType,
    isOpen,
    setIsOpen,
  } = props

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [numPages, setNumPages] = useState(0) // Total PDF pages
  const [pageNumber, setPageNumber] = useState(1) // Current PDF page
  const canvasRef = useRef(null) // Reference for rendering PDF canvas
  const containerRef = useRef(null)

  useEffect(() => {
    const renderPDF = async () => {
      if (!(fileUrl?.type) || !canvasRef.current) return

      const canvas = canvasRef.current
      const context = canvas.getContext('2d')

      try {
        // Load the PDF document
        const pdf = await getDocument(fileUrl).promise
        setNumPages(pdf.numPages)

        // Get the specified page
        const page = await pdf.getPage(pageNumber)

        const viewport = page.getViewport({ scale: 1.5 })
        canvas.width = viewport.width
        canvas.height = viewport.height

        // Render the page on the canvas
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        }
        await page.render(renderContext).promise
      } catch (error) {
        console.error("Error rendering PDF:", error)
      }
    }

    renderPDF()
  }, [fileUrl, pageNumber])

  const renderDocx = async (fileBlob) => {
    const container = document.getElementById("docx-container")
    container.innerHTML = "" // Clear any previous content
    await renderAsync(fileBlob, container)
  }

  const renderFilePreview = () => {
    if (!fileUrl || !fileType) return null;

    if (fileType === "application/pdf") {
      // Render PDF
      return (
        <div>
          <canvas ref={canvasRef} style={{ border: '1px solid black' }} />
          <div style={{ marginTop: '10px' }}>
            <button
              onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
              disabled={pageNumber <= 1}
            >
              Previous Page
            </button>
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={() => setPageNumber((prev) => Math.min(prev + 1, numPages))}
              disabled={pageNumber >= numPages}
            >
              Next Page
            </button>
          </div>
        </div>
      );
    } else if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      // Render DOCX using docx-preview
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => renderDocx(blob))
        .catch((error) => console.error("Error rendering DOCX file:", error));

      return (
        <div
          id="docx-container"
          style={{ width: "100%", height: "500px", overflow: "auto" }}
        ></div>
      );
    } else if (fileType.startsWith("image/")) {
      // Render images (e.g., jpg, png, gif, bmp)
      return <img src={fileUrl} alt="Preview" style={{ maxWidth: "100%", height: "auto" }} />;
    } else if (fileType === "text/plain") {
      // Render plain text files
      return (
        <iframe
          src={fileUrl}
          style={{
            width: "100%",
            height: "500px",
            border: "1px solid #ccc",
          }}
          title="Text File Preview"
        ></iframe>
      );
    } else if (fileType === "application/json") {
      // Render JSON files
      return (
        <iframe
          src={fileUrl}
          style={{
            width: "100%",
            height: "500px",
            border: "1px solid #ccc",
          }}
          title="JSON File Preview"
        ></iframe>
      );
    } else {
      // Handle unsupported file types
      return <p>Preview not available for this file type.</p>;
    }
  };

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)} key={isOpen ? 'open' : 'closed'}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              {acceptAndSubmitFile && 
                <span className={styles.cancelButton} onClick={() => setIsOpen(false)}>
                  Cancel
                </span>
              }
              <ButtonWithIcon 
                label={acceptAndSubmitFile ? 'Submit' : 'Close'} 
                icon={'checkmark0'} 
                onClick={acceptAndSubmitFile ? () => acceptAndSubmitFile() : () => setIsOpen(false)} 
                submitSuccess={false} />
            </div>
          </div>
          {fileUrl && (
            <div className={styles.previewContainer}>
              <h2>Preview:</h2>
              {renderFilePreview()}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
