import React, { useState, useEffect } from 'react'
import { createConfirmToastAuto, createInfoToastAuto } from '../../../services/queryClient.js'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from "react-responsive"
import { useNavigate } from 'react-router'
import AssessmentEssay from '../../../components/Assessment/AssessmentEssay'
import AssessmentFillBlank from '../../../components/Assessment/AssessmentFillBlank'
import AssessmentMatching from '../../../components/Assessment/AssessmentMatching'
import AssessmentMultipleAnswer from '../../../components/Assessment/AssessmentMultipleAnswer'
import AssessmentMultipleChoice from '../../../components/Assessment/AssessmentMultipleChoice'
import AssessmentPassage from '../../../components/Assessment/AssessmentPassage'
import AssessmentTrueFalse from '../../../components/Assessment/AssessmentTrueFalse'
import classes from 'classnames'
import EditTable from '../../../components/EditTable'
import globalStyles from '../../../utils/globalStyles.module.css'
import Icon from '../../../components/Icon'
import MediaListViewer from '../../../components/Media/MediaListViewer'
import OneFJefFooter from '../../../components/OneFJefFooter'
import RadioGroup from '../../../components/RadioGroup'
import SelectSingleDropDown from '../../../components/SelectSingleDropDown'
import styles from './AssessmentCorrectView.module.css'
import TextDisplay from '../../../components/TextDisplay'

function AssessmentCorrectView(props) {
  const {
    assessment,
    assessmentCorrect = {},
    assessmentId,
    getAssessmentQuestions, 
    assignmentId,
    clearAssessmentCorrect,
    clearAssessmentQuestion,
    correctionTypeFilter,
    getCorrectedAssessment,
    groupMembers,
    isTeacher,
    listLevelGeneral,
    userPersonId,
    questionTypeFilter,
    retakeTest,
    updateTeacherAssessmentLearnerAnswer,
    updateAssessmentQuestionAnswerCorrect,
  } = props

  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [correctionTypes, setCorrectionTypes] = useState('all')
  const [isSearch, setIsSearch] = useState(false)
  const [questionTypes, setQuestionTypes] = useState('all')
  const [studentPersonId, setStudentPersonId] = useState()
  const [displayCurrentStudent, setDisplayCurrentStudent] = useState()

  useEffect(() => {
    let searchId = studentPersonId
    if (!searchId) searchId = props.studentPersonId
    const chosen = groupMembers?.length > 0 && groupMembers.filter(m => m.id === searchId)[0]
    if (chosen) {
      setDisplayCurrentStudent(chosen.label)
      setStudentPersonId(chosen.id)
    }
  }, [props.studentPersonId, studentPersonId, groupMembers])

  useEffect(() => {
    localStorage.setItem('preEditReviewPage', location.pathname)
    return () => {
      clearAssessmentQuestion()
      clearAssessmentCorrect()
    }
  }, [])

  const showViewOnlyMessage = () => {
    createInfoToastAuto('<div>You are in the assessment correction page.<br/>The answers cannot be changed here.</div>')
  }

  const toggleSearch = () => {
    setIsSearch(!isSearch)
  }

  const changeCorrectionTypes = (correctionTypes) => {
    setCorrectionTypes(correctionTypes)
  }

  const changeQuestionTypes = (questionTypes) => {
    setQuestionTypes(questionTypes)
  }

  const handleLearnerChange = (event) => {
    setStudentPersonId(event.target.value)
    getCorrectedAssessment(userPersonId, event.target.value, assessmentId, assignmentId)
    getAssessmentQuestions(event.target.value, assessmentId, assignmentId)
    navigate(`/assessmentCorrect/${assignmentId}/${assessmentId}/${event.target.value}`)
  }

  const handleRetakeTestOpen = () => createConfirmToastAuto('<div>Are you sure you want to retake this assignment?</div>', handleRetakeTest)
  const handleRetakeTest = () => {
    const runFunction = () => navigate(`/assessmentLearner/${assignmentId}/${assessmentId}`)
    retakeTest(userPersonId, assignmentId, assessmentId, runFunction)
  }

  const onBlurScore = (score, studentPersonId, assessmentQuestionId) => {
    if (isTeacher) {
      updateTeacherAssessmentLearnerAnswer(studentPersonId, assessmentQuestionId, score)
    }
  }

  const setScore = (score, studentPersonId, assessmentQuestionId) => {
    props.setLocalScore(studentPersonId, assessmentQuestionId, score)
  }

  let canViewAnswers = assessment.personId !== userPersonId //assessment.personId is the teacher
    ? assessment.showAnswersImmediately
    : true

  let headings = [
    { label: '%', tightText: true },
    { label: 'Questions', tightText: true },
    { label: 'Possible', tightText: true },
    { label: 'Score', tightText: true },
    { label: 'Score Pending', tightText: true },
    { label: 'Pending Correction', tightText: true },
  ]

  let data = []

  let questions = props.assessmentQuestions

  if (questions?.length > 0) {
    let score = Math.round(questions.reduce((acc, m) => acc + m.learnerAnswer?.score, 0) * 10) / 10
    let scorePending = Math.round(questions.reduce((acc, m) => acc + (m.learnerAnswer?.pendingCorrection && m.questionTypeCode !== 'PASSAGE' ? m.pointsPossible : 0), 0) * 10) / 10
    let pendingCorrection = questions.reduce((acc, m) => acc + (!m.learnerAnswer || m.learnerAnswer.pendingCorrection ? 1 : 0), 0)
    let totalPoints = Math.round(questions.reduce((acc, m) => acc + m.pointsPossible, 0) * 10) / 10
    let questionsCount = questions.length
    let scorePercent = (totalPoints - scorePending) > 0 ? Math.round((score / (totalPoints - scorePending)) * 100) : 0

    data = [[
      { value: scorePending ? '--' : scorePercent + '%', boldText: true },
      { value: questionsCount, boldText: true },
      { value: totalPoints, boldText: true },
      { value: Number(score), boldText: true },
      { value: <div className={styles.red}>{!!scorePending ? scorePending : ''}</div>, boldText: true },
      { value: <div className={styles.red}>{!!pendingCorrection ? pendingCorrection : ''}</div>, boldText: true },
    ]]
  }

  let filteredQuestions = [...props.assessmentQuestions]

  if (correctionTypes !== "all" && questions?.length > 0) {
    let questionIds = []
    if (correctionTypes === "pendingEssays") {
      questionIds = questions.reduce((acc, m) => m.pendingCorrection ? acc.concat(m.assessmentQuestionId) : acc, [])
    } else if (correctionTypes === "correctAnswers") {
      questionIds = questions.reduce((acc, m) => m.isCorrect ? acc.concat(m.assessmentQuestionId) : acc, [])
    } else if (correctionTypes === "wrongAnswers") {
      questionIds = questions.reduce((acc, m) => !m.isCorrect && !m.pendingCorrection ? acc.concat(m.assessmentQuestionId) : acc, [])
    }
    filteredQuestions = filteredQuestions.filter(m => questionIds.indexOf(m.assessmentQuestionId) > -1)
  }

  if (questionTypes !== "all" && filteredQuestions && filteredQuestions.length > 0) {
    filteredQuestions = filteredQuestions.filter(m => m.questionTypeCode === questionTypes)
  }

  const reTakeNotAvailable = () => {
    if (!assessmentCorrect.canRetakeQuiz) {
      return <div className={styles.retake}>Not available</div>
    } else if (!(assessment.dueDate && assessment.dueDate < new Date())) {
      return <div className={styles.retake}>Due date is past</div>
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Assessment Result
        </div>
        <div className={(styles.moreTop, styles.rowWrap)}>
          <TextDisplay label={'Current Student'} className={styles.textDisplay} text={displayCurrentStudent} />
          <TextDisplay label={'Assessment'} className={styles.textDisplay} text={assessment?.name} />
          {isTeacher && //This is the teacher
            <TextDisplay 
              label={'Summary Report'} 
              className={styles.textDisplay} 
              text={<div onClick={() => navigate(`/assessmentCorrectSummary/${assessment.assignmentId}/${assessment.assessmentId}`)} className={styles.linkSummary}>&lt; Go to summary report</div>} />
          }
          {/* <TextDisplay label={benchmarkTestId ? `Benchmark assessment` : `Assessment`} className={styles.textDisplay} text={assessment && assessment.name} /> */}
          {!isTeacher && 
            <a onClick={reTakeNotAvailable() ? () => {} : handleRetakeTestOpen} className={reTakeNotAvailable() ? styles.notLink : styles.link}>
              Re-take {reTakeNotAvailable()}
            </a>
          }
          {/* {isTeacher &&
          <Link to={`/gradebookEntry/${courseScheduledId}`} className={classes(styles.link, styles.row)}>
            <Icon pathName={'medal_empty'} premium={true} />'Go to Gradebook'
          </Link>
        } */}
        </div>
        {isTeacher &&
          <div>
            <SelectSingleDropDown
              id={'studentPersonId'}
              value={studentPersonId || ''}
              label={'Student (only completed assessments are available)'}
              options={groupMembers}
              height={`medium`}
              noBlank={false}
              className={styles.singleDropDown}
              onChange={handleLearnerChange} />
          </div>
        }
        <hr />
        <div className={styles.rowWrap}>
          <div className={styles.backgroundBlue}>
            <EditTable 
              labelClass={styles.tableLabelClass} 
              headings={headings} 
              data={data} 
              noCount={true} 
              isMobile={isMobile}
              firstColumnClass={styles.firstColumnClass} />
          </div>
          {/* {assessmentCorrect.gradingType === 'STANDARDSRATING' && standardsRatings && standardsRatings.length > 0 &&
          <StandardsAssignmentResult scores={details} standardsRatings={standardsRatings} horizontal={false} showRightCode={true} />
        } */}
        </div>
        {canViewAnswers &&
          <a onClick={toggleSearch} className={classes(styles.row, styles.search)}>
            <Icon pathName={'magnifier'} premium={true} className={styles.icon} />
            {isSearch ? 'Hide filter controls' : 'Show filter choices for question types?'}
          </a>
        }
        <hr />
        {isSearch && canViewAnswers &&
          <div className={styles.backgroundBlue}>
            <RadioGroup
              title={'View correction types'}
              data={correctionTypeFilter}
              horizontal={true}
              className={styles.radio}
              initialValue={correctionTypes || 'all'}
              onClick={changeCorrectionTypes} />
            <hr />
            <RadioGroup
              title={'View question types'}
              data={questionTypeFilter}
              horizontal={true}
              className={styles.radio}
              initialValue={questionTypes || 'all'}
              onClick={changeQuestionTypes} />
            <hr />
          </div>
        }
        {canViewAnswers && filteredQuestions?.length > 0 && filteredQuestions.map((m, i) => {
          //let correct = (details && details.length > 0 && details.filter(m => m.assessmentQuestionId === m.assessmentQuestionId)[0]) || {}
          let includeCorrectControls = false
          //let scoreRecord = details && details.length > 0 && details.filter(f => f.assessmentQuestionId === m.assessmentQuestionId)[0]
          //let score = scoreRecord && scoreRecord.assessmentQuestionId ? scoreRecord.score : ''
          let score = m.learnerAnswer && m.learnerAnswer.score

          if (isTeacher) {
            includeCorrectControls = true
          }

          if (m.questionTypeCode === 'TRUEFALSE') {
            return (
              <div key={i}>
                <AssessmentTrueFalse
                  assessmentCorrect={assessmentCorrect}
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay}
                  disabled={assessmentCorrect?.assessmentId}
                  disabledMessage={showViewOnlyMessage}
                  isTeacher={isTeacher}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onBlurScore={onBlurScore}
                  question={m} 
                  score={score} 
                  setScore={setScore}
                  userPersonId={userPersonId} />
              </div>
            )
          } else if (m.questionTypeCode === 'MULTIPLECHOICE') {
            return (
              <div key={i}>
                <AssessmentMultipleChoice
                  assessmentCorrect={assessmentCorrect}
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay}
                  disabled={assessmentCorrect?.assessmentId}
                  disabledMessage={showViewOnlyMessage}
                  isTeacher={isTeacher}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onBlurScore={onBlurScore}
                  question={m}
                  score={score}
                  setScore={setScore}
                  userPersonId={userPersonId}
                  viewMode={'CorrectedView'} />
              </div>
            )
          } else if (m.questionTypeCode === 'MULTIPLEANSWER') {
            return (
              <div key={i}>
                <AssessmentMultipleAnswer
                  answers={m.answers}
                  assessmentCorrect={assessmentCorrect}
                  assessmentQuestionId={m.assessmentQuestionId}
                  disabled={assessmentCorrect?.assessmentId}
                  disabledMessage={showViewOnlyMessage}
                  bigTextDisplay={assessment.bigTextDisplay}
                  includeCorrectControls={includeCorrectControls}
                  isTeacher={isTeacher}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onBlurScore={onBlurScore}
                  userPersonId={userPersonId}
                  question={m}
                  score={score} 
                  setScore={setScore}
                  viewMode={'CorrectedView'}
                  />
              </div>
            )
          } else if (m.questionTypeCode === 'ESSAY') {
            return (
              <div key={i}>
                <AssessmentEssay
                  assessmentCorrect={assessmentCorrect}
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay}
                  includeCorrectControls={includeCorrectControls}
                  isSubmitted={true}
                  isTeacher={isTeacher}
                  keywords={m.keywordPhrases}
                  listLevelGeneral={listLevelGeneral}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onBlurScore={onBlurScore}
                  question={m}
                  score={score}
                  setScore={setScore}  
                  updateAssessmentQuestionAnswerCorrect={updateAssessmentQuestionAnswerCorrect}
                  userPersonId={userPersonId} />
                {/*<TextDisplay text={m.keywordCountAccuracy || score ? `${score} out of ${m.pointsPossible}` : `pending teacher review`} label={'Points'} />*/}
              </div>
            )
          } else if (m.questionTypeCode === 'PASSAGE') {
            return (
              <div key={i}>
                <AssessmentPassage
                  isTeacher={isTeacher}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  question={m}
                  userPersonId={userPersonId} />
              </div>
            )
          } else if (m.questionTypeCode === 'FILLBLANK') {
            return (
              <div key={i}>
                <AssessmentFillBlank
                  answerVariations={m.answerVariations}
                  assessmentCorrect={assessmentCorrect}
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay}
                  includeCorrectControls={includeCorrectControls}
                  isTeacher={isTeacher}
                  learnerAnswer={m.learnerAnswer}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onBlurScore={onBlurScore}
                  question={m}
                  score={score} 
                  setScore={setScore} 
                  userPersonId={userPersonId} />
              </div>
            )
          } else if (m.questionTypeCode === 'MATCHING') {
            return (
              <div key={i}>
                <AssessmentMatching
                  answers={m?.answers}
                  assessmentCorrect={assessmentCorrect}
                  assessmentQuestionId={m.assessmentQuestionId}
                  bigTextDisplay={assessment.bigTextDisplay}
                  isTeacher={isTeacher}
                  learnerAnswer={m.learnerAnswer}
                  matches={m?.matches}
                  mediaBinaries={m?.mediaBinaries}
                  nameKey={i}
                  onBlurScore={onBlurScore}
                  question={m}
                  score={score} 
                  setScore={setScore}
                  userPersonId={userPersonId}
                  viewMode={'CorrectedView'} />
              </div>
            )
          }
          return null
        })}
      </div>
      <OneFJefFooter />
    </div>
  )
}

export default AssessmentCorrectView