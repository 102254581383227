export const formatDayShortMonthYear = (dateText, hasDashes=false) => {
    if (!dateText) {
        return '';
    }
    let newDate;

    if (hasDashes && dateText.indexOf("-") === -1) return dateText;

    if (hasDashes) {
        newDate = new Date(dateText.substring(0,10));
    } else {
        newDate = new Date(dateText.substring(0,4) + '-' + dateText.substring(4,6) + '-' + dateText.substring(6,8));
    }
    newDate.setDate(newDate.getDate() + 1);
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    let day = newDate.getDate();
    let monthIndex = newDate.getMonth(); //Notice that the monthNames array, in this case, starts in index 0 which matches with getMonth which is also zero based..
    let year = newDate.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

export const formatDateYYYYMMDDHHMMSS = (incomingDate) => {
  const newDate = new Date(incomingDate)
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const date = String(newDate.getDate()).padStart(2, '0');
  const hours = String(newDate.getHours()).padStart(2, '0');
  const minutes = String(newDate.getMinutes()).padStart(2, '0');
  const seconds = String(newDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
}

export default formatDayShortMonthYear;
