import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router'
import styles from './GroupAddNewView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import InputText from '../../components/InputText';
import InputTextArea from '../../components/InputTextArea';
import MessageModal from '../../components/MessageModal';
import Icon from '../../components/Icon';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import OneFJefFooter from '../../components/OneFJefFooter';
import {guidEmpty} from '../../utils/GuidValidate'
import { useAppContext } from "../../../AppContext.js";

function GroupAddNewView(props) {
  let {groupTypeDescription, workFolders, paramWorkFolderId, groupId, groups} = props;
  const navigate = useNavigate()
  const params = useParams()
  const { pageVisitor } = useAppContext();

  const [group, setGroup] = useState()
  const [groupName, setGroupName] = useState(props.groupName || '')
  const [languageId, setLanguageId] = useState(props.languageId || 1)
  const [internalId, setInternalId] = useState('')
  const [description, setDescription] = useState('')
  const [groupNameError, setGroupNameError] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [workFolderId, setWorkFolderId] = useState()
  const [moreInfo, setMoreInfo] = useState(false)
  const [showAssignedFolderWarning, setShowAssignedFolderWarning] = useState(false)

  useEffect(() => {
    setWorkFolderId(paramWorkFolderId)
  }, [paramWorkFolderId])

  useEffect(() => {
    if (group && (group.internalId ||  group.description)) {
      setMoreInfo(true)
      pageVisitor({ p: 'GroupAddnewView', n: 'Opened the advanced details' })
    }
  }, [group])

  useEffect(() => {
    let editGroup = groups && groups.length > 0 && groups.filter(m => m.groupId === groupId)[0]
    if (editGroup) {
      setGroup(editGroup)
      setGroupName(editGroup.groupName)
      setInternalId(editGroup.internalId)
      setDescription(editGroup.description)
      pageVisitor({ p: 'GroupAddnewView', n: 'Opened up this page to edit a group' })
    }
  }, [groupId, groups])

  const handleSubmit = () => {
    let hasError = false;
    if (!groupName) {
      setGroupNameError("Please enter a group name")
      hasError = true;
    }

    //Check to see if another group is already assigned to the workFolderId given here.
    const existAssignedFolder = groups && groups.length > 0 && groups.filter(m => m.workFolderId === workFolderId && m.groupId !== group.groupId)[0]
    if (existAssignedFolder) {
      setShowAssignedFolderWarning(existAssignedFolder.groupName)
    } else if (!hasError) {
      submitAddOrUpdateGroup()
    }
    pageVisitor({ p: 'GroupAddnewView', n: 'Submitted the name of a new group' })
  }

  const submitAddOrUpdateGroup = () => {
    const {addOrUpdateGroup, personId} = props;
    addOrUpdateGroup({
      groupId,
      personId,
      groupName,
      languageId,
      internalId,
      description,
      workFolderId: workFolderId || params.workFolderId,
    });
    //The user will be sent to the groupContacts page in the addOrUpdateGroup function
    setSubmitSuccess(true)
    setShowAssignedFolderWarning(false)
  }

  const setGroupNameSameAsFolder = () => {
    const folder = workFolders && workFolders.length > 0 && workFolders.filter(m => m.workFolderId === workFolderId)[0]
    if (folder) setGroupName(folder.folderName)
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <form method="post" encType="multipart/form-data" id="my-awesome-dropzone" className={styles.form}>
          <div className={globalStyles.pageTitle}>
            Create New Group
          </div>
          <div className={styles.subTitle}>
            {groupTypeDescription}
          </div>
          <div className={styles.background}>
            <div className={styles.inputControls}>
              {/*<div className={styles.row}>*/}
              {/*  <WorkFileTreeFolderDropDown workFolders={workFolders}*/}
              {/*                              label={'Choose the document folder for this group'}*/}
              {/*                              setWorkFolderId={setWorkFolderId}*/}
              {/*                              workFolderId={paramWorkFolderId}*/}
              {/*                              labelClass={styles.textWhite}/>*/}
              {/*  <div className={styles.spaceForOr}>OR</div>*/}
              {/*  <div className={styles.link} onClick={() => navigate('/GroupAddnewView')}>go create another folder</div>*/}
              {/*</div>*/}
              {workFolderId && workFolderId !== guidEmpty &&
                <span className={styles.groupNameCopy} onClick={setGroupNameSameAsFolder}>
                  <Icon pathName={'files'} premium={true} fillColor={'white'} className={styles.icon}/>
                  Set the group name the same as the folder name
                </span>
              }
              <InputText
                value={groupName}
                size={"long"}
                name={"groupName"}
                label={"Group name"}
                onChange={(event) => setGroupName(event.target.value)}
                required={true}
                whenFilled={groupName}
                inputClassName={styles.textWhite}
                error={groupNameError}/>
              <div className={styles.rowMoreInfo} onClick={() => setMoreInfo(!moreInfo)}>
                <Icon pathName={'chevron_right'} premium={false} fillColor={'white'}
                      className={moreInfo ? styles.lessInfo : styles.moreInfo}/>
                {moreInfo ? 'Less Info' : 'More Info'}
              </div>
              <div className={moreInfo ? styles.show : styles.hide}>
                <InputText
                  value={internalId || ''}
                  size={"medium"}
                  name={"internalId"}
                  label={"Internal id"}
                  inputClassName={styles.textWhite}
                  onChange={(event) => setInternalId(event.target.value)}/>

                <InputTextArea label={'Description (optional)'}
                               value={description || ''}
                               onChange={(event) => setDescription(event.target.value)}
                               labelClass={styles.textWhite}/>
              </div>
            </div>
          </div>
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={() => navigate(-1)}>
                Cancel
              </span>
              <ButtonWithIcon label={'Next'} icon={'play3'} isPremiumIcon={false} onClick={handleSubmit} submitSuccess={submitSuccess}/>
            </div>
          </div>
          <OneFJefFooter/>
        </form>
      </div>
      <MessageModal show={showAssignedFolderWarning} handleClose={() => setShowAssignedFolderWarning(false)} heading={`Group Document Folder`}
                    explain={`Another group is assigned to this folder:<br/><strong>${showAssignedFolderWarning}</strong><br/><br/>Do you want to unassign this group in order to add the new group? This group will not be assigned to any folder.`}
                    onClick={submitAddOrUpdateGroup} isConfirmType/>
    </div>
  )
}

export default GroupAddNewView
