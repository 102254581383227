import React, {useState, useEffect} from 'react'
import { createConfirmToastAuto } from '../../../services/queryClient'
import { useAppContext } from '../../../../AppContext'
import { useLocation } from "react-router-dom";
import * as mediaService from '../../../services/media-files'
import AudioPlayer from '../AudioPlayer'
import FileUploadDisplay from '../FileUploadDisplay'
import styles from './MediaListViewer.module.css'
import VideoPlayer from '../VideoPlayer'
import MicRecorder from 'mic-recorder-to-mp3'
const recorder = new MicRecorder({ bitRate: 128 })

function MediaListViewer(props) {
  const {
    index = 0,
    label,
    mediaBinaries = [],
    recordType, 
    recordId, 
    runFunction,
    section,
    userPersonId, 
  } = props

  const location = useLocation();
  const isLearnerPage = location.pathname.indexOf('assessmentLearner') > -1
  const isCorrectPage = location.pathname.indexOf('assessmentCorrect') > -1
  const isItemSetupPage = location.pathname.indexOf('assessmentQuestion') > -1 || location.pathname.indexOf('assessmentItemSetup') > -1

  const { removeMediaBinary } = useAppContext();

  const [isFileUploadDisplayOpen, setIsFileUploadDisplayOpen] = useState()
  const [isShowingVideoPlayer, setIsShowingVideoPlayer] = useState()
  const [videoUrl, setVideoUrl] = useState()
  const [mediaBinary, setMediaBinary] = useState()
  const [sectionCapitalized, setSectionCapitalized] = useState()
  const [fileUrl, setFileUrl] = useState()
  const [removeId, setRemoveId] = useState()
    
  useEffect(() => {
    //If this is the label that is coming through, then it is a more specific label rather than just usingn the section label, such as `Student's response`
    setSectionCapitalized(label ? label : section.charAt(0).toUpperCase() + section.slice(1))
  }, [section])

  const handleOpenFileUploadDisplay = (mediaBinary) => {
    setIsFileUploadDisplayOpen(true)
    setFileUrl(URL.createObjectURL(mediaService.base64ToBlob(mediaBinary.data, mediaBinary.fileType)))
    setMediaBinary(mediaBinary)
  }

  const removeRecord = (event, id) => {
    event.stopPropagation()
    event.preventDefault()
    createConfirmToastAuto(`<div>Are you sure you want to delete this record?</div>`, () => removeMediaBinary(id, runFunction))
  }

  return (
    <div className={styles.container}>
      {mediaBinaries.map((m, i) => {
        if (m.recordType === recordType && m.recordId === recordId && m.section === section && m.mediaType === 'upload' && m.indexNbr === index) {
          return (
            <div onClick={() => handleOpenFileUploadDisplay(m)} className={styles.linkIndent} onMouseOver={() => setRemoveId(m.mediaBinaryId)} onMouseOut={() => setRemoveId(null)} key={`file${i}`}>
              <div className={styles.fileLabel}>
                {`${sectionCapitalized} file:`}
                {isItemSetupPage && removeId === m.mediaBinaryId && <div className={styles.remove} onClick={(event) => removeRecord(event, m.mediaBinaryId)}>remove</div>}
              </div>
              <div className={styles.fileName}>{m.fileName}</div>
            </div>
          )
        }
      })}

      {mediaBinaries.map((m, i) => {
        if (m.recordType === recordType && m.recordId === recordId && m.section === section && m.mediaType === 'sketch' && m.indexNbr === index) {
          return (
            <div onClick={() => { handleOpenFileUploadDisplay(m) }} className={styles.linkIndent} onMouseOver={() => setRemoveId(m.mediaBinaryId)} onMouseOut={() => setRemoveId(null)} key={`handwriting${i}`}>
              <div className={styles.fileLabel}>
                {`${sectionCapitalized} handwriting:`}
                {isItemSetupPage && removeId === m.mediaBinaryId && <div className={styles.remove} onClick={(event) => removeRecord(event, m.mediaBinaryId)}>remove</div>}
              </div>
              <div className={styles.fileName}>Handwriting</div>
            </div>
          )
        }
      })}

      {mediaBinaries.map((m, i) => {
        if (m.recordType === recordType && m.recordId === recordId && m.section === section && m.mediaType === 'audio' && m.indexNbr === index) {
          return (
            <div className={styles.linkIndent} onMouseOver={() => setRemoveId(m.mediaBinaryId)} onMouseOut={() => setRemoveId(null)} key={`audio${i}`}>
              <div className={styles.fileLabelBottom}>
                {`${sectionCapitalized} audio:`}
                {isItemSetupPage && removeId === m.mediaBinaryId && <div className={styles.remove} onClick={(event) => removeRecord(event, m.mediaBinaryId)}>remove</div>}
              </div>
              <AudioPlayer
                label={`${sectionCapitalized} audio`}
                keyIndex={`solution`}
                audioFile={m.data}
                userPersonId={userPersonId}
              />
            </div>
          )
        }
      })}

      {mediaBinaries.map((m, i) => {
        if (m.recordType === recordType && m.recordId === recordId && m.section === section && m.mediaType === 'camera' && m.indexNbr === index) {
          return (
            <div onClick={() => { handleOpenFileUploadDisplay(m) }} className={styles.linkIndent} onMouseOver={() => setRemoveId(m.mediaBinaryId)} onMouseOut={() => setRemoveId(null)} key={`camera${i}`}>
              <div className={styles.fileLabel}>
                {`${sectionCapitalized} camera photo:`}
                {isItemSetupPage && removeId === m.mediaBinaryId && <div className={styles.remove} onClick={(event) => removeRecord(event, m.mediaBinaryId)}>remove</div>}
              </div>
              <div className={styles.fileName}>{m.fileName}</div>
            </div>
          )
        }
      })}

      {mediaBinaries.map((m, i) => {
        if (m.recordType === recordType && m.recordId === recordId && m.section === section && m.mediaType === 'video' && m.indexNbr === index) {
          return (
            <div onClick={() => { setVideoUrl(URL.createObjectURL(mediaService.base64ToBlob(m.data, m.fileType))); setIsShowingVideoPlayer(true); }} className={styles.linkIndent} onMouseOver={() => setRemoveId(m.mediaBinaryId)} onMouseOut={() => setRemoveId(null)} key={`video${i}`}>
              <div className={styles.fileLabel}>
                {`${sectionCapitalized} video:`}
                {isItemSetupPage && removeId === m.mediaBinaryId && <div className={styles.remove} onClick={(event) => removeRecord(event, m.mediaBinaryId)}>remove</div>}
              </div>
              <div className={styles.fileName}>{m.fileName}</div>
            </div>
          )
        }
      })}

      {mediaBinaries.map((m, i) => {
        if (m.recordType === recordType && m.recordId === recordId && m.section === section && m.mediaType === 'website' && m.indexNbr === index) {
          return (
            <div className={styles.linkIndent} onMouseOver={() => setRemoveId(m.mediaBinaryId)} onMouseOut={() => setRemoveId(null)} key={`website${i}`}>
              <a href={m.fileName} target={'_website'} className={styles.noUnderline}>
                <div className={styles.fileLabel}>
                  {`${sectionCapitalized} website:`}
                  {isItemSetupPage && removeId === m.mediaBinaryId && <div className={styles.remove} onClick={(event) => removeRecord(event, m.mediaBinaryId)}>remove</div>}
                </div>
                <div className={styles.fileName}>{m.words}</div>
              </a>
            </div>
          )
        }
      })}

      <FileUploadDisplay
        fileType={mediaBinary?.fileType}
        fileUrl={fileUrl}
        isOpen={isFileUploadDisplayOpen}
        setIsOpen={setIsFileUploadDisplayOpen} />

      <VideoPlayer
        isOpen={isShowingVideoPlayer}
        setIsOpen={setIsShowingVideoPlayer}
        videoUrl={videoUrl} />

    </div>
  )
}

export default MediaListViewer