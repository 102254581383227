import React from 'react';
import { createConfirmToastAuto } from '../../../services/queryClient.js'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive"
import * as editorService from '../../../services/editor-dom'
import classes from 'classnames';
import penspringSmall from "../../../assets/logos/penspring_reg.png"
import styles from './AssessmentEssayControlsOptions.module.css';

function AssessmentEssayControlsOptions(props) {
  const {
    isTeacher,
    handleCreateWorkInAssessment,
    question,
  } = props

  const location = useLocation();
  const isLearnerPage = location.pathname.indexOf('assessmentLearner') > -1
  const isCorrectPage = location.pathname.indexOf('assessmentCorrect') > -1
  const isItemSetupPage = location.pathname.indexOf('assessmentQuestion') > -1 || location.pathname.indexOf('assessmentItemSetup') > -1

  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const sendToEditReviewView = () => {
    const send = () => navigate(`/editReview/${question.learnerAnswer.workId}/${question.learnerAnswer.chapterId}/${question.learnerAnswer.languageId}/${question.assessmentQuestionId}`)
    createConfirmToastAuto('<div>Do you want to view or edit this text in penspring?</div>', send)
  }

  return (
    <div className={styles.editorDiv}>
      {question?.learnerAnswer?.workSummary?.chapterId_current &&
        <div id={editorService.getEditorDivId(question.learnerAnswer.workSummary.chapterId_current)}
          style={{ marginTop: isMobile ? '35px' : '', marginLeft: isMobile ? '0px' : '' }}
          contentEditable='false'
          spellCheck={'true'}
          onMouseUp={sendToEditReviewView} />
      }
      <div className={classes(styles.row, styles.moreLeft)}>
        <div className={styles.penspring}>
          {isTeacher
            ? isCorrectPage
              ? <Link
                  to={`/editReview/${question.learnerAnswer.workId}/${question.learnerAnswer.chapterId}/${question.learnerAnswer.languageId}/${question.assessmentQuestionId}`}
                  className={classes(styles.link, styles.row)}
                  target={'_penspring'}>
                  Edit the student's essay in the 
                  <img className={classes(styles.penspringLogo, styles.pointer)} src={penspringSmall} alt="penspring" />editor
                </Link>
              : <div className={styles.row}>
                  The student's essay will be editable in the 
                  <img className={styles.penspringLogo} src={penspringSmall} alt="penspring" />editor
                </div>
            : question?.learnerAnswer?.isSubmitted
              ? <Link
                    to={`/editReview/${question.learnerAnswer.workId}/${question.learnerAnswer.chapterId}/${question.learnerAnswer.languageId}/${question.assessmentQuestionId}`}
                    className={classes(styles.link, styles.row)}
                    target={'_penspring'}>
                    Review this essay in the
                  <img className={classes(styles.penspringLogo, styles.pointer)} src={penspringSmall} alt="penspring" />editor
                </Link>
              : <div onClick={question?.isOwner ? () => { } : handleCreateWorkInAssessment} className={classes(styles.link, styles.row)} >
                  Compose this essay in the
                  <img className={classes(styles.penspringLogo, styles.pointer)} src={penspringSmall} alt="penspring" />editor
                </div>
          }
        </div>
      </div>
    </div>
  )
};

export default AssessmentEssayControlsOptions