import React, { useState } from 'react';
import styles from './TapeReel.module.css';
import classes from 'classnames';
import {useMediaQuery} from "react-responsive";

function TapeReel(props) {
	const {
    isPlaying,
    setIsPlaying
	} = props;

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

	return (
    <div className={classes(styles['tape-player'], styles['has-tape'], isPlaying ? styles.playing : '')} onClick={() => setIsPlaying(!isPlaying)}>
      <div className={styles['buttons']}>
      </div>
      <div className={styles['small-reel']}>
        <div className={classes(styles['middle'], styles['circle'])}></div>
        <div className={classes(styles['circle'], styles['one'])}></div>
        <div className={classes(styles['circle'], styles['two'])}></div>
        <div className={classes(styles['circle'], styles['three'])}></div>
        <div className={classes(styles['circle'], styles['four'])}></div>
        <div className={classes(styles['circle'], styles['five'])}></div>
        <div className={classes(styles['circle'], styles['six'])}></div>
        <div className={classes(styles['circle'], styles['seven'])}></div>
        <div className={classes(styles['circle'], styles['eight'])}></div>
        <div className={classes(styles['circle'], styles['nine'])}></div>
      </div>
      <div className={styles['big-reel']}>
        <div className={classes(styles['hole'], styles['one'])}></div>
        <div className={classes(styles['hole'], styles['two'])}></div>
        <div className={classes(styles['hole'], styles['three'])}></div>
        <div className={styles['middle-hole']}></div>
      </div>

      <div className={styles['tape-top']}></div>
      <div className={styles['tape-bottom']}></div>
    </div>
	)
}

export default TapeReel;