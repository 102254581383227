import React, { useState, useEffect } from 'react'
import styles from './FileUploadDropzone.module.css'
import {useDropzone} from 'react-dropzone'
import moveTarget from '../../assets/Inline Icons/move-target.svg'
import classes from 'classnames'
import { useAppContext } from "../../../AppContext.js";

function FileUploadDropzone(props) {
	const { 
    preLabel, 
    label, 
    subLabel, 
    setOpenViewer,
    fileTypes, 
    fileTypesMode,
    targetIcon, 
    isMobile 
  } = props

  const { pageVisitor } = useAppContext();
	const [acceptFileTypes, setAcceptFileTypes] = useState({
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'text/plain': ['.txt'],
		'application/pdf': ['.pdf'],
		'application/vnd.oasis.opendocument.text': ['.odt'],
		'text/html': ['.html'],
		'text/css': ['.css'],
		'text/javascript': ['.js'],
		'text/javascript': ['.jsx'],
		'text/x': ['.py'],
		'text/x': ['.java'],
		'text/plain': ['.cs'],
		'text/x': ['.c'],
		'text/x': ['.cpp'],
		'application/x': ['.php'],
		'text/x': ['.rb'],
		'text/x': ['.swift'],
		'text/typescript': ['.ts'],
		'text/typescript': ['.tsx'],
		'application/xml': ['.xml'],
		'application/json': ['.json'],
		'application/sql': ['.sql'],
		'text/markdown': ['.md'],
	})

	useEffect(() => {
    if (fileTypesMode) {
      if (fileTypesMode === 'ALL') {
        setAcceptFileTypes({
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/tiff': ['.tiff'],
          'image/gif': ['.gif'],
          'image/png': ['.png'],
          'image/bmp': ['.bmp'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.oasis.opendocument.text': ['.odt'],
          'application/vnd.wordperfect': ['.wpd'],
          'application/rtf': ['.rtf'],
          'text/plain': ['.txt', '.dat'],
          'application/pdf': ['.pdf'],
          'audio/mp4': ['.m4a'],
          'text/html': ['.html'],
          'text/css': ['.css'],
          'application/javascript': ['.js', '.jsx'],
          'text/x-python': ['.py'],
          'text/x-java-source': ['.java'],
          'text/x-csharp': ['.cs'],
          'text/x-c': ['.c'],
          'text/x-cpp': ['.cpp'],
          'application/x-httpd-php': ['.php'],
          'text/x-ruby': ['.rb'],
          'application/x-swift': ['.swift'],
          'application/typescript': ['.ts', '.tsx'],
          'application/xml': ['.xml'],
          'application/json': ['.json'],
          'application/sql': ['.sql'],
          'text/markdown': ['.md']
        })
      } else if (fileTypesMode === 'DOCUMENTS') {
        setAcceptFileTypes({
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'text/plain': ['.txt'],
          'application/pdf': ['.pdf'],
          'application/vnd.oasis.opendocument.text': ['.odt'],
          'text/html': ['.html'],
          'text/css': ['.css'],
          'text/javascript': ['.js'],
          'text/javascript': ['.jsx'],
          'text/x': ['.py'],
          'text/x': ['.java'],
          'text/plain': ['.cs'],
          'text/x': ['.c'],
          'text/x': ['.cpp'],
          'application/x': ['.php'],
          'text/x': ['.rb'],
          'text/x': ['.swift'],
          'text/typescript': ['.ts'],
          'text/typescript': ['.tsx'],
          'application/xml': ['.xml'],
          'application/json': ['.json'],
          'application/sql': ['.sql'],
          'text/markdown': ['.md'],
        })
      } else if (fileTypesMode === 'IMAGES') {
        setAcceptFileTypes({
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/tiff': ['.tiff'],
          'image/gif': ['.gif'],
          'image/png': ['.png'],
          'image/bmp': ['.bmp'],
          'application/pdf': ['.pdf'],
        })
      }
    }
		if (fileTypes) {
			setAcceptFileTypes(fileTypes)
      pageVisitor({ p: 'FileUploadDropzone', n: 'Chose a local file' })
		}
	}, [fileTypes, fileTypesMode])

	const {
		acceptedFiles,
		fileRejections,
		getRootProps,
		getInputProps
	} = useDropzone({
		maxFiles: 1,
		accept: acceptFileTypes})

	const acceptedFileItems = acceptedFiles.map((file, index) => {
		if (index === 0) props.setFile(file)
		return (
			<li key={file.path}>
				{file.path} - {file.size} bytes
			</li>
		)
	})

	const fileRejectionItems = fileRejections.map(({ file, errors  }) => {
		//props.setFile(null)
		return (
			<li key={file.path}>
				{file.path} - {file.size} bytes
				<ul>
					{errors.map(e => <li key={e.code}>{e.message}</li>)}
				</ul>
			</li>
		)
	})

  const handleSetOpenViewer = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setOpenViewer(true)
  }

	return (
		<section className={isMobile ? styles.containerMobile : styles.container}>
			<div {...getRootProps({ className: styles.dropzone })} className={styles.row}>
        <div className={styles.imageMoveTarget}>
					{targetIcon ? targetIcon  : <img src={moveTarget} width={22}/>}
        </div>
        <div>
          <input {...getInputProps()} />
          {preLabel && <div className={styles.preLabel}>{preLabel}</div>}
          <div className={styles.fitLabel}>{label || 'Local file (click or drag & drop)'} <div className={styles.allowed}>{subLabel || '(Only 1 file is allowed)  Microsoft&reg; Word, HTML, ...'}</div></div>
        </div>
        {acceptedFileItems && <div onClick={handleSetOpenViewer} className={styles.link}>Preview file</div>}
      </div>
			<div>
				{/*<h4>Accepted file</h4>*/}
				<div className={classes(styles.chosenFile, isMobile ? styles.isMobile : '')}>{acceptedFileItems}</div>
				{fileRejectionItems && fileRejectionItems.length > 0 && <h4>Rejected files</h4>}
				{/* <div>{fileRejectionItems}</div> */}
			</div>
		</section>
	)
}

export default FileUploadDropzone