import React, { useEffect, useState } from 'react'
import styles from './AuthoringEditor.module.css'
import { createConfirmToastAuto } from '../../services/queryClient.js'
import { createInfoToastAuto } from '../../services/queryClient'
import { setCursorPosition } from "../../services/editor-dom"
import { useAppContext } from "../../../AppContext.js"
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router'
import * as editListStructure from "../../services/edit-list-structure"
import * as editorTableStructure from "../../services/editor-table-structure"
import * as editorService from '../../services/editor-dom'
import * as sentenceService from "../../services/sentence-splitter"
import backgroundColors from "../../utils/backgroundColors"
import classes from "classnames"
import CurrentSentenceEditsPanelLauncher from '../../components/CurrentSentenceEditsPanelLauncher'
import DiscussionEntryOptions from '../../components/DiscussionEntryOptions'
import DownloadButton from '../../components/DownloadButton'
import DownloadFileModal from '../../components/DownloadFileModal'
import EditListChoice from '../../components/EditListChoice'
import EditorDivFormatControls from '../../components/EditorDivFormatControls'
import EditorDivFormatControlsMobile from '../../components/EditorDivFormatControlsMobile'
import EditorFontNameSizeBold from '../../components/EditorFontNameSizeBold'
import EditorFullTextView from '../../components/EditorFullTextView'
import EditorSaveButton from '../../components/EditorSaveButton'
import ListChangeModal from '../ListChangeModal'
import MessageModal from '../../components/MessageModal'
import moment from 'moment'
import PointsDisplay from '../../components/PointsDisplay'
import TableAddModal from '../../components/TableAddModal'
import TabPage from '../../components/TabPage'
import TextDisplay from '../../components/TextDisplay'
import TextStyleChangeModal from '../../components/TextStyleChangeModal'
import ToggleBoardMobile from '../../components/ToggleBoardMobile'
import WebsiteLinkEntry from '../../components/WebsiteLinkEntry'

/* The views by device:
    isLargerLaptop                isMediumLaptop              isTablet                isSmallTablet        isMobile
    1600 and larger               1400 to 1599                1050 to 1399            750 To 1049          To 749

    constant    editor   tab    | slide-in   editor   tab   | constant    editor   |  slide-in   editor   | slide-in    editor
    side         view    view   | side       view     view  | side        view     |  side       view	    | side        view
    panel                       | pandel                    | panel       with     |  panel      with     | panel       with
                                                                          writer's |             writer's |             all lists
                                                                          list     |             list     |
*/

//const limitChangeCounts = 20
//let isSurgicalTextChange = false
//let savedRange //This is used only (so far) for the color dialog box since clicking on the dialog causes the selection to be lost behind. So we restore it when we want to ultimatley apply the color to it.
let androidEnterKey = []
let backspaceNodes = []
let changeStyleEditArray = []
let convertAddListEditArray = []
let debounceTimer
let deleteKeyNodes = []
let deleteSentenceArray = []
let globalPreviousSpan
let isInitScroll = false
let isTextChanged = false
let moveEditArray = []
let personConfig = localStorage.getItem('personConfig')
let savedCursorPosition = {}
let savedParagraphsForTextAlign //This is used just for edit control text-align:  left, right, center, justify ... well, now for textIndent, blockLeft and blockRight
let skipRestoreCursorOnce = false
let workDownloadReady = null  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
personConfig = personConfig ? JSON.parse(personConfig) : []


function AuthoringEditor(props) {
  const {
    //chapterId,  //We can't take chapterId from here. For some reason, the discussion multiple records seems to latch onto the first chapterId on which the discussoin is based and then that throws off all of the editorDivId or suffixId values that are attempted to find the suffixId (or prefixId which is the same) for element identification.  We'll use a state in the AuthoringEditor page instead from workSummary.chapterId_current.
    addChapterListLevels,
    addImage,
    addOrUpdateEdit,
    addOrUpdateEditAddList,
    addTable,
    addTableCell,
    addTableColumn,
    addTableRow,
    changeList,
    changeStyleSequence,
    chapterListLevels,
    chosenSegment,
    convertAddListSequence,
    currentEditorDiv,
    currentElement,
    deleteImage,
    deleteTable,
    deleteTableCell,
    deleteTableColumn,
    deleteTableRow,
    editLanguageId,
    editorDivId,
    editorName,
    edits = [],
    getWorkEditReviewFilled,
    globalChosenTab,
    handleSetChosenSegment,
    handleSetCurrentElement,
    handleSetGlobalChosenTab,
    imageSettings,
    isTranslation = false,
    keyIndex,
    listLevelGeneral,
    moveSequence,
    navText,
    openListModal,
    openTableAddModal,
    personId,
    removeDeleteSentenceEdits,
    saveWorkSpaceTime,
    scrollTogether,
    segments,
    sentenceEditCount,
    setAddImage,
    setAddTable,
    setAddTableCell,
    setAddTableColumn,
    setAddTableRow,
    setChangeList,
    setCurrentEditorDiv,
    setDeleteImage,
    setDeleteTable,
    setDeleteTableCell,
    setDeleteTableColumn,
    setDeleteTableRow,
    setImageSettings,
    setOpenListModal, //This is set to the editorDivId in order to keep the list modals separate from each other. Otherwise, they all open up at the same time.
    setOpenTableAddModal, //This is set to the editorDivId in order to keep the list modals separate from each other. Otherwise, they all open up at the same time.
    setSaveWorkSpaceTime,
    setScrollTogether,
    setShowEditIcons,
    setTableSettings,
    showEditIcons,
    tableSettings,
    tabNav,
    workSummaries,
    workSummary,
  } = props;

  const navigate = useNavigate()
  const isLargerLaptop = useMediaQuery({ query: '(min-width: 1600px)' })
  const isMediumLaptop = useMediaQuery({ query: '(min-width: 1400px) and (max-width: 1599px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 1050px) and (max-width: 1399px)' })
  const isSmallTablet = useMediaQuery({ query: '(min-width: 750px) and (max-width: 1049px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 749px)' })

  const { pageVisitor } = useAppContext();

  const [chosenTab, setChosenTab] = useState();
  const [revisions, setRevisions] = useState([''])
  const [redoRevisions, setRedoRevisions] = useState([''])
  const [chosenHTMLSegment, setChosenHTMLSegment] = useState([''])
  const [isOpenLinkEntry, setIsOpenLinkEntry] = useState(false)
  const [showInstructions, setShowInstructions] = useState(false)
  const [editChosen, setEditChosen] = useState()
  const [entryError, setEntryError] = useState('')
  const [showDownloadReady, setShowDownloadReady] = useState(false)  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
  const [openTextStyleModal, setOpenTextStyleModal] = useState(false)
  const [listItemReorderArray, setListItemReorderArray] = useState([])
  const [savedElementTextAnchorNode, setSavedElementTextAnchorNode] = useState()  //This is looking for the anchorNode in order to manage a website link entry, if any.
  const [savedRange, setSavedRange] = useState()
  const [saveSelectedSelection, setSaveSelectedSelection] = useState()
  const [savedSpansForStyleChange, setSavedSpansForStyleChange] = useState()
  const [saveSelectionInnerHtml, setSaveSelectionInnerHtml] = useState()
  const [savedElementsForEdit, setSavedElementsForEdit] = useState()
  const [allSelectionData, setAllSelectionData] = useState()
  const [isCopyCommand, setIsCopyCommand] = useState()
  const [isAuthor, setIsAuthor] = useState()
  const [hasListStructure, setHasListStructure] = useState()
  const [hasTableStructure, setHasTableStructure] = useState()
  const [tabsData, setTabsData] = useState()
  const [changeCounts, setChangeCounts] = useState(0)
  const [chapterId, setChapterId] = useState(workSummary.chapterId_current)
  const [fontNameSize, setFontNameSize] = useState()
  
  const suffixId = editorService.getSuffixIdFromEditorDivId(editorDivId)

  useEffect(() => {
    workDownloadReady = localStorage.getItem('workDownloadReady')
    workDownloadReady = workDownloadReady ? JSON.parse(workDownloadReady) : null
    if (workDownloadReady) setShowDownloadReady(true)  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
    editorService.setCursorInFirstSpan(chapterId)
    const firstEditorDivId = editorService.getPrefixChapterId(chapterId)
    props.setGlobalSuffixId(firstEditorDivId)
    props.setCurrentEditorDiv(`editorDiv${firstEditorDivId}`)
  }, [])

  useEffect(() => {
    if (props.globalWorkSummary?.chapterId_current) {
      setChapterId(props.globalWorkSummary.chapterId_current)
      pageVisitor({ p: 'AuthoringEditor', n: `Changed to another chapter ${props.globalWorkSummary.chapterId_current}`})
    }
  }, [props.globalWorkSummary])

  useEffect(() => {
    if (currentEditorDiv === editorDivId || workSummaries?.length === 1) {
      const newTabsData = editorService.getTabsData(workSummary, edits, props.editorColors, editorName, isMobile, isAuthor, personId)
      setTabsData(newTabsData)
      props.setGlobalTabsData(newTabsData)
      props.setGlobalEdits(edits)
      pageVisitor({ p: 'AuthoringEditor', n: `Changed to another editorDiv ${currentEditorDiv}` })
    }
  }, [currentEditorDiv, workSummaries])

  useEffect(() => {
    if (!chosenTab && tabsData?.length > 0 && workSummary?.authorPersonId) {
      if (!isMobile) {
        setChosenTab(workSummary.authorPersonId)
        handleSetGlobalChosenTab(workSummary.authorPersonId)
      }
      const thisPersonUser = tabsData?.filter(m => m.id === personId)[0]
      if (thisPersonUser) {
        setChosenTab(thisPersonUser.id)
        handleSetGlobalChosenTab(thisPersonUser.id)
      } else {
        setChosenTab(tabsData && tabsData[0].id)
      }
    }
  }, [tabsData, props.showEditorFullText])

  useEffect(() => {
    if (globalChosenTab !== chosenTab) {
      setChosenTab(globalChosenTab)
      pageVisitor({ p: 'AuthoringEditor', n: `Changed to another tabs chosen ${globalChosenTab}` })
    }
  }, [globalChosenTab])

  useEffect(() => {
    if (chapterId && segments?.length > 0) {
      const listItems = segments.filter(m => m.type === 'LI' && m.chapterId === chapterId)
      setHasListStructure(listItems?.length > 0)
      const tableItems = segments.filter(m => m.type === 'TABLE' && m.chapterId === chapterId)
      setHasTableStructure(tableItems?.length > 0)
      const newIsAuthor = workSummary.workOwners.filter(m => m.personId === personId)[0]
      setIsAuthor(newIsAuthor && newIsAuthor.personId ? true : false)
    } else {
      setHasListStructure(false)
      setIsAuthor(false)
    }
  }, [chapterId, segments])

  useEffect(() => {
    if (segments && segments.length > 0 && workSummary && workSummary.workId) { //!isInit &&
      setChapterId(workSummary.chapterId_current)
      props.setGlobalWorkSummary(workSummary) //This is necessary in order to update any segment that just had its comment updated in SentenceEdits which is outside of AuthoringEditor but inside EditReviewView
      editorService.setSegmentViews({
        addChapterListLevels,
        chapterId: workSummary.chapterId_current,
        chapterListLevels,
        chosenTab,
        editorName,
        edits,
        isAuthor,
        isTranslation,
        listLevelGeneral,
        personId,
        savedRange,
        segments,
        showEditIcons,
        tabsData,
        workSummary,
      })

      if (!isInitScroll) {
        //If there isn't any currentElement, then let's put the cursor proactively into the first spot of the main body to avoid having the user put the cursor in no-mans' land of BEFORE the main
        const scrollCurrentElementId = localStorage.getItem(`scrollCurrentElementId-${workSummary.workId}`)
        if (!scrollCurrentElementId) {
          editorService.setIntoFirstSpan(chapterId)
        }
        isInitScroll = true
        // //Scroll the page into the view of where the user left off.
        // const scrollCurrentElementId = localStorage.getItem(`scrollCurrentElementId-${workSummary.workId}`)
        // const scrollCurrentElement = scrollCurrentElementId && document.querySelector(`[id="${scrollCurrentElementId}"]`)
        // if (scrollCurrentElement) {
        //   scrollCurrentElement.scrollIntoView({behavior: "smooth", block: "center"})
        //   // const tabViewElement = document.querySelector(`[id="${scrollCurrentElementId + '~tabView'}"]`)
        //   // if (tabViewElement) setTimeout(() => tabViewElement.scrollIntoView({behavior: "smooth", block: "center"}), 500)
        //   setTimeout(() => scrollDocumentToMatch(scrollCurrentElement, scrollCurrentElement.id), 500)
        // }
      }
    }
  }, [segments, workSummary, workSummaries, edits, tabsData, chosenTab, props.showEditIcons]) //Don't put in ", workSummary, isAuthor, personId" since it causes the cursor to keep moving to the beginning of editorDivId.

  useEffect(() => {
    if (props.deleteListItem) {
      saveRevision()
      if (editorService.isCursorInsideList(currentElement, chapterId)) {
        editorService.setDeleteListItemIcon({
          addChapterListLevels,
          addOrUpdateEdit,
          chapterId,
          chapterListLevels,
          chosenTab,
          currentElement,
          editLanguageId,
          editorDivId,
          editorName,
          edits,
          isAuthor,
          isTranslation,
          listLevelGeneral,
          personId,
          savedRange,
          segments,
          tabsData,
          updateSegmentsLocal: props.updateSegmentsLocal,
          workSummary,
        })
      }
    } else {
      editorService.removeDeleteListItemIcons(editorDivId)
    }
  }, [props.deleteListItem])

  useEffect(() => {
    if (props.changeList) {
      setOpenListModal(editorDivId)
      pageVisitor({ p: 'AuthoringEditor', n: `Changed to change a list` })
    }
  }, [props.changeList])

  useEffect(() => {
    if (props.scrollTogether && currentElement) {
      scrollDocumentToMatch(currentElement, currentElement.id)
    }
  }, [props.scrollTogether, currentElement])

  useEffect(() => {
    if (props.addList) {
      if (savedElementsForEdit?.length > 0) {
        setOpenListModal(editorDivId) //We set this to the editorDivId to keep it separate from the other multiple editor versions, if there are multiple.
      } else {
        props.setShowAddListDecision(true)
      }
    } else {
      editorService.removeAddListTargetIcons(editorDivId)
      //clear selection and the addList
    }
  }, [props.addList])

  useEffect(() => {
    if (props.addTable) {
      editorTableStructure.insertAddTableTargets(suffixId, props.setAddTableTarget, setOpenTableAddModal, setHasTableStructure)
    } else {
      editorTableStructure.removeAddTableTargetIcons(editorDivId)
    }
  }, [props.addTable])

  useEffect(() => {
    if (props.reorderListItems && editorService.isCursorInsideList(currentElement, chapterId)) {
      saveRevision()
      setListItemReorderArray(editorService.setReorderDropDownLists(currentElement, onChooseReorder, isAuthor, updateChangeCounts))
      pageVisitor({ p: 'AuthoringEditor', n: `Chose to reorder list items` })
    } else {
      editorService.removeReorderListItemsDropDowns(editorDivId)
    }
  }, [props.reorderListItems])

  const unHighlightChosenSegments = () => {
    const rewindCount = 5;
    for (let i = chosenSegment.length - 1; i >= 0 && i >= chosenSegment.length - rewindCount; i--) {
      if (chosenSegment[i].type) {
        //Editor view
        let element = document.querySelector(`[id="${chosenSegment[i].id}"][data-type="${chosenSegment[i].type}"]`)
        if (!element) element = document.querySelector(`[data-span-id="${chosenSegment[i].id}"][data-type="${chosenSegment[i].type}"]`)
        if (element) {
          if (chosenSegment[i].type === 'TEXT') element.style.backgroundColor = backgroundColors.editPending
          else element.style.backgroundColor = backgroundColors.normal
        }
        //Tab view
        element = document.querySelector(`[id^="${chosenSegment[i].id}~tabView${suffixId}"][data-type="${chosenSegment[i].type}"]`)
        if (!element) element = document.querySelector(`[data-span-id^="${chosenSegment[i].id}~tabView${suffixId}"][data-type="${chosenSegment[i].type}"]`)
        if (element) {
          if (chosenSegment[i].type === 'TEXT') element.style.backgroundColor = backgroundColors.editPending
          else element.style.backgroundColor = backgroundColors.normal
        }
      }
    }
  }

  const updateChangeCounts = () => {
    if (isAuthor) {
      //6/22/2024 - decided not to do this since the cursor is jumping around to the start. Not good. We'll let the user be responsible for saving their document.
      // if (changeCounts > limitChangeCounts) {
      //   //savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))
      //   saveByButtonPress()
      //   setChangeCounts(0)
      //   //editorService.restoreCursorLocation(document.getElementById(editorDivId), savedCursorPosition)
      // } else {
      setChangeCounts(changeCounts + 1)
      // }
    }
  }

  const onChooseReorder = (listElement, listItemElement, currentIndex, targetIndex, isAuthor, editLanguageId) => {
    if (!editLanguageId) editLanguageId = workSummary.languageId_current || workSummary.languageId //Just to ensure that we have a languageId
    if (isAuthor) {
      //In order to send over the elementIds so that the segments can be manipulated and recalled in their new order
      //  1. Keep track of the movingListItemElementId before deleting the listItemElement
      //  2. Remove the listItemElement from the DOM
      //  3. Now see where the targetIndex lands
      //      If the listElement has a targetIndex (targetIndex is not out of range because of the shortened list - if the end of the list was chosen as the target)
      //         record the targetListItemElementId
      //      Otherwise, we need to look for the nextSibling or the parent's nextSibling or the grandparents nextSibling - whatever is the next element after the listItem
      //        record the listElementNextNeighborId
      //  4. Send it off to setReorderListItemsMovesAndIcons_Segments

      //  1. Keep track of the movingListItemElementId before deleting the listItemElement
      let movingListItemElementId = Number(listItemElement.id)
      let targetListItemElementId
      let listElementNextNeighborId
      let upperParent
      //  2. Remove the listItemElement from the DOM
      listItemElement.remove()
      //  3. Now see where the targetIndex lands
      //      If the listElement has a targetIndex (targetIndex is not out of range because of the shortened list - if the end of the list was chosen as the target)
      //         record the targetListItemElementId
      //  4. Send it off to setReorderListItemsMovesAndIcons_Segments
      if (listElement.children.length - 1 >= targetIndex) {
        targetListItemElementId = Number(listElement.children[targetIndex].id)
        //      Otherwise, we need to look for the nextSibling or the parent's nextSibling or the grandparents nextSibling - whatever is the next element after the listItem
        //        record the listElementNextNeighborId
      } else {
        let loop = 0
        listElementNextNeighborId = listElement.nextSibling ? Number(listElement.nextSibling.id) : null
        if (!listElementNextNeighborId) {
          upperParent = listElement.parentElement
          listElementNextNeighborId = upperParent.nextSibling ? Number(upperParent.nextSibling.id) : null
        }
        while (!listElementNextNeighborId && loop < 10) {
          upperParent = listElement.parentElement
          listElementNextNeighborId = upperParent.nextSibling ? Number(upperParent.nextSibling.id) : null
          loop++
        }
      }
      //  4. Send it off to setReorderListItemsMovesAndIcons_Segments
      let tempSegments = editorService.setReorderListItemsMovesAndIcons_Segments(segments, movingListItemElementId, targetListItemElementId, listElementNextNeighborId)
      editorService.setSegments({
        addChapterListLevels: props.addChapterListLevels,
        chapterId,
        chapterListLevels: props.chapterListLevels,
        chosenTab: props.chosenTab,
        editorName,
        edits,
        isAuthor,
        listLevelGeneral: props.listLevelGeneral,
        personId,
        savedRange,
        segments: tempSegments,
        showEditIcons,
        tabsData,
        workSummary,
      })
      props.updateSegmentsLocal(tempSegments)
    } else {
      editorService.updateListItemOrder({
        addOrUpdateEdit,
        addOrUpdateSegments: props.addOrUpdateSegments,
        chapterId,
        currentIndex,
        editLanguageId,
        editorName,
        isAuthor,
        listElement,
        listItemId: Number(listItemElement.id),
        personId,
        segments,
        setSaveWorkSpaceTime,
        targetIndex,
      })
      //We just need to get an element inside the listItemElement in order to call the setReorderDropDownLists again.
      let firstSpan = editorService.getFirstSpanFromParent(listElement)
      if (firstSpan) setTimeout(() => setListItemReorderArray(editorService.setReorderDropDownLists(firstSpan, onChooseReorder, isAuthor, updateChangeCounts), 500))
    }
  }

  const handleTouchStart = (event) => {
    saveSelection()
  };

  const handleTouchEnd = (event) => {
    saveSelection()
  };

  // const handleTouchStart = (event) => {
  //   timer = setTimeout(() => onLongTouch(event), touchDuration);
  // }

  // const handleTouchEnd = (event) => {
  //   //stops short touches from firing the event
  //   if (timer) clearTimeout(timer); // clearTimeout, not cleartimeout..
  //   handleMouseUp(event, true)
  // }


  const updateMoveEdit = (updateType, elementId, editLanguageId, moveEndParagraph, moveEndParagraphTarget) => {
    if (updateType === 'ChoseStartElement') {
      saveRevision()
      moveEditArray.push(elementId)
      props.setMoveSequence(2)
    } else if (updateType === 'ChoseEndElement') {
      moveEditArray = editorService.getMoveSegments(moveEditArray, elementId, moveEndParagraph, chapterId)
      props.setMoveSequence(3)
    } else if (updateType === 'ChoseTarget') {
      const targetElementId = moveEditArray[0]
      if (isAuthor) {
        const psuedoEditSegment = { //This just creates enough of the editSegment in order to move the sentences in segments for the editor (the same function, moveSentencesInSegments, used when building the segments in the EditReviewView page).
          moveEndParagraph: Number(moveEndParagraph),  //This variable looks like a boolean (the old intention) but it is now the paragraph's elementId of where that last segment came from.
          moveEndParagraphTarget: Number(moveEndParagraphTarget),
          moveToElementId: Number(elementId), //This is the parameter coming into this function (above)
          segmentsArray: moveEditArray.toString(),
        }
        const tempSegments = editorService.moveSentencesInSegments([...segments], psuedoEditSegment)
        editorService.setSegments({
          addChapterListLevels: props.addChapterListLevels,
          chapterId,
          chapterListLevels: props.chapterListLevels,
          chosenTab,
          divDisplayId: editorDivId,
          editorName,
          edits,
          isAuthor,
          isTranslation,
          listLevelGeneral: props.listLevelGeneral,
          personId,
          savedRange,
          segments: tempSegments,
          showEditIcons,
          tabsData,
          workSummary,
        })
        props.updateSegmentsLocal(tempSegments)
        //Do not save this change to the database because we want the UNDO to work for the user. They will need to save the record on their own when they are done.  Uh...I think that you can still UNDO because of the saveRevision() function event after saving segments to the databsae.
      } else {
        addOrUpdateEdit({
          authorTextSnapshot: editorService.getMoveAuthorTextSnapshot(moveEditArray, elementId, moveEndParagraph, moveEndParagraphTarget),
          chapterId,
          editSegmentId: 0,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          elementId: Number(moveEditArray[0]),
          languageId: editLanguageId,
          moveEndParagraph: Number(moveEndParagraph),  //This variable looks like a boolean (the old intention) but it is now the paragraph's elementId of where that last segment came from.
          moveEndParagraphTarget: Number(moveEndParagraphTarget),
          moveToElementId: Number(elementId), //This is the parameter coming into this function (above)
          personId,
          precedingStartElementId: Number(editorService.getPrecedingElementId(moveEditArray[0])), //This is used in order to place the target icon in the editor display for the editor AFTER the segments have been moved to their new place ... or otherwise, we lose track of where to put the target icon where the sentences came FROM.
          segmentsArray: moveEditArray.toString(),
          startElementId: Number(moveEditArray[0]),
          text: '',
          type: 'MOVE',
        }, () => {
          setTimeout(() => getWorkEditReviewFilled(), 500)
          setTimeout(() => editorService.setCursorPositionByRecall(targetElementId, 'TEXT', handleSetChosenSegment), 1000)
        })
      }
      props.setMoveSentences(false)
      props.setMoveSequence(1)
      moveEditArray = []
    }
    pageVisitor({ p: 'AuthoringEditor', n: `Move edit in process` })
  }

  const updateChangeStyleEdit = (updateType, elementId, editLanguageId, textStyleChangeEntries, moveEndParagraph) => {
    if (updateType === 'ChoseStartElement') {
      saveRevision()
      changeStyleEditArray.push(elementId)
      props.setChangeStyleSequence(2)
    } else if (updateType === 'ChoseEndElement') {
      changeStyleEditArray = editorService.getChangeStyleSegments(changeStyleEditArray, elementId, moveEndParagraph)
      props.setChangeStyleSequence(3)
      setOpenTextStyleModal(true)
    } else if (updateType === 'ChoseSettings') {
      if (isAuthor) {
        const tempSegments = editorService.setChangeStylesForEditor_Segments(segments, textStyleChangeEntries, false, changeStyleEditArray, chapterId)
        changeStyleEditArray = []
        //Do not save this change to the database because we want the UNDO to work for the user. They will need to save the record on their own when they are done.
        editorService.setSegments({
          addChapterListLevels: props.addChapterListLevels,
          chapterListLevels: props.chapterListLevels,
          chapterId,
          chosenTab,
          divDisplayId: editorDivId,
          editorName,
          edits,
          isAuthor,
          isTranslation,
          listLevelGeneral: props.listLevelGeneral,
          personId,
          savedRange,
          segments: tempSegments,
          showEditIcons,
          tabsData,
          workSummary,
        })
        props.updateSegmentsLocal(tempSegments)
      } else {
        addOrUpdateEdit({
          authorTextSnapshot: '', //Help ToDo: We could show the before and after in a table of what the previous style was compared to the new settings.
          chapterId,
          editSegmentId: 0,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          elementId: Number(changeStyleEditArray[0]),
          languageId: editLanguageId,
          moveEndParagraph: Number(moveEndParagraph),  //This variable looks like a boolean (the old intention) but it is now the paragraph's elementId of where that last segment came from.
          moveToElementId: Number(elementId), //This is the parameter coming into this function (above)
          personId,
          precedingStartElementId: Number(editorService.getPrecedingElementId(changeStyleEditArray[0])), //This is used in order to place the target icon in the editor display for the editor AFTER the segments have been moved to their new place ... os otherwise, we lose track of where to put the target icon where the sentences came FROM.
          segmentsArray: changeStyleEditArray.toString(),
          startElementId: Number(changeStyleEditArray[0]),
          text: '',
          textStyleChangeEntries,
          type: 'CHANGESTYLE',
        }, () => setTimeout(() => getWorkEditReviewFilled(), 500))
        changeStyleEditArray = []
      }
      props.setChangeStyleSequence(1)
      props.setChangeStyle(false)
    }
    pageVisitor({ p: 'AuthoringEditor', n: `Change text style in process` })
  }

  const updateConvertAddListEdit = (updateType, spanElement, editLanguageId, moveEndParagraph, listType) => {
    if (updateType === 'ChoseStartElement') {
      saveRevision()
      convertAddListEditArray.push(spanElement)
      props.setConvertAddListSequence(2)
    } else if (updateType === 'ChoseEndElement') {
      convertAddListEditArray = editorService.getConvertAddListSegments(convertAddListEditArray, spanElement, moveEndParagraph)
      setOpenListModal(editorDivId) //We set this to the editorDivId to keep it separate from the other multiple editor versions, if there are multiple.
      props.setConvertAddListSequence(3)
    } else if (updateType === 'ChoseListType') {
      let targetParagraph

      //We're just going to see if the last span selected is the last span of the last paragraph. Then we will go to convertTextToList
      //If it is the last sentence, then we'll pick up the nextSibling paragraph as the target paragraph. That is all that this is doing below.
      const spansOnly = convertAddListEditArray.filter(m => m.nodeName === 'SPAN')
      const lastSpanSelected = spansOnly[spansOnly.length - 1]
      const paragraphs = convertAddListEditArray.filter(m => m.nodeName === 'P')

      let lastParagraph
      if (paragraphs?.length > 0) {
        lastParagraph = paragraphs[paragraphs.length - 1]
      } else {
        const editorSpan = document.querySelector(`span[id="${lastSpanSelected.id}"][data-type="TEXT"]`)
        if (editorSpan) {
          lastParagraph = editorSpan.parentElement
        }
      }
      if (lastParagraph) {
        //Remember that savedElementsForEdit is its own limited collection. In order to compare the actual last paragraph in the full editor, we need to get the paragraph from the editorDiv.
        const editorParagraph = document.querySelector(`p[id="${lastParagraph.id}"]`)
        let lastSpanInParagraph
        for (let i = editorParagraph.children.length; i >= 0; i--) {
          if (!lastSpanInParagraph && editorParagraph.children[i]?.nodeName === 'SPAN' && editorParagraph.children[i]?.id && !isNaN(editorParagraph.children[i]?.id)) {
            lastSpanInParagraph = editorParagraph.children[i]
          }
        }
        const isCompleteParagraph = lastSpanSelected === lastSpanInParagraph
        targetParagraph = isCompleteParagraph ? lastParagraph.nextSibling : lastParagraph
      }

      editorService.convertTextToList({
        addOrUpdateEdit: props.addOrUpdateEdit,
        chapterId,
        editorName,
        edits,
        firstPartialSpan: null,
        firstPartialSpanRightSide: null,
        fullChosenElements: convertAddListEditArray,
        getWorkEditReviewFilled,
        isAuthor,
        languageId: editLanguageId,
        lastPartialSpan: null,
        lastPartialSpanLeftSide: null,
        listType,
        personId,
        segments,
        targetParagraph,
      })
      props.setConvertAddListSequence(0)
      props.setAddList(false)
      editorService.removeAddListIconsAll()
    }
    pageVisitor({ p: 'AuthoringEditor', n: `Convert text to list in process` })
  }


  const updateDeleteSentenceEdit = (updateType, editorDivId, elementId, editLanguageId) => {
    if (updateType === 'ChoseStartElement') {
      saveRevision()
      deleteSentenceArray.push(elementId)
    } else if (updateType === 'ChoseEndElement') {
      if (isAuthor) {
        const spanElement = document.querySelector(`span[id="${elementId}"][data-type="TEXT"]`)
        if (spanElement) {
          const prevElement = editorService.getPrevSpan(spanElement)
          deleteSentenceArray.push(prevElement.id)
        }
        //Delete the segments and set the segments again and set review.
        //If there are paragraphs that are erradicated entirely due to the sentences being deleted, then remove the paragraph as well.
        let tempSegments = editorService.gatherSegmentsToSave(segments, chapterId, editLanguageId)
        tempSegments = editorService.deleteSegments(tempSegments, deleteSentenceArray)
        editorService.setSegments({
          addChapterListLevels: props.addChapterListLevels,
          chapterListLevels: props.chapterListLevels,
          chapterId,
          chosenTab,
          divDisplayId: editorDivId,
          editorName,
          edits,
          isAuthor,
          isTranslation,
          listLevelGeneral: props.listLevelGeneral,
          personId,
          savedRange,
          segments: tempSegments,
          showEditIcons,
          tabsData,
          workSummary,
        })
        props.updateSegmentsLocal(tempSegments)
      } else {
        editorService.deleteSentencesInArray(
          {
            addOrUpdateEdit,
            chapterId,
            editLanguageId,
            editorDivId,
            editorName,
            edits,
            endElementId: elementId,
            getWorkEditReviewFilled,
            handleSetChosenSegment,
            handleSetCurrentElement,
            isAuthor,
            personId,
            responseEdit: props.responseEdit,
            segments,
            startElementId: Number(deleteSentenceArray[0]),
            workSummary,
          })
        setTimeout(() => props.setIsInitEdits('FORCE'), 300)
      }
      props.setDeleteSentence(false)
      deleteSentenceArray = []
    }
    pageVisitor({ p: 'AuthoringEditor', n: `Delete sentences in process` })
  }

  const saveByButtonPress = () => {
    if (isAuthor) {
      //savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))
      let chapterId = segments?.length > 0 && segments[0].chapterId
      if (chapterId) {
        let saveSegments = editorService.gatherSegmentsToSave(segments, chapterId, editLanguageId)
        props.addOrUpdateSegments(personId, saveSegments)
        setSaveWorkSpaceTime(new Date())
        setChangeCounts(0)
        //We might have to pull a trick here that if the sentence was split from where we were at, to match up the latest text sentence and find the new span in order to set the cursor location.
        editorService.restoreCursorLocation(document.getElementById(editorDivId), savedCursorPosition)
        pageVisitor({ p: 'AuthoringEditor', n: `Save button was pressed` })
      }
    }
  }

  //This function is causing the problem for us to lose our cursor where it jumps to the start. We need a different way to get the latest edits and segments.
  //And remember that we need to pick up the savedCursorPosition element from the new editorDiv elements since it seems that they need to be refreshed.
  const getLatestEditsAndAuthorChanges = () => {
    //This is getting the latest editSegments as well as workSegments so if we click on a sentence we will see the most recent. But we need to restore the cursor
    props.getWorkEditReview(personId, workSummary.workId, workSummary.essayScorePointsPossible?.assessmentQuestionId) 
    setTimeout(() => editorService.restoreCursorLocation(document.getElementById(editorDivId), savedCursorPosition), 1000)
  }

  const handleMouseUp = (event, isEditorDivView) => {
    //Is this a double-click in order to select text?
    const selection = window.getSelection()
    // Check if the selection is non-empty and valid
    if (selection.rangeCount > 0 && !selection.isCollapsed) {
      return // Exit if there's an active selection
    }
    setFontNameSize(editorService.getFontStylesAtCursor())
    savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))  //The cursor is restored at the bottom of this function.
    event.stopPropagation()
    props.setCurrentEditorDiv(editorDivId) 
    props.setGlobalWorkSummary(workSummary)
    const workOwner = workSummary.workOwners.filter(m => m.personId === personId)[0]
    props.setGlobalIsAuthor(!!workOwner?.personId )
    setChapterId(workSummary.chapterId_current)
    //This function is causing the problem for us to lose our cursor where it jumps to the start. 
    //getLatestEditsAndAuthorChanges()

    if (isTranslation) editorService.clearTextHighlights(editorDivId, chosenSegment, edits)  //This is for the translation version where the editorDiv side is highlighted if it is the automatic gotoNextSentence setting
    if (event.target.nodeName === 'SELECT' && event.target.dataset.type === 'REORDERLISTITEMS') return true
    let spanElement = event.target
    if (spanElement && spanElement.id === editorDivId) editorService.getPreciseCaretLocation(event) //We were having trouble clicking on the front of a span but it would end up choosing the previous element.
  
    //This could be a formatting element such as <u> or <i> or <b>, etc., which would not then be able to get up to the span with the Id.
    let loop = 0
    while (spanElement && !(spanElement && (spanElement.nodeName === 'SPAN' || spanElement.nodeName === 'IMG' || spanElement.nodeName === 'TR' || spanElement.nodeName === 'TD' || spanElement.nodeName === 'TH') && spanElement.id) && loop < 8) {
      spanElement = spanElement.parentElement
      loop++
    }
    spanElement = handleSetCurrentElement(spanElement, chapterId)

    if (props.deleteTable) {
      saveRevision()
      let tableElement = spanElement?.parentElement
      let loop = 0
      while (tableElement && !(tableElement.nodeName === 'TABLE' && tableElement.id) && loop < 10) {
        tableElement = tableElement.parentElement
        loop++
      }
      if (tableElement.nodeName === 'TABLE' && tableElement.id) {
        if (tableElement?.dataset?.type === 'ADDTABLE' && !isAuthor) {
          createInfoToastAuto(`<div>This table appears to be the table you created.<br/>To delete it, undo the edit to add the table.</div>`)
        } else {
          createConfirmToastAuto(`<div>Are you sure you want to delete this table?</div>`, () => {
            editorTableStructure.deleteAuthorTable({
              addOrUpdateEdit,
              chapterId,
              editorName,
              getWorkEditReviewFilled,
              isAuthor,
              languageId: editLanguageId,
              personId,
              tableElement,
              suffixId,
            })
            props.setShowTableOptions(false)
            props.setDeleteTable(false)
          })
        }
      }
      return
    }

    if (props.addTableRow) {
      saveRevision()
      editorTableStructure.addNewTableRow({
        addOrUpdateEdit,
        addOrUpdateEditAddList: props.addOrUpdateEditAddList,
        chapterId,
        editorName,
        getWorkEditReviewFilled,
        handleSetChosenSegment,
        isAuthor,
        languageId: editLanguageId,
        personId,
        setCursorPosition,
        spanElement,
        suffixId,
      })
      props.setShowTableOptions(false)
      props.setAddTableRow(false)
      return
    }

    if (props.deleteTableRow) {
      saveRevision()
      editorTableStructure.deleteTableRowEdit({
        addOrUpdateEdit,
        addOrUpdateEditAddList: props.addOrUpdateEditAddList,
        chapterId,
        getWorkEditReviewFilled,
        languageId: editLanguageId,
        personId,
        spanElement,
        suffixId,
      })
      props.setShowTableOptions(false)
      props.setDeleteTableRow(false)
      return
    }

    if (props.addTableColumn) {
      let cellElement = spanElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      let tableElement = spanElement.parentElement
      let loop = 0
      while (tableElement && !(tableElement.nodeName === 'TABLE' && tableElement.id) && loop < 10) {
        tableElement = tableElement.parentElement
        loop++
      }
      if (tableElement?.nodeName === 'TABLE' && tableElement?.id) {
        saveRevision()
        editorTableStructure.addTableColumnEdit({
          addOrUpdateEdit,
          addOrUpdateEditAddList: props.addOrUpdateEditAddList,
          chapterId,
          columnIndex: cellElement.cellIndex,
          editorName,
          getWorkEditReviewFilled,
          handleSetChosenSegment,
          isAuthor,
          languageId: editLanguageId,
          personId,
          setCursorPosition,
          tableElement,
          suffixId,
        })
        props.setShowTableOptions(false)
        props.setAddTableColumn(false)
      }
      return
    }

    if (props.deleteTableColumn) {
      let cellElement = spanElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      let tableElement = spanElement.parentElement
      let loop = 0
      while (tableElement && !(tableElement.nodeName === 'TABLE' && tableElement.id) && loop < 10) {
        tableElement = tableElement.parentElement
        loop++
      }
      if (tableElement?.nodeName === 'TABLE' && tableElement?.id) {
        saveRevision()
        editorTableStructure.deleteTableColumnEdit({
          addOrUpdateEdit,
          addOrUpdateEditAddList: props.addOrUpdateEditAddList,
          chapterId,
          columnIndex: cellElement.cellIndex,
          editorName,
          getWorkEditReviewFilled,
          isAuthor,
          languageId: editLanguageId,
          personId,
          tableElement,
          suffixId,
        })
        props.setShowTableOptions(false)
        props.setDeleteTableColumn(false)
      }
      return
    }

    if (props.addTableCell) {
      let cellElement = spanElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      let rowElement = cellElement.parentElement
      let tableElement = spanElement.parentElement
      let loop = 0
      while (tableElement && !(tableElement.nodeName === 'TABLE' && tableElement.id) && loop < 10) {
        tableElement = tableElement.parentElement
        loop++
      }
      if (tableElement?.nodeName === 'TABLE' && tableElement?.id) {
        saveRevision()
        editorTableStructure.addTableCellEdit({
          addOrUpdateEdit,
          addOrUpdateEditAddList: props.addOrUpdateEditAddList,
          chapterId,
          columnIndex: cellElement.cellIndex,
          editorName,
          getWorkEditReviewFilled,
          handleSetChosenSegment,
          isAuthor,
          languageId: editLanguageId,
          personId,
          rowIndex: rowElement.rowIndex,
          suffixId,
          tableElement,
        })
        props.setShowTableOptions(false)
        props.setAddTableCell(false)
      }
      return
    }

    if (props.deleteTableCell) {
      let cellElement = spanElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      if (!(cellElement.nodeName === 'TD' && cellElement.id)) cellElement = cellElement.parentElement
      let rowElement = cellElement.parentElement
      let tableElement = spanElement.parentElement
      let loop = 0
      while (tableElement && !(tableElement.nodeName === 'TABLE' && tableElement.id) && loop < 10) {
        tableElement = tableElement.parentElement
        loop++
      }
      if (tableElement?.nodeName === 'TABLE' && tableElement?.id) {
        saveRevision()
        editorTableStructure.deleteTableCellEdit({
          addOrUpdateEdit,
          addOrUpdateEditAddList: props.addOrUpdateEditAddList,
          chapterId,
          columnIndex: cellElement.cellIndex,
          rowIndex: rowElement.rowIndex,
          editorName,
          getWorkEditReviewFilled,
          isAuthor,
          languageId: editLanguageId,
          personId,
          tableElement,
          suffixId,
        })
        props.setShowTableOptions(false)
        props.setDeleteTableCell(false)
      }
      return
    }

    if (props.deleteImage) {
      if (spanElement.nodeName === 'IMG' && spanElement.id) {
        saveRevision()
        if (isAuthor) {
          updateChangeCounts()
        } else {
          addOrUpdateEdit({
            chapterId,
            editSegmentId: 0,
            editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
            elementId: Number(spanElement.id),
            languageId: editLanguageId,
            personId,
            text: '',
            type: 'DELETEIMAGE',
          }, () => setTimeout(() => getWorkEditReviewFilled(), 500))
        }
        spanElement.remove()
        props.setShowImageOptions(false)
        props.setDeleteImage(false)
      }
      return
    }

    if (spanElement && spanElement.nodeName === 'IMG' && spanElement.dataset.type && (isMediumLaptop || isSmallTablet ||isMobile)) {
      props.setIsOpenSlideOut(true)
    }
    editorService.ifMoveSentenceClickInListCancelMove(props.moveSentences, spanElement, props.setMoveSentences)
    saveSelection()
    const selectedText = getSelectionText(event.target)
    if (selectedText) {
      handleSetChosenSegment(spanElement)
      return false
    }

    //If this is an ADDSENTENCE edit that is turned on and the user is clicking into an ADDLISTITEM list item, do not allow this to happen since the list item is already wide open for editing.
    if (props.addSentence && spanElement.dataset.type === 'ADDLISTITEM') {
      createInfoToastAuto(`<div><div>It is not necessary to add a sentence in an Add List Item entry.</div><div>Add List Item is already open for editing.</div></div>`)
      return
    }

    androidEnterKey = []
    if (event.target.nodeName === 'IMG' && event.target.dataset.type === 'COMMENT') {
      spanElement = document.querySelector(`span[id="${event.target.id.replace(`~tabView${suffixId}`, '')}"][data-type='TEXT']`)
      if (spanElement) {
        handleSetCurrentElement(spanElement, chapterId)
        return
      }
    }

    //handleSetChosenSegment(event.target)  //??Don't do this yet. If we are clicking into the same sentence, I want to know about it later on in the mouseUpAction function of editor-dom.
    if (props.reorderListItems && editorService.isCursorInsideList(spanElement, chapterId)) {
      saveRevision()
      setListItemReorderArray(editorService.setReorderDropDownLists(spanElement, onChooseReorder, isAuthor, updateChangeCounts))
    }

    if (props.addList && props.convertAddListSequence === 1) editorService.setConvertAddListStartIcon({ editorDivId, currentElement: spanElement, editorName, updateConvertAddListEdit, editLanguageId, setOpenListModal })

    if (props.addListItem && editorService.isCursorInsideList(spanElement, chapterId)) {
      //Just do it! Don't make the user click a second time on the question-icon. If they click on a list item, then let them have a new one.
      if (isAuthor) {
        const newSpan = editorService.addNewListItemByAuthor(spanElement, handleSetChosenSegment)
        const tempSegments = editorService.gatherSegmentsToSave(segments, chapterId, editLanguageId)
        editorService.setSegments({
          addChapterListLevels: props.addChapterListLevels,
          chapterId,
          chapterListLevels: props.chapterListLevels,
          chosenTab,
          divDisplayId: editorDivId,
          editorName,
          edits,
          isAuthor,
          isTranslation,
          listLevelGeneral: props.listLevelGeneral,
          personId,
          savedRange,
          segments: tempSegments,
          showEditIcons,
          tabsData,
          workSummary,
        })
        props.updateSegmentsLocal(tempSegments)
        setTimeout(() => {
          const span = document.querySelector(`span[id="${newSpan.id}"][data-type="TEXT"]`)
          setCursorPosition(span, span, 0, 0)
        }, 1000)

      } else {
        addOrUpdateEdit({
          addListItemSequence: spanElement.dataset.addListItemSequence ? Number(spanElement.dataset.addListItemSequence) + 1 : 1,
          authorTextSnapshot: '',
          chapterId,
          editSegmentId: 0,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          elementId: spanElement.id,
          firstName: editorName && editorName.firstName,
          isNewAddListItemSequence: true,
          languageId: props.editLanguageId,
          lastName: editorName && editorName.lastName,
          personId,
          text: '&nbsp;____',
          type: 'ADDLISTITEM',
        }, () => {
          setTimeout(() => getWorkEditReviewFilled(), 500)
          setTimeout(() => editorService.setCursorPositionByRecallAddListItem(spanElement.id, spanElement.dataset.addListItemSequence ? Number(spanElement.dataset.addListItemSequence) + 1 : 1, handleSetChosenSegment, handleSetCurrentElement), 1000)
        })
      }
      props.setAddListItem(false) //Be sure to turn this off or it will continue to add more list items and confuse the user (like it did me)
    }
    if (props.deleteListItem && editorService.isCursorInsideList(spanElement, chapterId)) {
      editorService.setDeleteListItemIcon({
        addChapterListLevels,
        addOrUpdateEdit,
        chapterId,
        chapterListLevels,
        chosenTab,
        currentElement: spanElement,
        editLanguageId,
        editorDivId,
        editorName,
        edits,
        isAuthor,
        isTranslation,
        listLevelGeneral,
        personId,
        savedRange,
        segments,
        tabsData,
        updateSegmentsLocal: props.updateSegmentsLocal,
        workSummary
      })
    }
    if (props.moveSentences && !editorService.isCursorInsideList(spanElement, chapterId)) {
      editorService.setMoveSentencesStartIcon({ editorDivId, currentElement: spanElement, editorName, chapterId, updateMoveEdit, editLanguageId })
    }
    //if (props.changeStyle) setTimeout(() => editorService.setChangeStyleStartIcon({ editorDivId, currentElement: spanElement, editorName, updateChangeStyleEdit, editLanguageId }), 500)
    if (props.changeStyle) editorService.setChangeStyleStartIcon({ editorDivId, currentElement: spanElement, editorName, updateChangeStyleEdit, editLanguageId })
    if (props.addParagraphSentence && editorService.isCursorInsideSpan(spanElement) && spanElement.dataset.type === 'TEXT') {
      if (editorService.isCursorInsideList(spanElement, chapterId)) {
        createInfoToastAuto(`<div>Adding a paragraph and sentence should not be used on an outline.
          Use an outline type edit option instead, such as 'Add List Item', 
          or press the enter key in a list item to start a new line.</div>`)
      } else {
        editorService.setAddParagraphSentenceIcon({
          editorDivId,
          currentElement: spanElement,
          personId,
          editorName,
          chapterId,
          addOrUpdateEdit,
          handleSetChosenSegment,
          setAddParagraphSentence: props.setAddParagraphSentence,
          editLanguageId,
          getWorkEditReviewFilled
        })
      }
    }
    if (props.addSentence && editorService.isCursorInsideSpan(spanElement)) {
      editorService.setAddSentenceIcon({
        editorDivId,
        currentElement: spanElement,
        personId,
        editorName,
        chapterId,
        addOrUpdateEdit,
        handleSetChosenSegment,
        setAddSentence: props.setAddSentence,
        editLanguageId,
        getWorkEditReviewFilled
      })
    }
    if (props.deleteSentence && ((editorService.isCursorInsideSpan(spanElement) || (spanElement && spanElement.nodeName === 'IMG' && spanElement.dataset.type === 'DELETESENTENCE')))) {
      //If deleteSentence edit type is chosen, then the first click is to choose the first sentence (and submit it as an edit)
      //A second click will determine the end of the sentences to be deleted (even if it is the current sentence)
      if (!deleteSentenceArray || deleteSentenceArray.length === 0) {
        setTimeout(() => {
          updateDeleteSentenceEdit('ChoseStartElement', editorDivId, spanElement.id, editLanguageId);
          editorService.setDeleteSentenceStartIcon({ editorDivId, currentElement: spanElement, personId, editorName, chapterId, updateDeleteSentenceEdit, editLanguageId });
          editorService.setDeleteSentencesEndIcons(editorDivId, updateDeleteSentenceEdit, editLanguageId, spanElement.id, chapterId);
        }, 500)
      } else {
        //In the sentence-click case, however, (in contrast to the icon click that has the left arrow from the start of the given sentence) we will pick up the id
        //  of the next span so that it is deleted as well.  
        //If the delete-sentence image was clicked on (before the actual span) then we need to go nextSibling twice.
        let nextSpanElement = spanElement.nodeName === 'IMG' ? spanElement?.nextSibling?.nextSibling : spanElement?.nextSibling
        let loop = 0
        while (!(nextSpanElement && nextSpanElement.nodeName === 'SPAN' && nextSpanElement.id) && loop < 10) {
          nextSpanElement = nextSpanElement && nextSpanElement.nextSibling
          loop++
        }
        //If the nextSpanElement was not yet found, then the last sentence of a paragraph must have been chosen. So we need to go to the next paragraph (parentElement) and get the first span from there
        if (!(nextSpanElement && nextSpanElement.nodeName === 'SPAN' && nextSpanElement.id)) {
          let previousParagraph = spanElement.parentElement.nextElementSibling
          if (previousParagraph) {
            nextSpanElement = previousParagraph.firstChild
            let loop = 0
            while (!(nextSpanElement && nextSpanElement.nodeName === 'SPAN' && nextSpanElement.id) && loop < 10) {
              nextSpanElement = nextSpanElement && nextSpanElement.nextSibling
              loop++
            }
          }
        }
        if (nextSpanElement) {
          if (isAuthor) {
            deleteSentenceArray.push(spanElement.id)
            //Delete the segments and set the segments again and set review.
            //If there are paragraphs that are erradicated entirely due to the sentences being deleted, then remove the paragraph as well.
            let tempSegments = editorService.gatherSegmentsToSave(segments, chapterId, editLanguageId)
            tempSegments = editorService.deleteSegments(tempSegments, deleteSentenceArray)
            editorService.setSegments({
              addChapterListLevels: props.addChapterListLevels,
              chapterId,
              chapterListLevels: props.chapterListLevels,
              chosenTab,
              divDisplayId: editorDivId,
              editorName,
              edits,
              isAuthor,
              isTranslation,
              listLevelGeneral: props.listLevelGeneral,
              personId,
              savedRange,
              segments: tempSegments,
              showEditIcons,
              tabsData,
              workSummary,
            })
            props.updateSegmentsLocal(tempSegments)
            props.setDeleteSentence(false)
          } else {
            updateDeleteSentenceEdit('ChoseEndElement', editorDivId, nextSpanElement && nextSpanElement.id, editLanguageId)
            editorService.setDeleteSentencesEndIcons(editorDivId, updateDeleteSentenceEdit, editLanguageId, spanElement.id, chapterId)
            editorService.setDeleteSentencesEditorIcon(personId, editorName, chapterId, spanElement)
          }
          editorService.removeDeleteSentenceEndIcons(editorDivId)
          deleteSentenceArray = []
          handleSetCurrentElement(nextSpanElement, chapterId)
        }
        return //If this continues on it is possible that a second edit will be created locally as a TEXT which is blank so when the user goes to delete this DELETESENTENCE edit, the other one will stil exist and make the background of the sentence colored and show an edit (which doesn't match with the database, by the way).
      }
    }

    if (props.deleteParagraphBreak) {
      if (editorService.isCursorInsideList(spanElement, chapterId)) {
        createInfoToastAuto(`<div>Deleting a paragraph in an outline list is not allowed. 
          You can put the cursor at the beginning of a list item and press the backspace 
          button to move that item to the left and up to the next line.</div>`)
      } else if (editorService.isCursorInsideSpan(spanElement)) {
        editorService.setDeleteParagraphBreakIcon(spanElement, personId, editorName, chapterId, addOrUpdateEdit, handleSetChosenSegment, props.setDeleteParagraphBreak, editLanguageId, getWorkEditReviewFilled)
      }
    }

    if (props.addParagraphBreak) {
      if (editorService.isCursorInsideList(spanElement, chapterId)) {
        createInfoToastAuto(`<div>Adding a paragraph and sentence should not be used on an outline.
          Use an outline type edit option instead, such as 'Add List Item', 
          or press the enter key in a list item to start a new line.</div>`)
      } else if (editorService.isCursorInsideSpan(spanElement)) {
        editorService.setAddParagraphBreakIcon({
          editorDivId,
          currentElement: spanElement,
          personId,
          editorName,
          chapterId,
          addOrUpdateEdit,
          handleSetChosenSegment,
          setAddParagraphBreak: props.setAddParagraphBreak,
          editLanguageId,
          getWorkEditReviewFilled
        })
      }
    }

    //Clear edit things when the given edit control is not chosen - just to make sure there aren't orphans.
    if (!props.moveSentences) props.setChosenMoveEdit() //Reset any moveEdit icons that could have been emphasized in the editor for display
    if (!props.changeStyle) props.setChosenChangeStyleEdit() //Reset any changeStyleEdit icons that could have been emphasized in the editor for display
    if (!props.addParagraphBreak) props.setChosenAddParagraphEdit()
    if (!props.deleteParagraphBreak) props.setChosenDeleteParagraphEdit('', 'ClearLast')
    if (!props.addList && spanElement && spanElement.nodeName !== 'IMG') props.unhighlightChosenAddListEdit()
    if (!props.addListItem) props.setChosenAddListItemEdit()
    if (!props.deleteListItem) props.setChosenDeleteListItemEdit('', 'ClearLast')
    if (!props.reorderListItems) props.setChosenReorderListItemsEdit('', 'ClearLast')

    let { returnIsTextChanged, previousSpan } = editorService.mouseUpAction({
      addOrUpdateEdit,
      addOrUpdateEditAddList,
      chapterId,
      chapterListLevels,
      chosenHTMLSegment,
      chosenSegment,
      editLanguageId,
      editorDivId,
      editorName,
      edits,
      event,
      getWorkEditReviewFilled,
      globalPreviousSpan,
      handleSetChosenSegment,
      handleSetCurrentElement,
      isAuthor,
      isEditorDivView,
      isTextChanged,
      isTranslation,
      personId,
      savedCursorPosition,
      saveRevision,
      scrollDocumentToMatch,
      segments,
      setChosenAddParagraphEdit: props.setChosenAddParagraphEdit,
      setChosenDeleteListItemEdit: props.setChosenDeleteListItemEdit,
      setChosenDeleteParagraphEdit: props.setChosenDeleteParagraphEdit,
      setChosenHTMLSegment,
      setReorderListItems: props.setReorderListItems,
      suffixId,
      updateChangeCounts,
      workSummary,
    })

    isTextChanged = returnIsTextChanged
    globalPreviousSpan = previousSpan
    editorService.clearTextHighlights(`~tabView${suffixId}`, chosenSegment, edits, spanElement)
    setTimeout(() => {
      setFocusOnCurrentElement(spanElement)
      editorService.restoreCursorLocation(document.getElementById(editorDivId), savedCursorPosition)
    }, 100)

    if (isTextChanged) {
      updateChangeCounts()
      isTextChanged = false
    }
    //If this is an image touch on mobile, then let the sidepanel open and find the currentElement in order to display.
    //But not if it is a question icon to start the process. If "question" is found in the class name, then don't open.
    if (isMobile && spanElement && spanElement.nodeName === 'IMG' && spanElement.title && spanElement.src.indexOf('question') === -1) { //!Array.from(spanElement.classList).some(className => className.includes('question'))) { //If it has a title then it is displaying an editor's name which means that it is an image that is going to be found in the side panel. 
      props.setIsOpenSlideOut(true)
    }
    if (!isMobile) handleSetCurrentElement(spanElement, chapterId) //This is here, again, because the tabView was losing the highlight on the chosen sentence. (It is called earlier in this big function)
  }

  const setFocusOnCurrentElement = (element) => {
    if (element) {
      let editorDiv = document.getElementById(editorService.setIdByDivDisplayId(chapterId, editorDivId))
      if (editorDiv) {
        editorDiv.focus()
        element && element.focus()
      }
    }
  }

  const handleKeyDOWNEditor = (event) => {
    event.stopPropagation()
    event.preventDefault()
    return false
  }

  const processEnterKey = (event, spanElement) => {
    event.stopPropagation()
    event.preventDefault()
    pageVisitor({ p: 'AuthoringEditor', n: `Enter key was pressed` })
    if (spanElement) {
      let parent = spanElement.parentElement
      let grandParent = parent && parent.parentElement
      let listItem = parent && parent.nodeName === 'LI' ? parent : grandParent && grandParent.nodeName === 'LI' ? grandParent : ''
      let newSpan

      if (listItem) {
        //If this is a list item and the current listItem doesn't have anything but a blank span (or a span with just a &nbsp;) then it is like a shift-TAB (or Backspace) to go backwards
        if (editorService.isListItemBlankContent(listItem)) {
          let elementNotTop = editorService.ensureChosenElementNotTop(spanElement, chosenSegment, chapterId)
          const tabParagraphOrList = editorService.isFirstPositionOfParagraphOrList(elementNotTop, true, chosenSegment)

          editorService.adjustTab({
            addChapterListLevels,
            addOrUpdateEdit,
            chapterId,
            chapterListLevels,
            chosenSegment: props.chosenSegment,
            currentElement,
            editLanguageId,
            editorName,
            edits,
            event,
            forceShiftKey: true, //Because this is a force backward movement.
            getWorkEditReviewFilled,
            handleSetChosenSegment,
            isAuthor,
            listLevelGeneral,
            personId,
            responseEdit: props.responseEdit,
            setDeleteParagraphBreak: props.handleSetDeleteParagraphBreak,
            setIsInitEdits: props.setIsInitEdits,
            tabParagraphOrList,
            tabsData,
            workId: workSummary.workId,
          })
          currentElement.focus()
          setCursorPosition(currentElement, currentElement, 0, 0)

          //Add one to the cursor position for the left move. I don't know how it is going to respond to the right move.
          let { end, newElement, start } = savedCursorPosition
          editorService.restoreCursorLocation(document.getElementById(editorDivId), {
            end, //: ++end,
            newElement,
            start, //: ++start
          })

        } else if (!isAuthor) {
          event.preventDefault()
          editorService.enterEditorNewListItem(spanElement, personId, chapterId, editLanguageId, addOrUpdateEdit, getWorkEditReviewFilled, handleSetChosenSegment, handleSetCurrentElement)
          return false
        } else {
          event.preventDefault()
          newSpan = editorService.createNewListItem(spanElement, handleSetChosenSegment)
        }
      } else {
        newSpan = editorService.createNewParagraphOnEnterKey({
          addOrUpdateEdit,
          chapterId,
          editLanguageId,
          editorName,
          element: spanElement,
          getWorkEditReviewFilled,
          handleSetChosenSegment,
          isAuthor,
          languageId: workSummary.languageId_current,
          personId,
          setCursorPosition,
          showEditIcons,
          workId: workSummary.workId,
        })
      }
      if (newSpan) {
        //newSpan.contentEditable = 'true'
        setCursorPosition(newSpan, newSpan, 0, 0)
        handleSetChosenSegment(newSpan)
        handleSetCurrentElement(newSpan, chapterId)
        setChosenHTMLSegment(chosenHTMLSegment.concat(newSpan.innerHTML))
        skipRestoreCursorOnce = true
        saveRevision()
        androidEnterKey = []
      }
      return false
    }
  }

  const hasAndroidEnterKey = () => {
    if (editorService.isMobile()) {
      let found = androidEnterKey.filter(enterKey => enterKey.indexOf('ENTER') > -1)[0]
      if (found) {
        androidEnterKey = []
        return true
      }
    }
    return false
  }

  const handleKeyDOWN = (event) => {
    const firstElementId = Number(editorService.getSuffixIdFromEditorDivId(editorDivId) + '0001') //Using the chapterId to get the editorDivId is a problem probably because the first chapterId is in the querystring of the parameter call to this page.
    let spanElement = event.target
    if (!spanElement || spanElement.id === firstElementId || spanElement.id === editorDivId) {
      spanElement = editorService.getLocationSegment() //When using the HOME key or END key, we need to know where the cursor ends up for the editor so that we can continue to watch the location of the cursor for sentence comparison.
      if (!spanElement) spanElement = savedCursorPosition?.newElement
    }
    handleSetCurrentElement(spanElement, chapterId)
    //Help ToDo - What is this? androidEnterKey.push(event.nativeEvent.code + ' - ' + event.nativeEvent.key + ' - ' + event.keyCode)
    let selection
    let selectionChildren
    try {
      selection = window && window.getSelection() && window.getSelection().getRangeAt(0)
      selectionChildren = selection && selection.cloneContents().childNodes
    } catch (e) {
      //do nothing
    }

    if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "b" || event.key === "B") && !isTranslation) {
      props.setDeleteParagraphBreak(!props.deleteParagraphBreak)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setDeleteParagraphBreak` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "a" || event.key === "A") && !isTranslation) {
      props.setAddParagraphBreak(!props.addParagraphBreak)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setAddParagraphBreak` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "p" || event.key === "P") && !isTranslation) {
      props.setAddParagraphSentence(!props.addParagraphSentence)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setAddParagraphSentence` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "c" || event.key === "C") && !isTranslation) {
      props.setAddSentence(!props.addSentence)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setAddSentence` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "d" || event.key === "D") && !isTranslation) {
      props.setDeleteSentence(!props.deleteSentence)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setDeleteSentence` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "l" || event.key === "L") && !isTranslation) {
      props.setAddList(!props.addList)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setAddList` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "t" || event.key === "T") && !isTranslation) {
      props.setAddList(!props.changeList)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setAddList` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "n" || event.key === "N") && !isTranslation && hasListStructure) {
      props.setAddListItem(!props.addListItem)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setAddListItem` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "i" || event.key === "I") && !isTranslation && hasListStructure) {
      props.setDeleteListItem(!props.deleteListItem)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setDeleteListItem` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "r" || event.key === "R") && hasListStructure) {
      props.setReorderListItems(!props.reorderListItems)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setReorderListItems` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "m" || event.key === "M") && !isTranslation) {
      props.setMoveSentences(!props.moveSentences)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setMoveSentences` })
    } else if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "s" || event.key === "S") && !isTranslation) {
      props.setChangeStyle(!props.changeStyle)
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: setChangeStyle` })


      //Control+S
    } else if (isAuthor && (event.ctrlKey || event.metaKey) && (event.key === 's' || event.key === 'S')) {
      event.stopPropagation()
      event.preventDefault()
      saveByButtonPress()
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: Save` })

      //Control+A
    } else if (isAuthor && (event.ctrlKey || event.metaKey) && (event.key === 'a' || event.key === 'A')) {
      pageVisitor({ p: 'AuthoringEditor', n: `Short cut key: Control+A` })
      return true

      //ENTER
    } else if (event.key === 'Enter' || hasAndroidEnterKey()) {
      let saveSegments = []

      const allowedTypes = ['ADDLIST', 'ADDTABLE', 'ADDTABLEROW', 'ADDTABLECOLUMN', 'ADDTABLECELL']

      if (!isAuthor && allowedTypes.includes(spanElement?.dataset?.type)) {
        event.stopPropagation()
        event.preventDefault()
        editorService.appendLastNewAddListItem(spanElement)
        setTimeout(() => {
          if (spanElement?.dataset?.type === 'ADDLIST') {
            saveSegments = editorService.gatherAddListSegmentsToSave(spanElement?.dataset?.editSegmentId, suffixId)
          } else if (spanElement?.dataset?.type === 'ADDTABLE') {
            saveSegments = editorTableStructure.gatherAddTableSegmentsToSave(spanElement?.dataset?.editSegmentId, suffixId)
          } else if (spanElement?.dataset?.type === 'ADDTABLEROW') {
            saveSegments = editorTableStructure.gatherRowAddTableSegmentsToSave(spanElement?.dataset?.editSegmentId, suffixId)
          } else if (spanElement?.dataset?.type === 'ADDTABLECOLUMN') {
            saveSegments = editorTableStructure.gatherColumnAddTableSegmentsToSave(spanElement?.dataset?.editSegmentId, suffixId)
          } else if (spanElement?.dataset?.type === 'ADDTABLECELL') {
            saveSegments = editorTableStructure.gatherCellAddTableSegmentsToSave(spanElement?.dataset?.editSegmentId, suffixId)
          }
          addOrUpdateEditAddList(saveSegments)
        }, 500) //Give the chance for the addOrUpdateEditAddList update above before this is run again. It can collide if it is too fast.
        return false
      } else {
        processEnterKey(event, spanElement)
      }

      //Arrow Left
    } else if (event.key === 'ArrowLeft') {
      if (event.key === 'ArrowLeft' && editorService.leftElementIsEditImage()) {
        editorService.placeCursorAtFirstCharacter(spanElement, handleSetChosenSegment)
        return
      }

      //TAB
    } else if (event.key === 'Tab') {
      pageVisitor({ p: 'AuthoringEditor', n: `Tab key pressed` })
      //Note, if this is the editor and a tab is hit at the very beginning of the first sentence, then do the tab modification
      let elementNotTop = editorService.ensureChosenElementNotTop(spanElement, chosenSegment, chapterId)
      const isCursorAtFrontOfList = editorService.isFirstPositionOfParagraphOrList(spanElement, event.shiftKey, chosenSegment)
      let tabParagraphOrList
      if (isCursorAtFrontOfList) {
        if (spanElement.nodeName === 'LI' || spanElement.nodeName === 'P') {
          tabParagraphOrList = spanElement
        } else {
          let loop = 0
          while (spanElement && !tabParagraphOrList && !(spanElement.nodeName === 'LI' || spanElement.nodeName === 'P') && loop < 7) {
            spanElement = spanElement.parentElement
            if (spanElement.nodeName === 'LI' || spanElement.nodeName === 'P') tabParagraphOrList = spanElement
            loop++
          }
        }
      }

      //If this is the editor in an ADDLIST edit on the first level and hitting Shift+TAB to go backwards, do not let the editor go outside of the freestyle list. Force them to stay on that first level.
      if (!isAuthor && event.shiftKey && editorService.isInAddListFirstLevel(spanElement)) {
        pageVisitor({ p: 'AuthoringEditor', n: `Tab in AddListFirstLevel` })
        event.preventDefault()
        event.stopPropagation()
        return false
      }

      const listType = editorService.isListStart(spanElement)  //* or 1. are lists starters when hitting tab after those characters.
      if (listType) {
        pageVisitor({ p: 'AuthoringEditor', n: `Tab in list type` })
        event.preventDefault()
        setListChoice(listType)
      }

      //If NOT in a list but the cursor is in the first position of the first child
      //Else if in a list and (has a preceding tab element (span or paragraph) OR if there isn't a preceding tab element but the cursor is in the first child in the first position)
      if (isAuthor || spanElement.dataset.type === 'ADDLIST') {
        saveRevision()
        let focusElement
        if (tabParagraphOrList) {
          //Give the paragraph a text-indent (just one) then add to the margin-left which means block-indent (which is Word's convention)
          event.stopPropagation()
          event.preventDefault()
          clearTimeout(debounceTimer);

          if (editorService.isCursorInsideList(spanElement, chapterId)) {
            debounceTimer = setTimeout(() => editListStructure.setListLevel({
              currentElement,
              elementListItem: tabParagraphOrList,
              direction: event.shiftKey ? 'MINUS' : 'PLUS',
              chapterId,
              edits,
              chapterListLevels,
              listLevelGeneral,
              addChapterListLevels,
              chosenSegment,
            }), 200)
            focusElement = currentElement
          } else {
            let paragraph = tabParagraphOrList.nodeName === 'P' ? tabParagraphOrList : editorService.setAsParagraphElement(elementNotTop)
            focusElement = editorService.adjustParagraphForAuthor(paragraph, event.shiftKey)

          }
          if (focusElement) setTimeout(() => {
            setCursorPosition(focusElement, focusElement, 0, 0)
            savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))
          }, 500)
        }

      } else if (tabParagraphOrList) { //This is for the author as well as the editor.  //editorService.isCursorInsideList(spanElement)
        event.preventDefault()
        event.stopPropagation()
        savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))

        editorService.adjustTab({
          addChapterListLevels,
          addOrUpdateEdit,
          chapterId,
          chapterListLevels,
          chosenSegment: props.chosenSegment,
          currentElement: spanElement,
          editLanguageId,
          editorName,
          edits,
          event,
          getWorkEditReviewFilled,
          handleSetChosenSegment,
          isAuthor,
          listLevelGeneral,
          personId,
          responseEdit: props.responseEdit,
          setDeleteParagraphBreak: props.handleSetDeleteParagraphBreak,
          setIsInitEdits: props.setIsInitEdits,
          tabParagraphOrList,
          tabsData,
          workId: workSummary.workId,
        })
        //For the ADDTAB, the span element was changed to the paragraph in order to set the edit to the paragraph
        // but we need to set the cursor back into the span so that the user can conveniently continue with another tab or an edit.
        //if (!isAuthor && spanElement.nodeName === 'SPAN') spanElement.contentEditable = 'true'
        spanElement.focus()
        setTimeout(() => setCursorPosition(spanElement, spanElement, 0, 0), 500)

        //Add one to the cursor position for the left move. I don't know how it is going to respond to the right move.
        let { end, newElement, start } = savedCursorPosition
        editorService.restoreCursorLocation(document.getElementById(editorDivId), {
          end, //: ++end,
          newElement,
          start, //: ++start
        })

      } else if (!isAuthor && spanElement?.dataset?.type !== 'ADDLIST') {
        event.preventDefault()
        event.stopPropagation()
        pageVisitor({ p: 'AuthoringEditor', n: `Shift+Tab pressed together` })
        if (event.shiftKey) editorService.useTabToPreviousSentence(spanElement, chapterListLevels, chapterId)
        else editorService.useTabToNextSentence(spanElement, chapterListLevels, chapterId)
        skipRestoreCursorOnce = true
      }
      //BACKSPACE or DELETE before a selection is cut out.
    } else if (selectionChildren && selectionChildren.length > 0 && (event.key === 'Backspace' || event.key === 'Delete')) {
      setIsCopyCommand(false)
      saveRevision()
      //if (!isAuthor) editorService.spliceRemainingEndsFromSelectDelete(event, selectionChildren)  
      if (!isAuthor) {
        editorService.setDeleteSentencesForEditor_DOM(spanElement, savedRange, chapterId, editLanguageId, personId, editorName, addOrUpdateEdit, edits, savedElementsForEdit)
        updateChangeCounts()
        return false
      }
      setSavedElementsForEdit(null) //We need to blank this out since it will be filled in after making a selection and deleting a list. It could appear that the selection contains a list and will throw off the ADDLIST edit option if the user clicks on it again right after making this delete.
      //Backspace without anything selected - just a keystroke backwards which might be crossing (and deleting a penspring span boundary to combine to sentences)
    } else if (event.key === 'Backspace' && !isAuthor) {  //Backspace
      pageVisitor({ p: 'AuthoringEditor', n: `Backspace pressed` })
      //If this is the editor in an ADDLIST edit on the first level on the first child and hitting Backspace to go backwards which would then take the editor out of list, 
      //  do not let the editor go outside of the freestyle list. Force them to stay on that first level in that firstChild position.
      if (!isAuthor && editorService.isInAddListFirstLevelFirstSpan(spanElement)) {
        event.preventDefault()
        event.stopPropagation()
        return false
      }

      if (spanElement?.dataset?.type !== 'ADDLIST') {  //This is strongly assumed that this is the editor and that she is editing freestyle in her own ADDLIST edit. So just let the default behavior work.
        const selection = window.getSelection();
        const characterBeforeCaret = editorService.getCharacterBeforeCaret()
        // Check if there's a selection and the caret is at the start of a span
        if (editorService.isAtFirstCharacterOfSpan(selection) && selection.isCollapsed && ((selection.anchorOffset === 1 && characterBeforeCaret === ' ') || selection.anchorOffset === 0)) {
          const anchorNode = selection.anchorNode
          const currentSpan = anchorNode.nodeType === 3 ? anchorNode.parentNode : anchorNode
          const currentSpanLength = currentSpan.textContent.length
          // Get previous sibling element (and we might need to jump over an edit icon, so we're gpoing to look for the previous element sibling that is a penspring span with an id)
          let previousSibling = currentSpan.previousElementSibling
          let loop = 0
          while (previousSibling && !(previousSibling.nodeName === 'SPAN' && previousSibling.id && !isNaN(previousSibling.id) && previousSibling.textContent) && loop < 10) {
            previousSibling = previousSibling.previousElementSibling
            loop++
          }
          if (previousSibling) {
            const currentSpanId = currentSpan.id
            // Check if current and previous elements are spans and merge them if they are
            if (currentSpan.tagName === 'SPAN' && previousSibling && previousSibling.tagName === 'SPAN') {
              event.preventDefault()  // Stop the default backspace action
              // Merge text from current span into the previous span
              previousSibling.textContent += currentSpan.textContent
              // Remove the current span
              currentSpan.innerHTML = ''
              // Reset caret position to the end of the previous span's content
              const range = document.createRange()
              range.selectNodeContents(previousSibling)
              range.collapse(false) // false to collapse the range to its end
              selection.removeAllRanges()
              selection.addRange(range)

              if (isAuthor) {
                const deleteSpan = document.querySelector(`span[id="${currentSpanId}"][data-type="TEXT"]`)
                if (deleteSpan) deleteSpan.remove()
              } else {
                const prevEdit = edits?.length > 0 && edits.filter(m => Number(m.elementId) === Number(previousSibling.id)
                  && m.personId === personId
                  && m.type === 'TEXT'
                  && previousSibling.dataset.type === 'TEXT')

                addOrUpdateEdit({
                  personId,
                  editSegmentId: (prevEdit?.editSegmentId) || 0,
                  editSegmentTypeId: 0, //This is going to be filled in by the backend with the type given below.
                  firstName: editorName && editorName.firstName,
                  lastName: editorName && editorName.lastName,
                  chapterId,
                  elementId: previousSibling.id,
                  languageId: editLanguageId,
                  type: 'TEXT',
                  text: previousSibling.innerHTML,
                  authorTextSnapshot: '',
                  comment: (prevEdit?.comment) || '',
                })

                addOrUpdateEdit({
                  personId,
                  editSegmentId: 0,
                  editSegmentTypeId: 0, //This is going to be filled in by the backend with the type given below.
                  firstName: editorName && editorName.firstName,
                  lastName: editorName && editorName.lastName,
                  chapterId,
                  elementId: Number(currentSpanId),
                  languageId: editLanguageId,
                  type: 'DELETESENTENCE',
                  text: '',
                  authorTextSnapshot: '',
                  comment: '',
                })
              }
            }
            setTimeout(() => {
              editorService.setCursorAtEndOfSpan(previousSibling.id, currentSpanLength)
              savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))
            }, 500)
          } else if (editorService.isCursorInsideList(spanElement, chapterId)) { //If the cursor is in a list, then the backspace is like a SHIFT + TAB to move the indentation to the left.
            const isCursorAtFrontOfList = editorService.isFirstPositionOfParagraphOrList(spanElement, event.shiftKey, chosenSegment)
            let tabParagraphOrList
            if (isCursorAtFrontOfList) {
              if (spanElement.nodeName === 'LI' || spanElement.nodeName === 'P') {
                tabParagraphOrList = spanElement
              } else {
                let loop = 0
                while (spanElement && !tabParagraphOrList && !(spanElement.nodeName === 'LI' || spanElement.nodeName === 'P') && loop < 7) {
                  spanElement = spanElement.parentElement
                  if (spanElement.nodeName === 'LI' || spanElement.nodeName === 'P') tabParagraphOrList = spanElement
                  loop++
                }
              }
            }

            if (tabParagraphOrList) { //This is for the author as well as the editor.  //isCursorInsideList(spanElement)
              event.preventDefault()
              event.stopPropagation()
              savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))

              pageVisitor({ p: 'AuthoringEditor', n: `Tab adjustment is being processed` })
              editorService.adjustTab({
                addChapterListLevels,
                addOrUpdateEdit,
                chapterId,
                chapterListLevels,
                chosenSegment: props.chosenSegment,
                currentElement: spanElement,
                editLanguageId,
                editorName,
                edits,
                event,
                getWorkEditReviewFilled,
                handleSetChosenSegment,
                isAuthor,
                listLevelGeneral,
                personId,
                responseEdit: props.responseEdit,
                setDeleteParagraphBreak: props.handleSetDeleteParagraphBreak,
                setIsInitEdits: props.setIsInitEdits,
                tabParagraphOrList,
                tabsData,
                workId: workSummary.workId,
              })
              //For the ADDTAB, the span element was changed to the paragraph in order to set the edit to the paragraph
              // but we need to set the cursor back into the span so that the user can conveniently continue with another tab or an edit.
              //if (!isAuthor && spanElement.nodeName === 'SPAN') spanElement.contentEditable = 'true'
              spanElement.focus()
              setTimeout(() => setCursorPosition(spanElement, spanElement, 0, 0), 500)

              //Add one to the cursor position for the left move. I don't know how it is going to respond to the right move.
              let { end, newElement, start } = savedCursorPosition
              editorService.restoreCursorLocation(document.getElementById(editorDivId), {
                end, //: ++end,
                newElement,
                start, //: ++start
              })
            }
          }

        } else {
          const tabParagraphOrList = editorService.isFirstPositionOfParagraphOrList(spanElement, true, chosenSegment)  //event.shiftKey is set to true since backspace is like a backwards TAB thing
          //If the element has a preceding tab element (span or paragraph) OR if there isn't a preceding tab element but the cursor is in the first child in the first position
          if (tabParagraphOrList) {
            event.preventDefault()
            event.stopPropagation()
            editorService.adjustTab({
              addChapterListLevels,
              addOrUpdateEdit,
              chapterId,
              chosenSegment: props.chosenSegment,
              currentElement: spanElement,
              editLanguageId,
              editorName,
              edits,
              event,
              getWorkEditReviewFilled,
              handleSetChosenSegment,
              isAuthor,
              listLevelGeneral,
              personId,
              responseEdit: props.responseEdit,
              setDeleteParagraphBreak: props.handleSetDeleteParagraphBreak,
              tabParagraphOrList,
              tabsData,
              workId: workSummary.workId,
            })
            updateChangeCounts()
          }
        }
      }
    } else if (event.key === 'Delete') {
      pageVisitor({ p: 'AuthoringEditor', n: `Delete key is pressed` })
      if (spanElement?.dataset?.type !== 'ADDLIST') {  //This is strongly assumed that this is the editor and that she is editing freestyle in her own ADDLIST edit. So just let the default behavior work.
        const selection = window.getSelection();
        if (selection.rangeCount > 0 && selection.isCollapsed) {
          let nextSiblingLength
          const anchorNode = selection.anchorNode;
          const currentSpan = anchorNode.nodeType === 3 ? anchorNode.parentNode : anchorNode;
          const currentSpanLength = currentSpan.textContent.length;
          // Check if caret is at the end of the span's text
          if (selection.anchorOffset === currentSpanLength) {
            event.preventDefault();  // Prevent the default delete action
            let nextSibling = currentSpan.nextElementSibling;
            let loop = 0;
            while (nextSibling && !(nextSibling.nodeName === 'SPAN' && !isNaN(nextSibling.id) && nextSibling.textContent) && loop < 10) {
              nextSibling = nextSibling.nextElementSibling;
              loop++;
            }
            if (nextSibling && nextSibling.tagName === 'SPAN') {
              nextSiblingLength = nextSibling.textContent.length
              // Merge text from current span into the next span
              currentSpan.textContent = currentSpan.textContent + nextSibling.textContent;
              // Remove the current span
              if (isAuthor) nextSibling.parentNode.removeChild(nextSibling);
              // Reset caret position to the start of the next span's content
              const range = document.createRange();
              range.selectNodeContents(nextSibling);
              range.setStart(nextSibling.firstChild, 0);
              range.setEnd(nextSibling.firstChild, 0);
              selection.removeAllRanges();
              selection.addRange(range);
            }
            if (!isAuthor) {
              const prevEdit = edits?.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentSpan.id)
                && m.personId === personId
                && m.type === 'TEXT'
                && currentSpan.dataset.type === 'TEXT')

              addOrUpdateEdit({
                personId,
                editSegmentId: (prevEdit?.editSegmentId) || 0,
                editSegmentTypeId: 0, //This is going to be filled in by the backend with the type given below.
                firstName: editorName && editorName.firstName,
                lastName: editorName && editorName.lastName,
                chapterId,
                elementId: currentSpan.id,
                languageId: editLanguageId,
                type: 'TEXT',
                text: currentSpan.innerHTML,
                authorTextSnapshot: '',
                comment: (prevEdit?.comment) || '',
              })

              addOrUpdateEdit({
                personId,
                editSegmentId: 0,
                editSegmentTypeId: 0, //This is going to be filled in by the backend with the type given below.
                firstName: editorName && editorName.firstName,
                lastName: editorName && editorName.lastName,
                chapterId,
                elementId: Number(nextSibling.id),
                languageId: editLanguageId,
                type: 'DELETESENTENCE',
                text: '',
                authorTextSnapshot: '',
                comment: '',
              })
            }
            setTimeout(() => {
              editorService.setCursorAtEndOfSpan(currentSpan.id, nextSiblingLength)
              savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))
            }, 500)
          }
        }
      }
      //If the typing is happening in a parent-like element (P, UL or OL), then get a span started and return that as the new element
      //But first try to put the cursor in the first child of the LI. But if there are not any children, then add a new span.
    } else {
      if (spanElement && spanElement.nodeName !== 'SPAN') {
        let pointerElement = spanElement
        if (spanElement.nodeName === 'UL' || spanElement.nodeName === 'OL') {
          pointerElement = spanElement.firstChild //which should be an LI
        }
        if (pointerElement && pointerElement.nodeName === 'LI') {
          let setSpan = editorService.getListItemFirstChildWithId(pointerElement)
          if (!setSpan) {
            let setSpan = document.createElement('span')
            setSpan.id = editorService.getNextId(null, null, editorDivId)
            setSpan.setAttribute('style', 'font-family: Calibri; border-radius: 3px;')
            setSpan.setAttribute('data-type', 'TEXT')
            setSpan.innerHTML = '&nbsp;'
            if (pointerElement.firstChild) {
              pointerElement.insertBefore(setSpan, pointerElement.firstChild)
            } else {
              pointerElement.append(setSpan)
            }
            setSpan.focus()
            handleSetCurrentElement(setSpan, chapterId)
            handleSetChosenSegment(setSpan)
            setCursorPosition(setSpan, setSpan, 0, 0)
            savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))
          }
          saveRevision()
        }
      }
    }
  }

  const getSelectionText = () => {
    let text = "";
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== "Control") {
      text = document.selection.createRange().text;
    }
    return text;
  }

  const handleKeyUp = (event) => {
    setFontNameSize(editorService.getFontStylesAtCursor())
    let spanElement = editorService.getLocationSegment(event.key)

    saveSelection()
    androidEnterKey.push(event.nativeEvent.code + ' - ' + event.nativeEvent.key + ' - ' + event.keyCode)
    //Help put this back? savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))
    props.setChosenMoveEdit() //Reset any moveEdit icons that could have been emphasized in the editor for display
    props.setChosenChangeStyleEdit() //Reset any changeStyleEdit icons that could have been emphasized in the editor for display

    if (event.which !== 8) backspaceNodes = []
    if (event.which !== 46) deleteKeyNodes = []

    //Backspace
    if (event.key === 'Backspace') {
      //If a backspace is used consecutively to move through a P node and then into the leftside span node, join the two adjoining spans
      //The editor should be restricted by the segment which is contentEditable only
      if (isAuthor) {
        if (editorService.combineSpansIfBackspacing(backspaceNodes, savedCursorPosition, clearBackspaceNodes, chapterId)) updateChangeCounts()
      } else {
        isTextChanged = true
      }
      saveRevision()
      updateChangeCounts()

      //Delete key
    } else if (event.key === 'Delete') {
      //If a delete is used consecutively to move through a P node and then into the rightside span node, join the two adjoining spans
      //The editor should be restricted by the segment which is contentEditable only
      if (isAuthor) {
        if (editorService.combineSpansIfDeleteKeying(deleteKeyNodes, savedCursorPosition, clearDeleteKeyNodes, chapterId)) updateChangeCounts()
      } else {
        isTextChanged = true
      }
      saveRevision()
      updateChangeCounts()

      //End and Home buttons; Arrows: left, up, right, down as well as pageDown, pageUp, End, Home
    } else if (event.key === 'ArrowLeft'
      || event.key === 'ArrowUp'
      || event.key === 'ArrowRight'
      || event.key === 'ArrowDown'
      || event.key === 'PageUp'
      || event.key === 'PageDown'
      || event.key === 'End'
      || event.key === 'Home') {

      if (event.shiftKey) return  //Keep going since this is a selection (highlight) move
      if (event.key === 'ArrowLeft' && editorService.leftElementIsEditImage()) {
        editorService.placeCursorAtFirstCharacter(spanElement, handleSetChosenSegment)
        return
      }

      savedCursorPosition = editorService.saveCursorLocation(document.getElementById(editorDivId))
      //Whenever an arrow key is used, we're going to compare the current sentence to see if it changed.
      if (!isAuthor) {
        isTextChanged = editorService.compareChangeToAddOrUpdateEdit({
          addOrUpdateEdit,
          addOrUpdateEditAddList,
          chapterId,
          currentSpan: spanElement || event.target,
          editLanguageId,
          editorName,
          edits,
          getWorkEditReviewFilled,
          handleSetChosenSegment,
          isAuthor,
          personId,
          previousSpan: editorService.getPreviousSegment(chosenSegment),
          savedCursorPosition,
          segments,
          suffixId,
          workSummary,
        })
      }

      if (event.key === 'PageUp' || event.key === 'PageDown') {
        event.stopPropagation()
        event.preventDefault()
      }

      if (isAuthor && spanElement && spanElement.nodeName === 'P') {
        let paragraph = spanElement
        let previousElementId = paragraph.id
        //Check for any textnodes that have new text to be processed
        for (let p = 0; p < paragraph.children.length; p++) {
          if (paragraph.children[p].nodeName === 'SPAN' && paragraph.children[p].innerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').length === 0) {
            let editorDiv = document.getElementById(editorDivId)
            const { newOuterHtml, lastNewId } = sentenceService.delineateSentences(previousElementId, paragraph.children[p].textContent)
            editorDiv.innerHTML = editorDiv.innerHTML.replace(paragraph.children[p].textContent, newOuterHtml);
            previousElementId = lastNewId
          } else if (paragraph.children[p].id) {
            previousElementId = paragraph.children[p].id
          }
        }
      }

      if (event.key === 'Home') {
        event.preventDefault();
        const success = editorService.placeCursorAtFirstCharacter(spanElement, handleSetChosenSegment)
        if (success) return
      }

      handleSetChosenSegment(spanElement)
      if (isTextChanged) updateChangeCounts()
      isTextChanged = false

      //CTRL + Z (Undo)
    } else if ((event.ctrlKey || event.metaKey) && (event.key === 'z' || event.key === 'Z')) {
      undo()
      updateChangeCounts()
      pageVisitor({ p: 'AuthoringEditor', n: `Control Z pressed` })

      //CTRL + SHIFT + Z  (Redo)
    } else if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === 'z' || event.key === 'Z')) {
      redo()
      updateChangeCounts()
      pageVisitor({ p: 'AuthoringEditor', n: `Control + SHIFT Z pressed` })

      //CTRL + B
    } else if ((event.ctrlKey || event.metaKey) && (event.key === 'b' || event.key === 'B')) {
      setFormatChoice({ formatType: 'bold', fontValue: '', allSelectionData })
      updateChangeCounts()
      pageVisitor({ p: 'AuthoringEditor', n: `Control B pressed` })

      //CTRL + I
    } else if ((event.ctrlKey || event.metaKey) && (event.key === 'i' || event.key === 'I')) {
      setFormatChoice({ formatType: 'italic', fontValue: '', allSelectionData })
      updateChangeCounts()
      pageVisitor({ p: 'AuthoringEditor', n: `Control I pressed` })

      //CTRL + U
    } else if ((event.ctrlKey || event.metaKey) && (event.key === 'u' || event.key === 'U')) {
      setFormatChoice({ formatType: 'underline', fontValue: '', allSelectionData })
      updateChangeCounts()
      pageVisitor({ p: 'AuthoringEditor', n: `Control U pressed` })

      //CTRL + SHIFT + K
    } else if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === 'k' || event.key === 'K')) {
      setFormatChoice({ formatType: 'strikeout', fontValue: '', allSelectionData })
      updateChangeCounts()
      pageVisitor({ p: 'AuthoringEditor', n: `Control K pressed` })

      //CTRL + S  (save)
    } else if ((event.ctrlKey || event.metaKey) && (event.key === 's' || event.key === 'S')) {
      if (isAuthor) {
        saveByButtonPress()
        return
      }

      //CTRL + C  (copy)
    } else if ((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'C')) {
      saveRevision()
      setIsCopyCommand(true)
      pageVisitor({ p: 'AuthoringEditor', n: `Control C pressed` })

      //CTRL + X  (cut and copy)
    } else if ((event.ctrlKey || event.metaKey) && (event.key === 'x' || event.key === 'X')) {
      setIsCopyCommand(false)
      saveRevision()
      pageVisitor({ p: 'AuthoringEditor', n: `Control X pressed` })
      if (!isAuthor) {
        event.stopPropagation()
        event.preventDefault() //We want to keep the elements around until we can delete them ourselves.
        editorService.setDeleteSentencesForEditor_DOM(spanElement, savedRange, chapterId, editLanguageId, personId, editorName, addOrUpdateEdit, edits, savedElementsForEdit)
        updateChangeCounts()
        return false
      }

      //TAB
    } else if (event.which === 'Tab' && !isAuthor) {
      //If this is the editor in an ADDLIST edit on the first level and hitting Shift+TAB to go backwards, do not let the editor go outside of the freestyle list. Force them to stay on that first level.
      if (event.shiftKey && editorService.isInAddListFirstLevel(spanElement)) {
        event.preventDefault()
        event.stopPropagation()
        return false
      }

    } else if (spanElement) {
      // //This could be the author or the editor since we give the author the convenience of adding list items for themselves.
      // if (spanElement && spanElement?.dataset
      //   && (spanElement.dataset?.type === 'TEXT'
      //     || spanElement.dataset?.type === 'ADDSENTENCE'
      //     || spanElement.dataset?.type === 'ADDPARAGRAPHSENTENCE'
      //     || spanElement.dataset?.type === 'ADDLISTITEM')
      //   || spanElement.dataset?.type === 'ADDLISTITEM_TEXT') {
      //   //Take out the beginning &nbsp; as many times as it might exist
      //   const hasOneCharacter = hasOnlyOneCharacter(spanElement.innerHTML)
      //   if (hasOneCharacter) {
      //     while (spanElement.innerHTML.indexOf('&nbsp;') === 0 || spanElement.innerHTML.indexOf(' ') === 0) {
      //       spanElement.innerHTML = spanElement.innerHTML.indexOf('&nbsp;') === 0 ? spanElement.innerHTML.substring(6) : spanElement.innerHTML.indexOf(' ') === 0 ? spanElement.innerHTML.substring(1) : spanElement.innerHTML
      //     }
      //     spanElement.innerHTML = hasOneCharacter
      //     moveCursorRightOneCharacter(spanElement)
      //   }
      // }


      //ADDLISTITEM or ADDSENTENCE or ADDPARAGRAPHSENTENCE
      //Clean out the prompting text &nbsp;____ and save off the edit record proactively so the user doesn't have to leave the sentence in order to pick up the comparison.
      if (!isAuthor) {
        let editType
        if (spanElement.dataset?.type?.indexOf('ADDLISTITEM') > -1) {
          editType = 'ADDLISTITEM'
        } else if (spanElement.dataset?.type?.indexOf('ADDSENTENCE') > -1) {
          editType = 'ADDSENTENCE'
        } else if (spanElement.dataset?.type?.indexOf('ADDPARAGRAPHSENTENCE') > -1) {
          editType = 'ADDPARAGRAPHSENTENCE'
        }

        if (editType) {
          clearTimeout(debounceTimer);

          debounceTimer = setTimeout(() => {
            let newText = spanElement.innerHTML
            if (newText.lastIndexOf('&nbsp;') === newText.length - 6) newText = newText.substring(0, newText.length - 6) //Take off the end &nbsp;
            while (newText.lastIndexOf('_') > -1) {
              newText = newText.substring(0, newText.length - 1)
            }
            if (newText.indexOf('&nbsp;') === 0) newText = newText.substring(6) //Take off the front &nbsp;
            spanElement.innerHTML = newText

            const prevEdit = edits?.length > 0 && edits.filter(m => Number(m.elementId) === Number(spanElement.id) && m.personId === personId && m.type === editType)[0]
            if (prevEdit) { //Just to make sure that we are picking up this existing edit ... so that it doesn't create a new edit.
              addOrUpdateEdit({
                addListItemSequence: prevEdit.addListItemSequence ? Number(prevEdit.addListItemSequence) : 0,
                authorTextSnapshot: '',
                chapterId,
                editSegmentId: prevEdit.editSegmentId || 0,
                editSegmentTypeId: prevEdit.editSegmentTypeId || 0, //This will be filled in on the server side by the type entered below
                elementId: Number(spanElement.id),
                firstName: editorName && editorName.firstName,
                isNewAddListItemSequence: prevEdit ? false : true,
                languageId: props.editLanguageId,
                lastName: editorName && editorName.lastName,
                personId,
                text: newText,
                type: editType,
              })
            }
          }, 1000)
        }

      } else if (spanElement?.dataset?.type === 'ADDPARAGRAPHSENTENCE' && spanElement.innerHTML.startsWith('\u00A0')) { //Just take the first space off of the front, which happens with the &nbsp; that is used to start the sentence part of the ADDPARAGRAPHSENTENCE edit
        spanElement.innerHTML = spanElement.innerHTML.replace(/^\u00A0/, '');
        pageVisitor({ p: 'AuthoringEditor', n: `ADDPARAGRAPHSENTENCE combination was chosen by Enter key` })
      } else if (spanElement?.dataset?.type === 'ADDPARAGRAPHSENTENCE' && spanElement.innerHTML.startsWith('&nbsp;')) {
        spanElement.innerHTML = spanElement.innerHTML.replace(/^&nbsp;/, '');
      } else {
        //Check to make sure that the chosenSegment has a valid entry in it
        //It is possible that the user clicked into a paragraph or even editorDiv, but it moves the cursor inside a span.
        let latestSegment = chosenSegment[chosenSegment.length - 1]
        //let element = document.querySelectorAll(`[id="${chosenSegment.id}"][data-type="TEXT"]`)[0]
        if (latestSegment && Number(latestSegment.id) === Number(spanElement.id) && !latestSegment.type && spanElement.nodeName === 'SPAN' && spanElement.dataset.type === 'TEXT') {
          let item = {
            id: spanElement.id,
            type: spanElement.dataset.type,
            addListItemSequence: spanElement.dataset.addListItemSequence ? spanElement.dataset.addListItemSequence : ''
          }
          handleSetChosenSegment(chosenSegment.concat(item))
          saveRevision()
        }
      }
    }

    if (skipRestoreCursorOnce) { //This is used for the tabbing to the next sentence and setting the cursor in place when it is the editor
      skipRestoreCursorOnce = false
      //} else if (spanElement && spanElement.id !== editorDivId) {
      //editorService.restoreCursorLocation(document.getElementById(editorDivId), savedCursorPosition)
    }
    androidEnterKey = []
    updateChangeCounts()
  }

  const saveRevision = () => {
    let newRevisions = [...revisions]
    const editorDiv = document.getElementById(editorDivId)
    if (editorDiv) {
      newRevisions.push(editorDiv.innerHTML)
      setRevisions(newRevisions)
    }
  }

  const undo = () => {
    pageVisitor({ p: 'AuthoringEditor', n: `User chosen to undo changes` })
    let newRevisions = [...revisions];
    const editorDiv = document.getElementById(editorDivId)
    editorDiv.focus()

    if (revisions.length > 0) {
      const lastRevision = newRevisions.pop()  // Pop and save the last revision
      setRedoRevisions(redoRevisions => [...redoRevisions, lastRevision])  // Push it into redoRevisions
      editorDiv.innerHTML = lastRevision  // Use lastRevision to update the editor's content
    } else {
      editorDiv.innerHTML = ''
    }

    if (editorDiv.innerHTML === '') {
      isAuthor
        ? editorService.setSegments({
          addChapterListLevels,
          chapterId,
          chapterListLevels,
          chosenTab,
          divDisplayId: editorDivId,
          editorName,
          edits,
          isAuthor,
          isTranslation,
          listLevelGeneral,
          personId,
          savedRange,
          segments: [...segments],
          showEditIcons,
          tabsData,
          workSummary,
        })
        : editorService.setSegmentsWithEdits({
          addChapterListLevels,
          chapterId,
          chapterListLevels,
          chosenTab,
          divDisplayId: editorDivId,
          editorName,
          edits,
          listLevelGeneral,
          personId,
          savedRange,
          segments: [...segments],
          showEditIcons,
          tabsData,
          workSummary,
        })
    }

    setRevisions(newRevisions)
    updateChangeCounts()
  }

  const redo = () => {
    pageVisitor({ p: 'AuthoringEditor', n: `Chose to redo the undo of changes` })
    let newRedoRevisions = [...redoRevisions]
    const editorDiv = document.getElementById(editorDivId)
    editorDiv.focus()

    if (redoRevisions.length > 0) {
      const redoRevision = newRedoRevisions.pop()  // Pop the last redo revision
      setRevisions(revisions => [...revisions, redoRevision])  // Push it back into revisions
      editorDiv.innerHTML = redoRevision  // Reapply the redo revision to the editor
    }

    setRedoRevisions(newRedoRevisions)
    updateChangeCounts()
  }

  const moveCursor = (spaces = 1, savedCursorPosition) => {
    return {
      start: savedCursorPosition.start + 1,
      end: savedCursorPosition.end + 1,
      newElement: savedCursorPosition.newElement
    }
  }

  const processPotentialChanges = (spanArray) => {
    if (!isAuthor) {
      spanArray?.length > 0 && spanArray.forEach(span => {
        const renewSpan = document.querySelector(`span[id="${span?.id}"][data-type="TEXT"]`)
        if (renewSpan) {
          editorService.compareChangeToAddOrUpdateEdit({
            addOrUpdateEdit,
            addOrUpdateEditAddList,
            chapterId,
            currentSpan: renewSpan,
            editLanguageId,
            editorName,
            edits,
            getWorkEditReviewFilled,
            handleSetChosenSegment,
            isAuthor,
            personId,
            previousSpan: renewSpan,
            savedCursorPosition,
            segments,
            suffixId,
            workSummary,
          })
        }
      })
    }
  }

  const setFormatChoice = ({ formatType, fontValue, allSelectionData }) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Chose a format change: ${formatType} - ${fontValue}` })
    saveRevision()
    restoreSelection()

    let formatStyles = []
    if (formatType === 'bold') formatStyles = [{ code: 'fontWeight', value: 'bold', tags: ['b', 'strong'] }]
    if (formatType === 'italic') formatStyles = [{ code: 'fontStyle', value: 'italic', tags: ['i'] }]
    if (formatType === 'underline') formatStyles = [{ code: 'textDecoration', value: 'underline', tags: ['u'] }]
    if (formatType === 'strikeout') formatStyles = [{ code: 'textDecoration', value: 'line-through', tags: ['s', 'del', 'strke'] }]
    if (formatType === 'subscript') formatStyles = [{ code: 'verticalAlign', value: 'super', tags: ['sub'] }, { code: 'fontSize', value: 'smaller', tags: ['sub'] }]
    if (formatType === 'superscript') formatStyles = [{ code: 'verticalAlign', value: 'sub', tags: ['sup'] }, { code: 'fontSize', value: 'smaller', tags: ['sup'] }]
    if (formatType === 'fontName') formatStyles = [{ code: 'fontFamily', value: fontValue, tags: [] }]
    if (formatType === 'fontSize') formatStyles = [{ code: 'fontSize', value: fontValue, tags: [] }]
    if (formatType === 'fontColor') formatStyles = [{ code: 'color', value: fontValue, tags: [] }]

    editorService.setFormatStyleHtml({
      addOrUpdateEdit,
      allSelectionData,
      chapterId,
      editLanguageId,
      edits,
      formatStyles,
      getWorkEditReviewFilled,
      isAuthor,
      personId,
      savedCursorPosition,
    })

    if (!isAuthor) {
      setTimeout(() => {
        processPotentialChanges(allSelectionData?.savedSpansForStyleChange?.length > 0 ? allSelectionData.savedSpansForStyleChange : [currentElement]);
        editorService.restoreCursorLocation(document.getElementById(editorDivId), moveCursor(1, savedCursorPosition));
      }, 1000)
    }
    updateChangeCounts()
    setTimeout(() => clearSelection(), 1000)
  }

  const setParagraphAlign = (formatValue) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Set paragraph align: ${formatValue}` })
    let success
    if (!savedParagraphsForTextAlign || savedParagraphsForTextAlign.length === 0 || !savedParagraphsForTextAlign[0]) {
      success = false
    } else {
      saveRevision()
      success = editorService.setParagraphTextAlign(formatValue, savedParagraphsForTextAlign, currentElement)
      editorService.restoreCursorLocation(document.getElementById(editorDivId), savedCursorPosition)
      updateChangeCounts()
      if (!isAuthor) {
        if (!(savedParagraphsForTextAlign && savedParagraphsForTextAlign.length > 0)) savedParagraphsForTextAlign = [currentElement.parentElement]
        const editInSeries = Math.floor(100000 + Math.random() * 900000)
        savedParagraphsForTextAlign.forEach(element => {
          if (!element) return false
          addOrUpdateEdit({
            editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
            personId,
            firstName: editorName && editorName.firstName,
            lastName: editorName && editorName.lastName,
            chapterId,
            languageId: editLanguageId,
            elementId: Number(element.id),
            type: 'TEXTALIGN',
            styleEdit: formatValue,
            editInSeries,
          })
        })
      }
    }
    if (!success) createInfoToastAuto(`<div>Please select a paragraph by either <br/>clicking on a sentence or highlighting a <br/>part of one or more paragraphs.</div>`, 4000)
  }

  const setParagraphIndentChoice = (indentType, value) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Chose to set paragraph indent: ${indentType} - ${value}` })
    let success
    if (!savedParagraphsForTextAlign || savedParagraphsForTextAlign.length === 0 || !savedParagraphsForTextAlign[0]) {
      success = false
    } else {
      saveRevision()
      success = editorService.setParagraphIndent(indentType, value, savedParagraphsForTextAlign, currentElement)
      editorService.restoreCursorLocation(document.getElementById(editorDivId), savedCursorPosition)
      const getEditType = () => {
        if (indentType === 'textIndent') {
          return 'TEXTINDENT'
        } else if (indentType === 'textAlign') {
          return 'TEXTALIGN'
        } else if (indentType === 'marginLeft') {
          return 'BLOCKLEFT'
        } else if (indentType === 'marginRight') {
          return 'BLOCKRIGHT'
        }
      }

      updateChangeCounts()
      if (!isAuthor) {
        if (!(savedParagraphsForTextAlign && savedParagraphsForTextAlign.length > 0)) savedParagraphsForTextAlign = [currentElement.parentElement]
        const editInSeries = Math.floor(100000 + Math.random() * 900000)
        savedParagraphsForTextAlign.forEach(element => {
          if (element && element.nodeName === 'P') {
            addOrUpdateEdit({
              editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
              personId,
              firstName: editorName && editorName.firstName,
              lastName: editorName && editorName.lastName,
              chapterId,
              languageId: editLanguageId,
              elementId: Number(element.id),
              type: getEditType(),
              styleEdit: value,
              editInSeries,
              //authorTextSnapshot: editSegment.authorTextSnapshot,
            })
          }
        })
      }
    }
    if (!success) createInfoToastAuto(`<div>Please select a paragraph by either <br/>clicking on a sentence or highlighting a <br/>part of one or more paragraphs.</div>`, 4000)
  }

  const setListChoice = (listType) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Chose a list type` })
    let elementData = chosenSegment[chosenSegment.length - 1]
    let currentElement = document.getElementById(elementData.id)
    editorService.setListHtml({
      chapterId,
      currentElement,
      handleSetChosenSegment,
      listLevels: props.listLevelGeneral,
      listType,
      savedCursorPosition,
      saveRevision,
    })
    setCursorPosition()
    editorService.restoreCursorLocation(document.getElementById(editorDivId), moveCursor(1, savedCursorPosition))
    updateChangeCounts()
    saveRevision()
  }

  const preventDragDrop = event => {
    event.preventDefault()
    return false
  }

  const saveWebsiteLink = (websiteLink, linkDisplayText) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Saved a website link` })
    editorService.restoreCursorLocation(document.getElementById(editorDivId), savedCursorPosition)
    editorService.addWebsiteEntryElement(websiteLink, linkDisplayText)
    setIsOpenLinkEntry(false)
    updateChangeCounts()
  }

  const handleDownloadChoice = (choice) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Chose to download the file: ${choice}` })
    let htmlString = document.getElementById(editorDivId).innerHTML
    //Take out the ending paragraphs and spans which are empty for the purpose of convenience for the editor to move around and put in a sentence in the middle of no-man's land lower in the document. But unnecessary and in the way when downloading a document.
    htmlString = editorService.removeExtraSpaceLines(htmlString)
    htmlString = editorService.removePenspringEditImages(htmlString)

    let wordConversion = {
      htmlString,
      personId,
      workId: workSummary.workId,
      languageId: workSummary.languageId || 1,
      conversionType: choice
    }
    let getWorkChoice = props.downloadOptions.filter(m => m.id === choice)[0]
    const runFunction = () => {
      localStorage.setItem('workDownloadReady', JSON.stringify(getWorkChoice))
      setShowDownloadReady(true)
    }
    props.downloadWork(wordConversion, runFunction)
  }

  const closeModalDownloadDocx = () => {
    //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
    //workDownloadReady is the conversion type which is also the file extension.
    setShowDownloadReady(false)
    setTimeout(() => {
      props.deleteFile(personId, workSummary.workId, workDownloadReady.id, workSummary.languageId || 1)
      localStorage.setItem('workDownloadReady', null)
      workDownloadReady = null
    }, 5000) //This will trigger the WORK_DOWNLOAD_READY by setting it to blank which will close this modal dialog.
  }

  const handleFontNameChoice = (fontValue) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Chose a font name: ${fontValue}` })
    restoreSelection()
    setFormatChoice({ formatType: 'fontName', fontValue, allSelectionData })
    updateChangeCounts()
    setTimeout(() => clearSelection(), 1000)
  }

  const handleHtmlCharacterInsert = (character) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Inserted an HTML character: ${character}` })
    restoreSelection()
    editorService.insertHtmlCharacter(character)
    updateChangeCounts()
    setTimeout(() => clearSelection(), 1000)
  }

  const handleFontSizeChoice = (fontValue) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Changed the font size: ${fontValue}` })
    restoreSelection()
    setFormatChoice({ formatType: 'fontSize', fontValue, allSelectionData })
    updateChangeCounts()
    setTimeout(() => clearSelection(), 1000)
  }

  const handleFontColorChoice = (event, colorValue) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Changed the font color: ${colorValue}` })
    restoreSelection()
    event.stopPropagation()
    event.preventDefault()
    setFormatChoice({ formatType: 'fontColor', fontValue: colorValue, allSelectionData })
    updateChangeCounts()
    setTimeout(() => clearSelection(), 1000)
  }

  const clearDeleteKeyNodes = () => {
    deleteKeyNodes = []
  }

  const clearBackspaceNodes = () => {
    backspaceNodes = []
  }

  const handleSetEditChosen = (elementId, label) => {
    pageVisitor({ p: 'AuthoringEditor', n: `Chose an edit from the edit list` })
    unHighlightChosenSegments()
    let element
    if (label.indexOf('Sentence edit') > -1) {
      element = document.querySelector(`span[id="${elementId}"][data-type="TEXT"]`)
      if (element) {
        element.style.backgroundColor = backgroundColors.currentFocus
        handleSetCurrentElement(element, chapterId)
        handleSetChosenSegment(element)
        scrollDocumentToMatch(element, element.id)
        //Include the tabView
        let tabViewElement = document.querySelector(`span[id="${elementId}~tabView${suffixId}"][data-type="TEXT"]`)
        if (tabViewElement) tabViewElement.style.backgroundColor = backgroundColors.currentFocus
      }
    } else if (label.indexOf('Move sentences') > -1) {
      element = document.querySelectorAll(`img[data-span-id="${elementId}"][data-type="MOVE"]`)
      for (let i = 0; i < element.length; i++) {
        if (i === 0) {
          handleSetCurrentElement(element[i], chapterId)
          handleSetChosenSegment(element[i])
          scrollDocumentToMatch(element[i], element[i].dataset.spanId)
        }
        element[i].style.backgroundColor = backgroundColors.currentFocus
      }
      //Include the tabView
      element = document.querySelectorAll(`img[data-span-id="${elementId}~tabView${suffixId}"][data-type="MOVE"]`)
      for (let i = 0; i < element.length; i++) {
        element[i].style.backgroundColor = backgroundColors.currentFocus
      }
    } else if (label.indexOf('Change style') > -1) {
      element = document.querySelectorAll(`img[data-span-id="${elementId}"][data-type="CHANGESTYLE"]`)
      for (let i = 0; i < element.length; i++) {
        if (i === 0) {
          handleSetCurrentElement(element[i], chapterId)
          handleSetChosenSegment(element[i])
          scrollDocumentToMatch(element[i], element[i].dataset.spanId)
        }
        element[i].style.backgroundColor = backgroundColors.currentFocus
      }
      //Include the tabView
      element = document.querySelectorAll(`img[data-span-id="${elementId}~tabView${suffixId}"][data-type="CHANGESTYLE"]`)
      for (let i = 0; i < element.length; i++) {
        element[i].style.backgroundColor = backgroundColors.currentFocus
      }
    } else {
      let dataType
      if (label.indexOf('Add paragraph break') > -1) {
        dataType = 'ADDPARAGRAPH'
      } else if (label.indexOf('Delete paragraph break') > -1) {
        dataType = 'DELETEPARAGRAPH'
      } else if (label.indexOf('Add sentence') > -1) {
        dataType = 'ADDSENTENCE'
      } else if (label.indexOf('Delete sentence') > -1) {
        dataType = 'DELETESENTENCE'
      } else if (label.indexOf('Add list item') > -1) {
        dataType = 'ADDLISTITEM'
      } else if (label.indexOf('Add list') > -1) {
        dataType = 'ADDLIST'
      } else if (label.indexOf('Delete list item') > -1) {
        dataType = 'DELETELISTITEM'
      } else if (label.indexOf('Re-order list items') > -1) {
        dataType = 'REORDERLISTITEMS'
      } else if (label.indexOf('Add tab') > -1) {
        dataType = 'ADDTAB'
      } else if (label.indexOf('Delete tab') > -1) {
        dataType = 'DELETETAB'
      } else if (label.indexOf('Move list item left') > -1) {
        dataType = 'LISTLEVELMINUS'
      } else if (label.indexOf('Move list item right') > -1) {
        dataType = 'LISTLEVELPLUS'
      }

      element = document.querySelector(`img[id="${elementId}"][data-type="${dataType}"]`)
      if (!element) element = document.querySelector(`img[data-span-id="${elementId}"][data-type="${dataType}"]`)
      if (!element) element = document.querySelector(`img[data-span-id="${elementId}"]`)
      if (!element) element = document.querySelector(`img[id="${elementId}"]`)
      if (element) {
        element.style.backgroundColor = backgroundColors.currentFocus
        //Include the tabView
        let tabViewElement = document.querySelector(`img[id="${elementId}~tabView${suffixId}"][data-type="${dataType}"]`)
        if (!tabViewElement) tabViewElement = document.querySelector(`img[data-span-id="${elementId}~tabView${suffixId}"][data-type="${dataType}"]`)
        if (!tabViewElement) tabViewElement = document.querySelector(`img[data-span-id="${elementId}~tabView${suffixId}"]`)
        if (!tabViewElement) tabViewElement = document.querySelector(`img[id="${elementId}~tabView${suffixId}"]`)
        if (tabViewElement) tabViewElement.style.backgroundColor = backgroundColors.currentFocus
        handleSetCurrentElement(element, chapterId)
        handleSetChosenSegment(element)
        if (tabViewElement) tabViewElement.scrollIntoView({ behavior: "smooth", block: "center" });
        if (element) setTimeout(() => element.scrollIntoView({ behavior: "smooth", block: "center" }), 500);
        //scrollDocumentToMatch(element, element.id)
      }
    }
    setEditChosen(elementId)
  }

  const scrollDocumentToMatch = (paramElement, paramElementId) => {
    //I don't think that (paramElement, paramElementId) are actually sent in. That's okay. We can get the currentElementId and the editors on our own.
    pageVisitor({ p: 'AuthoringEditor', n: `Chose to scroll documents together` })
    //let elementId = paramElementId ? paramElementId : paramElement ? paramElement.id : chosenSegment && chosenSegment.length > 0 ? chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id : ''
    if (!currentElement || !currentElement.id) {
      setEntryError('Please choose a sentence before scrolling view locations.')
    } else {
      const editorElement = document.querySelector(`[id="${currentElement.id}"]`)  //[data-type="TEXT"]
      const tabViewElement = document.querySelector(`[id="${currentElement.id + `~tabView${suffixId}`}"]`) //[data-type="TEXT"]
      if (tabViewElement) tabViewElement.scrollIntoView({ behavior: "smooth", block: "center" });
      if (editorElement) setTimeout(() => editorElement.scrollIntoView({ behavior: "smooth", block: "center" }), 500);
    }
  }

  const clearSelection = () => {
    //I cleared these out for now since it seems that clearing these actually reverses the format style, from what I can tell. I'm not to the bottom of this one.
    setAllSelectionData()
    setSavedElementsForEdit()
    setSavedElementTextAnchorNode()
    setSavedRange()
    setSavedSpansForStyleChange()
    setSaveSelectedSelection()
    setSaveSelectionInnerHtml()
  }

  const saveSelection = () => {
    //If the selection is inside of a single span, the element list will be empty here ... unless we force in the anchorNode as the single span affected which 
    //  can then be picked up for use in convertTextToList and other things. But if that goes south on us, we will have to adjust this paradigm that we are choosing.
    let textLeftOfCursor
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      let selectionInnerHtml
      const range = selection.getRangeAt(0);
      const container = document.createElement('div');
      container.appendChild(range.cloneContents());
      //To get the text left of the cursor where the cursor is located without any selection.
      const anchorNode = selection.anchorNode;

      // Ensure the anchor node is a text node and within a span
      if (anchorNode && anchorNode.nodeType === Node.TEXT_NODE) {
        const parentSpan = anchorNode.parentElement;
        if (parentSpan && parentSpan.nodeName === 'SPAN') {
          const spanText = parentSpan.textContent;
          const cursorOffset = selection.anchorOffset;
          textLeftOfCursor = spanText.substring(0, cursorOffset);
        }
      }

      setSavedRange(range)
      setSaveSelectedSelection(selection)
      setSavedElementTextAnchorNode(selection?.anchorNode?.parentElement)
      setSaveSelectionInnerHtml(container.innerHTML)
      selectionInnerHtml = container.innerHTML

      //sendTestMessageSelection(container.innerHTML || 'EMPTY')
      const elements = [];
      const getAllElements = (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) { //Be aware that there are going to be some non-penspring spans since penspring spans can have style spans inside of them.
          elements.push(node);
        }
        node.childNodes.forEach(getAllElements);
      };

      getAllElements(container);
      //Preserve this selection so that it can be used before pasting. We'll manually blank it out when we are done. In other words, we don't want this to go blank prematurely when clicking into a sentence when the savedRange gets lost in this function
      //There is a consideration we need to take when it comes to a selection of text within a single span. There will be a textnode only without an element recorded here.
      //  So, we need to go out of our way to record that textNode in the element list here.
      //  If there is only a DIV but there is a selection made, then that div will have a textNode in its childNodes with some text. We can get the Anchornode above.
      //  So, we will record that textNode here as the only element and see how we can work with that in the code for creating a new list and other actions.
      let theDiv
      let newElements
      if (elements?.length > 0) {
        //Take off both the first DIV and (the one in second place) the first P, if it exists.
        //BUT! First, let's see if there is only one element involved and it is a DIV. That would imply that there might just be a textnode. If there is text in it, we will record it, otherwise we will ignore this and consider it empty. 
        if (elements.length === 1 && elements[0].nodeName === 'DIV') {
          theDiv = elements[0]
          if (theDiv.childNodes?.length === 1 && theDiv.childNodes[0].textContent?.length > 0) {
            setSavedElementsForEdit([theDiv.childNodes[0]]) //This needs to be an array of one.
          }

        } else {
          newElements = [...elements]
          if (newElements[0].nodeName === 'DIV') newElements.shift()
          if (newElements?.length > 0 && newElements[0].nodeName === 'P') newElements.shift()
          //Also take off the first paragraph tag if the first span in the list has siblings to the left so that it is not the entire span that is possibly going to be deleted..
          setSavedElementsForEdit(newElements)
        }

        const spans = elements.filter(m => m.nodeName === 'SPAN' && m.id && !isNaN(m.id)) //Only take valid penspring spans.
        let uniqueSpans = [...new Set(spans)];
        if (uniqueSpans.length === 0 && selection?.anchorNode?.parentElement) uniqueSpans = [selection?.anchorNode?.parentElement]  //In case this is a partial sentence selection which would just be a textNode. We'll pick up the span that it belongs to.
        setSavedSpansForStyleChange(uniqueSpans)

        const paragraphs = elements.filter(m => m.nodeName === 'P')
        let uniqueParagraphs = [...new Set(paragraphs)]
        savedParagraphsForTextAlign = uniqueParagraphs
        if (!savedParagraphsForTextAlign || savedParagraphsForTextAlign?.length === 0) {
          let tempParagraphs = []
          uniqueSpans.forEach(span => {
            if (span.nodeName === 'SPAN' && span.id && span.parentElement.nodeName === 'P' && span.parentElement.id) {
              tempParagraphs.push(span.parentElement)
            }
          })
          uniqueParagraphs = [...new Set(tempParagraphs)]
          savedParagraphsForTextAlign = uniqueParagraphs
        }

        setAllSelectionData({
          textLeftOfCursor,
          savedSpansForStyleChange: uniqueSpans,
          savedElementsForEdit: newElements,
          saveSelectedSelection: selection,
          savedElementTextAnchorNode: selection?.anchorNode?.parentElement,
          saveSelectionInnerHtml: selectionInnerHtml,
        })
      }
    }
  }

  const restoreSelection = () => {
    const selection = window.getSelection()

    if (savedRange) {
      try {
        // Remove any existing selection ranges
        selection.removeAllRanges()
        // Add the saved range
        selection.addRange(savedRange)
      } catch (error) {
        // Fallback for older browsers
        if (document.body.createTextRange) {
          const textRange = document.body.createTextRange()
          textRange.select()
          document.selection.empty()
        }
      }
    }
  }

  useEffect(() => {
    getEditorDivStyle()
  }, [workSummary, currentEditorDiv, editorDivId])

  const getEditorDivStyle = () => {
    if (workSummary.workTypeCode === 'DISCUSSION' || workSummary.workTypeCode === 'TESTQUIZ') {
      if (currentEditorDiv === editorDivId) {
        return isMobile ? styles.discussionFocusedMobile : styles.discussionFocused
      } else {
        return isMobile ? styles.discussionUnfocusedMobile : styles.discussionUnfocused
      }
    } else if (isMobile) {
      return styles.editorDivLeftMobile
    } else {
      return styles.editorDivLeft
    }
  }

  const checkEditChangeCount = () => {
    const returnToPage = localStorage.getItem('preEditReviewPage')
    let sendBackToPage = '-1'
    if (returnToPage) sendBackToPage = returnToPage

    if (changeCounts) {
      createConfirmToastAuto('<div><div>It looks like the latest changes to the text are not saved.</div><div>Do you want to leave this page without saving?</div></div>', () => navigate(sendBackToPage))
    } else {
      navigate(sendBackToPage)
    }
  }

  const onBlurScore = (score, studentPersonId, assessmentQuestionId) => {
    props.updateTeacherAssessmentLearnerAnswer(studentPersonId, assessmentQuestionId, score)
  }

  const setAssessmentScoreWork = (score) => { //Notice that these parameters are for the other components for the assessment, although we won't be using them in this case. We just need the WorkId.
    pageVisitor({ p: 'AuthoringEditor', n: `Entered an assessment score: ${score}` })
    props.setLocalScoreWork(workSummary.workId, score)
  }

  return (
    <div className={classes(isMobile ? styles.containerMobile : styles.container, workSummary.workTypeCode === 'DISCUSSION' || workSummary.workTypeCode === 'TESTQUIZ' ? styles.discussion : '')} key={`main${keyIndex}`}>
      {workSummaries?.length === 1 && workSummaries[0]?.workTypeCode === 'TESTQUIZ' &&
        <div className={styles.topSpace}>
          <div className={styles.row}>
            <div className={styles.assessmentHeader}>Assessment</div>
          </div>
          <TextDisplay label={'Title'}
            text={<div onClick={checkEditChangeCount} className={styles.rowLinkPlain}>
                    {props.assessmentQuestion?.assessmentName}
                    <div className={styles.link}>&lt; Go back</div>
                  </div>} />
          <TextDisplay label={'Editor'} text={workSummaries[0].authorName} noDisplayIfBlank />
          <TextDisplay label={'Description'} text={props.assessmentQuestion?.questionText} noDisplayIfBlank className={styles.widthLimit}/>
        </div>
      }
      {(isMobile || workSummary.workTypeCode === 'DISCUSSION' || workSummary.workTypeCode === 'TESTQUIZ') &&
        <div>
          <EditorFontNameSizeBold fontNameSize={fontNameSize} />
          <div key={`mobileFormat${keyIndex}`}
            className={classes(workSummary.workTypeCode === 'DISCUSSION' || workSummary.workTypeCode === 'TESTQUIZ' 
              ? currentEditorDiv === editorDivId 
                ? styles.rowMobileToolsMultiple 
                : styles.hide 
              : styles.rowMobileTools, styles.row)}>
            <CurrentSentenceEditsPanelLauncher setIsOpenSlideOut={props.setIsOpenSlideOut} sentenceEditCount={sentenceEditCount} />
            {workSummary.workTypeCode !== 'DISCUSSION' && workSummary.workTypeCode !== 'TESTQUIZ' &&
              <div className={styles.downloadButton}>
                <DownloadButton
                  downloadOptions={props.downloadOptions}
                  handleDownloadChoice={handleDownloadChoice}
                  personId={personId} 
                  setOpenOriginatingAuthorSend={props.setOpenOriginatingAuthorSend}
                  workSummary={workSummary} />
              </div>
            }
            <div style={{ width: '50px' }}>
              <EditorDivFormatControlsMobile
                allSelectionData={allSelectionData}
                changeCounts={changeCounts}
                chapterId={chapterId}
                clearSelection={clearSelection}
                editChosen={editChosen}
                editOptions={props.editOptions}
                eraseFormatting={() => {
                  saveRevision();
                  restoreSelection();
                  updateChangeCounts();
                  editorService.eraseFormatting({ allSelectionData, edits, personId, chapterId, editLanguageId, isAuthor, addOrUpdateEdit, getWorkEditReviewFilled });
                }}
                fontNameSize={fontNameSize}
                handleDownloadChoice={handleDownloadChoice}
                handleFontColorChoice={handleFontColorChoice}
                handleFontNameChoice={handleFontNameChoice}
                handleFontSizeChoice={handleFontSizeChoice}
                handleHtmlCharacterInsert={handleHtmlCharacterInsert}
                handleSetEditChosen={handleSetEditChosen}
                isAuthor={isAuthor}
                isOpenLinkEntry={isOpenLinkEntry}
                keyIndex={keyIndex}
                openListModal={openListModal}
                redo={redo}
                saveByButtonPress={saveByButtonPress}
                savedElementsForEdit={savedElementsForEdit}
                saveSelection={saveSelection}
                saveWorkSpaceTime={saveWorkSpaceTime}
                scrollDocumentToMatch={scrollDocumentToMatch}
                setAddList={props.setAddList}
                setChangeStyle={props.setChangeStyle}
                setFormatChoice={setFormatChoice}
                setIsOpenLinkEntry={setIsOpenLinkEntry}
                setListChoice={setListChoice}
                setOpenListModal={setOpenListModal}
                setOpenTextStyleModal={setOpenTextStyleModal}
                setParagraphAlign={setParagraphAlign}
                setParagraphIndentChoice={setParagraphIndentChoice}
                tabViewElement={document.getElementById(`tabView${suffixId}`)}
                undo={undo}
                {...props} />
            </div>
            <div style={{ width: '73px' }}>
              <ToggleBoardMobile
                addList={props.addList} setAddList={props.setAddList}
                addListItem={props.addListItem} setAddListItem={props.setAddListItem}
                addParagraphBreak={props.addParagraphBreak} setAddParagraphBreak={props.setAddParagraphBreak}
                addParagraphSentence={props.addParagraphSentence} setAddParagraphSentence={props.setAddParagraphSentence}
                addSentence={props.addSentence} setAddSentence={props.setAddSentence}
                changeList={changeList} setChangeList={setChangeList}
                changeStyle={props.changeStyle} setChangeStyle={props.setChangeStyle}
                chapterId={chapterId}
                clearAllEditTypesAndIcons={props.clearAllEditTypesAndIcons}
                convertAddListSequence={convertAddListSequence}
                currentElement={currentElement}
                deleteListItem={props.deleteListItem} setDeleteListItem={props.setDeleteListItem}
                deleteParagraphBreak={props.deleteParagraphBreak} setDeleteParagraphBreak={props.setDeleteParagraphBreak}
                deleteSentence={props.deleteSentence} setDeleteSentence={props.setDeleteSentence}
                editTrackChanges={props.editTrackChanges} setEditTrackChanges={props.setEditTrackChanges}
                goToNextSentence={props.goToNextSentence} setGoToNextSentence={props.setGoToNextSentence}
                hasListStructure={hasListStructure}
                hasTableStructure={hasTableStructure}
                isTranslation={isTranslation}
                keepCommentOn={props.keepCommentOn} setKeepCommentOn={props.setKeepCommentOn}
                keyIndex={keyIndex}
                moveSentences={props.moveSentences} setMoveSentences={props.setMoveSentences}
                moveSequence={moveSequence} changeStyleSequence={changeStyleSequence}
                moveTranslationToEditor={props.moveTranslationToEditor} setMoveTranslationToEditor={props.setMoveTranslationToEditor}
                personConfig={props.personConfig}
                personId={personId}
                reorderListItems={props.reorderListItems} setReorderListItems={props.setReorderListItems}
                scrollTogether={scrollTogether} setScrollTogether={setScrollTogether}
                segments={segments}
                showEditIcons={showEditIcons} setShowEditIcons={setShowEditIcons}
                showEditorFullText={props.showEditorFullText} setShowEditorFullText={props.setShowEditorFullText}
                addTable={addTable} setAddTable={setAddTable}
                addTableRow={addTableRow} setAddTableRow={setAddTableRow}
                addTableColumn={addTableColumn} setAddTableColumn={setAddTableColumn}
                addTableCell={addTableCell} setAddTableCell={setAddTableCell}
                deleteTable={deleteTable} setDeleteTable={setDeleteTable}
                deleteTableRow={deleteTableRow} setDeleteTableRow={setDeleteTableRow}
                deleteTableColumn={deleteTableColumn} setDeleteTableColumn={setDeleteTableColumn}
                deleteTableCell={deleteTableCell} setDeleteTableCell={setDeleteTableCell}
                tableSettings={tableSettings} setTableSettings={setTableSettings}
                addImage={addImage} setAddImage={setAddImage}
                deleteImage={deleteImage} setDeleteImage={setDeleteImage}
                imageSettings={imageSettings} setImageSettings={setImageSettings}
                {...props} />
            </div>
            <div className={styles.editListChoice} style={{ width: isMobile || workSummaries?.length > 1 ? '48px' : '83px' }}>
              <EditListChoice
                keyIndex={keyIndex}
                editOptions={props.editOptions}
                handleSetEditChosen={handleSetEditChosen}
                editChosen={editChosen}
                scrollDocumentToMatch={scrollDocumentToMatch} />
            </div>
            {/* There is a separate copy of the TabPage for other device sizes since it is placed surgically in the editor div area */}
            <div className={classes(styles.tabPage, styles.row)} 
                style={{ width: isTablet || isSmallTablet || isMobile || workSummaries?.length > 1 ? '83px' : '', visibility: isTablet || isSmallTablet || isMobile ? '' : 'hidden' }}>
              <TabPage
                chosenTab={chosenTab}
                editChosen={editChosen}
                editOptions={props.editOptions}
                editorName={editorName}
                handleSetEditChosen={handleSetEditChosen}
                keyIndex={keyIndex}
                navClose={tabNav}
                navText={navText}
                onClick={(chosenTab) => { handleSetGlobalChosenTab(chosenTab); setChosenTab(chosenTab); scrollDocumentToMatch() }}
                showListAfterQuantity={8}
                showZeroCount={true}
                tabsData={tabsData}
                userPersonId={personId}
                workSummaries={workSummaries} />
            </div>
            {(isMobile || workSummary.workTypeCode === 'DISCUSSION' || workSummary.workTypeCode === 'TESTQUIZ') && workSummary.authorPersonId === personId &&
              <EditorSaveButton
                addClassName={styles.editorSaveButton} 
                changeCounts={changeCounts}
                keyIndex={keyIndex}
                label={'Save'}
                saveByButtonPress={saveByButtonPress}
                saveWorkSpaceTime={saveWorkSpaceTime} />
            }
          </div>
        </div>
      }
      <div className={workSummary.workTypeCode === 'DISCUSSION' || workSummary.workTypeCode === 'TESTQUIZ'
        ? isMobile
          ? styles.rowEditReviewDiscussionMobile
          : styles.rowEditReviewDiscussion
        : styles.rowEditReview} key={`format${keyIndex}`}>
        <div className={isMobile ? '' : workSummary.workTypeCode === 'DISCUSSION' || workSummary.workTypeCode === 'TESTQUIZ' ? styles.showEditorDivDiscussion : styles.showEditorDiv}
          style={{ marginLeft: workSummary.discussionLevel ? workSummary.discussionLevel * (isMobile ? '1' : '2') + 'em' : '.5em', width: '100%' }}>
          {!isMobile && workSummary.workTypeCode !== 'DISCUSSION' && workSummary.workTypeCode !== 'TESTQUIZ' &&
            <EditorDivFormatControls
              editorName={editorName}
              chosenTab={chosenTab}
              tabNav={tabNav}
              navText={navText}
              handleSetGlobalChosenTab={handleSetGlobalChosenTab}
              setChosenTab={setChosenTab}
              tabsData={tabsData}
              workSummaries={workSummaries}

              allSelectionData={allSelectionData}
              changeCounts={changeCounts}
              changeStyleMode={props.changeStyle}
              currentEditorDiv={currentEditorDiv}
              editChosen={editChosen}
              editOptions={props.editOptions}
              editorDivId={editorDivId}
              eraseFormatting={() => {
                saveRevision();
                restoreSelection();
                updateChangeCounts();
                editorService.eraseFormatting({ allSelectionData, edits, personId, chapterId, editLanguageId, isAuthor, addOrUpdateEdit, getWorkEditReviewFilled });
              }}
              fontNameSize={fontNameSize}
              handleDownloadChoice={handleDownloadChoice}
              handleFontColorChoice={handleFontColorChoice}
              handleFontNameChoice={handleFontNameChoice}
              handleFontSizeChoice={handleFontSizeChoice}
              handleHtmlCharacterInsert={handleHtmlCharacterInsert}
              handleSetEditChosen={handleSetEditChosen}
              hasSelectedText={!!savedRange}
              isAuthor={isAuthor}
              isOpenLinkEntry={isOpenLinkEntry}
              isTranslation={isTranslation}
              keyIndex={keyIndex}
              openListModal={openListModal}
              personId={personId}
              redo={redo}
              saveByButtonPress={saveByButtonPress}
              saveSelection={saveSelection}
              saveWorkSpaceTime={saveWorkSpaceTime}
              scrollDocumentToMatch={scrollDocumentToMatch}
              sentenceEditCount={sentenceEditCount}
              setFormatChoice={setFormatChoice}
              setIsOpenLinkEntry={setIsOpenLinkEntry}
              setListChoice={setListChoice}
              setOpenListModal={setOpenListModal}
              setOpenTextStyleModal={setOpenTextStyleModal}
              setParagraphAlign={setParagraphAlign}
              setParagraphIndentChoice={setParagraphIndentChoice}
              tabViewElement={document.getElementById(`tabView${suffixId}`)}
              undo={undo}
              {...props} />
          }
          {(true || !isMobile || (isMobile && personId === chosenTab)) && //When it is in mobile-mode, there is a list to choose to show editorDiv when it is the current user - whether or not they are the author or editor.
            <div style={{ position: 'relative' }}>
              {workSummary?.workTypeCode === 'DISCUSSION' &&
                <div>
                  {workSummary.discussionIsFirstLevelPost &&
                    <div className={styles.discussionTitle}>
                      {workSummary.discussionSubject}
                    </div>
                  }
                  <div className={styles.row}>
                    <div className={styles.discussionPerson}>
                      <div className={styles.postOrReply}>
                        {workSummary.discussionIsFirstLevelPost ? 'POST' : workSummary.discussionReplyToDiscussionEntryId ? 'reply' : 'SUBJECT'} {/* Don't show this for the discussion owner's first record. */}
                      </div>
                      <div className={styles.authorName}>
                        {workSummary.authorName}
                      </div>
                    </div>
                    <div className={styles.discussionPersonDate}>{moment(workSummary.entryDate).format("D MMM YYYY")}</div>
                  </div>
                </div>
              }
              {workSummary?.workTypeCode === 'TESTQUIZ' && //If there is just one, then enter in the summary details. Otherwise, the first record will be the author/teacher's version which will be displayed only
                <div className={styles.testQuizHeader}>
                  <div className={styles.row}>
                    <div className={styles.assessmentPerson}>
                      {workSummary.authorName}
                    </div>
                    <div className={styles.assessmentPersonDate}>{moment(workSummary.entryDate).format("D MMM YYYY")}</div>
                    {!isAuthor &&
                      <div style={{marginTop: '6px'}}>
                        <PointsDisplay
                          assessmentQuestionId={workSummary.essayScorePointsPossible?.assessmentQuestionId}
                          isCorrect={workSummary.essayScorePointsPossible?.isCorrect}
                          isTeacher={workSummary.assignmentOwners.filter(m => m.personId === personId)[0]}
                          label='Points'
                          onBlurScore={onBlurScore}
                          onCorrectShowIsCorrect={true}
                          pending={isAuthor && (workSummary?.essayScorePointsPossible?.score == null || workSummary?.essayScorePointsPossible?.score == undefined)}
                          pointsPossible={workSummary.essayScorePointsPossible?.pointsPossible}
                          score={workSummary.essayScorePointsPossible?.score ? workSummary.essayScorePointsPossible?.score : workSummary.essayScorePointsPossible.pendingCorrection ? null : ''}
                          setScore={setAssessmentScoreWork}
                          studentPersonId={workSummary.authorPersonId} 
                          updateAssessmentQuestionAnswerCorrect={props.updateAssessmentQuestionAnswerCorrect}  />
                      </div>      
                    }
                  </div>
                </div>
              }
              {(!isMobile || (isMobile && (personId === chosenTab || !chosenTab))) && 
                <div id={editorDivId}
                  key={`editorDiv${keyIndex}`}
                  style={{ marginTop: isMobile && workSummaries?.length === 1 ? '35px' : '', marginLeft: isMobile ? '0px' : '', minWidth: isSmallTablet ? '750px' : isMobile ? '94vw' : '700px' }}
                  className={getEditorDivStyle()}
                  contentEditable='true'
                  spellCheck={'true'}
                  onKeyUp={handleKeyUp} //This one is not working on the span probably for some React reason.  So this is a hybrid solution at the moment until I can figure out how to get the keyup on the span.
                  onKeyDown={handleKeyDOWN}
                  onDoubleClick={saveSelection}
                  onMouseUp={(event) => setTimeout(() => handleMouseUp(event, true), 200)}
                  onDragStart={preventDragDrop}
                  onPaste={isAuthor && !isCopyCommand ? () => {} : (event) => {  //Don't let this throw you. This is for the editor (!isAuthor) so that if it is the author, then onPaste is not called but allowed to paste where they want. Except if it is a copy, then we are going to take control of the author's entry in order to create new penspring ids. 
                    editorService.setPastedHtml({
                      addOrUpdateEdit: props.addOrUpdateEdit,
                      chapterId,
                      editorName,
                      edits,
                      event,
                      getWorkEditReviewFilled,
                      isAuthor,
                      isCopyCommand,
                      languageId: editLanguageId,
                      personId,
                      removeDeleteSentenceEdits,
                    })
                    if (isAuthor) {
                      let saveSegments = editorService.gatherSegmentsToSave(segments, chapterId, editLanguageId)
                      props.addOrUpdateSegments(personId, saveSegments)
                      setSaveWorkSpaceTime(new Date())
                      setChangeCounts(0)
                    }
                    return false
                  }}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                />
              }
              {workSummary?.workTypeCode === 'DISCUSSION' &&
                <div className={styles.rowRight}>
                  <DiscussionEntryOptions
                    currentEditorDiv={currentEditorDiv}
                    editorDivId={editorDivId}
                    isAuthor={isAuthor}
                    keyIndex={keyIndex}
                    setCurrentEditorDiv={setCurrentEditorDiv}
                    workSummary={workSummary}
                    {...props} />
                </div>
              }
            </div>
          }
        </div>
        {(isLargerLaptop || isMediumLaptop || isMobile) && props.showEditorFullText && (personConfig.showEditorFullText || props.showEditorFullText) && (currentEditorDiv === editorDivId || workSummaries?.length === 1) &&
          <div className={styles.flexShrink} style={{ minWidth: isSmallTablet ? '750px' : isMobile ? '94vw' : '700px' }}>
            <EditorFullTextView
              chosenTab={chosenTab}
              edits={edits}
              handleKeyDOWNEditor={handleKeyDOWNEditor}
              handleMouseUp={handleMouseUp}
              handleSetGlobalChosenTab={handleSetGlobalChosenTab}
              isRightSide={true}
              navText={navText}
              setChosenTab={setChosenTab}
              suffixId={editorService.getSuffixIdFromEditorDivId(editorDivId)}
              tabNav={tabNav}
              tabsData={tabsData}
              {...props} />
          </div>
        }
      </div>
      <div className={isMobile && !workSummary?.workOwners.filter(owner => owner.personId === chosenTab)[0] && (currentEditorDiv === editorDivId || workSummaries?.length === 1) ? styles.showTabView : styles.hideTabView} key={`tabView${keyIndex}`}>
        <div className={styles.tabInstructions}>Changes cannot be made in this view</div>
        {isMobile &&
          <div className={styles.closeTabPage} onClick={() => { handleSetGlobalChosenTab(workSummary?.authorPersonId); setChosenTab(workSummary?.authorPersonId); }}>
            X close
          </div>
        }
        <div id={`tabView${editorService.getSuffixIdFromEditorDivId(editorDivId)}`}
          contentEditable={false}
          onKeyDown={handleKeyDOWNEditor}
          className={styles.tabView}
          onMouseUp={(event) => handleMouseUp(event, false)} />
      </div>
      <WebsiteLinkEntry isOpen={isOpenLinkEntry} onSave={saveWebsiteLink} onCancel={() => setIsOpenLinkEntry(false)} anchorNode={savedElementTextAnchorNode} />
      <MessageModal
        show={showInstructions} handleClose={() => setShowInstructions(false)}
        heading={`Editor Instructions`}
        explain={props.instructionText}
        onClick={() => setShowInstructions(false)} />
      <DownloadFileModal
        show={showDownloadReady}
        handleClose={closeModalDownloadDocx}
        heading={`Download as ${workDownloadReady && workDownloadReady.title.replace('&reg;', '')}`}
        explain={`&nbsp;&nbsp;&nbsp;&nbsp;${workSummary && workSummary.title}`}
        downloadOptions={props.downloadOptions}
        downloadFileName={`${workSummary && workSummary.title}~${workSummary && workSummary.workId && workSummary.workId.substring(0, 6)}${workSummary && workSummary.languageId || 1}.${workDownloadReady && workDownloadReady.id.toLowerCase()}`} />
      <MessageModal
        displayTempMessage
        explain={entryError}
        setEntryError={setEntryError}
        handleClose={() => setEntryError('')}
        heading={`Entry Error!`}
        onClick={() => setEntryError('')} />
      <TextStyleChangeModal
        isOpen={!!openTextStyleModal}
        onClose={() => setOpenTextStyleModal('')}
        onCancel={() => { setOpenTextStyleModal(''); props.setChangeStyle(false); props.setChangeStyleSequence(1); changeStyleEditArray = []; }}
        setOpenTextStyleModal={setOpenTextStyleModal}
        updateChangeStyleEdit={updateChangeStyleEdit}
        changeStyleEditArray={changeStyleEditArray}
        updateChangeCounts={updateChangeCounts}
        savedRange={savedRange}
        savedElementsForEdit={savedElementsForEdit}
        savedSpansForStyleChange={savedSpansForStyleChange}
        segments={segments}
        label='Text Style Change Edit' {...props} />

      <ListChangeModal
        editorDivId={editorDivId}
        openListModal={openListModal}
        currentElement={currentElement}
        label='Number or Unordered List'
        onClose={() => { setOpenListModal(''); props.setChangeList(false); }}
        onCancel={() => { setOpenListModal(''); props.setChangeList(false); }}
        addListTarget={props.addListTarget}
        savedElementsForEdit={savedElementsForEdit}
        savedElementTextAnchorNode={savedElementTextAnchorNode}
        targetParagraph={props.addListTarget}
        saveRevision={saveRevision}
        createNewList={(listType) => {
          editorService.createNewList({
            addOrUpdateEdit: props.addOrUpdateEdit,
            chapterId,
            editorName,
            getWorkEditReviewFilled,
            handleSetChosenSegment,
            isAuthor,
            languageId: editLanguageId,
            listType,
            paragraphElement: props.addListTarget,
            personId,
            suffixId
          })
          setHasListStructure(true)
        }}
        updateConvertAddListEdit={updateConvertAddListEdit}
        convertTextToList={(firstPartialSpan,
          firstPartialSpanRightSide,
          lastPartialSpan,
          lastPartialSpanLeftSide,
          fullChosenElements,
          targetParagraph,
          listType) => editorService.convertTextToList({
            addOrUpdateEdit: props.addOrUpdateEdit,
            chapterId,
            editorName,
            edits,
            firstPartialSpan,
            firstPartialSpanRightSide,
            fullChosenElements,
            getWorkEditReviewFilled,
            isAuthor,
            languageId: editLanguageId,
            lastPartialSpan,
            lastPartialSpanLeftSide,
            listType,
            personId,
            segments,
            targetParagraph,
          })}
        {...props} />

      <TableAddModal
        editorDivId={editorDivId}
        openTableAddModal={openTableAddModal}
        currentElement={currentElement}
        label='Number or Unordered List'
        onClose={() => setOpenTableAddModal('')}
        onCancel={() => setOpenTableAddModal('')}
        addTableTarget={props.addTableTarget}
        savedElementsForEdit={savedElementsForEdit}
        savedElementTextAnchorNode={savedElementTextAnchorNode}
        targetParagraph={props.addTableTarget}
        saveRevision={saveRevision}
        createNewTable={(rows, columns) => editorTableStructure.createNewTable({
          addOrUpdateEdit: props.addOrUpdateEdit,
          chapterId,
          columns,
          editorName,
          getWorkEditReviewFilled,
          handleSetChosenSegment,
          isAuthor,
          languageId: editLanguageId,
          paragraphElement: props.addTableTarget,
          personId,
          rows, 
          suffixId, 
        })}
        {...props} />
    </div>
  );
}

export default AuthoringEditor

