import * as types from '../actions/actionTypes'
import { doSort } from '../utils/sort'

//This has changed to handle discussion entries, test/quizzes, and general multiple homework with essays. It can return one workSummary, 
//    but now it returns many so it will be an array in every case.
export default function (state = [], action) {
  switch (action.type) {
    case types.WORK_EDIT_REVIEW:
      return action.payload

    case types.WORK_EDIT_UPDATE: {
      let incomingEdit = action.payload;
      const newState = state.map(workSummary => {
        if (workSummary.chapterId_current === incomingEdit.chapterId) {
          let newEditSegments = [...(workSummary.editSegments || [])];
          //If not an ADDLISTITEM
          //  Take the edit away in order to add it again with new data
          //else
          //  Take the edit away if there is a matching addListItemSequence
          //  If the edit comes without an addListItemSequence
          //    set the addListItemSequence to the next greatest sequence
          //  end if
          //end if
          //Add the edit to state

          if (incomingEdit.type.indexOf('ADDLISTITEM') === -1) {
            newEditSegments = newEditSegments.filter(
              m =>
                !(
                  m.personId === incomingEdit.personId &&
                  Number(m.elementId) === Number(incomingEdit.elementId) &&
                  m.type === incomingEdit.type
                )
            );
          } else {
            const existEdit = newEditSegments.find(
              m =>
                m.personId === incomingEdit.personId &&
                Number(m.elementId) === Number(incomingEdit.elementId) &&
                m.type === incomingEdit.type &&
                Number(m.addListItemSequence) === Number(incomingEdit.addListItemSequence)
            );

            newEditSegments = newEditSegments.filter(
              m =>
                !(
                  m.personId === incomingEdit.personId &&
                  Number(m.elementId) === Number(incomingEdit.elementId) &&
                  m.type === incomingEdit.type &&
                  Number(m.addListItemSequence) === Number(incomingEdit.addListItemSequence)
                )
            );

            let newIncomingEdit = { ...incomingEdit };
            if (existEdit) {
              newIncomingEdit.addListItemSequence = existEdit.addListItemSequence;
            }
            incomingEdit = newIncomingEdit;
          }

          newEditSegments = newEditSegments.concat(incomingEdit);
          newEditSegments = doSort([...newEditSegments], { sortField: 'addListItemSequence', isAsc: true, isNumber: true });

          return {
            ...workSummary,
            editSegments: newEditSegments,
          };
        }
        return workSummary;
      });
      return newState;
    }

    case types.WORK_EDIT_RESPONSE: {
      const { responseType, edit } = action.payload
      const newState = state.map(workSummary => {
        if (workSummary.chapterId_current === edit.chapterId) {
          let newEditSegments = [...workSummary.editSegments]
          if (responseType === 'DeleteEdit') {
            if (edit.editSegmentId) {
              newEditSegments = newEditSegments.filter(m => Number(m.editSegmentId) !== Number(edit.editSegmentId))
            } else {
              newEditSegments = newEditSegments.filter(m => !(m.personId === edit.personId && Number(m.elementId) === Number(edit.elementId) && m.type === edit.type))  //Just the deleted edit is removed. This could be the editor who just created the edit but we don't yet have an editSegmentId
            }
          }
          return { ...workSummary, editSegments: newEditSegments }
        }
        // Return workSummary as-is if it doesn't match
        return workSummary
      })
      return state
    }
    case types.WORK_ASSESSMENT_SCORE_UPDATE: {
      const { workId, score } = action.payload
      return state.map(work => {
        if (work.workId === workId) {
          return {
            ...work,
            essayScorePointsPossible: { ...work.essayScorePointsPossible, score: score }
          }
        }
        return work
      })
    }
    case types.WORK_COMMENT_UPDATE: {
      const { chapterId, elementId, comment } = action.payload
      const newState = state.map(workSummary => {
        if (workSummary.chapterId_current === chapterId) {
          return {
            ...workSummary,
            workSegments: workSummary.workSegments.map(segment => {
              if (Number(segment.elementId) === Number(elementId)) {
                return {
                  ...segment,
                  comment: comment,
                }
              }
              return { ...segment }
            }),
          }
        }
        return { ...workSummary }
      })

      return newState
    }
    case types.WORK_COMMENT_DELETE: { 
      const { chapterId, workSegmentId } = action.payload
      const newState = state.map(workSummary => {
        if (workSummary.chapterId_current === chapterId) {
          return {
            ...workSummary,
            workSegments: workSummary.workSegments.map(segment => {
              if (Number(segment.workSegmentId) === Number(workSegmentId)) {
                return {
                  ...segment,
                  comment: '',
                }
              }
              return { ...segment }
            }),
          }
        }
        return { ...workSummary }
      })

      return newState
    }

    default:
      return state
  }
}

export const selectWorkEditReview = (state) => state
