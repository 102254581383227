import React, {useState} from 'react'
import styles from './MenuDocumentRibbon.module.css'
import ToastInputText from '../../components/ToastInputText';
import classes from 'classnames'
import Icon from '../Icon'
import {guidEmpty} from '../../utils/GuidValidate'
import { useAppContext } from "../../../AppContext.js";

function MenuDocumentRibbon(props) {
	const {className="", personId, mineOrOthers } = props
	const [enterFolderTitle, setEnterFolderTitle] = useState(false)
  const { pageVisitor } = useAppContext();

	const submitAddWorkFolder = (folderTitle) => {
		props.addWorkFolder(personId, guidEmpty, folderTitle, mineOrOthers)
		setEnterFolderTitle(false)
    pageVisitor({ p: 'myWorks', n: `Added a new folder: ${folderTitle}` })

	}

  return (
		<div className={classes(styles.container, styles.row, className)}>
			{mineOrOthers === 'Mine' &&
				<a onClick={() => setEnterFolderTitle(true)} title={'Add new folder'}>
					<Icon pathName={'folder_only'} premium={true} superscript={'plus'} supFillColor={'white'} fillColor={'white'}
								className={classes(styles.image, styles.moreTopMargin)}/>
				</a>
			}
			<ToastInputText 
				show={enterFolderTitle} 
				label={`Enter Folder Title`} 
				message="New Folder" 
				onSubmit={submitAddWorkFolder} 
				onClose={() => setEnterFolderTitle(false)} />
		</div>
  )
}

export default MenuDocumentRibbon