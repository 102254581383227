import * as types from './actionTypes'
import getGroupsIdCurrent from '../services/groupsId-current.js'
import * as actionEditorInvitePending from './editor-invite-pending'
import {apiHost} from '../api_host.js'
import * as guid from '../utils/GuidValidate.js'


export const getGroups = (personId) => {
	return dispatch => {
		let storage = localStorage.getItem("groups");
		storage && dispatch && dispatch({ type: types.GROUPS_INIT, payload: JSON.parse(storage) });

		return fetch(`${apiHost}ebi/groups/${personId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				}
			})
			.then(response => {
				localStorage.setItem("groups", JSON.stringify(response));
				dispatch({type: types.GROUPS_INIT, payload: response})
			})
	}
}

export const getGroupByName = (personId, groupName) => {
	return dispatch => {
		return fetch(`${apiHost}ebi/group/byName/${personId}/${groupName}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				}
			})
			.then(response => {
				dispatch({ type: types.GROUP_BY_NAME_INIT, payload: response })
			})
	}
}

export const toggleGroupJoinLink = (groupId) => {
	return dispatch =>
		fetch(`${apiHost}ebi/group/toggleJoinLink/${groupId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				}
			})
			.then(response => {
        dispatch({ type: types.GROUP_JOIN_INVITE_CODE, payload: response });
			})
}

export const getGroupJoinInviteCode = (groupId) => {
  return dispatch =>
    fetch(`${apiHost}ebi/group/getJoinLink/${groupId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.GROUP_JOIN_INVITE_CODE, payload: response });
      })
}


function setGroupsIdCurrent(groupsId_current) {
	return {type: types.GROUPS_CURRENT_SET_SELECTED, payload: groupsId_current}
}

export const initGroupsIdCurrent = (personId) => dispatch =>
	getGroupsIdCurrent(personId).then(n => {
		dispatch(setGroupsIdCurrent(n))
	})

export const setGroupCurrentSelected = (personId, groupId, masterWorkId, memberWorkId, goToPage) => {
	masterWorkId = guid.isGuidNotEmpty(masterWorkId) ? masterWorkId : guid.emptyGuid()
	memberWorkId = guid.isGuidNotEmpty(memberWorkId) ? memberWorkId : guid.emptyGuid()

	return dispatch => {
		fetch(`${apiHost}ebi/groups/setRecentGroup`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify({
				personId,
				groupId,
				masterWorkId,
				memberWorkId,
			}),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.GROUPS_CURRENT_SET_SELECTED, payload: groupId})
				masterWorkId && masterWorkId !== guid.emptyGuid() && dispatch({
					type: types.WORK_CURRENT_SET_SELECTED,
					payload: masterWorkId
				})
				dispatch({type: types.GROUPS_INIT, payload: response})
				if (goToPage && goToPage !== "STAY") {
					window.location = `/` + goToPage
				}
			})
	}
}

export const addOrUpdateGroup = (groupData, sendTo) => {
	return dispatch =>
		fetch(`${apiHost}ebi/groups/addOrUpdate`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(groupData),
		})
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: response && response.groupFileTreeExplorer });
        localStorage.setItem("groupFileTreeExplorer", JSON.stringify(response));
				const personId = response?.groupFileTreeExplorer?.length > 0 && response.groupFileTreeExplorer[0].personId
				getGroupContactsWorks(personId, response?.group?.groupId)
				if (sendTo === 'NONE') {
					//dont do anything
				} else if (sendTo) {
					window.location = `${sendTo}/${response.group && response.group.groupId}`
        } else {
          window.location = `/groupContacts/${response.group && response.group.groupId}`
        }
      })
}

export const deleteGroupWorkFolderAssignment = (personId, workFolderId, sendTo) => {
	return dispatch =>
		fetch(`${apiHost}ebi/groups/deleteWorkFolderAssignment/${personId}/${workFolderId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				}
			})
			.then(response => {
				dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: response && response.groupFileTreeExplorer });
				localStorage.setItem("groupFileTreeExplorer", JSON.stringify(response));
				if (sendTo) {
					window.location = sendTo
				} else {
					window.location = `/myWorks`
				}
			})
}

export const updateGroup = (personId, groupId, groupName, internalId, description, goToPage) => {
	return dispatch =>
		fetch(`${apiHost}ebi/groups/addOrUpdate`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify({
				personId,
				groupId,
				groupName,
				internalId,
				description,
			}),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.GROUPS_INIT, payload: response})
				if (goToPage !== "STAY") {
					window.location = goToPage
				}
			})
	//.catch(error => { console.l og('request failed', error) })
}

export const deleteGroup = (personId, groupId) => {
	return dispatch =>
		fetch(`${apiHost}ebi/groups/delete/${personId}/${groupId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
        dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: response });
        localStorage.setItem("groups", JSON.stringify(response));
      })
	//.catch(error => { console.l og('request failed', error) })
}

export const setGroupMembers = (personId, groupId, members) => {
	var test = !groupId || groupId == "undefined" ? guid.guidEmpty : groupId
	return dispatch =>
		fetch(`${apiHost}ebi/groups/members/update`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify({
				personId,
				groupId: !groupId || groupId == "undefined" ? guid.guidEmpty : groupId,
				members
			}),
		})
			.then(dispatch(actionEditorInvitePending.getInvitesPending(personId)))
}

export const removeMember = (personId, groupId, member_personId) => {
	return dispatch =>
		fetch(`${apiHost}ebi/groups/member/remove/${personId}/${groupId}/${member_personId}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify({}),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.GROUPS_INIT, payload: response})
			})
	//.catch(error => { console.l og('request failed', error) })
}

export const removeMemberByWorkFolderId = (personId, workFolderId) => {
	return dispatch =>
		fetch(`${apiHost}ebi/groups/member/remove/byWorkFolder/${personId}/${workFolderId}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify({}),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.WORK_FILE_TREE_INIT, payload: response });
				localStorage.setItem("workFileTreeExplorer", JSON.stringify(response));
			})
	//.catch(error => { console.l og('request failed', error) })
}

export const updateGroupMember = (personId, groupId, member) => {
	return dispatch =>
		fetch(`${apiHost}ebi/groups/updateMember/` + personId + `/` + groupId, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(member),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.GROUPS_INIT, payload: response})
			})
}

export const getGroupContactsWorks = (personId, groupId) => {
	return dispatch => {
		let storage = localStorage.getItem("groupFileTreeExplorer");
		storage && dispatch && dispatch({ type: types.GROUP_FILE_TREE_INIT, payload: JSON.parse(storage) });

		return fetch(`${apiHost}ebi/group/${personId}/${groupId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials' : 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				}
			})
			.then(response => {
				dispatch({ type: types.GROUP_CONTACTS_WORKS_INIT, payload: response });
				localStorage.setItem("groupFileTreeExplorer", JSON.stringify(response));
			})
	}
}

export const assignWorkToGroup = (personId, workId, groupId, isDelete=false) => {
	return dispatch => {
		return fetch(`${apiHost}ebi/group/assignWork/${workId}/${groupId}/${isDelete}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials' : 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(dispatch(getGroupContactsWorks(personId, groupId)))
	}
}

export const assignContactToGroup = (senderPersonId, contactPersonId, groupId, firstName, lastName, photo, emailAddress, socialMediaListId, includeIndividualContact=false, isDelete=false) => {
	return dispatch => {
		dispatch({ type: types.GROUP_CONTACTS_UPDATE, payload: { contactPersonId, firstName, lastName, photo, emailAddress, socialMediaListId, includeIndividualContact, isDelete} });
		return fetch(`${apiHost}ebi/group/assignContact`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials' : 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify({
				senderPersonId,
				contactPersonId: contactPersonId || guid.guidEmpty,
				groupId: groupId || guid.guidEmpty,
				firstName,
				lastName,
				photoUrl: photo,
				emailAddress,
				includeIndividualContact,
				isDelete,
				socialMediaListId,
			})
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				}
			})
			.then(response => {
				localStorage.setItem('contacts', JSON.stringify(response))
				dispatch({ type: types.CONTACTS_INIT, payload: response })
				setTimeout(() => dispatch(getGroupContactsWorks(senderPersonId, groupId)), 500)
			})

	}
}

export const addUserToGroupInvite = (inviteCodeShort) => {
	return dispatch => {
		return fetch(`${apiHost}ebi/group/invite/${inviteCodeShort}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
	}
}

